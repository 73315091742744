/* eslint-disable */
const DEFS = {
  "pageDataKey": "newbornInOut",
  "pIndex": "64",
  "hasGridTable": true,
  "pageChildren": [
    {
      "elementKey": "nbPerFd_form_name",
      "formIndex": "1",
      "inputType": "practitionerName",
      "tableColumn": "2",
      "recHeader": true,
      "fqn": "newbornInOut.name"
    },
    {
      "elementKey": "nbPerFd_form_id",
      "inputType": "generatedId",
      "tableColumn": 0,
      "label": "Row id",
      "tableCss": "row-id"
    },
    {
      "elementKey": "nbPerFd_form_profession",
      "formIndex": "1",
      "inputType": "practitionerProfession",
      "tableColumn": "2",
      "recHeader": true,
      "fqn": "newbornInOut.profession"
    },
    {
      "elementKey": "nbPerFd_form_day",
      "formIndex": "1",
      "inputType": "visitDay",
      "mandatory": true,
      "tableColumn": "2",
      "validation": "visitDay",
      "recHeader": true,
      "fqn": "newbornInOut.day"
    },
    {
      "elementKey": "nbPerFd_form_time",
      "formIndex": "1",
      "inputType": "visitTime",
      "mandatory": true,
      "tableColumn": "2",
      "validation": "time24",
      "recHeader": true,
      "fqn": "newbornInOut.time"
    },
    {
      "elementKey": "nbPerFdMomPom",
      "formIndex": "1",
      "inputType": "number",
      "suffix": "ml",
      "tableColumn": "4",
      "fqn": "newbornInOut.nbPerFdMomPom",
      "suffixText": "ml"
    },
    {
      "elementKey": "nbPerFdFormula",
      "formIndex": "1",
      "inputType": "number",
      "suffix": "ml",
      "tableColumn": "5",
      "fqn": "newbornInOut.nbPerFdFormula",
      "suffixText": "ml"
    },
    {
      "elementKey": "nbPerFdPdhm",
      "formIndex": "1",
      "inputType": "number",
      "suffix": "ml",
      "tableColumn": "6",
      "fqn": "newbornInOut.nbPerFdPdhm",
      "suffixText": "ml"
    },
    {
      "elementKey": "nbPerFdBatch",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "7",
      "fqn": "newbornInOut.nbPerFdBatch"
    },
    {
      "elementKey": "nbPerDiaperWet",
      "formIndex": "1",
      "inputType": "radioset",
      "options": [
        {
          "key": "yes",
          "text": "yes"
        },
        {
          "key": "no",
          "text": "no"
        }
      ],
      "passToFunction": "nbPerFdDiapersWS",
      "tableColumn": "9",
      "fqn": "newbornInOut.nbPerDiaperWet"
    },
    {
      "elementKey": "nbPerDiaperStool",
      "formIndex": "1",
      "inputType": "radioset",
      "options": [
        {
          "key": "yes",
          "text": "yes"
        },
        {
          "key": "no",
          "text": "no"
        }
      ],
      "passToFunction": "nbPerFdDiapersWS",
      "tableColumn": "10",
      "fqn": "newbornInOut.nbPerDiaperStool"
    },
    {
      "elementKey": "nbPerDiaperAmnt",
      "formIndex": "1",
      "inputType": "radioset",
      "options": [
        {
          "key": "Small",
          "text": "Small"
        },
        {
          "key": "Medium",
          "text": "Medium"
        },
        {
          "key": "Large",
          "text": "Large"
        }
      ],
      "passToFunction": "nbPerFdDiapersWS",
      "tableColumn": "11",
      "fqn": "newbornInOut.nbPerDiaperAmnt"
    },
    {
      "elementKey": "nbPerDiaperType",
      "formIndex": "1",
      "inputType": "radioset",
      "options": [
        {
          "key": "meconium",
          "text": "Meconium"
        },
        {
          "key": "seedy",
          "text": "Seedy"
        },
        {
          "key": "soft",
          "text": "Soft"
        },
        {
          "key": "pasty",
          "text": "Pasty"
        },
        {
          "key": "formed",
          "text": "Formed"
        }
      ],
      "passToFunction": "nbPerFdDiapersWS",
      "tableColumn": "12",
      "fqn": "newbornInOut.nbPerDiaperType"
    },
    {
      "elementKey": "nbPerDiaperClr",
      "calculationType": "nbDiapers",
      "formIndex": "1",
      "inputType": "radioset",
      "options": [
        {
          "key": "Yellow",
          "text": "Yellow"
        },
        {
          "key": "Green",
          "text": "Green"
        },
        {
          "key": "Brown",
          "text": "Brown"
        },
        {
          "key": "Pale",
          "text": "Pale"
        }
      ],
      "tableColumn": "13",
      "fqn": "newbornInOut.nbPerDiaperClr"
    },
    {
      "elementKey": "nbPerFdComments",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "15",
      "fqn": "newbornInOut.nbPerFdComments"
    },
    {
      "elementKey": "nbShiftEnd_form_name",
      "formIndex": "2",
      "inputType": "practitionerName",
      "tableColumn": "18",
      "recHeader": true,
      "fqn": "newbornInOut.name"
    },
    {
      "elementKey": "nbShiftEnd_form_id",
      "inputType": "generatedId",
      "tableColumn": 0,
      "label": "Row id",
      "tableCss": "row-id"
    },
    {
      "elementKey": "nbShiftEnd_form_profession",
      "formIndex": "2",
      "inputType": "practitionerProfession",
      "tableColumn": "18",
      "recHeader": true,
      "fqn": "newbornInOut.profession"
    },
    {
      "elementKey": "nbShiftEnd_form_day",
      "formIndex": "2",
      "inputType": "visitDay",
      "mandatory": true,
      "tableColumn": "18",
      "validation": "visitDay",
      "recHeader": true,
      "fqn": "newbornInOut.day"
    },
    {
      "elementKey": "nbShiftEnd_form_time",
      "formIndex": "2",
      "inputType": "visitTime",
      "mandatory": true,
      "tableColumn": "18",
      "validation": "time24",
      "recHeader": true,
      "fqn": "newbornInOut.time"
    },
    {
      "elementKey": "nbShiftEndLabel1",
      "formIndex": "2",
      "inputType": "form_label",
      "tableColumn": "20",
      "fqn": "newbornInOut.nbShiftEndLabel1"
    },
    {
      "elementKey": "nbShiftEndFeeds",
      "formIndex": "2",
      "inputType": "number",
      "tableColumn": "21",
      "fqn": "newbornInOut.nbShiftEndFeeds"
    },
    {
      "elementKey": "nbShiftEndIntake",
      "formIndex": "2",
      "inputType": "text",
      "tableColumn": "22",
      "fqn": "newbornInOut.nbShiftEndIntake"
    },
    {
      "elementKey": "nbShiftEndLabel2",
      "formIndex": "2",
      "inputType": "form_label",
      "tableColumn": "24",
      "fqn": "newbornInOut.nbShiftEndLabel2"
    },
    {
      "elementKey": "nbShiftEndVoids",
      "formIndex": "2",
      "inputType": "number",
      "tableColumn": "25",
      "fqn": "newbornInOut.nbShiftEndVoids"
    },
    {
      "elementKey": "nbShiftEndStools",
      "formIndex": "2",
      "inputType": "number",
      "tableColumn": "26",
      "fqn": "newbornInOut.nbShiftEndStools"
    },
    {
      "elementKey": "nbShiftEndOutput",
      "formIndex": "2",
      "inputType": "text",
      "tableColumn": "27",
      "fqn": "newbornInOut.nbShiftEndOutput"
    }
  ],
  "pageElements": {
    "nbPerFd_form": {
      "elementKey": "nbPerFd_form",
      "pageElementIndex": "1",
      "pageKey": "newbornInOut",
      "tableKey": "nbPerFd_form",
      "isTable": true,
      "form": {
        "elementKey": "nbPerFd_form",
        "label": "Intake Output",
        "addButtonText": "Add Intake Output",
        "formKey": "nbPerFd_form",
        "ehr_groups": [
          {
            "elementKey": "nbPerFd_hdrGroup",
            "formCss": "record-header",
            "fqn": "newbornInOut.nbPerFd_hdrGroup",
            "gIndex": "1",
            "gChildren": [
              "nbPerFd_form_name",
              "nbPerFd_form_profession",
              "nbPerFd_form_day",
              "nbPerFd_form_time"
            ]
          },
          {
            "elementKey": "nbPerFd_1",
            "formCss": "ehr-row-flow",
            "fqn": "newbornInOut.nbPerFd_1",
            "gIndex": "2",
            "gChildren": [
              "nbPerFdMomPom",
              "nbPerFdFormula",
              "nbPerFdPdhm",
              "nbPerFdBatch"
            ]
          },
          {
            "elementKey": "nbPerFd_2",
            "formCss": "ehr-row-flow",
            "fqn": "newbornInOut.nbPerFd_2",
            "gIndex": "3",
            "gChildren": [
              "nbPerDiaperWet",
              "nbPerDiaperStool",
              "nbPerDiaperAmnt",
              "nbPerDiaperType",
              "nbPerDiaperClr"
            ]
          },
          {
            "elementKey": "nbPerFd_2",
            "formCss": "grid-left-to-right-1",
            "fqn": "newbornInOut.nbPerFd_2",
            "gIndex": "4",
            "gChildren": [
              "nbPerFdComments"
            ]
          }
        ],
        "ehr_data": {
          "nbPerFd_form_name": "",
          "nbPerFd_form_profession": "",
          "nbPerFd_form_day": "",
          "nbPerFd_form_time": "",
          "nbPerFdMomPom": "",
          "nbPerFdFormula": "",
          "nbPerFdPdhm": "",
          "nbPerFdBatch": "",
          "nbPerDiaperWet": "",
          "nbPerDiaperStool": "",
          "nbPerDiaperAmnt": "",
          "nbPerDiaperType": "",
          "nbPerDiaperClr": "",
          "nbPerFdComments": ""
        }
      },
      "fqn": "newbornInOut.nbPerFd_form",
      "tableChildren": [
        "nbPerFd_form_id",
        "nbPerFd_form_day",
        "nbPerFd_form_time",
        "nbPerFdMomPom",
        "nbPerFdFormula",
        "nbPerFdPdhm",
        "nbPerFdBatch",
        "nbPerDiaperWet",
        "nbPerDiaperStool",
        "nbPerDiaperAmnt",
        "nbPerDiaperType",
        "nbPerDiaperClr",
        "nbPerFdComments",
        "nbPerFd_form_name",
        "nbPerFd_form_profession"
      ],
      "hasRecHeader": true
    },
    "nbShiftEnd_form": {
      "elementKey": "nbShiftEnd_form",
      "pageElementIndex": "2",
      "pageKey": "newbornInOut",
      "tableKey": "nbShiftEnd_form",
      "isTable": true,
      "form": {
        "elementKey": "nbShiftEnd_form",
        "label": "End of Shift Totals",
        "addButtonText": "Add End of Shift Totals",
        "formKey": "nbShiftEnd_form",
        "ehr_groups": [
          {
            "elementKey": "nbShiftEnd_hdrGroup",
            "formCss": "record-header",
            "fqn": "newbornInOut.nbShiftEnd_hdrGroup",
            "gIndex": "1",
            "gChildren": [
              "nbShiftEnd_form_name",
              "nbShiftEnd_form_profession",
              "nbShiftEnd_form_day",
              "nbShiftEnd_form_time"
            ]
          },
          {
            "elementKey": "nbShiftEnd_1",
            "formCss": "ehr-row-flow",
            "fqn": "newbornInOut.nbShiftEnd_1",
            "gIndex": "2",
            "gChildren": [
              "nbShiftEndLabel1",
              "nbShiftEndFeeds",
              "nbShiftEndIntake"
            ]
          },
          {
            "elementKey": "nbShiftEnd_2",
            "formCss": "ehr-row-flow",
            "fqn": "newbornInOut.nbShiftEnd_2",
            "gIndex": "3",
            "gChildren": [
              "nbShiftEndLabel2",
              "nbShiftEndVoids",
              "nbShiftEndStools",
              "nbShiftEndOutput"
            ]
          }
        ],
        "ehr_data": {
          "nbShiftEnd_form_name": "",
          "nbShiftEnd_form_profession": "",
          "nbShiftEnd_form_day": "",
          "nbShiftEnd_form_time": "",
          "nbShiftEndFeeds": "",
          "nbShiftEndIntake": "",
          "nbShiftEndVoids": "",
          "nbShiftEndStools": "",
          "nbShiftEndOutput": ""
        }
      },
      "fqn": "newbornInOut.nbShiftEnd_form",
      "tableChildren": [
        "nbShiftEnd_form_id",
        "nbShiftEnd_form_day",
        "nbShiftEnd_form_time",
        "nbShiftEndLabel1",
        "nbShiftEndFeeds",
        "nbShiftEndIntake",
        "nbShiftEndLabel2",
        "nbShiftEndVoids",
        "nbShiftEndStools",
        "nbShiftEndOutput",
        "nbShiftEnd_form_name",
        "nbShiftEnd_form_profession"
      ],
      "hasRecHeader": true
    }
  },
  "path": "/ehr/peri-ped/newborn",
  "routeName": "newborn-in-out",
  "label": "Intake Output",
  "generateComponent": "v2page",
  "featureFlag": "ffPed",
  "ehr": "yes",
  "fullPageKey": "periPed.newborn.newbornInOut"
}
export default DEFS