/* eslint-disable */
const DEFS = {
  "pageDataKey": "newbornDischargeEd",
  "pIndex": "67",
  "hasGridForm": true,
  "pageChildren": [
    {
      "elementKey": "nbDischEd_form_name",
      "formIndex": "1",
      "inputType": "practitionerName",
      "tableColumn": "1",
      "recHeader": true,
      "fqn": "newbornDischargeEd.name"
    },
    {
      "elementKey": "nbDischEd_form_profession",
      "formIndex": "1",
      "inputType": "practitionerProfession",
      "tableColumn": "1",
      "recHeader": true,
      "fqn": "newbornDischargeEd.profession"
    },
    {
      "elementKey": "nbDischEd_form_day",
      "formIndex": "1",
      "inputType": "visitDay",
      "mandatory": true,
      "tableColumn": "1",
      "validation": "visitDay",
      "recHeader": true,
      "fqn": "newbornDischargeEd.day"
    },
    {
      "elementKey": "nbDischEd_form_time",
      "formIndex": "1",
      "inputType": "visitTime",
      "mandatory": true,
      "tableColumn": "1",
      "validation": "time24",
      "recHeader": true,
      "fqn": "newbornDischargeEd.time"
    },
    {
      "elementKey": "nbDischEdTo",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "3",
      "fqn": "newbornDischargeEd.nbDischEdTo"
    },
    {
      "elementKey": "nbDischEdInterpreter",
      "formIndex": "1",
      "inputType": "radioset",
      "options": [
        {
          "key": "interpreter",
          "text": "Interpreter services"
        },
        {
          "key": "staff",
          "text": "Staff member"
        },
        {
          "key": "family",
          "text": "Family member"
        },
        {
          "key": "other",
          "text": "Other"
        }
      ],
      "tableColumn": "4",
      "fqn": "newbornDischargeEd.nbDischEdInterpreter"
    },
    {
      "elementKey": "nbDischEdName",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "5",
      "fqn": "newbornDischargeEd.nbDischEdName"
    },
    {
      "elementKey": "nbDischEdSkinBenefits",
      "formIndex": "1",
      "inputType": "checkset",
      "options": [
        {
          "key": "completed",
          "text": "Education completed"
        },
        {
          "key": "started",
          "text": "Education initiated"
        },
        {
          "key": "followUp",
          "text": "Follow up needed"
        },
        {
          "key": "patientAware",
          "text": "Patient aware independent"
        },
        {
          "key": "resources",
          "text": "Resources given"
        },
        {
          "key": "na",
          "text": "Not applicable"
        }
      ],
      "tableColumn": "7",
      "fqn": "newbornDischargeEd.nbDischEdSkinBenefits"
    },
    {
      "elementKey": "nbDischEdSkinSafety",
      "formIndex": "1",
      "inputType": "checkset",
      "options": [
        {
          "key": "completed",
          "text": "Education completed"
        },
        {
          "key": "started",
          "text": "Education initiated"
        },
        {
          "key": "followUp",
          "text": "Follow up needed"
        },
        {
          "key": "patientAware",
          "text": "Patient aware independent"
        },
        {
          "key": "resources",
          "text": "Resources given"
        },
        {
          "key": "na",
          "text": "Not applicable"
        }
      ],
      "tableColumn": "8",
      "fqn": "newbornDischargeEd.nbDischEdSkinSafety"
    },
    {
      "elementKey": "nbDischEdSkinToSkin",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "9",
      "fqn": "newbornDischargeEd.nbDischEdSkinToSkin"
    },
    {
      "elementKey": "nbDischEdFeedingCues",
      "formIndex": "1",
      "inputType": "checkset",
      "options": [
        {
          "key": "completed",
          "text": "Education completed"
        },
        {
          "key": "started",
          "text": "Education initiated"
        },
        {
          "key": "followUp",
          "text": "Follow up needed"
        },
        {
          "key": "patientAware",
          "text": "Patient aware independent"
        },
        {
          "key": "resources",
          "text": "Resources given"
        },
        {
          "key": "na",
          "text": "Not applicable"
        }
      ],
      "tableColumn": "11",
      "fqn": "newbornDischargeEd.nbDischEdFeedingCues"
    },
    {
      "elementKey": "nbDischEdFeedingPosition",
      "formIndex": "1",
      "inputType": "checkset",
      "options": [
        {
          "key": "completed",
          "text": "Education completed"
        },
        {
          "key": "started",
          "text": "Education initiated"
        },
        {
          "key": "followUp",
          "text": "Follow up needed"
        },
        {
          "key": "patientAware",
          "text": "Patient aware independent"
        },
        {
          "key": "resources",
          "text": "Resources given"
        },
        {
          "key": "na",
          "text": "Not applicable"
        }
      ],
      "tableColumn": "12",
      "fqn": "newbornDischargeEd.nbDischEdFeedingPosition"
    },
    {
      "elementKey": "nbDischEdFeedingSigns",
      "formIndex": "1",
      "inputType": "checkset",
      "options": [
        {
          "key": "completed",
          "text": "Education completed"
        },
        {
          "key": "started",
          "text": "Education initiated"
        },
        {
          "key": "followUp",
          "text": "Follow up needed"
        },
        {
          "key": "patientAware",
          "text": "Patient aware independent"
        },
        {
          "key": "resources",
          "text": "Resources given"
        },
        {
          "key": "na",
          "text": "Not applicable"
        }
      ],
      "tableColumn": "13",
      "fqn": "newbornDischargeEd.nbDischEdFeedingSigns"
    },
    {
      "elementKey": "nbDischEdFeedingLatch",
      "formIndex": "1",
      "inputType": "checkset",
      "options": [
        {
          "key": "completed",
          "text": "Education completed"
        },
        {
          "key": "started",
          "text": "Education initiated"
        },
        {
          "key": "followUp",
          "text": "Follow up needed"
        },
        {
          "key": "patientAware",
          "text": "Patient aware independent"
        },
        {
          "key": "resources",
          "text": "Resources given"
        },
        {
          "key": "na",
          "text": "Not applicable"
        }
      ],
      "tableColumn": "14",
      "fqn": "newbornDischargeEd.nbDischEdFeedingLatch"
    },
    {
      "elementKey": "nbDischEdFeedingActive",
      "formIndex": "1",
      "inputType": "checkset",
      "options": [
        {
          "key": "completed",
          "text": "Education completed"
        },
        {
          "key": "started",
          "text": "Education initiated"
        },
        {
          "key": "followUp",
          "text": "Follow up needed"
        },
        {
          "key": "patientAware",
          "text": "Patient aware independent"
        },
        {
          "key": "resources",
          "text": "Resources given"
        },
        {
          "key": "na",
          "text": "Not applicable"
        }
      ],
      "tableColumn": "15",
      "fqn": "newbornDischargeEd.nbDischEdFeedingActive"
    },
    {
      "elementKey": "nbDischEdFeedingMilk",
      "formIndex": "1",
      "inputType": "checkset",
      "options": [
        {
          "key": "completed",
          "text": "Education completed"
        },
        {
          "key": "started",
          "text": "Education initiated"
        },
        {
          "key": "followUp",
          "text": "Follow up needed"
        },
        {
          "key": "patientAware",
          "text": "Patient aware independent"
        },
        {
          "key": "resources",
          "text": "Resources given"
        },
        {
          "key": "na",
          "text": "Not applicable"
        }
      ],
      "tableColumn": "16",
      "fqn": "newbornDischargeEd.nbDischEdFeedingMilk"
    },
    {
      "elementKey": "nbDischEdFeedingPlan",
      "formIndex": "1",
      "inputType": "checkset",
      "options": [
        {
          "key": "completed",
          "text": "Education completed"
        },
        {
          "key": "started",
          "text": "Education initiated"
        },
        {
          "key": "followUp",
          "text": "Follow up needed"
        },
        {
          "key": "patientAware",
          "text": "Patient aware independent"
        },
        {
          "key": "resources",
          "text": "Resources given"
        },
        {
          "key": "na",
          "text": "Not applicable"
        }
      ],
      "tableColumn": "17",
      "fqn": "newbornDischargeEd.nbDischEdFeedingPlan"
    },
    {
      "elementKey": "nbDischEdFeedingComments",
      "formIndex": "1",
      "inputType": "textarea",
      "tableColumn": "18",
      "fqn": "newbornDischargeEd.nbDischEdFeedingComments"
    },
    {
      "elementKey": "nbDischEdCareBehaviour",
      "formIndex": "1",
      "inputType": "checkset",
      "options": [
        {
          "key": "completed",
          "text": "Education completed"
        },
        {
          "key": "started",
          "text": "Education initiated"
        },
        {
          "key": "followUp",
          "text": "Follow up needed"
        },
        {
          "key": "patientAware",
          "text": "Patient aware independent"
        },
        {
          "key": "resources",
          "text": "Resources given"
        },
        {
          "key": "na",
          "text": "Not applicable"
        }
      ],
      "tableColumn": "20",
      "fqn": "newbornDischargeEd.nbDischEdCareBehaviour"
    },
    {
      "elementKey": "nbDischEdCareSleep",
      "formIndex": "1",
      "inputType": "checkset",
      "options": [
        {
          "key": "completed",
          "text": "Education completed"
        },
        {
          "key": "started",
          "text": "Education initiated"
        },
        {
          "key": "followUp",
          "text": "Follow up needed"
        },
        {
          "key": "patientAware",
          "text": "Patient aware independent"
        },
        {
          "key": "resources",
          "text": "Resources given"
        },
        {
          "key": "na",
          "text": "Not applicable"
        }
      ],
      "tableColumn": "21",
      "fqn": "newbornDischargeEd.nbDischEdCareSleep"
    },
    {
      "elementKey": "nbDischEdCareCrying",
      "formIndex": "1",
      "inputType": "checkset",
      "options": [
        {
          "key": "completed",
          "text": "Education completed"
        },
        {
          "key": "started",
          "text": "Education initiated"
        },
        {
          "key": "followUp",
          "text": "Follow up needed"
        },
        {
          "key": "patientAware",
          "text": "Patient aware independent"
        },
        {
          "key": "resources",
          "text": "Resources given"
        },
        {
          "key": "na",
          "text": "Not applicable"
        }
      ],
      "tableColumn": "22",
      "fqn": "newbornDischargeEd.nbDischEdCareCrying"
    },
    {
      "elementKey": "nbDischEdCarePreventSBS",
      "formIndex": "1",
      "inputType": "checkset",
      "options": [
        {
          "key": "completed",
          "text": "Education completed"
        },
        {
          "key": "started",
          "text": "Education initiated"
        },
        {
          "key": "followUp",
          "text": "Follow up needed"
        },
        {
          "key": "patientAware",
          "text": "Patient aware independent"
        },
        {
          "key": "resources",
          "text": "Resources given"
        },
        {
          "key": "na",
          "text": "Not applicable"
        }
      ],
      "tableColumn": "23",
      "fqn": "newbornDischargeEd.nbDischEdCarePreventSBS"
    },
    {
      "elementKey": "nbDischEdCareSmokeFree",
      "formIndex": "1",
      "inputType": "checkset",
      "options": [
        {
          "key": "completed",
          "text": "Education completed"
        },
        {
          "key": "started",
          "text": "Education initiated"
        },
        {
          "key": "followUp",
          "text": "Follow up needed"
        },
        {
          "key": "patientAware",
          "text": "Patient aware independent"
        },
        {
          "key": "resources",
          "text": "Resources given"
        },
        {
          "key": "na",
          "text": "Not applicable"
        }
      ],
      "tableColumn": "24",
      "fqn": "newbornDischargeEd.nbDischEdCareSmokeFree"
    },
    {
      "elementKey": "nbDischEdCarePreventInjury",
      "formIndex": "1",
      "inputType": "checkset",
      "options": [
        {
          "key": "completed",
          "text": "Education completed"
        },
        {
          "key": "started",
          "text": "Education initiated"
        },
        {
          "key": "followUp",
          "text": "Follow up needed"
        },
        {
          "key": "patientAware",
          "text": "Patient aware independent"
        },
        {
          "key": "resources",
          "text": "Resources given"
        },
        {
          "key": "na",
          "text": "Not applicable"
        }
      ],
      "tableColumn": "25",
      "fqn": "newbornDischargeEd.nbDischEdCarePreventInjury"
    },
    {
      "elementKey": "nbDischEdCareCarSeat",
      "formIndex": "1",
      "inputType": "checkset",
      "options": [
        {
          "key": "completed",
          "text": "Education completed"
        },
        {
          "key": "started",
          "text": "Education initiated"
        },
        {
          "key": "followUp",
          "text": "Follow up needed"
        },
        {
          "key": "patientAware",
          "text": "Patient aware independent"
        },
        {
          "key": "resources",
          "text": "Resources given"
        },
        {
          "key": "na",
          "text": "Not applicable"
        }
      ],
      "tableColumn": "26",
      "fqn": "newbornDischargeEd.nbDischEdCareCarSeat"
    },
    {
      "elementKey": "nbDischEdCareBath",
      "formIndex": "1",
      "inputType": "checkset",
      "options": [
        {
          "key": "completed",
          "text": "Education completed"
        },
        {
          "key": "started",
          "text": "Education initiated"
        },
        {
          "key": "followUp",
          "text": "Follow up needed"
        },
        {
          "key": "patientAware",
          "text": "Patient aware independent"
        },
        {
          "key": "resources",
          "text": "Resources given"
        },
        {
          "key": "na",
          "text": "Not applicable"
        }
      ],
      "tableColumn": "27",
      "fqn": "newbornDischargeEd.nbDischEdCareBath"
    },
    {
      "elementKey": "nbDischEdCareCarry",
      "formIndex": "1",
      "inputType": "checkset",
      "options": [
        {
          "key": "completed",
          "text": "Education completed"
        },
        {
          "key": "started",
          "text": "Education initiated"
        },
        {
          "key": "followUp",
          "text": "Follow up needed"
        },
        {
          "key": "patientAware",
          "text": "Patient aware independent"
        },
        {
          "key": "resources",
          "text": "Resources given"
        },
        {
          "key": "na",
          "text": "Not applicable"
        }
      ],
      "tableColumn": "28",
      "fqn": "newbornDischargeEd.nbDischEdCareCarry"
    },
    {
      "elementKey": "nbDischEdCareVitD",
      "formIndex": "1",
      "inputType": "checkset",
      "options": [
        {
          "key": "completed",
          "text": "Education completed"
        },
        {
          "key": "started",
          "text": "Education initiated"
        },
        {
          "key": "followUp",
          "text": "Follow up needed"
        },
        {
          "key": "patientAware",
          "text": "Patient aware independent"
        },
        {
          "key": "resources",
          "text": "Resources given"
        },
        {
          "key": "na",
          "text": "Not applicable"
        }
      ],
      "tableColumn": "29",
      "fqn": "newbornDischargeEd.nbDischEdCareVitD"
    },
    {
      "elementKey": "nbDischEdCareJaundice",
      "formIndex": "1",
      "inputType": "checkset",
      "options": [
        {
          "key": "completed",
          "text": "Education completed"
        },
        {
          "key": "started",
          "text": "Education initiated"
        },
        {
          "key": "followUp",
          "text": "Follow up needed"
        },
        {
          "key": "patientAware",
          "text": "Patient aware independent"
        },
        {
          "key": "resources",
          "text": "Resources given"
        },
        {
          "key": "na",
          "text": "Not applicable"
        }
      ],
      "tableColumn": "30",
      "fqn": "newbornDischargeEd.nbDischEdCareJaundice"
    },
    {
      "elementKey": "nbDischEdCareSeekAdvice",
      "formIndex": "1",
      "inputType": "checkset",
      "options": [
        {
          "key": "completed",
          "text": "Education completed"
        },
        {
          "key": "started",
          "text": "Education initiated"
        },
        {
          "key": "followUp",
          "text": "Follow up needed"
        },
        {
          "key": "patientAware",
          "text": "Patient aware independent"
        },
        {
          "key": "resources",
          "text": "Resources given"
        },
        {
          "key": "na",
          "text": "Not applicable"
        }
      ],
      "tableColumn": "31",
      "fqn": "newbornDischargeEd.nbDischEdCareSeekAdvice"
    },
    {
      "elementKey": "nbDischEdCareComments",
      "formIndex": "1",
      "inputType": "textarea",
      "tableColumn": "32",
      "fqn": "newbornDischargeEd.nbDischEdCareComments"
    },
    {
      "elementKey": "nbDischEdScreeningHearing",
      "formIndex": "1",
      "inputType": "checkset",
      "options": [
        {
          "key": "completed",
          "text": "Education completed"
        },
        {
          "key": "started",
          "text": "Education initiated"
        },
        {
          "key": "followUp",
          "text": "Follow up needed"
        },
        {
          "key": "patientAware",
          "text": "Patient aware independent"
        },
        {
          "key": "resources",
          "text": "Resources given"
        },
        {
          "key": "na",
          "text": "Not applicable"
        }
      ],
      "tableColumn": "34",
      "fqn": "newbornDischargeEd.nbDischEdScreeningHearing"
    },
    {
      "elementKey": "nbDischEdScreeningMetabolic",
      "formIndex": "1",
      "inputType": "checkset",
      "options": [
        {
          "key": "completed",
          "text": "Education completed"
        },
        {
          "key": "started",
          "text": "Education initiated"
        },
        {
          "key": "followUp",
          "text": "Follow up needed"
        },
        {
          "key": "patientAware",
          "text": "Patient aware independent"
        },
        {
          "key": "resources",
          "text": "Resources given"
        },
        {
          "key": "na",
          "text": "Not applicable"
        }
      ],
      "tableColumn": "35",
      "fqn": "newbornDischargeEd.nbDischEdScreeningMetabolic"
    },
    {
      "elementKey": "nbDischEdScreeningBilirubin",
      "formIndex": "1",
      "inputType": "checkset",
      "options": [
        {
          "key": "completed",
          "text": "Education completed"
        },
        {
          "key": "started",
          "text": "Education initiated"
        },
        {
          "key": "followUp",
          "text": "Follow up needed"
        },
        {
          "key": "patientAware",
          "text": "Patient aware independent"
        },
        {
          "key": "resources",
          "text": "Resources given"
        },
        {
          "key": "na",
          "text": "Not applicable"
        }
      ],
      "tableColumn": "36",
      "fqn": "newbornDischargeEd.nbDischEdScreeningBilirubin"
    },
    {
      "elementKey": "nbDischEdScreeningCCHD",
      "formIndex": "1",
      "inputType": "checkset",
      "options": [
        {
          "key": "completed",
          "text": "Education completed"
        },
        {
          "key": "started",
          "text": "Education initiated"
        },
        {
          "key": "followUp",
          "text": "Follow up needed"
        },
        {
          "key": "patientAware",
          "text": "Patient aware independent"
        },
        {
          "key": "resources",
          "text": "Resources given"
        },
        {
          "key": "na",
          "text": "Not applicable"
        }
      ],
      "tableColumn": "37",
      "fqn": "newbornDischargeEd.nbDischEdScreeningCCHD"
    },
    {
      "elementKey": "nbDischEdScreeningBiliaryAtresia",
      "formIndex": "1",
      "inputType": "checkset",
      "options": [
        {
          "key": "completed",
          "text": "Education completed"
        },
        {
          "key": "started",
          "text": "Education initiated"
        },
        {
          "key": "followUp",
          "text": "Follow up needed"
        },
        {
          "key": "patientAware",
          "text": "Patient aware independent"
        },
        {
          "key": "resources",
          "text": "Resources given"
        },
        {
          "key": "na",
          "text": "Not applicable"
        }
      ],
      "tableColumn": "38",
      "fqn": "newbornDischargeEd.nbDischEdScreeningBiliaryAtresia"
    },
    {
      "elementKey": "nbDischEdScreeningComments",
      "formIndex": "1",
      "inputType": "textarea",
      "tableColumn": "39",
      "fqn": "newbornDischargeEd.nbDischEdScreeningComments"
    },
    {
      "elementKey": "nbDischEdWt",
      "formIndex": "1",
      "inputType": "checkset",
      "options": [
        {
          "key": "completed",
          "text": "Education completed"
        },
        {
          "key": "started",
          "text": "Education initiated"
        },
        {
          "key": "followUp",
          "text": "Follow up needed"
        },
        {
          "key": "patientAware",
          "text": "Patient aware independent"
        },
        {
          "key": "resources",
          "text": "Resources given"
        },
        {
          "key": "na",
          "text": "Not applicable"
        }
      ],
      "tableColumn": "41",
      "fqn": "newbornDischargeEd.nbDischEdWt"
    },
    {
      "elementKey": "nbDischEdContagious",
      "formIndex": "1",
      "inputType": "checkset",
      "options": [
        {
          "key": "completed",
          "text": "Education completed"
        },
        {
          "key": "started",
          "text": "Education initiated"
        },
        {
          "key": "followUp",
          "text": "Follow up needed"
        },
        {
          "key": "patientAware",
          "text": "Patient aware independent"
        },
        {
          "key": "resources",
          "text": "Resources given"
        },
        {
          "key": "na",
          "text": "Not applicable"
        }
      ],
      "tableColumn": "42",
      "fqn": "newbornDischargeEd.nbDischEdContagious"
    },
    {
      "elementKey": "nbDischEdCareProvider",
      "formIndex": "1",
      "inputType": "checkset",
      "options": [
        {
          "key": "completed",
          "text": "Education completed"
        },
        {
          "key": "started",
          "text": "Education initiated"
        },
        {
          "key": "followUp",
          "text": "Follow up needed"
        },
        {
          "key": "patientAware",
          "text": "Patient aware independent"
        },
        {
          "key": "resources",
          "text": "Resources given"
        },
        {
          "key": "na",
          "text": "Not applicable"
        }
      ],
      "tableColumn": "43",
      "fqn": "newbornDischargeEd.nbDischEdCareProvider"
    },
    {
      "elementKey": "nbDischEdPubHealth",
      "formIndex": "1",
      "inputType": "checkset",
      "options": [
        {
          "key": "completed",
          "text": "Education completed"
        },
        {
          "key": "started",
          "text": "Education initiated"
        },
        {
          "key": "followUp",
          "text": "Follow up needed"
        },
        {
          "key": "patientAware",
          "text": "Patient aware independent"
        },
        {
          "key": "resources",
          "text": "Resources given"
        },
        {
          "key": "na",
          "text": "Not applicable"
        }
      ],
      "tableColumn": "44",
      "fqn": "newbornDischargeEd.nbDischEdPubHealth"
    },
    {
      "elementKey": "nbDischEdPubInfo1",
      "formIndex": "1",
      "inputType": "checkset",
      "options": [
        {
          "key": "completed",
          "text": "Education completed"
        },
        {
          "key": "started",
          "text": "Education initiated"
        },
        {
          "key": "followUp",
          "text": "Follow up needed"
        },
        {
          "key": "patientAware",
          "text": "Patient aware independent"
        },
        {
          "key": "resources",
          "text": "Resources given"
        },
        {
          "key": "na",
          "text": "Not applicable"
        }
      ],
      "tableColumn": "45",
      "fqn": "newbornDischargeEd.nbDischEdPubInfo1"
    },
    {
      "elementKey": "nbDischEdPubInfo2",
      "formIndex": "1",
      "inputType": "checkset",
      "options": [
        {
          "key": "completed",
          "text": "Education completed"
        },
        {
          "key": "started",
          "text": "Education initiated"
        },
        {
          "key": "followUp",
          "text": "Follow up needed"
        },
        {
          "key": "patientAware",
          "text": "Patient aware independent"
        },
        {
          "key": "resources",
          "text": "Resources given"
        },
        {
          "key": "na",
          "text": "Not applicable"
        }
      ],
      "tableColumn": "46",
      "fqn": "newbornDischargeEd.nbDischEdPubInfo2"
    },
    {
      "elementKey": "nbDischEdComments",
      "formIndex": "1",
      "inputType": "textarea",
      "tableColumn": "48",
      "fqn": "newbornDischargeEd.nbDischEdComments"
    }
  ],
  "pageElements": {
    "nbDischEd_form": {
      "elementKey": "nbDischEd_form",
      "pageElementIndex": "1",
      "formKey": "nbDischEd_form",
      "fqn": "newbornDischargeEd.nbDischEd_form",
      "isPageForm": true,
      "ehr_groups": [
        {
          "elementKey": "nbDischEd_hdrGroup",
          "formCss": "record-header",
          "fqn": "newbornDischargeEd.nbDischEd_hdrGroup",
          "gIndex": "1",
          "gChildren": [
            "nbDischEd_form_name",
            "nbDischEd_form_profession",
            "nbDischEd_form_day",
            "nbDischEd_form_time"
          ]
        },
        {
          "elementKey": "nbDischEd_1",
          "formCss": "ehr-row-flow",
          "fqn": "newbornDischargeEd.nbDischEd_1",
          "gIndex": "2",
          "gChildren": [
            "nbDischEdTo",
            "nbDischEdInterpreter",
            "nbDischEdName"
          ]
        },
        {
          "elementKey": "nbDischEdSkin",
          "formCss": "ehr-row-flow",
          "fqn": "newbornDischargeEd.nbDischEdSkin",
          "gIndex": "3",
          "gChildren": [
            "nbDischEdSkinBenefits",
            "nbDischEdSkinSafety",
            "nbDischEdSkinToSkin"
          ]
        },
        {
          "elementKey": "nbDischEdFeedingGrp",
          "formCss": "ehr-row-flow",
          "fqn": "newbornDischargeEd.nbDischEdFeedingGrp",
          "gIndex": "4",
          "gChildren": [
            "nbDischEdFeedingCues",
            "nbDischEdFeedingPosition",
            "nbDischEdFeedingSigns",
            "nbDischEdFeedingLatch",
            "nbDischEdFeedingActive",
            "nbDischEdFeedingMilk",
            "nbDischEdFeedingPlan"
          ]
        },
        {
          "elementKey": "nbDischEd_9",
          "formCss": "grid-left-to-right-1",
          "fqn": "newbornDischargeEd.nbDischEd_9",
          "gIndex": "5",
          "gChildren": [
            "nbDischEdFeedingComments"
          ]
        },
        {
          "elementKey": "nbDischEdCare",
          "formCss": "ehr-row-flow",
          "fqn": "newbornDischargeEd.nbDischEdCare",
          "gIndex": "6",
          "gChildren": [
            "nbDischEdCareBehaviour",
            "nbDischEdCareSleep",
            "nbDischEdCareCrying",
            "nbDischEdCarePreventSBS",
            "nbDischEdCareSmokeFree",
            "nbDischEdCarePreventInjury",
            "nbDischEdCareCarSeat",
            "nbDischEdCareBath",
            "nbDischEdCareCarry",
            "nbDischEdCareVitD",
            "nbDischEdCareJaundice",
            "nbDischEdCareSeekAdvice"
          ]
        },
        {
          "elementKey": "nbDischEd_10",
          "formCss": "grid-left-to-right-1",
          "fqn": "newbornDischargeEd.nbDischEd_10",
          "gIndex": "7",
          "gChildren": [
            "nbDischEdCareComments"
          ]
        },
        {
          "elementKey": "nbDischEdScreeningGrp",
          "formCss": "ehr-row-flow",
          "fqn": "newbornDischargeEd.nbDischEdScreeningGrp",
          "gIndex": "8",
          "gChildren": [
            "nbDischEdScreeningHearing",
            "nbDischEdScreeningMetabolic",
            "nbDischEdScreeningBilirubin",
            "nbDischEdScreeningCCHD",
            "nbDischEdScreeningBiliaryAtresia"
          ]
        },
        {
          "elementKey": "nbDischEd_8",
          "formCss": "grid-left-to-right-1",
          "fqn": "newbornDischargeEd.nbDischEd_8",
          "gIndex": "9",
          "gChildren": [
            "nbDischEdScreeningComments"
          ]
        },
        {
          "elementKey": "nbDischEdTopicsGrp",
          "formCss": "ehr-row-flow",
          "fqn": "newbornDischargeEd.nbDischEdTopicsGrp",
          "gIndex": "10",
          "gChildren": [
            "nbDischEdWt",
            "nbDischEdContagious",
            "nbDischEdCareProvider",
            "nbDischEdPubHealth",
            "nbDischEdPubInfo1",
            "nbDischEdPubInfo2"
          ]
        },
        {
          "elementKey": "nbDischEd_7",
          "formCss": "grid-left-to-right-1",
          "fqn": "newbornDischargeEd.nbDischEd_7",
          "gIndex": "11",
          "gChildren": [
            "nbDischEdComments"
          ]
        }
      ]
    }
  },
  "path": "/ehr/peri-ped/newborn",
  "routeName": "newborn-discharge-ed",
  "label": "Discharge Education",
  "generateComponent": "v2page",
  "featureFlag": "ffPed",
  "ehr": "yes",
  "fullPageKey": "periPed.newborn.newbornDischargeEd"
}
export default DEFS