/* eslint-disable */
import acpMost from '../ehr-definitions/pages/acpMost.js'
import allergies from '../ehr-definitions/pages/allergies.js'
import assessmentTools from '../ehr-definitions/pages/assessmentTools.js'
import assessments from '../ehr-definitions/pages/assessments.js'
import billing from '../ehr-definitions/pages/billing.js'
import biopsychosocial from '../ehr-definitions/pages/biopsychosocial.js'
import cardiovascular from '../ehr-definitions/pages/cardiovascular.js'
import carePlan from '../ehr-definitions/pages/carePlan.js'
import careTeam from '../ehr-definitions/pages/careTeam.js'
import caseMixGroup from '../ehr-definitions/pages/caseMixGroup.js'
import chart from '../ehr-definitions/pages/chart.js'
import checklistsPage from '../ehr-definitions/pages/checklistsPage.js'
import codeLookup from '../ehr-definitions/pages/codeLookup.js'
import consentsPage from '../ehr-definitions/pages/consentsPage.js'
import consults from '../ehr-definitions/pages/consults.js'
import current from '../ehr-definitions/pages/current.js'
import demographics from '../ehr-definitions/pages/demographics.js'
import diagnosticCodes from '../ehr-definitions/pages/diagnosticCodes.js'
import dischargeSummary from '../ehr-definitions/pages/dischargeSummary.js'
import dishargeplan from '../ehr-definitions/pages/dishargeplan.js'
import external from '../ehr-definitions/pages/external.js'
import familyHistory from '../ehr-definitions/pages/familyHistory.js'
import fluidBalance from '../ehr-definitions/pages/fluidBalance.js'
import gastrointestinal from '../ehr-definitions/pages/gastrointestinal.js'
import genitourinary from '../ehr-definitions/pages/genitourinary.js'
import hematology from '../ehr-definitions/pages/hematology.js'
import history from '../ehr-definitions/pages/history.js'
import immunization from '../ehr-definitions/pages/immunization.js'
import integumentaryAssessment from '../ehr-definitions/pages/integumentaryAssessment.js'
import interventionCodes from '../ehr-definitions/pages/interventionCodes.js'
import labRequisitions from '../ehr-definitions/pages/labRequisitions.js'
import labResults from '../ehr-definitions/pages/labResults.js'
import leveyJennings from '../ehr-definitions/pages/leveyJennings.js'
import linesDevices from '../ehr-definitions/pages/linesDevices.js'
import matAntenatal from '../ehr-definitions/pages/matAntenatal.js'
import matDischargeEd from '../ehr-definitions/pages/matDischargeEd.js'
import matPostpartum from '../ehr-definitions/pages/matPostpartum.js'
import matSummary from '../ehr-definitions/pages/matSummary.js'
import maternity from '../ehr-definitions/pages/maternity.js'
import medAdminRec from '../ehr-definitions/pages/medAdminRec.js'
import medLab from '../ehr-definitions/pages/medLab.js'
import medLabAccession from '../ehr-definitions/pages/medLabAccession.js'
import medLabBody from '../ehr-definitions/pages/medLabBody.js'
import medLabChemistry from '../ehr-definitions/pages/medLabChemistry.js'
import medLabPhoneLog from '../ehr-definitions/pages/medLabPhoneLog.js'
import medLabUrinalysis from '../ehr-definitions/pages/medLabUrinalysis.js'
import medical from '../ehr-definitions/pages/medical.js'
import medicalImaging from '../ehr-definitions/pages/medicalImaging.js'
import medicationOrders from '../ehr-definitions/pages/medicationOrders.js'
import musculoskeletal from '../ehr-definitions/pages/musculoskeletal.js'
import neurological from '../ehr-definitions/pages/neurological.js'
import newborn from '../ehr-definitions/pages/newborn.js'
import newbornBath from '../ehr-definitions/pages/newbornBath.js'
import newbornComprehensive from '../ehr-definitions/pages/newbornComprehensive.js'
import newbornDischargeEd from '../ehr-definitions/pages/newbornDischargeEd.js'
import newbornFeeding from '../ehr-definitions/pages/newbornFeeding.js'
import newbornInOut from '../ehr-definitions/pages/newbornInOut.js'
import newbornMeasure from '../ehr-definitions/pages/newbornMeasure.js'
import newbornRec from '../ehr-definitions/pages/newbornRec.js'
import newbornScreening from '../ehr-definitions/pages/newbornScreening.js'
import newbornVitals from '../ehr-definitions/pages/newbornVitals.js'
import nonmedOrders from '../ehr-definitions/pages/nonmedOrders.js'
import operationReports from '../ehr-definitions/pages/operationReports.js'
import orderSets from '../ehr-definitions/pages/orderSets.js'
import orders from '../ehr-definitions/pages/orders.js'
import pain from '../ehr-definitions/pages/pain.js'
import pastAppointments from '../ehr-definitions/pages/pastAppointments.js'
import pedAdmission from '../ehr-definitions/pages/pedAdmission.js'
import pedHourly from '../ehr-definitions/pages/pedHourly.js'
import pedInOut from '../ehr-definitions/pages/pedInOut.js'
import pedMeasure from '../ehr-definitions/pages/pedMeasure.js'
import pedPain from '../ehr-definitions/pages/pedPain.js'
import pedPram from '../ehr-definitions/pages/pedPram.js'
import pedSystem from '../ehr-definitions/pages/pedSystem.js'
import pedVitals from '../ehr-definitions/pages/pedVitals.js'
import pediatrics from '../ehr-definitions/pages/pediatrics.js'
import periPed from '../ehr-definitions/pages/periPed.js'
import progressNotes from '../ehr-definitions/pages/progressNotes.js'
import psychosocial from '../ehr-definitions/pages/psychosocial.js'
import referrals from '../ehr-definitions/pages/referrals.js'
import reports from '../ehr-definitions/pages/reports.js'
import respiratory from '../ehr-definitions/pages/respiratory.js'
import riskAssessment from '../ehr-definitions/pages/riskAssessment.js'
import specialIndicators from '../ehr-definitions/pages/specialIndicators.js'
import surgical from '../ehr-definitions/pages/surgical.js'
import visit from '../ehr-definitions/pages/visit.js'
import vitals from '../ehr-definitions/pages/vitals.js'
const pageDefFileMap = {
   acpMost: acpMost,
   allergies: allergies,
   assessmentTools: assessmentTools,
   assessments: assessments,
   billing: billing,
   biopsychosocial: biopsychosocial,
   cardiovascular: cardiovascular,
   carePlan: carePlan,
   careTeam: careTeam,
   caseMixGroup: caseMixGroup,
   chart: chart,
   checklistsPage: checklistsPage,
   codeLookup: codeLookup,
   consentsPage: consentsPage,
   consults: consults,
   current: current,
   demographics: demographics,
   diagnosticCodes: diagnosticCodes,
   dischargeSummary: dischargeSummary,
   dishargeplan: dishargeplan,
   external: external,
   familyHistory: familyHistory,
   fluidBalance: fluidBalance,
   gastrointestinal: gastrointestinal,
   genitourinary: genitourinary,
   hematology: hematology,
   history: history,
   immunization: immunization,
   integumentaryAssessment: integumentaryAssessment,
   interventionCodes: interventionCodes,
   labRequisitions: labRequisitions,
   labResults: labResults,
   leveyJennings: leveyJennings,
   linesDevices: linesDevices,
   matAntenatal: matAntenatal,
   matDischargeEd: matDischargeEd,
   matPostpartum: matPostpartum,
   matSummary: matSummary,
   maternity: maternity,
   medAdminRec: medAdminRec,
   medLab: medLab,
   medLabAccession: medLabAccession,
   medLabBody: medLabBody,
   medLabChemistry: medLabChemistry,
   medLabPhoneLog: medLabPhoneLog,
   medLabUrinalysis: medLabUrinalysis,
   medical: medical,
   medicalImaging: medicalImaging,
   medicationOrders: medicationOrders,
   musculoskeletal: musculoskeletal,
   neurological: neurological,
   newborn: newborn,
   newbornBath: newbornBath,
   newbornComprehensive: newbornComprehensive,
   newbornDischargeEd: newbornDischargeEd,
   newbornFeeding: newbornFeeding,
   newbornInOut: newbornInOut,
   newbornMeasure: newbornMeasure,
   newbornRec: newbornRec,
   newbornScreening: newbornScreening,
   newbornVitals: newbornVitals,
   nonmedOrders: nonmedOrders,
   operationReports: operationReports,
   orderSets: orderSets,
   orders: orders,
   pain: pain,
   pastAppointments: pastAppointments,
   pedAdmission: pedAdmission,
   pedHourly: pedHourly,
   pedInOut: pedInOut,
   pedMeasure: pedMeasure,
   pedPain: pedPain,
   pedPram: pedPram,
   pedSystem: pedSystem,
   pedVitals: pedVitals,
   pediatrics: pediatrics,
   periPed: periPed,
   progressNotes: progressNotes,
   psychosocial: psychosocial,
   referrals: referrals,
   reports: reports,
   respiratory: respiratory,
   riskAssessment: riskAssessment,
   specialIndicators: specialIndicators,
   surgical: surgical,
   visit: visit,
   vitals: vitals,
}
export default pageDefFileMap