/* eslint-disable */
const DEFS = {
  "pageDataKey": "newbornComprehensive",
  "pIndex": "66",
  "hasGridTable": true,
  "pageChildren": [
    {
      "elementKey": "nbComp_form_name",
      "formIndex": "1",
      "inputType": "practitionerName",
      "tableColumn": "1",
      "recHeader": true,
      "fqn": "newbornComprehensive.name"
    },
    {
      "elementKey": "nbComp_form_id",
      "inputType": "generatedId",
      "tableColumn": 0,
      "label": "Row id",
      "tableCss": "row-id"
    },
    {
      "elementKey": "nbComp_form_profession",
      "formIndex": "1",
      "inputType": "practitionerProfession",
      "tableColumn": "1",
      "recHeader": true,
      "fqn": "newbornComprehensive.profession"
    },
    {
      "elementKey": "nbComp_form_day",
      "formIndex": "1",
      "inputType": "visitDay",
      "mandatory": true,
      "tableColumn": "1",
      "validation": "visitDay",
      "recHeader": true,
      "fqn": "newbornComprehensive.day"
    },
    {
      "elementKey": "nbComp_form_time",
      "formIndex": "1",
      "inputType": "visitTime",
      "mandatory": true,
      "tableColumn": "1",
      "validation": "time24",
      "recHeader": true,
      "fqn": "newbornComprehensive.time"
    },
    {
      "elementKey": "nbCompHeadDefined",
      "formIndex": "1",
      "inputType": "radioset",
      "options": [
        {
          "key": "wdf",
          "text": "Within defined parameters"
        },
        {
          "key": "significantFindings",
          "text": "Significant findings"
        },
        {
          "key": "na",
          "text": "Not applicable"
        },
        {
          "key": "notAssessed",
          "text": "Not assessed"
        }
      ],
      "tableColumn": "3",
      "fqn": "newbornComprehensive.nbCompHeadDefined"
    },
    {
      "elementKey": "nbCompHeadDefinedText",
      "formIndex": "1",
      "inputType": "formInfoText",
      "tableColumn": "4",
      "fqn": "newbornComprehensive.nbCompHeadDefinedText"
    },
    {
      "elementKey": "nbCompHeadDesc",
      "formIndex": "1",
      "inputType": "checkset",
      "options": [
        {
          "key": "asymmetricalEdema",
          "text": "asymmetrical edema"
        },
        {
          "key": "bruising",
          "text": "bruising"
        },
        {
          "key": "caputSuccedaneum",
          "text": "caput succedaneum"
        },
        {
          "key": "cephalohematoma",
          "text": "cephalohematoma"
        },
        {
          "key": "excoriation",
          "text": "excoriation"
        },
        {
          "key": "flat",
          "text": "flat"
        },
        {
          "key": "laceration",
          "text": "laceration"
        },
        {
          "key": "moulding",
          "text": "moulding"
        },
        {
          "key": "round",
          "text": "round"
        },
        {
          "key": "other",
          "text": "other"
        }
      ],
      "tableColumn": "5",
      "fqn": "newbornComprehensive.nbCompHeadDesc"
    },
    {
      "elementKey": "nbCompHeadDescOther",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "6",
      "fqn": "newbornComprehensive.nbCompHeadDescOther"
    },
    {
      "elementKey": "nbCompHeadAnteriorCranial",
      "formIndex": "1",
      "inputType": "radioset",
      "options": [
        {
          "key": "approximated",
          "text": "Approximated"
        },
        {
          "key": "overriding",
          "text": "Overriding"
        },
        {
          "key": "separated",
          "text": "Separated"
        },
        {
          "key": "other",
          "text": "Other'"
        }
      ],
      "tableColumn": "7",
      "fqn": "newbornComprehensive.nbCompHeadAnteriorCranial"
    },
    {
      "elementKey": "nbCompHeadPosterior",
      "formIndex": "1",
      "inputType": "radioset",
      "options": [
        {
          "key": "approximated",
          "text": "Approximated"
        },
        {
          "key": "overriding",
          "text": "Overriding"
        },
        {
          "key": "separated",
          "text": "Separated"
        },
        {
          "key": "other",
          "text": "Other"
        }
      ],
      "tableColumn": "8",
      "fqn": "newbornComprehensive.nbCompHeadPosterior"
    },
    {
      "elementKey": "nbCompHeadAnteriorFontanelles",
      "formIndex": "1",
      "inputType": "checkset",
      "options": [
        {
          "key": "soft",
          "text": "Soft"
        },
        {
          "key": "flat",
          "text": "Flat"
        },
        {
          "key": "full",
          "text": "Full"
        },
        {
          "key": "tense",
          "text": "Tense"
        },
        {
          "key": "bulging",
          "text": "Bulging"
        },
        {
          "key": "depressed",
          "text": "Depressed"
        },
        {
          "key": "other",
          "text": "Other"
        }
      ],
      "tableColumn": "9",
      "fqn": "newbornComprehensive.nbCompHeadAnteriorFontanelles"
    },
    {
      "elementKey": "nbCompHeadAnteriorFontanellesOther",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "10",
      "fqn": "newbornComprehensive.nbCompHeadAnteriorFontanellesOther"
    },
    {
      "elementKey": "nbCompHeadPosteriorFontanelles",
      "formIndex": "1",
      "inputType": "checkset",
      "options": [
        {
          "key": "soft",
          "text": "Soft"
        },
        {
          "key": "flat",
          "text": "Flat"
        },
        {
          "key": "full",
          "text": "Full"
        },
        {
          "key": "tense",
          "text": "Tense"
        },
        {
          "key": "bulging",
          "text": "Bulging"
        },
        {
          "key": "depressed",
          "text": "Depressed"
        },
        {
          "key": "other",
          "text": "Other"
        }
      ],
      "tableColumn": "11",
      "fqn": "newbornComprehensive.nbCompHeadPosteriorFontanelles"
    },
    {
      "elementKey": "nbCompHeadPosteriorFontanellesOther",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "12",
      "fqn": "newbornComprehensive.nbCompHeadPosteriorFontanellesOther"
    },
    {
      "elementKey": "nbCompHeadComments",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "13",
      "fqn": "newbornComprehensive.nbCompHeadComments"
    },
    {
      "elementKey": "nbCompEyesDefined",
      "formIndex": "1",
      "inputType": "radioset",
      "options": [
        {
          "key": "wdf",
          "text": "Within defined parameters"
        },
        {
          "key": "significantFindings",
          "text": "Significant findings"
        },
        {
          "key": "na",
          "text": "Not applicable"
        },
        {
          "key": "notAssessed",
          "text": "Not assessed"
        }
      ],
      "tableColumn": "15",
      "fqn": "newbornComprehensive.nbCompEyesDefined"
    },
    {
      "elementKey": "nbCompEyesDefinedText",
      "formIndex": "1",
      "inputType": "formInfoText",
      "tableColumn": "16",
      "fqn": "newbornComprehensive.nbCompEyesDefinedText"
    },
    {
      "elementKey": "nbCompEarsDefined",
      "formIndex": "1",
      "inputType": "radioset",
      "options": [
        {
          "key": "wdf",
          "text": "Within defined parameters"
        },
        {
          "key": "significantFindings",
          "text": "Significant findings"
        },
        {
          "key": "na",
          "text": "Not applicable"
        },
        {
          "key": "notAssessed",
          "text": "Not assessed"
        }
      ],
      "tableColumn": "18",
      "fqn": "newbornComprehensive.nbCompEarsDefined"
    },
    {
      "elementKey": "nbCompEarsDefinedText",
      "formIndex": "1",
      "inputType": "formInfoText",
      "tableColumn": "19",
      "fqn": "newbornComprehensive.nbCompEarsDefinedText"
    },
    {
      "elementKey": "nbCompNoseDefined",
      "formIndex": "1",
      "inputType": "radioset",
      "options": [
        {
          "key": "wdf",
          "text": "Within defined parameters"
        },
        {
          "key": "significantFindings",
          "text": "Significant findings"
        },
        {
          "key": "na",
          "text": "Not applicable"
        },
        {
          "key": "notAssessed",
          "text": "Not assessed"
        }
      ],
      "tableColumn": "21",
      "fqn": "newbornComprehensive.nbCompNoseDefined"
    },
    {
      "elementKey": "nbCompNoseDefinedText",
      "formIndex": "1",
      "inputType": "formInfoText",
      "tableColumn": "22",
      "fqn": "newbornComprehensive.nbCompNoseDefinedText"
    },
    {
      "elementKey": "nbCompNoseAssConcerns",
      "formIndex": "1",
      "inputType": "checkset",
      "options": [
        {
          "key": "asymmetrical",
          "text": "Asymmetrical"
        },
        {
          "key": "nasalDischarge",
          "text": "Nasal Discharge"
        },
        {
          "key": "nasalFlaring",
          "text": "Nasal Flaring"
        },
        {
          "key": "other",
          "text": "Other"
        }
      ],
      "tableColumn": "23",
      "fqn": "newbornComprehensive.nbCompNoseAssConcerns"
    },
    {
      "elementKey": "nbCompNoseAssConcernsOther",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "24",
      "fqn": "newbornComprehensive.nbCompNoseAssConcernsOther"
    },
    {
      "elementKey": "nbCompNoseAssInterventions",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "25",
      "fqn": "newbornComprehensive.nbCompNoseAssInterventions"
    },
    {
      "elementKey": "nbCompNoseAssComments",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "26",
      "fqn": "newbornComprehensive.nbCompNoseAssComments"
    },
    {
      "elementKey": "nbCompOralDefined",
      "formIndex": "1",
      "inputType": "radioset",
      "options": [
        {
          "key": "wdf",
          "text": "Within defined parameters"
        },
        {
          "key": "significantFindings",
          "text": "Significant findings"
        },
        {
          "key": "na",
          "text": "Not applicable"
        },
        {
          "key": "notAssessed",
          "text": "Not assessed"
        }
      ],
      "tableColumn": "28",
      "fqn": "newbornComprehensive.nbCompOralDefined"
    },
    {
      "elementKey": "nbCompOralDefinedText",
      "formIndex": "1",
      "inputType": "formInfoText",
      "tableColumn": "29",
      "fqn": "newbornComprehensive.nbCompOralDefinedText"
    },
    {
      "elementKey": "nbCompOralConcerns",
      "formIndex": "1",
      "inputType": "checkset",
      "options": [
        {
          "key": "dryMucosa",
          "text": "Dry mucosa"
        },
        {
          "key": "tongueTightFrenulum",
          "text": "Tongue tie/tight frenulum"
        },
        {
          "key": "heartShape",
          "text": "Heart shaped tongue"
        },
        {
          "key": "receding",
          "text": "Receding lower jaw"
        },
        {
          "key": "large",
          "text": "Large tongue"
        },
        {
          "key": "other",
          "text": "Other"
        }
      ],
      "tableColumn": "30",
      "fqn": "newbornComprehensive.nbCompOralConcerns"
    },
    {
      "elementKey": "nbCompOralConcernsOther",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "31",
      "fqn": "newbornComprehensive.nbCompOralConcernsOther"
    },
    {
      "elementKey": "nbCompOralAnomalies",
      "formIndex": "1",
      "inputType": "checkset",
      "options": [
        {
          "key": "none",
          "text": "None"
        },
        {
          "key": "cleftLip",
          "text": "Cleft lip"
        },
        {
          "key": "cleftPalate",
          "text": "Cleft palate"
        },
        {
          "key": "neonatalTeeth",
          "text": "neonatal teeth"
        },
        {
          "key": "other",
          "text": "Other"
        }
      ],
      "tableColumn": "32",
      "fqn": "newbornComprehensive.nbCompOralAnomalies"
    },
    {
      "elementKey": "nbCompOralAnomaliesComments",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "33",
      "fqn": "newbornComprehensive.nbCompOralAnomaliesComments"
    },
    {
      "elementKey": "nbCompOralComments",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "34",
      "fqn": "newbornComprehensive.nbCompOralComments"
    },
    {
      "elementKey": "nbCompHeentCmmt",
      "formIndex": "1",
      "inputType": "textarea",
      "tableColumn": "36",
      "fqn": "newbornComprehensive.nbCompHeentCmmt"
    },
    {
      "elementKey": "nbCompCcr_form_name",
      "formIndex": "2",
      "inputType": "practitionerName",
      "tableColumn": "1",
      "recHeader": true,
      "fqn": "newbornComprehensive.name"
    },
    {
      "elementKey": "nbCompCcr_form_id",
      "inputType": "generatedId",
      "tableColumn": 0,
      "label": "Row id",
      "tableCss": "row-id"
    },
    {
      "elementKey": "nbCompCcr_form_profession",
      "formIndex": "2",
      "inputType": "practitionerProfession",
      "tableColumn": "1",
      "recHeader": true,
      "fqn": "newbornComprehensive.profession"
    },
    {
      "elementKey": "nbCompCcr_form_day",
      "formIndex": "2",
      "inputType": "visitDay",
      "mandatory": true,
      "tableColumn": "1",
      "validation": "visitDay",
      "recHeader": true,
      "fqn": "newbornComprehensive.day"
    },
    {
      "elementKey": "nbCompCcr_form_time",
      "formIndex": "2",
      "inputType": "visitTime",
      "mandatory": true,
      "tableColumn": "1",
      "validation": "time24",
      "recHeader": true,
      "fqn": "newbornComprehensive.time"
    },
    {
      "elementKey": "nbCompCcrDefined",
      "formIndex": "2",
      "inputType": "radioset",
      "options": [
        {
          "key": "wdf",
          "text": "Within defined parameters"
        },
        {
          "key": "significantFindings",
          "text": "Significant findings"
        },
        {
          "key": "na",
          "text": "Not applicable"
        },
        {
          "key": "notAssessed",
          "text": "Not assessed"
        }
      ],
      "tableColumn": "3",
      "fqn": "newbornComprehensive.nbCompCcrDefined"
    },
    {
      "elementKey": "nbCompCcrDefinedText",
      "formIndex": "2",
      "inputType": "formInfoText",
      "tableColumn": "4",
      "fqn": "newbornComprehensive.nbCompCcrDefinedText"
    },
    {
      "elementKey": "nbCompCcrIndicators",
      "formIndex": "2",
      "inputType": "checkset",
      "options": [
        {
          "key": "none",
          "text": "None"
        },
        {
          "key": "audibleWheeze",
          "text": "Audible wheeze"
        },
        {
          "key": "intercostal",
          "text": "Intercostal retractions"
        },
        {
          "key": "nasal",
          "text": "Nasal flaring"
        },
        {
          "key": "substernalRetractions",
          "text": "Substernal retractions"
        },
        {
          "key": "sternalRetractions",
          "text": "Sternal retractions"
        },
        {
          "key": "other",
          "text": "Other"
        },
        {
          "key": "audibleStridor",
          "text": "Audible stridor"
        },
        {
          "key": "grunting",
          "text": "Grunting"
        },
        {
          "key": "nasalCongestion",
          "text": "Nasal congestion"
        },
        {
          "key": "subcostalRetractions",
          "text": "Subcostal retractions"
        },
        {
          "key": "supraclavicularRetraction",
          "text": "Supraclavicular retraction"
        },
        {
          "key": "trachealTug",
          "text": "Tracheal tug"
        }
      ],
      "tableColumn": "5",
      "fqn": "newbornComprehensive.nbCompCcrIndicators"
    },
    {
      "elementKey": "nbCompCcrIndicatorsOther",
      "formIndex": "2",
      "inputType": "text",
      "tableColumn": "6",
      "fqn": "newbornComprehensive.nbCompCcrIndicatorsOther"
    },
    {
      "elementKey": "nbCompCcrChest",
      "formIndex": "2",
      "inputType": "radioset",
      "options": [
        {
          "key": "symmetrical",
          "text": "Symmetrical"
        },
        {
          "key": "rightGreater",
          "text": "Right greater than left"
        },
        {
          "key": "leftGreater",
          "text": "Left greater than right"
        },
        {
          "key": "undetermined",
          "text": "Undetermined vent mode"
        },
        {
          "key": "other",
          "text": "Other"
        }
      ],
      "tableColumn": "7",
      "fqn": "newbornComprehensive.nbCompCcrChest"
    },
    {
      "elementKey": "nbCompCcrDepth",
      "formIndex": "2",
      "inputType": "radioset",
      "options": [
        {
          "key": "normal",
          "text": "Normal depth"
        },
        {
          "key": "shallow",
          "text": "Shallow"
        },
        {
          "key": "deep",
          "text": "Deep"
        }
      ],
      "tableColumn": "8",
      "fqn": "newbornComprehensive.nbCompCcrDepth"
    },
    {
      "elementKey": "nbCompCcrPattern",
      "formIndex": "2",
      "inputType": "radioset",
      "options": [
        {
          "key": "regular",
          "text": "Regular"
        },
        {
          "key": "irregular",
          "text": "Irregular"
        },
        {
          "key": "periodic",
          "text": "Periodic"
        },
        {
          "key": "overriging",
          "text": "Overriding"
        },
        {
          "key": "synchronous",
          "text": "Synchronous"
        },
        {
          "key": "asynchronous",
          "text": "Asynchronous"
        }
      ],
      "tableColumn": "9",
      "fqn": "newbornComprehensive.nbCompCcrPattern"
    },
    {
      "elementKey": "nbCompCcrWork",
      "formIndex": "2",
      "inputType": "radioset",
      "options": [
        {
          "key": "normal",
          "text": "Normal"
        },
        {
          "key": "mild",
          "text": "Mild"
        },
        {
          "key": "moderate",
          "text": "Moderate"
        },
        {
          "key": "severe",
          "text": "Severe"
        },
        {
          "key": "other",
          "text": "Other"
        }
      ],
      "tableColumn": "10",
      "fqn": "newbornComprehensive.nbCompCcrWork"
    },
    {
      "elementKey": "nbCompCcrSounds",
      "formIndex": "2",
      "inputType": "checkset",
      "options": [
        {
          "key": "clear",
          "text": "Clear to base"
        },
        {
          "key": "fineCrackles",
          "text": "Fine crackles to base"
        },
        {
          "key": "wheezes",
          "text": "Wheezes throughout"
        },
        {
          "key": "bronchial",
          "text": "Bronchial"
        },
        {
          "key": "absent",
          "text": "Absent"
        },
        {
          "key": "posteriorChest",
          "text": "Posterior chest"
        },
        {
          "key": "onInspiration",
          "text": "On inspiration"
        },
        {
          "key": "other",
          "text": "Other"
        },
        {
          "key": "decreased",
          "text": "Decreased to base"
        },
        {
          "key": "coarseCrackles",
          "text": "Coarse crackles to base"
        },
        {
          "key": "rhonchi",
          "text": "Rhonchi"
        },
        {
          "key": "pleuralRub",
          "text": "Pleural rub"
        },
        {
          "key": "anteriorChest",
          "text": "Anterior chest"
        },
        {
          "key": "lateralChest",
          "text": "Lateral chest"
        },
        {
          "key": "onExpiration",
          "text": "On expiration"
        }
      ],
      "tableColumn": "11",
      "fqn": "newbornComprehensive.nbCompCcrSounds"
    },
    {
      "elementKey": "nbCompCcrSoundsComments",
      "formIndex": "2",
      "inputType": "text",
      "tableColumn": "12",
      "fqn": "newbornComprehensive.nbCompCcrSoundsComments"
    },
    {
      "elementKey": "nbCompCcrColour",
      "formIndex": "2",
      "inputType": "checkset",
      "options": [
        {
          "key": "clear",
          "text": "Clear"
        },
        {
          "key": "white",
          "text": "White"
        },
        {
          "key": "creamy",
          "text": "Creamy"
        },
        {
          "key": "yellow",
          "text": "Yellow"
        },
        {
          "key": "green",
          "text": "Green"
        },
        {
          "key": "bloodStreaked",
          "text": "Blood streaked"
        },
        {
          "key": "other",
          "text": "Other"
        }
      ],
      "tableColumn": "13",
      "fqn": "newbornComprehensive.nbCompCcrColour"
    },
    {
      "elementKey": "nbCompCcrColourOther",
      "formIndex": "2",
      "inputType": "text",
      "tableColumn": "14",
      "fqn": "newbornComprehensive.nbCompCcrColourOther"
    },
    {
      "elementKey": "nbCompCcrConsistency",
      "formIndex": "2",
      "inputType": "checkset",
      "options": [
        {
          "key": "thin",
          "text": "Thin"
        },
        {
          "key": "thick",
          "text": "Thick"
        },
        {
          "key": "stringy",
          "text": "Stringy"
        },
        {
          "key": "other",
          "text": "Other"
        }
      ],
      "tableColumn": "15",
      "fqn": "newbornComprehensive.nbCompCcrConsistency"
    },
    {
      "elementKey": "nbCompCcrConsistencyOther",
      "formIndex": "2",
      "inputType": "text",
      "tableColumn": "16",
      "fqn": "newbornComprehensive.nbCompCcrConsistencyOther"
    },
    {
      "elementKey": "nbCompCcrSource",
      "formIndex": "2",
      "inputType": "checkset",
      "options": [
        {
          "key": "expectorated",
          "text": "Expectorated"
        },
        {
          "key": "nasal",
          "text": "Nasal"
        },
        {
          "key": "nasopharyngeal",
          "text": "Nasopharyngeal"
        },
        {
          "key": "oropharyngeal",
          "text": "Oropharyngeal"
        }
      ],
      "tableColumn": "17",
      "fqn": "newbornComprehensive.nbCompCcrSource"
    },
    {
      "elementKey": "nbCompCcrChestAppearance",
      "formIndex": "2",
      "inputType": "text",
      "tableColumn": "17",
      "fqn": "newbornComprehensive.nbCompCcrChestAppearance"
    },
    {
      "elementKey": "nbCompCcrRhythm",
      "formIndex": "2",
      "inputType": "radioset",
      "options": [
        {
          "key": "regular",
          "text": "Regular"
        },
        {
          "key": "irregular",
          "text": "Irregular"
        },
        {
          "key": "gallop",
          "text": "Gallop"
        },
        {
          "key": "other",
          "text": "Other"
        }
      ],
      "tableColumn": "18",
      "fqn": "newbornComprehensive.nbCompCcrRhythm"
    },
    {
      "elementKey": "nbCompCcrMurmur",
      "formIndex": "2",
      "inputType": "radioset",
      "options": [
        {
          "key": "noMurmur",
          "text": "No murmur"
        },
        {
          "key": "audible",
          "text": "Audible murmur"
        },
        {
          "key": "other",
          "text": "Other"
        }
      ],
      "tableColumn": "19",
      "fqn": "newbornComprehensive.nbCompCcrMurmur"
    },
    {
      "elementKey": "nbCompCcrOtherComments",
      "formIndex": "2",
      "inputType": "text",
      "tableColumn": "20",
      "fqn": "newbornComprehensive.nbCompCcrOtherComments"
    },
    {
      "elementKey": "nbCompCctCmmt",
      "formIndex": "2",
      "inputType": "textarea",
      "tableColumn": "22",
      "fqn": "newbornComprehensive.nbCompCctCmmt"
    },
    {
      "elementKey": "nbAbdomen_form_name",
      "formIndex": "3",
      "inputType": "practitionerName",
      "tableColumn": "1",
      "recHeader": true,
      "fqn": "newbornComprehensive.name"
    },
    {
      "elementKey": "nbAbdomen_form_id",
      "inputType": "generatedId",
      "tableColumn": 0,
      "label": "Row id",
      "tableCss": "row-id"
    },
    {
      "elementKey": "nbAbdomen_form_profession",
      "formIndex": "3",
      "inputType": "practitionerProfession",
      "tableColumn": "1",
      "recHeader": true,
      "fqn": "newbornComprehensive.profession"
    },
    {
      "elementKey": "nbAbdomen_form_day",
      "formIndex": "3",
      "inputType": "visitDay",
      "mandatory": true,
      "tableColumn": "1",
      "validation": "visitDay",
      "recHeader": true,
      "fqn": "newbornComprehensive.day"
    },
    {
      "elementKey": "nbAbdomen_form_time",
      "formIndex": "3",
      "inputType": "visitTime",
      "mandatory": true,
      "tableColumn": "1",
      "validation": "time24",
      "recHeader": true,
      "fqn": "newbornComprehensive.time"
    },
    {
      "elementKey": "nbCompAbdDefined",
      "formIndex": "3",
      "inputType": "radioset",
      "options": [
        {
          "key": "wdf",
          "text": "Within defined parameters"
        },
        {
          "key": "significantFindings",
          "text": "Significant findings"
        },
        {
          "key": "na",
          "text": "Not applicable"
        },
        {
          "key": "notAssessed",
          "text": "Not assessed"
        }
      ],
      "tableColumn": "3",
      "fqn": "newbornComprehensive.nbCompAbdDefined"
    },
    {
      "elementKey": "nbCompAbdDefinedText",
      "formIndex": "3",
      "inputType": "formInfoText",
      "tableColumn": "4",
      "fqn": "newbornComprehensive.nbCompAbdDefinedText"
    },
    {
      "elementKey": "nbCompAbdDesc",
      "formIndex": "3",
      "inputType": "checkset",
      "options": [
        {
          "key": "distended",
          "text": "Distended"
        },
        {
          "key": "round",
          "text": "Round"
        },
        {
          "key": "duskyBlue",
          "text": "Dusky blue"
        },
        {
          "key": "red",
          "text": "Red"
        },
        {
          "key": "ropey",
          "text": "Ropey"
        },
        {
          "key": "other",
          "text": "Other"
        }
      ],
      "tableColumn": "5",
      "fqn": "newbornComprehensive.nbCompAbdDesc"
    },
    {
      "elementKey": "nbCompAbdDescOther",
      "formIndex": "3",
      "inputType": "text",
      "tableColumn": "6",
      "fqn": "newbornComprehensive.nbCompAbdDescOther"
    },
    {
      "elementKey": "nbCompAbdPalp",
      "formIndex": "3",
      "inputType": "checkset",
      "options": [
        {
          "key": "soft",
          "text": "Soft"
        },
        {
          "key": "nonTender",
          "text": "Non-tender"
        },
        {
          "key": "tender",
          "text": "Tender"
        },
        {
          "key": "mass",
          "text": "Mass"
        },
        {
          "key": "guarding",
          "text": "Guarding"
        },
        {
          "key": "pulsableMass",
          "text": "Pulsable mass"
        },
        {
          "key": "firm",
          "text": "Firm"
        },
        {
          "key": "other",
          "text": "Other"
        }
      ],
      "tableColumn": "7",
      "fqn": "newbornComprehensive.nbCompAbdPalp"
    },
    {
      "elementKey": "nbCompAbdPalpOther",
      "formIndex": "3",
      "inputType": "text",
      "tableColumn": "8",
      "fqn": "newbornComprehensive.nbCompAbdPalpOther"
    },
    {
      "elementKey": "nbCompAbdSounds",
      "formIndex": "3",
      "inputType": "radioset",
      "options": [
        {
          "key": "active",
          "text": "Active"
        },
        {
          "key": "hypoactive",
          "text": "Hypoactive"
        },
        {
          "key": "hyperactive",
          "text": "Hyperactive"
        },
        {
          "key": "absent",
          "text": "Absent"
        },
        {
          "key": "other",
          "text": "Other"
        }
      ],
      "tableColumn": "9",
      "fqn": "newbornComprehensive.nbCompAbdSounds"
    },
    {
      "elementKey": "nbCompAbdOther",
      "formIndex": "3",
      "inputType": "text",
      "tableColumn": "10",
      "fqn": "newbornComprehensive.nbCompAbdOther"
    },
    {
      "elementKey": "nbCompUmbilicusDefined",
      "formIndex": "3",
      "inputType": "radioset",
      "options": [
        {
          "key": "wdf",
          "text": "Within defined parameters"
        },
        {
          "key": "significantFindings",
          "text": "Significant findings"
        },
        {
          "key": "na",
          "text": "Not applicable"
        },
        {
          "key": "notAssessed",
          "text": "Not assessed"
        }
      ],
      "tableColumn": "12",
      "fqn": "newbornComprehensive.nbCompUmbilicusDefined"
    },
    {
      "elementKey": "nbCompUmbilicusDefinedText",
      "formIndex": "3",
      "inputType": "formInfoText",
      "tableColumn": "13",
      "fqn": "newbornComprehensive.nbCompUmbilicusDefinedText"
    },
    {
      "elementKey": "nbCompUmbilicusCmmt",
      "formIndex": "3",
      "inputType": "textarea",
      "tableColumn": "15",
      "fqn": "newbornComprehensive.nbCompUmbilicusCmmt"
    },
    {
      "elementKey": "nbCompSkin_form_name",
      "formIndex": "4",
      "inputType": "practitionerName",
      "tableColumn": "1",
      "recHeader": true,
      "fqn": "newbornComprehensive.name"
    },
    {
      "elementKey": "nbCompSkin_form_id",
      "inputType": "generatedId",
      "tableColumn": 0,
      "label": "Row id",
      "tableCss": "row-id"
    },
    {
      "elementKey": "nbCompSkin_form_profession",
      "formIndex": "4",
      "inputType": "practitionerProfession",
      "tableColumn": "1",
      "recHeader": true,
      "fqn": "newbornComprehensive.profession"
    },
    {
      "elementKey": "nbCompSkin_form_day",
      "formIndex": "4",
      "inputType": "visitDay",
      "mandatory": true,
      "tableColumn": "1",
      "validation": "visitDay",
      "recHeader": true,
      "fqn": "newbornComprehensive.day"
    },
    {
      "elementKey": "nbCompSkin_form_time",
      "formIndex": "4",
      "inputType": "visitTime",
      "mandatory": true,
      "tableColumn": "1",
      "validation": "time24",
      "recHeader": true,
      "fqn": "newbornComprehensive.time"
    },
    {
      "elementKey": "nbCompSkinDefined",
      "formIndex": "4",
      "inputType": "radioset",
      "options": [
        {
          "key": "wdf",
          "text": "Within defined parameters"
        },
        {
          "key": "significantFindings",
          "text": "Significant findings"
        },
        {
          "key": "na",
          "text": "Not applicable"
        },
        {
          "key": "notAssessed",
          "text": "Not assessed"
        }
      ],
      "tableColumn": "3",
      "fqn": "newbornComprehensive.nbCompSkinDefined"
    },
    {
      "elementKey": "nbCompSkinDefinedText",
      "formIndex": "4",
      "inputType": "formInfoText",
      "tableColumn": "4",
      "fqn": "newbornComprehensive.nbCompSkinDefinedText"
    },
    {
      "elementKey": "nbCompSkinClrCentral",
      "formIndex": "4",
      "inputType": "checkset",
      "options": [
        {
          "key": "pink",
          "text": "Pink"
        },
        {
          "key": "plethoric",
          "text": "Plethoric"
        },
        {
          "key": "cyanotic",
          "text": "Cyanotic"
        },
        {
          "key": "pale",
          "text": "Pale"
        },
        {
          "key": "jaundice",
          "text": "Jaundice"
        },
        {
          "key": "mottled",
          "text": "Mottled"
        }
      ],
      "tableColumn": "5",
      "fqn": "newbornComprehensive.nbCompSkinClrCentral"
    },
    {
      "elementKey": "nbCompSkinClrPeripheral",
      "formIndex": "4",
      "inputType": "checkset",
      "options": [
        {
          "key": "pink",
          "text": "Pink"
        },
        {
          "key": "plethoric",
          "text": "Plethoric"
        },
        {
          "key": "cyanotic",
          "text": "Cyanotic"
        },
        {
          "key": "pale",
          "text": "Pale"
        },
        {
          "key": "jaundice",
          "text": "Jaundice"
        },
        {
          "key": "mottled",
          "text": "Mottled"
        },
        {
          "key": "acrocyanotic",
          "text": "Acrocyanotic"
        }
      ],
      "tableColumn": "6",
      "fqn": "newbornComprehensive.nbCompSkinClrPeripheral"
    },
    {
      "elementKey": "nbCompSkinMucous",
      "formIndex": "4",
      "inputType": "radioset",
      "options": [
        {
          "key": "moist",
          "text": "Moist"
        },
        {
          "key": "dry",
          "text": "Dry"
        }
      ],
      "tableColumn": "7",
      "fqn": "newbornComprehensive.nbCompSkinMucous"
    },
    {
      "elementKey": "nbCompSkinTurgor",
      "formIndex": "4",
      "inputType": "radioset",
      "options": [
        {
          "key": "turgorElastic",
          "text": "Turgor elastic"
        },
        {
          "key": "turgorNonElastic",
          "text": "Turgor non-elastic"
        }
      ],
      "tableColumn": "8",
      "fqn": "newbornComprehensive.nbCompSkinTurgor"
    },
    {
      "elementKey": "nbCompSkinCondition",
      "formIndex": "4",
      "inputType": "checkset",
      "options": [
        {
          "key": "clear",
          "text": "Clear and intact"
        },
        {
          "key": "bruising",
          "text": "Bruising"
        },
        {
          "key": "excoriation",
          "text": "Excoriation"
        },
        {
          "key": "fragile",
          "text": "Fragile"
        },
        {
          "key": "incision",
          "text": "Incision"
        },
        {
          "key": "peeling",
          "text": "Peeling"
        },
        {
          "key": "rash",
          "text": "Rash"
        },
        {
          "key": "redness",
          "text": "Redness"
        },
        {
          "key": "wound",
          "text": "Wound"
        },
        {
          "key": "other",
          "text": "Other"
        }
      ],
      "tableColumn": "9",
      "fqn": "newbornComprehensive.nbCompSkinCondition"
    },
    {
      "elementKey": "nbCompSkinConditionComments",
      "formIndex": "4",
      "inputType": "text",
      "tableColumn": "10",
      "fqn": "newbornComprehensive.nbCompSkinConditionComments"
    },
    {
      "elementKey": "nbCompSkinInterventions",
      "formIndex": "4",
      "inputType": "checkset",
      "options": [
        {
          "key": "none",
          "text": "None"
        },
        {
          "key": "barrierCream",
          "text": "Barrier cream applied"
        },
        {
          "key": "cleansed",
          "text": "Cleansed"
        },
        {
          "key": "creamApplied",
          "text": "Cream applied"
        },
        {
          "key": "open",
          "text": "Open to air"
        },
        {
          "key": "woundAssessment",
          "text": "Wound assessment and treatment plan"
        },
        {
          "key": "other",
          "text": "Other"
        }
      ],
      "tableColumn": "11",
      "fqn": "newbornComprehensive.nbCompSkinInterventions"
    },
    {
      "elementKey": "nbCompSkinInterventionsComments",
      "formIndex": "4",
      "inputType": "text",
      "tableColumn": "12",
      "fqn": "newbornComprehensive.nbCompSkinInterventionsComments"
    },
    {
      "elementKey": "nbCompSkinAnomalies",
      "formIndex": "4",
      "inputType": "checkset",
      "options": [
        {
          "key": "none",
          "text": "None"
        },
        {
          "key": "birthMark",
          "text": "Birth mark"
        },
        {
          "key": "harlequin",
          "text": "Harlequin sign"
        },
        {
          "key": "hemangioma",
          "text": "Hemangioma"
        },
        {
          "key": "mongolia",
          "text": "Mongolian spot"
        },
        {
          "key": "sacralDimple",
          "text": "Sacral dimple"
        },
        {
          "key": "other",
          "text": "Other"
        }
      ],
      "tableColumn": "13",
      "fqn": "newbornComprehensive.nbCompSkinAnomalies"
    },
    {
      "elementKey": "nbCompSkinAnomaliesOther",
      "formIndex": "4",
      "inputType": "text",
      "tableColumn": "14",
      "fqn": "newbornComprehensive.nbCompSkinAnomaliesOther"
    },
    {
      "elementKey": "nbCompSkinComments",
      "formIndex": "4",
      "inputType": "text",
      "tableColumn": "15",
      "fqn": "newbornComprehensive.nbCompSkinComments"
    },
    {
      "elementKey": "nbCompSkinCmmt",
      "formIndex": "4",
      "inputType": "textarea",
      "tableColumn": "17",
      "fqn": "newbornComprehensive.nbCompSkinCmmt"
    },
    {
      "elementKey": "nbCompSkeletal_form_name",
      "formIndex": "5",
      "inputType": "practitionerName",
      "tableColumn": "1",
      "recHeader": true,
      "fqn": "newbornComprehensive.name"
    },
    {
      "elementKey": "nbCompSkeletal_form_id",
      "inputType": "generatedId",
      "tableColumn": 0,
      "label": "Row id",
      "tableCss": "row-id"
    },
    {
      "elementKey": "nbCompSkeletal_form_profession",
      "formIndex": "5",
      "inputType": "practitionerProfession",
      "tableColumn": "1",
      "recHeader": true,
      "fqn": "newbornComprehensive.profession"
    },
    {
      "elementKey": "nbCompSkeletal_form_day",
      "formIndex": "5",
      "inputType": "visitDay",
      "mandatory": true,
      "tableColumn": "1",
      "validation": "visitDay",
      "recHeader": true,
      "fqn": "newbornComprehensive.day"
    },
    {
      "elementKey": "nbCompSkeletal_form_time",
      "formIndex": "5",
      "inputType": "visitTime",
      "mandatory": true,
      "tableColumn": "1",
      "validation": "time24",
      "recHeader": true,
      "fqn": "newbornComprehensive.time"
    },
    {
      "elementKey": "nbCompSkeletalDefined",
      "formIndex": "5",
      "inputType": "radioset",
      "options": [
        {
          "key": "wdf",
          "text": "Within defined parameters"
        },
        {
          "key": "significantFindings",
          "text": "Significant findings"
        },
        {
          "key": "na",
          "text": "Not applicable"
        },
        {
          "key": "notAssessed",
          "text": "Not assessed"
        }
      ],
      "tableColumn": "3",
      "fqn": "newbornComprehensive.nbCompSkeletalDefined"
    },
    {
      "elementKey": "nbCompSkeletalDefinedText",
      "formIndex": "5",
      "inputType": "formInfoText",
      "tableColumn": "4",
      "fqn": "newbornComprehensive.nbCompSkeletalDefinedText"
    },
    {
      "elementKey": "nbCompSkeletalAppearance",
      "formIndex": "5",
      "inputType": "checkset",
      "options": [
        {
          "key": "asymmetricalExtremities",
          "text": "Asymmetrical extremities"
        },
        {
          "key": "unequalLimbMovement",
          "text": "Unequal limb movement"
        },
        {
          "key": "other",
          "text": "Other"
        }
      ],
      "tableColumn": "5",
      "fqn": "newbornComprehensive.nbCompSkeletalAppearance"
    },
    {
      "elementKey": "nbCompSkeletalAppearanceOther",
      "formIndex": "5",
      "inputType": "text",
      "tableColumn": "6",
      "fqn": "newbornComprehensive.nbCompSkeletalAppearanceOther"
    },
    {
      "elementKey": "nbCompSkeletalPosture",
      "formIndex": "5",
      "inputType": "checkset",
      "options": [
        {
          "key": "armsFlexed",
          "text": "Arms flexed"
        },
        {
          "key": "armsExtended",
          "text": "Arms extended"
        },
        {
          "key": "legFlexed",
          "text": "Leg flexed"
        },
        {
          "key": "legsExtended",
          "text": "Legs extended"
        },
        {
          "key": "other",
          "text": "Other"
        }
      ],
      "tableColumn": "7",
      "fqn": "newbornComprehensive.nbCompSkeletalPosture"
    },
    {
      "elementKey": "nbCompSkeletalPostureOther",
      "formIndex": "5",
      "inputType": "text",
      "tableColumn": "8",
      "fqn": "newbornComprehensive.nbCompSkeletalPostureOther"
    },
    {
      "elementKey": "nbCompSkeletalConcerns",
      "formIndex": "5",
      "inputType": "checkset",
      "options": [
        {
          "key": "clubFoot",
          "text": "Club foot"
        },
        {
          "key": "coccygealDimple",
          "text": "Coccygeal dimple"
        },
        {
          "key": "curvedSpine",
          "text": "Curved spine"
        },
        {
          "key": "fractures",
          "text": "Fractures"
        },
        {
          "key": "hipDislocation",
          "text": "Hip dislocation"
        },
        {
          "key": "nonIntactSpine",
          "text": "Non intact spine"
        },
        {
          "key": "poorRangeMotion",
          "text": "Poor range of motion"
        },
        {
          "key": "other",
          "text": "Other"
        }
      ],
      "tableColumn": "9",
      "fqn": "newbornComprehensive.nbCompSkeletalConcerns"
    },
    {
      "elementKey": "nbCompSkeletalConcernsOther",
      "formIndex": "5",
      "inputType": "text",
      "tableColumn": "10",
      "fqn": "newbornComprehensive.nbCompSkeletalConcernsOther"
    },
    {
      "elementKey": "nbCompSkeletalComments",
      "formIndex": "5",
      "inputType": "text",
      "tableColumn": "11",
      "fqn": "newbornComprehensive.nbCompSkeletalComments"
    },
    {
      "elementKey": "nbCompSkeletalCmmt",
      "formIndex": "5",
      "inputType": "textarea",
      "tableColumn": "13",
      "fqn": "newbornComprehensive.nbCompSkeletalCmmt"
    },
    {
      "elementKey": "nbCompNueromuscular_form_name",
      "formIndex": "6",
      "inputType": "practitionerName",
      "tableColumn": "1",
      "recHeader": true,
      "fqn": "newbornComprehensive.name"
    },
    {
      "elementKey": "nbCompNueromuscular_form_id",
      "inputType": "generatedId",
      "tableColumn": 0,
      "label": "Row id",
      "tableCss": "row-id"
    },
    {
      "elementKey": "nbCompNueromuscular_form_profession",
      "formIndex": "6",
      "inputType": "practitionerProfession",
      "tableColumn": "1",
      "recHeader": true,
      "fqn": "newbornComprehensive.profession"
    },
    {
      "elementKey": "nbCompNueromuscular_form_day",
      "formIndex": "6",
      "inputType": "visitDay",
      "mandatory": true,
      "tableColumn": "1",
      "validation": "visitDay",
      "recHeader": true,
      "fqn": "newbornComprehensive.day"
    },
    {
      "elementKey": "nbCompNueromuscular_form_time",
      "formIndex": "6",
      "inputType": "visitTime",
      "mandatory": true,
      "tableColumn": "1",
      "validation": "time24",
      "recHeader": true,
      "fqn": "newbornComprehensive.time"
    },
    {
      "elementKey": "nbCompNueromuscularDefined",
      "formIndex": "6",
      "inputType": "radioset",
      "options": [
        {
          "key": "wdf",
          "text": "Within defined parameters"
        },
        {
          "key": "significantFindings",
          "text": "Significant findings"
        },
        {
          "key": "na",
          "text": "Not applicable"
        },
        {
          "key": "notAssessed",
          "text": "Not assessed"
        }
      ],
      "tableColumn": "3",
      "fqn": "newbornComprehensive.nbCompNueromuscularDefined"
    },
    {
      "elementKey": "nbCompNueromuscularDefinedText",
      "formIndex": "6",
      "inputType": "formInfoText",
      "tableColumn": "4",
      "fqn": "newbornComprehensive.nbCompNueromuscularDefinedText"
    },
    {
      "elementKey": "nbCompNueromuscularReflexes",
      "formIndex": "6",
      "inputType": "checkset",
      "options": [
        {
          "key": "babinski",
          "text": "Babinski"
        },
        {
          "key": "grasping",
          "text": "Grasping"
        },
        {
          "key": "moro",
          "text": "Moro"
        },
        {
          "key": "palmar",
          "text": "Palmar"
        },
        {
          "key": "planter",
          "text": "Planter"
        },
        {
          "key": "rooting",
          "text": "Rooting"
        },
        {
          "key": "stepping",
          "text": "Stepping"
        },
        {
          "key": "sucking",
          "text": "Sucking"
        },
        {
          "key": "swallowing",
          "text": "Swallowing"
        }
      ],
      "tableColumn": "5",
      "fqn": "newbornComprehensive.nbCompNueromuscularReflexes"
    },
    {
      "elementKey": "nbCompNueromuscularTone",
      "formIndex": "6",
      "inputType": "checkset",
      "options": [
        {
          "key": "normal",
          "text": "Normal for GA/PMA"
        },
        {
          "key": "hypotonic",
          "text": "Hypotonic"
        },
        {
          "key": "hypertonic",
          "text": "Hypertonic"
        },
        {
          "key": "absent",
          "text": "Absent"
        },
        {
          "key": "paralyzed",
          "text": "Paralyzed"
        },
        {
          "key": "jittery",
          "text": "Jittery"
        },
        {
          "key": "other",
          "text": "Other"
        }
      ],
      "tableColumn": "6",
      "fqn": "newbornComprehensive.nbCompNueromuscularTone"
    },
    {
      "elementKey": "nbCompNueromuscularToneComments",
      "formIndex": "6",
      "inputType": "text",
      "tableColumn": "7",
      "fqn": "newbornComprehensive.nbCompNueromuscularToneComments"
    },
    {
      "elementKey": "nbCompNueromuscularOther",
      "formIndex": "6",
      "inputType": "text",
      "tableColumn": "8",
      "fqn": "newbornComprehensive.nbCompNueromuscularOther"
    },
    {
      "elementKey": "nbCompNueromuscularCmmt",
      "formIndex": "6",
      "inputType": "textarea",
      "tableColumn": "10",
      "fqn": "newbornComprehensive.nbCompNueromuscularCmmt"
    },
    {
      "elementKey": "nbCompGenitalia_form_name",
      "formIndex": "7",
      "inputType": "practitionerName",
      "tableColumn": "1",
      "recHeader": true,
      "fqn": "newbornComprehensive.name"
    },
    {
      "elementKey": "nbCompGenitalia_form_id",
      "inputType": "generatedId",
      "tableColumn": 0,
      "label": "Row id",
      "tableCss": "row-id"
    },
    {
      "elementKey": "nbCompGenitalia_form_profession",
      "formIndex": "7",
      "inputType": "practitionerProfession",
      "tableColumn": "1",
      "recHeader": true,
      "fqn": "newbornComprehensive.profession"
    },
    {
      "elementKey": "nbCompGenitalia_form_day",
      "formIndex": "7",
      "inputType": "visitDay",
      "mandatory": true,
      "tableColumn": "1",
      "validation": "visitDay",
      "recHeader": true,
      "fqn": "newbornComprehensive.day"
    },
    {
      "elementKey": "nbCompGenitalia_form_time",
      "formIndex": "7",
      "inputType": "visitTime",
      "mandatory": true,
      "tableColumn": "1",
      "validation": "time24",
      "recHeader": true,
      "fqn": "newbornComprehensive.time"
    },
    {
      "elementKey": "nbCompGenitaliaDefined",
      "formIndex": "7",
      "inputType": "radioset",
      "options": [
        {
          "key": "wdf",
          "text": "Within defined parameters"
        },
        {
          "key": "significantFindings",
          "text": "Significant findings"
        },
        {
          "key": "na",
          "text": "Not applicable"
        },
        {
          "key": "notAssessed",
          "text": "Not assessed"
        }
      ],
      "tableColumn": "3",
      "fqn": "newbornComprehensive.nbCompGenitaliaDefined"
    },
    {
      "elementKey": "nbCompGenitaliaDefinedText",
      "formIndex": "7",
      "inputType": "formInfoText",
      "tableColumn": "4",
      "fqn": "newbornComprehensive.nbCompGenitaliaDefinedText"
    },
    {
      "elementKey": "nbCompGenitaliaAnonmalies",
      "formIndex": "7",
      "inputType": "checkset",
      "options": [
        {
          "key": "none",
          "text": "None"
        },
        {
          "key": "ambiguous",
          "text": "Ambiguous genitalia"
        },
        {
          "key": "hydrocele",
          "text": "Hydrocele"
        },
        {
          "key": "hypospadias",
          "text": "Hypospadias"
        },
        {
          "key": "testesNotPalpable",
          "text": "Testes not palpable"
        },
        {
          "key": "undescendedRight",
          "text": "Undescended testicle right"
        },
        {
          "key": "undescendedLeft",
          "text": "Undescended testicle left"
        },
        {
          "key": "fusion",
          "text": "Fusion of labia"
        },
        {
          "key": "other",
          "text": "Other"
        }
      ],
      "tableColumn": "5",
      "fqn": "newbornComprehensive.nbCompGenitaliaAnonmalies"
    },
    {
      "elementKey": "nbCompGenitaliaComments",
      "formIndex": "7",
      "inputType": "text",
      "tableColumn": "6",
      "fqn": "newbornComprehensive.nbCompGenitaliaComments"
    },
    {
      "elementKey": "nbCompGenitaliaCmmt",
      "formIndex": "7",
      "inputType": "textarea",
      "tableColumn": "8",
      "fqn": "newbornComprehensive.nbCompGenitaliaCmmt"
    },
    {
      "elementKey": "nbBehav_form_name",
      "formIndex": "8",
      "inputType": "practitionerName",
      "tableColumn": "1",
      "recHeader": true,
      "fqn": "newbornComprehensive.name"
    },
    {
      "elementKey": "nbBehav_form_id",
      "inputType": "generatedId",
      "tableColumn": 0,
      "label": "Row id",
      "tableCss": "row-id"
    },
    {
      "elementKey": "nbBehav_form_profession",
      "formIndex": "8",
      "inputType": "practitionerProfession",
      "tableColumn": "1",
      "recHeader": true,
      "fqn": "newbornComprehensive.profession"
    },
    {
      "elementKey": "nbBehav_form_day",
      "formIndex": "8",
      "inputType": "visitDay",
      "mandatory": true,
      "tableColumn": "1",
      "validation": "visitDay",
      "recHeader": true,
      "fqn": "newbornComprehensive.day"
    },
    {
      "elementKey": "nbBehav_form_time",
      "formIndex": "8",
      "inputType": "visitTime",
      "mandatory": true,
      "tableColumn": "1",
      "validation": "time24",
      "recHeader": true,
      "fqn": "newbornComprehensive.time"
    },
    {
      "elementKey": "nbCompBehaviourDefined",
      "formIndex": "8",
      "inputType": "radioset",
      "options": [
        {
          "key": "wdp",
          "text": "Within defined parameters"
        },
        {
          "key": "significantFindings",
          "text": "Significant findings"
        },
        {
          "key": "na",
          "text": "Not applicable"
        },
        {
          "key": "notAssessed",
          "text": "Not assessed"
        }
      ],
      "tableColumn": "8",
      "fqn": "newbornComprehensive.nbCompBehaviourDefined"
    },
    {
      "elementKey": "nbCompBehaviourDefinedText",
      "formIndex": "8",
      "inputType": "formInfoText",
      "tableColumn": "9",
      "fqn": "newbornComprehensive.nbCompBehaviourDefinedText"
    },
    {
      "elementKey": "nbCompBehaviourState",
      "formIndex": "8",
      "inputType": "checkset",
      "options": [
        {
          "key": "deepSleep",
          "text": "Deep sleep"
        },
        {
          "key": "lightSleep",
          "text": "Light sleep"
        },
        {
          "key": "drowsy",
          "text": "Drowsy difficulty awakening"
        },
        {
          "key": "lethargic",
          "text": "Lethargic"
        },
        {
          "key": "quietAlert",
          "text": "Quiet alert"
        },
        {
          "key": "activeAlert",
          "text": "Active alert"
        },
        {
          "key": "crying",
          "text": "Crying"
        },
        {
          "key": "sedated",
          "text": "Sedated"
        },
        {
          "key": "irritable",
          "text": "Irritable"
        },
        {
          "key": "unresponsive",
          "text": "Unresponsive"
        },
        {
          "key": "other",
          "text": "Other"
        }
      ],
      "tableColumn": "10",
      "fqn": "newbornComprehensive.nbCompBehaviourState"
    },
    {
      "elementKey": "nbCompBehaviourCry",
      "formIndex": "8",
      "inputType": "checkset",
      "options": [
        {
          "key": "change",
          "text": "Change in cry"
        },
        {
          "key": "absent",
          "text": "Absent"
        },
        {
          "key": "grimace",
          "text": "Grimace only"
        },
        {
          "key": "weak",
          "text": "Weak cry"
        },
        {
          "key": "irritable",
          "text": "Irritable cry"
        },
        {
          "key": "highPitch",
          "text": "High pitch cry"
        },
        {
          "key": "strong",
          "text": "Strong cry"
        },
        {
          "key": "na",
          "text": "Not assessed"
        },
        {
          "key": "other",
          "text": "Other"
        }
      ],
      "tableColumn": "11",
      "fqn": "newbornComprehensive.nbCompBehaviourCry"
    },
    {
      "elementKey": "nbCompBehaviourCryOther",
      "formIndex": "8",
      "inputType": "text",
      "tableColumn": "12",
      "fqn": "newbornComprehensive.nbCompBehaviourCryOther"
    },
    {
      "elementKey": "nbCompBehaviourComments",
      "formIndex": "8",
      "inputType": "text",
      "tableColumn": "13",
      "fqn": "newbornComprehensive.nbCompBehaviourComments"
    },
    {
      "elementKey": "nbBehavCmmt",
      "formIndex": "8",
      "inputType": "textarea",
      "tableColumn": "15",
      "fqn": "newbornComprehensive.nbBehavCmmt"
    }
  ],
  "pageElements": {
    "nbComp_form": {
      "elementKey": "nbComp_form",
      "pageElementIndex": "1",
      "pageKey": "newbornComprehensive",
      "tableKey": "nbComp_form",
      "isTable": true,
      "form": {
        "elementKey": "nbComp_form",
        "label": "HEENT",
        "addButtonText": "Add HEENT",
        "formKey": "nbComp_form",
        "ehr_groups": [
          {
            "elementKey": "nbComp_hdrGroup",
            "formCss": "record-header",
            "fqn": "newbornComprehensive.nbComp_hdrGroup",
            "gIndex": "1",
            "gChildren": [
              "nbComp_form_name",
              "nbComp_form_profession",
              "nbComp_form_day",
              "nbComp_form_time"
            ]
          },
          {
            "elementKey": "nbCompHeadGrp1",
            "formCss": "ehr-row-flow",
            "fqn": "newbornComprehensive.nbCompHeadGrp1",
            "gIndex": "2",
            "gChildren": [
              "nbCompHeadDefined",
              "nbCompHeadDefinedText",
              "nbCompHeadDesc",
              "nbCompHeadDescOther",
              "nbCompHeadAnteriorCranial",
              "nbCompHeadPosterior",
              "nbCompHeadAnteriorFontanelles",
              "nbCompHeadAnteriorFontanellesOther",
              "nbCompHeadPosteriorFontanelles",
              "nbCompHeadPosteriorFontanellesOther",
              "nbCompHeadComments"
            ]
          },
          {
            "elementKey": "nbCompEyesGrp",
            "formCss": "ehr-row-flow",
            "fqn": "newbornComprehensive.nbCompEyesGrp",
            "gIndex": "3",
            "gChildren": [
              "nbCompEyesDefined",
              "nbCompEyesDefinedText"
            ]
          },
          {
            "elementKey": "nbCompEarsGrp",
            "formCss": "ehr-row-flow",
            "fqn": "newbornComprehensive.nbCompEarsGrp",
            "gIndex": "4",
            "gChildren": [
              "nbCompEarsDefined",
              "nbCompEarsDefinedText"
            ]
          },
          {
            "elementKey": "nbCompNoseGrp",
            "formCss": "ehr-row-flow",
            "fqn": "newbornComprehensive.nbCompNoseGrp",
            "gIndex": "5",
            "gChildren": [
              "nbCompNoseDefined",
              "nbCompNoseDefinedText",
              "nbCompNoseAssConcerns",
              "nbCompNoseAssConcernsOther",
              "nbCompNoseAssInterventions",
              "nbCompNoseAssComments"
            ]
          },
          {
            "elementKey": "nbCompOralGrp1",
            "formCss": "ehr-row-flow",
            "fqn": "newbornComprehensive.nbCompOralGrp1",
            "gIndex": "6",
            "gChildren": [
              "nbCompOralDefined",
              "nbCompOralDefinedText",
              "nbCompOralConcerns",
              "nbCompOralConcernsOther",
              "nbCompOralAnomalies",
              "nbCompOralAnomaliesComments",
              "nbCompOralComments"
            ]
          },
          {
            "elementKey": "nbCompHeentCmtGrp",
            "formCss": "grid-left-to-right-1",
            "fqn": "newbornComprehensive.nbCompHeentCmtGrp",
            "gIndex": "7",
            "gChildren": [
              "nbCompHeentCmmt"
            ]
          }
        ],
        "ehr_data": {
          "nbComp_form_name": "",
          "nbComp_form_profession": "",
          "nbComp_form_day": "",
          "nbComp_form_time": "",
          "nbCompHeadDefined": "",
          "nbCompHeadDesc": "",
          "nbCompHeadDescOther": "",
          "nbCompHeadAnteriorCranial": "",
          "nbCompHeadPosterior": "",
          "nbCompHeadAnteriorFontanelles": "",
          "nbCompHeadAnteriorFontanellesOther": "",
          "nbCompHeadPosteriorFontanelles": "",
          "nbCompHeadPosteriorFontanellesOther": "",
          "nbCompHeadComments": "",
          "nbCompEyesDefined": "",
          "nbCompEarsDefined": "",
          "nbCompNoseDefined": "",
          "nbCompNoseAssConcerns": "",
          "nbCompNoseAssConcernsOther": "",
          "nbCompNoseAssInterventions": "",
          "nbCompNoseAssComments": "",
          "nbCompOralDefined": "",
          "nbCompOralConcerns": "",
          "nbCompOralConcernsOther": "",
          "nbCompOralAnomalies": "",
          "nbCompOralAnomaliesComments": "",
          "nbCompOralComments": "",
          "nbCompHeentCmmt": ""
        }
      },
      "fqn": "newbornComprehensive.nbComp_form",
      "tableChildren": [
        "nbComp_form_id",
        "nbComp_form_day",
        "nbComp_form_time",
        "nbCompHeadDefined",
        "nbCompHeadDefinedText",
        "nbCompHeadDesc",
        "nbCompHeadDescOther",
        "nbCompHeadAnteriorCranial",
        "nbCompHeadPosterior",
        "nbCompHeadAnteriorFontanelles",
        "nbCompHeadAnteriorFontanellesOther",
        "nbCompHeadPosteriorFontanelles",
        "nbCompHeadPosteriorFontanellesOther",
        "nbCompHeadComments",
        "nbCompEyesDefined",
        "nbCompEyesDefinedText",
        "nbCompEarsDefined",
        "nbCompEarsDefinedText",
        "nbCompNoseDefined",
        "nbCompNoseDefinedText",
        "nbCompNoseAssConcerns",
        "nbCompNoseAssConcernsOther",
        "nbCompNoseAssInterventions",
        "nbCompNoseAssComments",
        "nbCompOralDefined",
        "nbCompOralDefinedText",
        "nbCompOralConcerns",
        "nbCompOralConcernsOther",
        "nbCompOralAnomalies",
        "nbCompOralAnomaliesComments",
        "nbCompOralComments",
        "nbCompHeentCmmt",
        "nbComp_form_name",
        "nbComp_form_profession"
      ],
      "hasRecHeader": true
    },
    "nbCompCcr_form": {
      "elementKey": "nbCompCcr_form",
      "pageElementIndex": "2",
      "pageKey": "newbornComprehensive",
      "tableKey": "nbCompCcr_form",
      "isTable": true,
      "form": {
        "elementKey": "nbCompCcr_form",
        "label": "Cardiorespiratory",
        "addButtonText": "Add Cardiorespiratory",
        "formKey": "nbCompCcr_form",
        "ehr_groups": [
          {
            "elementKey": "nbCompCcrHdr",
            "formCss": "record-header",
            "fqn": "newbornComprehensive.nbCompCcrHdr",
            "gIndex": "1",
            "gChildren": [
              "nbCompCcr_form_name",
              "nbCompCcr_form_profession",
              "nbCompCcr_form_day",
              "nbCompCcr_form_time"
            ]
          },
          {
            "elementKey": "nbCompCcrGrp1",
            "formCss": "ehr-row-flow",
            "fqn": "newbornComprehensive.nbCompCcrGrp1",
            "gIndex": "2",
            "gChildren": [
              "nbCompCcrDefined",
              "nbCompCcrDefinedText",
              "nbCompCcrIndicators",
              "nbCompCcrIndicatorsOther",
              "nbCompCcrChest",
              "nbCompCcrDepth",
              "nbCompCcrPattern",
              "nbCompCcrWork",
              "nbCompCcrSounds",
              "nbCompCcrSoundsComments",
              "nbCompCcrColour",
              "nbCompCcrColourOther",
              "nbCompCcrConsistency",
              "nbCompCcrConsistencyOther",
              "nbCompCcrSource",
              "nbCompCcrChestAppearance",
              "nbCompCcrRhythm",
              "nbCompCcrMurmur",
              "nbCompCcrOtherComments"
            ]
          },
          {
            "elementKey": "nbCompCctCmtGrp",
            "formCss": "grid-left-to-right-1",
            "fqn": "newbornComprehensive.nbCompCctCmtGrp",
            "gIndex": "3",
            "gChildren": [
              "nbCompCctCmmt"
            ]
          }
        ],
        "ehr_data": {
          "nbCompCcr_form_name": "",
          "nbCompCcr_form_profession": "",
          "nbCompCcr_form_day": "",
          "nbCompCcr_form_time": "",
          "nbCompCcrDefined": "",
          "nbCompCcrIndicators": "",
          "nbCompCcrIndicatorsOther": "",
          "nbCompCcrChest": "",
          "nbCompCcrDepth": "",
          "nbCompCcrPattern": "",
          "nbCompCcrWork": "",
          "nbCompCcrSounds": "",
          "nbCompCcrSoundsComments": "",
          "nbCompCcrColour": "",
          "nbCompCcrColourOther": "",
          "nbCompCcrConsistency": "",
          "nbCompCcrConsistencyOther": "",
          "nbCompCcrSource": "",
          "nbCompCcrChestAppearance": "",
          "nbCompCcrRhythm": "",
          "nbCompCcrMurmur": "",
          "nbCompCcrOtherComments": "",
          "nbCompCctCmmt": ""
        }
      },
      "fqn": "newbornComprehensive.nbCompCcr_form",
      "tableChildren": [
        "nbCompCcr_form_id",
        "nbCompCcr_form_day",
        "nbCompCcr_form_time",
        "nbCompCcrDefined",
        "nbCompCcrDefinedText",
        "nbCompCcrIndicators",
        "nbCompCcrIndicatorsOther",
        "nbCompCcrChest",
        "nbCompCcrDepth",
        "nbCompCcrPattern",
        "nbCompCcrWork",
        "nbCompCcrSounds",
        "nbCompCcrSoundsComments",
        "nbCompCcrColour",
        "nbCompCcrColourOther",
        "nbCompCcrConsistency",
        "nbCompCcrConsistencyOther",
        "nbCompCcrSource",
        "nbCompCcrChestAppearance",
        "nbCompCcrRhythm",
        "nbCompCcrMurmur",
        "nbCompCcrOtherComments",
        "nbCompCctCmmt",
        "nbCompCcr_form_name",
        "nbCompCcr_form_profession"
      ],
      "hasRecHeader": true
    },
    "nbAbdomen_form": {
      "elementKey": "nbAbdomen_form",
      "pageElementIndex": "3",
      "pageKey": "newbornComprehensive",
      "tableKey": "nbAbdomen_form",
      "isTable": true,
      "form": {
        "elementKey": "nbAbdomen_form",
        "label": "Abdomen",
        "addButtonText": "Add Abdomen",
        "formKey": "nbAbdomen_form",
        "ehr_groups": [
          {
            "elementKey": "nbAbdomenHdr",
            "formCss": "record-header",
            "fqn": "newbornComprehensive.nbAbdomenHdr",
            "gIndex": "1",
            "gChildren": [
              "nbAbdomen_form_name",
              "nbAbdomen_form_profession",
              "nbAbdomen_form_day",
              "nbAbdomen_form_time"
            ]
          },
          {
            "elementKey": "nbCompAbdGrp1",
            "formCss": "ehr-row-flow",
            "fqn": "newbornComprehensive.nbCompAbdGrp1",
            "gIndex": "2",
            "gChildren": [
              "nbCompAbdDefined",
              "nbCompAbdDefinedText",
              "nbCompAbdDesc",
              "nbCompAbdDescOther",
              "nbCompAbdPalp",
              "nbCompAbdPalpOther",
              "nbCompAbdSounds",
              "nbCompAbdOther"
            ]
          },
          {
            "elementKey": "nbCompUmbilicusGrp",
            "formCss": "ehr-row-flow",
            "fqn": "newbornComprehensive.nbCompUmbilicusGrp",
            "gIndex": "3",
            "gChildren": [
              "nbCompUmbilicusDefined",
              "nbCompUmbilicusDefinedText"
            ]
          },
          {
            "elementKey": "nbCompUmbilicusCmtGrp",
            "formCss": "grid-left-to-right-1",
            "fqn": "newbornComprehensive.nbCompUmbilicusCmtGrp",
            "gIndex": "4",
            "gChildren": [
              "nbCompUmbilicusCmmt"
            ]
          }
        ],
        "ehr_data": {
          "nbAbdomen_form_name": "",
          "nbAbdomen_form_profession": "",
          "nbAbdomen_form_day": "",
          "nbAbdomen_form_time": "",
          "nbCompAbdDefined": "",
          "nbCompAbdDesc": "",
          "nbCompAbdDescOther": "",
          "nbCompAbdPalp": "",
          "nbCompAbdPalpOther": "",
          "nbCompAbdSounds": "",
          "nbCompAbdOther": "",
          "nbCompUmbilicusDefined": "",
          "nbCompUmbilicusCmmt": ""
        }
      },
      "fqn": "newbornComprehensive.nbAbdomen_form",
      "tableChildren": [
        "nbAbdomen_form_id",
        "nbAbdomen_form_day",
        "nbAbdomen_form_time",
        "nbCompAbdDefined",
        "nbCompAbdDefinedText",
        "nbCompAbdDesc",
        "nbCompAbdDescOther",
        "nbCompAbdPalp",
        "nbCompAbdPalpOther",
        "nbCompAbdSounds",
        "nbCompAbdOther",
        "nbCompUmbilicusDefined",
        "nbCompUmbilicusDefinedText",
        "nbCompUmbilicusCmmt",
        "nbAbdomen_form_name",
        "nbAbdomen_form_profession"
      ],
      "hasRecHeader": true
    },
    "nbCompSkin_form": {
      "elementKey": "nbCompSkin_form",
      "pageElementIndex": "4",
      "pageKey": "newbornComprehensive",
      "tableKey": "nbCompSkin_form",
      "isTable": true,
      "form": {
        "elementKey": "nbCompSkin_form",
        "label": "Skin",
        "addButtonText": "Add Skin",
        "formKey": "nbCompSkin_form",
        "ehr_groups": [
          {
            "elementKey": "nbCompSkinHdr",
            "formCss": "record-header",
            "fqn": "newbornComprehensive.nbCompSkinHdr",
            "gIndex": "1",
            "gChildren": [
              "nbCompSkin_form_name",
              "nbCompSkin_form_profession",
              "nbCompSkin_form_day",
              "nbCompSkin_form_time"
            ]
          },
          {
            "elementKey": "nbCompSkinGrp1",
            "formCss": "ehr-row-flow",
            "fqn": "newbornComprehensive.nbCompSkinGrp1",
            "gIndex": "2",
            "gChildren": [
              "nbCompSkinDefined",
              "nbCompSkinDefinedText",
              "nbCompSkinClrCentral",
              "nbCompSkinClrPeripheral",
              "nbCompSkinMucous",
              "nbCompSkinTurgor",
              "nbCompSkinCondition",
              "nbCompSkinConditionComments",
              "nbCompSkinInterventions",
              "nbCompSkinInterventionsComments",
              "nbCompSkinAnomalies",
              "nbCompSkinAnomaliesOther",
              "nbCompSkinComments"
            ]
          },
          {
            "elementKey": "nbCompSkinCmtGrp",
            "formCss": "grid-left-to-right-1",
            "fqn": "newbornComprehensive.nbCompSkinCmtGrp",
            "gIndex": "3",
            "gChildren": [
              "nbCompSkinCmmt"
            ]
          }
        ],
        "ehr_data": {
          "nbCompSkin_form_name": "",
          "nbCompSkin_form_profession": "",
          "nbCompSkin_form_day": "",
          "nbCompSkin_form_time": "",
          "nbCompSkinDefined": "",
          "nbCompSkinClrCentral": "",
          "nbCompSkinClrPeripheral": "",
          "nbCompSkinMucous": "",
          "nbCompSkinTurgor": "",
          "nbCompSkinCondition": "",
          "nbCompSkinConditionComments": "",
          "nbCompSkinInterventions": "",
          "nbCompSkinInterventionsComments": "",
          "nbCompSkinAnomalies": "",
          "nbCompSkinAnomaliesOther": "",
          "nbCompSkinComments": "",
          "nbCompSkinCmmt": ""
        }
      },
      "fqn": "newbornComprehensive.nbCompSkin_form",
      "tableChildren": [
        "nbCompSkin_form_id",
        "nbCompSkin_form_day",
        "nbCompSkin_form_time",
        "nbCompSkinDefined",
        "nbCompSkinDefinedText",
        "nbCompSkinClrCentral",
        "nbCompSkinClrPeripheral",
        "nbCompSkinMucous",
        "nbCompSkinTurgor",
        "nbCompSkinCondition",
        "nbCompSkinConditionComments",
        "nbCompSkinInterventions",
        "nbCompSkinInterventionsComments",
        "nbCompSkinAnomalies",
        "nbCompSkinAnomaliesOther",
        "nbCompSkinComments",
        "nbCompSkinCmmt",
        "nbCompSkin_form_name",
        "nbCompSkin_form_profession"
      ],
      "hasRecHeader": true
    },
    "nbCompSkeletal_form": {
      "elementKey": "nbCompSkeletal_form",
      "pageElementIndex": "5",
      "pageKey": "newbornComprehensive",
      "tableKey": "nbCompSkeletal_form",
      "isTable": true,
      "form": {
        "elementKey": "nbCompSkeletal_form",
        "label": "Skeletal",
        "addButtonText": "Add Skeletal",
        "formKey": "nbCompSkeletal_form",
        "ehr_groups": [
          {
            "elementKey": "nbCompSkeletalHdr",
            "formCss": "record-header",
            "fqn": "newbornComprehensive.nbCompSkeletalHdr",
            "gIndex": "1",
            "gChildren": [
              "nbCompSkeletal_form_name",
              "nbCompSkeletal_form_profession",
              "nbCompSkeletal_form_day",
              "nbCompSkeletal_form_time"
            ]
          },
          {
            "elementKey": "nbCompSkeletalGrp1",
            "formCss": "ehr-row-flow",
            "fqn": "newbornComprehensive.nbCompSkeletalGrp1",
            "gIndex": "2",
            "gChildren": [
              "nbCompSkeletalDefined",
              "nbCompSkeletalDefinedText",
              "nbCompSkeletalAppearance",
              "nbCompSkeletalAppearanceOther",
              "nbCompSkeletalPosture",
              "nbCompSkeletalPostureOther",
              "nbCompSkeletalConcerns",
              "nbCompSkeletalConcernsOther",
              "nbCompSkeletalComments"
            ]
          },
          {
            "elementKey": "nbCompSkeletalCmtGrp",
            "formCss": "grid-left-to-right-1",
            "fqn": "newbornComprehensive.nbCompSkeletalCmtGrp",
            "gIndex": "3",
            "gChildren": [
              "nbCompSkeletalCmmt"
            ]
          }
        ],
        "ehr_data": {
          "nbCompSkeletal_form_name": "",
          "nbCompSkeletal_form_profession": "",
          "nbCompSkeletal_form_day": "",
          "nbCompSkeletal_form_time": "",
          "nbCompSkeletalDefined": "",
          "nbCompSkeletalAppearance": "",
          "nbCompSkeletalAppearanceOther": "",
          "nbCompSkeletalPosture": "",
          "nbCompSkeletalPostureOther": "",
          "nbCompSkeletalConcerns": "",
          "nbCompSkeletalConcernsOther": "",
          "nbCompSkeletalComments": "",
          "nbCompSkeletalCmmt": ""
        }
      },
      "fqn": "newbornComprehensive.nbCompSkeletal_form",
      "tableChildren": [
        "nbCompSkeletal_form_id",
        "nbCompSkeletal_form_day",
        "nbCompSkeletal_form_time",
        "nbCompSkeletalDefined",
        "nbCompSkeletalDefinedText",
        "nbCompSkeletalAppearance",
        "nbCompSkeletalAppearanceOther",
        "nbCompSkeletalPosture",
        "nbCompSkeletalPostureOther",
        "nbCompSkeletalConcerns",
        "nbCompSkeletalConcernsOther",
        "nbCompSkeletalComments",
        "nbCompSkeletalCmmt",
        "nbCompSkeletal_form_name",
        "nbCompSkeletal_form_profession"
      ],
      "hasRecHeader": true
    },
    "nbCompNueromuscular_form": {
      "elementKey": "nbCompNueromuscular_form",
      "pageElementIndex": "6",
      "pageKey": "newbornComprehensive",
      "tableKey": "nbCompNueromuscular_form",
      "isTable": true,
      "form": {
        "elementKey": "nbCompNueromuscular_form",
        "label": "Neuromuscular",
        "addButtonText": "Add Neuromuscular",
        "formKey": "nbCompNueromuscular_form",
        "ehr_groups": [
          {
            "elementKey": "nbCompNueromuscularHdr",
            "formCss": "record-header",
            "fqn": "newbornComprehensive.nbCompNueromuscularHdr",
            "gIndex": "1",
            "gChildren": [
              "nbCompNueromuscular_form_name",
              "nbCompNueromuscular_form_profession",
              "nbCompNueromuscular_form_day",
              "nbCompNueromuscular_form_time"
            ]
          },
          {
            "elementKey": "nbCompNueromuscularGrp1",
            "formCss": "ehr-row-flow",
            "fqn": "newbornComprehensive.nbCompNueromuscularGrp1",
            "gIndex": "2",
            "gChildren": [
              "nbCompNueromuscularDefined",
              "nbCompNueromuscularDefinedText",
              "nbCompNueromuscularReflexes",
              "nbCompNueromuscularTone",
              "nbCompNueromuscularToneComments",
              "nbCompNueromuscularOther"
            ]
          },
          {
            "elementKey": "nbCompNueromuscularCmtGrp",
            "formCss": "grid-left-to-right-1",
            "fqn": "newbornComprehensive.nbCompNueromuscularCmtGrp",
            "gIndex": "3",
            "gChildren": [
              "nbCompNueromuscularCmmt"
            ]
          }
        ],
        "ehr_data": {
          "nbCompNueromuscular_form_name": "",
          "nbCompNueromuscular_form_profession": "",
          "nbCompNueromuscular_form_day": "",
          "nbCompNueromuscular_form_time": "",
          "nbCompNueromuscularDefined": "",
          "nbCompNueromuscularReflexes": "",
          "nbCompNueromuscularTone": "",
          "nbCompNueromuscularToneComments": "",
          "nbCompNueromuscularOther": "",
          "nbCompNueromuscularCmmt": ""
        }
      },
      "fqn": "newbornComprehensive.nbCompNueromuscular_form",
      "tableChildren": [
        "nbCompNueromuscular_form_id",
        "nbCompNueromuscular_form_day",
        "nbCompNueromuscular_form_time",
        "nbCompNueromuscularDefined",
        "nbCompNueromuscularDefinedText",
        "nbCompNueromuscularReflexes",
        "nbCompNueromuscularTone",
        "nbCompNueromuscularToneComments",
        "nbCompNueromuscularOther",
        "nbCompNueromuscularCmmt",
        "nbCompNueromuscular_form_name",
        "nbCompNueromuscular_form_profession"
      ],
      "hasRecHeader": true
    },
    "nbCompGenitalia_form": {
      "elementKey": "nbCompGenitalia_form",
      "pageElementIndex": "7",
      "pageKey": "newbornComprehensive",
      "tableKey": "nbCompGenitalia_form",
      "isTable": true,
      "form": {
        "elementKey": "nbCompGenitalia_form",
        "label": "Genitalia",
        "addButtonText": "Add Genitalia",
        "formKey": "nbCompGenitalia_form",
        "ehr_groups": [
          {
            "elementKey": "nbCompGenitaliaHdr",
            "formCss": "record-header",
            "fqn": "newbornComprehensive.nbCompGenitaliaHdr",
            "gIndex": "1",
            "gChildren": [
              "nbCompGenitalia_form_name",
              "nbCompGenitalia_form_profession",
              "nbCompGenitalia_form_day",
              "nbCompGenitalia_form_time"
            ]
          },
          {
            "elementKey": "nbCompGenitaliaGrp1",
            "formCss": "ehr-row-flow",
            "fqn": "newbornComprehensive.nbCompGenitaliaGrp1",
            "gIndex": "2",
            "gChildren": [
              "nbCompGenitaliaDefined",
              "nbCompGenitaliaDefinedText",
              "nbCompGenitaliaAnonmalies",
              "nbCompGenitaliaComments"
            ]
          },
          {
            "elementKey": "nbCompGenitaliaCmtGrp",
            "formCss": "grid-left-to-right-1",
            "fqn": "newbornComprehensive.nbCompGenitaliaCmtGrp",
            "gIndex": "3",
            "gChildren": [
              "nbCompGenitaliaCmmt"
            ]
          }
        ],
        "ehr_data": {
          "nbCompGenitalia_form_name": "",
          "nbCompGenitalia_form_profession": "",
          "nbCompGenitalia_form_day": "",
          "nbCompGenitalia_form_time": "",
          "nbCompGenitaliaDefined": "",
          "nbCompGenitaliaAnonmalies": "",
          "nbCompGenitaliaComments": "",
          "nbCompGenitaliaCmmt": ""
        }
      },
      "fqn": "newbornComprehensive.nbCompGenitalia_form",
      "tableChildren": [
        "nbCompGenitalia_form_id",
        "nbCompGenitalia_form_day",
        "nbCompGenitalia_form_time",
        "nbCompGenitaliaDefined",
        "nbCompGenitaliaDefinedText",
        "nbCompGenitaliaAnonmalies",
        "nbCompGenitaliaComments",
        "nbCompGenitaliaCmmt",
        "nbCompGenitalia_form_name",
        "nbCompGenitalia_form_profession"
      ],
      "hasRecHeader": true
    },
    "nbBehav_form": {
      "elementKey": "nbBehav_form",
      "pageElementIndex": "8",
      "pageKey": "newbornComprehensive",
      "tableKey": "nbBehav_form",
      "isTable": true,
      "form": {
        "elementKey": "nbBehav_form",
        "label": "Behaviour",
        "addButtonText": "Add Behaviour",
        "formKey": "nbBehav_form",
        "ehr_groups": [
          {
            "elementKey": "nbBehavHdr",
            "formCss": "record-header",
            "fqn": "newbornComprehensive.nbBehavHdr",
            "gIndex": "1",
            "gChildren": [
              "nbBehav_form_name",
              "nbBehav_form_profession",
              "nbBehav_form_day",
              "nbBehav_form_time"
            ]
          },
          {
            "elementKey": "nbCompBehaviourGrp1",
            "formCss": "ehr-row-flow",
            "fqn": "newbornComprehensive.nbCompBehaviourGrp1",
            "gIndex": "2",
            "gChildren": [
              "nbCompBehaviourDefined",
              "nbCompBehaviourDefinedText",
              "nbCompBehaviourState",
              "nbCompBehaviourCry",
              "nbCompBehaviourCryOther",
              "nbCompBehaviourComments"
            ]
          },
          {
            "elementKey": "nbBehavCmtGrp",
            "formCss": "grid-left-to-right-1",
            "fqn": "newbornComprehensive.nbBehavCmtGrp",
            "gIndex": "3",
            "gChildren": [
              "nbBehavCmmt"
            ]
          }
        ],
        "ehr_data": {
          "nbBehav_form_name": "",
          "nbBehav_form_profession": "",
          "nbBehav_form_day": "",
          "nbBehav_form_time": "",
          "nbCompBehaviourDefined": "",
          "nbCompBehaviourState": "",
          "nbCompBehaviourCry": "",
          "nbCompBehaviourCryOther": "",
          "nbCompBehaviourComments": "",
          "nbBehavCmmt": ""
        }
      },
      "fqn": "newbornComprehensive.nbBehav_form",
      "tableChildren": [
        "nbBehav_form_id",
        "nbBehav_form_day",
        "nbBehav_form_time",
        "nbCompBehaviourDefined",
        "nbCompBehaviourDefinedText",
        "nbCompBehaviourState",
        "nbCompBehaviourCry",
        "nbCompBehaviourCryOther",
        "nbCompBehaviourComments",
        "nbBehavCmmt",
        "nbBehav_form_name",
        "nbBehav_form_profession"
      ],
      "hasRecHeader": true
    }
  },
  "path": "/ehr/peri-ped/newborn",
  "routeName": "newborn-comprehensive",
  "label": "Comprehensive",
  "generateComponent": "v2page",
  "featureFlag": "ffPed",
  "ehr": "yes",
  "fullPageKey": "periPed.newborn.newbornComprehensive"
}
export default DEFS