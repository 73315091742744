/* eslint-disable */
const DEFS = {
  "pageDataKey": "matDischargeEd",
  "pIndex": "84",
  "hasGridForm": true,
  "pageChildren": [
    {
      "elementKey": "matDischargeEdForm_name",
      "formIndex": "1",
      "inputType": "practitionerName",
      "tableColumn": "2",
      "recHeader": true,
      "fqn": "matDischargeEd.name"
    },
    {
      "elementKey": "matDischargeEdForm_profession",
      "formIndex": "1",
      "inputType": "practitionerProfession",
      "tableColumn": "2",
      "recHeader": true,
      "fqn": "matDischargeEd.profession"
    },
    {
      "elementKey": "matDischargeEdForm_day",
      "formIndex": "1",
      "inputType": "visitDay",
      "mandatory": true,
      "tableColumn": "2",
      "validation": "visitDay",
      "recHeader": true,
      "fqn": "matDischargeEd.day"
    },
    {
      "elementKey": "matDischargeEdForm_time",
      "formIndex": "1",
      "inputType": "visitTime",
      "mandatory": true,
      "tableColumn": "2",
      "validation": "time24",
      "recHeader": true,
      "fqn": "matDischargeEd.time"
    },
    {
      "elementKey": "matDischargeEdq1",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "4",
      "fqn": "matDischargeEd.matDischargeEdq1"
    },
    {
      "elementKey": "matDischargeEdq2",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "5",
      "fqn": "matDischargeEd.matDischargeEdq2"
    },
    {
      "elementKey": "matDischargeEdq3",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "6",
      "fqn": "matDischargeEd.matDischargeEdq3"
    },
    {
      "elementKey": "matDischargeEdq4",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "7",
      "fqn": "matDischargeEd.matDischargeEdq4"
    },
    {
      "elementKey": "matDischargeEdq5",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "8",
      "fqn": "matDischargeEd.matDischargeEdq5"
    },
    {
      "elementKey": "matDischargeEdq6",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "9",
      "fqn": "matDischargeEd.matDischargeEdq6"
    },
    {
      "elementKey": "matDischargeEdq7",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "10",
      "fqn": "matDischargeEd.matDischargeEdq7"
    },
    {
      "elementKey": "matDischargeEdq8",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "11",
      "fqn": "matDischargeEd.matDischargeEdq8"
    },
    {
      "elementKey": "matDischargeEdq9",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "12",
      "fqn": "matDischargeEd.matDischargeEdq9"
    },
    {
      "elementKey": "matDischargeEdq10",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "13",
      "fqn": "matDischargeEd.matDischargeEdq10"
    },
    {
      "elementKey": "matDischargeEdq11",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "14",
      "fqn": "matDischargeEd.matDischargeEdq11"
    },
    {
      "elementKey": "matDischargeEdq12",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "15",
      "fqn": "matDischargeEd.matDischargeEdq12"
    },
    {
      "elementKey": "matDischargeEdq13",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "16",
      "fqn": "matDischargeEd.matDischargeEdq13"
    },
    {
      "elementKey": "matDischargeEdq14",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "17",
      "fqn": "matDischargeEd.matDischargeEdq14"
    },
    {
      "elementKey": "matDischargeEdq15",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "18",
      "fqn": "matDischargeEd.matDischargeEdq15"
    },
    {
      "elementKey": "matDischargeEdq16",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "19",
      "fqn": "matDischargeEd.matDischargeEdq16"
    },
    {
      "elementKey": "matDischargeEdq17",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "20",
      "fqn": "matDischargeEd.matDischargeEdq17"
    },
    {
      "elementKey": "matDischargeEdq18",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "21",
      "fqn": "matDischargeEd.matDischargeEdq18"
    },
    {
      "elementKey": "matDischargeEdq19",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "22",
      "fqn": "matDischargeEd.matDischargeEdq19"
    },
    {
      "elementKey": "matDischargeEdq20",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "23",
      "fqn": "matDischargeEd.matDischargeEdq20"
    },
    {
      "elementKey": "matDischargeEdq21",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "24",
      "fqn": "matDischargeEd.matDischargeEdq21"
    },
    {
      "elementKey": "matDischargeEdq22",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "25",
      "fqn": "matDischargeEd.matDischargeEdq22"
    },
    {
      "elementKey": "matDischargeEdq23",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "26",
      "fqn": "matDischargeEd.matDischargeEdq23"
    },
    {
      "elementKey": "matDischargeEdComments",
      "formIndex": "1",
      "inputType": "textarea",
      "tableColumn": "28",
      "fqn": "matDischargeEd.matDischargeEdComments"
    }
  ],
  "pageElements": {
    "matDischargeEdForm": {
      "elementKey": "matDischargeEdForm",
      "pageElementIndex": "1",
      "formKey": "matDischargeEdForm",
      "fqn": "matDischargeEd.matDischargeEdForm",
      "isPageForm": true,
      "ehr_groups": [
        {
          "elementKey": "matDischargeEdHdrGrp",
          "formCss": "record-header",
          "fqn": "matDischargeEd.matDischargeEdHdrGrp",
          "gIndex": "1",
          "gChildren": [
            "matDischargeEdForm_name",
            "matDischargeEdForm_profession",
            "matDischargeEdForm_day",
            "matDischargeEdForm_time"
          ]
        },
        {
          "elementKey": "matDischargeEdSnglMultBrths",
          "formCss": "mat-education-list",
          "fqn": "matDischargeEd.matDischargeEdSnglMultBrths",
          "gIndex": "2",
          "gChildren": [
            "matDischargeEdq1",
            "matDischargeEdq2",
            "matDischargeEdq3",
            "matDischargeEdq4",
            "matDischargeEdq5",
            "matDischargeEdq6",
            "matDischargeEdq7",
            "matDischargeEdq8",
            "matDischargeEdq9",
            "matDischargeEdq10",
            "matDischargeEdq11",
            "matDischargeEdq12",
            "matDischargeEdq13",
            "matDischargeEdq14",
            "matDischargeEdq15",
            "matDischargeEdq16",
            "matDischargeEdq17",
            "matDischargeEdq18",
            "matDischargeEdq19",
            "matDischargeEdq20",
            "matDischargeEdq21",
            "matDischargeEdq22",
            "matDischargeEdq23"
          ]
        },
        {
          "elementKey": "matDischargeEdCGrp",
          "formCss": "grid-left-to-right-1",
          "fqn": "matDischargeEd.matDischargeEdCGrp",
          "gIndex": "3",
          "gChildren": [
            "matDischargeEdComments"
          ]
        }
      ]
    }
  },
  "path": "/ehr/peri-ped/maternity",
  "routeName": "mat-discharge-ed",
  "label": "Discharge Education",
  "generateComponent": "v2page",
  "featureFlag": "ffPed",
  "ehr": "yes",
  "fullPageKey": "periPed.maternity.matDischargeEd"
}
export default DEFS