/* eslint-disable */
const DEFS = {
  "pageDataKey": "maternity",
  "pIndex": "81",
  "pageChildren": [],
  "pageElements": {},
  "path": "/ehr/peri-ped",
  "routeName": "maternity",
  "label": "Maternity",
  "generateComponent": "custom",
  "featureFlag": "ffPed",
  "ehr": "yes",
  "fullPageKey": "periPed.maternity"
}
export default DEFS