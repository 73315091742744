export function limitedStackTrace (stack, limit = 10) {
  if (!stack ) {
    stack = new Error().stack
  }
  let list = stack.split('\n') // .slice(1) // Remove "Error" line
  // list = list.filter(line => !line.includes('node_modules'))
  list = list.slice(0,limit)
  return list// .join('\n')
}

export function consoleLimitedStackTrace (limit = 10) {
  const stack = new Error().stack.split('\n').slice(1, limit + 1) // Remove "Error" line and limit depth
  console.log(stack.join('\n'))
}

// Example usage
// limitedStackTrace(10);
