/* eslint-disable */
const DEFS = {
  "pageDataKey": "respiratory",
  "pIndex": "15",
  "hasGridTable": true,
  "pageChildren": [
    {
      "elementKey": "table_name",
      "formIndex": "1",
      "inputType": "practitionerName",
      "tableColumn": "1",
      "recHeader": true,
      "fqn": "respiratory.name"
    },
    {
      "elementKey": "table_id",
      "inputType": "generatedId",
      "tableColumn": 0,
      "label": "Row id",
      "tableCss": "row-id"
    },
    {
      "elementKey": "table_profession",
      "formIndex": "1",
      "inputType": "practitionerProfession",
      "tableColumn": "1",
      "recHeader": true,
      "fqn": "respiratory.profession"
    },
    {
      "elementKey": "table_day",
      "formIndex": "1",
      "inputType": "visitDay",
      "mandatory": true,
      "tableColumn": "1",
      "validation": "visitDay",
      "recHeader": true,
      "fqn": "respiratory.day"
    },
    {
      "elementKey": "table_time",
      "formIndex": "1",
      "inputType": "visitTime",
      "mandatory": true,
      "tableColumn": "1",
      "validation": "time24",
      "recHeader": true,
      "fqn": "respiratory.time"
    },
    {
      "elementKey": "airway",
      "formIndex": "1",
      "inputType": "checkset",
      "options": [
        {
          "key": "patent",
          "text": "Patent"
        },
        {
          "key": "obstructed",
          "text": "Obstructed"
        },
        {
          "key": "oralEndotrachealTube",
          "text": "Oral endotracheal tube"
        },
        {
          "key": "other",
          "text": "Other"
        }
      ],
      "tableColumn": "2",
      "fqn": "respiratory.airway"
    },
    {
      "elementKey": "oxygenTherapy",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "3",
      "fqn": "respiratory.oxygenTherapy"
    },
    {
      "elementKey": "flowRate",
      "formIndex": "1",
      "inputType": "text",
      "suffix": "/lpm",
      "tableColumn": "4",
      "fqn": "respiratory.flowRate",
      "suffixText": "/lpm"
    },
    {
      "elementKey": "lul",
      "formIndex": "1",
      "inputType": "select",
      "options": [
        {
          "key": "Normal",
          "text": "Clear"
        },
        {
          "key": "Wheeze",
          "text": "Wheeze"
        },
        {
          "key": "Crackles",
          "text": "Crackles course"
        },
        {
          "key": "CracklesFine",
          "text": "Crackles fine"
        },
        {
          "key": "Absent",
          "text": "Absent"
        },
        {
          "key": "Decreased",
          "text": "Decreased"
        }
      ],
      "tableColumn": "5",
      "fqn": "respiratory.lul"
    },
    {
      "elementKey": "aSpacer2",
      "formIndex": "1",
      "formCss": "grid-new-subsection",
      "inputType": "spacer",
      "fqn": "respiratory.aSpacer2"
    },
    {
      "elementKey": "lll",
      "formIndex": "1",
      "formCss": "grid-new-subsection",
      "inputType": "select",
      "options": [
        {
          "key": "Normal",
          "text": "Clear"
        },
        {
          "key": "Wheeze",
          "text": "Wheeze"
        },
        {
          "key": "Crackles",
          "text": "Crackles course"
        },
        {
          "key": "CracklesFine",
          "text": "Crackles fine"
        },
        {
          "key": "Absent",
          "text": "Absent"
        },
        {
          "key": "Decreased",
          "text": "Decreased"
        }
      ],
      "tableColumn": "6",
      "fqn": "respiratory.lll"
    },
    {
      "elementKey": "rul",
      "formIndex": "1",
      "inputType": "select",
      "options": [
        {
          "key": "Normal",
          "text": "Clear"
        },
        {
          "key": "Wheeze",
          "text": "Wheeze"
        },
        {
          "key": "Crackles",
          "text": "Crackles course"
        },
        {
          "key": "CracklesFine",
          "text": "Crackles fine"
        },
        {
          "key": "Absent",
          "text": "Absent"
        },
        {
          "key": "Decreased",
          "text": "Decreased"
        }
      ],
      "tableColumn": "7",
      "fqn": "respiratory.rul"
    },
    {
      "elementKey": "rml",
      "formIndex": "1",
      "inputType": "select",
      "options": [
        {
          "key": "Normal",
          "text": "Clear"
        },
        {
          "key": "Wheeze",
          "text": "Wheeze"
        },
        {
          "key": "Crackles",
          "text": "Crackles course"
        },
        {
          "key": "CracklesFine",
          "text": "Crackles fine"
        },
        {
          "key": "Absent",
          "text": "Absent"
        },
        {
          "key": "Decreased",
          "text": "Decreased"
        }
      ],
      "tableColumn": "8",
      "fqn": "respiratory.rml"
    },
    {
      "elementKey": "rll",
      "formIndex": "1",
      "inputType": "select",
      "options": [
        {
          "key": "Normal",
          "text": "Clear"
        },
        {
          "key": "Wheeze",
          "text": "Wheeze"
        },
        {
          "key": "Crackles",
          "text": "Crackles course"
        },
        {
          "key": "CracklesFine",
          "text": "Crackles fine"
        },
        {
          "key": "Absent",
          "text": "Absent"
        },
        {
          "key": "Decreased",
          "text": "Decreased"
        }
      ],
      "tableColumn": "9",
      "fqn": "respiratory.rll"
    },
    {
      "elementKey": "respiratoryRhythm",
      "formIndex": "1",
      "inputType": "select",
      "options": [
        {
          "key": "Regular",
          "text": "Regular"
        },
        {
          "key": "Irregular",
          "text": "Irregular"
        },
        {
          "key": "Paradoxical",
          "text": "Paradoxical"
        }
      ],
      "tableColumn": "10",
      "fqn": "respiratory.respiratoryRhythm"
    },
    {
      "elementKey": "respiratoryDepth",
      "formIndex": "1",
      "inputType": "select",
      "options": [
        {
          "key": "Normal",
          "text": "Normal"
        },
        {
          "key": "Shallow",
          "text": "Shallow"
        },
        {
          "key": "Deep",
          "text": "Deep"
        }
      ],
      "tableColumn": "11",
      "fqn": "respiratory.respiratoryDepth"
    },
    {
      "elementKey": "aSpacer4",
      "formIndex": "1",
      "inputType": "spacer",
      "fqn": "respiratory.aSpacer4"
    },
    {
      "elementKey": "cough",
      "formIndex": "1",
      "formCss": "grid-new-subsection",
      "inputType": "select",
      "options": [
        {
          "key": "No",
          "text": "No"
        },
        {
          "key": "Nonproductive",
          "text": "Nonproductive"
        },
        {
          "key": "Productive",
          "text": "Productive"
        }
      ],
      "tableColumn": "12",
      "fqn": "respiratory.cough"
    },
    {
      "elementKey": "sputumColour",
      "formIndex": "1",
      "formCss": "grid-new-subsection",
      "inputType": "select",
      "options": [
        {
          "key": "mucoid",
          "text": "Mucoid"
        },
        {
          "key": "purulent",
          "text": "Purulent"
        },
        {
          "key": "yellow_green",
          "text": "Yellow-green"
        },
        {
          "key": "rust_coloured",
          "text": "Rust-coloured"
        },
        {
          "key": "pink_blood_tinged",
          "text": "Pink, blood tinged"
        },
        {
          "key": "pink_frothy",
          "text": "Pink, frothy"
        },
        {
          "key": "profuse_colourless",
          "text": "Profuse, colourless"
        },
        {
          "key": "bloody",
          "text": "Bloody"
        }
      ],
      "tableColumn": "13",
      "fqn": "respiratory.sputumColour"
    },
    {
      "elementKey": "sputumComments",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "14",
      "fqn": "respiratory.sputumComments"
    },
    {
      "elementKey": "generalComments",
      "formIndex": "1",
      "formCss": "grid-span-3-columns",
      "inputType": "textarea",
      "tableColumn": "15",
      "fqn": "respiratory.generalComments"
    },
    {
      "elementKey": "ventilator_name",
      "formIndex": "2",
      "inputType": "practitionerName",
      "tableColumn": "1",
      "recHeader": true,
      "fqn": "respiratory.name"
    },
    {
      "elementKey": "ventilator_id",
      "inputType": "generatedId",
      "tableColumn": 0,
      "label": "Row id",
      "tableCss": "row-id"
    },
    {
      "elementKey": "ventilator_profession",
      "formIndex": "2",
      "inputType": "practitionerProfession",
      "tableColumn": "1",
      "recHeader": true,
      "fqn": "respiratory.profession"
    },
    {
      "elementKey": "ventilator_day",
      "formIndex": "2",
      "inputType": "visitDay",
      "mandatory": true,
      "tableColumn": "1",
      "validation": "visitDay",
      "recHeader": true,
      "fqn": "respiratory.day"
    },
    {
      "elementKey": "ventilator_time",
      "formIndex": "2",
      "inputType": "visitTime",
      "mandatory": true,
      "tableColumn": "1",
      "validation": "time24",
      "recHeader": true,
      "fqn": "respiratory.time"
    },
    {
      "elementKey": "ventilatorMode",
      "formIndex": "2",
      "formCss": "grid-span-3-columns",
      "inputType": "select",
      "options": [
        {
          "key": "AC",
          "text": "Assist-Control (AC)"
        },
        {
          "key": "SIMV",
          "text": "Synchronized Intermittent Mandatory Ventilation (SIMV)"
        },
        {
          "key": "PS",
          "text": "Pressure Support (PS)"
        },
        {
          "key": "CPAP",
          "text": "Continuous Positive Airway Pressure (CPAP)"
        },
        {
          "key": "BiPAP",
          "text": "Bilevel Positive Airway Pressure (BiPAP)"
        },
        {
          "key": "HFOV",
          "text": "High-Frequency Oscillatory Ventilation (HFOV)"
        }
      ],
      "tableColumn": "2",
      "fqn": "respiratory.ventilatorMode"
    },
    {
      "elementKey": "tidalVolume",
      "formIndex": "2",
      "inputType": "number",
      "suffix": "mL",
      "tableColumn": "3",
      "fqn": "respiratory.tidalVolume",
      "suffixText": "mL"
    },
    {
      "elementKey": "respiratoryRate",
      "formIndex": "2",
      "inputType": "number",
      "tableColumn": "4",
      "fqn": "respiratory.respiratoryRate"
    },
    {
      "elementKey": "peakInspiratoryPressure",
      "formIndex": "2",
      "inputType": "number",
      "suffix": "cmH2O",
      "tableColumn": "5",
      "fqn": "respiratory.peakInspiratoryPressure",
      "suffixText": "cmH2O"
    },
    {
      "elementKey": "positiveEndExpiratoryPressure",
      "formIndex": "2",
      "inputType": "number",
      "suffix": "cmH2O",
      "tableColumn": "6",
      "fqn": "respiratory.positiveEndExpiratoryPressure",
      "suffixText": "cmH2O"
    },
    {
      "elementKey": "fractionOfInspiredOxygen",
      "formIndex": "2",
      "inputType": "number",
      "tableColumn": "7",
      "fqn": "respiratory.fractionOfInspiredOxygen"
    },
    {
      "elementKey": "inspiratoryTime",
      "formIndex": "2",
      "inputType": "number",
      "suffix": "secs",
      "tableColumn": "8",
      "fqn": "respiratory.inspiratoryTime",
      "suffixText": "secs"
    },
    {
      "elementKey": "highPressureLimitAlarm",
      "formIndex": "2",
      "inputType": "number",
      "suffix": "cmH2O",
      "tableColumn": "9",
      "fqn": "respiratory.highPressureLimitAlarm",
      "suffixText": "cmH2O"
    },
    {
      "elementKey": "lowPressureLimitAlarm",
      "formIndex": "2",
      "inputType": "number",
      "suffix": "cmH2O",
      "tableColumn": "10",
      "fqn": "respiratory.lowPressureLimitAlarm",
      "suffixText": "cmH2O"
    },
    {
      "elementKey": "apneaAlarm",
      "formIndex": "2",
      "inputType": "checkbox",
      "tableColumn": "11",
      "fqn": "respiratory.apneaAlarm"
    },
    {
      "elementKey": "ventComments",
      "formIndex": "2",
      "inputType": "textarea",
      "tableColumn": "1",
      "fqn": "respiratory.ventComments"
    }
  ],
  "pageElements": {
    "table": {
      "elementKey": "table",
      "pageElementIndex": "1",
      "pageKey": "respiratory",
      "tableKey": "table",
      "isTable": true,
      "form": {
        "elementKey": "table",
        "label": "Respiratory assessment",
        "addButtonText": "Add a respiratory assessment",
        "formOption": "transpose",
        "formKey": "table",
        "ehr_groups": [
          {
            "elementKey": "hdrGroup",
            "formCss": "record-header",
            "fqn": "respiratory.hdrGroup",
            "gIndex": "1",
            "gChildren": [
              "table_name",
              "table_profession",
              "table_day",
              "table_time"
            ]
          },
          {
            "elementKey": "respGroup1",
            "formCss": "grid-left-to-right-3",
            "fqn": "respiratory.respGroup1",
            "gIndex": "2",
            "gChildren": [
              "airway",
              "oxygenTherapy",
              "flowRate"
            ]
          },
          {
            "elementKey": "respGroup2",
            "formCss": "grid-left-to-right-3",
            "fqn": "respiratory.respGroup2",
            "gIndex": "3",
            "gChildren": [
              "lul",
              "aSpacer2",
              "lll",
              "rul",
              "rml",
              "rll"
            ]
          },
          {
            "elementKey": "respGrou3",
            "formCss": "grid-left-to-right-3",
            "fqn": "respiratory.respGrou3",
            "gIndex": "4",
            "gChildren": [
              "respiratoryRhythm",
              "respiratoryDepth",
              "aSpacer4",
              "cough",
              "sputumColour",
              "sputumComments",
              "generalComments"
            ]
          }
        ],
        "ehr_data": {
          "table_name": "",
          "table_profession": "",
          "table_day": "",
          "table_time": "",
          "airway": "",
          "oxygenTherapy": "",
          "flowRate": "",
          "lul": "",
          "lll": "",
          "rul": "",
          "rml": "",
          "rll": "",
          "respiratoryRhythm": "",
          "respiratoryDepth": "",
          "cough": "",
          "sputumColour": "",
          "sputumComments": "",
          "generalComments": ""
        }
      },
      "fqn": "respiratory.table",
      "tableChildren": [
        "table_id",
        "table_day",
        "table_time",
        "airway",
        "oxygenTherapy",
        "flowRate",
        "lul",
        "lll",
        "rul",
        "rml",
        "rll",
        "respiratoryRhythm",
        "respiratoryDepth",
        "cough",
        "sputumColour",
        "sputumComments",
        "generalComments",
        "table_name",
        "table_profession"
      ],
      "hasRecHeader": true
    },
    "ventilator": {
      "elementKey": "ventilator",
      "pageElementIndex": "2",
      "pageKey": "respiratory",
      "tableKey": "ventilator",
      "isTable": true,
      "form": {
        "elementKey": "ventilator",
        "label": "Ventilator",
        "addButtonText": "Add a ventilator settings",
        "formOption": "transpose",
        "formKey": "ventilator",
        "ehr_groups": [
          {
            "elementKey": "hdrGroup",
            "formCss": "record-header",
            "fqn": "respiratory.hdrGroup",
            "gIndex": "1",
            "gChildren": [
              "ventilator_name",
              "ventilator_profession",
              "ventilator_day",
              "ventilator_time"
            ]
          },
          {
            "elementKey": "ventGroup1",
            "formCss": "grid-left-to-right-3",
            "fqn": "respiratory.ventGroup1",
            "gIndex": "2",
            "gChildren": [
              "ventilatorMode",
              "tidalVolume",
              "respiratoryRate",
              "peakInspiratoryPressure",
              "positiveEndExpiratoryPressure",
              "fractionOfInspiredOxygen",
              "inspiratoryTime",
              "highPressureLimitAlarm",
              "lowPressureLimitAlarm",
              "apneaAlarm"
            ]
          },
          {
            "elementKey": "ventGroup6",
            "formCss": "grid-left-to-right-1",
            "fqn": "respiratory.ventGroup6",
            "gIndex": "3",
            "gChildren": [
              "ventComments"
            ]
          }
        ],
        "ehr_data": {
          "ventilator_name": "",
          "ventilator_profession": "",
          "ventilator_day": "",
          "ventilator_time": "",
          "ventilatorMode": "",
          "tidalVolume": "",
          "respiratoryRate": "",
          "peakInspiratoryPressure": "",
          "positiveEndExpiratoryPressure": "",
          "fractionOfInspiredOxygen": "",
          "inspiratoryTime": "",
          "highPressureLimitAlarm": "",
          "lowPressureLimitAlarm": "",
          "apneaAlarm": "",
          "ventComments": ""
        }
      },
      "fqn": "respiratory.ventilator",
      "tableChildren": [
        "ventilator_id",
        "ventilator_day",
        "ventilator_time",
        "ventilatorMode",
        "tidalVolume",
        "respiratoryRate",
        "peakInspiratoryPressure",
        "positiveEndExpiratoryPressure",
        "fractionOfInspiredOxygen",
        "inspiratoryTime",
        "highPressureLimitAlarm",
        "lowPressureLimitAlarm",
        "apneaAlarm",
        "ventComments",
        "ventilator_name",
        "ventilator_profession"
      ],
      "hasRecHeader": true
    }
  },
  "path": "/ehr/current/assessments",
  "routeName": "respiratory",
  "label": "Respiratory assessment",
  "redirectEhr": "",
  "redirectLis": "",
  "ehr": "yes",
  "fullPageKey": "current.assessments.respiratory"
}
export default DEFS