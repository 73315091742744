/* eslint-disable */
const DEFS = {
  "pageDataKey": "vitals",
  "pIndex": "11",
  "hasGridTable": true,
  "pageChildren": [
    {
      "elementKey": "table_name",
      "formIndex": "1",
      "inputType": "practitionerName",
      "tableColumn": "1",
      "recHeader": true,
      "fqn": "vitals.name"
    },
    {
      "elementKey": "table_id",
      "inputType": "generatedId",
      "tableColumn": 0,
      "label": "Row id",
      "tableCss": "row-id"
    },
    {
      "elementKey": "table_profession",
      "formIndex": "1",
      "inputType": "practitionerProfession",
      "tableColumn": "1",
      "recHeader": true,
      "fqn": "vitals.profession"
    },
    {
      "elementKey": "table_day",
      "formIndex": "1",
      "inputType": "visitDay",
      "mandatory": true,
      "tableColumn": "1",
      "validation": "visitDay",
      "recHeader": true,
      "fqn": "vitals.day"
    },
    {
      "elementKey": "table_time",
      "formIndex": "1",
      "inputType": "visitTime",
      "mandatory": true,
      "tableColumn": "1",
      "validation": "time24",
      "recHeader": true,
      "fqn": "vitals.time"
    },
    {
      "elementKey": "rate",
      "formIndex": "1",
      "formCss": "number-short",
      "inputType": "number",
      "passToFunction": "news2Pulse",
      "tableColumn": "3",
      "validation": "numeric",
      "fqn": "vitals.rate"
    },
    {
      "elementKey": "vitalsHemi",
      "formIndex": "1",
      "inputType": "radioset",
      "options": [
        {
          "key": "left",
          "text": "Left"
        },
        {
          "key": "right",
          "text": "Right"
        }
      ],
      "tableColumn": "3",
      "fqn": "vitals.vitalsHemi"
    },
    {
      "elementKey": "strength",
      "formIndex": "1",
      "inputType": "radioset",
      "options": [
        {
          "key": "apical",
          "text": "Apical"
        },
        {
          "key": "brachial",
          "text": "Brachial artery (arm)"
        },
        {
          "key": "carotid",
          "text": "Carotid artery (neck)"
        },
        {
          "key": "dorsalis",
          "text": "Dorsalis pedis artery (foot)"
        },
        {
          "key": "femoral",
          "text": "Femoral artery (groin)"
        },
        {
          "key": "popliteal",
          "text": "Popliteal artery (behind knee)"
        },
        {
          "key": "tibial",
          "text": "Posterior tibial artery (ankle joint)"
        },
        {
          "key": "radial",
          "text": "Radial artery (wrist)"
        },
        {
          "key": "Peripheral",
          "text": "Peripheral"
        },
        {
          "key": "Other",
          "text": "Other"
        }
      ],
      "tableColumn": "4",
      "fqn": "vitals.strength"
    },
    {
      "elementKey": "pulseSource",
      "formIndex": "1",
      "inputType": "radioset",
      "options": [
        {
          "key": "oximeter",
          "text": "Pulse oximeter"
        },
        {
          "key": "cardia",
          "text": "Cardiac monitor"
        },
        {
          "key": "auto",
          "text": "Automatic monitor"
        },
        {
          "key": "palpation",
          "text": "Palpation"
        },
        {
          "key": "auscultation",
          "text": "Auscultation"
        }
      ],
      "tableColumn": "5",
      "fqn": "vitals.pulseSource"
    },
    {
      "elementKey": "rhythm",
      "formIndex": "1",
      "inputType": "radioset",
      "options": [
        {
          "key": "Regular",
          "text": "Regular"
        },
        {
          "key": "Irregular",
          "text": "Irregular"
        }
      ],
      "tableColumn": "6",
      "fqn": "vitals.rhythm"
    },
    {
      "elementKey": "pulseSp",
      "formIndex": "1",
      "inputType": "spacer",
      "tableColumn": "7",
      "fqn": "vitals.pulseSp"
    },
    {
      "elementKey": "news2Pulse",
      "calculationType": "newsPulse",
      "formIndex": "1",
      "inputType": "calculatedValue",
      "passToFunction": "vitalNewsTotal",
      "tableColumn": "8",
      "fqn": "vitals.news2Pulse"
    },
    {
      "elementKey": "news2PulseInfo",
      "formIndex": "1",
      "inputType": "formInfoText",
      "tableColumn": "9",
      "fqn": "vitals.news2PulseInfo"
    },
    {
      "elementKey": "respirationRate",
      "formIndex": "1",
      "formCss": "number-short",
      "inputType": "number",
      "passToFunction": "news2Respiration",
      "tableColumn": "11",
      "validation": "numeric",
      "fqn": "vitals.respirationRate"
    },
    {
      "elementKey": "news2RespSp",
      "formIndex": "1",
      "inputType": "spacer",
      "tableColumn": "12",
      "fqn": "vitals.news2RespSp"
    },
    {
      "elementKey": "news2Respiration",
      "calculationType": "newsRespiration",
      "formIndex": "1",
      "inputType": "calculatedValue",
      "passToFunction": "vitalNewsTotal",
      "tableColumn": "13",
      "fqn": "vitals.news2Respiration"
    },
    {
      "elementKey": "news2RespInfo",
      "formIndex": "1",
      "inputType": "formInfoText",
      "tableColumn": "14",
      "fqn": "vitals.news2RespInfo"
    },
    {
      "elementKey": "systolic",
      "formIndex": "1",
      "formCss": "number-short",
      "inputType": "number",
      "passToFunction": "[meanArterialPressure,news2Systolic]",
      "tableColumn": "16",
      "validation": "numeric",
      "fqn": "vitals.systolic"
    },
    {
      "elementKey": "diastolic",
      "formIndex": "1",
      "formCss": "number-short",
      "inputType": "number",
      "passToFunction": "meanArterialPressure",
      "tableColumn": "17",
      "validation": "numeric",
      "fqn": "vitals.diastolic"
    },
    {
      "elementKey": "meanArterialPressure",
      "calculationType": "meanArterialPressure",
      "formIndex": "1",
      "inputType": "calculatedValue",
      "suffix": "mmHg",
      "tableColumn": "18",
      "fqn": "vitals.meanArterialPressure",
      "suffixText": "mmHg"
    },
    {
      "elementKey": "patientPosition",
      "formIndex": "1",
      "inputType": "radioset",
      "options": [
        {
          "key": "Supine",
          "text": "Supine"
        },
        {
          "key": "Sitting",
          "text": "Sitting"
        },
        {
          "key": "Standing",
          "text": "Standing"
        },
        {
          "key": "left",
          "text": "Left lateral"
        },
        {
          "key": "right",
          "text": "Right lateral"
        },
        {
          "key": "prone",
          "text": "Prone"
        }
      ],
      "tableColumn": "18",
      "fqn": "vitals.patientPosition"
    },
    {
      "elementKey": "bpSource",
      "formIndex": "1",
      "inputType": "radioset",
      "options": [
        {
          "key": "auto",
          "text": "Automatic cuff"
        },
        {
          "key": "manual",
          "text": "Manual cuff"
        },
        {
          "key": "arterial",
          "text": "Arterial line"
        }
      ],
      "tableColumn": "19",
      "fqn": "vitals.bpSource"
    },
    {
      "elementKey": "news2Systolic",
      "calculationType": "newsSystolic",
      "formIndex": "1",
      "inputType": "calculatedValue",
      "passToFunction": "vitalNewsTotal",
      "tableColumn": "20",
      "fqn": "vitals.news2Systolic"
    },
    {
      "elementKey": "news2SysInfo",
      "formIndex": "1",
      "inputType": "formInfoText",
      "tableColumn": "21",
      "fqn": "vitals.news2SysInfo"
    },
    {
      "elementKey": "temperature",
      "formIndex": "1",
      "formCss": "number-short",
      "inputType": "decimal1",
      "passToFunction": "news2Temperature",
      "suffix": "C",
      "tableColumn": "23",
      "validation": "numeric",
      "fqn": "vitals.temperature",
      "suffixText": "C"
    },
    {
      "elementKey": "source",
      "formIndex": "1",
      "inputType": "radioset",
      "options": [
        {
          "key": "Oral",
          "text": "Oral"
        },
        {
          "key": "Axilla",
          "text": "Axilla"
        },
        {
          "key": "Rectal",
          "text": "Rectal"
        },
        {
          "key": "Tympanic",
          "text": "Tympanic"
        }
      ],
      "passToFunction": "temperatureRangeCalc",
      "tableColumn": "24",
      "fqn": "vitals.source"
    },
    {
      "elementKey": "temperatureRangeCalc",
      "calculationType": "text",
      "formIndex": "1",
      "inputType": "calculatedText",
      "options": [
        {
          "key": "Oral",
          "text": "36.0–37.5°C"
        },
        {
          "key": "Rectal",
          "text": "36.5–38.0°C"
        },
        {
          "key": "Axilla",
          "text": "35.9–37.2°C"
        },
        {
          "key": "Tympanic",
          "text": "35.5–37.5°C"
        },
        {
          "key": "forehead",
          "text": "35.8–37.5°C"
        },
        {
          "key": "temporal",
          "text": "36.1–37.9°C"
        },
        {
          "key": "skinProbe",
          "text": "Variable"
        },
        {
          "key": "urinary",
          "text": "36.5–37.8°C"
        },
        {
          "key": "rectalProbe",
          "text": "36.5–38.0°C"
        },
        {
          "key": "esophageal",
          "text": "36.5–38.0°C"
        },
        {
          "key": "paCatheter",
          "text": "36.5–38.0°C"
        }
      ],
      "tableColumn": "25",
      "fqn": "vitals.temperatureRangeCalc"
    },
    {
      "elementKey": "news2TempSp",
      "formIndex": "1",
      "inputType": "spacer",
      "tableColumn": "26",
      "fqn": "vitals.news2TempSp"
    },
    {
      "elementKey": "news2Temperature",
      "calculationType": "newsTemperature",
      "formIndex": "1",
      "inputType": "calculatedValue",
      "passToFunction": "vitalNewsTotal",
      "tableColumn": "27",
      "fqn": "vitals.news2Temperature"
    },
    {
      "elementKey": "news2TemperatureInfo",
      "formIndex": "1",
      "inputType": "formInfoText",
      "tableColumn": "28",
      "fqn": "vitals.news2TemperatureInfo"
    },
    {
      "elementKey": "oxygenSaturation",
      "formIndex": "1",
      "formCss": "number-short",
      "inputType": "number",
      "passToFunction": "news2Oxygen",
      "suffix": "%",
      "tableColumn": "30",
      "validation": "numeric",
      "fqn": "vitals.oxygenSaturation",
      "suffixText": "%"
    },
    {
      "elementKey": "oxygenMode",
      "formIndex": "1",
      "inputType": "radioset",
      "options": [
        {
          "key": "Room air",
          "text": "Room air"
        },
        {
          "key": "Nasal prongs",
          "text": "Nasal prongs"
        },
        {
          "key": "Simple face mask",
          "text": "Simple face mask"
        },
        {
          "key": "Rebreathing face mask",
          "text": "Rebreathing face mask"
        },
        {
          "key": "Optiflow",
          "text": "Optiflow"
        }
      ],
      "passToFunction": "[news2Air,news2Oxygen]",
      "tableColumn": "31",
      "fqn": "vitals.oxygenMode"
    },
    {
      "elementKey": "flowRate",
      "formIndex": "1",
      "formCss": "number-short",
      "inputType": "decimal1",
      "suffix": "/lpm",
      "tableColumn": "32",
      "validation": "numeric",
      "fqn": "vitals.flowRate",
      "suffixText": "/lpm"
    },
    {
      "elementKey": "spO2Scale",
      "formIndex": "1",
      "inputType": "radioset",
      "options": [
        {
          "key": "scale1",
          "text": "Scale 1 SpO2 96-100% expected"
        },
        {
          "key": "scale2",
          "text": "Scale 2 SpO2 88-92% (for chronic conditions)"
        }
      ],
      "passToFunction": "news2Oxygen",
      "tableColumn": "33",
      "fqn": "vitals.spO2Scale"
    },
    {
      "elementKey": "news2Air",
      "calculationType": "newsAir",
      "formIndex": "1",
      "inputType": "calculatedValue",
      "passToFunction": "vitalNewsTotal",
      "tableColumn": "34",
      "fqn": "vitals.news2Air"
    },
    {
      "elementKey": "news2AirInfo",
      "formIndex": "1",
      "inputType": "formInfoText",
      "tableColumn": "35",
      "fqn": "vitals.news2AirInfo"
    },
    {
      "elementKey": "news2Oxygen",
      "calculationType": "newsOxygen",
      "formIndex": "1",
      "inputType": "calculatedValue",
      "passToFunction": "vitalNewsTotal",
      "tableColumn": "35",
      "fqn": "vitals.news2Oxygen"
    },
    {
      "elementKey": "news2OxygenInfo",
      "formIndex": "1",
      "inputType": "formInfoText",
      "tableColumn": "36",
      "fqn": "vitals.news2OxygenInfo"
    },
    {
      "elementKey": "vitalsAcvpu",
      "formIndex": "1",
      "inputType": "radioset",
      "options": [
        {
          "key": "alert",
          "text": "Alert"
        },
        {
          "key": "newC",
          "text": "New-onset confusion"
        },
        {
          "key": "voice",
          "text": "Voice"
        },
        {
          "key": "pain",
          "text": "Pain"
        },
        {
          "key": "unresp",
          "text": "Unresponsive"
        }
      ],
      "passToFunction": "news2Consciousness",
      "tableColumn": "37",
      "fqn": "vitals.vitalsAcvpu"
    },
    {
      "elementKey": "news2Consciousness",
      "calculationType": "newsConsciousness",
      "formIndex": "1",
      "inputType": "calculatedValue",
      "passToFunction": "vitalNewsTotal",
      "tableColumn": "38",
      "fqn": "vitals.news2Consciousness"
    },
    {
      "elementKey": "vitalNewsTotal",
      "calculationType": "vitalNewsTotal",
      "formIndex": "1",
      "inputType": "calculatedValue",
      "tableColumn": "40",
      "fqn": "vitals.vitalNewsTotal"
    },
    {
      "elementKey": "vitalNewsInfo",
      "formIndex": "1",
      "inputType": "formInfoText",
      "tableColumn": "41",
      "fqn": "vitals.vitalNewsInfo"
    },
    {
      "elementKey": "vitalNewsEcalation",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "42",
      "fqn": "vitals.vitalNewsEcalation"
    },
    {
      "elementKey": "bloodSugar",
      "formIndex": "1",
      "formCss": "number-short",
      "inputType": "decimal2",
      "suffix": "mmol/L",
      "tableColumn": "44",
      "validation": "numeric",
      "fqn": "vitals.bloodSugar",
      "suffixText": "mmol/L"
    },
    {
      "elementKey": "glucoseWarn",
      "formIndex": "1",
      "inputType": "form_label",
      "tableColumn": "45",
      "fqn": "vitals.glucoseWarn"
    },
    {
      "elementKey": "painLevel",
      "formIndex": "1",
      "formCss": "number-short",
      "inputType": "number",
      "tableColumn": "47",
      "validation": "numeric",
      "fqn": "vitals.painLevel"
    },
    {
      "elementKey": "painWarn",
      "formIndex": "1",
      "formCss": "grid-span-2-columns",
      "inputType": "form_label",
      "tableColumn": "48",
      "fqn": "vitals.painWarn"
    },
    {
      "elementKey": "cvp",
      "formIndex": "1",
      "formCss": "number-short",
      "inputType": "number",
      "tableColumn": "50",
      "validation": "cvp",
      "fqn": "vitals.cvp"
    },
    {
      "elementKey": "vitalsComment",
      "formIndex": "1",
      "inputType": "textarea",
      "tableColumn": "52",
      "fqn": "vitals.vitalsComment"
    },
    {
      "elementKey": "glucoseTable_name",
      "formIndex": "2",
      "inputType": "practitionerName",
      "tableColumn": "1",
      "recHeader": true,
      "fqn": "vitals.name"
    },
    {
      "elementKey": "glucoseTable_id",
      "inputType": "generatedId",
      "tableColumn": 0,
      "label": "Row id",
      "tableCss": "row-id"
    },
    {
      "elementKey": "glucoseTable_profession",
      "formIndex": "2",
      "inputType": "practitionerProfession",
      "tableColumn": "1",
      "recHeader": true,
      "fqn": "vitals.profession"
    },
    {
      "elementKey": "glucoseTable_day",
      "formIndex": "2",
      "inputType": "visitDay",
      "mandatory": true,
      "tableColumn": "1",
      "validation": "visitDay",
      "recHeader": true,
      "fqn": "vitals.day"
    },
    {
      "elementKey": "glucoseTable_time",
      "formIndex": "2",
      "inputType": "visitTime",
      "mandatory": true,
      "tableColumn": "1",
      "validation": "time24",
      "recHeader": true,
      "fqn": "vitals.time"
    },
    {
      "elementKey": "gluBloodSugar",
      "formIndex": "2",
      "inputType": "text",
      "suffix": "mmol/L",
      "tableColumn": "2",
      "validation": "numeric",
      "fqn": "vitals.gluBloodSugar",
      "suffixText": "mmol/L"
    },
    {
      "elementKey": "gluWhen",
      "formIndex": "2",
      "inputType": "select",
      "options": [
        {
          "key": "brk",
          "text": "Before breakfast"
        },
        {
          "key": "lunch",
          "text": "Before lunch"
        },
        {
          "key": "supper",
          "text": "Before supper"
        },
        {
          "key": "bed",
          "text": "Before bedtime snack"
        },
        {
          "key": "outside",
          "text": "Outside meal times"
        }
      ],
      "tableColumn": "3",
      "fqn": "vitals.gluWhen"
    },
    {
      "elementKey": "comment",
      "formIndex": "2",
      "inputType": "textarea",
      "tableColumn": "4",
      "fqn": "vitals.comment"
    }
  ],
  "pageElements": {
    "table": {
      "elementKey": "table",
      "pageElementIndex": "1",
      "pageKey": "vitals",
      "tableKey": "table",
      "isTable": true,
      "form": {
        "elementKey": "table",
        "label": "Vital signs",
        "addButtonText": "Add vital signs",
        "formKey": "table",
        "ehr_groups": [
          {
            "elementKey": "hdrGroup",
            "formCss": "record-header",
            "fqn": "vitals.hdrGroup",
            "gIndex": "1",
            "gChildren": [
              "table_name",
              "table_profession",
              "table_day",
              "table_time"
            ]
          },
          {
            "elementKey": "vitalsGrp_1",
            "formCss": "ehr-row-flow",
            "fqn": "vitals.vitalsGrp_1",
            "gIndex": "2",
            "gChildren": [
              "rate",
              "vitalsHemi",
              "strength",
              "pulseSource",
              "rhythm",
              "pulseSp",
              "news2Pulse",
              "news2PulseInfo"
            ]
          },
          {
            "elementKey": "vitalsGrp_2",
            "formCss": "ehr-row-flow",
            "fqn": "vitals.vitalsGrp_2",
            "gIndex": "3",
            "gChildren": [
              "respirationRate",
              "news2RespSp",
              "news2Respiration",
              "news2RespInfo"
            ]
          },
          {
            "elementKey": "cGroup",
            "formCss": "ehr-row-flow",
            "fqn": "vitals.cGroup",
            "gIndex": "4",
            "gChildren": [
              "systolic",
              "diastolic",
              "meanArterialPressure",
              "patientPosition",
              "bpSource",
              "news2Systolic",
              "news2SysInfo"
            ]
          },
          {
            "elementKey": "vitalsGrp_3",
            "formCss": "ehr-row-flow",
            "fqn": "vitals.vitalsGrp_3",
            "gIndex": "5",
            "gChildren": [
              "temperature",
              "source",
              "temperatureRangeCalc",
              "news2TempSp",
              "news2Temperature",
              "news2TemperatureInfo"
            ]
          },
          {
            "elementKey": "vitalsGrp_4",
            "formCss": "ehr-row-flow",
            "fqn": "vitals.vitalsGrp_4",
            "gIndex": "6",
            "gChildren": [
              "oxygenSaturation",
              "oxygenMode",
              "flowRate",
              "spO2Scale",
              "news2Air",
              "news2AirInfo",
              "news2Oxygen",
              "news2OxygenInfo"
            ]
          },
          {
            "elementKey": "vitalsGrp_5",
            "formCss": "ehr-row-flow",
            "fqn": "vitals.vitalsGrp_5",
            "gIndex": "7",
            "gChildren": [
              "vitalsAcvpu",
              "news2Consciousness"
            ]
          },
          {
            "elementKey": "vitalsGrp_NEWS",
            "formCss": "ehr-row-flow",
            "fqn": "vitals.vitalsGrp_NEWS",
            "gIndex": "8",
            "gChildren": [
              "vitalNewsTotal",
              "vitalNewsInfo"
            ]
          },
          {
            "elementKey": "vitalNewsCommentsGrp",
            "formCss": "grid-left-to-right-1",
            "fqn": "vitals.vitalNewsCommentsGrp",
            "gIndex": "9",
            "gChildren": [
              "vitalNewsEcalation"
            ]
          },
          {
            "elementKey": "vitalsGrp_6",
            "formCss": "ehr-row-flow",
            "fqn": "vitals.vitalsGrp_6",
            "gIndex": "10",
            "gChildren": [
              "bloodSugar",
              "glucoseWarn"
            ]
          },
          {
            "elementKey": "vitalsGrp_8",
            "formCss": "ehr-row-flow",
            "fqn": "vitals.vitalsGrp_8",
            "gIndex": "11",
            "gChildren": [
              "painLevel",
              "painWarn"
            ]
          },
          {
            "elementKey": "vitalsGrp_9",
            "formCss": "ehr-row-flow",
            "fqn": "vitals.vitalsGrp_9",
            "gIndex": "12",
            "gChildren": [
              "cvp"
            ]
          },
          {
            "elementKey": "vitalsGrp",
            "formCss": "grid-left-to-right-1",
            "fqn": "vitals.vitalsGrp",
            "gIndex": "13",
            "gChildren": [
              "vitalsComment"
            ]
          }
        ],
        "ehr_data": {
          "table_name": "",
          "table_profession": "",
          "table_day": "",
          "table_time": "",
          "rate": "",
          "vitalsHemi": "",
          "strength": "",
          "pulseSource": "",
          "rhythm": "",
          "news2Pulse": "",
          "respirationRate": "",
          "news2Respiration": "",
          "systolic": "",
          "diastolic": "",
          "meanArterialPressure": "",
          "patientPosition": "",
          "bpSource": "",
          "news2Systolic": "",
          "temperature": "",
          "source": "",
          "temperatureRangeCalc": "",
          "news2Temperature": "",
          "oxygenSaturation": "",
          "oxygenMode": "",
          "flowRate": "",
          "spO2Scale": "",
          "news2Air": "",
          "news2Oxygen": "",
          "vitalsAcvpu": "",
          "news2Consciousness": "",
          "vitalNewsTotal": "",
          "vitalNewsEcalation": "",
          "bloodSugar": "",
          "painLevel": "",
          "cvp": "",
          "vitalsComment": ""
        }
      },
      "fqn": "vitals.table",
      "tableChildren": [
        "table_id",
        "table_day",
        "table_time",
        "rate",
        "vitalsHemi",
        "strength",
        "pulseSource",
        "rhythm",
        "pulseSp",
        "news2Pulse",
        "news2PulseInfo",
        "respirationRate",
        "news2RespSp",
        "news2Respiration",
        "news2RespInfo",
        "systolic",
        "diastolic",
        "meanArterialPressure",
        "patientPosition",
        "bpSource",
        "news2Systolic",
        "news2SysInfo",
        "temperature",
        "source",
        "temperatureRangeCalc",
        "news2TempSp",
        "news2Temperature",
        "news2TemperatureInfo",
        "oxygenSaturation",
        "oxygenMode",
        "flowRate",
        "spO2Scale",
        "news2Air",
        "news2AirInfo",
        "news2Oxygen",
        "news2OxygenInfo",
        "vitalsAcvpu",
        "news2Consciousness",
        "vitalNewsTotal",
        "vitalNewsInfo",
        "vitalNewsEcalation",
        "bloodSugar",
        "glucoseWarn",
        "painLevel",
        "painWarn",
        "cvp",
        "vitalsComment",
        "table_name",
        "table_profession"
      ],
      "hasRecHeader": true
    },
    "glucoseTable": {
      "elementKey": "glucoseTable",
      "pageElementIndex": "2",
      "pageKey": "vitals",
      "tableKey": "glucoseTable",
      "isTable": true,
      "form": {
        "elementKey": "glucoseTable",
        "label": "Glucose",
        "addButtonText": "Add glucose reading",
        "formOption": "transpose",
        "formKey": "glucoseTable",
        "ehr_groups": [
          {
            "elementKey": "gluGrp1",
            "formCss": "record-header",
            "fqn": "vitals.gluGrp1",
            "gIndex": "1",
            "gChildren": [
              "glucoseTable_name",
              "glucoseTable_profession",
              "glucoseTable_day",
              "glucoseTable_time"
            ]
          },
          {
            "elementKey": "gluGrp2",
            "formCss": "grid-left-to-right-3",
            "fqn": "vitals.gluGrp2",
            "gIndex": "2",
            "gChildren": [
              "gluBloodSugar",
              "gluWhen"
            ]
          },
          {
            "elementKey": "gluGrp3",
            "formCss": "grid-left-to-right-1",
            "fqn": "vitals.gluGrp3",
            "gIndex": "3",
            "gChildren": [
              "comment"
            ]
          }
        ],
        "ehr_data": {
          "glucoseTable_name": "",
          "glucoseTable_profession": "",
          "glucoseTable_day": "",
          "glucoseTable_time": "",
          "gluBloodSugar": "",
          "gluWhen": "",
          "comment": ""
        }
      },
      "fqn": "vitals.glucoseTable",
      "tableChildren": [
        "glucoseTable_id",
        "glucoseTable_day",
        "glucoseTable_time",
        "gluBloodSugar",
        "gluWhen",
        "comment",
        "glucoseTable_name",
        "glucoseTable_profession"
      ],
      "hasRecHeader": true
    }
  },
  "path": "/ehr/current",
  "routeName": "vital-signs",
  "label": "Vital signs",
  "redirectEhr": "",
  "redirectLis": "",
  "generateComponent": "custom",
  "ehr": "yes",
  "fullPageKey": "current.vitals"
}
export default DEFS