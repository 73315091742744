/* eslint-disable */
const DEFS = {
  "pageDataKey": "assessmentTools",
  "pIndex": "35",
  "hasGridForm": true,
  "pageChildren": [
    {
      "elementKey": "admissions1_1",
      "assetBase": "standardized-assessment-tools",
      "assetName": "the-canadian-ed-triage-and-acuity-scale.gif",
      "formIndex": "1",
      "inputType": "assetLink",
      "fqn": "assessmentTools.admissions1_1"
    },
    {
      "elementKey": "admissions2_1",
      "assetBase": "standardized-assessment-tools",
      "assetName": "patient-health-questionnaire.pdf",
      "formIndex": "1",
      "inputType": "assetLink",
      "fqn": "assessmentTools.admissions2_1"
    },
    {
      "elementKey": "admissions3_1",
      "assetBase": "standardized-assessment-tools",
      "assetName": "audit.pdf",
      "formIndex": "1",
      "inputType": "assetLink",
      "fqn": "assessmentTools.admissions3_1"
    },
    {
      "elementKey": "painAssessment1_1",
      "assetBase": "standardized-assessment-tools",
      "assetName": "opqrstuv.pdf",
      "formIndex": "1",
      "inputType": "assetLink",
      "fqn": "assessmentTools.painAssessment1_1"
    },
    {
      "elementKey": "painAssessment2_1",
      "assetBase": "standardized-assessment-tools",
      "assetName": "numeric-rating-scale.pdf",
      "formIndex": "1",
      "inputType": "assetLink",
      "fqn": "assessmentTools.painAssessment2_1"
    },
    {
      "elementKey": "neuroAssessment1_1",
      "assetBase": "standardized-assessment-tools",
      "assetName": "glasgow-coma-scale.pdf",
      "formIndex": "1",
      "inputType": "assetLink",
      "fqn": "assessmentTools.neuroAssessment1_1"
    },
    {
      "elementKey": "neuroAssessment2_1",
      "assetBase": "standardized-assessment-tools",
      "assetName": "mini-mental-health-screen.pdf",
      "formIndex": "1",
      "inputType": "assetLink",
      "fqn": "assessmentTools.neuroAssessment2_1"
    },
    {
      "elementKey": "neuroAssessment3_1",
      "assetBase": "standardized-assessment-tools",
      "assetName": "richmond-agitation-sedation-scale-rass.pdf",
      "formIndex": "1",
      "inputType": "assetLink",
      "fqn": "assessmentTools.neuroAssessment3_1"
    },
    {
      "elementKey": "fallRisk1_1",
      "assetBase": "standardized-assessment-tools",
      "assetName": "fall-risk-assessment-tool.pdf",
      "formIndex": "1",
      "inputType": "assetLink",
      "fqn": "assessmentTools.fallRisk1_1"
    },
    {
      "elementKey": "fallRisk2_1",
      "assetBase": "standardized-assessment-tools",
      "assetName": "risk-for-falls.png",
      "formIndex": "1",
      "inputType": "assetLink",
      "fqn": "assessmentTools.fallRisk2_1"
    },
    {
      "elementKey": "notes1_1",
      "assetBase": "standardized-assessment-tools",
      "assetName": "consultation-form.pdf",
      "formIndex": "1",
      "inputType": "assetLink",
      "fqn": "assessmentTools.notes1_1"
    },
    {
      "elementKey": "educational1_1",
      "assetBase": "standardized-assessment-tools",
      "assetName": "copd-assessment-test.pdf",
      "formIndex": "1",
      "inputType": "assetLink",
      "fqn": "assessmentTools.educational1_1"
    },
    {
      "elementKey": "educational2_1",
      "assetBase": "standardized-assessment-tools",
      "assetName": "bristol-stool-chart.pdf",
      "formIndex": "1",
      "inputType": "assetLink",
      "fqn": "assessmentTools.educational2_1"
    },
    {
      "elementKey": "wound1_1",
      "assetBase": "standardized-assessment-tools",
      "assetName": "braden-scale.pdf",
      "formIndex": "1",
      "inputType": "assetLink",
      "fqn": "assessmentTools.wound1_1"
    },
    {
      "elementKey": "medication1_1",
      "assetBase": "standardized-assessment-tools",
      "assetName": "best-possible-medication.pdf",
      "formIndex": "1",
      "inputType": "assetLink",
      "fqn": "assessmentTools.medication1_1"
    }
  ],
  "pageElements": {
    "pageForm": {
      "elementKey": "pageForm",
      "pageElementIndex": "1",
      "formKey": "pageForm",
      "fqn": "assessmentTools.pageForm",
      "isPageForm": true,
      "ehr_groups": [
        {
          "elementKey": "admissions",
          "formCss": "grid-left-to-right-3",
          "fqn": "assessmentTools.admissions",
          "gIndex": "1",
          "gChildren": [
            "admissions1_1",
            "admissions2_1",
            "admissions3_1"
          ]
        },
        {
          "elementKey": "painAssessment",
          "formCss": "grid-left-to-right-3",
          "fqn": "assessmentTools.painAssessment",
          "gIndex": "2",
          "gChildren": [
            "painAssessment1_1",
            "painAssessment2_1"
          ]
        },
        {
          "elementKey": "neuroAssessment",
          "formCss": "grid-left-to-right-3",
          "fqn": "assessmentTools.neuroAssessment",
          "gIndex": "3",
          "gChildren": [
            "neuroAssessment1_1",
            "neuroAssessment2_1",
            "neuroAssessment3_1"
          ]
        },
        {
          "elementKey": "fallRisk",
          "formCss": "grid-left-to-right-3",
          "fqn": "assessmentTools.fallRisk",
          "gIndex": "4",
          "gChildren": [
            "fallRisk1_1",
            "fallRisk2_1"
          ]
        },
        {
          "elementKey": "notes",
          "formCss": "grid-left-to-right-3",
          "fqn": "assessmentTools.notes",
          "gIndex": "5",
          "gChildren": [
            "notes1_1"
          ]
        },
        {
          "elementKey": "educational",
          "formCss": "grid-left-to-right-3",
          "fqn": "assessmentTools.educational",
          "gIndex": "6",
          "gChildren": [
            "educational1_1",
            "educational2_1"
          ]
        },
        {
          "elementKey": "wound",
          "formCss": "grid-left-to-right-3",
          "fqn": "assessmentTools.wound",
          "gIndex": "7",
          "gChildren": [
            "wound1_1"
          ]
        },
        {
          "elementKey": "medication",
          "formCss": "grid-left-to-right-3",
          "fqn": "assessmentTools.medication",
          "gIndex": "8",
          "gChildren": [
            "medication1_1"
          ]
        }
      ]
    }
  },
  "path": "/ehr/external",
  "routeName": "assessment-tools",
  "label": "Standardized assessment tools",
  "redirectEhr": "",
  "redirectLis": "",
  "ehr": "yes",
  "fullPageKey": "external.assessmentTools"
}
export default DEFS