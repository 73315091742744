<template lang="pug">
  div(class="ehr-nav-list", :class="ehrNavCollapsed ? 'ehr-nav-collapsed' : 'ehr-nav-full'")
    transition(name="fade")
      div(v-show="showNavPath(path)", class="EhrNavList__teaserList")
        ehr-nav-list-item(:path="path", :level="level", :opened='open')
        transition(name="slide")
          div(v-show="open")
            ehr-nav-list(v-for="child in path.children", v-bind:key="child.name" :path="child" :level="level + 1")
</template>

<script>
import EhrNavList from './EhrNavList'
import EhrNavListItem from './EhrNavListItem'
import StoreHelper from '@/helpers/store-helper'
import FeatureHelper, { FF_PED_PAGES } from '@/helpers/feature-helper'

export default {
  name: 'EhrNavList',
  components: {
    EhrNavList,
    EhrNavListItem
  },
  props: {
    path: { type: Object },
    level: { type: Number }
  },
  computed: {
    ehrNavCollapsed () { return this.$store.getters['system/ehrNavCollapsed']},
    open () {
      if (StoreHelper.isLIS_Showing()) {
        // force all LIS nav groups to be open
        return true
      }
      let pathParts = this.$route.path.split('/')
      return pathParts.indexOf(this.path.name) > -1
    }
  },
  methods: {
    showNavPath (path) {
      let showPath = false
      const FF = FeatureHelper.isFeatureFlagEnabled(FF_PED_PAGES)
      if (path.isLIS) {
        showPath = StoreHelper.isLIS_Showing()
      }
      if (path.isEHR) {
        showPath = showPath || StoreHelper.isEHR_Showing()
      }
      if (showPath && !!path.featureFlag) {
        if (path.featureFlag === FF_PED_PAGES) {
          showPath = FF
        }
      }
      return showPath
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../scss/definitions';
// Fade effect for parent visibility
.fade-enter-active, .fade-leave-active {
  transition: opacity 0.3s ease;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}

// Slide effect for child expansion
.slide-enter-active, .slide-leave-active {
  transition: max-height 0.3s ease-out, opacity 0.3s ease-out;
  overflow: hidden;
}
.slide-enter, .slide-leave-to {
  max-height: 0;
  opacity: 0;
}
.slide-enter-to, .slide-leave {
  max-height: 500px; // Adjust based on your content
  opacity: 1;
}

.ehr-nav-collapsed {
  width: 9rem;
}
.ehr-nav-full {
  width: 20rem;
}

.ehr-nav-list {
  padding: 0;
  &__teaserList {
    margin-left: 0;
  }
  &__link {
    color: $white;
  }
}
</style>
