/* eslint-disable */
const DEFS = {
  "pageDataKey": "fluidBalance",
  "pIndex": "12",
  "hasGridTable": true,
  "pageChildren": [
    {
      "elementKey": "fluidInOutTable_name",
      "formIndex": "1",
      "inputType": "practitionerName",
      "tableColumn": "1",
      "recHeader": true,
      "fqn": "fluidBalance.name"
    },
    {
      "elementKey": "fluidInOutTable_id",
      "inputType": "generatedId",
      "tableColumn": 0,
      "label": "Row id",
      "tableCss": "row-id"
    },
    {
      "elementKey": "fluidInOutTable_profession",
      "formIndex": "1",
      "inputType": "practitionerProfession",
      "tableColumn": "1",
      "recHeader": true,
      "fqn": "fluidBalance.profession"
    },
    {
      "elementKey": "fluidInOutTable_day",
      "formIndex": "1",
      "inputType": "visitDay",
      "mandatory": true,
      "tableColumn": "1",
      "validation": "visitDay",
      "recHeader": true,
      "fqn": "fluidBalance.day"
    },
    {
      "elementKey": "fluidInOutTable_time",
      "formIndex": "1",
      "inputType": "visitTime",
      "mandatory": true,
      "tableColumn": "1",
      "validation": "time24",
      "recHeader": true,
      "fqn": "fluidBalance.time"
    },
    {
      "elementKey": "inOral",
      "formIndex": "1",
      "formCss": "number-short",
      "inputType": "number",
      "passToFunction": "totalInputs",
      "suffix": "mL",
      "tableColumn": "2",
      "validation": "numeric",
      "fqn": "fluidBalance.inOral",
      "suffixText": "mL"
    },
    {
      "elementKey": "inIV",
      "formIndex": "1",
      "formCss": "number-short",
      "inputType": "number",
      "passToFunction": "totalInputs",
      "suffix": "mL",
      "tableColumn": "3",
      "validation": "numeric",
      "fqn": "fluidBalance.inIV",
      "suffixText": "mL"
    },
    {
      "elementKey": "inIV2",
      "formIndex": "1",
      "formCss": "number-short",
      "inputType": "number",
      "passToFunction": "totalInputs",
      "suffix": "mL",
      "tableColumn": "4",
      "validation": "numeric",
      "fqn": "fluidBalance.inIV2",
      "suffixText": "mL"
    },
    {
      "elementKey": "inIV3",
      "formIndex": "1",
      "formCss": "number-short",
      "inputType": "number",
      "passToFunction": "totalInputs",
      "suffix": "mL",
      "tableColumn": "5",
      "validation": "numeric",
      "fqn": "fluidBalance.inIV3",
      "suffixText": "mL"
    },
    {
      "elementKey": "inIV4",
      "formIndex": "1",
      "formCss": "number-short",
      "inputType": "number",
      "passToFunction": "totalInputs",
      "suffix": "mL",
      "tableColumn": "6",
      "validation": "numeric",
      "fqn": "fluidBalance.inIV4",
      "suffixText": "mL"
    },
    {
      "elementKey": "inEnteral",
      "formIndex": "1",
      "formCss": "number-short",
      "inputType": "number",
      "passToFunction": "totalInputs",
      "suffix": "mL",
      "tableColumn": "7",
      "validation": "numeric",
      "fqn": "fluidBalance.inEnteral",
      "suffixText": "mL"
    },
    {
      "elementKey": "inOther",
      "formIndex": "1",
      "formCss": "number-short",
      "inputType": "number",
      "passToFunction": "totalInputs",
      "suffix": "mL",
      "tableColumn": "8",
      "validation": "numeric",
      "fqn": "fluidBalance.inOther",
      "suffixText": "mL"
    },
    {
      "elementKey": "totalInputs",
      "calculationType": "sum",
      "formIndex": "1",
      "formCss": "number-short",
      "inputType": "calculatedValue",
      "passToFunction": "cumulativeTotal",
      "suffix": "mL",
      "tableColumn": "9",
      "fqn": "fluidBalance.totalInputs",
      "suffixText": "mL"
    },
    {
      "elementKey": "outUrine",
      "formIndex": "1",
      "formCss": "number-short",
      "inputType": "number",
      "passToFunction": "totalOutputs",
      "suffix": "mL",
      "tableColumn": "10",
      "validation": "numeric",
      "fqn": "fluidBalance.outUrine",
      "suffixText": "mL"
    },
    {
      "elementKey": "outDrains",
      "formIndex": "1",
      "formCss": "number-short",
      "inputType": "number",
      "passToFunction": "totalOutputs",
      "suffix": "mL",
      "tableColumn": "11",
      "validation": "numeric",
      "fqn": "fluidBalance.outDrains",
      "suffixText": "mL"
    },
    {
      "elementKey": "outBlood",
      "formIndex": "1",
      "formCss": "number-short",
      "inputType": "number",
      "passToFunction": "totalOutputs",
      "suffix": "mL",
      "tableColumn": "12",
      "validation": "numeric",
      "fqn": "fluidBalance.outBlood",
      "suffixText": "mL"
    },
    {
      "elementKey": "outOther",
      "formIndex": "1",
      "formCss": "number-short",
      "inputType": "number",
      "passToFunction": "totalOutputs",
      "suffix": "mL",
      "tableColumn": "13",
      "validation": "numeric",
      "fqn": "fluidBalance.outOther",
      "suffixText": "mL"
    },
    {
      "elementKey": "totalOutputs",
      "calculationType": "sum",
      "formIndex": "1",
      "formCss": "number-short",
      "inputType": "calculatedValue",
      "passToFunction": "cumulativeTotal",
      "suffix": "mL",
      "tableColumn": "14",
      "fqn": "fluidBalance.totalOutputs",
      "suffixText": "mL"
    },
    {
      "elementKey": "cumulativeTotal",
      "calculationType": "fluidBalance",
      "formIndex": "1",
      "formCss": "number-short",
      "inputType": "calculatedValue",
      "suffix": "mL",
      "tableColumn": "15",
      "fqn": "fluidBalance.cumulativeTotal",
      "suffixText": "mL"
    },
    {
      "elementKey": "comments",
      "formIndex": "1",
      "inputType": "textarea",
      "tableColumn": "16",
      "fqn": "fluidBalance.comments"
    },
    {
      "elementKey": "table_name",
      "formIndex": "2",
      "inputType": "practitionerName",
      "tableColumn": "1",
      "recHeader": true,
      "fqn": "fluidBalance.name"
    },
    {
      "elementKey": "table_id",
      "inputType": "generatedId",
      "tableColumn": 0,
      "label": "Row id",
      "tableCss": "row-id"
    },
    {
      "elementKey": "table_profession",
      "formIndex": "2",
      "inputType": "practitionerProfession",
      "tableColumn": "1",
      "recHeader": true,
      "fqn": "fluidBalance.profession"
    },
    {
      "elementKey": "table_day",
      "formIndex": "2",
      "inputType": "visitDay",
      "mandatory": true,
      "tableColumn": "1",
      "validation": "visitDay",
      "recHeader": true,
      "fqn": "fluidBalance.day"
    },
    {
      "elementKey": "table_time",
      "formIndex": "2",
      "inputType": "visitTime",
      "mandatory": true,
      "tableColumn": "1",
      "validation": "time24",
      "recHeader": true,
      "fqn": "fluidBalance.time"
    },
    {
      "elementKey": "fluidIn",
      "formIndex": "2",
      "inputType": "text",
      "tableColumn": "2",
      "fqn": "fluidBalance.fluidIn"
    },
    {
      "elementKey": "fluidOut",
      "formIndex": "2",
      "inputType": "text",
      "tableColumn": "3",
      "fqn": "fluidBalance.fluidOut"
    }
  ],
  "pageElements": {
    "fluidInOutTable": {
      "elementKey": "fluidInOutTable",
      "pageElementIndex": "1",
      "pageKey": "fluidBalance",
      "tableKey": "fluidInOutTable",
      "isTable": true,
      "form": {
        "elementKey": "fluidInOutTable",
        "label": "Fluid balance",
        "addButtonText": "Add a fluid record",
        "formOption": "transpose",
        "formKey": "fluidInOutTable",
        "ehr_groups": [
          {
            "elementKey": "hdrGroup",
            "formCss": "record-header",
            "fqn": "fluidBalance.hdrGroup",
            "gIndex": "1",
            "gChildren": [
              "fluidInOutTable_name",
              "fluidInOutTable_profession",
              "fluidInOutTable_day",
              "fluidInOutTable_time"
            ]
          },
          {
            "elementKey": "cGroup",
            "formCss": "grid-left-to-right-4",
            "fqn": "fluidBalance.cGroup",
            "gIndex": "2",
            "gChildren": [
              "inOral",
              "inIV",
              "inIV2",
              "inIV3",
              "inIV4",
              "inEnteral",
              "inOther"
            ]
          },
          {
            "elementKey": "cGroup1b",
            "formCss": "grid-left-to-right-1",
            "fqn": "fluidBalance.cGroup1b",
            "gIndex": "3",
            "gChildren": [
              "totalInputs"
            ]
          },
          {
            "elementKey": "cGroup2",
            "formCss": "grid-left-to-right-4",
            "fqn": "fluidBalance.cGroup2",
            "gIndex": "4",
            "gChildren": [
              "outUrine",
              "outDrains",
              "outBlood",
              "outOther"
            ]
          },
          {
            "elementKey": "cGroup2b",
            "formCss": "grid-left-to-right-1",
            "fqn": "fluidBalance.cGroup2b",
            "gIndex": "5",
            "gChildren": [
              "totalOutputs"
            ]
          },
          {
            "elementKey": "cGroup3",
            "formCss": "grid-left-to-right-1",
            "fqn": "fluidBalance.cGroup3",
            "gIndex": "6",
            "gChildren": [
              "cumulativeTotal",
              "comments"
            ]
          }
        ],
        "ehr_data": {
          "fluidInOutTable_name": "",
          "fluidInOutTable_profession": "",
          "fluidInOutTable_day": "",
          "fluidInOutTable_time": "",
          "inOral": "",
          "inIV": "",
          "inIV2": "",
          "inIV3": "",
          "inIV4": "",
          "inEnteral": "",
          "inOther": "",
          "totalInputs": "",
          "outUrine": "",
          "outDrains": "",
          "outBlood": "",
          "outOther": "",
          "totalOutputs": "",
          "cumulativeTotal": "",
          "comments": ""
        }
      },
      "fqn": "fluidBalance.fluidInOutTable",
      "tableChildren": [
        "fluidInOutTable_id",
        "fluidInOutTable_day",
        "fluidInOutTable_time",
        "inOral",
        "inIV",
        "inIV2",
        "inIV3",
        "inIV4",
        "inEnteral",
        "inOther",
        "totalInputs",
        "outUrine",
        "outDrains",
        "outBlood",
        "outOther",
        "totalOutputs",
        "cumulativeTotal",
        "comments",
        "fluidInOutTable_name",
        "fluidInOutTable_profession"
      ],
      "hasRecHeader": true
    },
    "table": {
      "elementKey": "table",
      "pageElementIndex": "2",
      "pageKey": "fluidBalance",
      "tableKey": "table",
      "isTable": true,
      "form": {
        "elementKey": "table",
        "label": "Fluid balance v1",
        "addButtonText": "Add a fluid in/out record",
        "formKey": "table",
        "ehr_groups": [
          {
            "elementKey": "hdrGroup2",
            "formCss": "record-header",
            "fqn": "fluidBalance.hdrGroup2",
            "gIndex": "1",
            "gChildren": [
              "table_name",
              "table_profession",
              "table_day",
              "table_time"
            ]
          },
          {
            "elementKey": "cGroup4",
            "formCss": "grid-left-to-right-3",
            "fqn": "fluidBalance.cGroup4",
            "gIndex": "2",
            "gChildren": [
              "fluidIn",
              "fluidOut"
            ]
          }
        ],
        "ehr_data": {
          "table_name": "",
          "table_profession": "",
          "table_day": "",
          "table_time": "",
          "fluidIn": "",
          "fluidOut": ""
        }
      },
      "fqn": "fluidBalance.table",
      "tableChildren": [
        "table_id",
        "table_day",
        "table_time",
        "fluidIn",
        "fluidOut",
        "table_name",
        "table_profession"
      ],
      "hasRecHeader": true
    }
  },
  "path": "/ehr/current",
  "routeName": "fluid-balance",
  "label": "Fluid balance",
  "generateComponent": "custom",
  "ehr": "yes",
  "fullPageKey": "current.fluidBalance"
}
export default DEFS