/* eslint-disable */
const DEFS = {
  "pageDataKey": "newbornVitals",
  "pIndex": "70",
  "hasGridTable": true,
  "pageChildren": [
    {
      "elementKey": "nbVitals_form_name",
      "formIndex": "1",
      "inputType": "practitionerName",
      "tableColumn": "2",
      "recHeader": true,
      "fqn": "newbornVitals.name"
    },
    {
      "elementKey": "nbVitals_form_id",
      "inputType": "generatedId",
      "tableColumn": 0,
      "label": "Row id",
      "tableCss": "row-id"
    },
    {
      "elementKey": "nbVitals_form_profession",
      "formIndex": "1",
      "inputType": "practitionerProfession",
      "tableColumn": "2",
      "recHeader": true,
      "fqn": "newbornVitals.profession"
    },
    {
      "elementKey": "nbVitals_form_day",
      "formIndex": "1",
      "inputType": "visitDay",
      "mandatory": true,
      "tableColumn": "2",
      "validation": "visitDay",
      "recHeader": true,
      "fqn": "newbornVitals.day"
    },
    {
      "elementKey": "nbVitals_form_time",
      "formIndex": "1",
      "inputType": "visitTime",
      "mandatory": true,
      "tableColumn": "2",
      "validation": "time24",
      "recHeader": true,
      "fqn": "newbornVitals.time"
    },
    {
      "elementKey": "nbVitalsTemp",
      "formIndex": "1",
      "inputType": "number",
      "suffix": "C",
      "tableColumn": "4",
      "fqn": "newbornVitals.nbVitalsTemp",
      "suffixText": "C"
    },
    {
      "elementKey": "nbVitalsTempSrc",
      "formIndex": "1",
      "inputType": "radioset",
      "options": [
        {
          "key": "axillary",
          "text": "Axillary"
        },
        {
          "key": "skinProbe",
          "text": "Skin probe"
        },
        {
          "key": "rectal",
          "text": "Rectal"
        },
        {
          "key": "other",
          "text": "Other"
        }
      ],
      "tableColumn": "5",
      "fqn": "newbornVitals.nbVitalsTempSrc"
    },
    {
      "elementKey": "nbVitalsBPM",
      "formIndex": "1",
      "inputType": "number",
      "tableColumn": "6",
      "fqn": "newbornVitals.nbVitalsBPM"
    },
    {
      "elementKey": "nbVitalsPulseMethod",
      "formIndex": "1",
      "inputType": "radioset",
      "options": [
        {
          "key": "auscultation",
          "text": "Auscultation"
        },
        {
          "key": "pulseOximeter",
          "text": "Pulse oximeter"
        },
        {
          "key": "cardiacMonitor",
          "text": "Cardiac monitor"
        },
        {
          "key": "other",
          "text": "Other"
        }
      ],
      "tableColumn": "7",
      "fqn": "newbornVitals.nbVitalsPulseMethod"
    },
    {
      "elementKey": "nbVitalsRR",
      "formIndex": "1",
      "inputType": "number",
      "tableColumn": "8",
      "fqn": "newbornVitals.nbVitalsRR"
    },
    {
      "elementKey": "nbVitalsOximetry",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "9",
      "fqn": "newbornVitals.nbVitalsOximetry"
    },
    {
      "elementKey": "nbVitalsSystolic",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "10",
      "fqn": "newbornVitals.nbVitalsSystolic"
    },
    {
      "elementKey": "nbVitalsDystolic",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "11",
      "fqn": "newbornVitals.nbVitalsDystolic"
    },
    {
      "elementKey": "nbVitalsLocation",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "12",
      "fqn": "newbornVitals.nbVitalsLocation"
    }
  ],
  "pageElements": {
    "nbVitals_form": {
      "elementKey": "nbVitals_form",
      "pageElementIndex": "1",
      "pageKey": "newbornVitals",
      "tableKey": "nbVitals_form",
      "isTable": true,
      "form": {
        "elementKey": "nbVitals_form",
        "label": "Newborn Vitals",
        "addButtonText": "Add Newborn Vitals",
        "formKey": "nbVitals_form",
        "ehr_groups": [
          {
            "elementKey": "nbVitals_hdrGroup",
            "formCss": "record-header",
            "fqn": "newbornVitals.nbVitals_hdrGroup",
            "gIndex": "1",
            "gChildren": [
              "nbVitals_form_name",
              "nbVitals_form_profession",
              "nbVitals_form_day",
              "nbVitals_form_time"
            ]
          },
          {
            "elementKey": "nbVitals_1",
            "formCss": "ehr-row-flow",
            "fqn": "newbornVitals.nbVitals_1",
            "gIndex": "2",
            "gChildren": [
              "nbVitalsTemp",
              "nbVitalsTempSrc",
              "nbVitalsBPM",
              "nbVitalsPulseMethod",
              "nbVitalsRR",
              "nbVitalsOximetry",
              "nbVitalsSystolic",
              "nbVitalsDystolic",
              "nbVitalsLocation"
            ]
          }
        ],
        "ehr_data": {
          "nbVitals_form_name": "",
          "nbVitals_form_profession": "",
          "nbVitals_form_day": "",
          "nbVitals_form_time": "",
          "nbVitalsTemp": "",
          "nbVitalsTempSrc": "",
          "nbVitalsBPM": "",
          "nbVitalsPulseMethod": "",
          "nbVitalsRR": "",
          "nbVitalsOximetry": "",
          "nbVitalsSystolic": "",
          "nbVitalsDystolic": "",
          "nbVitalsLocation": ""
        }
      },
      "fqn": "newbornVitals.nbVitals_form",
      "tableChildren": [
        "nbVitals_form_id",
        "nbVitals_form_day",
        "nbVitals_form_time",
        "nbVitalsTemp",
        "nbVitalsTempSrc",
        "nbVitalsBPM",
        "nbVitalsPulseMethod",
        "nbVitalsRR",
        "nbVitalsOximetry",
        "nbVitalsSystolic",
        "nbVitalsDystolic",
        "nbVitalsLocation",
        "nbVitals_form_name",
        "nbVitals_form_profession"
      ],
      "hasRecHeader": true
    }
  },
  "path": "/ehr/peri-ped/newborn",
  "routeName": "newborn-vitals",
  "label": "Newborn Vitals",
  "generateComponent": "v2page",
  "featureFlag": "ffPed",
  "ehr": "yes",
  "fullPageKey": "periPed.newborn.newbornVitals"
}
export default DEFS