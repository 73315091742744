/* eslint-disable */
const DEFS = {
  "pageDataKey": "billing",
  "pIndex": "28",
  "hasGridForm": true,
  "pageChildren": [
    {
      "elementKey": "paymentType",
      "formIndex": "1",
      "inputType": "checkset",
      "options": [
        {
          "key": "msp",
          "text": "MSP"
        },
        {
          "key": "thirdParty",
          "text": "Third party"
        },
        {
          "key": "federal",
          "text": "Federal"
        }
      ],
      "fqn": "billing.paymentType"
    }
  ],
  "pageElements": {
    "pageForm": {
      "elementKey": "pageForm",
      "pageElementIndex": "1",
      "formKey": "pageForm",
      "fqn": "billing.pageForm",
      "isPageForm": true,
      "ehr_groups": [
        {
          "elementKey": "cGroup28-1",
          "formCss": "grid-left-to-right-3",
          "fqn": "billing.cGroup28-1",
          "gIndex": "1",
          "gChildren": [
            "paymentType"
          ]
        }
      ]
    }
  },
  "path": "/ehr/current",
  "routeName": "billing",
  "label": "Billing",
  "redirectEhr": "",
  "redirectLis": "",
  "ehr": "yes",
  "fullPageKey": "current.billing"
}
export default DEFS