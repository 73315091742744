/* eslint-disable */
const DEFS = {
  "pageDataKey": "progressNotes",
  "pIndex": "29",
  "hasGridTable": true,
  "pageChildren": [
    {
      "elementKey": "table_name",
      "formIndex": "1",
      "inputType": "practitionerName",
      "tableColumn": "1",
      "recHeader": true,
      "fqn": "progressNotes.name"
    },
    {
      "elementKey": "table_id",
      "inputType": "generatedId",
      "tableColumn": 0,
      "label": "Row id",
      "tableCss": "row-id"
    },
    {
      "elementKey": "table_profession",
      "formIndex": "1",
      "inputType": "practitionerProfession",
      "tableColumn": "1",
      "recHeader": true,
      "fqn": "progressNotes.profession"
    },
    {
      "elementKey": "table_day",
      "formIndex": "1",
      "inputType": "visitDay",
      "mandatory": true,
      "tableColumn": "1",
      "validation": "visitDay",
      "recHeader": true,
      "fqn": "progressNotes.day"
    },
    {
      "elementKey": "table_time",
      "formIndex": "1",
      "inputType": "visitTime",
      "mandatory": true,
      "tableColumn": "1",
      "validation": "time24",
      "recHeader": true,
      "fqn": "progressNotes.time"
    },
    {
      "elementKey": "note",
      "formIndex": "1",
      "inputType": "textarea",
      "tableColumn": "2",
      "fqn": "progressNotes.note"
    },
    {
      "elementKey": "sbar_name",
      "formIndex": "2",
      "inputType": "practitionerName",
      "tableColumn": "1",
      "recHeader": true,
      "fqn": "progressNotes.name"
    },
    {
      "elementKey": "sbar_id",
      "inputType": "generatedId",
      "tableColumn": 0,
      "label": "Row id",
      "tableCss": "row-id"
    },
    {
      "elementKey": "sbar_profession",
      "formIndex": "2",
      "inputType": "practitionerProfession",
      "tableColumn": "1",
      "recHeader": true,
      "fqn": "progressNotes.profession"
    },
    {
      "elementKey": "sbar_day",
      "formIndex": "2",
      "inputType": "visitDay",
      "mandatory": true,
      "tableColumn": "1",
      "validation": "visitDay",
      "recHeader": true,
      "fqn": "progressNotes.day"
    },
    {
      "elementKey": "sbar_time",
      "formIndex": "2",
      "inputType": "visitTime",
      "mandatory": true,
      "tableColumn": "1",
      "validation": "time24",
      "recHeader": true,
      "fqn": "progressNotes.time"
    },
    {
      "elementKey": "situation",
      "formIndex": "2",
      "inputType": "textarea",
      "tableColumn": "2",
      "fqn": "progressNotes.situation"
    },
    {
      "elementKey": "background",
      "formIndex": "2",
      "inputType": "textarea",
      "tableColumn": "3",
      "fqn": "progressNotes.background"
    },
    {
      "elementKey": "assessment",
      "formIndex": "2",
      "inputType": "textarea",
      "tableColumn": "4",
      "fqn": "progressNotes.assessment"
    },
    {
      "elementKey": "recommendation",
      "formIndex": "2",
      "inputType": "textarea",
      "tableColumn": "5",
      "fqn": "progressNotes.recommendation"
    },
    {
      "elementKey": "dar_name",
      "formIndex": "3",
      "inputType": "practitionerName",
      "tableColumn": "1",
      "recHeader": true,
      "fqn": "progressNotes.name"
    },
    {
      "elementKey": "dar_id",
      "inputType": "generatedId",
      "tableColumn": 0,
      "label": "Row id",
      "tableCss": "row-id"
    },
    {
      "elementKey": "dar_profession",
      "formIndex": "3",
      "inputType": "practitionerProfession",
      "tableColumn": "1",
      "recHeader": true,
      "fqn": "progressNotes.profession"
    },
    {
      "elementKey": "dar_day",
      "formIndex": "3",
      "inputType": "visitDay",
      "mandatory": true,
      "tableColumn": "1",
      "validation": "visitDay",
      "recHeader": true,
      "fqn": "progressNotes.day"
    },
    {
      "elementKey": "dar_time",
      "formIndex": "3",
      "inputType": "visitTime",
      "mandatory": true,
      "tableColumn": "1",
      "validation": "time24",
      "recHeader": true,
      "fqn": "progressNotes.time"
    },
    {
      "elementKey": "darFocus",
      "formIndex": "3",
      "inputType": "textarea",
      "tableColumn": "2",
      "fqn": "progressNotes.darFocus"
    },
    {
      "elementKey": "darData",
      "formIndex": "3",
      "inputType": "textarea",
      "tableColumn": "3",
      "fqn": "progressNotes.darData"
    },
    {
      "elementKey": "darAction",
      "formIndex": "3",
      "inputType": "textarea",
      "tableColumn": "4",
      "fqn": "progressNotes.darAction"
    },
    {
      "elementKey": "darResponse",
      "formIndex": "3",
      "inputType": "textarea",
      "tableColumn": "5",
      "fqn": "progressNotes.darResponse"
    },
    {
      "elementKey": "soap_name",
      "formIndex": "4",
      "inputType": "practitionerName",
      "tableColumn": "1",
      "recHeader": true,
      "fqn": "progressNotes.name"
    },
    {
      "elementKey": "soap_id",
      "inputType": "generatedId",
      "tableColumn": 0,
      "label": "Row id",
      "tableCss": "row-id"
    },
    {
      "elementKey": "soap_profession",
      "formIndex": "4",
      "inputType": "practitionerProfession",
      "tableColumn": "1",
      "recHeader": true,
      "fqn": "progressNotes.profession"
    },
    {
      "elementKey": "soap_day",
      "formIndex": "4",
      "inputType": "visitDay",
      "mandatory": true,
      "tableColumn": "1",
      "validation": "visitDay",
      "recHeader": true,
      "fqn": "progressNotes.day"
    },
    {
      "elementKey": "soap_time",
      "formIndex": "4",
      "inputType": "visitTime",
      "mandatory": true,
      "tableColumn": "1",
      "validation": "time24",
      "recHeader": true,
      "fqn": "progressNotes.time"
    },
    {
      "elementKey": "soapSubjective",
      "formIndex": "4",
      "inputType": "textarea",
      "tableColumn": "2",
      "fqn": "progressNotes.soapSubjective"
    },
    {
      "elementKey": "soapObjective",
      "formIndex": "4",
      "inputType": "textarea",
      "tableColumn": "3",
      "fqn": "progressNotes.soapObjective"
    },
    {
      "elementKey": "soapAssessment",
      "formIndex": "4",
      "inputType": "textarea",
      "tableColumn": "4",
      "fqn": "progressNotes.soapAssessment"
    },
    {
      "elementKey": "soapPlan",
      "formIndex": "4",
      "inputType": "textarea",
      "tableColumn": "5",
      "fqn": "progressNotes.soapPlan"
    }
  ],
  "pageElements": {
    "table": {
      "elementKey": "table",
      "pageElementIndex": "1",
      "pageKey": "progressNotes",
      "tableKey": "table",
      "isTable": true,
      "form": {
        "elementKey": "table",
        "label": "Progress notes",
        "addButtonText": "Add an encounter note",
        "formKey": "table",
        "ehr_groups": [
          {
            "elementKey": "hdrGroup",
            "formCss": "record-header",
            "fqn": "progressNotes.hdrGroup",
            "gIndex": "1",
            "gChildren": [
              "table_name",
              "table_profession",
              "table_day",
              "table_time"
            ]
          },
          {
            "elementKey": "cGroup29-2",
            "formCss": "grid-left-to-right-1",
            "fqn": "progressNotes.cGroup29-2",
            "gIndex": "2",
            "gChildren": [
              "note"
            ]
          }
        ],
        "ehr_data": {
          "table_name": "",
          "table_profession": "",
          "table_day": "",
          "table_time": "",
          "note": ""
        }
      },
      "fqn": "progressNotes.table",
      "tableChildren": [
        "table_id",
        "table_day",
        "table_time",
        "note",
        "table_name",
        "table_profession"
      ],
      "hasRecHeader": true
    },
    "sbar": {
      "elementKey": "sbar",
      "pageElementIndex": "2",
      "pageKey": "progressNotes",
      "tableKey": "sbar",
      "isTable": true,
      "form": {
        "elementKey": "sbar",
        "label": "SBAR notes",
        "addButtonText": "Add a SBAR note",
        "formKey": "sbar",
        "ehr_groups": [
          {
            "elementKey": "sbarHdr",
            "formCss": "record-header",
            "fqn": "progressNotes.sbarHdr",
            "gIndex": "1",
            "gChildren": [
              "sbar_name",
              "sbar_profession",
              "sbar_day",
              "sbar_time"
            ]
          },
          {
            "elementKey": "sbarNotes",
            "formCss": "grid-left-to-right-1",
            "fqn": "progressNotes.sbarNotes",
            "gIndex": "2",
            "gChildren": [
              "situation",
              "background",
              "assessment",
              "recommendation"
            ]
          }
        ],
        "ehr_data": {
          "sbar_name": "",
          "sbar_profession": "",
          "sbar_day": "",
          "sbar_time": "",
          "situation": "",
          "background": "",
          "assessment": "",
          "recommendation": ""
        }
      },
      "fqn": "progressNotes.sbar",
      "tableChildren": [
        "sbar_id",
        "sbar_day",
        "sbar_time",
        "situation",
        "background",
        "assessment",
        "recommendation",
        "sbar_name",
        "sbar_profession"
      ],
      "hasRecHeader": true
    },
    "dar": {
      "elementKey": "dar",
      "pageElementIndex": "3",
      "pageKey": "progressNotes",
      "tableKey": "dar",
      "isTable": true,
      "form": {
        "elementKey": "dar",
        "label": "DAR notes",
        "addButtonText": "Add a DAR note",
        "formKey": "dar",
        "ehr_groups": [
          {
            "elementKey": "darHdr",
            "formCss": "record-header",
            "fqn": "progressNotes.darHdr",
            "gIndex": "1",
            "gChildren": [
              "dar_name",
              "dar_profession",
              "dar_day",
              "dar_time"
            ]
          },
          {
            "elementKey": "darNotes",
            "formCss": "grid-left-to-right-1",
            "fqn": "progressNotes.darNotes",
            "gIndex": "2",
            "gChildren": [
              "darFocus",
              "darData",
              "darAction",
              "darResponse"
            ]
          }
        ],
        "ehr_data": {
          "dar_name": "",
          "dar_profession": "",
          "dar_day": "",
          "dar_time": "",
          "darFocus": "",
          "darData": "",
          "darAction": "",
          "darResponse": ""
        }
      },
      "fqn": "progressNotes.dar",
      "tableChildren": [
        "dar_id",
        "dar_day",
        "dar_time",
        "darFocus",
        "darData",
        "darAction",
        "darResponse",
        "dar_name",
        "dar_profession"
      ],
      "hasRecHeader": true
    },
    "soap": {
      "elementKey": "soap",
      "pageElementIndex": "4",
      "pageKey": "progressNotes",
      "tableKey": "soap",
      "isTable": true,
      "form": {
        "elementKey": "soap",
        "label": "SOAP notes",
        "addButtonText": "Add a SOAP note",
        "formKey": "soap",
        "ehr_groups": [
          {
            "elementKey": "soapHdr",
            "formCss": "record-header",
            "fqn": "progressNotes.soapHdr",
            "gIndex": "1",
            "gChildren": [
              "soap_name",
              "soap_profession",
              "soap_day",
              "soap_time"
            ]
          },
          {
            "elementKey": "soapNotes",
            "formCss": "grid-left-to-right-1",
            "fqn": "progressNotes.soapNotes",
            "gIndex": "2",
            "gChildren": [
              "soapSubjective",
              "soapObjective",
              "soapAssessment",
              "soapPlan"
            ]
          }
        ],
        "ehr_data": {
          "soap_name": "",
          "soap_profession": "",
          "soap_day": "",
          "soap_time": "",
          "soapSubjective": "",
          "soapObjective": "",
          "soapAssessment": "",
          "soapPlan": ""
        }
      },
      "fqn": "progressNotes.soap",
      "tableChildren": [
        "soap_id",
        "soap_day",
        "soap_time",
        "soapSubjective",
        "soapObjective",
        "soapAssessment",
        "soapPlan",
        "soap_name",
        "soap_profession"
      ],
      "hasRecHeader": true
    }
  },
  "path": "/ehr/chart",
  "routeName": "progress-notes",
  "label": "Progress notes",
  "redirectEhr": "",
  "redirectLis": "",
  "ehr": "yes",
  "fullPageKey": "chart.progressNotes"
}
export default DEFS