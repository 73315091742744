/* eslint-disable */
const DEFS = {
  "pageDataKey": "consults",
  "pIndex": "31",
  "hasGridTable": true,
  "pageChildren": [
    {
      "elementKey": "table_name",
      "formIndex": "1",
      "inputType": "practitionerName",
      "tableColumn": "1",
      "recHeader": true,
      "fqn": "consults.name"
    },
    {
      "elementKey": "table_id",
      "inputType": "generatedId",
      "tableColumn": 0,
      "label": "Row id",
      "tableCss": "row-id"
    },
    {
      "elementKey": "table_profession",
      "formIndex": "1",
      "inputType": "practitionerProfession",
      "tableColumn": "1",
      "recHeader": true,
      "fqn": "consults.profession"
    },
    {
      "elementKey": "table_day",
      "formIndex": "1",
      "inputType": "visitDay",
      "mandatory": true,
      "tableColumn": "1",
      "validation": "visitDay",
      "recHeader": true,
      "fqn": "consults.day"
    },
    {
      "elementKey": "table_time",
      "formIndex": "1",
      "inputType": "visitTime",
      "mandatory": true,
      "tableColumn": "1",
      "validation": "time24",
      "recHeader": true,
      "fqn": "consults.time"
    },
    {
      "elementKey": "comments",
      "formIndex": "1",
      "inputType": "textarea",
      "tableColumn": "2",
      "fqn": "consults.comments"
    },
    {
      "elementKey": "consultReport",
      "formIndex": "1",
      "inputType": "ehrFile",
      "tableColumn": "3",
      "fqn": "consults.consultReport"
    }
  ],
  "pageElements": {
    "table": {
      "elementKey": "table",
      "pageElementIndex": "1",
      "pageKey": "consults",
      "tableKey": "table",
      "isTable": true,
      "form": {
        "elementKey": "table",
        "label": "Consults",
        "addButtonText": "Add a consultation report",
        "formKey": "table",
        "ehr_groups": [
          {
            "elementKey": "hdrGroup",
            "formCss": "record-header",
            "fqn": "consults.hdrGroup",
            "gIndex": "1",
            "gChildren": [
              "table_name",
              "table_profession",
              "table_day",
              "table_time"
            ]
          },
          {
            "elementKey": "cGroup31-2",
            "formCss": "grid-left-to-right-1",
            "fqn": "consults.cGroup31-2",
            "gIndex": "2",
            "gChildren": [
              "comments",
              "consultReport"
            ]
          }
        ],
        "ehr_data": {
          "table_name": "",
          "table_profession": "",
          "table_day": "",
          "table_time": "",
          "comments": "",
          "consultReport": ""
        }
      },
      "fqn": "consults.table",
      "tableChildren": [
        "table_id",
        "table_day",
        "table_time",
        "comments",
        "consultReport",
        "table_name",
        "table_profession"
      ],
      "hasRecHeader": true
    }
  },
  "path": "/ehr/chart/reports",
  "routeName": "consults",
  "label": "Consults",
  "redirectEhr": "",
  "redirectLis": "",
  "ehr": "yes",
  "fullPageKey": "chart.reports.consults"
}
export default DEFS