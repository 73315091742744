/* eslint-disable */
const DEFS = {
  "pageDataKey": "newbornBath",
  "pIndex": "69",
  "hasGridTable": true,
  "pageChildren": [
    {
      "elementKey": "nbBath_form_name",
      "formIndex": "1",
      "inputType": "practitionerName",
      "tableColumn": "2",
      "recHeader": true,
      "fqn": "newbornBath.name"
    },
    {
      "elementKey": "nbBath_form_id",
      "inputType": "generatedId",
      "tableColumn": 0,
      "label": "Row id",
      "tableCss": "row-id"
    },
    {
      "elementKey": "nbBath_form_profession",
      "formIndex": "1",
      "inputType": "practitionerProfession",
      "tableColumn": "2",
      "recHeader": true,
      "fqn": "newbornBath.profession"
    },
    {
      "elementKey": "nbBath_form_day",
      "formIndex": "1",
      "inputType": "visitDay",
      "mandatory": true,
      "tableColumn": "2",
      "validation": "visitDay",
      "recHeader": true,
      "fqn": "newbornBath.day"
    },
    {
      "elementKey": "nbBath_form_time",
      "formIndex": "1",
      "inputType": "visitTime",
      "mandatory": true,
      "tableColumn": "2",
      "validation": "time24",
      "recHeader": true,
      "fqn": "newbornBath.time"
    },
    {
      "elementKey": "nbBathType",
      "formIndex": "1",
      "inputType": "radioset",
      "options": [
        {
          "key": "swaddle",
          "text": "Swaddle bath"
        },
        {
          "key": "tub",
          "text": "Tub bath"
        },
        {
          "key": "targeted",
          "text": "Targeted wash less than 1500 grams"
        }
      ],
      "tableColumn": "4",
      "fqn": "newbornBath.nbBathType"
    },
    {
      "elementKey": "nbBathBy",
      "formIndex": "1",
      "inputType": "radioset",
      "options": [
        {
          "key": "nurse",
          "text": "Nurse"
        },
        {
          "key": "parents",
          "text": "Parents"
        },
        {
          "key": "caregiver",
          "text": "Caregiver"
        },
        {
          "key": "other",
          "text": "Other"
        }
      ],
      "tableColumn": "5",
      "fqn": "newbornBath.nbBathBy"
    },
    {
      "elementKey": "nbBathNext",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "6",
      "fqn": "newbornBath.nbBathNext"
    },
    {
      "elementKey": "nbBathComments",
      "formIndex": "1",
      "inputType": "textarea",
      "tableColumn": "7",
      "fqn": "newbornBath.nbBathComments"
    }
  ],
  "pageElements": {
    "nbBath_form": {
      "elementKey": "nbBath_form",
      "pageElementIndex": "1",
      "pageKey": "newbornBath",
      "tableKey": "nbBath_form",
      "isTable": true,
      "form": {
        "elementKey": "nbBath_form",
        "label": "Bath Neo Record",
        "addButtonText": "Add Bath Neo Record",
        "formKey": "nbBath_form",
        "ehr_groups": [
          {
            "elementKey": "nbBath_hdrGroup",
            "formCss": "record-header",
            "fqn": "newbornBath.nbBath_hdrGroup",
            "gIndex": "1",
            "gChildren": [
              "nbBath_form_name",
              "nbBath_form_profession",
              "nbBath_form_day",
              "nbBath_form_time"
            ]
          },
          {
            "elementKey": "nbBath_1",
            "formCss": "ehr-row-flow",
            "fqn": "newbornBath.nbBath_1",
            "gIndex": "2",
            "gChildren": [
              "nbBathType",
              "nbBathBy",
              "nbBathNext"
            ]
          },
          {
            "elementKey": "nbBath_2",
            "formCss": "grid-left-to-right-1",
            "fqn": "newbornBath.nbBath_2",
            "gIndex": "3",
            "gChildren": [
              "nbBathComments"
            ]
          }
        ],
        "ehr_data": {
          "nbBath_form_name": "",
          "nbBath_form_profession": "",
          "nbBath_form_day": "",
          "nbBath_form_time": "",
          "nbBathType": "",
          "nbBathBy": "",
          "nbBathNext": "",
          "nbBathComments": ""
        }
      },
      "fqn": "newbornBath.nbBath_form",
      "tableChildren": [
        "nbBath_form_id",
        "nbBath_form_day",
        "nbBath_form_time",
        "nbBathType",
        "nbBathBy",
        "nbBathNext",
        "nbBathComments",
        "nbBath_form_name",
        "nbBath_form_profession"
      ],
      "hasRecHeader": true
    }
  },
  "path": "/ehr/peri-ped/newborn",
  "routeName": "newborn-bath",
  "label": "Bath Neo Record",
  "generateComponent": "v2page",
  "featureFlag": "ffPed",
  "ehr": "yes",
  "fullPageKey": "periPed.newborn.newbornBath"
}
export default DEFS