/* eslint-disable */
const DEFS = {
  "pageDataKey": "pedVitals",
  "pIndex": "72",
  "hasGridTable": true,
  "pageChildren": [
    {
      "elementKey": "pedVForm_name",
      "formIndex": "1",
      "inputType": "practitionerName",
      "tableColumn": "2",
      "recHeader": true,
      "fqn": "pedVitals.name"
    },
    {
      "elementKey": "pedVForm_id",
      "inputType": "generatedId",
      "tableColumn": 0,
      "label": "Row id",
      "tableCss": "row-id"
    },
    {
      "elementKey": "pedVForm_profession",
      "formIndex": "1",
      "inputType": "practitionerProfession",
      "tableColumn": "2",
      "recHeader": true,
      "fqn": "pedVitals.profession"
    },
    {
      "elementKey": "pedVForm_day",
      "formIndex": "1",
      "inputType": "visitDay",
      "mandatory": true,
      "tableColumn": "2",
      "validation": "visitDay",
      "recHeader": true,
      "fqn": "pedVitals.day"
    },
    {
      "elementKey": "pedVForm_time",
      "formIndex": "1",
      "inputType": "visitTime",
      "mandatory": true,
      "tableColumn": "2",
      "validation": "time24",
      "recHeader": true,
      "fqn": "pedVitals.time"
    },
    {
      "elementKey": "pedVRespRate",
      "formIndex": "1",
      "inputType": "number",
      "passToFunction": "pedVPEWSResp",
      "suffix": "bpm",
      "tableColumn": "4",
      "fqn": "pedVitals.pedVRespRate",
      "suffixText": "bpm"
    },
    {
      "elementKey": "pedVRespRange",
      "calculationType": "pewsRespiratorText",
      "formIndex": "1",
      "inputType": "calculatedInfoText",
      "tableColumn": "5",
      "fqn": "pedVitals.pedVRespRange"
    },
    {
      "elementKey": "pedVRespDepth",
      "formIndex": "1",
      "inputType": "radioset",
      "options": [
        {
          "key": "normal",
          "text": "Normal depth"
        },
        {
          "key": "shallow",
          "text": "Shallow"
        },
        {
          "key": "deep",
          "text": "Deep"
        }
      ],
      "tableColumn": "5",
      "fqn": "pedVitals.pedVRespDepth"
    },
    {
      "elementKey": "pedVOxi",
      "formIndex": "1",
      "inputType": "number",
      "suffix": "%",
      "tableColumn": "6",
      "fqn": "pedVitals.pedVOxi",
      "suffixText": "%"
    },
    {
      "elementKey": "pedVProbeReSited",
      "formIndex": "1",
      "inputType": "radioset",
      "options": [
        {
          "key": "yes",
          "text": "Yes"
        },
        {
          "key": "no",
          "text": "No"
        },
        {
          "key": "other",
          "text": "Other"
        }
      ],
      "tableColumn": "7",
      "fqn": "pedVitals.pedVProbeReSited"
    },
    {
      "elementKey": "pedVOtherComm",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "8",
      "fqn": "pedVitals.pedVOtherComm"
    },
    {
      "elementKey": "pedVProbeLocMod",
      "formIndex": "1",
      "inputType": "radioset",
      "options": [
        {
          "key": "right",
          "text": "Right"
        },
        {
          "key": "left",
          "text": "Left"
        }
      ],
      "tableColumn": "9",
      "fqn": "pedVitals.pedVProbeLocMod"
    },
    {
      "elementKey": "pedVProbeLoc",
      "formIndex": "1",
      "inputType": "radioset",
      "options": [
        {
          "key": "1stFingerThumb",
          "text": "1st finger/thumb"
        },
        {
          "key": "2ndFinger",
          "text": "2nd finger"
        },
        {
          "key": "3rdFinger",
          "text": "3rd finger"
        },
        {
          "key": "4thFinger",
          "text": "4th finger"
        },
        {
          "key": "5thFinger",
          "text": "5th finger"
        },
        {
          "key": "hand",
          "text": "Hand"
        },
        {
          "key": "1stToe",
          "text": "1st toe"
        },
        {
          "key": "2ndToe",
          "text": "2nd toe"
        },
        {
          "key": "3rdToe",
          "text": "3rd toe"
        },
        {
          "key": "4thToe",
          "text": "4th toe"
        },
        {
          "key": "5thToe",
          "text": "5th toe"
        },
        {
          "key": "foot",
          "text": "Foot"
        },
        {
          "key": "other",
          "text": "Other"
        }
      ],
      "tableColumn": "10",
      "fqn": "pedVitals.pedVProbeLoc"
    },
    {
      "elementKey": "pedVOxyDel",
      "formIndex": "1",
      "inputType": "checkset",
      "options": [
        {
          "key": "roomAirNone",
          "text": "Room air/none"
        },
        {
          "key": "simpleMask",
          "text": "Simple mask"
        },
        {
          "key": "heatedHighFlow",
          "text": "Heated hight flow system"
        },
        {
          "key": "faceTent",
          "text": "Face tent"
        },
        {
          "key": "trachMask",
          "text": "Trach mask"
        },
        {
          "key": "invasiveVentilator",
          "text": "Invasive ventilator"
        },
        {
          "key": "manual",
          "text": "Manual resuscitator"
        },
        {
          "key": "nasalCannula",
          "text": "Nasal cannula"
        },
        {
          "key": "nonRebreather",
          "text": "Non rebreather"
        },
        {
          "key": "highFlow",
          "text": "High flow mask"
        },
        {
          "key": "highFlowNasal",
          "text": "High flow nasal cannula"
        },
        {
          "key": "tpiece",
          "text": "T-piece"
        },
        {
          "key": "noninvasive",
          "text": "Non-invasive ventilator"
        }
      ],
      "tableColumn": "11",
      "fqn": "pedVitals.pedVOxyDel"
    },
    {
      "elementKey": "pedVFlow",
      "formIndex": "1",
      "inputType": "number",
      "suffix": "L/min",
      "tableColumn": "12",
      "fqn": "pedVitals.pedVFlow",
      "suffixText": "L/min"
    },
    {
      "elementKey": "pedVFiO2",
      "formIndex": "1",
      "inputType": "number",
      "tableColumn": "13",
      "fqn": "pedVitals.pedVFiO2"
    },
    {
      "elementKey": "pedVSuppOxy",
      "formIndex": "1",
      "inputType": "radioset",
      "options": [
        {
          "key": "0",
          "text": "Less than 3L or 30%"
        },
        {
          "key": "1",
          "text": "More than 3Lor 30%"
        },
        {
          "key": "2",
          "text": "More than 6L or 40%"
        },
        {
          "key": "3",
          "text": "More than 8L or 50%"
        }
      ],
      "passToFunction": "pedVPEWSResp",
      "tableColumn": "14",
      "fqn": "pedVitals.pedVSuppOxy"
    },
    {
      "elementKey": "pedVRespDist",
      "formIndex": "1",
      "inputType": "radioset",
      "options": [
        {
          "key": "0",
          "text": "None"
        },
        {
          "key": "1",
          "text": "Mild"
        },
        {
          "key": "2",
          "text": "Moderate"
        },
        {
          "key": "3",
          "text": "Severe"
        }
      ],
      "passToFunction": "pedVPEWSResp",
      "tableColumn": "15",
      "fqn": "pedVitals.pedVRespDist"
    },
    {
      "elementKey": "pedVPEWSResp",
      "calculationType": "pewsRespirator",
      "formIndex": "1",
      "inputType": "calculatedValue",
      "passToFunction": "pedVTotalPEWS",
      "tableColumn": "16",
      "fqn": "pedVitals.pedVPEWSResp"
    },
    {
      "elementKey": "pedVPulseRate",
      "formIndex": "1",
      "inputType": "number",
      "passToFunction": "pedVPEWSCV",
      "suffix": "bpm",
      "tableColumn": "18",
      "fqn": "pedVitals.pedVPulseRate",
      "suffixText": "bpm"
    },
    {
      "elementKey": "pedVPewsRange",
      "calculationType": "getPewsPulseText",
      "formIndex": "1",
      "inputType": "calculatedInfoText",
      "tableColumn": "19",
      "fqn": "pedVitals.pedVPewsRange"
    },
    {
      "elementKey": "pedVPulseSrc",
      "formIndex": "1",
      "inputType": "radioset",
      "options": [
        {
          "key": "pulse",
          "text": "Pulse oximeter"
        },
        {
          "key": "cardiac",
          "text": "Cardiac monitor"
        },
        {
          "key": "automatic",
          "text": "Automatic monitor"
        },
        {
          "key": "palpatation",
          "text": "Palpatation"
        },
        {
          "key": "auscultation",
          "text": "Auscultation"
        }
      ],
      "tableColumn": "20",
      "fqn": "pedVitals.pedVPulseSrc"
    },
    {
      "elementKey": "pedVLocMod",
      "formIndex": "1",
      "inputType": "radioset",
      "options": [
        {
          "key": "right",
          "text": "Right"
        },
        {
          "key": "left",
          "text": "Left"
        }
      ],
      "tableColumn": "21",
      "fqn": "pedVitals.pedVLocMod"
    },
    {
      "elementKey": "pedVPulseLoc",
      "formIndex": "1",
      "inputType": "radioset",
      "options": [
        {
          "key": "radial",
          "text": "Radial"
        },
        {
          "key": "brachial",
          "text": "Brachial"
        },
        {
          "key": "apical",
          "text": "Apical"
        },
        {
          "key": "femoral",
          "text": "Femoral"
        },
        {
          "key": "carotid",
          "text": "Carotid"
        }
      ],
      "tableColumn": "22",
      "fqn": "pedVitals.pedVPulseLoc"
    },
    {
      "elementKey": "pedVPulseRhythm",
      "formIndex": "1",
      "inputType": "radioset",
      "options": [
        {
          "key": "reg",
          "text": "Regular"
        },
        {
          "key": "irr",
          "text": "Irregular"
        },
        {
          "key": "irrIrr",
          "text": "Irregularly irregular"
        },
        {
          "key": "regIrr",
          "text": "Regularly irregular"
        }
      ],
      "tableColumn": "23",
      "fqn": "pedVitals.pedVPulseRhythm"
    },
    {
      "elementKey": "pedVBpSystolic",
      "formIndex": "1",
      "inputType": "number",
      "passToFunction": "pedVBPMean",
      "suffix": "mmHg",
      "tableColumn": "25",
      "fqn": "pedVitals.pedVBpSystolic",
      "suffixText": "mmHg"
    },
    {
      "elementKey": "pedVBpDiastolic",
      "formIndex": "1",
      "inputType": "number",
      "passToFunction": "pedVBPMean",
      "suffix": "mmHg",
      "tableColumn": "26",
      "fqn": "pedVitals.pedVBpDiastolic",
      "suffixText": "mmHg"
    },
    {
      "elementKey": "pedVBPMean",
      "calculationType": "meanArterialPressure",
      "formIndex": "1",
      "inputType": "calculatedValue",
      "suffix": "mmHg",
      "tableColumn": "27",
      "fqn": "pedVitals.pedVBPMean",
      "suffixText": "mmHg"
    },
    {
      "elementKey": "pedVBpPewsRange",
      "calculationType": "getPewsBloodPressureText",
      "formIndex": "1",
      "inputType": "calculatedInfoText",
      "tableColumn": "28",
      "fqn": "pedVitals.pedVBpPewsRange"
    },
    {
      "elementKey": "pedVBPSource",
      "formIndex": "1",
      "inputType": "radioset",
      "options": [
        {
          "key": "automatic",
          "text": "Automatic cuff"
        },
        {
          "key": "manual",
          "text": "Manual cuff"
        },
        {
          "key": "arterial",
          "text": "Arterial line"
        }
      ],
      "tableColumn": "29",
      "fqn": "pedVitals.pedVBPSource"
    },
    {
      "elementKey": "pedVPatientPos",
      "formIndex": "1",
      "inputType": "radioset",
      "options": [
        {
          "key": "supine",
          "text": "Supine"
        },
        {
          "key": "sitting",
          "text": "Sitting"
        },
        {
          "key": "standing",
          "text": "Standing"
        },
        {
          "key": "rightLateral",
          "text": "Right lateral"
        },
        {
          "key": "leftLateral",
          "text": "Left lateral"
        },
        {
          "key": "prone",
          "text": "Prone"
        }
      ],
      "tableColumn": "30",
      "fqn": "pedVitals.pedVPatientPos"
    },
    {
      "elementKey": "pedVCapRefill",
      "formIndex": "1",
      "inputType": "radioset",
      "options": [
        {
          "key": "0",
          "text": "1-2 seconds"
        },
        {
          "key": "1",
          "text": "3 seconds"
        },
        {
          "key": "2",
          "text": "4 seconds"
        },
        {
          "key": "3",
          "text": "5 seconds or more"
        }
      ],
      "passToFunction": "pedVPEWSCV",
      "tableColumn": "32",
      "fqn": "pedVitals.pedVCapRefill"
    },
    {
      "elementKey": "pedVSkinColor",
      "formIndex": "1",
      "inputType": "checkset",
      "options": [
        {
          "key": "0",
          "text": "Appropriate for ethnicity"
        },
        {
          "key": "1",
          "text": "Pale"
        },
        {
          "key": "2",
          "text": "Grey/ cyanotic"
        },
        {
          "key": "3",
          "text": "Grey/mottled"
        }
      ],
      "passToFunction": "pedVPEWSCV",
      "tableColumn": "33",
      "fqn": "pedVitals.pedVSkinColor"
    },
    {
      "elementKey": "pedVPEWSCV",
      "calculationType": "pewsCardiovascular",
      "formIndex": "1",
      "inputType": "calculatedValue",
      "passToFunction": "pedVTotalPEWS",
      "tableColumn": "34",
      "fqn": "pedVitals.pedVPEWSCV"
    },
    {
      "elementKey": "pedVBehav",
      "formIndex": "1",
      "inputType": "radioset",
      "options": [
        {
          "key": "0",
          "text": "Playing/appropriate"
        },
        {
          "key": "1",
          "text": "Sleeping"
        },
        {
          "key": "2",
          "text": "Irritable"
        },
        {
          "key": "3",
          "text": "Lethargic, confused or decreased response to pain"
        }
      ],
      "passToFunction": "pedVPEWSBehav",
      "tableColumn": "36",
      "fqn": "pedVitals.pedVBehav"
    },
    {
      "elementKey": "pedVPEWSBehav",
      "calculationType": "pewsBehaviour",
      "formIndex": "1",
      "inputType": "calculatedValue",
      "passToFunction": "pedVTotalPEWS",
      "tableColumn": "37",
      "fqn": "pedVitals.pedVPEWSBehav"
    },
    {
      "elementKey": "pedVVomPostSurg",
      "formIndex": "1",
      "inputType": "radioset",
      "options": [
        {
          "key": "no",
          "text": "No"
        },
        {
          "key": "yes",
          "text": "Yes"
        }
      ],
      "passToFunction": "pedVPEWSAdditional",
      "tableColumn": "39",
      "fqn": "pedVitals.pedVVomPostSurg"
    },
    {
      "elementKey": "pedVBroncoEvery20",
      "formIndex": "1",
      "inputType": "radioset",
      "options": [
        {
          "key": "no",
          "text": "No"
        },
        {
          "key": "yes",
          "text": "Yes"
        }
      ],
      "passToFunction": "pedVPEWSAdditional",
      "tableColumn": "40",
      "fqn": "pedVitals.pedVBroncoEvery20"
    },
    {
      "elementKey": "pedVPEWSAdditional",
      "calculationType": "pewsAdditional",
      "formIndex": "1",
      "inputType": "calculatedValue",
      "passToFunction": "pedVTotalPEWS",
      "tableColumn": "41",
      "fqn": "pedVitals.pedVPEWSAdditional"
    },
    {
      "elementKey": "pedVTotalPEWS",
      "calculationType": "pewsTotal",
      "formIndex": "1",
      "inputType": "calculatedValue",
      "tableColumn": "43",
      "fqn": "pedVitals.pedVTotalPEWS"
    },
    {
      "elementKey": "pedVPEWSInterp",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "44",
      "fqn": "pedVitals.pedVPEWSInterp"
    },
    {
      "elementKey": "pedVPEWSInterpNote",
      "formIndex": "1",
      "inputType": "formInfoText",
      "tableColumn": "45",
      "fqn": "pedVitals.pedVPEWSInterpNote"
    },
    {
      "elementKey": "pedVTempSrc",
      "formIndex": "1",
      "inputType": "radioset",
      "options": [
        {
          "key": "oral",
          "text": "Oral"
        },
        {
          "key": "rectal",
          "text": "Rectal"
        },
        {
          "key": "axillary",
          "text": "Axillary"
        },
        {
          "key": "forehead",
          "text": "Forehead"
        },
        {
          "key": "temporal",
          "text": "Temporal"
        }
      ],
      "passToFunction": "pedVTempNormalCalc",
      "tableColumn": "47",
      "fqn": "pedVitals.pedVTempSrc"
    },
    {
      "elementKey": "pedVTempNote",
      "formIndex": "1",
      "inputType": "formInfoText",
      "tableColumn": "48",
      "fqn": "pedVitals.pedVTempNote"
    },
    {
      "elementKey": "pedVTemp",
      "formIndex": "1",
      "inputType": "number",
      "suffix": "C",
      "tableColumn": "49",
      "fqn": "pedVitals.pedVTemp",
      "suffixText": "C"
    },
    {
      "elementKey": "pedVTempNormalCalc",
      "calculationType": "text",
      "formIndex": "1",
      "inputType": "calculatedText",
      "options": [
        {
          "key": "oral",
          "text": "36.0–37.5°C"
        },
        {
          "key": "rectal",
          "text": "36.5–38.0°C"
        },
        {
          "key": "axillary",
          "text": "35.9–37.2°C"
        },
        {
          "key": "forehead",
          "text": "35.8–37.5°C"
        },
        {
          "key": "temporal",
          "text": "36.1–37.9°C"
        },
        {
          "key": "skinProbe",
          "text": "Variable"
        },
        {
          "key": "urinary",
          "text": "36.5–37.8°C"
        },
        {
          "key": "rectalProbe",
          "text": "36.5–38.0°C"
        },
        {
          "key": "esophageal",
          "text": "36.5–38.0°C"
        },
        {
          "key": "paCatheter",
          "text": "36.5–38.0°C"
        }
      ],
      "tableColumn": "50",
      "fqn": "pedVitals.pedVTempNormalCalc"
    },
    {
      "elementKey": "pedVSituFactors",
      "formIndex": "1",
      "inputType": "checkset",
      "options": [
        {
          "key": "patient",
          "text": "Patient/family/caregiver concern"
        },
        {
          "key": "unusualTherapy",
          "text": "Unusual therapy"
        },
        {
          "key": "watcherPatient",
          "text": "Watcher patient"
        },
        {
          "key": "communicationBreakdown",
          "text": "Communication breakdown"
        },
        {
          "key": "PEWOver2",
          "text": "Pew over 2"
        }
      ],
      "tableColumn": "52",
      "fqn": "pedVitals.pedVSituFactors"
    },
    {
      "elementKey": "pedVSituFactorsComm",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "53",
      "fqn": "pedVitals.pedVSituFactorsComm"
    },
    {
      "elementKey": "pedVPEWSEscal",
      "formIndex": "1",
      "inputType": "visitTime",
      "suffix": "24hr time",
      "tableColumn": "54",
      "fqn": "pedVitals.pedVPEWSEscal",
      "suffixText": "24hr time"
    },
    {
      "elementKey": "pedVPEWSEscalNote",
      "formIndex": "1",
      "inputType": "formInfoText",
      "tableColumn": "55",
      "fqn": "pedVitals.pedVPEWSEscalNote"
    },
    {
      "elementKey": "pedVSepsisSigns",
      "formIndex": "1",
      "inputType": "checkset",
      "options": [
        {
          "key": "none",
          "text": "None"
        },
        {
          "key": "workBreathing",
          "text": "Work of breathing"
        },
        {
          "key": "caregiverConcern",
          "text": "Caregiver concern"
        },
        {
          "key": "integumentary",
          "text": "Integumentary"
        },
        {
          "key": "suspectedInfection",
          "text": "Suspected infection presenting to ED"
        },
        {
          "key": "alteredMental",
          "text": "Altered mental status"
        },
        {
          "key": "circulation",
          "text": "Circulation"
        },
        {
          "key": "gastrointestinal",
          "text": "Gastrointestinal"
        },
        {
          "key": "genitourinary",
          "text": "Genitourinary"
        }
      ],
      "tableColumn": "57",
      "fqn": "pedVitals.pedVSepsisSigns"
    },
    {
      "elementKey": "pedVSepsisSignsText",
      "formIndex": "1",
      "inputType": "formInfoText",
      "tableColumn": "58",
      "fqn": "pedVitals.pedVSepsisSignsText"
    },
    {
      "elementKey": "pedVSepsisRisk",
      "formIndex": "1",
      "inputType": "checkset",
      "options": [
        {
          "key": "none",
          "text": "None"
        },
        {
          "key": "cardiac",
          "text": "Cardiac respiratory or neuromuscular disease"
        },
        {
          "key": "surgery",
          "text": "Recent surgery or hospitalization"
        },
        {
          "key": "age",
          "text": "Age less than 3 months or born premature"
        },
        {
          "key": "delay",
          "text": "Significant developmental delay"
        },
        {
          "key": "sepsis",
          "text": "Recent inpatient episode of sepsis (within 6-12 weeks)"
        },
        {
          "key": "immunocompromised",
          "text": "Immunocompromised"
        },
        {
          "key": "indwelling",
          "text": "Indwelling vascular access or medical device"
        },
        {
          "key": "recDrug",
          "text": "IV recreational drug use"
        }
      ],
      "tableColumn": "59",
      "fqn": "pedVitals.pedVSepsisRisk"
    },
    {
      "elementKey": "pedVComments",
      "formIndex": "1",
      "inputType": "textarea",
      "tableColumn": "61",
      "fqn": "pedVitals.pedVComments"
    }
  ],
  "pageElements": {
    "pedVForm": {
      "elementKey": "pedVForm",
      "pageElementIndex": "1",
      "pageKey": "pedVitals",
      "tableKey": "pedVForm",
      "isTable": true,
      "form": {
        "elementKey": "pedVForm",
        "label": "Vital Signs PEWS",
        "addButtonText": "Add a Vital Signs PEWS assessment",
        "formKey": "pedVForm",
        "ehr_groups": [
          {
            "elementKey": "pedVHdrGrp",
            "formCss": "record-header",
            "fqn": "pedVitals.pedVHdrGrp",
            "gIndex": "1",
            "gChildren": [
              "pedVForm_name",
              "pedVForm_profession",
              "pedVForm_day",
              "pedVForm_time"
            ]
          },
          {
            "elementKey": "pedVResp",
            "formCss": "ehr-row-flow",
            "fqn": "pedVitals.pedVResp",
            "gIndex": "2",
            "gChildren": [
              "pedVRespRate",
              "pedVRespRange",
              "pedVRespDepth",
              "pedVOxi",
              "pedVProbeReSited",
              "pedVOtherComm",
              "pedVProbeLocMod",
              "pedVProbeLoc",
              "pedVOxyDel",
              "pedVFlow",
              "pedVFiO2",
              "pedVSuppOxy",
              "pedVRespDist",
              "pedVPEWSResp"
            ]
          },
          {
            "elementKey": "pedVPulseGrp",
            "formCss": "ehr-row-flow",
            "fqn": "pedVitals.pedVPulseGrp",
            "gIndex": "3",
            "gChildren": [
              "pedVPulseRate",
              "pedVPewsRange",
              "pedVPulseSrc",
              "pedVLocMod",
              "pedVPulseLoc",
              "pedVPulseRhythm"
            ]
          },
          {
            "elementKey": "pedVBPGrp",
            "formCss": "ehr-row-flow",
            "fqn": "pedVitals.pedVBPGrp",
            "gIndex": "4",
            "gChildren": [
              "pedVBpSystolic",
              "pedVBpDiastolic",
              "pedVBPMean",
              "pedVBpPewsRange",
              "pedVBPSource",
              "pedVPatientPos"
            ]
          },
          {
            "elementKey": "pedVCardioGrp",
            "formCss": "ehr-row-flow",
            "fqn": "pedVitals.pedVCardioGrp",
            "gIndex": "5",
            "gChildren": [
              "pedVCapRefill",
              "pedVSkinColor",
              "pedVPEWSCV"
            ]
          },
          {
            "elementKey": "pedVBehavGrp",
            "formCss": "ehr-row-flow",
            "fqn": "pedVitals.pedVBehavGrp",
            "gIndex": "6",
            "gChildren": [
              "pedVBehav",
              "pedVPEWSBehav"
            ]
          },
          {
            "elementKey": "pedVAddPEW",
            "formCss": "ehr-row-flow",
            "fqn": "pedVitals.pedVAddPEW",
            "gIndex": "7",
            "gChildren": [
              "pedVVomPostSurg",
              "pedVBroncoEvery20",
              "pedVPEWSAdditional"
            ]
          },
          {
            "elementKey": "pedVPEWSGrp",
            "formCss": "ehr-row-flow",
            "fqn": "pedVitals.pedVPEWSGrp",
            "gIndex": "8",
            "gChildren": [
              "pedVTotalPEWS",
              "pedVPEWSInterp",
              "pedVPEWSInterpNote"
            ]
          },
          {
            "elementKey": "pedVTempGrp",
            "formCss": "ehr-row-flow",
            "fqn": "pedVitals.pedVTempGrp",
            "gIndex": "9",
            "gChildren": [
              "pedVTempSrc",
              "pedVTempNote",
              "pedVTemp",
              "pedVTempNormalCalc"
            ]
          },
          {
            "elementKey": "pedVSituationalGrp",
            "formCss": "ehr-row-flow",
            "fqn": "pedVitals.pedVSituationalGrp",
            "gIndex": "10",
            "gChildren": [
              "pedVSituFactors",
              "pedVSituFactorsComm",
              "pedVPEWSEscal",
              "pedVPEWSEscalNote"
            ]
          },
          {
            "elementKey": "pedVSepsisScreenGrp",
            "formCss": "ehr-row-flow",
            "fqn": "pedVitals.pedVSepsisScreenGrp",
            "gIndex": "11",
            "gChildren": [
              "pedVSepsisSigns",
              "pedVSepsisSignsText",
              "pedVSepsisRisk"
            ]
          },
          {
            "elementKey": "pedVCommGrp",
            "formCss": "grid-left-to-right-1",
            "fqn": "pedVitals.pedVCommGrp",
            "gIndex": "12",
            "gChildren": [
              "pedVComments"
            ]
          }
        ],
        "ehr_data": {
          "pedVForm_name": "",
          "pedVForm_profession": "",
          "pedVForm_day": "",
          "pedVForm_time": "",
          "pedVRespRate": "",
          "pedVRespDepth": "",
          "pedVOxi": "",
          "pedVProbeReSited": "",
          "pedVOtherComm": "",
          "pedVProbeLocMod": "",
          "pedVProbeLoc": "",
          "pedVOxyDel": "",
          "pedVFlow": "",
          "pedVFiO2": "",
          "pedVSuppOxy": "",
          "pedVRespDist": "",
          "pedVPEWSResp": "",
          "pedVPulseRate": "",
          "pedVPulseSrc": "",
          "pedVLocMod": "",
          "pedVPulseLoc": "",
          "pedVPulseRhythm": "",
          "pedVBpSystolic": "",
          "pedVBpDiastolic": "",
          "pedVBPMean": "",
          "pedVBPSource": "",
          "pedVPatientPos": "",
          "pedVCapRefill": "",
          "pedVSkinColor": "",
          "pedVPEWSCV": "",
          "pedVBehav": "",
          "pedVPEWSBehav": "",
          "pedVVomPostSurg": "",
          "pedVBroncoEvery20": "",
          "pedVPEWSAdditional": "",
          "pedVTotalPEWS": "",
          "pedVPEWSInterp": "",
          "pedVTempSrc": "",
          "pedVTemp": "",
          "pedVTempNormalCalc": "",
          "pedVSituFactors": "",
          "pedVSituFactorsComm": "",
          "pedVPEWSEscal": "",
          "pedVSepsisSigns": "",
          "pedVSepsisRisk": "",
          "pedVComments": ""
        }
      },
      "fqn": "pedVitals.pedVForm",
      "tableChildren": [
        "pedVForm_id",
        "pedVForm_day",
        "pedVForm_time",
        "pedVRespRate",
        "pedVRespRange",
        "pedVRespDepth",
        "pedVOxi",
        "pedVProbeReSited",
        "pedVOtherComm",
        "pedVProbeLocMod",
        "pedVProbeLoc",
        "pedVOxyDel",
        "pedVFlow",
        "pedVFiO2",
        "pedVSuppOxy",
        "pedVRespDist",
        "pedVPEWSResp",
        "pedVPulseRate",
        "pedVPewsRange",
        "pedVPulseSrc",
        "pedVLocMod",
        "pedVPulseLoc",
        "pedVPulseRhythm",
        "pedVBpSystolic",
        "pedVBpDiastolic",
        "pedVBPMean",
        "pedVBpPewsRange",
        "pedVBPSource",
        "pedVPatientPos",
        "pedVCapRefill",
        "pedVSkinColor",
        "pedVPEWSCV",
        "pedVBehav",
        "pedVPEWSBehav",
        "pedVVomPostSurg",
        "pedVBroncoEvery20",
        "pedVPEWSAdditional",
        "pedVTotalPEWS",
        "pedVPEWSInterp",
        "pedVPEWSInterpNote",
        "pedVTempSrc",
        "pedVTempNote",
        "pedVTemp",
        "pedVTempNormalCalc",
        "pedVSituFactors",
        "pedVSituFactorsComm",
        "pedVPEWSEscal",
        "pedVPEWSEscalNote",
        "pedVSepsisSigns",
        "pedVSepsisSignsText",
        "pedVSepsisRisk",
        "pedVComments",
        "pedVForm_name",
        "pedVForm_profession"
      ],
      "hasRecHeader": true
    }
  },
  "path": "/ehr/peri-ped/pediatrics",
  "routeName": "ped-vitals",
  "label": "Vital Signs PEWS",
  "generateComponent": "v2page",
  "featureFlag": "ffPed",
  "ehr": "yes",
  "fullPageKey": "periPed.pediatrics.pedVitals"
}
export default DEFS