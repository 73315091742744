/* eslint-disable */
const DEFS = {
  "pageDataKey": "newbornMeasure",
  "pIndex": "65",
  "hasGridTable": true,
  "pageChildren": [
    {
      "elementKey": "nbMeasure_form_name",
      "formIndex": "1",
      "inputType": "practitionerName",
      "tableColumn": "2",
      "recHeader": true,
      "fqn": "newbornMeasure.name"
    },
    {
      "elementKey": "nbMeasure_form_id",
      "inputType": "generatedId",
      "tableColumn": 0,
      "label": "Row id",
      "tableCss": "row-id"
    },
    {
      "elementKey": "nbMeasure_form_profession",
      "formIndex": "1",
      "inputType": "practitionerProfession",
      "tableColumn": "2",
      "recHeader": true,
      "fqn": "newbornMeasure.profession"
    },
    {
      "elementKey": "nbMeasure_form_day",
      "formIndex": "1",
      "inputType": "visitDay",
      "mandatory": true,
      "tableColumn": "2",
      "validation": "visitDay",
      "recHeader": true,
      "fqn": "newbornMeasure.day"
    },
    {
      "elementKey": "nbMeasure_form_time",
      "formIndex": "1",
      "inputType": "visitTime",
      "mandatory": true,
      "tableColumn": "2",
      "validation": "time24",
      "recHeader": true,
      "fqn": "newbornMeasure.time"
    },
    {
      "elementKey": "nbMeasureWt",
      "formIndex": "1",
      "inputType": "decimal2",
      "suffix": "kg",
      "tableColumn": "4",
      "fqn": "newbornMeasure.nbMeasureWt",
      "suffixText": "kg"
    },
    {
      "elementKey": "nbMeasureWtChangeKg",
      "formIndex": "1",
      "inputType": "decimal2",
      "suffix": "kg",
      "tableColumn": "5",
      "fqn": "newbornMeasure.nbMeasureWtChangeKg",
      "suffixText": "kg"
    },
    {
      "elementKey": "nbMeasureWtChangeKgPc",
      "formIndex": "1",
      "inputType": "number",
      "suffix": "%",
      "tableColumn": "6",
      "fqn": "newbornMeasure.nbMeasureWtChangeKgPc",
      "suffixText": "%"
    },
    {
      "elementKey": "nbMeasureFromPrev",
      "formIndex": "1",
      "inputType": "decimal2",
      "suffix": "kg",
      "tableColumn": "7",
      "fqn": "newbornMeasure.nbMeasureFromPrev",
      "suffixText": "kg"
    },
    {
      "elementKey": "nbMeasureDosing",
      "formIndex": "1",
      "inputType": "decimal2",
      "suffix": "kg",
      "tableColumn": "8",
      "fqn": "newbornMeasure.nbMeasureDosing",
      "suffixText": "kg"
    },
    {
      "elementKey": "nbMeasureHt",
      "formIndex": "1",
      "inputType": "number",
      "suffix": "cm",
      "tableColumn": "9",
      "fqn": "newbornMeasure.nbMeasureHt",
      "suffixText": "cm"
    },
    {
      "elementKey": "nbMeasureLn",
      "formIndex": "1",
      "inputType": "number",
      "suffix": "cm",
      "tableColumn": "10",
      "fqn": "newbornMeasure.nbMeasureLn",
      "suffixText": "cm"
    },
    {
      "elementKey": "nbMeasureHead",
      "formIndex": "1",
      "inputType": "number",
      "suffix": "cm",
      "tableColumn": "11",
      "fqn": "newbornMeasure.nbMeasureHead",
      "suffixText": "cm"
    },
    {
      "elementKey": "nbMeasureAbdominal",
      "formIndex": "1",
      "inputType": "number",
      "suffix": "cm",
      "tableColumn": "12",
      "fqn": "newbornMeasure.nbMeasureAbdominal",
      "suffixText": "cm"
    },
    {
      "elementKey": "nbMeasureComments",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "14",
      "fqn": "newbornMeasure.nbMeasureComments"
    }
  ],
  "pageElements": {
    "nbMeasure_form": {
      "elementKey": "nbMeasure_form",
      "pageElementIndex": "1",
      "pageKey": "newbornMeasure",
      "tableKey": "nbMeasure_form",
      "isTable": true,
      "form": {
        "elementKey": "nbMeasure_form",
        "label": "Neo/Ped Measure",
        "addButtonText": "Add Neo/Ped Measure",
        "formKey": "nbMeasure_form",
        "ehr_groups": [
          {
            "elementKey": "nbMeasure_hdrGroup",
            "formCss": "record-header",
            "fqn": "newbornMeasure.nbMeasure_hdrGroup",
            "gIndex": "1",
            "gChildren": [
              "nbMeasure_form_name",
              "nbMeasure_form_profession",
              "nbMeasure_form_day",
              "nbMeasure_form_time"
            ]
          },
          {
            "elementKey": "nbMeasure_1",
            "formCss": "ehr-row-flow",
            "fqn": "newbornMeasure.nbMeasure_1",
            "gIndex": "2",
            "gChildren": [
              "nbMeasureWt",
              "nbMeasureWtChangeKg",
              "nbMeasureWtChangeKgPc",
              "nbMeasureFromPrev",
              "nbMeasureDosing",
              "nbMeasureHt",
              "nbMeasureLn",
              "nbMeasureHead",
              "nbMeasureAbdominal"
            ]
          },
          {
            "elementKey": "nbMeasure_2",
            "formCss": "grid-left-to-right-1",
            "fqn": "newbornMeasure.nbMeasure_2",
            "gIndex": "3",
            "gChildren": [
              "nbMeasureComments"
            ]
          }
        ],
        "ehr_data": {
          "nbMeasure_form_name": "",
          "nbMeasure_form_profession": "",
          "nbMeasure_form_day": "",
          "nbMeasure_form_time": "",
          "nbMeasureWt": "",
          "nbMeasureWtChangeKg": "",
          "nbMeasureWtChangeKgPc": "",
          "nbMeasureFromPrev": "",
          "nbMeasureDosing": "",
          "nbMeasureHt": "",
          "nbMeasureLn": "",
          "nbMeasureHead": "",
          "nbMeasureAbdominal": "",
          "nbMeasureComments": ""
        }
      },
      "fqn": "newbornMeasure.nbMeasure_form",
      "tableChildren": [
        "nbMeasure_form_id",
        "nbMeasure_form_day",
        "nbMeasure_form_time",
        "nbMeasureWt",
        "nbMeasureWtChangeKg",
        "nbMeasureWtChangeKgPc",
        "nbMeasureFromPrev",
        "nbMeasureDosing",
        "nbMeasureHt",
        "nbMeasureLn",
        "nbMeasureHead",
        "nbMeasureAbdominal",
        "nbMeasureComments",
        "nbMeasure_form_name",
        "nbMeasure_form_profession"
      ],
      "hasRecHeader": true
    }
  },
  "path": "/ehr/peri-ped/newborn",
  "routeName": "newborn-measure",
  "label": "Neo/Ped Measure",
  "generateComponent": "v2page",
  "featureFlag": "ffPed",
  "ehr": "yes",
  "fullPageKey": "periPed.newborn.newbornMeasure"
}
export default DEFS