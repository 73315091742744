export default {
  namespaced: true,
  state: {
    pgCache: {}
  },
  getters: {
    getCached: (state) => state.pgCache
  },
  actions: {
    updateCache ({ commit }, newCache) {
      // console.log('pg cache UPDATE', newCache)
      commit('updateCache', newCache)
    },
    resetCache ({ commit }) {
      // console.log('pg cache RESET')
      commit('updateCache', {})
    }
  },
  mutations: {
    updateCache: (state, newCache) => {
      state.pgCache = newCache
    }
  }
}
