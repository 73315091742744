/* eslint-disable */
const DEFS = {
  "pageDataKey": "matSummary",
  "pIndex": "82",
  "formCss": "mat_page_level",
  "hasGridForm": true,
  "pageChildren": [
    {
      "elementKey": "matSummaryForm_name",
      "formIndex": "1",
      "inputType": "practitionerName",
      "tableColumn": "2",
      "recHeader": true,
      "fqn": "matSummary.name"
    },
    {
      "elementKey": "matSummaryForm_profession",
      "formIndex": "1",
      "inputType": "practitionerProfession",
      "tableColumn": "2",
      "recHeader": true,
      "fqn": "matSummary.profession"
    },
    {
      "elementKey": "matSummaryForm_day",
      "formIndex": "1",
      "inputType": "visitDay",
      "mandatory": true,
      "tableColumn": "2",
      "validation": "visitDay",
      "recHeader": true,
      "fqn": "matSummary.day"
    },
    {
      "elementKey": "matSummaryForm_time",
      "formIndex": "1",
      "inputType": "visitTime",
      "mandatory": true,
      "tableColumn": "2",
      "validation": "time24",
      "recHeader": true,
      "fqn": "matSummary.time"
    },
    {
      "elementKey": "matSummaryLabel",
      "formIndex": "1",
      "inputType": "form_label",
      "fqn": "matSummary.matSummaryLabel"
    },
    {
      "elementKey": "matSummaryBrthsCnt",
      "formIndex": "1",
      "inputType": "radioset",
      "options": [
        {
          "key": "singleton",
          "text": "Singleton"
        },
        {
          "key": "twin",
          "text": "Twin"
        },
        {
          "key": "triplet",
          "text": "Triplet"
        },
        {
          "key": "other",
          "text": "Other"
        }
      ],
      "tableColumn": "3",
      "fqn": "matSummary.matSummaryBrthsCnt"
    },
    {
      "elementKey": "matSummaryLvBrths",
      "formIndex": "1",
      "inputType": "radioset",
      "options": [
        {
          "key": "0",
          "text": "0"
        },
        {
          "key": "1",
          "text": "1"
        },
        {
          "key": "2",
          "text": "2"
        },
        {
          "key": "3",
          "text": "3"
        },
        {
          "key": "other",
          "text": "Other"
        }
      ],
      "tableColumn": "4",
      "fqn": "matSummary.matSummaryLvBrths"
    },
    {
      "elementKey": "matSummaryNwbrnAcctNum",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "5",
      "fqn": "matSummary.matSummaryNwbrnAcctNum"
    },
    {
      "elementKey": "matSummaryLvBrthsOth",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "6",
      "fqn": "matSummary.matSummaryLvBrthsOth"
    },
    {
      "elementKey": "matSummaryGrvd",
      "formIndex": "1",
      "inputType": "number",
      "tableColumn": "8",
      "fqn": "matSummary.matSummaryGrvd"
    },
    {
      "elementKey": "matSummaryPra",
      "formIndex": "1",
      "inputType": "number",
      "tableColumn": "9",
      "fqn": "matSummary.matSummaryPra"
    },
    {
      "elementKey": "matSummaryTrm",
      "formIndex": "1",
      "inputType": "number",
      "tableColumn": "10",
      "fqn": "matSummary.matSummaryTrm"
    },
    {
      "elementKey": "matSummaryPrtrm",
      "formIndex": "1",
      "inputType": "number",
      "tableColumn": "11",
      "fqn": "matSummary.matSummaryPrtrm"
    },
    {
      "elementKey": "matSummaryAbrts",
      "formIndex": "1",
      "inputType": "number",
      "tableColumn": "12",
      "fqn": "matSummary.matSummaryAbrts"
    },
    {
      "elementKey": "matSummaryLvg",
      "formIndex": "1",
      "inputType": "number",
      "tableColumn": "13",
      "fqn": "matSummary.matSummaryLvg"
    },
    {
      "elementKey": "matSummaryPlndDelMode",
      "formIndex": "1",
      "inputType": "radioset",
      "options": [
        {
          "key": "vaginal",
          "text": "Vaginal"
        },
        {
          "key": "primaryC-section",
          "text": "Primary C-section"
        },
        {
          "key": "repeatC-section",
          "text": "Repeat C-section"
        }
      ],
      "tableColumn": "14",
      "fqn": "matSummary.matSummaryPlndDelMode"
    },
    {
      "elementKey": "matSummaryEdd",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "14",
      "fqn": "matSummary.matSummaryEdd"
    },
    {
      "elementKey": "matSummaryGaDelWk",
      "formIndex": "1",
      "inputType": "number",
      "tableColumn": "15",
      "fqn": "matSummary.matSummaryGaDelWk"
    },
    {
      "elementKey": "matSummaryGaDelDay",
      "formIndex": "1",
      "inputType": "number",
      "tableColumn": "16",
      "fqn": "matSummary.matSummaryGaDelDay"
    },
    {
      "elementKey": "matSummaryGaDel",
      "formIndex": "1",
      "inputType": "number",
      "tableColumn": "17",
      "fqn": "matSummary.matSummaryGaDel"
    },
    {
      "elementKey": "matSummaryVbacElig",
      "formIndex": "1",
      "inputType": "radioset",
      "options": [
        {
          "key": "yes",
          "text": "Yes"
        },
        {
          "key": "no",
          "text": "No"
        },
        {
          "key": "na",
          "text": "Not applicable"
        }
      ],
      "tableColumn": "19",
      "fqn": "matSummary.matSummaryVbacElig"
    },
    {
      "elementKey": "matSummaryVbacAttmpt",
      "formIndex": "1",
      "inputType": "radioset",
      "options": [
        {
          "key": "yes",
          "text": "Yes"
        },
        {
          "key": "no",
          "text": "No"
        },
        {
          "key": "na",
          "text": "Not applicable"
        }
      ],
      "tableColumn": "20",
      "fqn": "matSummary.matSummaryVbacAttmpt"
    },
    {
      "elementKey": "matSummaryVbacNotEligCmt",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "21",
      "fqn": "matSummary.matSummaryVbacNotEligCmt"
    },
    {
      "elementKey": "matSummaryNotAttmptRsn",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "21",
      "fqn": "matSummary.matSummaryNotAttmptRsn"
    },
    {
      "elementKey": "LabourForm_name",
      "formIndex": "2",
      "inputType": "practitionerName",
      "tableColumn": "2",
      "recHeader": true,
      "fqn": "matSummary.name"
    },
    {
      "elementKey": "LabourForm_profession",
      "formIndex": "2",
      "inputType": "practitionerProfession",
      "tableColumn": "2",
      "recHeader": true,
      "fqn": "matSummary.profession"
    },
    {
      "elementKey": "LabourForm_day",
      "formIndex": "2",
      "inputType": "visitDay",
      "mandatory": true,
      "tableColumn": "2",
      "validation": "visitDay",
      "recHeader": true,
      "fqn": "matSummary.day"
    },
    {
      "elementKey": "LabourForm_time",
      "formIndex": "2",
      "inputType": "visitTime",
      "mandatory": true,
      "tableColumn": "2",
      "validation": "time24",
      "recHeader": true,
      "fqn": "matSummary.time"
    },
    {
      "elementKey": "LabourLabourType",
      "formIndex": "2",
      "inputType": "checkset",
      "options": [
        {
          "key": "noLabour",
          "text": "No labour"
        },
        {
          "key": "spontaneous",
          "text": "Spontaneous"
        },
        {
          "key": "augmented",
          "text": "Augmented"
        },
        {
          "key": "induced",
          "text": "Induced"
        }
      ],
      "tableColumn": "4",
      "fqn": "matSummary.LabourLabourType"
    },
    {
      "elementKey": "LabourAugMeth",
      "formIndex": "2",
      "inputType": "checkset",
      "options": [
        {
          "key": "ARM",
          "text": "Artificial Rupture of Membranes"
        },
        {
          "key": "oxytocin",
          "text": "Oxytocin(see MAR)"
        },
        {
          "key": "other",
          "text": "Other"
        }
      ],
      "tableColumn": "5",
      "fqn": "matSummary.LabourAugMeth"
    },
    {
      "elementKey": "LabourIndMeth",
      "formIndex": "2",
      "inputType": "checkset",
      "options": [
        {
          "key": "mechanical",
          "text": "Mechanical"
        },
        {
          "key": "ARM",
          "text": "Artificial Rupture of Membranes"
        },
        {
          "key": "oxytocin",
          "text": "Oxytocin (see MAR)"
        },
        {
          "key": "prostaglandins",
          "text": "Prostaglandin (see MAR)"
        },
        {
          "key": "other",
          "text": "Other"
        }
      ],
      "tableColumn": "6",
      "fqn": "matSummary.LabourIndMeth"
    },
    {
      "elementKey": "LabourProstagladin",
      "formIndex": "2",
      "inputType": "checkset",
      "options": [
        {
          "key": "dinoprostone",
          "text": "Dinoprostone"
        },
        {
          "key": "misoprostol",
          "text": "Misoprostol"
        }
      ],
      "tableColumn": "7",
      "fqn": "matSummary.LabourProstagladin"
    },
    {
      "elementKey": "LabourCervid",
      "formIndex": "2",
      "inputType": "number",
      "suffix": "doses",
      "tableColumn": "8",
      "fqn": "matSummary.LabourCervid",
      "suffixText": "doses"
    },
    {
      "elementKey": "LabourProstin",
      "formIndex": "2",
      "inputType": "number",
      "suffix": "doses",
      "tableColumn": "9",
      "fqn": "matSummary.LabourProstin",
      "suffixText": "doses"
    },
    {
      "elementKey": "LabourPrepidil",
      "formIndex": "2",
      "inputType": "number",
      "suffix": "doses",
      "tableColumn": "10",
      "fqn": "matSummary.LabourPrepidil",
      "suffixText": "doses"
    },
    {
      "elementKey": "LabourMOral",
      "formIndex": "2",
      "inputType": "number",
      "suffix": "doses",
      "tableColumn": "11",
      "fqn": "matSummary.LabourMOral",
      "suffixText": "doses"
    },
    {
      "elementKey": "LabourMvaginal",
      "formIndex": "2",
      "inputType": "number",
      "suffix": "doses",
      "tableColumn": "12",
      "fqn": "matSummary.LabourMvaginal",
      "suffixText": "doses"
    },
    {
      "elementKey": "LabourOxytocin",
      "formIndex": "2",
      "inputType": "number",
      "suffix": "doses",
      "tableColumn": "13",
      "fqn": "matSummary.LabourOxytocin",
      "suffixText": "doses"
    },
    {
      "elementKey": "LabourOxyDay1",
      "formIndex": "2",
      "inputType": "visitDay",
      "tableColumn": "14",
      "fqn": "matSummary.LabourOxyDay1"
    },
    {
      "elementKey": "LabourOxyTime1",
      "formIndex": "2",
      "inputType": "visitTime",
      "tableColumn": "15",
      "fqn": "matSummary.LabourOxyTime1"
    },
    {
      "elementKey": "LabourOxyDay2",
      "formIndex": "2",
      "inputType": "visitDay",
      "tableColumn": "16",
      "fqn": "matSummary.LabourOxyDay2"
    },
    {
      "elementKey": "LabourOxyTime2",
      "formIndex": "2",
      "inputType": "visitTime",
      "tableColumn": "17",
      "fqn": "matSummary.LabourOxyTime2"
    },
    {
      "elementKey": "LabourIndPrimInd",
      "formIndex": "2",
      "inputType": "text",
      "tableColumn": "18",
      "fqn": "matSummary.LabourIndPrimInd"
    },
    {
      "elementKey": "LabourLbrComments",
      "formIndex": "2",
      "inputType": "textarea",
      "tableColumn": "19",
      "fqn": "matSummary.LabourLbrComments"
    },
    {
      "elementKey": "LabourFtlPresLabour",
      "formIndex": "2",
      "inputType": "radioset",
      "options": [
        {
          "key": "cephalic",
          "text": "Cephalic"
        },
        {
          "key": "breech",
          "text": "Breech"
        },
        {
          "key": "transverse",
          "text": "Transverse"
        },
        {
          "key": "other",
          "text": "Other"
        }
      ],
      "tableColumn": "21",
      "fqn": "matSummary.LabourFtlPresLabour"
    },
    {
      "elementKey": "LabourBrchPosLabour",
      "formIndex": "2",
      "inputType": "radioset",
      "options": [
        {
          "key": "complete",
          "text": "Complete"
        },
        {
          "key": "footing",
          "text": "Footing"
        },
        {
          "key": "frank",
          "text": "Frank"
        }
      ],
      "tableColumn": "22",
      "fqn": "matSummary.LabourBrchPosLabour"
    },
    {
      "elementKey": "LabourFtlPosLabour",
      "formIndex": "2",
      "inputType": "checkset",
      "options": [
        {
          "key": "right",
          "text": "Right"
        },
        {
          "key": "left",
          "text": "Left"
        },
        {
          "key": "direct",
          "text": "Direct"
        },
        {
          "key": "occiput",
          "text": "Occiput"
        },
        {
          "key": "sacrum",
          "text": "Sacrum"
        },
        {
          "key": "transverse",
          "text": "Transverse"
        },
        {
          "key": "anterior",
          "text": "Anterior"
        },
        {
          "key": "posterior",
          "text": "Posterior"
        },
        {
          "key": "other",
          "text": "Other"
        }
      ],
      "tableColumn": "23",
      "fqn": "matSummary.LabourFtlPosLabour"
    },
    {
      "elementKey": "LabourFtlSurveillance",
      "formIndex": "2",
      "inputType": "checkset",
      "options": [
        {
          "key": "ia",
          "text": "IA"
        },
        {
          "key": "efmExternal",
          "text": "External EFM"
        },
        {
          "key": "efmInternal",
          "text": "Internal EFM"
        },
        {
          "key": "iupc",
          "text": "IUPC"
        },
        {
          "key": "fetalScalpBlood",
          "text": "Fetal scalp blood"
        },
        {
          "key": "fetalScalpLactate",
          "text": "Fetal scalp lactate"
        }
      ],
      "tableColumn": "24",
      "fqn": "matSummary.LabourFtlSurveillance"
    },
    {
      "elementKey": "LabourFtlPresLabourOth",
      "formIndex": "2",
      "inputType": "text",
      "tableColumn": "25",
      "fqn": "matSummary.LabourFtlPresLabourOth"
    },
    {
      "elementKey": "LabourFtlPosLabourOth",
      "formIndex": "2",
      "inputType": "text",
      "tableColumn": "26",
      "fqn": "matSummary.LabourFtlPosLabourOth"
    },
    {
      "elementKey": "LabourIndExtEFM",
      "formIndex": "2",
      "inputType": "text",
      "tableColumn": "27",
      "fqn": "matSummary.LabourIndExtEFM"
    },
    {
      "elementKey": "LabourIndIntEFM",
      "formIndex": "2",
      "inputType": "text",
      "tableColumn": "28",
      "fqn": "matSummary.LabourIndIntEFM"
    },
    {
      "elementKey": "LabourFtlScalpBloodCollected",
      "formIndex": "2",
      "inputType": "text",
      "tableColumn": "29",
      "fqn": "matSummary.LabourFtlScalpBloodCollected"
    },
    {
      "elementKey": "LabourFtlScalpBloodResult",
      "formIndex": "2",
      "inputType": "text",
      "tableColumn": "30",
      "fqn": "matSummary.LabourFtlScalpBloodResult"
    },
    {
      "elementKey": "LabourFtlScalpLactateCollected",
      "formIndex": "2",
      "inputType": "text",
      "tableColumn": "31",
      "fqn": "matSummary.LabourFtlScalpLactateCollected"
    },
    {
      "elementKey": "LabourFtlScalpLactateResult",
      "formIndex": "2",
      "inputType": "text",
      "tableColumn": "32",
      "fqn": "matSummary.LabourFtlScalpLactateResult"
    },
    {
      "elementKey": "LabourFtlScalpLactateInterp",
      "formIndex": "2",
      "inputType": "text",
      "tableColumn": "33",
      "fqn": "matSummary.LabourFtlScalpLactateInterp"
    },
    {
      "elementKey": "LabourAnalgesia",
      "formIndex": "2",
      "inputType": "checkset",
      "options": [
        {
          "key": "comfort",
          "text": "Comfort, labour support, non-pharm other"
        },
        {
          "key": "waterImmersion",
          "text": "Water immersion"
        },
        {
          "key": "doula",
          "text": "Doula support"
        },
        {
          "key": "nitrousOxide",
          "text": "Nitrous oxide gas"
        },
        {
          "key": "opiods",
          "text": "Opioids"
        }
      ],
      "tableColumn": "35",
      "fqn": "matSummary.LabourAnalgesia"
    },
    {
      "elementKey": "LabourAnesthLabour1",
      "formIndex": "2",
      "inputType": "checkset",
      "options": [
        {
          "key": "none",
          "text": "None"
        },
        {
          "key": "epidural",
          "text": "Epidural"
        },
        {
          "key": "local",
          "text": "Local"
        },
        {
          "key": "spinal",
          "text": "Spinal"
        },
        {
          "key": "combined",
          "text": "Combined"
        },
        {
          "key": "pudendal",
          "text": "Pudendal"
        },
        {
          "key": "other",
          "text": "Other"
        }
      ],
      "tableColumn": "36",
      "fqn": "matSummary.LabourAnesthLabour1"
    },
    {
      "elementKey": "LabourAnesthLabour2",
      "formIndex": "2",
      "inputType": "text",
      "tableColumn": "37",
      "fqn": "matSummary.LabourAnesthLabour2"
    },
    {
      "elementKey": "LabourAnesthCS",
      "formIndex": "2",
      "inputType": "checkset",
      "options": [
        {
          "key": "none",
          "text": "None"
        },
        {
          "key": "epidural",
          "text": "Epidural"
        },
        {
          "key": "spinal",
          "text": "Spinal"
        },
        {
          "key": "combined",
          "text": "Combined"
        },
        {
          "key": "general",
          "text": "General"
        }
      ],
      "tableColumn": "38",
      "fqn": "matSummary.LabourAnesthCS"
    },
    {
      "elementKey": "LabourAbxAdmin",
      "formIndex": "2",
      "inputType": "radioset",
      "options": [
        {
          "key": "yes",
          "text": "Yes"
        },
        {
          "key": "no",
          "text": "No"
        }
      ],
      "tableColumn": "39",
      "fqn": "matSummary.LabourAbxAdmin"
    },
    {
      "elementKey": "LabourAbxInd",
      "formIndex": "2",
      "inputType": "checkset",
      "options": [
        {
          "key": "none",
          "text": "None"
        },
        {
          "key": "GBS",
          "text": "GBS"
        },
        {
          "key": "intraFever",
          "text": "Intrapartum fever, chorio"
        },
        {
          "key": "preOperative",
          "text": "Pre-operative"
        }
      ],
      "tableColumn": "40",
      "fqn": "matSummary.LabourAbxInd"
    },
    {
      "elementKey": "LabourMedInd",
      "formIndex": "2",
      "inputType": "checkset",
      "options": [
        {
          "key": "none",
          "text": "None"
        },
        {
          "key": "intrapartumHypertension",
          "text": "intrapartumHypertension"
        },
        {
          "key": "seizureProphylaxis",
          "text": "Seizure prophylaxis (MgS04)"
        },
        {
          "key": "fetalNeuroprotection",
          "text": "Fetal neuroprotection (MgS04)"
        }
      ],
      "tableColumn": "41",
      "fqn": "matSummary.LabourMedInd"
    },
    {
      "elementKey": "BirthForm_name",
      "formIndex": "3",
      "inputType": "practitionerName",
      "tableColumn": "2",
      "recHeader": true,
      "fqn": "matSummary.name"
    },
    {
      "elementKey": "BirthForm_profession",
      "formIndex": "3",
      "inputType": "practitionerProfession",
      "tableColumn": "2",
      "recHeader": true,
      "fqn": "matSummary.profession"
    },
    {
      "elementKey": "BirthForm_day",
      "formIndex": "3",
      "inputType": "visitDay",
      "mandatory": true,
      "tableColumn": "2",
      "validation": "visitDay",
      "recHeader": true,
      "fqn": "matSummary.day"
    },
    {
      "elementKey": "BirthForm_time",
      "formIndex": "3",
      "inputType": "visitTime",
      "mandatory": true,
      "tableColumn": "2",
      "validation": "time24",
      "recHeader": true,
      "fqn": "matSummary.time"
    },
    {
      "elementKey": "BirthFtlPresBirth",
      "formIndex": "3",
      "inputType": "radioset",
      "options": [
        {
          "key": "cephalic",
          "text": "Cephalic"
        },
        {
          "key": "breech",
          "text": "Breech"
        },
        {
          "key": "transverse",
          "text": "Transverse"
        },
        {
          "key": "other",
          "text": "Other"
        }
      ],
      "tableColumn": "4",
      "fqn": "matSummary.BirthFtlPresBirth"
    },
    {
      "elementKey": "BirthBrchPosBirth",
      "formIndex": "3",
      "inputType": "radioset",
      "options": [
        {
          "key": "complete",
          "text": "Complete"
        },
        {
          "key": "footing",
          "text": "Footing"
        },
        {
          "key": "frank",
          "text": "Frank"
        }
      ],
      "tableColumn": "5",
      "fqn": "matSummary.BirthBrchPosBirth"
    },
    {
      "elementKey": "BirthFtlPosBirth",
      "formIndex": "3",
      "inputType": "checkset",
      "options": [
        {
          "key": "right",
          "text": "Right"
        },
        {
          "key": "left",
          "text": "Left"
        },
        {
          "key": "direct",
          "text": "Direct"
        },
        {
          "key": "occiput",
          "text": "Occiput"
        },
        {
          "key": "sacrum",
          "text": "Sacrum"
        },
        {
          "key": "transverse",
          "text": "Transverse"
        },
        {
          "key": "anterior",
          "text": "Anterior"
        },
        {
          "key": "posterior",
          "text": "Posterior"
        },
        {
          "key": "other",
          "text": "Other"
        }
      ],
      "tableColumn": "6",
      "fqn": "matSummary.BirthFtlPosBirth"
    },
    {
      "elementKey": "BirthAmnFluidBirth",
      "formIndex": "3",
      "inputType": "checkset",
      "options": [
        {
          "key": "clear",
          "text": "Clear"
        },
        {
          "key": "bloody",
          "text": "Bloody"
        },
        {
          "key": "meconium",
          "text": "Meconium"
        },
        {
          "key": "other",
          "text": "Other"
        }
      ],
      "tableColumn": "7",
      "fqn": "matSummary.BirthAmnFluidBirth"
    },
    {
      "elementKey": "BirthDelType",
      "formIndex": "3",
      "inputType": "radioset",
      "options": [
        {
          "key": "spontaneous",
          "text": "Spontaneous vaginal"
        },
        {
          "key": "assisted",
          "text": "Assisted vaginal"
        },
        {
          "key": "caesarean",
          "text": "Caesarean section"
        }
      ],
      "tableColumn": "8",
      "fqn": "matSummary.BirthDelType"
    },
    {
      "elementKey": "BirthAssistVagDelMeth",
      "formIndex": "3",
      "inputType": "checkset",
      "options": [
        {
          "key": "vacuum",
          "text": "Vacuum"
        },
        {
          "key": "forceps",
          "text": "Forceps"
        },
        {
          "key": "outlet",
          "text": "Outlet"
        },
        {
          "key": "low",
          "text": "Low"
        },
        {
          "key": "mid",
          "text": "Mid"
        },
        {
          "key": "rotation",
          "text": "Rotation"
        },
        {
          "key": "other",
          "text": "Other"
        }
      ],
      "tableColumn": "9",
      "fqn": "matSummary.BirthAssistVagDelMeth"
    },
    {
      "elementKey": "BirthCsType",
      "formIndex": "3",
      "inputType": "radioset",
      "options": [
        {
          "key": "scheduled",
          "text": "Scheduled"
        },
        {
          "key": "emergent",
          "text": "Emergent"
        }
      ],
      "tableColumn": "10",
      "fqn": "matSummary.BirthCsType"
    },
    {
      "elementKey": "BirthCsDecisionDate",
      "formIndex": "3",
      "inputType": "visitDay",
      "tableColumn": "11",
      "fqn": "matSummary.BirthCsDecisionDate"
    },
    {
      "elementKey": "BirthCsDecisionTime",
      "formIndex": "3",
      "inputType": "visitTime",
      "tableColumn": "12",
      "fqn": "matSummary.BirthCsDecisionTime"
    },
    {
      "elementKey": "BirthCervDilationPreCS",
      "formIndex": "3",
      "inputType": "number",
      "suffix": "cm",
      "tableColumn": "13",
      "fqn": "matSummary.BirthCervDilationPreCS",
      "suffixText": "cm"
    },
    {
      "elementKey": "BirthFtlPosBirthOth",
      "formIndex": "3",
      "inputType": "text",
      "tableColumn": "15",
      "fqn": "matSummary.BirthFtlPosBirthOth"
    },
    {
      "elementKey": "BirthAmnFluidBirthOth",
      "formIndex": "3",
      "inputType": "text",
      "tableColumn": "16",
      "fqn": "matSummary.BirthAmnFluidBirthOth"
    },
    {
      "elementKey": "BirthAssistVagDelMethOth",
      "formIndex": "3",
      "inputType": "text",
      "tableColumn": "17",
      "fqn": "matSummary.BirthAssistVagDelMethOth"
    },
    {
      "elementKey": "BirthCsTypeOth",
      "formIndex": "3",
      "inputType": "text",
      "tableColumn": "18",
      "fqn": "matSummary.BirthCsTypeOth"
    },
    {
      "elementKey": "BirthCsIndOth",
      "formIndex": "3",
      "inputType": "text",
      "tableColumn": "19",
      "fqn": "matSummary.BirthCsIndOth"
    },
    {
      "elementKey": "BirthCsPrimInd",
      "formIndex": "3",
      "inputType": "text",
      "tableColumn": "20",
      "fqn": "matSummary.BirthCsPrimInd"
    },
    {
      "elementKey": "BirthUterotonicsGiven",
      "formIndex": "3",
      "inputType": "checkset",
      "options": [
        {
          "key": "none",
          "text": "None"
        },
        {
          "key": "oxytocin",
          "text": "Oxytocin"
        },
        {
          "key": "carbetocin",
          "text": "Carbetocin"
        },
        {
          "key": "other",
          "text": "Other"
        }
      ],
      "tableColumn": "22",
      "fqn": "matSummary.BirthUterotonicsGiven"
    },
    {
      "elementKey": "BirthOxyDoseIM",
      "formIndex": "3",
      "inputType": "number",
      "tableColumn": "23",
      "fqn": "matSummary.BirthOxyDoseIM"
    },
    {
      "elementKey": "BirthOxyDoseIV",
      "formIndex": "3",
      "inputType": "number",
      "tableColumn": "24",
      "fqn": "matSummary.BirthOxyDoseIV"
    },
    {
      "elementKey": "BirthCarbDoseIM",
      "formIndex": "3",
      "inputType": "number",
      "suffix": "mcg",
      "tableColumn": "25",
      "fqn": "matSummary.BirthCarbDoseIM",
      "suffixText": "mcg"
    },
    {
      "elementKey": "BirthCarbDoseIV",
      "formIndex": "3",
      "inputType": "number",
      "suffix": "mcg",
      "tableColumn": "26",
      "fqn": "matSummary.BirthCarbDoseIV",
      "suffixText": "mcg"
    },
    {
      "elementKey": "BirthUterotonicsGivenOth",
      "formIndex": "3",
      "inputType": "text",
      "tableColumn": "27",
      "fqn": "matSummary.BirthUterotonicsGivenOth"
    },
    {
      "elementKey": "BirthPlacentaDelMeth",
      "formIndex": "3",
      "inputType": "checkset",
      "options": [
        {
          "key": "controlledTractions",
          "text": "Controlled tractions"
        },
        {
          "key": "manual",
          "text": "Manual removal"
        },
        {
          "key": "spontaneous",
          "text": "Spontaneous"
        },
        {
          "key": "surgical",
          "text": "Surgical removal"
        }
      ],
      "tableColumn": "28",
      "fqn": "matSummary.BirthPlacentaDelMeth"
    },
    {
      "elementKey": "BirthPlacentaComplete",
      "formIndex": "3",
      "inputType": "radioset",
      "options": [
        {
          "key": "yes",
          "text": "Yes"
        },
        {
          "key": "no",
          "text": "No"
        }
      ],
      "tableColumn": "29",
      "fqn": "matSummary.BirthPlacentaComplete"
    },
    {
      "elementKey": "BirthPlacentaPath",
      "formIndex": "3",
      "inputType": "radioset",
      "options": [
        {
          "key": "yes",
          "text": "Yes"
        },
        {
          "key": "no",
          "text": "No"
        }
      ],
      "tableColumn": "30",
      "fqn": "matSummary.BirthPlacentaPath"
    },
    {
      "elementKey": "BirthUmbVessels",
      "formIndex": "3",
      "inputType": "radioset",
      "options": [
        {
          "key": "2",
          "text": "2"
        },
        {
          "key": "3",
          "text": "3"
        },
        {
          "key": "other",
          "text": "Other"
        }
      ],
      "tableColumn": "32",
      "fqn": "matSummary.BirthUmbVessels"
    },
    {
      "elementKey": "BirthCordBlood",
      "formIndex": "3",
      "inputType": "checkset",
      "options": [
        {
          "key": "Rh",
          "text": "Rh"
        },
        {
          "key": "bloodGas",
          "text": "Blood gas"
        },
        {
          "key": "notCollected",
          "text": "Not collected"
        },
        {
          "key": "other",
          "text": "Other"
        }
      ],
      "tableColumn": "33",
      "fqn": "matSummary.BirthCordBlood"
    },
    {
      "elementKey": "BirthCordClamped",
      "formIndex": "3",
      "inputType": "checkset",
      "options": [
        {
          "key": "<15Sec",
          "text": "<15 sec"
        },
        {
          "key": "15-30Sec",
          "text": "15-30 sec"
        },
        {
          "key": "31-60Sec",
          "text": "31-60 sec"
        },
        {
          "key": ">60Sec",
          "text": ">60 sec"
        }
      ],
      "tableColumn": "34",
      "fqn": "matSummary.BirthCordClamped"
    },
    {
      "elementKey": "BirthUmbVesselsOth",
      "formIndex": "3",
      "inputType": "text",
      "tableColumn": "35",
      "fqn": "matSummary.BirthUmbVesselsOth"
    },
    {
      "elementKey": "BirthCordBloodOth",
      "formIndex": "3",
      "inputType": "text",
      "tableColumn": "36",
      "fqn": "matSummary.BirthCordBloodOth"
    },
    {
      "elementKey": "BirthEarlyCordClampRsn",
      "formIndex": "3",
      "inputType": "text",
      "tableColumn": "37",
      "fqn": "matSummary.BirthEarlyCordClampRsn"
    },
    {
      "elementKey": "BirthPerineumDesc",
      "formIndex": "3",
      "inputType": "checkset",
      "options": [
        {
          "key": "intact",
          "text": "Intact"
        },
        {
          "key": "cervicalTear",
          "text": "Cervical tear"
        },
        {
          "key": "laceration1",
          "text": "Laceration 1st degree"
        },
        {
          "key": "laceration2",
          "text": "Laceration 2nd degree"
        },
        {
          "key": "laceration3",
          "text": "Laceration 3rd degree"
        },
        {
          "key": "laceration4",
          "text": "Laceration 4th degree"
        },
        {
          "key": "episiotomyMedio",
          "text": "Mediolateral episiotomy"
        },
        {
          "key": "episiotomyMid",
          "text": "Midline episiotomy"
        },
        {
          "key": "other",
          "text": "Other"
        }
      ],
      "tableColumn": "39",
      "fqn": "matSummary.BirthPerineumDesc"
    },
    {
      "elementKey": "BirthRepairBy",
      "formIndex": "3",
      "inputType": "text",
      "tableColumn": "40",
      "fqn": "matSummary.BirthRepairBy"
    },
    {
      "elementKey": "BirthSpongeCountCorrect",
      "formIndex": "3",
      "inputType": "radioset",
      "options": [
        {
          "key": "yes",
          "text": "Yes"
        },
        {
          "key": "no",
          "text": "No"
        }
      ],
      "tableColumn": "40",
      "fqn": "matSummary.BirthSpongeCountCorrect"
    },
    {
      "elementKey": "BirthNeedleCountCorrect",
      "formIndex": "3",
      "inputType": "radioset",
      "options": [
        {
          "key": "yes",
          "text": "Yes"
        },
        {
          "key": "no",
          "text": "No"
        }
      ],
      "tableColumn": "41",
      "fqn": "matSummary.BirthNeedleCountCorrect"
    },
    {
      "elementKey": "BirthPerineumDescOth",
      "formIndex": "3",
      "inputType": "text",
      "tableColumn": "42",
      "fqn": "matSummary.BirthPerineumDescOth"
    },
    {
      "elementKey": "BirthQuantBloodLoss",
      "formIndex": "3",
      "inputType": "form_label",
      "tableColumn": "43",
      "fqn": "matSummary.BirthQuantBloodLoss"
    },
    {
      "elementKey": "BirthMeasureType",
      "formIndex": "3",
      "inputType": "radioset",
      "options": [
        {
          "key": "m",
          "text": "Measured"
        },
        {
          "key": "e",
          "text": "Estimated"
        }
      ],
      "tableColumn": "44",
      "fqn": "matSummary.BirthMeasureType"
    },
    {
      "elementKey": "BirthMeasureBloodLoss",
      "formIndex": "3",
      "inputType": "radioset",
      "options": [
        {
          "key": "<500mL",
          "text": "<500 mL"
        },
        {
          "key": "50l-1000ML",
          "text": "50l-1000mL"
        },
        {
          "key": "1001-1500",
          "text": "1001-1500mL"
        },
        {
          "key": ">1500 mL",
          "text": ">1500 mL"
        }
      ],
      "tableColumn": "45",
      "fqn": "matSummary.BirthMeasureBloodLoss"
    },
    {
      "elementKey": "BirthBloodLossInterv",
      "formIndex": "3",
      "inputType": "checkset",
      "options": [
        {
          "key": "none",
          "text": "None"
        },
        {
          "key": "bloodProducts",
          "text": "Blood products"
        },
        {
          "key": "medication",
          "text": "Medication (see MAR)"
        },
        {
          "key": "other",
          "text": "Other"
        }
      ],
      "tableColumn": "46",
      "fqn": "matSummary.BirthBloodLossInterv"
    },
    {
      "elementKey": "BirthBloodLossIntervOth",
      "formIndex": "3",
      "inputType": "text",
      "tableColumn": "47",
      "fqn": "matSummary.BirthBloodLossIntervOth"
    },
    {
      "elementKey": "TimeForm_name",
      "formIndex": "4",
      "inputType": "practitionerName",
      "tableColumn": "2",
      "recHeader": true,
      "fqn": "matSummary.name"
    },
    {
      "elementKey": "TimeForm_profession",
      "formIndex": "4",
      "inputType": "practitionerProfession",
      "tableColumn": "2",
      "recHeader": true,
      "fqn": "matSummary.profession"
    },
    {
      "elementKey": "TimeForm_day",
      "formIndex": "4",
      "inputType": "visitDay",
      "mandatory": true,
      "tableColumn": "2",
      "validation": "visitDay",
      "recHeader": true,
      "fqn": "matSummary.day"
    },
    {
      "elementKey": "TimeForm_time",
      "formIndex": "4",
      "inputType": "visitTime",
      "mandatory": true,
      "tableColumn": "2",
      "validation": "time24",
      "recHeader": true,
      "fqn": "matSummary.time"
    },
    {
      "elementKey": "TimeMemRuptDate",
      "formIndex": "4",
      "inputType": "visitDay",
      "tableColumn": "4",
      "fqn": "matSummary.TimeMemRuptDate"
    },
    {
      "elementKey": "TimeMemRuptTime",
      "formIndex": "4",
      "inputType": "visitTime",
      "tableColumn": "5",
      "fqn": "matSummary.TimeMemRuptTime"
    },
    {
      "elementKey": "TimeStage1StartDate",
      "formIndex": "4",
      "inputType": "visitDay",
      "tableColumn": "6",
      "fqn": "matSummary.TimeStage1StartDate"
    },
    {
      "elementKey": "TimeStage1StartTime",
      "formIndex": "4",
      "inputType": "visitTime",
      "tableColumn": "7",
      "fqn": "matSummary.TimeStage1StartTime"
    },
    {
      "elementKey": "TimeFullDilationDate",
      "formIndex": "4",
      "inputType": "visitDay",
      "tableColumn": "8",
      "fqn": "matSummary.TimeFullDilationDate"
    },
    {
      "elementKey": "TimeFullDilationTime",
      "formIndex": "4",
      "inputType": "visitTime",
      "tableColumn": "9",
      "fqn": "matSummary.TimeFullDilationTime"
    },
    {
      "elementKey": "TimeStage2ActiveStartDate",
      "formIndex": "4",
      "inputType": "visitDay",
      "tableColumn": "10",
      "fqn": "matSummary.TimeStage2ActiveStartDate"
    },
    {
      "elementKey": "TimeStage2ActiveStartTime",
      "formIndex": "4",
      "inputType": "visitTime",
      "tableColumn": "11",
      "fqn": "matSummary.TimeStage2ActiveStartTime"
    },
    {
      "elementKey": "TimeNbBirthDate",
      "formIndex": "4",
      "inputType": "visitDay",
      "tableColumn": "12",
      "fqn": "matSummary.TimeNbBirthDate"
    },
    {
      "elementKey": "TimeNbBirthTime",
      "formIndex": "4",
      "inputType": "visitTime",
      "tableColumn": "13",
      "fqn": "matSummary.TimeNbBirthTime"
    },
    {
      "elementKey": "TimePlacentaDelDate",
      "formIndex": "4",
      "inputType": "visitDay",
      "tableColumn": "14",
      "fqn": "matSummary.TimePlacentaDelDate"
    },
    {
      "elementKey": "TimePlacentaDelTime",
      "formIndex": "4",
      "inputType": "visitTime",
      "tableColumn": "15",
      "fqn": "matSummary.TimePlacentaDelTime"
    },
    {
      "elementKey": "TimeRomTotalTime",
      "formIndex": "4",
      "inputType": "text",
      "tableColumn": "16",
      "fqn": "matSummary.TimeRomTotalTime"
    },
    {
      "elementKey": "TimeStage1Dur",
      "formIndex": "4",
      "inputType": "text",
      "tableColumn": "18",
      "fqn": "matSummary.TimeStage1Dur"
    },
    {
      "elementKey": "TimeStage2DurPassive",
      "formIndex": "4",
      "inputType": "text",
      "tableColumn": "19",
      "fqn": "matSummary.TimeStage2DurPassive"
    },
    {
      "elementKey": "TimeStage2DurActive",
      "formIndex": "4",
      "inputType": "text",
      "tableColumn": "20",
      "fqn": "matSummary.TimeStage2DurActive"
    },
    {
      "elementKey": "TimeStage3Dur",
      "formIndex": "4",
      "inputType": "text",
      "tableColumn": "21",
      "fqn": "matSummary.TimeStage3Dur"
    },
    {
      "elementKey": "TimeTotalDur",
      "formIndex": "4",
      "inputType": "text",
      "tableColumn": "22",
      "fqn": "matSummary.TimeTotalDur"
    },
    {
      "elementKey": "FetalStForm_name",
      "formIndex": "5",
      "inputType": "practitionerName",
      "tableColumn": "2",
      "recHeader": true,
      "fqn": "matSummary.name"
    },
    {
      "elementKey": "FetalStForm_profession",
      "formIndex": "5",
      "inputType": "practitionerProfession",
      "tableColumn": "2",
      "recHeader": true,
      "fqn": "matSummary.profession"
    },
    {
      "elementKey": "FetalStForm_day",
      "formIndex": "5",
      "inputType": "visitDay",
      "mandatory": true,
      "tableColumn": "2",
      "validation": "visitDay",
      "recHeader": true,
      "fqn": "matSummary.day"
    },
    {
      "elementKey": "FetalStForm_time",
      "formIndex": "5",
      "inputType": "visitTime",
      "mandatory": true,
      "tableColumn": "2",
      "validation": "time24",
      "recHeader": true,
      "fqn": "matSummary.time"
    },
    {
      "elementKey": "FetalStBirthSex",
      "formIndex": "5",
      "inputType": "radioset",
      "options": [
        {
          "key": "male",
          "text": "Male"
        },
        {
          "key": "female",
          "text": "Female"
        },
        {
          "key": "undifferentiated",
          "text": "Undifferentiated"
        }
      ],
      "tableColumn": "4",
      "fqn": "matSummary.FetalStBirthSex"
    },
    {
      "elementKey": "FetalStBirthWeight",
      "formIndex": "5",
      "inputType": "number",
      "suffix": "kg",
      "tableColumn": "5",
      "fqn": "matSummary.FetalStBirthWeight",
      "suffixText": "kg"
    },
    {
      "elementKey": "FetalStApgar1",
      "formIndex": "5",
      "inputType": "number",
      "tableColumn": "7",
      "fqn": "matSummary.FetalStApgar1"
    },
    {
      "elementKey": "FetalStApgar5",
      "formIndex": "5",
      "inputType": "number",
      "tableColumn": "8",
      "fqn": "matSummary.FetalStApgar5"
    },
    {
      "elementKey": "FetalStApgar10",
      "formIndex": "5",
      "inputType": "number",
      "tableColumn": "9",
      "fqn": "matSummary.FetalStApgar10"
    },
    {
      "elementKey": "FetalStFtlDeathSingle2",
      "formIndex": "5",
      "inputType": "radioset",
      "options": [
        {
          "key": "spontaneousPre",
          "text": "Spontaneous pre-labour"
        },
        {
          "key": "spontaneousDuring",
          "text": "Spontaneous during labour"
        },
        {
          "key": "elected",
          "text": "Elected/intended"
        }
      ],
      "tableColumn": "7",
      "fqn": "matSummary.FetalStFtlDeathSingle2"
    },
    {
      "elementKey": "FetalStFtlDeathDate",
      "formIndex": "5",
      "inputType": "visitDay",
      "tableColumn": "8",
      "fqn": "matSummary.FetalStFtlDeathDate"
    },
    {
      "elementKey": "FetalStGaDeathWks",
      "formIndex": "5",
      "inputType": "number",
      "suffix": "weeks",
      "tableColumn": "9",
      "fqn": "matSummary.FetalStGaDeathWks",
      "suffixText": "weeks"
    },
    {
      "elementKey": "FetalStGaDeathDays",
      "formIndex": "5",
      "inputType": "number",
      "suffix": "days",
      "tableColumn": "10",
      "fqn": "matSummary.FetalStGaDeathDays",
      "suffixText": "days"
    },
    {
      "elementKey": "FetalStGaDeath",
      "formIndex": "5",
      "inputType": "number",
      "tableColumn": "11",
      "fqn": "matSummary.FetalStGaDeath"
    },
    {
      "elementKey": "SignOffForm_name",
      "formIndex": "6",
      "inputType": "practitionerName",
      "tableColumn": "2",
      "recHeader": true,
      "fqn": "matSummary.name"
    },
    {
      "elementKey": "SignOffForm_profession",
      "formIndex": "6",
      "inputType": "practitionerProfession",
      "tableColumn": "2",
      "recHeader": true,
      "fqn": "matSummary.profession"
    },
    {
      "elementKey": "SignOffForm_day",
      "formIndex": "6",
      "inputType": "visitDay",
      "mandatory": true,
      "tableColumn": "2",
      "validation": "visitDay",
      "recHeader": true,
      "fqn": "matSummary.day"
    },
    {
      "elementKey": "SignOffForm_time",
      "formIndex": "6",
      "inputType": "visitTime",
      "mandatory": true,
      "tableColumn": "2",
      "validation": "time24",
      "recHeader": true,
      "fqn": "matSummary.time"
    },
    {
      "elementKey": "SignOffPlcBrth",
      "formIndex": "6",
      "inputType": "radioset",
      "options": [
        {
          "key": "hospital",
          "text": "Hospital"
        },
        {
          "key": "home",
          "text": "Home"
        },
        {
          "key": "other",
          "text": "Other"
        }
      ],
      "tableColumn": "4",
      "fqn": "matSummary.SignOffPlcBrth"
    },
    {
      "elementKey": "SignOffPlcBrthOth",
      "formIndex": "6",
      "inputType": "text",
      "tableColumn": "5",
      "fqn": "matSummary.SignOffPlcBrthOth"
    },
    {
      "elementKey": "SignOffPlnPlcBrthOnset",
      "formIndex": "6",
      "inputType": "radioset",
      "options": [
        {
          "key": "hospital",
          "text": "Hospital"
        },
        {
          "key": "home",
          "text": "Home"
        },
        {
          "key": "other",
          "text": "Other"
        }
      ],
      "tableColumn": "6",
      "fqn": "matSummary.SignOffPlnPlcBrthOnset"
    },
    {
      "elementKey": "SignOffPlnPlcBrthOnsetOth",
      "formIndex": "6",
      "inputType": "text",
      "tableColumn": "7",
      "fqn": "matSummary.SignOffPlnPlcBrthOnsetOth"
    },
    {
      "elementKey": "SignOffLbrBrthCmt",
      "formIndex": "6",
      "inputType": "text",
      "tableColumn": "8",
      "fqn": "matSummary.SignOffLbrBrthCmt"
    },
    {
      "elementKey": "SignOffCProvMRP",
      "formIndex": "6",
      "inputType": "text",
      "tableColumn": "9",
      "fqn": "matSummary.SignOffCProvMRP"
    },
    {
      "elementKey": "SignOffCProvDel",
      "formIndex": "6",
      "inputType": "text",
      "tableColumn": "10",
      "fqn": "matSummary.SignOffCProvDel"
    },
    {
      "elementKey": "SignOffPDelRM",
      "formIndex": "6",
      "inputType": "text",
      "tableColumn": "11",
      "fqn": "matSummary.SignOffPDelRM"
    },
    {
      "elementKey": "SignOffPDelFP",
      "formIndex": "6",
      "inputType": "text",
      "tableColumn": "12",
      "fqn": "matSummary.SignOffPDelFP"
    },
    {
      "elementKey": "SignOffPDelOB",
      "formIndex": "6",
      "inputType": "text",
      "tableColumn": "13",
      "fqn": "matSummary.SignOffPDelOB"
    },
    {
      "elementKey": "SignOffPDelNeo",
      "formIndex": "6",
      "inputType": "text",
      "tableColumn": "14",
      "fqn": "matSummary.SignOffPDelNeo"
    },
    {
      "elementKey": "SignOffPDelPRN",
      "formIndex": "6",
      "inputType": "text",
      "tableColumn": "15",
      "fqn": "matSummary.SignOffPDelPRN"
    },
    {
      "elementKey": "SignOffPDelSRN",
      "formIndex": "6",
      "inputType": "text",
      "tableColumn": "16",
      "fqn": "matSummary.SignOffPDelSRN"
    },
    {
      "elementKey": "SignOffPDelOth",
      "formIndex": "6",
      "inputType": "text",
      "tableColumn": "17",
      "fqn": "matSummary.SignOffPDelOth"
    },
    {
      "elementKey": "SignOffCLabour",
      "formIndex": "6",
      "inputType": "checkset",
      "options": [
        {
          "key": "GP",
          "text": "GP"
        },
        {
          "key": "OB",
          "text": "OB"
        },
        {
          "key": "MFM",
          "text": "MFM"
        },
        {
          "key": "pediatrician",
          "text": "Pediatrician"
        },
        {
          "key": "neonatologist",
          "text": "Neonatologist"
        },
        {
          "key": "other",
          "text": "Other"
        }
      ],
      "tableColumn": "18",
      "fqn": "matSummary.SignOffCLabour"
    },
    {
      "elementKey": "SignOffCLabourOth",
      "formIndex": "6",
      "inputType": "text",
      "tableColumn": "19",
      "fqn": "matSummary.SignOffCLabourOth"
    },
    {
      "elementKey": "SignOffComments",
      "formIndex": "6",
      "inputType": "textarea",
      "tableColumn": "21",
      "fqn": "matSummary.SignOffComments"
    }
  ],
  "pageElements": {
    "matSummaryForm": {
      "elementKey": "matSummaryForm",
      "formCss": "form_level_css",
      "pageElementIndex": "1",
      "formKey": "matSummaryForm",
      "fqn": "matSummary.matSummaryForm",
      "isPageForm": true,
      "ehr_groups": [
        {
          "elementKey": "matSummaryHdrGrp",
          "formCss": "record-header",
          "fqn": "matSummary.matSummaryHdrGrp",
          "gIndex": "1",
          "gChildren": [
            "matSummaryForm_name",
            "matSummaryForm_profession",
            "matSummaryForm_day",
            "matSummaryForm_time"
          ]
        },
        {
          "elementKey": "matSummarySnglMultBrths",
          "formCss": "ehr-row-flow",
          "fqn": "matSummary.matSummarySnglMultBrths",
          "gIndex": "2",
          "gChildren": [
            "matSummaryLabel",
            "matSummaryBrthsCnt",
            "matSummaryLvBrths",
            "matSummaryNwbrnAcctNum",
            "matSummaryLvBrthsOth"
          ]
        },
        {
          "elementKey": "matSummaryObstHx",
          "formCss": "ehr-row-flow",
          "fqn": "matSummary.matSummaryObstHx",
          "gIndex": "3",
          "gChildren": [
            "matSummaryGrvd",
            "matSummaryPra",
            "matSummaryTrm",
            "matSummaryPrtrm",
            "matSummaryAbrts",
            "matSummaryLvg",
            "matSummaryPlndDelMode",
            "matSummaryEdd",
            "matSummaryGaDelWk",
            "matSummaryGaDelDay",
            "matSummaryGaDel"
          ]
        },
        {
          "elementKey": "matSummaryVbac",
          "formCss": "ehr-row-flow",
          "fqn": "matSummary.matSummaryVbac",
          "gIndex": "4",
          "gChildren": [
            "matSummaryVbacElig",
            "matSummaryVbacAttmpt",
            "matSummaryVbacNotEligCmt",
            "matSummaryNotAttmptRsn"
          ]
        }
      ]
    },
    "LabourForm": {
      "elementKey": "LabourForm",
      "formCss": "form_level_css",
      "pageElementIndex": "2",
      "formKey": "LabourForm",
      "fqn": "matSummary.LabourForm",
      "isPageForm": true,
      "ehr_groups": [
        {
          "elementKey": "LabourHdrGrp",
          "formCss": "record-header",
          "fqn": "matSummary.LabourHdrGrp",
          "gIndex": "1",
          "gChildren": [
            "LabourForm_name",
            "LabourForm_profession",
            "LabourForm_day",
            "LabourForm_time"
          ]
        },
        {
          "elementKey": "LabourLabour",
          "formCss": "ehr-row-flow",
          "fqn": "matSummary.LabourLabour",
          "gIndex": "2",
          "gChildren": [
            "LabourLabourType",
            "LabourAugMeth",
            "LabourIndMeth",
            "LabourProstagladin"
          ]
        },
        {
          "elementKey": "Labourgrp",
          "formCss": "ehr-row-flow",
          "fqn": "matSummary.Labourgrp",
          "gIndex": "3",
          "gChildren": [
            "LabourCervid",
            "LabourProstin",
            "LabourPrepidil",
            "LabourMOral",
            "LabourMvaginal",
            "LabourOxytocin"
          ]
        },
        {
          "elementKey": "Labourgrp2",
          "formCss": "ehr-row-flow",
          "fqn": "matSummary.Labourgrp2",
          "gIndex": "4",
          "gChildren": [
            "LabourOxyDay1",
            "LabourOxyTime1",
            "LabourOxyDay2",
            "LabourOxyTime2",
            "LabourIndPrimInd",
            "LabourLbrComments"
          ]
        },
        {
          "elementKey": "LabourFtlMonSingle",
          "formCss": "ehr-row-flow",
          "fqn": "matSummary.LabourFtlMonSingle",
          "gIndex": "5",
          "gChildren": [
            "LabourFtlPresLabour",
            "LabourBrchPosLabour",
            "LabourFtlPosLabour",
            "LabourFtlSurveillance",
            "LabourFtlPresLabourOth",
            "LabourFtlPosLabourOth",
            "LabourIndExtEFM",
            "LabourIndIntEFM",
            "LabourFtlScalpBloodCollected",
            "LabourFtlScalpBloodResult",
            "LabourFtlScalpLactateCollected",
            "LabourFtlScalpLactateResult",
            "LabourFtlScalpLactateInterp"
          ]
        },
        {
          "elementKey": "LabourMedLabour",
          "formCss": "ehr-row-flow",
          "fqn": "matSummary.LabourMedLabour",
          "gIndex": "6",
          "gChildren": [
            "LabourAnalgesia",
            "LabourAnesthLabour1",
            "LabourAnesthLabour2",
            "LabourAnesthCS",
            "LabourAbxAdmin",
            "LabourAbxInd",
            "LabourMedInd"
          ]
        }
      ]
    },
    "BirthForm": {
      "elementKey": "BirthForm",
      "pageElementIndex": "3",
      "formKey": "BirthForm",
      "fqn": "matSummary.BirthForm",
      "isPageForm": true,
      "ehr_groups": [
        {
          "elementKey": "BirthHdrGrp",
          "formCss": "record-header",
          "fqn": "matSummary.BirthHdrGrp",
          "gIndex": "1",
          "gChildren": [
            "BirthForm_name",
            "BirthForm_profession",
            "BirthForm_day",
            "BirthForm_time"
          ]
        },
        {
          "elementKey": "BirthBrthDetailsSingle",
          "formCss": "ehr-row-flow",
          "fqn": "matSummary.BirthBrthDetailsSingle",
          "gIndex": "2",
          "gChildren": [
            "BirthFtlPresBirth",
            "BirthBrchPosBirth",
            "BirthFtlPosBirth",
            "BirthAmnFluidBirth",
            "BirthDelType",
            "BirthAssistVagDelMeth",
            "BirthCsType",
            "BirthCsDecisionDate",
            "BirthCsDecisionTime",
            "BirthCervDilationPreCS"
          ]
        },
        {
          "elementKey": "BirthBrthCommentGrp",
          "formCss": "ehr-row-flow",
          "fqn": "matSummary.BirthBrthCommentGrp",
          "gIndex": "3",
          "gChildren": [
            "BirthFtlPosBirthOth",
            "BirthAmnFluidBirthOth",
            "BirthAssistVagDelMethOth",
            "BirthCsTypeOth",
            "BirthCsIndOth",
            "BirthCsPrimInd"
          ]
        },
        {
          "elementKey": "BirthUterotonics",
          "formCss": "ehr-row-flow",
          "fqn": "matSummary.BirthUterotonics",
          "gIndex": "4",
          "gChildren": [
            "BirthUterotonicsGiven",
            "BirthOxyDoseIM",
            "BirthOxyDoseIV",
            "BirthCarbDoseIM",
            "BirthCarbDoseIV",
            "BirthUterotonicsGivenOth"
          ]
        },
        {
          "elementKey": "BirthPlacentaDelSingle",
          "formCss": "ehr-row-flow",
          "fqn": "matSummary.BirthPlacentaDelSingle",
          "gIndex": "5",
          "gChildren": [
            "BirthPlacentaDelMeth",
            "BirthPlacentaComplete",
            "BirthPlacentaPath"
          ]
        },
        {
          "elementKey": "BirthCord",
          "formCss": "ehr-row-flow",
          "fqn": "matSummary.BirthCord",
          "gIndex": "6",
          "gChildren": [
            "BirthUmbVessels",
            "BirthCordBlood",
            "BirthCordClamped",
            "BirthUmbVesselsOth",
            "BirthCordBloodOth",
            "BirthEarlyCordClampRsn"
          ]
        },
        {
          "elementKey": "BirthPerineumVaginaCervix",
          "formCss": "ehr-row-flow",
          "fqn": "matSummary.BirthPerineumVaginaCervix",
          "gIndex": "7",
          "gChildren": [
            "BirthPerineumDesc",
            "BirthRepairBy",
            "BirthSpongeCountCorrect",
            "BirthNeedleCountCorrect",
            "BirthPerineumDescOth"
          ]
        },
        {
          "elementKey": "BirthBloodLoss",
          "formCss": "ehr-row-flow",
          "fqn": "matSummary.BirthBloodLoss",
          "gIndex": "8",
          "gChildren": [
            "BirthQuantBloodLoss",
            "BirthMeasureType",
            "BirthMeasureBloodLoss",
            "BirthBloodLossInterv",
            "BirthBloodLossIntervOth"
          ]
        }
      ]
    },
    "TimeForm": {
      "elementKey": "TimeForm",
      "pageElementIndex": "4",
      "formKey": "TimeForm",
      "fqn": "matSummary.TimeForm",
      "isPageForm": true,
      "ehr_groups": [
        {
          "elementKey": "TimeHdrGrp",
          "formCss": "record-header",
          "fqn": "matSummary.TimeHdrGrp",
          "gIndex": "1",
          "gChildren": [
            "TimeForm_name",
            "TimeForm_profession",
            "TimeForm_day",
            "TimeForm_time"
          ]
        },
        {
          "elementKey": "TimeTimeSummarySingle",
          "formCss": "ehr-row-flow",
          "fqn": "matSummary.TimeTimeSummarySingle",
          "gIndex": "2",
          "gChildren": [
            "TimeMemRuptDate",
            "TimeMemRuptTime",
            "TimeStage1StartDate",
            "TimeStage1StartTime",
            "TimeFullDilationDate",
            "TimeFullDilationTime",
            "TimeStage2ActiveStartDate",
            "TimeStage2ActiveStartTime",
            "TimeNbBirthDate",
            "TimeNbBirthTime",
            "TimePlacentaDelDate",
            "TimePlacentaDelTime",
            "TimeRomTotalTime"
          ]
        },
        {
          "elementKey": "TimeDurationSingle",
          "formCss": "ehr-row-flow",
          "fqn": "matSummary.TimeDurationSingle",
          "gIndex": "3",
          "gChildren": [
            "TimeStage1Dur",
            "TimeStage2DurPassive",
            "TimeStage2DurActive",
            "TimeStage3Dur",
            "TimeTotalDur"
          ]
        }
      ]
    },
    "FetalStForm": {
      "elementKey": "FetalStForm",
      "pageElementIndex": "5",
      "formKey": "FetalStForm",
      "fqn": "matSummary.FetalStForm",
      "isPageForm": true,
      "ehr_groups": [
        {
          "elementKey": "FetalStHdrGrp",
          "formCss": "record-header",
          "fqn": "matSummary.FetalStHdrGrp",
          "gIndex": "1",
          "gChildren": [
            "FetalStForm_name",
            "FetalStForm_profession",
            "FetalStForm_day",
            "FetalStForm_time"
          ]
        },
        {
          "elementKey": "FetalStFtlNbStatusSingle",
          "formCss": "ehr-row-flow",
          "fqn": "matSummary.FetalStFtlNbStatusSingle",
          "gIndex": "2",
          "gChildren": [
            "FetalStBirthSex",
            "FetalStBirthWeight"
          ]
        },
        {
          "elementKey": "FetalStFtlDeathSingle1",
          "formCss": "ehr-row-flow",
          "fqn": "matSummary.FetalStFtlDeathSingle1",
          "gIndex": "3",
          "gChildren": [
            "FetalStFtlDeathSingle2",
            "FetalStFtlDeathDate",
            "FetalStGaDeathWks",
            "FetalStGaDeathDays",
            "FetalStGaDeath"
          ]
        }
      ]
    },
    "SignOffForm": {
      "elementKey": "SignOffForm",
      "pageElementIndex": "6",
      "formKey": "SignOffForm",
      "fqn": "matSummary.SignOffForm",
      "isPageForm": true,
      "ehr_groups": [
        {
          "elementKey": "SignOffHdrGrp",
          "formCss": "record-header",
          "fqn": "matSummary.SignOffHdrGrp",
          "gIndex": "1",
          "gChildren": [
            "SignOffForm_name",
            "SignOffForm_profession",
            "SignOffForm_day",
            "SignOffForm_time"
          ]
        },
        {
          "elementKey": "SignOffDelSingle",
          "formCss": "ehr-row-flow",
          "fqn": "matSummary.SignOffDelSingle",
          "gIndex": "2",
          "gChildren": [
            "SignOffPlcBrth",
            "SignOffPlcBrthOth",
            "SignOffPlnPlcBrthOnset",
            "SignOffPlnPlcBrthOnsetOth",
            "SignOffLbrBrthCmt",
            "SignOffCProvMRP",
            "SignOffCProvDel",
            "SignOffPDelRM",
            "SignOffPDelFP",
            "SignOffPDelOB",
            "SignOffPDelNeo",
            "SignOffPDelPRN",
            "SignOffPDelSRN",
            "SignOffPDelOth",
            "SignOffCLabour",
            "SignOffCLabourOth"
          ]
        },
        {
          "elementKey": "SignOffCommentGrp",
          "formCss": "grid-left-to-right-1",
          "fqn": "matSummary.SignOffCommentGrp",
          "gIndex": "3",
          "gChildren": [
            "SignOffComments"
          ]
        }
      ]
    }
  },
  "path": "/ehr/peri-ped/maternity",
  "routeName": "mat-summary",
  "label": "Labor Birth Summary",
  "generateComponent": "v2page",
  "featureFlag": "ffPed",
  "ehr": "yes",
  "fullPageKey": "periPed.maternity.matSummary"
}
export default DEFS