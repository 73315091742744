/* eslint-disable */
const DEFS = {
  "pageDataKey": "medLabPhoneLog",
  "pIndex": "52",
  "hasGridTable": true,
  "pageChildren": [
    {
      "elementKey": "phLogTable_name",
      "formIndex": "1",
      "inputType": "practitionerName",
      "tableColumn": "1",
      "recHeader": true,
      "fqn": "medLabPhoneLog.name"
    },
    {
      "elementKey": "phLogTable_id",
      "inputType": "generatedId",
      "tableColumn": 0,
      "label": "Row id",
      "tableCss": "row-id"
    },
    {
      "elementKey": "phLogTable_profession",
      "formIndex": "1",
      "inputType": "practitionerProfession",
      "tableColumn": "1",
      "recHeader": true,
      "fqn": "medLabPhoneLog.profession"
    },
    {
      "elementKey": "phLogTable_day",
      "formIndex": "1",
      "inputType": "visitDay",
      "mandatory": true,
      "tableColumn": "1",
      "validation": "visitDay",
      "recHeader": true,
      "fqn": "medLabPhoneLog.day"
    },
    {
      "elementKey": "phLogTable_time",
      "formIndex": "1",
      "inputType": "visitTime",
      "mandatory": true,
      "tableColumn": "1",
      "validation": "time24",
      "recHeader": true,
      "fqn": "medLabPhoneLog.time"
    },
    {
      "elementKey": "testAccessionID",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "2",
      "fqn": "medLabPhoneLog.testAccessionID"
    },
    {
      "elementKey": "mlphRecipient",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "3",
      "fqn": "medLabPhoneLog.mlphRecipient"
    },
    {
      "elementKey": "mlphReadBack",
      "formIndex": "1",
      "inputType": "checkbox",
      "tableColumn": "4",
      "fqn": "medLabPhoneLog.mlphReadBack"
    },
    {
      "elementKey": "mlplReason",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "5",
      "fqn": "medLabPhoneLog.mlplReason"
    },
    {
      "elementKey": "accComments",
      "formIndex": "1",
      "inputType": "textarea",
      "tableColumn": "6",
      "fqn": "medLabPhoneLog.accComments"
    }
  ],
  "pageElements": {
    "phLogTable": {
      "elementKey": "phLogTable",
      "pageElementIndex": "1",
      "pageKey": "medLabPhoneLog",
      "tableKey": "phLogTable",
      "isTable": true,
      "form": {
        "elementKey": "phLogTable",
        "label": "Phone Log",
        "addButtonText": "Create a phone log",
        "formKey": "phLogTable",
        "ehr_groups": [
          {
            "elementKey": "hdrGroup",
            "formCss": "record-header",
            "fqn": "medLabPhoneLog.hdrGroup",
            "gIndex": "1",
            "gChildren": [
              "phLogTable_name",
              "phLogTable_profession",
              "phLogTable_day",
              "phLogTable_time"
            ]
          },
          {
            "elementKey": "cGroup50-2",
            "formCss": "grid-left-to-right-3",
            "fqn": "medLabPhoneLog.cGroup50-2",
            "gIndex": "2",
            "gChildren": [
              "testAccessionID",
              "mlphRecipient",
              "mlphReadBack"
            ]
          },
          {
            "elementKey": "cGroup50-3",
            "formCss": "grid-left-to-right-1",
            "fqn": "medLabPhoneLog.cGroup50-3",
            "gIndex": "3",
            "gChildren": [
              "mlplReason",
              "accComments"
            ]
          }
        ],
        "ehr_data": {
          "phLogTable_name": "",
          "phLogTable_profession": "",
          "phLogTable_day": "",
          "phLogTable_time": "",
          "testAccessionID": "",
          "mlphRecipient": "",
          "mlphReadBack": "",
          "mlplReason": "",
          "accComments": ""
        }
      },
      "fqn": "medLabPhoneLog.phLogTable",
      "tableChildren": [
        "phLogTable_id",
        "phLogTable_day",
        "phLogTable_time",
        "testAccessionID",
        "mlphRecipient",
        "mlphReadBack",
        "mlplReason",
        "accComments",
        "phLogTable_name",
        "phLogTable_profession"
      ],
      "hasRecHeader": true
    }
  },
  "path": "/ehr/med-lab",
  "routeName": "med-lab-phone-log",
  "label": "Phone Log",
  "lis": "yes",
  "fullPageKey": "medLab.medLabPhoneLog"
}
export default DEFS