/* eslint-disable */
const DEFS = {
  "pageDataKey": "matPostpartum",
  "pIndex": "83",
  "hasGridTable": true,
  "pageChildren": [
    {
      "elementKey": "matPostpartumForm_name",
      "formIndex": "1",
      "inputType": "practitionerName",
      "tableColumn": "2",
      "recHeader": true,
      "fqn": "matPostpartum.name"
    },
    {
      "elementKey": "matPostpartumForm_id",
      "inputType": "generatedId",
      "tableColumn": 0,
      "label": "Row id",
      "tableCss": "row-id"
    },
    {
      "elementKey": "matPostpartumForm_profession",
      "formIndex": "1",
      "inputType": "practitionerProfession",
      "tableColumn": "2",
      "recHeader": true,
      "fqn": "matPostpartum.profession"
    },
    {
      "elementKey": "matPostpartumForm_day",
      "formIndex": "1",
      "inputType": "visitDay",
      "mandatory": true,
      "tableColumn": "2",
      "validation": "visitDay",
      "recHeader": true,
      "fqn": "matPostpartum.day"
    },
    {
      "elementKey": "matPostpartumForm_time",
      "formIndex": "1",
      "inputType": "visitTime",
      "mandatory": true,
      "tableColumn": "2",
      "validation": "time24",
      "recHeader": true,
      "fqn": "matPostpartum.time"
    },
    {
      "elementKey": "matPostpartumBrthsCnt",
      "formIndex": "1",
      "inputType": "number",
      "tableColumn": "4",
      "fqn": "matPostpartum.matPostpartumBrthsCnt"
    },
    {
      "elementKey": "matPostpartumFndusTone",
      "formIndex": "1",
      "inputType": "radioset",
      "options": [
        {
          "key": "firm",
          "text": "Firm"
        },
        {
          "key": "firmWithMassage",
          "text": "Firm with massage"
        },
        {
          "key": "boggy",
          "text": "Boggy"
        },
        {
          "key": "other",
          "text": "Other"
        }
      ],
      "tableColumn": "6",
      "fqn": "matPostpartum.matPostpartumFndusTone"
    },
    {
      "elementKey": "matPostpartumFndusToneOth",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "7",
      "fqn": "matPostpartum.matPostpartumFndusToneOth"
    },
    {
      "elementKey": "matPostpartumFndusLoc",
      "formIndex": "1",
      "inputType": "radioset",
      "options": [
        {
          "key": "midline",
          "text": "Midline"
        },
        {
          "key": "deviatedRight",
          "text": "Deviated right"
        },
        {
          "key": "deviatedLeft",
          "text": "Deviated left"
        }
      ],
      "tableColumn": "8",
      "fqn": "matPostpartum.matPostpartumFndusLoc"
    },
    {
      "elementKey": "matPostpartumFndusHt",
      "formIndex": "1",
      "inputType": "radioset",
      "options": [
        {
          "key": "2cmBelow",
          "text": "2cm below umbilicus"
        },
        {
          "key": "1cmBelow",
          "text": "1cm below umbilicus"
        },
        {
          "key": "umbilicus",
          "text": "Umbilicus"
        },
        {
          "key": "1cmAbove",
          "text": "1cm above umbilicus"
        },
        {
          "key": "2cmAbove",
          "text": "2cm above umbilicus"
        },
        {
          "key": "other",
          "text": "Other"
        }
      ],
      "tableColumn": "9",
      "fqn": "matPostpartum.matPostpartumFndusHt"
    },
    {
      "elementKey": "matPostpartumFndusHtOth",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "10",
      "fqn": "matPostpartum.matPostpartumFndusHtOth"
    },
    {
      "elementKey": "matPostpartumLochiaAmt",
      "formIndex": "1",
      "inputType": "radioset",
      "options": [
        {
          "key": "nil",
          "text": "Nil"
        },
        {
          "key": "scant",
          "text": "Scant"
        },
        {
          "key": "small",
          "text": "Small"
        },
        {
          "key": "moderate",
          "text": "Moderate"
        },
        {
          "key": "heavy",
          "text": "Heavy"
        }
      ],
      "tableColumn": "12",
      "fqn": "matPostpartum.matPostpartumLochiaAmt"
    },
    {
      "elementKey": "matPostpartumLochiaDesc",
      "formIndex": "1",
      "inputType": "radioset",
      "options": [
        {
          "key": "rubra",
          "text": "Rubra"
        },
        {
          "key": "serosa",
          "text": "Serosa"
        },
        {
          "key": "alba",
          "text": "Alba"
        },
        {
          "key": "other:Other",
          "text": "other:Other"
        }
      ],
      "tableColumn": "13",
      "fqn": "matPostpartum.matPostpartumLochiaDesc"
    },
    {
      "elementKey": "matPostpartumLochiaDescOth",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "14",
      "fqn": "matPostpartum.matPostpartumLochiaDescOth"
    },
    {
      "elementKey": "matPostpartumClotsPres",
      "formIndex": "1",
      "inputType": "radioset",
      "options": [
        {
          "key": "yes",
          "text": "Yes"
        },
        {
          "key": "no",
          "text": "No"
        },
        {
          "key": "comment",
          "text": "Comment"
        }
      ],
      "tableColumn": "15",
      "fqn": "matPostpartum.matPostpartumClotsPres"
    },
    {
      "elementKey": "matPostpartumClotsPresCmt",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "16",
      "fqn": "matPostpartum.matPostpartumClotsPresCmt"
    },
    {
      "elementKey": "matPostpartumLochiaOdor",
      "formIndex": "1",
      "inputType": "radioset",
      "options": [
        {
          "key": "none",
          "text": "None"
        },
        {
          "key": "foul",
          "text": "Foul"
        },
        {
          "key": "other",
          "text": "Other"
        }
      ],
      "tableColumn": "17",
      "fqn": "matPostpartum.matPostpartumLochiaOdor"
    },
    {
      "elementKey": "matPostpartumLochiaOdorOth",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "18",
      "fqn": "matPostpartum.matPostpartumLochiaOdorOth"
    },
    {
      "elementKey": "matPostpartumPerineumCond",
      "formIndex": "1",
      "inputType": "checkset",
      "options": [
        {
          "key": "noConcerns",
          "text": "Noconcerns"
        },
        {
          "key": "bruising",
          "text": "Bruising"
        },
        {
          "key": "hematoma",
          "text": "Hematoma"
        },
        {
          "key": "redness",
          "text": "Redness"
        },
        {
          "key": "swelling",
          "text": "Swelling"
        },
        {
          "key": "tenderness",
          "text": "Tenderness"
        },
        {
          "key": "other",
          "text": "Other"
        }
      ],
      "tableColumn": "20",
      "fqn": "matPostpartum.matPostpartumPerineumCond"
    },
    {
      "elementKey": "matPostpartumPerineumCondOth",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "21",
      "fqn": "matPostpartum.matPostpartumPerineumCondOth"
    },
    {
      "elementKey": "matPostpartumPerineumComfort",
      "formIndex": "1",
      "inputType": "checkset",
      "options": [
        {
          "key": "icePack",
          "text": "Ice pack"
        },
        {
          "key": "teaPads",
          "text": "Tea pads"
        },
        {
          "key": "sitzBath",
          "text": "Sitz bath"
        },
        {
          "key": "medication",
          "text": "Medication/ointment"
        },
        {
          "key": "other",
          "text": "Other"
        }
      ],
      "tableColumn": "22",
      "fqn": "matPostpartum.matPostpartumPerineumComfort"
    },
    {
      "elementKey": "matPostpartumPerineumComfortOth",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "23",
      "fqn": "matPostpartum.matPostpartumPerineumComfortOth"
    },
    {
      "elementKey": "matPostpartumFndusLochiaPerineumCmt",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "24",
      "fqn": "matPostpartum.matPostpartumFndusLochiaPerineumCmt"
    },
    {
      "elementKey": "matPostpartumDressingType",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "26",
      "fqn": "matPostpartum.matPostpartumDressingType"
    },
    {
      "elementKey": "matPostpartumAbdDressing",
      "formIndex": "1",
      "inputType": "radioset",
      "options": [
        {
          "key": "dry",
          "text": "Dry and intact"
        },
        {
          "key": "reinforced",
          "text": "Reinforced"
        },
        {
          "key": "changed",
          "text": "Changed"
        },
        {
          "key": "removed",
          "text": "Removed"
        },
        {
          "key": "other",
          "text": "Other"
        }
      ],
      "tableColumn": "27",
      "fqn": "matPostpartum.matPostpartumAbdDressing"
    },
    {
      "elementKey": "matPostpartumAbdDressingOth",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "28",
      "fqn": "matPostpartum.matPostpartumAbdDressingOth"
    },
    {
      "elementKey": "matPostpartumDressingSat",
      "formIndex": "1",
      "inputType": "radioset",
      "options": [
        {
          "key": "none",
          "text": "None/scant"
        },
        {
          "key": "25%",
          "text": "25%"
        },
        {
          "key": "50%",
          "text": "50%"
        },
        {
          "key": "75%",
          "text": "75%"
        },
        {
          "key": "100%",
          "text": "100%"
        },
        {
          "key": "other",
          "text": "Other"
        }
      ],
      "tableColumn": "29",
      "fqn": "matPostpartum.matPostpartumDressingSat"
    },
    {
      "elementKey": "matPostpartumDressingSatOth",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "30",
      "fqn": "matPostpartum.matPostpartumDressingSatOth"
    },
    {
      "elementKey": "matPostpartumAbdIncisionDesc",
      "formIndex": "1",
      "inputType": "checkset",
      "options": [
        {
          "key": "notAssessed",
          "text": "Not assessed"
        },
        {
          "key": "approximated",
          "text": "Approximated"
        },
        {
          "key": "oozing",
          "text": "Oozing"
        },
        {
          "key": "cleansed",
          "text": "Cleansed"
        },
        {
          "key": "suturesStaplesRemoved",
          "text": "Sutures/staples removed"
        },
        {
          "key": "other",
          "text": "Other"
        }
      ],
      "tableColumn": "31",
      "fqn": "matPostpartum.matPostpartumAbdIncisionDesc"
    },
    {
      "elementKey": "matPostpartumAbdIncisionDescOth",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "32",
      "fqn": "matPostpartum.matPostpartumAbdIncisionDescOth"
    },
    {
      "elementKey": "matPostpartumAbdIncisionCmt",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "33",
      "fqn": "matPostpartum.matPostpartumAbdIncisionCmt"
    },
    {
      "elementKey": "matPostpartumBrstChestDfdParm",
      "formIndex": "1",
      "inputType": "radioset",
      "options": [
        {
          "key": "wdp",
          "text": "Within defined parameters"
        },
        {
          "key": "significantFindings",
          "text": "Significant findings"
        },
        {
          "key": "na",
          "text": "Not applicable"
        },
        {
          "key": "notAssessed",
          "text": "Not assessed"
        }
      ],
      "tableColumn": "35",
      "fqn": "matPostpartum.matPostpartumBrstChestDfdParm"
    },
    {
      "elementKey": "matPostpartumBrstNippleConcerns",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "37",
      "fqn": "matPostpartum.matPostpartumBrstNippleConcerns"
    },
    {
      "elementKey": "matPostpartumBrstNippleConcernsOth",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "38",
      "fqn": "matPostpartum.matPostpartumBrstNippleConcernsOth"
    },
    {
      "elementKey": "matPostpartumBrstNippleIntervOth",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "39",
      "fqn": "matPostpartum.matPostpartumBrstNippleIntervOth"
    },
    {
      "elementKey": "matPostpartumBrstNippleCmt",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "40",
      "fqn": "matPostpartum.matPostpartumBrstNippleCmt"
    },
    {
      "elementKey": "matPostpartumColostrumPres",
      "formIndex": "1",
      "inputType": "radioset",
      "options": [
        {
          "key": "yes",
          "text": "Yes"
        },
        {
          "key": "no",
          "text": "No"
        },
        {
          "key": "comment",
          "text": "Comment"
        }
      ],
      "tableColumn": "42",
      "fqn": "matPostpartum.matPostpartumColostrumPres"
    },
    {
      "elementKey": "matPostpartumColostrumPresCmt",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "43",
      "fqn": "matPostpartum.matPostpartumColostrumPresCmt"
    },
    {
      "elementKey": "matPostpartumHmMilkPres",
      "formIndex": "1",
      "inputType": "radioset",
      "options": [
        {
          "key": "yes",
          "text": "Yes"
        },
        {
          "key": "no",
          "text": "No"
        },
        {
          "key": "comment",
          "text": "Comment"
        }
      ],
      "tableColumn": "44",
      "fqn": "matPostpartum.matPostpartumHmMilkPres"
    },
    {
      "elementKey": "matPostpartumHmMilkPresCmt",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "45",
      "fqn": "matPostpartum.matPostpartumHmMilkPresCmt"
    },
    {
      "elementKey": "matPostpartumHmMilkExpMeth",
      "formIndex": "1",
      "inputType": "checkset",
      "options": [
        {
          "key": "breast",
          "text": "Breast/chest feeding"
        },
        {
          "key": "hand",
          "text": "Hand expression"
        },
        {
          "key": "pumping",
          "text": "Pumping"
        }
      ],
      "tableColumn": "46",
      "fqn": "matPostpartum.matPostpartumHmMilkExpMeth"
    },
    {
      "elementKey": "matPostpartumHmMilkExpMethCmt",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "47",
      "fqn": "matPostpartum.matPostpartumHmMilkExpMethCmt"
    },
    {
      "elementKey": "matPostpartumVoidNoDiff",
      "formIndex": "1",
      "inputType": "radioset",
      "options": [
        {
          "key": "yes",
          "text": "Yes"
        },
        {
          "key": "no",
          "text": "No"
        },
        {
          "key": "comment",
          "text": "Comment"
        }
      ],
      "tableColumn": "49",
      "fqn": "matPostpartum.matPostpartumVoidNoDiff"
    },
    {
      "elementKey": "matPostpartumVoidNoDiffCmt",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "50",
      "fqn": "matPostpartum.matPostpartumVoidNoDiffCmt"
    },
    {
      "elementKey": "matPostpartumLastBowelMov",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "51",
      "fqn": "matPostpartum.matPostpartumLastBowelMov"
    },
    {
      "elementKey": "matPostpartumFlatus",
      "formIndex": "1",
      "inputType": "radioset",
      "options": [
        {
          "key": "passingFlatus",
          "text": "Passing flatus"
        },
        {
          "key": "other",
          "text": "Other"
        }
      ],
      "tableColumn": "52",
      "fqn": "matPostpartum.matPostpartumFlatus"
    },
    {
      "elementKey": "matPostpartumFlatusOth",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "53",
      "fqn": "matPostpartum.matPostpartumFlatusOth"
    },
    {
      "elementKey": "matPostpartumBowelSoundsPres",
      "formIndex": "1",
      "inputType": "radioset",
      "options": [
        {
          "key": "yes",
          "text": "Yes"
        },
        {
          "key": "no",
          "text": "No"
        }
      ],
      "tableColumn": "54",
      "fqn": "matPostpartum.matPostpartumBowelSoundsPres"
    },
    {
      "elementKey": "matPostpartumUrineBowelFuncCmt",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "55",
      "fqn": "matPostpartum.matPostpartumUrineBowelFuncCmt"
    },
    {
      "elementKey": "matPostpartumUrineCathStatusDesc",
      "formIndex": "1",
      "inputType": "radioset",
      "options": [
        {
          "key": "inserted",
          "text": "Inserted"
        },
        {
          "key": "inStu",
          "text": "In stu"
        },
        {
          "key": "removed",
          "text": "Removed"
        },
        {
          "key": "inAndOut",
          "text": "In and out"
        }
      ],
      "tableColumn": "57",
      "fqn": "matPostpartum.matPostpartumUrineCathStatusDesc"
    },
    {
      "elementKey": "matPostpartumUrineCathInsertDate",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "58",
      "fqn": "matPostpartum.matPostpartumUrineCathInsertDate"
    },
    {
      "elementKey": "matPostpartumUrineCathInsertTime",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "59",
      "fqn": "matPostpartum.matPostpartumUrineCathInsertTime"
    },
    {
      "elementKey": "matPostpartumUrineCathRemoveDate",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "60",
      "fqn": "matPostpartum.matPostpartumUrineCathRemoveDate"
    },
    {
      "elementKey": "matPostpartumUrineCathRemoveTime",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "61",
      "fqn": "matPostpartum.matPostpartumUrineCathRemoveTime"
    },
    {
      "elementKey": "matPostpartumInOutAmt",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "62",
      "fqn": "matPostpartum.matPostpartumInOutAmt"
    },
    {
      "elementKey": "matPostpartumUrineCathStatusCmt",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "63",
      "fqn": "matPostpartum.matPostpartumUrineCathStatusCmt"
    },
    {
      "elementKey": "matPostpartumVoidPostCathRemove",
      "formIndex": "1",
      "inputType": "radioset",
      "options": [
        {
          "key": "yes",
          "text": "Yes"
        },
        {
          "key": "no",
          "text": "No"
        }
      ],
      "tableColumn": "65",
      "fqn": "matPostpartum.matPostpartumVoidPostCathRemove"
    },
    {
      "elementKey": "matPostpartumVoidPostCathDate",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "66",
      "fqn": "matPostpartum.matPostpartumVoidPostCathDate"
    },
    {
      "elementKey": "matPostpartumVoidPostCathTime",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "67",
      "fqn": "matPostpartum.matPostpartumVoidPostCathTime"
    },
    {
      "elementKey": "matPostpartumVoidPostCathAmt",
      "formIndex": "1",
      "inputType": "number",
      "suffix": "mL",
      "tableColumn": "68",
      "fqn": "matPostpartum.matPostpartumVoidPostCathAmt",
      "suffixText": "mL"
    },
    {
      "elementKey": "matPostpartumPostCathVoidCmt",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "69",
      "fqn": "matPostpartum.matPostpartumPostCathVoidCmt"
    },
    {
      "elementKey": "matPostpartumRetSigns",
      "formIndex": "1",
      "inputType": "checkset",
      "options": [
        {
          "key": "none",
          "text": "None"
        },
        {
          "key": "increasedBloodPressure",
          "text": "Increased blood pressure"
        },
        {
          "key": "change",
          "text": "Change in voiding pattern"
        },
        {
          "key": "unableToVoid",
          "text": "Unable to void on sensing"
        },
        {
          "key": "palpableBladder",
          "text": "Palpable bladder"
        },
        {
          "key": "dullSound",
          "text": "Dull sound on percussion"
        },
        {
          "key": "restlessness",
          "text": "Restlessness or agitation"
        },
        {
          "key": "diaphoresis",
          "text": "Diaphoresis"
        },
        {
          "key": "frequent",
          "text": "Frequent small voids"
        },
        {
          "key": "lowerAbdominalPain",
          "text": "Lower abdominal pain"
        },
        {
          "key": "other",
          "text": "Other"
        }
      ],
      "tableColumn": "71",
      "fqn": "matPostpartum.matPostpartumRetSigns"
    },
    {
      "elementKey": "matPostpartumRetSignsOth",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "72",
      "fqn": "matPostpartum.matPostpartumRetSignsOth"
    },
    {
      "elementKey": "matPostpartumBladderScanVol",
      "formIndex": "1",
      "inputType": "number",
      "suffix": "mL",
      "tableColumn": "73",
      "fqn": "matPostpartum.matPostpartumBladderScanVol",
      "suffixText": "mL"
    },
    {
      "elementKey": "matPostpartumBladderScanCmt",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "74",
      "fqn": "matPostpartum.matPostpartumBladderScanCmt"
    },
    {
      "elementKey": "matPostpartumPostVoidResVol",
      "formIndex": "1",
      "inputType": "number",
      "suffix": "mL",
      "tableColumn": "75",
      "fqn": "matPostpartum.matPostpartumPostVoidResVol",
      "suffixText": "mL"
    },
    {
      "elementKey": "matPostpartumHrsPostAmbulate",
      "formIndex": "1",
      "inputType": "number",
      "suffix": "hrs",
      "tableColumn": "77",
      "fqn": "matPostpartum.matPostpartumHrsPostAmbulate",
      "suffixText": "hrs"
    },
    {
      "elementKey": "matPostpartumAmbulateConcerns",
      "formIndex": "1",
      "inputType": "radioset",
      "options": [
        {
          "key": "yes",
          "text": "Yes"
        },
        {
          "key": "no",
          "text": "No"
        },
        {
          "key": "comment",
          "text": "Comment"
        }
      ],
      "tableColumn": "78",
      "fqn": "matPostpartum.matPostpartumAmbulateConcerns"
    },
    {
      "elementKey": "matPostpartumAmbulateConcernsCmt",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "79",
      "fqn": "matPostpartum.matPostpartumAmbulateConcernsCmt"
    },
    {
      "elementKey": "matPostpartumPersCareDesc",
      "formIndex": "1",
      "inputType": "radioset",
      "options": [
        {
          "key": "showered",
          "text": "Showered"
        },
        {
          "key": "pericareInBathroom",
          "text": "Pericare in bathroom"
        },
        {
          "key": "pericareAtBedside",
          "text": "Pericare at bedside"
        },
        {
          "key": "other",
          "text": "Other"
        }
      ],
      "tableColumn": "80",
      "fqn": "matPostpartum.matPostpartumPersCareDesc"
    },
    {
      "elementKey": "matPostpartumPersCareDescOth",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "81",
      "fqn": "matPostpartum.matPostpartumPersCareDescOth"
    },
    {
      "elementKey": "matPostpartumFluidNutrAdeq",
      "formIndex": "1",
      "inputType": "radioset",
      "options": [
        {
          "key": "yes",
          "text": "Yes"
        },
        {
          "key": "no",
          "text": "No"
        },
        {
          "key": "comment",
          "text": "Comment"
        }
      ],
      "tableColumn": "83",
      "fqn": "matPostpartum.matPostpartumFluidNutrAdeq"
    },
    {
      "elementKey": "matPostpartumFluidNutrAdeqCmt",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "84",
      "fqn": "matPostpartum.matPostpartumFluidNutrAdeqCmt"
    },
    {
      "elementKey": "matPostpartumPtSleepAdeq",
      "formIndex": "1",
      "inputType": "radioset",
      "options": [
        {
          "key": "yes",
          "text": "Yes"
        },
        {
          "key": "no",
          "text": "No"
        },
        {
          "key": "comment",
          "text": "Comment"
        }
      ],
      "tableColumn": "86",
      "fqn": "matPostpartum.matPostpartumPtSleepAdeq"
    },
    {
      "elementKey": "matPostpartumPtSleepAdeqCmt",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "87",
      "fqn": "matPostpartum.matPostpartumPtSleepAdeqCmt"
    },
    {
      "elementKey": "matPostpartumRestWhenBabySleeps",
      "formIndex": "1",
      "inputType": "radioset",
      "options": [
        {
          "key": "yes",
          "text": "Yes"
        },
        {
          "key": "no",
          "text": "No"
        },
        {
          "key": "comment",
          "text": "Comment"
        }
      ],
      "tableColumn": "88",
      "fqn": "matPostpartum.matPostpartumRestWhenBabySleeps"
    },
    {
      "elementKey": "matPostpartumRestWhenBabySleepsCmt",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "89",
      "fqn": "matPostpartum.matPostpartumRestWhenBabySleepsCmt"
    },
    {
      "elementKey": "matPostpartumPersCareOralNutSleep",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "91",
      "fqn": "matPostpartum.matPostpartumPersCareOralNutSleep"
    },
    {
      "elementKey": "matPostpartumPpdRiskFactors",
      "formIndex": "1",
      "inputType": "checkset",
      "options": [
        {
          "key": "none",
          "text": "None"
        },
        {
          "key": "adverseLifeEvents",
          "text": "Adverse life events"
        },
        {
          "key": "anxiety",
          "text": "Anxiety"
        },
        {
          "key": "deliveryComplications",
          "text": "Delivery complications"
        },
        {
          "key": "familyConflict",
          "text": "Family conflict"
        },
        {
          "key": "familyHxOfDepression",
          "text": "Family hx of depression"
        },
        {
          "key": "financialStress",
          "text": "Financial stress"
        },
        {
          "key": "hxOfDepression",
          "text": "Hx of depression"
        },
        {
          "key": "hxPostpartumDepression",
          "text": "Hx postpartum depression"
        },
        {
          "key": "intimatePartnerViolence",
          "text": "Intimate partner violence"
        },
        {
          "key": "medicalChallenges",
          "text": "Medical challenges"
        },
        {
          "key": "poorSocialSupport",
          "text": "Poor social support"
        },
        {
          "key": "unintendedPregnancy",
          "text": "Unintended pregnancy"
        },
        {
          "key": "other",
          "text": "Other"
        }
      ],
      "tableColumn": "93",
      "fqn": "matPostpartum.matPostpartumPpdRiskFactors"
    },
    {
      "elementKey": "matPostpartumPpdRiskFactorsOth",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "94",
      "fqn": "matPostpartum.matPostpartumPpdRiskFactorsOth"
    },
    {
      "elementKey": "matPostpartumAffect",
      "formIndex": "1",
      "inputType": "checkset",
      "options": [
        {
          "key": "appropriate",
          "text": "Appropriate"
        },
        {
          "key": "anxious",
          "text": "Anxious"
        },
        {
          "key": "sad",
          "text": "Sad"
        },
        {
          "key": "fearful",
          "text": "Fearful"
        },
        {
          "key": "blunted",
          "text": "Blunted"
        },
        {
          "key": "flat",
          "text": "Flat"
        },
        {
          "key": "restricted",
          "text": "Restricted"
        },
        {
          "key": "elated",
          "text": "Elated"
        },
        {
          "key": "labile",
          "text": "Labile"
        },
        {
          "key": "animate",
          "text": "Animated"
        },
        {
          "key": "tense",
          "text": "Tense"
        },
        {
          "key": "angry",
          "text": "Angry"
        },
        {
          "key": "other",
          "text": "Other"
        }
      ],
      "tableColumn": "95",
      "fqn": "matPostpartum.matPostpartumAffect"
    },
    {
      "elementKey": "matPostpartumAffectOth",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "96",
      "fqn": "matPostpartum.matPostpartumAffectOth"
    },
    {
      "elementKey": "matPostpartumMood",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "97",
      "fqn": "matPostpartum.matPostpartumMood"
    },
    {
      "elementKey": "matPostpartumPsychosocialCmt",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "98",
      "fqn": "matPostpartum.matPostpartumPsychosocialCmt"
    },
    {
      "elementKey": "matPostpartumMatSupportsPres",
      "formIndex": "1",
      "inputType": "radioset",
      "options": [
        {
          "key": "yes",
          "text": "Yes"
        },
        {
          "key": "no",
          "text": "No"
        },
        {
          "key": "comment",
          "text": "Comment"
        }
      ],
      "tableColumn": "100",
      "fqn": "matPostpartum.matPostpartumMatSupportsPres"
    },
    {
      "elementKey": "matPostpartumMatSupportsPresCmt",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "101",
      "fqn": "matPostpartum.matPostpartumMatSupportsPresCmt"
    },
    {
      "elementKey": "matPostpartumFamCopingSkills",
      "formIndex": "1",
      "inputType": "radioset",
      "options": [
        {
          "key": "yes",
          "text": "Yes"
        },
        {
          "key": "no",
          "text": "No"
        }
      ],
      "tableColumn": "102",
      "fqn": "matPostpartum.matPostpartumFamCopingSkills"
    },
    {
      "elementKey": "matPostpartumFamFunctioning",
      "formIndex": "1",
      "inputType": "radioset",
      "options": [
        {
          "key": "yes",
          "text": "Yes"
        },
        {
          "key": "no",
          "text": "No"
        }
      ],
      "tableColumn": "103",
      "fqn": "matPostpartum.matPostpartumFamFunctioning"
    },
    {
      "elementKey": "matPostpartumFamFunctioningCmt",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "104",
      "fqn": "matPostpartum.matPostpartumFamFunctioningCmt"
    },
    {
      "elementKey": "matPostpartumType",
      "formIndex": "1",
      "inputType": "radioset",
      "options": [
        {
          "key": "yes",
          "text": "Yes"
        },
        {
          "key": "no",
          "text": "No"
        }
      ],
      "tableColumn": "106",
      "fqn": "matPostpartum.matPostpartumType"
    },
    {
      "elementKey": "matPostpartumBondAttachConcernsCmt",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "107",
      "fqn": "matPostpartum.matPostpartumBondAttachConcernsCmt"
    },
    {
      "elementKey": "matPostpartumSkinToSkin",
      "formIndex": "1",
      "inputType": "radioset",
      "options": [
        {
          "key": "yes",
          "text": "Yes"
        },
        {
          "key": "no",
          "text": "No"
        }
      ],
      "tableColumn": "108",
      "fqn": "matPostpartum.matPostpartumSkinToSkin"
    },
    {
      "elementKey": "matPostpartumFeedBehaveCues",
      "formIndex": "1",
      "inputType": "radioset",
      "options": [
        {
          "key": "yes",
          "text": "Yes"
        },
        {
          "key": "no",
          "text": "No"
        }
      ],
      "tableColumn": "109",
      "fqn": "matPostpartum.matPostpartumFeedBehaveCues"
    },
    {
      "elementKey": "matPostpartumFeedBehaveCuesCmt",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "110",
      "fqn": "matPostpartum.matPostpartumFeedBehaveCuesCmt"
    },
    {
      "elementKey": "matPostpartumNicUseHist",
      "formIndex": "1",
      "inputType": "radioset",
      "options": [
        {
          "key": "none",
          "text": "None"
        },
        {
          "key": "currentUse",
          "text": "Current use"
        },
        {
          "key": "quitLessThan30",
          "text": "Quit less than 30 days"
        },
        {
          "key": "quitGreaterThan30Days",
          "text": "Quit greater than 30 days"
        },
        {
          "key": "notAsked",
          "text": "Not asked"
        },
        {
          "key": "unableToCollect",
          "text": "Unable to collect"
        }
      ],
      "tableColumn": "112",
      "fqn": "matPostpartum.matPostpartumNicUseHist"
    },
    {
      "elementKey": "matPostpartumNicUseHistCmt",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "113",
      "fqn": "matPostpartum.matPostpartumNicUseHistCmt"
    },
    {
      "elementKey": "matPostpartumNicTypeUse",
      "formIndex": "1",
      "inputType": "checkset",
      "options": [
        {
          "key": "cigarettes",
          "text": "Cigarettes"
        },
        {
          "key": "cigarsCigarillosPipe",
          "text": "Cigars/cigarillos/pipe"
        },
        {
          "key": "smokeless",
          "text": "Smokeless"
        },
        {
          "key": "e-cigarettes",
          "text": "E-cigarettes"
        },
        {
          "key": "hookah",
          "text": "Hookah/bidis/kreteks"
        },
        {
          "key": "other",
          "text": "Other"
        }
      ],
      "tableColumn": "114",
      "fqn": "matPostpartum.matPostpartumNicTypeUse"
    },
    {
      "elementKey": "matPostpartumNicTypeUseOth",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "115",
      "fqn": "matPostpartum.matPostpartumNicTypeUseOth"
    },
    {
      "elementKey": "matPostpartumAlcSubUseHist",
      "formIndex": "1",
      "inputType": "checkset",
      "options": [
        {
          "key": "none",
          "text": "None"
        },
        {
          "key": "alcohol",
          "text": "Alcohol"
        },
        {
          "key": "cannabis",
          "text": "Cannabis"
        },
        {
          "key": "otherSubstances",
          "text": "Other substances"
        },
        {
          "key": "prescriptionMedication",
          "text": "Prescription medication"
        },
        {
          "key": "other",
          "text": "Other"
        }
      ],
      "tableColumn": "116",
      "fqn": "matPostpartum.matPostpartumAlcSubUseHist"
    },
    {
      "elementKey": "matPostpartumAlcSubUseHistOth",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "117",
      "fqn": "matPostpartum.matPostpartumAlcSubUseHistOth"
    },
    {
      "elementKey": "matPostpartumAlcSubUseDetails",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "118",
      "fqn": "matPostpartum.matPostpartumAlcSubUseDetails"
    },
    {
      "elementKey": "matPostpartumNicSubIntervType",
      "formIndex": "1",
      "inputType": "checkset",
      "options": [
        {
          "key": "ciwa",
          "text": "CIWA-Ar"
        },
        {
          "key": "cows",
          "text": "COWS"
        },
        {
          "key": "mhsu",
          "text": "MHSU"
        },
        {
          "key": "family",
          "text": "Family education"
        },
        {
          "key": "nrt",
          "text": "Nicotine replacement therapy"
        },
        {
          "key": "referral",
          "text": "Seamless Perinatal referral"
        }
      ],
      "tableColumn": "120",
      "fqn": "matPostpartum.matPostpartumNicSubIntervType"
    },
    {
      "elementKey": "matPostpartumNicSubIntervOth",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "121",
      "fqn": "matPostpartum.matPostpartumNicSubIntervOth"
    },
    {
      "elementKey": "matPostpartumNicSubIntervCmt",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "122",
      "fqn": "matPostpartum.matPostpartumNicSubIntervCmt"
    },
    {
      "elementKey": "matPostpartumComments",
      "formIndex": "1",
      "inputType": "textarea",
      "tableColumn": "124",
      "fqn": "matPostpartum.matPostpartumComments"
    }
  ],
  "pageElements": {
    "matPostpartumForm": {
      "elementKey": "matPostpartumForm",
      "pageElementIndex": "1",
      "pageKey": "matPostpartum",
      "tableKey": "matPostpartumForm",
      "isTable": true,
      "form": {
        "elementKey": "matPostpartumForm",
        "label": "Postpartum Assessment",
        "addButtonText": "Add a postpartum assessment",
        "formKey": "matPostpartumForm",
        "ehr_groups": [
          {
            "elementKey": "matPostpartumHdrGrp",
            "formCss": "record-header",
            "fqn": "matPostpartum.matPostpartumHdrGrp",
            "gIndex": "1",
            "gChildren": [
              "matPostpartumForm_name",
              "matPostpartumForm_profession",
              "matPostpartumForm_day",
              "matPostpartumForm_time"
            ]
          },
          {
            "elementKey": "matPostpartumSnglMultBrths",
            "formCss": "ehr-row-flow",
            "fqn": "matPostpartum.matPostpartumSnglMultBrths",
            "gIndex": "2",
            "gChildren": [
              "matPostpartumBrthsCnt"
            ]
          },
          {
            "elementKey": "matPostpartumFndus",
            "formCss": "ehr-row-flow",
            "fqn": "matPostpartum.matPostpartumFndus",
            "gIndex": "3",
            "gChildren": [
              "matPostpartumFndusTone",
              "matPostpartumFndusToneOth",
              "matPostpartumFndusLoc",
              "matPostpartumFndusHt",
              "matPostpartumFndusHtOth"
            ]
          },
          {
            "elementKey": "matPostpartumLochia",
            "formCss": "ehr-row-flow",
            "fqn": "matPostpartum.matPostpartumLochia",
            "gIndex": "4",
            "gChildren": [
              "matPostpartumLochiaAmt",
              "matPostpartumLochiaDesc",
              "matPostpartumLochiaDescOth",
              "matPostpartumClotsPres",
              "matPostpartumClotsPresCmt",
              "matPostpartumLochiaOdor",
              "matPostpartumLochiaOdorOth"
            ]
          },
          {
            "elementKey": "matPostpartumPerineum",
            "formCss": "ehr-row-flow",
            "fqn": "matPostpartum.matPostpartumPerineum",
            "gIndex": "5",
            "gChildren": [
              "matPostpartumPerineumCond",
              "matPostpartumPerineumCondOth",
              "matPostpartumPerineumComfort",
              "matPostpartumPerineumComfortOth",
              "matPostpartumFndusLochiaPerineumCmt"
            ]
          },
          {
            "elementKey": "matPostpartumAbdIncision",
            "formCss": "ehr-row-flow",
            "fqn": "matPostpartum.matPostpartumAbdIncision",
            "gIndex": "6",
            "gChildren": [
              "matPostpartumDressingType",
              "matPostpartumAbdDressing",
              "matPostpartumAbdDressingOth",
              "matPostpartumDressingSat",
              "matPostpartumDressingSatOth",
              "matPostpartumAbdIncisionDesc",
              "matPostpartumAbdIncisionDescOth",
              "matPostpartumAbdIncisionCmt"
            ]
          },
          {
            "elementKey": "matPostpartumBrstChestParams",
            "formCss": "ehr-row-flow",
            "fqn": "matPostpartum.matPostpartumBrstChestParams",
            "gIndex": "7",
            "gChildren": [
              "matPostpartumBrstChestDfdParm"
            ]
          },
          {
            "elementKey": "matPostpartumBrstChestObs",
            "formCss": "ehr-row-flow",
            "fqn": "matPostpartum.matPostpartumBrstChestObs",
            "gIndex": "8",
            "gChildren": [
              "matPostpartumBrstNippleConcerns",
              "matPostpartumBrstNippleConcernsOth",
              "matPostpartumBrstNippleIntervOth",
              "matPostpartumBrstNippleCmt"
            ]
          },
          {
            "elementKey": "matPostpartumBrstMilk",
            "formCss": "ehr-row-flow",
            "fqn": "matPostpartum.matPostpartumBrstMilk",
            "gIndex": "9",
            "gChildren": [
              "matPostpartumColostrumPres",
              "matPostpartumColostrumPresCmt",
              "matPostpartumHmMilkPres",
              "matPostpartumHmMilkPresCmt",
              "matPostpartumHmMilkExpMeth",
              "matPostpartumHmMilkExpMethCmt"
            ]
          },
          {
            "elementKey": "matPostpartumUrineBowelFunc",
            "formCss": "ehr-row-flow",
            "fqn": "matPostpartum.matPostpartumUrineBowelFunc",
            "gIndex": "10",
            "gChildren": [
              "matPostpartumVoidNoDiff",
              "matPostpartumVoidNoDiffCmt",
              "matPostpartumLastBowelMov",
              "matPostpartumFlatus",
              "matPostpartumFlatusOth",
              "matPostpartumBowelSoundsPres",
              "matPostpartumUrineBowelFuncCmt"
            ]
          },
          {
            "elementKey": "matPostpartumUrineCathStatus",
            "formCss": "ehr-row-flow",
            "fqn": "matPostpartum.matPostpartumUrineCathStatus",
            "gIndex": "11",
            "gChildren": [
              "matPostpartumUrineCathStatusDesc",
              "matPostpartumUrineCathInsertDate",
              "matPostpartumUrineCathInsertTime",
              "matPostpartumUrineCathRemoveDate",
              "matPostpartumUrineCathRemoveTime",
              "matPostpartumInOutAmt",
              "matPostpartumUrineCathStatusCmt"
            ]
          },
          {
            "elementKey": "matPostpartumPostCathRemove",
            "formCss": "ehr-row-flow",
            "fqn": "matPostpartum.matPostpartumPostCathRemove",
            "gIndex": "12",
            "gChildren": [
              "matPostpartumVoidPostCathRemove",
              "matPostpartumVoidPostCathDate",
              "matPostpartumVoidPostCathTime",
              "matPostpartumVoidPostCathAmt",
              "matPostpartumPostCathVoidCmt"
            ]
          },
          {
            "elementKey": "matPostpartumUrineRetAssess",
            "formCss": "ehr-row-flow",
            "fqn": "matPostpartum.matPostpartumUrineRetAssess",
            "gIndex": "13",
            "gChildren": [
              "matPostpartumRetSigns",
              "matPostpartumRetSignsOth",
              "matPostpartumBladderScanVol",
              "matPostpartumBladderScanCmt",
              "matPostpartumPostVoidResVol"
            ]
          },
          {
            "elementKey": "matPostpartumPersCare",
            "formCss": "ehr-row-flow",
            "fqn": "matPostpartum.matPostpartumPersCare",
            "gIndex": "14",
            "gChildren": [
              "matPostpartumHrsPostAmbulate",
              "matPostpartumAmbulateConcerns",
              "matPostpartumAmbulateConcernsCmt",
              "matPostpartumPersCareDesc",
              "matPostpartumPersCareDescOth"
            ]
          },
          {
            "elementKey": "matPostpartumOralNutrition",
            "formCss": "ehr-row-flow",
            "fqn": "matPostpartum.matPostpartumOralNutrition",
            "gIndex": "15",
            "gChildren": [
              "matPostpartumFluidNutrAdeq",
              "matPostpartumFluidNutrAdeqCmt"
            ]
          },
          {
            "elementKey": "matPostpartumSleepRest",
            "formCss": "ehr-row-flow",
            "fqn": "matPostpartum.matPostpartumSleepRest",
            "gIndex": "16",
            "gChildren": [
              "matPostpartumPtSleepAdeq",
              "matPostpartumPtSleepAdeqCmt",
              "matPostpartumRestWhenBabySleeps",
              "matPostpartumRestWhenBabySleepsCmt"
            ]
          },
          {
            "elementKey": "matPostpartumCmt",
            "formCss": "ehr-row-flow",
            "fqn": "matPostpartum.matPostpartumCmt",
            "gIndex": "17",
            "gChildren": [
              "matPostpartumPersCareOralNutSleep"
            ]
          },
          {
            "elementKey": "matPostpartumPsychosocial",
            "formCss": "ehr-row-flow",
            "fqn": "matPostpartum.matPostpartumPsychosocial",
            "gIndex": "18",
            "gChildren": [
              "matPostpartumPpdRiskFactors",
              "matPostpartumPpdRiskFactorsOth",
              "matPostpartumAffect",
              "matPostpartumAffectOth",
              "matPostpartumMood",
              "matPostpartumPsychosocialCmt"
            ]
          },
          {
            "elementKey": "matPostpartumFamFuncSafety",
            "formCss": "ehr-row-flow",
            "fqn": "matPostpartum.matPostpartumFamFuncSafety",
            "gIndex": "19",
            "gChildren": [
              "matPostpartumMatSupportsPres",
              "matPostpartumMatSupportsPresCmt",
              "matPostpartumFamCopingSkills",
              "matPostpartumFamFunctioning",
              "matPostpartumFamFunctioningCmt"
            ]
          },
          {
            "elementKey": "matPostpartumBondAttach",
            "formCss": "ehr-row-flow",
            "fqn": "matPostpartum.matPostpartumBondAttach",
            "gIndex": "20",
            "gChildren": [
              "matPostpartumType",
              "matPostpartumBondAttachConcernsCmt",
              "matPostpartumSkinToSkin",
              "matPostpartumFeedBehaveCues",
              "matPostpartumFeedBehaveCuesCmt"
            ]
          },
          {
            "elementKey": "matPostpartumNicSubstance",
            "formCss": "ehr-row-flow",
            "fqn": "matPostpartum.matPostpartumNicSubstance",
            "gIndex": "21",
            "gChildren": [
              "matPostpartumNicUseHist",
              "matPostpartumNicUseHistCmt",
              "matPostpartumNicTypeUse",
              "matPostpartumNicTypeUseOth",
              "matPostpartumAlcSubUseHist",
              "matPostpartumAlcSubUseHistOth",
              "matPostpartumAlcSubUseDetails"
            ]
          },
          {
            "elementKey": "matPostpartumNicSubInterv",
            "formCss": "ehr-row-flow",
            "fqn": "matPostpartum.matPostpartumNicSubInterv",
            "gIndex": "22",
            "gChildren": [
              "matPostpartumNicSubIntervType",
              "matPostpartumNicSubIntervOth",
              "matPostpartumNicSubIntervCmt"
            ]
          },
          {
            "elementKey": "matPostpartumCGrp",
            "formCss": "ehr-row-flow",
            "fqn": "matPostpartum.matPostpartumCGrp",
            "gIndex": "23",
            "gChildren": [
              "matPostpartumComments"
            ]
          }
        ],
        "ehr_data": {
          "matPostpartumForm_name": "",
          "matPostpartumForm_profession": "",
          "matPostpartumForm_day": "",
          "matPostpartumForm_time": "",
          "matPostpartumBrthsCnt": "",
          "matPostpartumFndusTone": "",
          "matPostpartumFndusToneOth": "",
          "matPostpartumFndusLoc": "",
          "matPostpartumFndusHt": "",
          "matPostpartumFndusHtOth": "",
          "matPostpartumLochiaAmt": "",
          "matPostpartumLochiaDesc": "",
          "matPostpartumLochiaDescOth": "",
          "matPostpartumClotsPres": "",
          "matPostpartumClotsPresCmt": "",
          "matPostpartumLochiaOdor": "",
          "matPostpartumLochiaOdorOth": "",
          "matPostpartumPerineumCond": "",
          "matPostpartumPerineumCondOth": "",
          "matPostpartumPerineumComfort": "",
          "matPostpartumPerineumComfortOth": "",
          "matPostpartumFndusLochiaPerineumCmt": "",
          "matPostpartumDressingType": "",
          "matPostpartumAbdDressing": "",
          "matPostpartumAbdDressingOth": "",
          "matPostpartumDressingSat": "",
          "matPostpartumDressingSatOth": "",
          "matPostpartumAbdIncisionDesc": "",
          "matPostpartumAbdIncisionDescOth": "",
          "matPostpartumAbdIncisionCmt": "",
          "matPostpartumBrstChestDfdParm": "",
          "matPostpartumBrstNippleConcerns": "",
          "matPostpartumBrstNippleConcernsOth": "",
          "matPostpartumBrstNippleIntervOth": "",
          "matPostpartumBrstNippleCmt": "",
          "matPostpartumColostrumPres": "",
          "matPostpartumColostrumPresCmt": "",
          "matPostpartumHmMilkPres": "",
          "matPostpartumHmMilkPresCmt": "",
          "matPostpartumHmMilkExpMeth": "",
          "matPostpartumHmMilkExpMethCmt": "",
          "matPostpartumVoidNoDiff": "",
          "matPostpartumVoidNoDiffCmt": "",
          "matPostpartumLastBowelMov": "",
          "matPostpartumFlatus": "",
          "matPostpartumFlatusOth": "",
          "matPostpartumBowelSoundsPres": "",
          "matPostpartumUrineBowelFuncCmt": "",
          "matPostpartumUrineCathStatusDesc": "",
          "matPostpartumUrineCathInsertDate": "",
          "matPostpartumUrineCathInsertTime": "",
          "matPostpartumUrineCathRemoveDate": "",
          "matPostpartumUrineCathRemoveTime": "",
          "matPostpartumInOutAmt": "",
          "matPostpartumUrineCathStatusCmt": "",
          "matPostpartumVoidPostCathRemove": "",
          "matPostpartumVoidPostCathDate": "",
          "matPostpartumVoidPostCathTime": "",
          "matPostpartumVoidPostCathAmt": "",
          "matPostpartumPostCathVoidCmt": "",
          "matPostpartumRetSigns": "",
          "matPostpartumRetSignsOth": "",
          "matPostpartumBladderScanVol": "",
          "matPostpartumBladderScanCmt": "",
          "matPostpartumPostVoidResVol": "",
          "matPostpartumHrsPostAmbulate": "",
          "matPostpartumAmbulateConcerns": "",
          "matPostpartumAmbulateConcernsCmt": "",
          "matPostpartumPersCareDesc": "",
          "matPostpartumPersCareDescOth": "",
          "matPostpartumFluidNutrAdeq": "",
          "matPostpartumFluidNutrAdeqCmt": "",
          "matPostpartumPtSleepAdeq": "",
          "matPostpartumPtSleepAdeqCmt": "",
          "matPostpartumRestWhenBabySleeps": "",
          "matPostpartumRestWhenBabySleepsCmt": "",
          "matPostpartumPersCareOralNutSleep": "",
          "matPostpartumPpdRiskFactors": "",
          "matPostpartumPpdRiskFactorsOth": "",
          "matPostpartumAffect": "",
          "matPostpartumAffectOth": "",
          "matPostpartumMood": "",
          "matPostpartumPsychosocialCmt": "",
          "matPostpartumMatSupportsPres": "",
          "matPostpartumMatSupportsPresCmt": "",
          "matPostpartumFamCopingSkills": "",
          "matPostpartumFamFunctioning": "",
          "matPostpartumFamFunctioningCmt": "",
          "matPostpartumType": "",
          "matPostpartumBondAttachConcernsCmt": "",
          "matPostpartumSkinToSkin": "",
          "matPostpartumFeedBehaveCues": "",
          "matPostpartumFeedBehaveCuesCmt": "",
          "matPostpartumNicUseHist": "",
          "matPostpartumNicUseHistCmt": "",
          "matPostpartumNicTypeUse": "",
          "matPostpartumNicTypeUseOth": "",
          "matPostpartumAlcSubUseHist": "",
          "matPostpartumAlcSubUseHistOth": "",
          "matPostpartumAlcSubUseDetails": "",
          "matPostpartumNicSubIntervType": "",
          "matPostpartumNicSubIntervOth": "",
          "matPostpartumNicSubIntervCmt": "",
          "matPostpartumComments": ""
        }
      },
      "fqn": "matPostpartum.matPostpartumForm",
      "tableChildren": [
        "matPostpartumForm_id",
        "matPostpartumForm_day",
        "matPostpartumForm_time",
        "matPostpartumBrthsCnt",
        "matPostpartumFndusTone",
        "matPostpartumFndusToneOth",
        "matPostpartumFndusLoc",
        "matPostpartumFndusHt",
        "matPostpartumFndusHtOth",
        "matPostpartumLochiaAmt",
        "matPostpartumLochiaDesc",
        "matPostpartumLochiaDescOth",
        "matPostpartumClotsPres",
        "matPostpartumClotsPresCmt",
        "matPostpartumLochiaOdor",
        "matPostpartumLochiaOdorOth",
        "matPostpartumPerineumCond",
        "matPostpartumPerineumCondOth",
        "matPostpartumPerineumComfort",
        "matPostpartumPerineumComfortOth",
        "matPostpartumFndusLochiaPerineumCmt",
        "matPostpartumDressingType",
        "matPostpartumAbdDressing",
        "matPostpartumAbdDressingOth",
        "matPostpartumDressingSat",
        "matPostpartumDressingSatOth",
        "matPostpartumAbdIncisionDesc",
        "matPostpartumAbdIncisionDescOth",
        "matPostpartumAbdIncisionCmt",
        "matPostpartumBrstChestDfdParm",
        "matPostpartumBrstNippleConcerns",
        "matPostpartumBrstNippleConcernsOth",
        "matPostpartumBrstNippleIntervOth",
        "matPostpartumBrstNippleCmt",
        "matPostpartumColostrumPres",
        "matPostpartumColostrumPresCmt",
        "matPostpartumHmMilkPres",
        "matPostpartumHmMilkPresCmt",
        "matPostpartumHmMilkExpMeth",
        "matPostpartumHmMilkExpMethCmt",
        "matPostpartumVoidNoDiff",
        "matPostpartumVoidNoDiffCmt",
        "matPostpartumLastBowelMov",
        "matPostpartumFlatus",
        "matPostpartumFlatusOth",
        "matPostpartumBowelSoundsPres",
        "matPostpartumUrineBowelFuncCmt",
        "matPostpartumUrineCathStatusDesc",
        "matPostpartumUrineCathInsertDate",
        "matPostpartumUrineCathInsertTime",
        "matPostpartumUrineCathRemoveDate",
        "matPostpartumUrineCathRemoveTime",
        "matPostpartumInOutAmt",
        "matPostpartumUrineCathStatusCmt",
        "matPostpartumVoidPostCathRemove",
        "matPostpartumVoidPostCathDate",
        "matPostpartumVoidPostCathTime",
        "matPostpartumVoidPostCathAmt",
        "matPostpartumPostCathVoidCmt",
        "matPostpartumRetSigns",
        "matPostpartumRetSignsOth",
        "matPostpartumBladderScanVol",
        "matPostpartumBladderScanCmt",
        "matPostpartumPostVoidResVol",
        "matPostpartumHrsPostAmbulate",
        "matPostpartumAmbulateConcerns",
        "matPostpartumAmbulateConcernsCmt",
        "matPostpartumPersCareDesc",
        "matPostpartumPersCareDescOth",
        "matPostpartumFluidNutrAdeq",
        "matPostpartumFluidNutrAdeqCmt",
        "matPostpartumPtSleepAdeq",
        "matPostpartumPtSleepAdeqCmt",
        "matPostpartumRestWhenBabySleeps",
        "matPostpartumRestWhenBabySleepsCmt",
        "matPostpartumPersCareOralNutSleep",
        "matPostpartumPpdRiskFactors",
        "matPostpartumPpdRiskFactorsOth",
        "matPostpartumAffect",
        "matPostpartumAffectOth",
        "matPostpartumMood",
        "matPostpartumPsychosocialCmt",
        "matPostpartumMatSupportsPres",
        "matPostpartumMatSupportsPresCmt",
        "matPostpartumFamCopingSkills",
        "matPostpartumFamFunctioning",
        "matPostpartumFamFunctioningCmt",
        "matPostpartumType",
        "matPostpartumBondAttachConcernsCmt",
        "matPostpartumSkinToSkin",
        "matPostpartumFeedBehaveCues",
        "matPostpartumFeedBehaveCuesCmt",
        "matPostpartumNicUseHist",
        "matPostpartumNicUseHistCmt",
        "matPostpartumNicTypeUse",
        "matPostpartumNicTypeUseOth",
        "matPostpartumAlcSubUseHist",
        "matPostpartumAlcSubUseHistOth",
        "matPostpartumAlcSubUseDetails",
        "matPostpartumNicSubIntervType",
        "matPostpartumNicSubIntervOth",
        "matPostpartumNicSubIntervCmt",
        "matPostpartumComments",
        "matPostpartumForm_name",
        "matPostpartumForm_profession"
      ],
      "hasRecHeader": true
    }
  },
  "path": "/ehr/peri-ped/maternity",
  "routeName": "mat-postpartum",
  "label": "Postpartum Assessment",
  "generateComponent": "v2page",
  "featureFlag": "ffPed",
  "ehr": "yes",
  "fullPageKey": "periPed.maternity.matPostpartum"
}
export default DEFS