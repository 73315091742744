/* eslint-disable */
const DEFS = {
  "pageDataKey": "newborn",
  "pIndex": "61",
  "pageChildren": [],
  "pageElements": {},
  "path": "/ehr/peri-ped",
  "routeName": "newborn",
  "label": "Newborn",
  "generateComponent": "custom",
  "featureFlag": "ffPed",
  "ehr": "yes",
  "fullPageKey": "periPed.newborn"
}
export default DEFS