/* eslint-disable */
const DEFS = {
  "pageDataKey": "dischargeSummary",
  "pIndex": "27",
  "hasGridForm": true,
  "pageChildren": [
    {
      "elementKey": "admissionDay",
      "defaultValue": "0",
      "formIndex": "1",
      "inputType": "visitDay",
      "validation": "visitDay",
      "fqn": "dischargeSummary.admissionDay"
    },
    {
      "elementKey": "dischargingPhysician/np",
      "formIndex": "1",
      "inputType": "select",
      "options": [
        {
          "key": "Dr. Notley",
          "text": "Dr. Notley"
        },
        {
          "key": "Dr. Hunicutt",
          "text": "Dr. Hunicutt"
        },
        {
          "key": "Dr. Lee",
          "text": "Dr. Lee"
        },
        {
          "key": "Dr. Malik",
          "text": "Dr. Malik"
        },
        {
          "key": "Dr. Ng",
          "text": "Dr. Ng"
        },
        {
          "key": "Dr. Walker",
          "text": "Dr. Walker"
        },
        {
          "key": "Dr. Brooks",
          "text": "Dr. Brooks"
        }
      ],
      "fqn": "dischargeSummary.dischargingPhysician/np"
    },
    {
      "elementKey": "spacer27",
      "formIndex": "1",
      "inputType": "spacer",
      "fqn": "dischargeSummary.spacer27"
    },
    {
      "elementKey": "clinicalSummary",
      "formIndex": "1",
      "formCss": "grid-span-2-columns",
      "inputType": "textarea",
      "fqn": "dischargeSummary.clinicalSummary"
    },
    {
      "elementKey": "dischargeDiagnosis",
      "formIndex": "2",
      "formCss": "grid-span-3-columns",
      "inputType": "textarea",
      "fqn": "dischargeSummary.dischargeDiagnosis"
    },
    {
      "elementKey": "dischargeEducation",
      "formIndex": "2",
      "formCss": "grid-span-3-columns",
      "inputType": "textarea",
      "fqn": "dischargeSummary.dischargeEducation"
    },
    {
      "elementKey": "spacer28",
      "formIndex": "2",
      "inputType": "spacer",
      "fqn": "dischargeSummary.spacer28"
    },
    {
      "elementKey": "dischargeDay",
      "formIndex": "2",
      "inputType": "visitDay",
      "validation": "visitDay",
      "fqn": "dischargeSummary.dischargeDay"
    },
    {
      "elementKey": "dischargeTime",
      "formIndex": "2",
      "inputType": "visitTime",
      "validation": "time24",
      "fqn": "dischargeSummary.dischargeTime"
    },
    {
      "elementKey": "dischargedTo",
      "formIndex": "2",
      "inputType": "select",
      "options": [
        {
          "key": "Home",
          "text": "Home"
        },
        {
          "key": "Expired",
          "text": "Expired"
        },
        {
          "key": "Transferred",
          "text": "Transferred"
        }
      ],
      "fqn": "dischargeSummary.dischargedTo"
    },
    {
      "elementKey": "transferedTo",
      "formIndex": "2",
      "inputType": "text",
      "fqn": "dischargeSummary.transferedTo"
    },
    {
      "elementKey": "leavingBy",
      "formIndex": "2",
      "inputType": "select",
      "options": [
        {
          "key": "Taxi",
          "text": "Taxi"
        },
        {
          "key": "Family",
          "text": "Family"
        },
        {
          "key": "Ambulance",
          "text": "Ambulance"
        },
        {
          "key": "Walk out/wheelchair",
          "text": "Walk out/wheelchair"
        }
      ],
      "fqn": "dischargeSummary.leavingBy"
    }
  ],
  "pageElements": {
    "pageForm": {
      "elementKey": "pageForm",
      "pageElementIndex": "1",
      "formKey": "pageForm",
      "fqn": "dischargeSummary.pageForm",
      "isPageForm": true,
      "ehr_groups": [
        {
          "elementKey": "cGroup27-1",
          "formCss": "grid-left-to-right-3",
          "fqn": "dischargeSummary.cGroup27-1",
          "gIndex": "1",
          "gChildren": [
            "admissionDay",
            "dischargingPhysician/np",
            "spacer27",
            "clinicalSummary"
          ]
        }
      ]
    },
    "pageForm2": {
      "elementKey": "pageForm2",
      "pageElementIndex": "2",
      "formKey": "pageForm2",
      "fqn": "dischargeSummary.pageForm2",
      "isPageForm": true,
      "ehr_groups": [
        {
          "elementKey": "cGroup27-1",
          "formCss": "grid-left-to-right-3",
          "fqn": "dischargeSummary.cGroup27-1",
          "gIndex": "1",
          "gChildren": [
            "dischargeDiagnosis",
            "dischargeEducation",
            "spacer28",
            "dischargeDay",
            "dischargeTime",
            "dischargedTo",
            "transferedTo",
            "leavingBy"
          ]
        }
      ]
    }
  },
  "path": "/ehr/current",
  "routeName": "discharge",
  "label": "Discharge summary",
  "redirectEhr": "",
  "redirectLis": "",
  "generateComponent": "custom",
  "ehr": "yes",
  "fullPageKey": "current.dischargeSummary"
}
export default DEFS