/* eslint-disable */
const DEFS = {
  "pageDataKey": "medLabAccession",
  "pIndex": "50",
  "hasGridTable": true,
  "pageChildren": [
    {
      "elementKey": "mlAccessioning_name",
      "formIndex": "1",
      "inputType": "practitionerName",
      "tableColumn": "1",
      "recHeader": true,
      "fqn": "medLabAccession.name"
    },
    {
      "elementKey": "mlAccessioning_id",
      "inputType": "generatedId",
      "tableColumn": 0,
      "label": "Row id",
      "tableCss": "row-id"
    },
    {
      "elementKey": "mlAccessioning_profession",
      "formIndex": "1",
      "inputType": "practitionerProfession",
      "tableColumn": "1",
      "recHeader": true,
      "fqn": "medLabAccession.profession"
    },
    {
      "elementKey": "mlAccessioning_day",
      "formIndex": "1",
      "inputType": "visitDay",
      "mandatory": true,
      "tableColumn": "1",
      "validation": "visitDay",
      "recHeader": true,
      "fqn": "medLabAccession.day"
    },
    {
      "elementKey": "mlAccessioning_time",
      "formIndex": "1",
      "inputType": "visitTime",
      "mandatory": true,
      "tableColumn": "1",
      "validation": "time24",
      "recHeader": true,
      "fqn": "medLabAccession.time"
    },
    {
      "elementKey": "accessionID",
      "formIndex": "1",
      "inputType": "accessionId",
      "tableColumn": "2",
      "fqn": "medLabAccession.accessionID"
    },
    {
      "elementKey": "physician",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "3",
      "fqn": "medLabAccession.physician"
    },
    {
      "elementKey": "priority",
      "formIndex": "1",
      "inputType": "select",
      "options": [
        {
          "key": "Fasting",
          "text": "Fasting"
        },
        {
          "key": "Routine",
          "text": "Routine"
        },
        {
          "key": "ASAP",
          "text": "ASAP"
        },
        {
          "key": "STAT",
          "text": "STAT"
        }
      ],
      "tableColumn": "4",
      "fqn": "medLabAccession.priority"
    },
    {
      "elementKey": "labTestSet",
      "formIndex": "1",
      "formCss": "grid-span-3-columns",
      "inputType": "labTestSet",
      "tableColumn": "5",
      "fqn": "medLabAccession.labTestSet"
    },
    {
      "elementKey": "collectionDate",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "6",
      "fqn": "medLabAccession.collectionDate"
    },
    {
      "elementKey": "collectionTime",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "7",
      "fqn": "medLabAccession.collectionTime"
    },
    {
      "elementKey": "mlAspacer",
      "formIndex": "1",
      "inputType": "spacer",
      "tableColumn": "8",
      "fqn": "medLabAccession.mlAspacer"
    },
    {
      "elementKey": "last_dose_day",
      "formIndex": "1",
      "formCss": "visit-day",
      "inputType": "visitDay",
      "tableColumn": "9",
      "validation": "visitDay",
      "fqn": "medLabAccession.last_dose_day"
    },
    {
      "elementKey": "last_dose_time",
      "formIndex": "1",
      "formCss": "visit-time",
      "inputType": "visitTime",
      "tableColumn": "10",
      "validation": "time24",
      "fqn": "medLabAccession.last_dose_time"
    },
    {
      "elementKey": "accComments",
      "formIndex": "1",
      "inputType": "textarea",
      "tableColumn": "11",
      "fqn": "medLabAccession.accComments"
    },
    {
      "elementKey": "mlChain_name",
      "formIndex": "2",
      "inputType": "practitionerName",
      "tableColumn": "1",
      "recHeader": true,
      "fqn": "medLabAccession.name"
    },
    {
      "elementKey": "mlChain_id",
      "inputType": "generatedId",
      "tableColumn": 0,
      "label": "Row id",
      "tableCss": "row-id"
    },
    {
      "elementKey": "mlChain_profession",
      "formIndex": "2",
      "inputType": "practitionerProfession",
      "tableColumn": "1",
      "recHeader": true,
      "fqn": "medLabAccession.profession"
    },
    {
      "elementKey": "mlChain_day",
      "formIndex": "2",
      "inputType": "visitDay",
      "mandatory": true,
      "tableColumn": "1",
      "validation": "visitDay",
      "recHeader": true,
      "fqn": "medLabAccession.day"
    },
    {
      "elementKey": "mlChain_time",
      "formIndex": "2",
      "inputType": "visitTime",
      "mandatory": true,
      "tableColumn": "1",
      "validation": "time24",
      "recHeader": true,
      "fqn": "medLabAccession.time"
    },
    {
      "elementKey": "chainAccessionID",
      "formIndex": "2",
      "inputType": "text",
      "tableColumn": "2",
      "fqn": "medLabAccession.chainAccessionID"
    },
    {
      "elementKey": "chainEvent",
      "formIndex": "2",
      "inputType": "text",
      "tableColumn": "3",
      "fqn": "medLabAccession.chainEvent"
    },
    {
      "elementKey": "chainDate",
      "formIndex": "2",
      "inputType": "text",
      "tableColumn": "4",
      "fqn": "medLabAccession.chainDate"
    },
    {
      "elementKey": "chainTime",
      "formIndex": "2",
      "inputType": "text",
      "tableColumn": "5",
      "fqn": "medLabAccession.chainTime"
    },
    {
      "elementKey": "chainDetails",
      "formIndex": "2",
      "inputType": "text",
      "tableColumn": "6",
      "fqn": "medLabAccession.chainDetails"
    },
    {
      "elementKey": "chainComments",
      "formIndex": "2",
      "inputType": "textarea",
      "tableColumn": "7",
      "fqn": "medLabAccession.chainComments"
    },
    {
      "elementKey": "mlTestStatus_name",
      "formIndex": "3",
      "inputType": "practitionerName",
      "tableColumn": "1",
      "recHeader": true,
      "fqn": "medLabAccession.name"
    },
    {
      "elementKey": "mlTestStatus_id",
      "inputType": "generatedId",
      "tableColumn": 0,
      "label": "Row id",
      "tableCss": "row-id"
    },
    {
      "elementKey": "mlTestStatus_profession",
      "formIndex": "3",
      "inputType": "practitionerProfession",
      "tableColumn": "1",
      "recHeader": true,
      "fqn": "medLabAccession.profession"
    },
    {
      "elementKey": "mlTestStatus_day",
      "formIndex": "3",
      "inputType": "visitDay",
      "mandatory": true,
      "tableColumn": "1",
      "validation": "visitDay",
      "recHeader": true,
      "fqn": "medLabAccession.day"
    },
    {
      "elementKey": "mlTestStatus_time",
      "formIndex": "3",
      "inputType": "visitTime",
      "mandatory": true,
      "tableColumn": "1",
      "validation": "time24",
      "recHeader": true,
      "fqn": "medLabAccession.time"
    },
    {
      "elementKey": "testAccessionID",
      "formIndex": "3",
      "inputType": "text",
      "tableColumn": "2",
      "fqn": "medLabAccession.testAccessionID"
    },
    {
      "elementKey": "testStatus",
      "formIndex": "3",
      "inputType": "text",
      "tableColumn": "3",
      "fqn": "medLabAccession.testStatus"
    },
    {
      "elementKey": "testDate",
      "formIndex": "3",
      "inputType": "text",
      "tableColumn": "4",
      "fqn": "medLabAccession.testDate"
    },
    {
      "elementKey": "testTime",
      "formIndex": "3",
      "inputType": "text",
      "tableColumn": "5",
      "fqn": "medLabAccession.testTime"
    },
    {
      "elementKey": "testComments",
      "formIndex": "3",
      "inputType": "textarea",
      "tableColumn": "6",
      "fqn": "medLabAccession.testComments"
    }
  ],
  "pageElements": {
    "mlAccessioning": {
      "elementKey": "mlAccessioning",
      "pageElementIndex": "1",
      "pageKey": "medLabAccession",
      "tableKey": "mlAccessioning",
      "isTable": true,
      "tableAction": "[ \"printBarCode\" ]",
      "form": {
        "elementKey": "mlAccessioning",
        "label": "Accessioning",
        "addButtonText": "Create an accessioning record",
        "formKey": "mlAccessioning",
        "ehr_groups": [
          {
            "elementKey": "hdrGroup",
            "formCss": "record-header",
            "fqn": "medLabAccession.hdrGroup",
            "gIndex": "1",
            "gChildren": [
              "mlAccessioning_name",
              "mlAccessioning_profession",
              "mlAccessioning_day",
              "mlAccessioning_time"
            ]
          },
          {
            "elementKey": "cGroup50-2",
            "formCss": "grid-left-to-right-3",
            "fqn": "medLabAccession.cGroup50-2",
            "gIndex": "2",
            "gChildren": [
              "accessionID",
              "physician",
              "priority",
              "labTestSet",
              "collectionDate",
              "collectionTime",
              "mlAspacer",
              "last_dose_day",
              "last_dose_time"
            ]
          },
          {
            "elementKey": "cGroup50-3",
            "formCss": "grid-left-to-right-1",
            "fqn": "medLabAccession.cGroup50-3",
            "gIndex": "3",
            "gChildren": [
              "accComments"
            ]
          }
        ],
        "ehr_data": {
          "mlAccessioning_name": "",
          "mlAccessioning_profession": "",
          "mlAccessioning_day": "",
          "mlAccessioning_time": "",
          "accessionID": "",
          "physician": "",
          "priority": "",
          "labTestSet": "",
          "collectionDate": "",
          "collectionTime": "",
          "last_dose_day": "",
          "last_dose_time": "",
          "accComments": ""
        }
      },
      "fqn": "medLabAccession.mlAccessioning",
      "tableActionType": "printBarCode",
      "tableChildren": [
        "mlAccessioning_id",
        "mlAccessioning_day",
        "mlAccessioning_time",
        "accessionID",
        "physician",
        "priority",
        "labTestSet",
        "collectionDate",
        "collectionTime",
        "mlAspacer",
        "last_dose_day",
        "last_dose_time",
        "accComments",
        "mlAccessioning_name",
        "mlAccessioning_profession"
      ],
      "hasRecHeader": true
    },
    "mlChain": {
      "elementKey": "mlChain",
      "pageElementIndex": "2",
      "pageKey": "medLabAccession",
      "tableKey": "mlChain",
      "isTable": true,
      "form": {
        "elementKey": "mlChain",
        "label": "Chain of Custody",
        "addButtonText": "Create a chain of custody record",
        "formKey": "mlChain",
        "ehr_groups": [
          {
            "elementKey": "hdrGroup",
            "formCss": "record-header",
            "fqn": "medLabAccession.hdrGroup",
            "gIndex": "1",
            "gChildren": [
              "mlChain_name",
              "mlChain_profession",
              "mlChain_day",
              "mlChain_time"
            ]
          },
          {
            "elementKey": "cGroup50-2",
            "formCss": "grid-left-to-right-3",
            "fqn": "medLabAccession.cGroup50-2",
            "gIndex": "2",
            "gChildren": [
              "chainAccessionID",
              "chainEvent",
              "chainDate",
              "chainTime",
              "chainDetails",
              "chainComments"
            ]
          }
        ],
        "ehr_data": {
          "mlChain_name": "",
          "mlChain_profession": "",
          "mlChain_day": "",
          "mlChain_time": "",
          "chainAccessionID": "",
          "chainEvent": "",
          "chainDate": "",
          "chainTime": "",
          "chainDetails": "",
          "chainComments": ""
        }
      },
      "fqn": "medLabAccession.mlChain",
      "tableChildren": [
        "mlChain_id",
        "mlChain_day",
        "mlChain_time",
        "chainAccessionID",
        "chainEvent",
        "chainDate",
        "chainTime",
        "chainDetails",
        "chainComments",
        "mlChain_name",
        "mlChain_profession"
      ],
      "hasRecHeader": true
    },
    "mlTestStatus": {
      "elementKey": "mlTestStatus",
      "pageElementIndex": "3",
      "pageKey": "medLabAccession",
      "tableKey": "mlTestStatus",
      "isTable": true,
      "form": {
        "elementKey": "mlTestStatus",
        "label": "Test Status",
        "addButtonText": "Create a test status record",
        "formKey": "mlTestStatus",
        "ehr_groups": [
          {
            "elementKey": "hdrGroup",
            "formCss": "record-header",
            "fqn": "medLabAccession.hdrGroup",
            "gIndex": "1",
            "gChildren": [
              "mlTestStatus_name",
              "mlTestStatus_profession",
              "mlTestStatus_day",
              "mlTestStatus_time"
            ]
          },
          {
            "elementKey": "cGroup50-2",
            "formCss": "grid-left-to-right-3",
            "fqn": "medLabAccession.cGroup50-2",
            "gIndex": "2",
            "gChildren": [
              "testAccessionID",
              "testStatus",
              "testDate",
              "testTime",
              "testComments"
            ]
          }
        ],
        "ehr_data": {
          "mlTestStatus_name": "",
          "mlTestStatus_profession": "",
          "mlTestStatus_day": "",
          "mlTestStatus_time": "",
          "testAccessionID": "",
          "testStatus": "",
          "testDate": "",
          "testTime": "",
          "testComments": ""
        }
      },
      "fqn": "medLabAccession.mlTestStatus",
      "tableChildren": [
        "mlTestStatus_id",
        "mlTestStatus_day",
        "mlTestStatus_time",
        "testAccessionID",
        "testStatus",
        "testDate",
        "testTime",
        "testComments",
        "mlTestStatus_name",
        "mlTestStatus_profession"
      ],
      "hasRecHeader": true
    }
  },
  "path": "/ehr/med-lab",
  "routeName": "med-lab-accession",
  "label": "Med Lab Accessioning",
  "generateComponent": "custom",
  "lis": "yes",
  "fullPageKey": "medLab.medLabAccession"
}
export default DEFS