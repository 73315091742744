/* eslint-disable */
const DEFS = {
  "pageDataKey": "periPed",
  "pIndex": "60",
  "pageChildren": [],
  "pageElements": {},
  "path": "/ehr",
  "routeName": "peri-ped",
  "label": "Maternal & Pediatric",
  "generateComponent": "custom",
  "ehr": "yes",
  "fullPageKey": "periPed"
}
export default DEFS