/* eslint-disable */
const DEFS = {
  "pageDataKey": "assessments",
  "pIndex": "13",
  "pageChildren": [],
  "pageElements": {},
  "path": "/ehr/current",
  "routeName": "assessments",
  "label": "Assessments",
  "generateComponent": "custom",
  "ehr": "yes",
  "fullPageKey": "current.assessments"
}
export default DEFS