/* eslint-disable */
const DEFS = {
  "pageDataKey": "pedAdmission",
  "pIndex": "80",
  "hasGridForm": true,
  "pageChildren": [
    {
      "elementKey": "pedAdmForm_name",
      "formIndex": "1",
      "inputType": "practitionerName",
      "tableColumn": "1",
      "recHeader": true,
      "fqn": "pedAdmission.name"
    },
    {
      "elementKey": "pedAdmForm_profession",
      "formIndex": "1",
      "inputType": "practitionerProfession",
      "tableColumn": "1",
      "recHeader": true,
      "fqn": "pedAdmission.profession"
    },
    {
      "elementKey": "pedAdmForm_day",
      "formIndex": "1",
      "inputType": "visitDay",
      "mandatory": true,
      "tableColumn": "1",
      "validation": "visitDay",
      "recHeader": true,
      "fqn": "pedAdmission.day"
    },
    {
      "elementKey": "pedAdmForm_time",
      "formIndex": "1",
      "inputType": "visitTime",
      "mandatory": true,
      "tableColumn": "1",
      "validation": "time24",
      "recHeader": true,
      "fqn": "pedAdmission.time"
    },
    {
      "elementKey": "pedAdmAdmitDate",
      "formIndex": "1",
      "inputType": "visitDay",
      "tableColumn": "3",
      "fqn": "pedAdmission.pedAdmAdmitDate"
    },
    {
      "elementKey": "pedAdmAdmitTime",
      "formIndex": "1",
      "inputType": "visitTime",
      "tableColumn": "4",
      "fqn": "pedAdmission.pedAdmAdmitTime"
    },
    {
      "elementKey": "pedAdmAdmittedFrom",
      "formIndex": "1",
      "inputType": "radioset",
      "options": [
        {
          "key": "emergency",
          "text": "Emergency"
        },
        {
          "key": "maternity",
          "text": "Maternity"
        },
        {
          "key": "NICU",
          "text": "NICU"
        },
        {
          "key": "BCCH",
          "text": "BCCH(PHSA)"
        },
        {
          "key": "other",
          "text": "Other FH site"
        },
        {
          "key": "other",
          "text": "Other"
        }
      ],
      "tableColumn": "5",
      "fqn": "pedAdmission.pedAdmAdmittedFrom"
    },
    {
      "elementKey": "pedAdmAdmittedFromOther",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "6",
      "fqn": "pedAdmission.pedAdmAdmittedFromOther"
    },
    {
      "elementKey": "pedAdmArrivalMethod",
      "formIndex": "1",
      "inputType": "radioset",
      "options": [
        {
          "key": "car",
          "text": "Car"
        },
        {
          "key": "ambulance",
          "text": "Ambulance"
        },
        {
          "key": "transport",
          "text": "Transport service"
        },
        {
          "key": "taxi",
          "text": "Taxi"
        },
        {
          "key": "police",
          "text": "Police"
        },
        {
          "key": "other",
          "text": "Other"
        }
      ],
      "tableColumn": "7",
      "fqn": "pedAdmission.pedAdmArrivalMethod"
    },
    {
      "elementKey": "pedAdmArrivalMethodOther",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "8",
      "fqn": "pedAdmission.pedAdmArrivalMethodOther"
    },
    {
      "elementKey": "pedAdmReportReceived",
      "formIndex": "1",
      "inputType": "radioset",
      "options": [
        {
          "key": "inPerson",
          "text": "In person"
        },
        {
          "key": "telephone",
          "text": "By telephone"
        },
        {
          "key": "fax",
          "text": "By fax"
        },
        {
          "key": "other",
          "text": "Other"
        }
      ],
      "tableColumn": "9",
      "fqn": "pedAdmission.pedAdmReportReceived"
    },
    {
      "elementKey": "pedAdmReportReceivedOther",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "10",
      "fqn": "pedAdmission.pedAdmReportReceivedOther"
    },
    {
      "elementKey": "pedAdmReportReceivedFrom",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "11",
      "fqn": "pedAdmission.pedAdmReportReceivedFrom"
    },
    {
      "elementKey": "pedAdmNotifications",
      "formIndex": "1",
      "inputType": "checkset",
      "options": [
        {
          "key": "MRP",
          "text": "MRP"
        },
        {
          "key": "family",
          "text": "Family"
        },
        {
          "key": "facility",
          "text": "Facility"
        },
        {
          "key": "nextOfKin",
          "text": "Next of kin"
        }
      ],
      "tableColumn": "12",
      "fqn": "pedAdmission.pedAdmNotifications"
    },
    {
      "elementKey": "pedAdmMedRecComplete",
      "formIndex": "1",
      "inputType": "radioset",
      "options": [
        {
          "key": "yes",
          "text": "Yes"
        },
        {
          "key": "no",
          "text": "No"
        },
        {
          "key": "comment",
          "text": "Comment"
        }
      ],
      "tableColumn": "13",
      "fqn": "pedAdmission.pedAdmMedRecComplete"
    },
    {
      "elementKey": "pedAdmMedRecComments",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "14",
      "fqn": "pedAdmission.pedAdmMedRecComments"
    },
    {
      "elementKey": "pedAdmAccompIndivs",
      "formIndex": "1",
      "inputType": "checkset",
      "options": [
        {
          "key": "caregiver",
          "text": "Caregiver"
        },
        {
          "key": "legalGuardian",
          "text": "Legal Guardian"
        },
        {
          "key": "nurse",
          "text": "Nurse"
        },
        {
          "key": "parent(s)",
          "text": "Parent(s)"
        },
        {
          "key": "paramedic(s)",
          "text": "Paramedic(s)"
        },
        {
          "key": "physician",
          "text": "Physician"
        },
        {
          "key": "RespiratoryTherapist",
          "text": "Respiratory therapist"
        },
        {
          "key": "other",
          "text": "Other"
        }
      ],
      "tableColumn": "15",
      "fqn": "pedAdmission.pedAdmAccompIndivs"
    },
    {
      "elementKey": "pedAdmAccompIndivsOther",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "16",
      "fqn": "pedAdmission.pedAdmAccompIndivsOther"
    },
    {
      "elementKey": "pedAdmPatientId",
      "formIndex": "1",
      "inputType": "checkset",
      "options": [
        {
          "key": "IDBand",
          "text": "ID band on"
        },
        {
          "key": "twoPatientID",
          "text": "Two patient ID completed"
        }
      ],
      "tableColumn": "17",
      "fqn": "pedAdmission.pedAdmPatientId"
    },
    {
      "elementKey": "pedAdmAllergiesReviewed",
      "formIndex": "1",
      "inputType": "radioset",
      "options": [
        {
          "key": "yes",
          "text": "Yes"
        },
        {
          "key": "no",
          "text": "No"
        },
        {
          "key": "comment",
          "text": "Comment"
        }
      ],
      "tableColumn": "18",
      "fqn": "pedAdmission.pedAdmAllergiesReviewed"
    },
    {
      "elementKey": "pedAdmAllergiesComments",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "19",
      "fqn": "pedAdmission.pedAdmAllergiesComments"
    },
    {
      "elementKey": "pedAdmAllergyBandChecked",
      "formIndex": "1",
      "inputType": "radioset",
      "options": [
        {
          "key": "yes",
          "text": "Yes"
        },
        {
          "key": "no",
          "text": "No"
        },
        {
          "key": "comment",
          "text": "Comment"
        }
      ],
      "tableColumn": "20",
      "fqn": "pedAdmission.pedAdmAllergyBandChecked"
    },
    {
      "elementKey": "pedAdmAllergyBandComments",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "21",
      "fqn": "pedAdmission.pedAdmAllergyBandComments"
    },
    {
      "elementKey": "pedAdmIsolationType",
      "formIndex": "1",
      "inputType": "checkset",
      "options": [
        {
          "key": "routine",
          "text": "Routine"
        },
        {
          "key": "contact",
          "text": "Contact"
        },
        {
          "key": "enhancedContact",
          "text": "Enhanced contact"
        },
        {
          "key": "contactPlus",
          "text": "Contact plus"
        },
        {
          "key": "droplet",
          "text": "Droplet"
        },
        {
          "key": "airborne",
          "text": "Airborne"
        },
        {
          "key": "immunocompromised",
          "text": "Immunocompromised"
        },
        {
          "key": "enhancedBarrier",
          "text": "Enhanced barrier (for LTC use only)"
        }
      ],
      "tableColumn": "23",
      "fqn": "pedAdmission.pedAdmIsolationType"
    },
    {
      "elementKey": "pedAdmIsolationRationale",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "24",
      "fqn": "pedAdmission.pedAdmIsolationRationale"
    },
    {
      "elementKey": "pedAdmInfectionScreen12Months",
      "formIndex": "1",
      "inputType": "radioset",
      "options": [
        {
          "key": "yes",
          "text": "Yes"
        },
        {
          "key": "no",
          "text": "No"
        },
        {
          "key": "notAsked",
          "text": "Not asked"
        },
        {
          "key": "unableToAnswer",
          "text": "Unable to answer"
        },
        {
          "key": "knownMRSA",
          "text": "Known MRSA"
        }
      ],
      "tableColumn": "26",
      "fqn": "pedAdmission.pedAdmInfectionScreen12Months"
    },
    {
      "elementKey": "pedAdmInfectionScreenCanada",
      "formIndex": "1",
      "inputType": "radioset",
      "options": [
        {
          "key": "yes",
          "text": "Yes"
        },
        {
          "key": "no",
          "text": "No"
        },
        {
          "key": "notAsked",
          "text": "Not asked"
        },
        {
          "key": "unableToAnswer",
          "text": "Unable to answer"
        },
        {
          "key": "knownMDRO",
          "text": "Known MDRO"
        }
      ],
      "tableColumn": "27",
      "fqn": "pedAdmission.pedAdmInfectionScreenCanada"
    },
    {
      "elementKey": "pedAdmInfectionScreenVisitedCountries",
      "formIndex": "1",
      "inputType": "radioset",
      "options": [
        {
          "key": "yes",
          "text": "Yes"
        },
        {
          "key": "no",
          "text": "No"
        },
        {
          "key": "notAsked",
          "text": "Not asked"
        },
        {
          "key": "unableToAnswer",
          "text": "Unable to answer"
        },
        {
          "key": "knownMDRO",
          "text": "Known MDRO"
        }
      ],
      "tableColumn": "28",
      "fqn": "pedAdmission.pedAdmInfectionScreenVisitedCountries"
    },
    {
      "elementKey": "pedAdmInfectionScreenDetails",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "29",
      "fqn": "pedAdmission.pedAdmInfectionScreenDetails"
    },
    {
      "elementKey": "pedAdmLatexHxSpinaBifidaComments",
      "formIndex": "1",
      "inputType": "radioset",
      "options": [
        {
          "key": "yes",
          "text": "Yes"
        },
        {
          "key": "no",
          "text": "No"
        },
        {
          "key": "comment",
          "text": "Comment"
        }
      ],
      "tableColumn": "31",
      "fqn": "pedAdmission.pedAdmLatexHxSpinaBifidaComments"
    },
    {
      "elementKey": "pedAdmLatexHxItchinessSwelling",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "32",
      "fqn": "pedAdmission.pedAdmLatexHxItchinessSwelling"
    },
    {
      "elementKey": "pedAdmLatexHxItchinessSwellingComments",
      "formIndex": "1",
      "inputType": "radioset",
      "options": [
        {
          "key": "yes",
          "text": "Yes"
        },
        {
          "key": "no",
          "text": "No"
        },
        {
          "key": "comment",
          "text": "Comment"
        }
      ],
      "tableColumn": "33",
      "fqn": "pedAdmission.pedAdmLatexHxItchinessSwellingComments"
    },
    {
      "elementKey": "pedAdmLatexHxUnexplainedReactions",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "34",
      "fqn": "pedAdmission.pedAdmLatexHxUnexplainedReactions"
    },
    {
      "elementKey": "pedAdmLatexHxUnexplainedReactionsComments",
      "formIndex": "1",
      "inputType": "radioset",
      "options": [
        {
          "key": "yes",
          "text": "Yes"
        },
        {
          "key": "no",
          "text": "No"
        },
        {
          "key": "comment",
          "text": "Comment"
        }
      ],
      "tableColumn": "35",
      "fqn": "pedAdmission.pedAdmLatexHxUnexplainedReactionsComments"
    },
    {
      "elementKey": "pedAdmLatexHxSensitivityToRubber",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "36",
      "fqn": "pedAdmission.pedAdmLatexHxSensitivityToRubber"
    },
    {
      "elementKey": "pedAdmLatexHxSensitivityToRubberOther",
      "formIndex": "1",
      "inputType": "checkset",
      "options": [
        {
          "key": "rubberGloves",
          "text": "Rubber gloves"
        },
        {
          "key": "balloons",
          "text": "Balloons"
        },
        {
          "key": "elasticBandages",
          "text": "Elastic bandages"
        },
        {
          "key": "condoms",
          "text": "Condoms"
        },
        {
          "key": "diaphrams",
          "text": "Diaphrams"
        },
        {
          "key": "rubber",
          "text": "Rubber"
        },
        {
          "key": "rubberBands",
          "text": "Rubber bands"
        },
        {
          "key": "halloweeMasks",
          "text": "Halloween masks"
        },
        {
          "key": "erasers",
          "text": "Erasers"
        },
        {
          "key": "soccerBalls",
          "text": "Soccer Balls"
        },
        {
          "key": "other",
          "text": "Other"
        }
      ],
      "tableColumn": "37",
      "fqn": "pedAdmission.pedAdmLatexHxSensitivityToRubberOther"
    },
    {
      "elementKey": "pedAdmLatexSuspectedAllergy",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "38",
      "fqn": "pedAdmission.pedAdmLatexSuspectedAllergy"
    },
    {
      "elementKey": "pedAdmLatexSuspectedAllergyComments",
      "formIndex": "1",
      "inputType": "radioset",
      "options": [
        {
          "key": "yes",
          "text": "Yes"
        },
        {
          "key": "no",
          "text": "No"
        },
        {
          "key": "comment",
          "text": "Comment"
        }
      ],
      "tableColumn": "39",
      "fqn": "pedAdmission.pedAdmLatexSuspectedAllergyComments"
    },
    {
      "elementKey": "pedAdmOrientationPatientOriented",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "40",
      "fqn": "pedAdmission.pedAdmOrientationPatientOriented"
    },
    {
      "elementKey": "pedAdmOrientationPatientComments",
      "formIndex": "1",
      "inputType": "radioset",
      "options": [
        {
          "key": "yes",
          "text": "Yes"
        },
        {
          "key": "no",
          "text": "No"
        },
        {
          "key": "comment",
          "text": "Comment"
        }
      ],
      "tableColumn": "42",
      "fqn": "pedAdmission.pedAdmOrientationPatientComments"
    },
    {
      "elementKey": "pedAdmOrientationPatientBelongings",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "43",
      "fqn": "pedAdmission.pedAdmOrientationPatientBelongings"
    },
    {
      "elementKey": "pedAdmMentalHealthCertification",
      "formIndex": "1",
      "inputType": "radioset",
      "options": [
        {
          "key": "yes",
          "text": "Yes"
        },
        {
          "key": "no",
          "text": "No"
        }
      ],
      "tableColumn": "44",
      "fqn": "pedAdmission.pedAdmMentalHealthCertification"
    },
    {
      "elementKey": "pedAdmMentalHealthStatus",
      "formIndex": "1",
      "inputType": "radioset",
      "options": [
        {
          "key": "yes",
          "text": "Yes"
        },
        {
          "key": "no",
          "text": "No"
        }
      ],
      "tableColumn": "46",
      "fqn": "pedAdmission.pedAdmMentalHealthStatus"
    },
    {
      "elementKey": "pedAdmMentalHealthObservation",
      "formIndex": "1",
      "inputType": "radioset",
      "options": [
        {
          "key": "voluntary",
          "text": "Voluntary"
        },
        {
          "key": "involuntary",
          "text": "Involuntary"
        }
      ],
      "tableColumn": "47",
      "fqn": "pedAdmission.pedAdmMentalHealthObservation"
    },
    {
      "elementKey": "pedAdmObservation",
      "formIndex": "1",
      "inputType": "radioset",
      "options": [
        {
          "key": "constant",
          "text": "Constant"
        },
        {
          "key": "level1",
          "text": "Level 1"
        },
        {
          "key": "level2",
          "text": "Level 2"
        },
        {
          "key": "level3",
          "text": "Level 3"
        }
      ],
      "tableColumn": "48",
      "fqn": "pedAdmission.pedAdmObservation"
    },
    {
      "elementKey": "pedAdmComments",
      "formIndex": "1",
      "inputType": "textarea",
      "tableColumn": "50",
      "fqn": "pedAdmission.pedAdmComments"
    }
  ],
  "pageElements": {
    "pedAdmForm": {
      "elementKey": "pedAdmForm",
      "pageElementIndex": "1",
      "formKey": "pedAdmForm",
      "fqn": "pedAdmission.pedAdmForm",
      "isPageForm": true,
      "ehr_groups": [
        {
          "elementKey": "pedAdmHdrGrp",
          "formCss": "record-header",
          "fqn": "pedAdmission.pedAdmHdrGrp",
          "gIndex": "1",
          "gChildren": [
            "pedAdmForm_name",
            "pedAdmForm_profession",
            "pedAdmForm_day",
            "pedAdmForm_time"
          ]
        },
        {
          "elementKey": "pedAdmDetailsGrp",
          "formCss": "ehr-row-flow",
          "fqn": "pedAdmission.pedAdmDetailsGrp",
          "gIndex": "2",
          "gChildren": [
            "pedAdmAdmitDate",
            "pedAdmAdmitTime",
            "pedAdmAdmittedFrom",
            "pedAdmAdmittedFromOther",
            "pedAdmArrivalMethod",
            "pedAdmArrivalMethodOther",
            "pedAdmReportReceived",
            "pedAdmReportReceivedOther",
            "pedAdmReportReceivedFrom",
            "pedAdmNotifications",
            "pedAdmMedRecComplete",
            "pedAdmMedRecComments",
            "pedAdmAccompIndivs",
            "pedAdmAccompIndivsOther",
            "pedAdmPatientId"
          ]
        },
        {
          "elementKey": "pedAdmHdrGrp2",
          "formCss": "ehr-row-flow",
          "fqn": "pedAdmission.pedAdmHdrGrp2",
          "gIndex": "3",
          "gChildren": [
            "pedAdmAllergiesReviewed",
            "pedAdmAllergiesComments",
            "pedAdmAllergyBandChecked",
            "pedAdmAllergyBandComments"
          ]
        },
        {
          "elementKey": "pedAdmHdrGrp3",
          "formCss": "ehr-row-flow",
          "fqn": "pedAdmission.pedAdmHdrGrp3",
          "gIndex": "4",
          "gChildren": [
            "pedAdmIsolationType",
            "pedAdmIsolationRationale"
          ]
        },
        {
          "elementKey": "pedAdmHdrGrp4",
          "formCss": "ehr-row-flow",
          "fqn": "pedAdmission.pedAdmHdrGrp4",
          "gIndex": "5",
          "gChildren": [
            "pedAdmInfectionScreen12Months",
            "pedAdmInfectionScreenCanada",
            "pedAdmInfectionScreenVisitedCountries",
            "pedAdmInfectionScreenDetails"
          ]
        },
        {
          "elementKey": "pedAdmLatexHxSpinaBifida",
          "formCss": "ehr-row-flow",
          "fqn": "pedAdmission.pedAdmLatexHxSpinaBifida",
          "gIndex": "6",
          "gChildren": [
            "pedAdmLatexHxSpinaBifidaComments",
            "pedAdmLatexHxItchinessSwelling",
            "pedAdmLatexHxItchinessSwellingComments",
            "pedAdmLatexHxUnexplainedReactions",
            "pedAdmLatexHxUnexplainedReactionsComments",
            "pedAdmLatexHxSensitivityToRubber",
            "pedAdmLatexHxSensitivityToRubberOther",
            "pedAdmLatexSuspectedAllergy",
            "pedAdmLatexSuspectedAllergyComments",
            "pedAdmOrientationPatientOriented"
          ]
        },
        {
          "elementKey": "pedAdmHdrGrp5",
          "formCss": "ehr-row-flow",
          "fqn": "pedAdmission.pedAdmHdrGrp5",
          "gIndex": "7",
          "gChildren": [
            "pedAdmOrientationPatientComments",
            "pedAdmOrientationPatientBelongings",
            "pedAdmMentalHealthCertification"
          ]
        },
        {
          "elementKey": "pedAdmHdrGrp6",
          "formCss": "ehr-row-flow",
          "fqn": "pedAdmission.pedAdmHdrGrp6",
          "gIndex": "8",
          "gChildren": [
            "pedAdmMentalHealthStatus",
            "pedAdmMentalHealthObservation",
            "pedAdmObservation"
          ]
        },
        {
          "elementKey": "pedAdmHdrCmtsGrp",
          "formCss": "grid-left-to-right-1",
          "fqn": "pedAdmission.pedAdmHdrCmtsGrp",
          "gIndex": "9",
          "gChildren": [
            "pedAdmComments"
          ]
        }
      ]
    }
  },
  "path": "/ehr/peri-ped/pediatrics",
  "routeName": "ped-admission",
  "label": "Admission Record Peds",
  "generateComponent": "v2page",
  "featureFlag": "ffPed",
  "ehr": "yes",
  "fullPageKey": "periPed.pediatrics.pedAdmission"
}
export default DEFS