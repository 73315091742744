/* eslint-disable */
const DEFS = {
  "pageDataKey": "pedPain",
  "pIndex": "75",
  "hasGridTable": true,
  "pageChildren": [
    {
      "elementKey": "pedPainForm_name",
      "formIndex": "1",
      "inputType": "practitionerName",
      "tableColumn": "1",
      "recHeader": true,
      "fqn": "pedPain.name"
    },
    {
      "elementKey": "pedPainForm_id",
      "inputType": "generatedId",
      "tableColumn": 0,
      "label": "Row id",
      "tableCss": "row-id"
    },
    {
      "elementKey": "pedPainForm_profession",
      "formIndex": "1",
      "inputType": "practitionerProfession",
      "tableColumn": "1",
      "recHeader": true,
      "fqn": "pedPain.profession"
    },
    {
      "elementKey": "pedPainForm_day",
      "formIndex": "1",
      "inputType": "visitDay",
      "mandatory": true,
      "tableColumn": "1",
      "validation": "visitDay",
      "recHeader": true,
      "fqn": "pedPain.day"
    },
    {
      "elementKey": "pedPainForm_time",
      "formIndex": "1",
      "inputType": "visitTime",
      "mandatory": true,
      "tableColumn": "1",
      "validation": "time24",
      "recHeader": true,
      "fqn": "pedPain.time"
    },
    {
      "elementKey": "predPainParams",
      "formIndex": "1",
      "inputType": "radioset",
      "options": [
        {
          "key": "wdf",
          "text": "Within defined parameters"
        },
        {
          "key": "significant",
          "text": "Significant findings"
        },
        {
          "key": "na",
          "text": "Not applicable"
        },
        {
          "key": "notAssessed",
          "text": "Not assessed"
        }
      ],
      "tableColumn": "3",
      "fqn": "pedPain.predPainParams"
    },
    {
      "elementKey": "predPainParamsText",
      "formIndex": "1",
      "inputType": "formInfoText",
      "tableColumn": "4",
      "fqn": "pedPain.predPainParamsText"
    },
    {
      "elementKey": "predPainScreen",
      "formIndex": "1",
      "inputType": "radioset",
      "options": [
        {
          "key": "painReported",
          "text": "Pain reported"
        },
        {
          "key": "deniesPain",
          "text": "Denies pain"
        },
        {
          "key": "nonverbalAssessment",
          "text": "Non-verbal assessment completed"
        },
        {
          "key": "asleep",
          "text": "Asleep"
        }
      ],
      "tableColumn": "5",
      "fqn": "pedPain.predPainScreen"
    },
    {
      "elementKey": "predPainGoal",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "6",
      "fqn": "pedPain.predPainGoal"
    },
    {
      "elementKey": "predPainScale",
      "formIndex": "1",
      "inputType": "radioset",
      "options": [
        {
          "key": "numeric",
          "text": "Numeric"
        },
        {
          "key": "faces",
          "text": "Faces"
        },
        {
          "key": "FLACC",
          "text": "FLACC"
        }
      ],
      "tableColumn": "7",
      "fqn": "pedPain.predPainScale"
    },
    {
      "elementKey": "predPainIntensity",
      "formIndex": "1",
      "inputType": "number",
      "tableColumn": "8",
      "fqn": "pedPain.predPainIntensity"
    },
    {
      "elementKey": "predPainLocation",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "9",
      "fqn": "pedPain.predPainLocation"
    },
    {
      "elementKey": "predPainType",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "10",
      "fqn": "pedPain.predPainType"
    },
    {
      "elementKey": "predPainResp",
      "formIndex": "1",
      "inputType": "number",
      "tableColumn": "11",
      "fqn": "pedPain.predPainResp"
    },
    {
      "elementKey": "predPainArousal",
      "formIndex": "1",
      "inputType": "radioset",
      "options": [
        {
          "key": "1",
          "text": "1 = Awake and alert/oriented"
        },
        {
          "key": "2",
          "text": "2 = Easy to arouse to verbal stimulation"
        },
        {
          "key": "3",
          "text": "3 = Difficult to arouse to verbal stimulation"
        },
        {
          "key": "4",
          "text": "4 = Responds only to physical stimulation"
        },
        {
          "key": "5",
          "text": "5 = Does not respond to verbal or physical stimulation"
        }
      ],
      "tableColumn": "12",
      "fqn": "pedPain.predPainArousal"
    },
    {
      "elementKey": "predPainInterv",
      "formIndex": "1",
      "inputType": "checkset",
      "options": [
        {
          "key": "medication",
          "text": "Medication"
        },
        {
          "key": "deepBreathing",
          "text": "Deep breathing"
        },
        {
          "key": "positioning",
          "text": "Positioning"
        },
        {
          "key": "exercises",
          "text": "Exercises"
        },
        {
          "key": "rest",
          "text": "Rest"
        },
        {
          "key": "massage",
          "text": "Massage"
        },
        {
          "key": "distraction",
          "text": "Distraction"
        },
        {
          "key": "music",
          "text": "Music"
        },
        {
          "key": "icePack",
          "text": "Ice pack"
        },
        {
          "key": "splintImmobilization",
          "text": "Splint/immobilization"
        },
        {
          "key": "cold",
          "text": "Cold"
        },
        {
          "key": "environmentalControl",
          "text": "Environmental control"
        },
        {
          "key": "heat",
          "text": "Heat"
        },
        {
          "key": "relaxationExercises",
          "text": "Relaxation exercises"
        },
        {
          "key": "imagery",
          "text": "Imagery"
        },
        {
          "key": "therapeuticTouch",
          "text": "Therapeutic touch"
        },
        {
          "key": "other",
          "text": "Other"
        }
      ],
      "tableColumn": "13",
      "fqn": "pedPain.predPainInterv"
    },
    {
      "elementKey": "predPainIntervOther",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "14",
      "fqn": "pedPain.predPainIntervOther"
    },
    {
      "elementKey": "predPainIntervComm",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "15",
      "fqn": "pedPain.predPainIntervComm"
    },
    {
      "elementKey": "flaccInfo",
      "formIndex": "1",
      "inputType": "formInfoText",
      "tableColumn": "17",
      "fqn": "pedPain.flaccInfo"
    },
    {
      "elementKey": "facesInfo",
      "formIndex": "1",
      "inputType": "formInfoText",
      "tableColumn": "18",
      "fqn": "pedPain.facesInfo"
    },
    {
      "elementKey": "pedPainComments",
      "formIndex": "1",
      "inputType": "textarea",
      "tableColumn": "20",
      "fqn": "pedPain.pedPainComments"
    }
  ],
  "pageElements": {
    "pedPainForm": {
      "elementKey": "pedPainForm",
      "pageElementIndex": "1",
      "pageKey": "pedPain",
      "tableKey": "pedPainForm",
      "isTable": true,
      "form": {
        "elementKey": "pedPainForm",
        "label": "Pain Assessment Peds",
        "addButtonText": "Add a Pain Assessment Peds assessment",
        "formKey": "pedPainForm",
        "ehr_groups": [
          {
            "elementKey": "pedPainHdrGrp",
            "formCss": "record-header",
            "fqn": "pedPain.pedPainHdrGrp",
            "gIndex": "1",
            "gChildren": [
              "pedPainForm_name",
              "pedPainForm_profession",
              "pedPainForm_day",
              "pedPainForm_time"
            ]
          },
          {
            "elementKey": "pedPainGrp1",
            "formCss": "ehr-row-flow",
            "fqn": "pedPain.pedPainGrp1",
            "gIndex": "2",
            "gChildren": [
              "predPainParams",
              "predPainParamsText",
              "predPainScreen",
              "predPainGoal",
              "predPainScale",
              "predPainIntensity",
              "predPainLocation",
              "predPainType",
              "predPainResp",
              "predPainArousal",
              "predPainInterv",
              "predPainIntervOther",
              "predPainIntervComm"
            ]
          },
          {
            "elementKey": "predPainInfoGrp",
            "formCss": "ehr-row-flow",
            "fqn": "pedPain.predPainInfoGrp",
            "gIndex": "3",
            "gChildren": [
              "flaccInfo",
              "facesInfo"
            ]
          },
          {
            "elementKey": "pedPainGrp2",
            "formCss": "grid-left-to-right-1",
            "fqn": "pedPain.pedPainGrp2",
            "gIndex": "4",
            "gChildren": [
              "pedPainComments"
            ]
          }
        ],
        "ehr_data": {
          "pedPainForm_name": "",
          "pedPainForm_profession": "",
          "pedPainForm_day": "",
          "pedPainForm_time": "",
          "predPainParams": "",
          "predPainScreen": "",
          "predPainGoal": "",
          "predPainScale": "",
          "predPainIntensity": "",
          "predPainLocation": "",
          "predPainType": "",
          "predPainResp": "",
          "predPainArousal": "",
          "predPainInterv": "",
          "predPainIntervOther": "",
          "predPainIntervComm": "",
          "pedPainComments": ""
        }
      },
      "fqn": "pedPain.pedPainForm",
      "tableChildren": [
        "pedPainForm_id",
        "pedPainForm_day",
        "pedPainForm_time",
        "predPainParams",
        "predPainParamsText",
        "predPainScreen",
        "predPainGoal",
        "predPainScale",
        "predPainIntensity",
        "predPainLocation",
        "predPainType",
        "predPainResp",
        "predPainArousal",
        "predPainInterv",
        "predPainIntervOther",
        "predPainIntervComm",
        "flaccInfo",
        "facesInfo",
        "pedPainComments",
        "pedPainForm_name",
        "pedPainForm_profession"
      ],
      "hasRecHeader": true
    }
  },
  "path": "/ehr/peri-ped/pediatrics",
  "routeName": "ped-pain",
  "label": "Pain Assessment Peds",
  "generateComponent": "v2page",
  "featureFlag": "ffPed",
  "ehr": "yes",
  "fullPageKey": "periPed.pediatrics.pedPain"
}
export default DEFS