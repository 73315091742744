/* eslint-disable */
const DEFS = {
  "pageDataKey": "external",
  "pIndex": "104",
  "pageChildren": [],
  "pageElements": {},
  "path": "/ehr",
  "routeName": "external",
  "label": "External resources",
  "generateComponent": "custom",
  "ehr": "yes",
  "fullPageKey": "external"
}
export default DEFS