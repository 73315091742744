/* eslint-disable */
const DEFS = {
  "pageDataKey": "diagnosticCodes",
  "pIndex": "37",
  "hasGridTable": true,
  "pageChildren": [
    {
      "elementKey": "table_name",
      "formIndex": "1",
      "inputType": "practitionerName",
      "tableColumn": "1",
      "recHeader": true,
      "fqn": "diagnosticCodes.name"
    },
    {
      "elementKey": "table_id",
      "inputType": "generatedId",
      "tableColumn": 0,
      "label": "Row id",
      "tableCss": "row-id"
    },
    {
      "elementKey": "table_profession",
      "formIndex": "1",
      "inputType": "practitionerProfession",
      "tableColumn": "1",
      "recHeader": true,
      "fqn": "diagnosticCodes.profession"
    },
    {
      "elementKey": "table_day",
      "formIndex": "1",
      "inputType": "visitDay",
      "mandatory": true,
      "tableColumn": "1",
      "validation": "visitDay",
      "recHeader": true,
      "fqn": "diagnosticCodes.day"
    },
    {
      "elementKey": "table_time",
      "formIndex": "1",
      "inputType": "visitTime",
      "mandatory": true,
      "tableColumn": "1",
      "validation": "time24",
      "recHeader": true,
      "fqn": "diagnosticCodes.time"
    },
    {
      "elementKey": "icd10",
      "assetBase": "static-databases",
      "assetName": "icd-10-medical-diagnosis-codes.pdf",
      "formIndex": "1",
      "inputType": "assetLink",
      "tableColumn": "2",
      "fqn": "diagnosticCodes.icd10"
    },
    {
      "elementKey": "condition",
      "formIndex": "1",
      "inputType": "text",
      "mandatory": "yes",
      "tableColumn": "3",
      "fqn": "diagnosticCodes.condition"
    },
    {
      "elementKey": "code",
      "formIndex": "1",
      "inputType": "text",
      "mandatory": "yes",
      "tableColumn": "4",
      "fqn": "diagnosticCodes.code"
    },
    {
      "elementKey": "type",
      "formIndex": "1",
      "inputType": "select",
      "mandatory": "yes",
      "options": [
        {
          "key": "M",
          "text": "M"
        },
        {
          "key": "1",
          "text": "1"
        },
        {
          "key": "2",
          "text": "2"
        },
        {
          "key": "3",
          "text": "3"
        }
      ],
      "tableColumn": "5",
      "fqn": "diagnosticCodes.type"
    },
    {
      "elementKey": "prefix",
      "formIndex": "1",
      "inputType": "text",
      "mandatory": "no",
      "tableColumn": "6",
      "fqn": "diagnosticCodes.prefix"
    },
    {
      "elementKey": "cluster",
      "formIndex": "1",
      "inputType": "text",
      "mandatory": "no",
      "tableColumn": "7",
      "fqn": "diagnosticCodes.cluster"
    }
  ],
  "pageElements": {
    "table": {
      "elementKey": "table",
      "pageElementIndex": "1",
      "pageKey": "diagnosticCodes",
      "tableKey": "table",
      "isTable": true,
      "form": {
        "elementKey": "table",
        "label": "Diagnostic codes (ICD-10)",
        "addButtonText": "Add a diagnostic code",
        "formKey": "table",
        "ehr_groups": [
          {
            "elementKey": "hdrGroup",
            "formCss": "record-header",
            "fqn": "diagnosticCodes.hdrGroup",
            "gIndex": "1",
            "gChildren": [
              "table_name",
              "table_profession",
              "table_day",
              "table_time"
            ]
          },
          {
            "elementKey": "cGroup37-2",
            "formCss": "grid-left-to-right-3",
            "fqn": "diagnosticCodes.cGroup37-2",
            "gIndex": "2",
            "gChildren": [
              "icd10",
              "condition",
              "code",
              "type",
              "prefix",
              "cluster"
            ]
          }
        ],
        "ehr_data": {
          "table_name": "",
          "table_profession": "",
          "table_day": "",
          "table_time": "",
          "condition": "",
          "code": "",
          "type": "",
          "prefix": "",
          "cluster": ""
        }
      },
      "fqn": "diagnosticCodes.table",
      "tableChildren": [
        "table_id",
        "table_day",
        "table_time",
        "icd10",
        "condition",
        "code",
        "type",
        "prefix",
        "cluster",
        "table_name",
        "table_profession"
      ],
      "hasRecHeader": true
    }
  },
  "path": "/ehr/external",
  "routeName": "diagnostic-codes",
  "label": "Diagnostic codes (ICD-10)",
  "redirectEhr": "",
  "redirectLis": "",
  "ehr": "yes",
  "fullPageKey": "external.diagnosticCodes"
}
export default DEFS