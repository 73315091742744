/* eslint-disable */
const DEFS = {
  "pageDataKey": "history",
  "pIndex": "105",
  "pageChildren": [],
  "pageElements": {},
  "path": "/ehr/patient",
  "routeName": "history",
  "label": "History",
  "generateComponent": "custom",
  "lis": "yes",
  "ehr": "yes",
  "fullPageKey": "patient.history"
}
export default DEFS