/* eslint-disable */
const DEFS = {
  "pageDataKey": "pediatrics",
  "pIndex": "71",
  "pageChildren": [],
  "pageElements": {},
  "path": "/ehr/peri-ped",
  "routeName": "pediatrics",
  "label": "Pediatrics",
  "generateComponent": "custom",
  "featureFlag": "ffPed",
  "ehr": "yes",
  "fullPageKey": "periPed.pediatrics"
}
export default DEFS