/* eslint-disable */
const DEFS = {
  "pageDataKey": "current",
  "pIndex": "100",
  "pageChildren": [],
  "pageElements": {},
  "path": "/ehr",
  "routeName": "current",
  "label": "Current visit",
  "generateComponent": "custom",
  "lis": "yes",
  "ehr": "yes",
  "fullPageKey": "current"
}
export default DEFS