/* eslint-disable */
const DEFS = {
  "pageDataKey": "pain",
  "pIndex": "20",
  "hasGridTable": true,
  "pageChildren": [
    {
      "elementKey": "table_name",
      "formIndex": "1",
      "inputType": "practitionerName",
      "tableColumn": "1",
      "recHeader": true,
      "fqn": "pain.name"
    },
    {
      "elementKey": "table_id",
      "inputType": "generatedId",
      "tableColumn": 0,
      "label": "Row id",
      "tableCss": "row-id"
    },
    {
      "elementKey": "table_profession",
      "formIndex": "1",
      "inputType": "practitionerProfession",
      "tableColumn": "1",
      "recHeader": true,
      "fqn": "pain.profession"
    },
    {
      "elementKey": "table_day",
      "formIndex": "1",
      "inputType": "visitDay",
      "mandatory": true,
      "tableColumn": "1",
      "validation": "visitDay",
      "recHeader": true,
      "fqn": "pain.day"
    },
    {
      "elementKey": "table_time",
      "formIndex": "1",
      "inputType": "visitTime",
      "mandatory": true,
      "tableColumn": "1",
      "validation": "time24",
      "recHeader": true,
      "fqn": "pain.time"
    },
    {
      "elementKey": "location",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "2",
      "fqn": "pain.location"
    },
    {
      "elementKey": "painScale",
      "formIndex": "1",
      "inputType": "text",
      "suffix": "/10",
      "tableColumn": "3",
      "fqn": "pain.painScale",
      "suffixText": "/10"
    },
    {
      "elementKey": "respiratoryRate",
      "formIndex": "1",
      "inputType": "text",
      "suffix": "br/min",
      "tableColumn": "4",
      "fqn": "pain.respiratoryRate",
      "suffixText": "br/min"
    },
    {
      "elementKey": "onset",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "5",
      "fqn": "pain.onset"
    },
    {
      "elementKey": "type",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "6",
      "fqn": "pain.type"
    },
    {
      "elementKey": "alleviationAssociatedSymptoms",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "7",
      "fqn": "pain.alleviationAssociatedSymptoms"
    },
    {
      "elementKey": "alleviation",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "8",
      "fqn": "pain.alleviation"
    },
    {
      "elementKey": "radiation",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "8",
      "fqn": "pain.radiation"
    },
    {
      "elementKey": "precipitatingEvents",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "9",
      "fqn": "pain.precipitatingEvents"
    },
    {
      "elementKey": "comments",
      "formIndex": "1",
      "formCss": "grid-span-3-columns",
      "inputType": "textarea",
      "tableColumn": "10",
      "fqn": "pain.comments"
    },
    {
      "elementKey": "location2",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "11",
      "fqn": "pain.location2"
    },
    {
      "elementKey": "painScale2",
      "formIndex": "1",
      "inputType": "text",
      "suffix": "/10",
      "tableColumn": "12",
      "fqn": "pain.painScale2",
      "suffixText": "/10"
    },
    {
      "elementKey": "onset2",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "13",
      "fqn": "pain.onset2"
    },
    {
      "elementKey": "type2",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "14",
      "fqn": "pain.type2"
    },
    {
      "elementKey": "alleviationAssociatedSymptoms2",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "15",
      "fqn": "pain.alleviationAssociatedSymptoms2"
    },
    {
      "elementKey": "alleviation2",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "16",
      "fqn": "pain.alleviation2"
    },
    {
      "elementKey": "radiation2",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "17",
      "fqn": "pain.radiation2"
    },
    {
      "elementKey": "precipitatingEvents2",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "18",
      "fqn": "pain.precipitatingEvents2"
    },
    {
      "elementKey": "comments2",
      "formIndex": "1",
      "formCss": "grid-span-3-columns",
      "inputType": "textarea",
      "tableColumn": "19",
      "fqn": "pain.comments2"
    }
  ],
  "pageElements": {
    "table": {
      "elementKey": "table",
      "pageElementIndex": "1",
      "pageKey": "pain",
      "tableKey": "table",
      "isTable": true,
      "form": {
        "elementKey": "table",
        "label": "Pain assessment",
        "addButtonText": "Add a pain assessment",
        "formOption": "transpose",
        "formKey": "table",
        "ehr_groups": [
          {
            "elementKey": "hdrGroup",
            "formCss": "record-header",
            "fqn": "pain.hdrGroup",
            "gIndex": "1",
            "gChildren": [
              "table_name",
              "table_profession",
              "table_day",
              "table_time"
            ]
          },
          {
            "elementKey": "cGroup20-2",
            "formCss": "grid-left-to-right-3",
            "fqn": "pain.cGroup20-2",
            "gIndex": "2",
            "gChildren": [
              "location",
              "painScale",
              "respiratoryRate",
              "onset",
              "type",
              "alleviationAssociatedSymptoms",
              "alleviation",
              "radiation",
              "precipitatingEvents",
              "comments"
            ]
          },
          {
            "elementKey": "cGroup20-3",
            "formCss": "grid-left-to-right-3",
            "fqn": "pain.cGroup20-3",
            "gIndex": "3",
            "gChildren": [
              "location2",
              "painScale2",
              "onset2",
              "type2",
              "alleviationAssociatedSymptoms2",
              "alleviation2",
              "radiation2",
              "precipitatingEvents2",
              "comments2"
            ]
          }
        ],
        "ehr_data": {
          "table_name": "",
          "table_profession": "",
          "table_day": "",
          "table_time": "",
          "location": "",
          "painScale": "",
          "respiratoryRate": "",
          "onset": "",
          "type": "",
          "alleviationAssociatedSymptoms": "",
          "alleviation": "",
          "radiation": "",
          "precipitatingEvents": "",
          "comments": "",
          "location2": "",
          "painScale2": "",
          "onset2": "",
          "type2": "",
          "alleviationAssociatedSymptoms2": "",
          "alleviation2": "",
          "radiation2": "",
          "precipitatingEvents2": "",
          "comments2": ""
        }
      },
      "fqn": "pain.table",
      "tableChildren": [
        "table_id",
        "table_day",
        "table_time",
        "location",
        "painScale",
        "respiratoryRate",
        "onset",
        "type",
        "alleviationAssociatedSymptoms",
        "alleviation",
        "radiation",
        "precipitatingEvents",
        "comments",
        "location2",
        "painScale2",
        "onset2",
        "type2",
        "alleviationAssociatedSymptoms2",
        "alleviation2",
        "radiation2",
        "precipitatingEvents2",
        "comments2",
        "table_name",
        "table_profession"
      ],
      "hasRecHeader": true
    }
  },
  "path": "/ehr/current/assessments",
  "routeName": "pain",
  "label": "Pain assessment",
  "redirectEhr": "",
  "redirectLis": "",
  "ehr": "yes",
  "fullPageKey": "current.assessments.pain"
}
export default DEFS