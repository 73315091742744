/* eslint-disable */
const DEFS = {
  "pageDataKey": "pedPram",
  "pIndex": "74",
  "hasGridTable": true,
  "pageChildren": [
    {
      "elementKey": "pedPramForm_name",
      "formIndex": "1",
      "inputType": "practitionerName",
      "tableColumn": "1",
      "recHeader": true,
      "fqn": "pedPram.name"
    },
    {
      "elementKey": "pedPramForm_id",
      "inputType": "generatedId",
      "tableColumn": 0,
      "label": "Row id",
      "tableCss": "row-id"
    },
    {
      "elementKey": "pedPramForm_profession",
      "formIndex": "1",
      "inputType": "practitionerProfession",
      "tableColumn": "1",
      "recHeader": true,
      "fqn": "pedPram.profession"
    },
    {
      "elementKey": "pedPramForm_day",
      "formIndex": "1",
      "inputType": "visitDay",
      "mandatory": true,
      "tableColumn": "1",
      "validation": "visitDay",
      "recHeader": true,
      "fqn": "pedPram.day"
    },
    {
      "elementKey": "pedPramForm_time",
      "formIndex": "1",
      "inputType": "visitTime",
      "mandatory": true,
      "tableColumn": "1",
      "validation": "time24",
      "recHeader": true,
      "fqn": "pedPram.time"
    },
    {
      "elementKey": "predPramO2SatRoomAir",
      "formIndex": "1",
      "inputType": "radioset",
      "options": [
        {
          "key": "0",
          "text": "0 = 95% or greater"
        },
        {
          "key": "1",
          "text": "1 = 92-94%"
        },
        {
          "key": "2",
          "text": "2 = less than 92%"
        }
      ],
      "passToFunction": "predPramPRAMScore",
      "tableColumn": "3",
      "fqn": "pedPram.predPramO2SatRoomAir"
    },
    {
      "elementKey": "predPramSuprasternalRet",
      "formIndex": "1",
      "inputType": "radioset",
      "options": [
        {
          "key": "0",
          "text": "0 = absent"
        },
        {
          "key": "2",
          "text": "2 = present"
        }
      ],
      "passToFunction": "predPramPRAMScore",
      "tableColumn": "4",
      "fqn": "pedPram.predPramSuprasternalRet"
    },
    {
      "elementKey": "predPramScaleneContraction",
      "formIndex": "1",
      "inputType": "radioset",
      "options": [
        {
          "key": "0",
          "text": "0 = absent"
        },
        {
          "key": "2",
          "text": "2 = present"
        }
      ],
      "passToFunction": "predPramPRAMScore",
      "tableColumn": "5",
      "fqn": "pedPram.predPramScaleneContraction"
    },
    {
      "elementKey": "predPramAirEntry",
      "formIndex": "1",
      "inputType": "radioset",
      "options": [
        {
          "key": "0",
          "text": "0 = normal"
        },
        {
          "key": "1",
          "text": "1 = decreased at bases"
        },
        {
          "key": "2",
          "text": "2 = decreased at apex and base"
        },
        {
          "key": "3",
          "text": "3 =absent/minimal"
        }
      ],
      "passToFunction": "predPramPRAMScore",
      "tableColumn": "6",
      "fqn": "pedPram.predPramAirEntry"
    },
    {
      "elementKey": "predPramWheezing",
      "formIndex": "1",
      "inputType": "radioset",
      "options": [
        {
          "key": "0",
          "text": "0 = absent"
        },
        {
          "key": "1",
          "text": "1 = expiratory only"
        },
        {
          "key": "2",
          "text": "2 = inspiratory +/- expiratory"
        },
        {
          "key": "3",
          "text": "3 = audible/silent chest"
        }
      ],
      "passToFunction": "predPramPRAMScore",
      "tableColumn": "7",
      "fqn": "pedPram.predPramWheezing"
    },
    {
      "elementKey": "predPramPRAMScore",
      "calculationType": "sum",
      "formIndex": "1",
      "inputType": "calculatedValue",
      "tableColumn": "8",
      "fqn": "pedPram.predPramPRAMScore"
    },
    {
      "elementKey": "predPramPRAMInterpret",
      "formIndex": "1",
      "inputType": "radioset",
      "options": [
        {
          "key": "mild",
          "text": "0-3 = mild"
        },
        {
          "key": "moderate",
          "text": "4-7 = moderate"
        },
        {
          "key": "severe",
          "text": "8-12 = severe"
        }
      ],
      "tableColumn": "9",
      "fqn": "pedPram.predPramPRAMInterpret"
    },
    {
      "elementKey": "predPramComments",
      "formIndex": "1",
      "inputType": "textarea",
      "tableColumn": "11",
      "fqn": "pedPram.predPramComments"
    },
    {
      "elementKey": "predPramCitation",
      "formIndex": "1",
      "inputType": "formInfoText",
      "tableColumn": "13",
      "fqn": "pedPram.predPramCitation"
    }
  ],
  "pageElements": {
    "pedPramForm": {
      "elementKey": "pedPramForm",
      "pageElementIndex": "1",
      "pageKey": "pedPram",
      "tableKey": "pedPramForm",
      "isTable": true,
      "form": {
        "elementKey": "pedPramForm",
        "label": "Respiratory Airway Measure  Peds",
        "addButtonText": "Add a Respiratory Airway Measure  Peds assessment",
        "formKey": "pedPramForm",
        "ehr_groups": [
          {
            "elementKey": "pedPramHdrGrp",
            "formCss": "record-header",
            "fqn": "pedPram.pedPramHdrGrp",
            "gIndex": "1",
            "gChildren": [
              "pedPramForm_name",
              "pedPramForm_profession",
              "pedPramForm_day",
              "pedPramForm_time"
            ]
          },
          {
            "elementKey": "pedPramGrp",
            "formCss": "ehr-row-flow",
            "fqn": "pedPram.pedPramGrp",
            "gIndex": "2",
            "gChildren": [
              "predPramO2SatRoomAir",
              "predPramSuprasternalRet",
              "predPramScaleneContraction",
              "predPramAirEntry",
              "predPramWheezing",
              "predPramPRAMScore",
              "predPramPRAMInterpret"
            ]
          },
          {
            "elementKey": "predPramGrp2",
            "formCss": "grid-left-to-right-1",
            "fqn": "pedPram.predPramGrp2",
            "gIndex": "3",
            "gChildren": [
              "predPramComments"
            ]
          },
          {
            "elementKey": "predPramGrp3",
            "formCss": "grid-left-to-right-1",
            "fqn": "pedPram.predPramGrp3",
            "gIndex": "4",
            "gChildren": [
              "predPramCitation"
            ]
          }
        ],
        "ehr_data": {
          "pedPramForm_name": "",
          "pedPramForm_profession": "",
          "pedPramForm_day": "",
          "pedPramForm_time": "",
          "predPramO2SatRoomAir": "",
          "predPramSuprasternalRet": "",
          "predPramScaleneContraction": "",
          "predPramAirEntry": "",
          "predPramWheezing": "",
          "predPramPRAMScore": "",
          "predPramPRAMInterpret": "",
          "predPramComments": ""
        }
      },
      "fqn": "pedPram.pedPramForm",
      "tableChildren": [
        "pedPramForm_id",
        "pedPramForm_day",
        "pedPramForm_time",
        "predPramO2SatRoomAir",
        "predPramSuprasternalRet",
        "predPramScaleneContraction",
        "predPramAirEntry",
        "predPramWheezing",
        "predPramPRAMScore",
        "predPramPRAMInterpret",
        "predPramComments",
        "predPramCitation",
        "pedPramForm_name",
        "pedPramForm_profession"
      ],
      "hasRecHeader": true
    }
  },
  "path": "/ehr/peri-ped/pediatrics",
  "routeName": "ped-pram",
  "label": "PRAM",
  "generateComponent": "v2page",
  "featureFlag": "ffPed",
  "ehr": "yes",
  "fullPageKey": "periPed.pediatrics.pedPram"
}
export default DEFS