/* eslint-disable */
const DEFS = {
  "pageDataKey": "visit",
  "pIndex": "10",
  "hasGridTable": true,
  "hasGridForm": true,
  "pageChildren": [
    {
      "elementKey": "admissionDay",
      "defaultValue": "0",
      "formIndex": "1",
      "inputType": "visitDay",
      "mandatory": "TRUE",
      "validation": "visitDay",
      "fqn": "visit.admissionDay"
    },
    {
      "elementKey": "admissionTime",
      "defaultValue": "1000",
      "formIndex": "1",
      "inputType": "visitTime",
      "mandatory": "TRUE",
      "validation": "time24",
      "fqn": "visit.admissionTime"
    },
    {
      "elementKey": "status",
      "formIndex": "1",
      "inputType": "select",
      "mandatory": "TRUE",
      "options": [
        {
          "key": "Admission in progress",
          "text": "Admission in progress"
        },
        {
          "key": "Admitted",
          "text": "Admitted"
        },
        {
          "key": "Inpatient",
          "text": "Inpatient"
        },
        {
          "key": "Outpatient",
          "text": "Outpatient"
        },
        {
          "key": "Surgical day care",
          "text": "Surgical day care"
        },
        {
          "key": "Discharge pending",
          "text": "Discharge pending"
        },
        {
          "key": "Discharged",
          "text": "Discharged"
        }
      ],
      "fqn": "visit.status"
    },
    {
      "elementKey": "locationLabel",
      "formIndex": "1",
      "inputType": "form_label",
      "fqn": "visit.locationLabel"
    },
    {
      "elementKey": "consentForTreatment",
      "formIndex": "1",
      "inputType": "checkbox",
      "fqn": "visit.consentForTreatment"
    },
    {
      "elementKey": "consentForBlood",
      "formIndex": "1",
      "inputType": "checkbox",
      "fqn": "visit.consentForBlood"
    },
    {
      "elementKey": "codeStatus",
      "formIndex": "1",
      "inputType": "text",
      "fqn": "visit.codeStatus"
    },
    {
      "elementKey": "diagnosis",
      "formIndex": "1",
      "inputType": "textarea",
      "fqn": "visit.diagnosis"
    },
    {
      "elementKey": "notes",
      "formIndex": "1",
      "inputType": "textarea",
      "fqn": "visit.notes"
    },
    {
      "elementKey": "diagnosisTable_name",
      "formIndex": "2",
      "inputType": "practitionerName",
      "tableColumn": "1",
      "recHeader": true,
      "fqn": "visit.name"
    },
    {
      "elementKey": "diagnosisTable_id",
      "inputType": "generatedId",
      "tableColumn": 0,
      "label": "Row id",
      "tableCss": "row-id"
    },
    {
      "elementKey": "diagnosisTable_profession",
      "formIndex": "2",
      "inputType": "practitionerProfession",
      "tableColumn": "1",
      "recHeader": true,
      "fqn": "visit.profession"
    },
    {
      "elementKey": "diagnosisTable_day",
      "formIndex": "2",
      "inputType": "visitDay",
      "mandatory": true,
      "tableColumn": "1",
      "validation": "visitDay",
      "recHeader": true,
      "fqn": "visit.day"
    },
    {
      "elementKey": "diagnosisTable_time",
      "formIndex": "2",
      "inputType": "visitTime",
      "mandatory": true,
      "tableColumn": "1",
      "validation": "time24",
      "recHeader": true,
      "fqn": "visit.time"
    },
    {
      "elementKey": "diagnosisRecord",
      "formIndex": "2",
      "inputType": "text",
      "tableColumn": "2",
      "fqn": "visit.diagnosisRecord"
    },
    {
      "elementKey": "diagnosisDetails",
      "formIndex": "2",
      "inputType": "textarea",
      "tableColumn": "3",
      "fqn": "visit.diagnosisDetails"
    },
    {
      "elementKey": "aroScreen_name",
      "formIndex": "3",
      "inputType": "practitionerName",
      "tableColumn": "1",
      "recHeader": true,
      "fqn": "visit.name"
    },
    {
      "elementKey": "aroScreen_id",
      "inputType": "generatedId",
      "tableColumn": 0,
      "label": "Row id",
      "tableCss": "row-id"
    },
    {
      "elementKey": "aroScreen_profession",
      "formIndex": "3",
      "inputType": "practitionerProfession",
      "tableColumn": "1",
      "recHeader": true,
      "fqn": "visit.profession"
    },
    {
      "elementKey": "aroScreen_day",
      "formIndex": "3",
      "inputType": "visitDay",
      "mandatory": true,
      "tableColumn": "1",
      "validation": "visitDay",
      "recHeader": true,
      "fqn": "visit.day"
    },
    {
      "elementKey": "aroScreen_time",
      "formIndex": "3",
      "inputType": "visitTime",
      "mandatory": true,
      "tableColumn": "1",
      "validation": "time24",
      "recHeader": true,
      "fqn": "visit.time"
    },
    {
      "elementKey": "aroForm",
      "formIndex": "3",
      "formOption": "aro-screening",
      "inputType": "custom_form",
      "tableColumn": "2",
      "fqn": "visit.aroForm"
    },
    {
      "elementKey": "table_name",
      "formIndex": "4",
      "inputType": "practitionerName",
      "tableColumn": "1",
      "recHeader": true,
      "fqn": "visit.name"
    },
    {
      "elementKey": "table_id",
      "inputType": "generatedId",
      "tableColumn": 0,
      "label": "Row id",
      "tableCss": "row-id"
    },
    {
      "elementKey": "table_profession",
      "formIndex": "4",
      "inputType": "practitionerProfession",
      "tableColumn": "1",
      "recHeader": true,
      "fqn": "visit.profession"
    },
    {
      "elementKey": "table_day",
      "formIndex": "4",
      "inputType": "visitDay",
      "mandatory": true,
      "tableColumn": "1",
      "validation": "visitDay",
      "recHeader": true,
      "fqn": "visit.day"
    },
    {
      "elementKey": "table_time",
      "formIndex": "4",
      "inputType": "visitTime",
      "mandatory": true,
      "tableColumn": "1",
      "validation": "time24",
      "recHeader": true,
      "fqn": "visit.time"
    },
    {
      "elementKey": "location",
      "formIndex": "4",
      "inputType": "text",
      "mandatory": "TRUE",
      "tableColumn": "2",
      "fqn": "visit.location"
    },
    {
      "elementKey": "transferInDay",
      "formIndex": "4",
      "inputType": "visitDay",
      "tableColumn": "3",
      "validation": "visitDay",
      "fqn": "visit.transferInDay"
    },
    {
      "elementKey": "transferInTime",
      "formIndex": "4",
      "inputType": "visitTime",
      "tableColumn": "4",
      "validation": "time24",
      "fqn": "visit.transferInTime"
    },
    {
      "elementKey": "aSpacer",
      "formIndex": "4",
      "inputType": "spacer",
      "fqn": "visit.aSpacer"
    },
    {
      "elementKey": "transferOutDay",
      "formIndex": "4",
      "inputType": "visitDay",
      "tableColumn": "5",
      "validation": "visitDay",
      "fqn": "visit.transferOutDay"
    },
    {
      "elementKey": "transferOutTime",
      "formIndex": "4",
      "inputType": "visitTime",
      "tableColumn": "6",
      "validation": "time24",
      "fqn": "visit.transferOutTime"
    }
  ],
  "pageElements": {
    "pageForm": {
      "elementKey": "pageForm",
      "pageElementIndex": "1",
      "formKey": "pageForm",
      "fqn": "visit.pageForm",
      "isPageForm": true,
      "ehr_groups": [
        {
          "elementKey": "cGroup",
          "formCss": "grid-left-to-right-3",
          "fqn": "visit.cGroup",
          "gIndex": "1",
          "gChildren": [
            "admissionDay",
            "admissionTime",
            "status",
            "locationLabel",
            "consentForTreatment",
            "consentForBlood",
            "codeStatus"
          ]
        },
        {
          "elementKey": "cGroup2",
          "formCss": "grid-left-to-right-1",
          "fqn": "visit.cGroup2",
          "gIndex": "2",
          "gChildren": [
            "diagnosis",
            "notes"
          ]
        }
      ]
    },
    "diagnosisTable": {
      "elementKey": "diagnosisTable",
      "pageElementIndex": "2",
      "pageKey": "visit",
      "tableKey": "diagnosisTable",
      "isTable": true,
      "form": {
        "elementKey": "diagnosisTable",
        "label": "Diagnosis",
        "addButtonText": "Add a diagnosis record",
        "formKey": "diagnosisTable",
        "ehr_groups": [
          {
            "elementKey": "hdrGroup",
            "formCss": "record-header",
            "fqn": "visit.hdrGroup",
            "gIndex": "1",
            "gChildren": [
              "diagnosisTable_name",
              "diagnosisTable_profession",
              "diagnosisTable_day",
              "diagnosisTable_time"
            ]
          },
          {
            "elementKey": "cGroup3",
            "formCss": "grid-left-to-right-1",
            "fqn": "visit.cGroup3",
            "gIndex": "2",
            "gChildren": [
              "diagnosisRecord",
              "diagnosisDetails"
            ]
          }
        ],
        "ehr_data": {
          "diagnosisTable_name": "",
          "diagnosisTable_profession": "",
          "diagnosisTable_day": "",
          "diagnosisTable_time": "",
          "diagnosisRecord": "",
          "diagnosisDetails": ""
        }
      },
      "fqn": "visit.diagnosisTable",
      "tableChildren": [
        "diagnosisTable_id",
        "diagnosisTable_day",
        "diagnosisTable_time",
        "diagnosisRecord",
        "diagnosisDetails",
        "diagnosisTable_name",
        "diagnosisTable_profession"
      ],
      "hasRecHeader": true
    },
    "aroScreen": {
      "elementKey": "aroScreen",
      "pageElementIndex": "3",
      "pageKey": "visit",
      "tableKey": "aroScreen",
      "isTable": true,
      "form": {
        "elementKey": "aroScreen",
        "label": "ARO Screening",
        "addButtonText": "Add ARO screen",
        "formKey": "aroScreen",
        "ehr_groups": [
          {
            "elementKey": "aro-1",
            "formCss": "record-header",
            "fqn": "visit.aro-1",
            "gIndex": "1",
            "gChildren": [
              "aroScreen_name",
              "aroScreen_profession",
              "aroScreen_day",
              "aroScreen_time"
            ]
          },
          {
            "elementKey": "aro-2",
            "formCss": "grid-left-to-right-1",
            "fqn": "visit.aro-2",
            "gIndex": "2",
            "gChildren": [
              "aroForm"
            ]
          }
        ],
        "ehr_data": {
          "aroScreen_name": "",
          "aroScreen_profession": "",
          "aroScreen_day": "",
          "aroScreen_time": "",
          "aroForm": ""
        }
      },
      "fqn": "visit.aroScreen",
      "tableChildren": [
        "aroScreen_id",
        "aroScreen_day",
        "aroScreen_time",
        "aroForm",
        "aroScreen_name",
        "aroScreen_profession"
      ],
      "hasRecHeader": true
    },
    "table": {
      "elementKey": "table",
      "pageElementIndex": "4",
      "pageKey": "visit",
      "tableKey": "table",
      "isTable": true,
      "form": {
        "elementKey": "table",
        "label": "Patient Location",
        "addButtonText": "Add a patient location",
        "formKey": "table",
        "ehr_groups": [
          {
            "elementKey": "hdrGroup",
            "formCss": "record-header",
            "fqn": "visit.hdrGroup",
            "gIndex": "1",
            "gChildren": [
              "table_name",
              "table_profession",
              "table_day",
              "table_time"
            ]
          },
          {
            "elementKey": "cGroup",
            "formCss": "grid-left-to-right-1",
            "fqn": "visit.cGroup",
            "gIndex": "2",
            "gChildren": [
              "location"
            ]
          },
          {
            "elementKey": "cGroup2",
            "formCss": "grid-left-to-right-3",
            "fqn": "visit.cGroup2",
            "gIndex": "3",
            "gChildren": [
              "transferInDay",
              "transferInTime",
              "aSpacer",
              "transferOutDay",
              "transferOutTime"
            ]
          }
        ],
        "ehr_data": {
          "table_name": "",
          "table_profession": "",
          "table_day": "",
          "table_time": "",
          "location": "",
          "transferInDay": "",
          "transferInTime": "",
          "transferOutDay": "",
          "transferOutTime": ""
        }
      },
      "fqn": "visit.table",
      "tableChildren": [
        "table_id",
        "table_day",
        "table_time",
        "location",
        "transferInDay",
        "transferInTime",
        "transferOutDay",
        "transferOutTime",
        "table_name",
        "table_profession"
      ],
      "hasRecHeader": true
    }
  },
  "path": "/ehr/current",
  "routeName": "visit-details",
  "label": "Admission, diagnosis, location",
  "redirectEhr": "",
  "redirectLis": "",
  "ehr": "yes",
  "fullPageKey": "current.visit"
}
export default DEFS