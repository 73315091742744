/* eslint-disable */
const DEFS = {
  "pageDataKey": "genitourinary",
  "pIndex": "18",
  "hasGridTable": true,
  "pageChildren": [
    {
      "elementKey": "table_name",
      "formIndex": "1",
      "inputType": "practitionerName",
      "tableColumn": "1",
      "recHeader": true,
      "fqn": "genitourinary.name"
    },
    {
      "elementKey": "table_id",
      "inputType": "generatedId",
      "tableColumn": 0,
      "label": "Row id",
      "tableCss": "row-id"
    },
    {
      "elementKey": "table_profession",
      "formIndex": "1",
      "inputType": "practitionerProfession",
      "tableColumn": "1",
      "recHeader": true,
      "fqn": "genitourinary.profession"
    },
    {
      "elementKey": "table_day",
      "formIndex": "1",
      "inputType": "visitDay",
      "mandatory": true,
      "tableColumn": "1",
      "validation": "visitDay",
      "recHeader": true,
      "fqn": "genitourinary.day"
    },
    {
      "elementKey": "table_time",
      "formIndex": "1",
      "inputType": "visitTime",
      "mandatory": true,
      "tableColumn": "1",
      "validation": "time24",
      "recHeader": true,
      "fqn": "genitourinary.time"
    },
    {
      "elementKey": "urinaryGroup1",
      "formIndex": "1",
      "inputType": "checkset",
      "options": [
        {
          "key": "none",
          "text": "None"
        },
        {
          "key": "increasedFrequency",
          "text": "Increased frequency"
        },
        {
          "key": "dysuria",
          "text": "Dysuria"
        },
        {
          "key": "hermaturia",
          "text": "Hermaturia"
        },
        {
          "key": "oliguria",
          "text": "Oliguria"
        },
        {
          "key": "incontinence",
          "text": "Incontinence"
        },
        {
          "key": "retention",
          "text": "Retention"
        },
        {
          "key": "distention",
          "text": "Distention"
        }
      ],
      "tableColumn": "2",
      "fqn": "genitourinary.urinaryGroup1"
    },
    {
      "elementKey": "lastVoidedDay",
      "formIndex": "1",
      "inputType": "visitDay",
      "tableColumn": "3",
      "validation": "visitDay",
      "fqn": "genitourinary.lastVoidedDay"
    },
    {
      "elementKey": "lastVoidedTime",
      "formIndex": "1",
      "inputType": "visitTime",
      "tableColumn": "4",
      "validation": "time24",
      "fqn": "genitourinary.lastVoidedTime"
    },
    {
      "elementKey": "colour",
      "formIndex": "1",
      "inputType": "select",
      "options": [
        {
          "key": "Pale yellow",
          "text": "Pale yellow"
        },
        {
          "key": "Dark yellow",
          "text": "Dark yellow"
        },
        {
          "key": "Amber",
          "text": "Amber"
        },
        {
          "key": "Red/blood",
          "text": "Red/blood"
        },
        {
          "key": "Other",
          "text": "Other"
        }
      ],
      "tableColumn": "5",
      "fqn": "genitourinary.colour"
    },
    {
      "elementKey": "consistency",
      "formIndex": "1",
      "inputType": "select",
      "options": [
        {
          "key": "Clear",
          "text": "Clear"
        },
        {
          "key": "Hazy",
          "text": "Hazy"
        },
        {
          "key": "Cloudy",
          "text": "Cloudy"
        },
        {
          "key": "Turbid",
          "text": "Turbid"
        }
      ],
      "tableColumn": "6",
      "fqn": "genitourinary.consistency"
    },
    {
      "elementKey": "diaper",
      "formIndex": "1",
      "inputType": "select",
      "options": [
        {
          "key": "Yes",
          "text": "Yes"
        },
        {
          "key": "No",
          "text": "No"
        }
      ],
      "tableColumn": "7",
      "fqn": "genitourinary.diaper"
    },
    {
      "elementKey": "toilettingMethod",
      "formIndex": "1",
      "inputType": "checkset",
      "options": [
        {
          "key": "bedpan",
          "text": "Bedpan"
        },
        {
          "key": "commode",
          "text": "Commode"
        },
        {
          "key": "urinal",
          "text": "Urinal"
        },
        {
          "key": "helpToTheToilet",
          "text": "Help to the toilet"
        },
        {
          "key": "brief",
          "text": "Brief"
        },
        {
          "key": "meshPant/pad",
          "text": "Mesh pant/pad"
        },
        {
          "key": "cleanIntermittentCatheter",
          "text": "Clean intermittent catheter"
        },
        {
          "key": "indwellingCatheter",
          "text": "Indwelling catheter"
        }
      ],
      "tableColumn": "8",
      "fqn": "genitourinary.toilettingMethod"
    },
    {
      "elementKey": "foley",
      "formIndex": "1",
      "inputType": "select",
      "options": [
        {
          "key": "Yes",
          "text": "Yes"
        },
        {
          "key": "No",
          "text": "No"
        }
      ],
      "tableColumn": "9",
      "fqn": "genitourinary.foley"
    },
    {
      "elementKey": "foleyType",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "10",
      "fqn": "genitourinary.foleyType"
    },
    {
      "elementKey": "foleySize",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "11",
      "fqn": "genitourinary.foleySize"
    },
    {
      "elementKey": "lastChange",
      "formIndex": "1",
      "inputType": "visitDay",
      "tableColumn": "12",
      "validation": "visitDay",
      "fqn": "genitourinary.lastChange"
    },
    {
      "elementKey": "lastChangeTime",
      "formIndex": "1",
      "inputType": "visitTime",
      "tableColumn": "13",
      "validation": "time24",
      "fqn": "genitourinary.lastChangeTime"
    },
    {
      "elementKey": "pelvicPain",
      "formIndex": "1",
      "inputType": "select",
      "options": [
        {
          "key": "Yes",
          "text": "Yes"
        },
        {
          "key": "No",
          "text": "No"
        }
      ],
      "tableColumn": "14",
      "fqn": "genitourinary.pelvicPain"
    },
    {
      "elementKey": "location",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "15",
      "fqn": "genitourinary.location"
    },
    {
      "elementKey": "pelvicPainSource",
      "formIndex": "1",
      "inputType": "select",
      "options": [
        {
          "key": "Observed",
          "text": "Observed"
        },
        {
          "key": "As per patient observed",
          "text": "As per patient observed"
        },
        {
          "key": "Not observed",
          "text": "Not observed"
        }
      ],
      "tableColumn": "16",
      "fqn": "genitourinary.pelvicPainSource"
    },
    {
      "elementKey": "aLabel",
      "formIndex": "1",
      "inputType": "form_label",
      "fqn": "genitourinary.aLabel"
    },
    {
      "elementKey": "discharge",
      "formIndex": "1",
      "inputType": "checkset",
      "options": [
        {
          "key": "discharge",
          "text": "Discharge"
        },
        {
          "key": "bleeding",
          "text": "Bleeding"
        }
      ],
      "tableColumn": "17",
      "fqn": "genitourinary.discharge"
    },
    {
      "elementKey": "lastMenstrualPeriodDate",
      "formIndex": "1",
      "inputType": "date",
      "tableColumn": "18",
      "fqn": "genitourinary.lastMenstrualPeriodDate"
    },
    {
      "elementKey": "description",
      "formIndex": "1",
      "formCss": "grid-span-3-columns",
      "inputType": "text",
      "tableColumn": "19",
      "fqn": "genitourinary.description"
    },
    {
      "elementKey": "pregnant",
      "formIndex": "1",
      "inputType": "select",
      "options": [
        {
          "key": "Yes",
          "text": "Yes"
        },
        {
          "key": "No",
          "text": "No"
        },
        {
          "key": "Unknown",
          "text": "Unknown"
        }
      ],
      "tableColumn": "20",
      "fqn": "genitourinary.pregnant"
    },
    {
      "elementKey": "gravida",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "21",
      "fqn": "genitourinary.gravida"
    },
    {
      "elementKey": "para",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "22",
      "fqn": "genitourinary.para"
    },
    {
      "elementKey": "livingChildren",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "23",
      "fqn": "genitourinary.livingChildren"
    },
    {
      "elementKey": "comments",
      "formIndex": "1",
      "inputType": "textarea",
      "tableColumn": "24",
      "fqn": "genitourinary.comments"
    }
  ],
  "pageElements": {
    "table": {
      "elementKey": "table",
      "pageElementIndex": "1",
      "pageKey": "genitourinary",
      "tableKey": "table",
      "isTable": true,
      "form": {
        "elementKey": "table",
        "label": "Genitourinary assessment",
        "addButtonText": "Add a genitourinary assessment",
        "formOption": "transpose",
        "formKey": "table",
        "ehr_groups": [
          {
            "elementKey": "hdrGroup",
            "formCss": "record-header",
            "fqn": "genitourinary.hdrGroup",
            "gIndex": "1",
            "gChildren": [
              "table_name",
              "table_profession",
              "table_day",
              "table_time"
            ]
          },
          {
            "elementKey": "urinaryGroup2",
            "formCss": "grid-left-to-right-3",
            "fqn": "genitourinary.urinaryGroup2",
            "gIndex": "2",
            "gChildren": [
              "urinaryGroup1",
              "lastVoidedDay",
              "lastVoidedTime",
              "colour",
              "consistency",
              "diaper",
              "toilettingMethod"
            ]
          },
          {
            "elementKey": "foleyGroup",
            "formCss": "grid-left-to-right-3",
            "fqn": "genitourinary.foleyGroup",
            "gIndex": "3",
            "gChildren": [
              "foley",
              "foleyType",
              "foleySize",
              "lastChange",
              "lastChangeTime"
            ]
          },
          {
            "elementKey": "pelvicGroup",
            "formCss": "grid-left-to-right-3",
            "fqn": "genitourinary.pelvicGroup",
            "gIndex": "4",
            "gChildren": [
              "pelvicPain",
              "location",
              "pelvicPainSource",
              "aLabel"
            ]
          },
          {
            "elementKey": "vaginal",
            "formCss": "grid-left-to-right-3",
            "fqn": "genitourinary.vaginal",
            "gIndex": "5",
            "gChildren": [
              "discharge",
              "lastMenstrualPeriodDate",
              "description"
            ]
          },
          {
            "elementKey": "pregnantGroup",
            "formCss": "grid-left-to-right-3",
            "fqn": "genitourinary.pregnantGroup",
            "gIndex": "6",
            "gChildren": [
              "pregnant",
              "gravida",
              "para",
              "livingChildren"
            ]
          },
          {
            "elementKey": "cGroup18-7",
            "formCss": "grid-left-to-right-1",
            "fqn": "genitourinary.cGroup18-7",
            "gIndex": "7",
            "gChildren": [
              "comments"
            ]
          }
        ],
        "ehr_data": {
          "table_name": "",
          "table_profession": "",
          "table_day": "",
          "table_time": "",
          "urinaryGroup1": "",
          "lastVoidedDay": "",
          "lastVoidedTime": "",
          "colour": "",
          "consistency": "",
          "diaper": "",
          "toilettingMethod": "",
          "foley": "",
          "foleyType": "",
          "foleySize": "",
          "lastChange": "",
          "lastChangeTime": "",
          "pelvicPain": "",
          "location": "",
          "pelvicPainSource": "",
          "discharge": "",
          "lastMenstrualPeriodDate": "",
          "description": "",
          "pregnant": "",
          "gravida": "",
          "para": "",
          "livingChildren": "",
          "comments": ""
        }
      },
      "fqn": "genitourinary.table",
      "tableChildren": [
        "table_id",
        "table_day",
        "table_time",
        "urinaryGroup1",
        "lastVoidedDay",
        "lastVoidedTime",
        "colour",
        "consistency",
        "diaper",
        "toilettingMethod",
        "foley",
        "foleyType",
        "foleySize",
        "lastChange",
        "lastChangeTime",
        "pelvicPain",
        "location",
        "pelvicPainSource",
        "discharge",
        "lastMenstrualPeriodDate",
        "description",
        "pregnant",
        "gravida",
        "para",
        "livingChildren",
        "comments",
        "table_name",
        "table_profession"
      ],
      "hasRecHeader": true
    }
  },
  "path": "/ehr/current/assessments",
  "routeName": "genitourinary",
  "label": "Genitourinary assessment",
  "redirectEhr": "",
  "redirectLis": "",
  "ehr": "yes",
  "fullPageKey": "current.assessments.genitourinary"
}
export default DEFS