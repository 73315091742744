/* eslint-disable */
const DEFS = {
  "pageDataKey": "consentsPage",
  "pIndex": "56",
  "hasGridTable": true,
  "pageChildren": [
    {
      "elementKey": "consentTreatTable_name",
      "formIndex": "1",
      "inputType": "practitionerName",
      "tableColumn": "1",
      "recHeader": true,
      "fqn": "consentsPage.name"
    },
    {
      "elementKey": "consentTreatTable_id",
      "inputType": "generatedId",
      "tableColumn": 0,
      "label": "Row id",
      "tableCss": "row-id"
    },
    {
      "elementKey": "consentTreatTable_profession",
      "formIndex": "1",
      "inputType": "practitionerProfession",
      "tableColumn": "1",
      "recHeader": true,
      "fqn": "consentsPage.profession"
    },
    {
      "elementKey": "consentTreatTable_day",
      "formIndex": "1",
      "inputType": "visitDay",
      "mandatory": true,
      "tableColumn": "1",
      "validation": "visitDay",
      "recHeader": true,
      "fqn": "consentsPage.day"
    },
    {
      "elementKey": "consentTreatTable_time",
      "formIndex": "1",
      "inputType": "visitTime",
      "mandatory": true,
      "tableColumn": "1",
      "validation": "time24",
      "recHeader": true,
      "fqn": "consentsPage.time"
    },
    {
      "elementKey": "consentTreatForm",
      "formIndex": "1",
      "formOption": "consent_for_treatment",
      "inputType": "custom_form",
      "tableColumn": "2",
      "fqn": "consentsPage.consentTreatForm"
    },
    {
      "elementKey": "consentBloodTable_name",
      "formIndex": "2",
      "inputType": "practitionerName",
      "tableColumn": "1",
      "recHeader": true,
      "fqn": "consentsPage.name"
    },
    {
      "elementKey": "consentBloodTable_id",
      "inputType": "generatedId",
      "tableColumn": 0,
      "label": "Row id",
      "tableCss": "row-id"
    },
    {
      "elementKey": "consentBloodTable_profession",
      "formIndex": "2",
      "inputType": "practitionerProfession",
      "tableColumn": "1",
      "recHeader": true,
      "fqn": "consentsPage.profession"
    },
    {
      "elementKey": "consentBloodTable_day",
      "formIndex": "2",
      "inputType": "visitDay",
      "mandatory": true,
      "tableColumn": "1",
      "validation": "visitDay",
      "recHeader": true,
      "fqn": "consentsPage.day"
    },
    {
      "elementKey": "consentBloodTable_time",
      "formIndex": "2",
      "inputType": "visitTime",
      "mandatory": true,
      "tableColumn": "1",
      "validation": "time24",
      "recHeader": true,
      "fqn": "consentsPage.time"
    },
    {
      "elementKey": "consentBloodForm",
      "formIndex": "2",
      "formOption": "consent_for_blood",
      "inputType": "custom_form",
      "tableColumn": "2",
      "fqn": "consentsPage.consentBloodForm"
    }
  ],
  "pageElements": {
    "consentTreatTable": {
      "elementKey": "consentTreatTable",
      "pageElementIndex": "1",
      "pageKey": "consentsPage",
      "tableKey": "consentTreatTable",
      "isTable": true,
      "form": {
        "elementKey": "consentTreatTable",
        "label": "Consent for treatment",
        "addButtonText": "Add Consent for treatment",
        "formKey": "consentTreatTable",
        "ehr_groups": [
          {
            "elementKey": "consentTreatHdrGrp",
            "formCss": "record-header",
            "fqn": "consentsPage.consentTreatHdrGrp",
            "gIndex": "1",
            "gChildren": [
              "consentTreatTable_name",
              "consentTreatTable_profession",
              "consentTreatTable_day",
              "consentTreatTable_time"
            ]
          },
          {
            "elementKey": "consentTreatGroup",
            "formCss": "grid-left-to-right-1",
            "fqn": "consentsPage.consentTreatGroup",
            "gIndex": "2",
            "gChildren": [
              "consentTreatForm"
            ]
          }
        ],
        "ehr_data": {
          "consentTreatTable_name": "",
          "consentTreatTable_profession": "",
          "consentTreatTable_day": "",
          "consentTreatTable_time": "",
          "consentTreatForm": ""
        }
      },
      "fqn": "consentsPage.consentTreatTable",
      "tableChildren": [
        "consentTreatTable_id",
        "consentTreatTable_day",
        "consentTreatTable_time",
        "consentTreatForm",
        "consentTreatTable_name",
        "consentTreatTable_profession"
      ],
      "hasRecHeader": true
    },
    "consentBloodTable": {
      "elementKey": "consentBloodTable",
      "pageElementIndex": "2",
      "pageKey": "consentsPage",
      "tableKey": "consentBloodTable",
      "isTable": true,
      "form": {
        "elementKey": "consentBloodTable",
        "label": "Consent for blood",
        "addButtonText": "Add Consent for blood products",
        "formKey": "consentBloodTable",
        "ehr_groups": [
          {
            "elementKey": "consentBloodHdrGrp",
            "formCss": "record-header",
            "fqn": "consentsPage.consentBloodHdrGrp",
            "gIndex": "1",
            "gChildren": [
              "consentBloodTable_name",
              "consentBloodTable_profession",
              "consentBloodTable_day",
              "consentBloodTable_time"
            ]
          },
          {
            "elementKey": "consentBloodGroup",
            "formCss": "grid-left-to-right-1",
            "fqn": "consentsPage.consentBloodGroup",
            "gIndex": "2",
            "gChildren": [
              "consentBloodForm"
            ]
          }
        ],
        "ehr_data": {
          "consentBloodTable_name": "",
          "consentBloodTable_profession": "",
          "consentBloodTable_day": "",
          "consentBloodTable_time": "",
          "consentBloodForm": ""
        }
      },
      "fqn": "consentsPage.consentBloodTable",
      "tableChildren": [
        "consentBloodTable_id",
        "consentBloodTable_day",
        "consentBloodTable_time",
        "consentBloodForm",
        "consentBloodTable_name",
        "consentBloodTable_profession"
      ],
      "hasRecHeader": true
    }
  },
  "path": "/ehr/current",
  "routeName": "consents",
  "label": "Consents",
  "ehr": "yes",
  "fullPageKey": "patient.consentsPage"
}
export default DEFS