/* eslint-disable */
const DEFS = [
  "acpMost",
  "allergies",
  "assessmentTools",
  "assessments",
  "billing",
  "biopsychosocial",
  "cardiovascular",
  "carePlan",
  "careTeam",
  "caseMixGroup",
  "chart",
  "checklistsPage",
  "codeLookup",
  "consentsPage",
  "consults",
  "current",
  "demographics",
  "diagnosticCodes",
  "dischargeSummary",
  "dishargeplan",
  "external",
  "familyHistory",
  "fluidBalance",
  "gastrointestinal",
  "genitourinary",
  "hematology",
  "history",
  "immunization",
  "integumentaryAssessment",
  "interventionCodes",
  "labRequisitions",
  "labResults",
  "leveyJennings",
  "linesDevices",
  "matAntenatal",
  "matDischargeEd",
  "matPostpartum",
  "matSummary",
  "maternity",
  "medAdminRec",
  "medLab",
  "medLabAccession",
  "medLabBody",
  "medLabChemistry",
  "medLabPhoneLog",
  "medLabUrinalysis",
  "medical",
  "medicalImaging",
  "medicationOrders",
  "musculoskeletal",
  "neurological",
  "newborn",
  "newbornBath",
  "newbornComprehensive",
  "newbornDischargeEd",
  "newbornFeeding",
  "newbornInOut",
  "newbornMeasure",
  "newbornRec",
  "newbornScreening",
  "newbornVitals",
  "nonmedOrders",
  "operationReports",
  "orderSets",
  "orders",
  "pain",
  "pastAppointments",
  "pedAdmission",
  "pedHourly",
  "pedInOut",
  "pedMeasure",
  "pedPain",
  "pedPram",
  "pedSystem",
  "pedVitals",
  "pediatrics",
  "periPed",
  "progressNotes",
  "psychosocial",
  "referrals",
  "reports",
  "respiratory",
  "riskAssessment",
  "specialIndicators",
  "surgical",
  "visit",
  "vitals"
]
export default DEFS