/* eslint-disable */
const DEFS = {
  "pageDataKey": "pedMeasure",
  "pIndex": "79",
  "hasGridTable": true,
  "pageChildren": [
    {
      "elementKey": "pedMesForm_name",
      "formIndex": "1",
      "inputType": "practitionerName",
      "tableColumn": "1",
      "recHeader": true,
      "fqn": "pedMeasure.name"
    },
    {
      "elementKey": "pedMesForm_id",
      "inputType": "generatedId",
      "tableColumn": 0,
      "label": "Row id",
      "tableCss": "row-id"
    },
    {
      "elementKey": "pedMesForm_profession",
      "formIndex": "1",
      "inputType": "practitionerProfession",
      "tableColumn": "1",
      "recHeader": true,
      "fqn": "pedMeasure.profession"
    },
    {
      "elementKey": "pedMesForm_day",
      "formIndex": "1",
      "inputType": "visitDay",
      "mandatory": true,
      "tableColumn": "1",
      "validation": "visitDay",
      "recHeader": true,
      "fqn": "pedMeasure.day"
    },
    {
      "elementKey": "pedMesForm_time",
      "formIndex": "1",
      "inputType": "visitTime",
      "mandatory": true,
      "tableColumn": "1",
      "validation": "time24",
      "recHeader": true,
      "fqn": "pedMeasure.time"
    },
    {
      "elementKey": "pedMesWeight",
      "formIndex": "1",
      "inputType": "number",
      "suffix": "kg",
      "tableColumn": "3",
      "fqn": "pedMeasure.pedMesWeight",
      "suffixText": "kg"
    },
    {
      "elementKey": "pedMesWeightChange",
      "formIndex": "1",
      "inputType": "number",
      "suffix": "kg",
      "tableColumn": "4",
      "fqn": "pedMeasure.pedMesWeightChange",
      "suffixText": "kg"
    },
    {
      "elementKey": "pedMesDosingWeight",
      "formIndex": "1",
      "inputType": "number",
      "suffix": "kg",
      "tableColumn": "5",
      "fqn": "pedMeasure.pedMesDosingWeight",
      "suffixText": "kg"
    },
    {
      "elementKey": "pedMesHeight",
      "formIndex": "1",
      "inputType": "number",
      "suffix": "cm",
      "tableColumn": "6",
      "fqn": "pedMeasure.pedMesHeight",
      "suffixText": "cm"
    },
    {
      "elementKey": "pedMesHeadCircumference",
      "formIndex": "1",
      "inputType": "number",
      "suffix": "cm",
      "tableColumn": "7",
      "fqn": "pedMeasure.pedMesHeadCircumference",
      "suffixText": "cm"
    },
    {
      "elementKey": "pedMesArmCircumference",
      "formIndex": "1",
      "inputType": "number",
      "suffix": "cm",
      "tableColumn": "8",
      "fqn": "pedMeasure.pedMesArmCircumference",
      "suffixText": "cm"
    },
    {
      "elementKey": "pedMesAbdominalGirth",
      "formIndex": "1",
      "inputType": "number",
      "suffix": "cm",
      "tableColumn": "9",
      "fqn": "pedMeasure.pedMesAbdominalGirth",
      "suffixText": "cm"
    },
    {
      "elementKey": "pedMesComments",
      "formIndex": "1",
      "inputType": "textarea",
      "tableColumn": "11",
      "fqn": "pedMeasure.pedMesComments"
    }
  ],
  "pageElements": {
    "pedMesForm": {
      "elementKey": "pedMesForm",
      "pageElementIndex": "1",
      "pageKey": "pedMeasure",
      "tableKey": "pedMesForm",
      "isTable": true,
      "form": {
        "elementKey": "pedMesForm",
        "label": "Measurement Records Peds",
        "addButtonText": "Add a Measurement Records Peds assessment",
        "formKey": "pedMesForm",
        "ehr_groups": [
          {
            "elementKey": "pedMesHdrGrp",
            "formCss": "record-header",
            "fqn": "pedMeasure.pedMesHdrGrp",
            "gIndex": "1",
            "gChildren": [
              "pedMesForm_name",
              "pedMesForm_profession",
              "pedMesForm_day",
              "pedMesForm_time"
            ]
          },
          {
            "elementKey": "pedMesGrp1",
            "formCss": "ehr-row-flow",
            "fqn": "pedMeasure.pedMesGrp1",
            "gIndex": "2",
            "gChildren": [
              "pedMesWeight",
              "pedMesWeightChange",
              "pedMesDosingWeight",
              "pedMesHeight",
              "pedMesHeadCircumference",
              "pedMesArmCircumference",
              "pedMesAbdominalGirth"
            ]
          },
          {
            "elementKey": "pedMesCommentsGrp",
            "formCss": "grid-left-to-right-1",
            "fqn": "pedMeasure.pedMesCommentsGrp",
            "gIndex": "3",
            "gChildren": [
              "pedMesComments"
            ]
          }
        ],
        "ehr_data": {
          "pedMesForm_name": "",
          "pedMesForm_profession": "",
          "pedMesForm_day": "",
          "pedMesForm_time": "",
          "pedMesWeight": "",
          "pedMesWeightChange": "",
          "pedMesDosingWeight": "",
          "pedMesHeight": "",
          "pedMesHeadCircumference": "",
          "pedMesArmCircumference": "",
          "pedMesAbdominalGirth": "",
          "pedMesComments": ""
        }
      },
      "fqn": "pedMeasure.pedMesForm",
      "tableChildren": [
        "pedMesForm_id",
        "pedMesForm_day",
        "pedMesForm_time",
        "pedMesWeight",
        "pedMesWeightChange",
        "pedMesDosingWeight",
        "pedMesHeight",
        "pedMesHeadCircumference",
        "pedMesArmCircumference",
        "pedMesAbdominalGirth",
        "pedMesComments",
        "pedMesForm_name",
        "pedMesForm_profession"
      ],
      "hasRecHeader": true
    }
  },
  "path": "/ehr/peri-ped/pediatrics",
  "routeName": "ped-measure",
  "label": "Measurement Records Peds",
  "generateComponent": "v2page",
  "featureFlag": "ffPed",
  "ehr": "yes",
  "fullPageKey": "periPed.pediatrics.pedMeasure"
}
export default DEFS