/* eslint-disable */
const DEFS = {
  "pageDataKey": "orders",
  "pIndex": "101",
  "pageChildren": [],
  "pageElements": {},
  "path": "/ehr/current",
  "routeName": "orders",
  "label": "Orders",
  "generateComponent": "custom",
  "lis": "yes",
  "ehr": "yes",
  "fullPageKey": "current.orders"
}
export default DEFS