/* eslint-disable */
const DEFS = {
  "pageDataKey": "leveyJennings",
  "pIndex": "53",
  "hasGridTable": true,
  "pageChildren": [
    {
      "elementKey": "tableLJ_name",
      "formIndex": "1",
      "inputType": "practitionerName",
      "tableColumn": "1",
      "recHeader": true,
      "fqn": "leveyJennings.name"
    },
    {
      "elementKey": "tableLJ_id",
      "inputType": "generatedId",
      "tableColumn": 0,
      "label": "Row id",
      "tableCss": "row-id"
    },
    {
      "elementKey": "tableLJ_profession",
      "formIndex": "1",
      "inputType": "practitionerProfession",
      "tableColumn": "1",
      "recHeader": true,
      "fqn": "leveyJennings.profession"
    },
    {
      "elementKey": "tableLJ_day",
      "formIndex": "1",
      "inputType": "visitDay",
      "mandatory": true,
      "tableColumn": "1",
      "validation": "visitDay",
      "recHeader": true,
      "fqn": "leveyJennings.day"
    },
    {
      "elementKey": "tableLJ_time",
      "formIndex": "1",
      "inputType": "visitTime",
      "mandatory": true,
      "tableColumn": "1",
      "validation": "time24",
      "recHeader": true,
      "fqn": "leveyJennings.time"
    },
    {
      "elementKey": "ljAnalyte",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "2",
      "fqn": "leveyJennings.ljAnalyte"
    },
    {
      "elementKey": "ljUseControl",
      "formIndex": "1",
      "inputType": "checkbox",
      "tableColumn": "3",
      "fqn": "leveyJennings.ljUseControl"
    },
    {
      "elementKey": "ljControl",
      "formIndex": "1",
      "inputType": "number",
      "tableColumn": "4",
      "fqn": "leveyJennings.ljControl"
    },
    {
      "elementKey": "ljStdDev",
      "formIndex": "1",
      "inputType": "decimal2",
      "tableColumn": "5",
      "fqn": "leveyJennings.ljStdDev"
    },
    {
      "elementKey": "ljValue1",
      "formIndex": "1",
      "inputType": "number",
      "tableColumn": "6",
      "fqn": "leveyJennings.ljValue1"
    },
    {
      "elementKey": "ljValue2",
      "formIndex": "1",
      "inputType": "number",
      "tableColumn": "7",
      "fqn": "leveyJennings.ljValue2"
    },
    {
      "elementKey": "ljValue3",
      "formIndex": "1",
      "inputType": "number",
      "tableColumn": "8",
      "fqn": "leveyJennings.ljValue3"
    },
    {
      "elementKey": "ljValue4",
      "formIndex": "1",
      "inputType": "number",
      "tableColumn": "9",
      "fqn": "leveyJennings.ljValue4"
    },
    {
      "elementKey": "ljValue5",
      "formIndex": "1",
      "inputType": "number",
      "tableColumn": "10",
      "fqn": "leveyJennings.ljValue5"
    },
    {
      "elementKey": "ljValue6",
      "formIndex": "1",
      "inputType": "number",
      "tableColumn": "11",
      "fqn": "leveyJennings.ljValue6"
    },
    {
      "elementKey": "ljValue7",
      "formIndex": "1",
      "inputType": "number",
      "tableColumn": "12",
      "fqn": "leveyJennings.ljValue7"
    },
    {
      "elementKey": "ljValue8",
      "formIndex": "1",
      "inputType": "number",
      "tableColumn": "13",
      "fqn": "leveyJennings.ljValue8"
    },
    {
      "elementKey": "ljValue9",
      "formIndex": "1",
      "inputType": "number",
      "tableColumn": "14",
      "fqn": "leveyJennings.ljValue9"
    },
    {
      "elementKey": "ljValue10",
      "formIndex": "1",
      "inputType": "number",
      "tableColumn": "15",
      "fqn": "leveyJennings.ljValue10"
    },
    {
      "elementKey": "ljValue11",
      "formIndex": "1",
      "inputType": "number",
      "tableColumn": "16",
      "fqn": "leveyJennings.ljValue11"
    },
    {
      "elementKey": "ljValue12",
      "formIndex": "1",
      "inputType": "number",
      "tableColumn": "17",
      "fqn": "leveyJennings.ljValue12"
    },
    {
      "elementKey": "ljValue13",
      "formIndex": "1",
      "inputType": "number",
      "tableColumn": "18",
      "fqn": "leveyJennings.ljValue13"
    },
    {
      "elementKey": "ljValue14",
      "formIndex": "1",
      "inputType": "number",
      "tableColumn": "19",
      "fqn": "leveyJennings.ljValue14"
    },
    {
      "elementKey": "ljValue15",
      "formIndex": "1",
      "inputType": "number",
      "tableColumn": "20",
      "fqn": "leveyJennings.ljValue15"
    },
    {
      "elementKey": "ljValue16",
      "formIndex": "1",
      "inputType": "number",
      "tableColumn": "21",
      "fqn": "leveyJennings.ljValue16"
    },
    {
      "elementKey": "ljValue17",
      "formIndex": "1",
      "inputType": "number",
      "tableColumn": "22",
      "fqn": "leveyJennings.ljValue17"
    },
    {
      "elementKey": "ljValue18",
      "formIndex": "1",
      "inputType": "number",
      "tableColumn": "23",
      "fqn": "leveyJennings.ljValue18"
    },
    {
      "elementKey": "ljValue19",
      "formIndex": "1",
      "inputType": "number",
      "tableColumn": "24",
      "fqn": "leveyJennings.ljValue19"
    },
    {
      "elementKey": "ljValue20",
      "formIndex": "1",
      "inputType": "number",
      "tableColumn": "25",
      "fqn": "leveyJennings.ljValue20"
    },
    {
      "elementKey": "ljValue21",
      "formIndex": "1",
      "inputType": "number",
      "tableColumn": "26",
      "fqn": "leveyJennings.ljValue21"
    },
    {
      "elementKey": "ljValue22",
      "formIndex": "1",
      "inputType": "number",
      "tableColumn": "27",
      "fqn": "leveyJennings.ljValue22"
    },
    {
      "elementKey": "ljValue23",
      "formIndex": "1",
      "inputType": "number",
      "tableColumn": "28",
      "fqn": "leveyJennings.ljValue23"
    },
    {
      "elementKey": "ljValue24",
      "formIndex": "1",
      "inputType": "number",
      "tableColumn": "29",
      "fqn": "leveyJennings.ljValue24"
    },
    {
      "elementKey": "ljValue25",
      "formIndex": "1",
      "inputType": "number",
      "tableColumn": "30",
      "fqn": "leveyJennings.ljValue25"
    },
    {
      "elementKey": "ljValue26",
      "formIndex": "1",
      "inputType": "number",
      "tableColumn": "31",
      "fqn": "leveyJennings.ljValue26"
    },
    {
      "elementKey": "ljValue27",
      "formIndex": "1",
      "inputType": "number",
      "tableColumn": "32",
      "fqn": "leveyJennings.ljValue27"
    },
    {
      "elementKey": "ljValue28",
      "formIndex": "1",
      "inputType": "number",
      "tableColumn": "33",
      "fqn": "leveyJennings.ljValue28"
    },
    {
      "elementKey": "ljValue29",
      "formIndex": "1",
      "inputType": "number",
      "tableColumn": "34",
      "fqn": "leveyJennings.ljValue29"
    },
    {
      "elementKey": "ljValue30",
      "formIndex": "1",
      "inputType": "number",
      "tableColumn": "35",
      "fqn": "leveyJennings.ljValue30"
    },
    {
      "elementKey": "ljcomments",
      "formIndex": "1",
      "formCss": "grid-span-3-columns",
      "inputType": "textarea",
      "tableColumn": "36",
      "fqn": "leveyJennings.ljcomments"
    }
  ],
  "pageElements": {
    "tableLJ": {
      "elementKey": "tableLJ",
      "pageElementIndex": "1",
      "pageKey": "leveyJennings",
      "tableKey": "tableLJ",
      "isTable": true,
      "form": {
        "elementKey": "tableLJ",
        "label": "Levey-Jennings",
        "addButtonText": "Add a Levey-Jennings Chart",
        "formOption": "openToEdit",
        "formKey": "tableLJ",
        "ehr_groups": [
          {
            "elementKey": "hdrGroup",
            "formCss": "record-header",
            "fqn": "leveyJennings.hdrGroup",
            "gIndex": "1",
            "gChildren": [
              "tableLJ_name",
              "tableLJ_profession",
              "tableLJ_day",
              "tableLJ_time"
            ]
          },
          {
            "elementKey": "ljGroup1",
            "formCss": "grid-left-to-right-3",
            "fqn": "leveyJennings.ljGroup1",
            "gIndex": "2",
            "gChildren": [
              "ljAnalyte",
              "ljUseControl",
              "ljControl",
              "ljStdDev",
              "ljValue1",
              "ljValue2",
              "ljValue3",
              "ljValue4",
              "ljValue5",
              "ljValue6",
              "ljValue7",
              "ljValue8",
              "ljValue9",
              "ljValue10",
              "ljValue11",
              "ljValue12",
              "ljValue13",
              "ljValue14",
              "ljValue15",
              "ljValue16",
              "ljValue17",
              "ljValue18",
              "ljValue19",
              "ljValue20",
              "ljValue21",
              "ljValue22",
              "ljValue23",
              "ljValue24",
              "ljValue25",
              "ljValue26",
              "ljValue27",
              "ljValue28",
              "ljValue29",
              "ljValue30",
              "ljcomments"
            ]
          }
        ],
        "ehr_data": {
          "tableLJ_name": "",
          "tableLJ_profession": "",
          "tableLJ_day": "",
          "tableLJ_time": "",
          "ljAnalyte": "",
          "ljUseControl": "",
          "ljControl": "",
          "ljStdDev": "",
          "ljValue1": "",
          "ljValue2": "",
          "ljValue3": "",
          "ljValue4": "",
          "ljValue5": "",
          "ljValue6": "",
          "ljValue7": "",
          "ljValue8": "",
          "ljValue9": "",
          "ljValue10": "",
          "ljValue11": "",
          "ljValue12": "",
          "ljValue13": "",
          "ljValue14": "",
          "ljValue15": "",
          "ljValue16": "",
          "ljValue17": "",
          "ljValue18": "",
          "ljValue19": "",
          "ljValue20": "",
          "ljValue21": "",
          "ljValue22": "",
          "ljValue23": "",
          "ljValue24": "",
          "ljValue25": "",
          "ljValue26": "",
          "ljValue27": "",
          "ljValue28": "",
          "ljValue29": "",
          "ljValue30": "",
          "ljcomments": ""
        }
      },
      "fqn": "leveyJennings.tableLJ",
      "tableChildren": [
        "tableLJ_id",
        "tableLJ_day",
        "tableLJ_time",
        "ljAnalyte",
        "ljUseControl",
        "ljControl",
        "ljStdDev",
        "ljValue1",
        "ljValue2",
        "ljValue3",
        "ljValue4",
        "ljValue5",
        "ljValue6",
        "ljValue7",
        "ljValue8",
        "ljValue9",
        "ljValue10",
        "ljValue11",
        "ljValue12",
        "ljValue13",
        "ljValue14",
        "ljValue15",
        "ljValue16",
        "ljValue17",
        "ljValue18",
        "ljValue19",
        "ljValue20",
        "ljValue21",
        "ljValue22",
        "ljValue23",
        "ljValue24",
        "ljValue25",
        "ljValue26",
        "ljValue27",
        "ljValue28",
        "ljValue29",
        "ljValue30",
        "ljcomments",
        "tableLJ_name",
        "tableLJ_profession"
      ],
      "hasRecHeader": true
    }
  },
  "path": "/ehr/med-lab",
  "routeName": "levey-jennings",
  "label": "Levey-Jennings",
  "generateComponent": "custom",
  "lis": "yes",
  "fullPageKey": "medLab.leveyJennings"
}
export default DEFS