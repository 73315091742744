/* eslint-disable */
const DEFS = {
  "pageDataKey": "cardiovascular",
  "pIndex": "16",
  "hasGridTable": true,
  "pageChildren": [
    {
      "elementKey": "table_name",
      "formIndex": "1",
      "inputType": "practitionerName",
      "tableColumn": "1",
      "recHeader": true,
      "fqn": "cardiovascular.name"
    },
    {
      "elementKey": "table_id",
      "inputType": "generatedId",
      "tableColumn": 0,
      "label": "Row id",
      "tableCss": "row-id"
    },
    {
      "elementKey": "table_profession",
      "formIndex": "1",
      "inputType": "practitionerProfession",
      "tableColumn": "1",
      "recHeader": true,
      "fqn": "cardiovascular.profession"
    },
    {
      "elementKey": "table_day",
      "formIndex": "1",
      "inputType": "visitDay",
      "mandatory": true,
      "tableColumn": "1",
      "validation": "visitDay",
      "recHeader": true,
      "fqn": "cardiovascular.day"
    },
    {
      "elementKey": "table_time",
      "formIndex": "1",
      "inputType": "visitTime",
      "mandatory": true,
      "tableColumn": "1",
      "validation": "time24",
      "recHeader": true,
      "fqn": "cardiovascular.time"
    },
    {
      "elementKey": "pulse",
      "formIndex": "1",
      "inputType": "select",
      "options": [
        {
          "key": "Regular",
          "text": "Regular"
        },
        {
          "key": "Irregular",
          "text": "Irregular"
        },
        {
          "key": "Normal",
          "text": "Normal"
        },
        {
          "key": "Weak",
          "text": "Weak"
        },
        {
          "key": "Bounding",
          "text": "Bounding"
        }
      ],
      "tableColumn": "2",
      "fqn": "cardiovascular.pulse"
    },
    {
      "elementKey": "skinAppearance",
      "formIndex": "1",
      "inputType": "select",
      "options": [
        {
          "key": "Normal",
          "text": "Appropriate for ethnicity"
        },
        {
          "key": "clammy",
          "text": "Clammy"
        },
        {
          "key": "dry",
          "text": "Dry"
        },
        {
          "key": "Pale",
          "text": "Pale"
        },
        {
          "key": "Mottled",
          "text": "Mottled"
        },
        {
          "key": "Cyanotic",
          "text": "Cyanotic"
        },
        {
          "key": "Flushed",
          "text": "Flushed"
        },
        {
          "key": "Jaundiced",
          "text": "Jaundiced"
        }
      ],
      "tableColumn": "3",
      "fqn": "cardiovascular.skinAppearance"
    },
    {
      "elementKey": "spacer1",
      "formIndex": "1",
      "inputType": "spacer",
      "fqn": "cardiovascular.spacer1"
    },
    {
      "elementKey": "labelCapRefill",
      "formIndex": "1",
      "inputType": "form_label",
      "fqn": "cardiovascular.labelCapRefill"
    },
    {
      "elementKey": "labelTemperature",
      "formIndex": "1",
      "inputType": "form_label",
      "fqn": "cardiovascular.labelTemperature"
    },
    {
      "elementKey": "labelPeripheral",
      "formIndex": "1",
      "inputType": "form_label",
      "fqn": "cardiovascular.labelPeripheral"
    },
    {
      "elementKey": "labelNailBed",
      "formIndex": "1",
      "inputType": "form_label",
      "fqn": "cardiovascular.labelNailBed"
    },
    {
      "elementKey": "cardLabel2",
      "formIndex": "1",
      "formCss": "bold-text",
      "inputType": "form_label",
      "fqn": "cardiovascular.cardLabel2"
    },
    {
      "elementKey": "capRefillLeftHand",
      "formIndex": "1",
      "formOption": "hideLabel",
      "inputType": "select",
      "options": [
        {
          "key": "normal",
          "text": "< 3 seconds"
        },
        {
          "key": "delayed",
          "text": "> 3 seconds"
        }
      ],
      "tableColumn": "4",
      "fqn": "cardiovascular.capRefillLeftHand"
    },
    {
      "elementKey": "temperatureRightHand",
      "formIndex": "1",
      "formOption": "hideLabel",
      "inputType": "select",
      "options": [
        {
          "key": "Warm",
          "text": "Warm"
        },
        {
          "key": "Cool",
          "text": "Cool"
        },
        {
          "key": "Hot",
          "text": "Hot"
        }
      ],
      "tableColumn": "5",
      "fqn": "cardiovascular.temperatureRightHand"
    },
    {
      "elementKey": "peripheralEdemaRightHand",
      "formIndex": "1",
      "formOption": "hideLabel",
      "inputType": "select",
      "options": [
        {
          "key": "No",
          "text": "None (0)"
        },
        {
          "key": "g1",
          "text": "1+"
        },
        {
          "key": "g2",
          "text": "2+"
        },
        {
          "key": "g3",
          "text": "3+"
        },
        {
          "key": "g4",
          "text": "4+"
        }
      ],
      "tableColumn": "6",
      "fqn": "cardiovascular.peripheralEdemaRightHand"
    },
    {
      "elementKey": "nailBedColourRightHand",
      "formIndex": "1",
      "formOption": "hideLabel",
      "inputType": "select",
      "options": [
        {
          "key": "Pink",
          "text": "Appropriate for ethnicity"
        },
        {
          "key": "Cyanotic",
          "text": "Cyanotic"
        }
      ],
      "tableColumn": "7",
      "fqn": "cardiovascular.nailBedColourRightHand"
    },
    {
      "elementKey": "cardLabel3",
      "formIndex": "1",
      "formCss": "bold-text",
      "inputType": "form_label",
      "fqn": "cardiovascular.cardLabel3"
    },
    {
      "elementKey": "capRefillRightHand",
      "formIndex": "1",
      "formOption": "hideLabel",
      "inputType": "select",
      "options": [
        {
          "key": "normal",
          "text": "< 3 seconds"
        },
        {
          "key": "delayed",
          "text": "> 3 seconds"
        }
      ],
      "tableColumn": "8",
      "fqn": "cardiovascular.capRefillRightHand"
    },
    {
      "elementKey": "temperatureLeftHand",
      "formIndex": "1",
      "formOption": "hideLabel",
      "inputType": "select",
      "options": [
        {
          "key": "Warm",
          "text": "Warm"
        },
        {
          "key": "Cool",
          "text": "Cool"
        },
        {
          "key": "Hot",
          "text": "Hot"
        }
      ],
      "tableColumn": "9",
      "fqn": "cardiovascular.temperatureLeftHand"
    },
    {
      "elementKey": "peripheralEdemaLeftHand",
      "formIndex": "1",
      "formOption": "hideLabel",
      "inputType": "select",
      "options": [
        {
          "key": "No",
          "text": "None (0)"
        },
        {
          "key": "g1",
          "text": "1+"
        },
        {
          "key": "g2",
          "text": "2+"
        },
        {
          "key": "g3",
          "text": "3+"
        },
        {
          "key": "g4",
          "text": "4+"
        }
      ],
      "tableColumn": "10",
      "fqn": "cardiovascular.peripheralEdemaLeftHand"
    },
    {
      "elementKey": "nailBedColourLeftHand",
      "formIndex": "1",
      "formOption": "hideLabel",
      "inputType": "select",
      "options": [
        {
          "key": "Pink",
          "text": "Appropriate for ethnicity"
        },
        {
          "key": "Cyanotic",
          "text": "Cyanotic"
        }
      ],
      "tableColumn": "11",
      "fqn": "cardiovascular.nailBedColourLeftHand"
    },
    {
      "elementKey": "carLabel4",
      "formIndex": "1",
      "formCss": "bold-text",
      "inputType": "form_label",
      "fqn": "cardiovascular.carLabel4"
    },
    {
      "elementKey": "capRefillRightFoot",
      "formIndex": "1",
      "formOption": "hideLabel",
      "inputType": "select",
      "options": [
        {
          "key": "normal",
          "text": "< 3 seconds"
        },
        {
          "key": "delayed",
          "text": "> 3 seconds"
        }
      ],
      "tableColumn": "12",
      "fqn": "cardiovascular.capRefillRightFoot"
    },
    {
      "elementKey": "temperatureRightFoot",
      "formIndex": "1",
      "formOption": "hideLabel",
      "inputType": "select",
      "options": [
        {
          "key": "Warm",
          "text": "Warm"
        },
        {
          "key": "Cool",
          "text": "Cool"
        },
        {
          "key": "Hot",
          "text": "Hot"
        }
      ],
      "tableColumn": "13",
      "fqn": "cardiovascular.temperatureRightFoot"
    },
    {
      "elementKey": "peripheralEdemaRightFoot",
      "formIndex": "1",
      "formOption": "hideLabel",
      "inputType": "select",
      "options": [
        {
          "key": "No",
          "text": "None (0)"
        },
        {
          "key": "g1",
          "text": "1+"
        },
        {
          "key": "g2",
          "text": "2+"
        },
        {
          "key": "g3",
          "text": "3+"
        },
        {
          "key": "g4",
          "text": "4+"
        }
      ],
      "tableColumn": "14",
      "fqn": "cardiovascular.peripheralEdemaRightFoot"
    },
    {
      "elementKey": "nailBedColourRightFoot",
      "formIndex": "1",
      "formOption": "hideLabel",
      "inputType": "select",
      "options": [
        {
          "key": "Pink",
          "text": "Appropriate for ethnicity"
        },
        {
          "key": "Cyanotic",
          "text": "Cyanotic"
        }
      ],
      "tableColumn": "15",
      "fqn": "cardiovascular.nailBedColourRightFoot"
    },
    {
      "elementKey": "cardLabel5",
      "formIndex": "1",
      "formCss": "bold-text",
      "inputType": "form_label",
      "fqn": "cardiovascular.cardLabel5"
    },
    {
      "elementKey": "capRefillLeftFoot",
      "formIndex": "1",
      "formOption": "hideLabel",
      "inputType": "select",
      "options": [
        {
          "key": "normal",
          "text": "< 3 seconds"
        },
        {
          "key": "delayed",
          "text": "> 3 seconds"
        }
      ],
      "tableColumn": "16",
      "fqn": "cardiovascular.capRefillLeftFoot"
    },
    {
      "elementKey": "temperatureLeftFoot",
      "formIndex": "1",
      "formOption": "hideLabel",
      "inputType": "select",
      "options": [
        {
          "key": "Warm",
          "text": "Warm"
        },
        {
          "key": "Cool",
          "text": "Cool"
        },
        {
          "key": "Hot",
          "text": "Hot"
        }
      ],
      "tableColumn": "17",
      "fqn": "cardiovascular.temperatureLeftFoot"
    },
    {
      "elementKey": "peripheralEdemaLeftFoot",
      "formIndex": "1",
      "formOption": "hideLabel",
      "inputType": "select",
      "options": [
        {
          "key": "No",
          "text": "None (0)"
        },
        {
          "key": "g1",
          "text": "1+"
        },
        {
          "key": "g2",
          "text": "2+"
        },
        {
          "key": "g3",
          "text": "3+"
        },
        {
          "key": "g4",
          "text": "4+"
        }
      ],
      "tableColumn": "18",
      "fqn": "cardiovascular.peripheralEdemaLeftFoot"
    },
    {
      "elementKey": "nailBedColourLeftFoot",
      "formIndex": "1",
      "formOption": "hideLabel",
      "inputType": "select",
      "options": [
        {
          "key": "Pink",
          "text": "Appropriate for ethnicity"
        },
        {
          "key": "Cyanotic",
          "text": "Cyanotic"
        }
      ],
      "tableColumn": "19",
      "fqn": "cardiovascular.nailBedColourLeftFoot"
    },
    {
      "elementKey": "comments",
      "formIndex": "1",
      "inputType": "textarea",
      "tableColumn": "20",
      "fqn": "cardiovascular.comments"
    }
  ],
  "pageElements": {
    "table": {
      "elementKey": "table",
      "pageElementIndex": "1",
      "pageKey": "cardiovascular",
      "tableKey": "table",
      "isTable": true,
      "form": {
        "elementKey": "table",
        "label": "Cardiovascular assessment",
        "addButtonText": "Add a cardiovascular assessment",
        "formOption": "transpose",
        "formKey": "table",
        "ehr_groups": [
          {
            "elementKey": "hdrGroup",
            "formCss": "record-header",
            "fqn": "cardiovascular.hdrGroup",
            "gIndex": "1",
            "gChildren": [
              "table_name",
              "table_profession",
              "table_day",
              "table_time"
            ]
          },
          {
            "elementKey": "cardGroup1",
            "formCss": "grid-left-to-right-3",
            "fqn": "cardiovascular.cardGroup1",
            "gIndex": "2",
            "gChildren": [
              "pulse",
              "skinAppearance"
            ]
          },
          {
            "elementKey": "cardGroup1",
            "formCss": "grid-left-to-right-5",
            "fqn": "cardiovascular.cardGroup1",
            "gIndex": "3",
            "gChildren": [
              "spacer1",
              "labelCapRefill",
              "labelTemperature",
              "labelPeripheral",
              "labelNailBed"
            ]
          },
          {
            "elementKey": "cardGroup2",
            "formCss": "grid-left-to-right-5 cardio-group",
            "fqn": "cardiovascular.cardGroup2",
            "gIndex": "4",
            "gChildren": [
              "cardLabel2",
              "capRefillLeftHand",
              "temperatureRightHand",
              "peripheralEdemaRightHand",
              "nailBedColourRightHand"
            ]
          },
          {
            "elementKey": "cardGroup3",
            "formCss": "grid-left-to-right-5 cardio-group",
            "fqn": "cardiovascular.cardGroup3",
            "gIndex": "5",
            "gChildren": [
              "cardLabel3",
              "capRefillRightHand",
              "temperatureLeftHand",
              "peripheralEdemaLeftHand",
              "nailBedColourLeftHand"
            ]
          },
          {
            "elementKey": "cardGroup4",
            "formCss": "grid-left-to-right-5 cardio-group",
            "fqn": "cardiovascular.cardGroup4",
            "gIndex": "6",
            "gChildren": [
              "carLabel4",
              "capRefillRightFoot",
              "temperatureRightFoot",
              "peripheralEdemaRightFoot",
              "nailBedColourRightFoot"
            ]
          },
          {
            "elementKey": "cardGroup5",
            "formCss": "grid-left-to-right-5 cardio-group",
            "fqn": "cardiovascular.cardGroup5",
            "gIndex": "7",
            "gChildren": [
              "cardLabel5",
              "capRefillLeftFoot",
              "temperatureLeftFoot",
              "peripheralEdemaLeftFoot",
              "nailBedColourLeftFoot"
            ]
          },
          {
            "elementKey": "cardGroup6",
            "formCss": "grid-left-to-right-1",
            "fqn": "cardiovascular.cardGroup6",
            "gIndex": "8",
            "gChildren": [
              "comments"
            ]
          }
        ],
        "ehr_data": {
          "table_name": "",
          "table_profession": "",
          "table_day": "",
          "table_time": "",
          "pulse": "",
          "skinAppearance": "",
          "capRefillLeftHand": "",
          "temperatureRightHand": "",
          "peripheralEdemaRightHand": "",
          "nailBedColourRightHand": "",
          "capRefillRightHand": "",
          "temperatureLeftHand": "",
          "peripheralEdemaLeftHand": "",
          "nailBedColourLeftHand": "",
          "capRefillRightFoot": "",
          "temperatureRightFoot": "",
          "peripheralEdemaRightFoot": "",
          "nailBedColourRightFoot": "",
          "capRefillLeftFoot": "",
          "temperatureLeftFoot": "",
          "peripheralEdemaLeftFoot": "",
          "nailBedColourLeftFoot": "",
          "comments": ""
        }
      },
      "fqn": "cardiovascular.table",
      "tableChildren": [
        "table_id",
        "table_day",
        "table_time",
        "pulse",
        "skinAppearance",
        "capRefillLeftHand",
        "temperatureRightHand",
        "peripheralEdemaRightHand",
        "nailBedColourRightHand",
        "capRefillRightHand",
        "temperatureLeftHand",
        "peripheralEdemaLeftHand",
        "nailBedColourLeftHand",
        "capRefillRightFoot",
        "temperatureRightFoot",
        "peripheralEdemaRightFoot",
        "nailBedColourRightFoot",
        "capRefillLeftFoot",
        "temperatureLeftFoot",
        "peripheralEdemaLeftFoot",
        "nailBedColourLeftFoot",
        "comments",
        "table_name",
        "table_profession"
      ],
      "hasRecHeader": true
    }
  },
  "path": "/ehr/current/assessments",
  "routeName": "cardiovascular",
  "label": "Cardiovascular assessment",
  "redirectEhr": "",
  "redirectLis": "",
  "ehr": "yes",
  "fullPageKey": "current.assessments.cardiovascular"
}
export default DEFS