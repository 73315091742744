/* eslint-disable */
const DEFS = {
  "pageDataKey": "demographics",
  "pIndex": "1",
  "hasGridForm": true,
  "pageChildren": [
    {
      "elementKey": "givenName",
      "formIndex": "1",
      "formCss": "grid-span-2-columns",
      "inputType": "text",
      "fqn": "demographics.givenName"
    },
    {
      "elementKey": "middleName",
      "formIndex": "1",
      "inputType": "text",
      "fqn": "demographics.middleName"
    },
    {
      "elementKey": "preferredName",
      "formIndex": "1",
      "formCss": "grid-span-2-columns",
      "inputType": "text",
      "fqn": "demographics.preferredName"
    },
    {
      "elementKey": "pronouns",
      "formIndex": "1",
      "inputType": "select",
      "options": [
        {
          "key": "she",
          "text": "She/Her/Hers"
        },
        {
          "key": "he",
          "text": "He/Him/His"
        },
        {
          "key": "they",
          "text": "They/Them/Theirs"
        },
        {
          "key": "ze",
          "text": "Ze/Hir/Hirs"
        },
        {
          "key": "none",
          "text": "I do not use a pronoun"
        },
        {
          "key": "other",
          "text": "Other, please ask"
        },
        {
          "key": "all",
          "text": "I use all gender pronouns"
        }
      ],
      "fqn": "demographics.pronouns"
    },
    {
      "elementKey": "familyName",
      "formIndex": "1",
      "formCss": "grid-span-2-columns",
      "inputType": "text",
      "fqn": "demographics.familyName"
    },
    {
      "elementKey": "mrn",
      "formIndex": "1",
      "inputType": "text",
      "fqn": "demographics.mrn"
    },
    {
      "elementKey": "personAge",
      "formIndex": "1",
      "inputType": "personAge",
      "fqn": "demographics.personAge"
    },
    {
      "elementKey": "dateOfBirth",
      "dependentOn": "age:personAge",
      "formIndex": "1",
      "inputType": "mainDOB",
      "fqn": "demographics.dateOfBirth"
    },
    {
      "elementKey": "languagePrimary",
      "formIndex": "1",
      "inputType": "select",
      "options": [
        {
          "key": "English",
          "text": "English"
        },
        {
          "key": "French",
          "text": "French"
        },
        {
          "key": "Spanish",
          "text": "Spanish"
        },
        {
          "key": "German",
          "text": "German"
        },
        {
          "key": "Chinese",
          "text": "Chinese"
        },
        {
          "key": "Other",
          "text": "Other"
        }
      ],
      "fqn": "demographics.languagePrimary"
    },
    {
      "elementKey": "birthSex",
      "formIndex": "1",
      "inputType": "select",
      "options": [
        {
          "key": "male",
          "text": "Male"
        },
        {
          "key": "fem",
          "text": "Female"
        },
        {
          "key": "inter",
          "text": "Intersex"
        }
      ],
      "fqn": "demographics.birthSex"
    },
    {
      "elementKey": "gender",
      "formIndex": "1",
      "inputType": "select",
      "options": [
        {
          "key": "Unknown",
          "text": "Unknown"
        },
        {
          "key": "Female",
          "text": "Female"
        },
        {
          "key": "Male",
          "text": "Male"
        },
        {
          "key": "Transgender female",
          "text": "Transgender female"
        },
        {
          "key": "Transgender male",
          "text": "Transgender male"
        },
        {
          "key": "Undifferentiated",
          "text": "Undifferentiated"
        },
        {
          "key": "Nonbinary",
          "text": "Nonbinary"
        },
        {
          "key": "Prefer not to say",
          "text": "Prefer not to say"
        }
      ],
      "fqn": "demographics.gender"
    },
    {
      "elementKey": "legalGender",
      "formIndex": "1",
      "inputType": "select",
      "options": [
        {
          "key": "M",
          "text": "M"
        },
        {
          "key": "F",
          "text": "F"
        },
        {
          "key": "X",
          "text": "X"
        }
      ],
      "fqn": "demographics.legalGender"
    },
    {
      "elementKey": "height",
      "formIndex": "1",
      "inputType": "text",
      "suffix": "cm",
      "fqn": "demographics.height",
      "suffixText": "cm"
    },
    {
      "elementKey": "weight",
      "formIndex": "1",
      "inputType": "text",
      "suffix": "kg",
      "fqn": "demographics.weight",
      "suffixText": "kg"
    },
    {
      "elementKey": "religion",
      "formIndex": "1",
      "inputType": "text",
      "fqn": "demographics.religion"
    },
    {
      "elementKey": "martialStatus",
      "formIndex": "1",
      "inputType": "select",
      "options": [
        {
          "key": "Married",
          "text": "Married"
        },
        {
          "key": "Single",
          "text": "Single"
        },
        {
          "key": "Life partner",
          "text": "Life partner"
        },
        {
          "key": "Divorced",
          "text": "Divorced"
        },
        {
          "key": "Separated",
          "text": "Separated"
        },
        {
          "key": "Widowed",
          "text": "Widowed"
        }
      ],
      "fqn": "demographics.martialStatus"
    },
    {
      "elementKey": "indigenousIdentifyAs",
      "formIndex": "1",
      "inputType": "select",
      "options": [
        {
          "key": "Yes",
          "text": "Yes"
        },
        {
          "key": "No",
          "text": "No"
        }
      ],
      "fqn": "demographics.indigenousIdentifyAs"
    },
    {
      "elementKey": "streetAddress",
      "formIndex": "1",
      "formCss": "grid-span-2-columns",
      "inputType": "text",
      "fqn": "demographics.streetAddress"
    },
    {
      "elementKey": "city",
      "formIndex": "1",
      "inputType": "text",
      "fqn": "demographics.city"
    },
    {
      "elementKey": "country",
      "formIndex": "1",
      "inputType": "text",
      "fqn": "demographics.country"
    },
    {
      "elementKey": "postalCode",
      "formIndex": "1",
      "inputType": "text",
      "fqn": "demographics.postalCode"
    },
    {
      "elementKey": "noAddress",
      "defaultValue": "FALSE",
      "formIndex": "1",
      "inputType": "checkbox",
      "fqn": "demographics.noAddress"
    },
    {
      "elementKey": "phoneNumber",
      "formIndex": "1",
      "inputType": "text",
      "fqn": "demographics.phoneNumber"
    },
    {
      "elementKey": "emailAddress",
      "formIndex": "1",
      "inputType": "text",
      "fqn": "demographics.emailAddress"
    },
    {
      "elementKey": "occupationStudent",
      "formIndex": "1",
      "inputType": "text",
      "fqn": "demographics.occupationStudent"
    },
    {
      "elementKey": "phn",
      "formIndex": "1",
      "inputType": "text",
      "fqn": "demographics.phn"
    },
    {
      "elementKey": "patientService",
      "formIndex": "1",
      "inputType": "text",
      "fqn": "demographics.patientService"
    },
    {
      "elementKey": "nextOfKinName",
      "formIndex": "1",
      "inputType": "text",
      "fqn": "demographics.nextOfKinName"
    },
    {
      "elementKey": "nextOfKinRelationship",
      "formIndex": "1",
      "inputType": "select",
      "options": [
        {
          "key": "Wife",
          "text": "Wife"
        },
        {
          "key": "Husband",
          "text": "Husband"
        },
        {
          "key": "Mother",
          "text": "Mother"
        },
        {
          "key": "Father",
          "text": "Father"
        },
        {
          "key": "Guardian",
          "text": "Guardian"
        },
        {
          "key": "Sister",
          "text": "Sister"
        },
        {
          "key": "Brother",
          "text": "Brother"
        },
        {
          "key": "Daughter",
          "text": "Daughter"
        },
        {
          "key": "Son",
          "text": "Son"
        },
        {
          "key": "Aunt",
          "text": "Aunt"
        },
        {
          "key": "Uncle",
          "text": "Uncle"
        },
        {
          "key": "Grandmother",
          "text": "Grandmother"
        },
        {
          "key": "Grandfather",
          "text": "Grandfather"
        },
        {
          "key": "Friend",
          "text": "Friend"
        },
        {
          "key": "Other",
          "text": "Other"
        }
      ],
      "fqn": "demographics.nextOfKinRelationship"
    },
    {
      "elementKey": "nextOfKinPhone",
      "formIndex": "1",
      "inputType": "text",
      "fqn": "demographics.nextOfKinPhone"
    },
    {
      "elementKey": "decisionMakerName",
      "formIndex": "1",
      "inputType": "text",
      "fqn": "demographics.decisionMakerName"
    },
    {
      "elementKey": "decisionMakerRelationship",
      "formIndex": "1",
      "inputType": "select",
      "options": [
        {
          "key": "Spouse",
          "text": "Spouse"
        },
        {
          "key": "Child",
          "text": "Child"
        },
        {
          "key": "Parent",
          "text": "Parent"
        },
        {
          "key": "Sibling",
          "text": "Sibling"
        },
        {
          "key": "Grandparent",
          "text": "Grandparent"
        },
        {
          "key": "Grandchild",
          "text": "Grandchild"
        },
        {
          "key": "Friend",
          "text": "Friend"
        },
        {
          "key": "Anyone else related by partnership",
          "text": "Anyone else related by partnership"
        },
        {
          "key": "Public guardian and trustee employee",
          "text": "Public guardian and trustee employee"
        },
        {
          "key": "Other",
          "text": "Other"
        }
      ],
      "fqn": "demographics.decisionMakerRelationship"
    },
    {
      "elementKey": "decisionMakerPhone",
      "formIndex": "1",
      "inputType": "text",
      "fqn": "demographics.decisionMakerPhone"
    }
  ],
  "pageElements": {
    "pageForm": {
      "elementKey": "pageForm",
      "pageElementIndex": "1",
      "formKey": "pageForm",
      "fqn": "demographics.pageForm",
      "isPageForm": true,
      "ehr_groups": [
        {
          "elementKey": "personGroup",
          "formCss": "section-divider grid-left-to-right-3",
          "fqn": "demographics.personGroup",
          "gIndex": "1",
          "gChildren": [
            "givenName",
            "middleName",
            "preferredName",
            "pronouns",
            "familyName",
            "mrn",
            "personAge",
            "dateOfBirth",
            "languagePrimary",
            "birthSex",
            "gender",
            "legalGender",
            "height",
            "weight",
            "religion",
            "martialStatus",
            "indigenousIdentifyAs"
          ]
        },
        {
          "elementKey": "addressGroup",
          "formCss": "section-divider grid-left-to-right-3",
          "fqn": "demographics.addressGroup",
          "gIndex": "2",
          "gChildren": [
            "streetAddress",
            "city",
            "country",
            "postalCode",
            "noAddress",
            "phoneNumber",
            "emailAddress",
            "occupationStudent",
            "phn",
            "patientService"
          ]
        },
        {
          "elementKey": "kinGroup",
          "formCss": "grid-left-to-right-3",
          "fqn": "demographics.kinGroup",
          "gIndex": "3",
          "gChildren": [
            "nextOfKinName",
            "nextOfKinRelationship",
            "nextOfKinPhone",
            "decisionMakerName",
            "decisionMakerRelationship",
            "decisionMakerPhone"
          ]
        }
      ]
    }
  },
  "path": "/ehr/patient",
  "routeName": "demographics",
  "label": "Demographics",
  "redirectEhr": "",
  "redirectLis": "",
  "lis": "yes",
  "ehr": "yes",
  "fullPageKey": "patient.demographics"
}
export default DEFS