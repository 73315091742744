/* eslint-disable */
const DEFS = {
  "pageDataKey": "immunization",
  "pIndex": "6",
  "hasGridForm": true,
  "pageChildren": [
    {
      "elementKey": "hepA",
      "formIndex": "1",
      "inputType": "checkbox",
      "fqn": "immunization.hepA"
    },
    {
      "elementKey": "hepADate",
      "formIndex": "1",
      "formOption": "hideLabel",
      "inputType": "date",
      "fqn": "immunization.hepADate"
    },
    {
      "elementKey": "hepASpacer",
      "formIndex": "1",
      "inputType": "spacer",
      "fqn": "immunization.hepASpacer"
    },
    {
      "elementKey": "hepB",
      "formIndex": "1",
      "inputType": "checkbox",
      "fqn": "immunization.hepB"
    },
    {
      "elementKey": "hepBDate",
      "formIndex": "1",
      "formOption": "hideLabel",
      "inputType": "date",
      "fqn": "immunization.hepBDate"
    },
    {
      "elementKey": "hepBSpacer",
      "formIndex": "1",
      "inputType": "spacer",
      "fqn": "immunization.hepBSpacer"
    },
    {
      "elementKey": "mmr",
      "formIndex": "1",
      "inputType": "checkbox",
      "fqn": "immunization.mmr"
    },
    {
      "elementKey": "mmrDate",
      "formIndex": "1",
      "formOption": "hideLabel",
      "inputType": "date",
      "fqn": "immunization.mmrDate"
    },
    {
      "elementKey": "mmrSpacer",
      "formIndex": "1",
      "inputType": "spacer",
      "fqn": "immunization.mmrSpacer"
    },
    {
      "elementKey": "pneumococcal",
      "formIndex": "1",
      "inputType": "checkbox",
      "fqn": "immunization.pneumococcal"
    },
    {
      "elementKey": "pneumococcalDate",
      "formIndex": "1",
      "formOption": "hideLabel",
      "inputType": "date",
      "fqn": "immunization.pneumococcalDate"
    },
    {
      "elementKey": "pneumococcalSpacer",
      "formIndex": "1",
      "inputType": "spacer",
      "fqn": "immunization.pneumococcalSpacer"
    },
    {
      "elementKey": "polioDiptheriaPertussis",
      "formIndex": "1",
      "inputType": "checkbox",
      "fqn": "immunization.polioDiptheriaPertussis"
    },
    {
      "elementKey": "polioDiptheriaPertussisDate",
      "formIndex": "1",
      "formOption": "hideLabel",
      "inputType": "date",
      "fqn": "immunization.polioDiptheriaPertussisDate"
    },
    {
      "elementKey": "polioDiptheriaPertussisSpacer",
      "formIndex": "1",
      "inputType": "spacer",
      "fqn": "immunization.polioDiptheriaPertussisSpacer"
    },
    {
      "elementKey": "tb",
      "formIndex": "1",
      "inputType": "checkbox",
      "fqn": "immunization.tb"
    },
    {
      "elementKey": "tbDate",
      "formIndex": "1",
      "formOption": "hideLabel",
      "inputType": "date",
      "fqn": "immunization.tbDate"
    },
    {
      "elementKey": "tbSpacer",
      "formIndex": "1",
      "inputType": "spacer",
      "fqn": "immunization.tbSpacer"
    },
    {
      "elementKey": "tetanus",
      "formIndex": "1",
      "inputType": "checkbox",
      "fqn": "immunization.tetanus"
    },
    {
      "elementKey": "tetanusDate",
      "formIndex": "1",
      "formOption": "hideLabel",
      "inputType": "date",
      "fqn": "immunization.tetanusDate"
    },
    {
      "elementKey": "tetanusSpacer",
      "formIndex": "1",
      "inputType": "spacer",
      "fqn": "immunization.tetanusSpacer"
    },
    {
      "elementKey": "covid",
      "formIndex": "1",
      "inputType": "checkbox",
      "fqn": "immunization.covid"
    },
    {
      "elementKey": "covidDate",
      "formIndex": "1",
      "formOption": "hideLabel",
      "inputType": "date",
      "fqn": "immunization.covidDate"
    },
    {
      "elementKey": "covidSpacer",
      "formIndex": "1",
      "inputType": "spacer",
      "fqn": "immunization.covidSpacer"
    },
    {
      "elementKey": "other1",
      "formIndex": "1",
      "inputType": "checkbox",
      "fqn": "immunization.other1"
    },
    {
      "elementKey": "other1Date",
      "formIndex": "1",
      "formOption": "hideLabel",
      "inputType": "date",
      "fqn": "immunization.other1Date"
    },
    {
      "elementKey": "other1Spacer",
      "formIndex": "1",
      "inputType": "spacer",
      "fqn": "immunization.other1Spacer"
    },
    {
      "elementKey": "other2",
      "formIndex": "1",
      "inputType": "checkbox",
      "fqn": "immunization.other2"
    },
    {
      "elementKey": "other2Date",
      "formIndex": "1",
      "formOption": "hideLabel",
      "inputType": "date",
      "fqn": "immunization.other2Date"
    },
    {
      "elementKey": "other2Spacer",
      "formIndex": "1",
      "inputType": "spacer",
      "fqn": "immunization.other2Spacer"
    },
    {
      "elementKey": "other3",
      "formIndex": "1",
      "inputType": "checkbox",
      "fqn": "immunization.other3"
    },
    {
      "elementKey": "other3Date",
      "formIndex": "1",
      "formOption": "hideLabel",
      "inputType": "date",
      "fqn": "immunization.other3Date"
    },
    {
      "elementKey": "other3Spacer",
      "formIndex": "1",
      "inputType": "spacer",
      "fqn": "immunization.other3Spacer"
    }
  ],
  "pageElements": {
    "pageForm": {
      "elementKey": "pageForm",
      "pageElementIndex": "1",
      "formKey": "pageForm",
      "fqn": "immunization.pageForm",
      "isPageForm": true,
      "ehr_groups": [
        {
          "elementKey": "cGroup",
          "formCss": "grid-left-to-right-3",
          "fqn": "immunization.cGroup",
          "gIndex": "1",
          "gChildren": [
            "hepA",
            "hepADate",
            "hepASpacer",
            "hepB",
            "hepBDate",
            "hepBSpacer",
            "mmr",
            "mmrDate",
            "mmrSpacer",
            "pneumococcal",
            "pneumococcalDate",
            "pneumococcalSpacer",
            "polioDiptheriaPertussis",
            "polioDiptheriaPertussisDate",
            "polioDiptheriaPertussisSpacer",
            "tb",
            "tbDate",
            "tbSpacer",
            "tetanus",
            "tetanusDate",
            "tetanusSpacer",
            "covid",
            "covidDate",
            "covidSpacer",
            "other1",
            "other1Date",
            "other1Spacer",
            "other2",
            "other2Date",
            "other2Spacer",
            "other3",
            "other3Date",
            "other3Spacer"
          ]
        }
      ]
    }
  },
  "path": "/ehr/patient/history",
  "routeName": "immunization",
  "label": "Immunization history",
  "redirectEhr": "",
  "redirectLis": "",
  "ehr": "yes",
  "fullPageKey": "patient.history.immunization"
}
export default DEFS