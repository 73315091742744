/* eslint-disable */
const DEFS = {
  "pageDataKey": "newbornRec",
  "pIndex": "62",
  "hasGridForm": true,
  "pageChildren": [
    {
      "elementKey": "matHistoryForm_name",
      "formIndex": "1",
      "inputType": "practitionerName",
      "tableColumn": "1",
      "recHeader": true,
      "fqn": "newbornRec.name"
    },
    {
      "elementKey": "matHistoryForm_profession",
      "formIndex": "1",
      "inputType": "practitionerProfession",
      "tableColumn": "1",
      "recHeader": true,
      "fqn": "newbornRec.profession"
    },
    {
      "elementKey": "matHistoryForm_day",
      "formIndex": "1",
      "inputType": "visitDay",
      "mandatory": true,
      "tableColumn": "1",
      "validation": "visitDay",
      "recHeader": true,
      "fqn": "newbornRec.day"
    },
    {
      "elementKey": "matHistoryForm_time",
      "formIndex": "1",
      "inputType": "visitTime",
      "mandatory": true,
      "tableColumn": "1",
      "validation": "time24",
      "recHeader": true,
      "fqn": "newbornRec.time"
    },
    {
      "elementKey": "matParentMrn",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "3",
      "fqn": "newbornRec.matParentMrn"
    },
    {
      "elementKey": "matGravida",
      "formIndex": "1",
      "inputType": "number",
      "tableColumn": "5",
      "fqn": "newbornRec.matGravida"
    },
    {
      "elementKey": "matPara",
      "formIndex": "1",
      "inputType": "number",
      "tableColumn": "6",
      "fqn": "newbornRec.matPara"
    },
    {
      "elementKey": "matTerm",
      "formIndex": "1",
      "inputType": "number",
      "tableColumn": "7",
      "fqn": "newbornRec.matTerm"
    },
    {
      "elementKey": "matPreterm",
      "formIndex": "1",
      "inputType": "number",
      "tableColumn": "8",
      "fqn": "newbornRec.matPreterm"
    },
    {
      "elementKey": "matAbortus",
      "formIndex": "1",
      "inputType": "number",
      "tableColumn": "9",
      "fqn": "newbornRec.matAbortus"
    },
    {
      "elementKey": "matLiving",
      "formIndex": "1",
      "inputType": "number",
      "tableColumn": "10",
      "fqn": "newbornRec.matLiving"
    },
    {
      "elementKey": "matEdd",
      "formIndex": "1",
      "inputType": "visitDay",
      "tableColumn": "12",
      "fqn": "newbornRec.matEdd"
    },
    {
      "elementKey": "matEddBy",
      "formIndex": "1",
      "inputType": "radioset",
      "options": [
        {
          "key": "us",
          "text": "US"
        },
        {
          "key": "vf",
          "text": "IVF"
        },
        {
          "key": "lmp",
          "text": "LMP"
        }
      ],
      "tableColumn": "13",
      "fqn": "newbornRec.matEddBy"
    },
    {
      "elementKey": "matBloodGroup",
      "formIndex": "1",
      "inputType": "radioset",
      "options": [
        {
          "key": "O",
          "text": "O"
        },
        {
          "key": "A",
          "text": "A"
        },
        {
          "key": "B",
          "text": "B"
        },
        {
          "key": "AB",
          "text": "AB"
        },
        {
          "key": "unknown",
          "text": "unknown"
        }
      ],
      "tableColumn": "15",
      "fqn": "newbornRec.matBloodGroup"
    },
    {
      "elementKey": "matRh",
      "formIndex": "1",
      "inputType": "radioset",
      "options": [
        {
          "key": "positive",
          "text": "Positive"
        },
        {
          "key": "negative",
          "text": "Negative"
        }
      ],
      "tableColumn": "16",
      "fqn": "newbornRec.matRh"
    },
    {
      "elementKey": "matAntibodies",
      "formIndex": "1",
      "inputType": "radioset",
      "options": [
        {
          "key": "yes",
          "text": "Yes"
        },
        {
          "key": "no",
          "text": "No"
        }
      ],
      "tableColumn": "17",
      "fqn": "newbornRec.matAntibodies"
    },
    {
      "elementKey": "matGBS",
      "formIndex": "1",
      "inputType": "radioset",
      "options": [
        {
          "key": "negative",
          "text": "Negative"
        },
        {
          "key": "positive",
          "text": "Positive"
        },
        {
          "key": "unknown",
          "text": "Unknown"
        }
      ],
      "tableColumn": "18",
      "fqn": "newbornRec.matGBS"
    },
    {
      "elementKey": "matSubstance",
      "formIndex": "1",
      "inputType": "checkset",
      "options": [
        {
          "key": "none",
          "text": "None"
        },
        {
          "key": "cocaine",
          "text": "Cocaine"
        },
        {
          "key": "alcohol",
          "text": "Alcohol"
        },
        {
          "key": "opiods",
          "text": "Opiods"
        },
        {
          "key": "tobacco",
          "text": "Tobacco"
        },
        {
          "key": "ivdrugs",
          "text": "IV drugs"
        },
        {
          "key": "cannabis",
          "text": "Cannabis"
        },
        {
          "key": "methaphetamine",
          "text": "Methaphetamine"
        },
        {
          "key": "other",
          "text": "Other"
        }
      ],
      "tableColumn": "19",
      "fqn": "newbornRec.matSubstance"
    },
    {
      "elementKey": "MatSubOther",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "20",
      "fqn": "newbornRec.MatSubOther"
    },
    {
      "elementKey": "matComments",
      "formIndex": "1",
      "inputType": "textarea",
      "tableColumn": "22",
      "fqn": "newbornRec.matComments"
    },
    {
      "elementKey": "apgarScore_name",
      "formIndex": "2",
      "inputType": "practitionerName",
      "tableColumn": "2",
      "recHeader": true,
      "fqn": "newbornRec.name"
    },
    {
      "elementKey": "apgarScore_profession",
      "formIndex": "2",
      "inputType": "practitionerProfession",
      "tableColumn": "2",
      "recHeader": true,
      "fqn": "newbornRec.profession"
    },
    {
      "elementKey": "apgarScore_day",
      "formIndex": "2",
      "inputType": "visitDay",
      "mandatory": true,
      "tableColumn": "2",
      "validation": "visitDay",
      "recHeader": true,
      "fqn": "newbornRec.day"
    },
    {
      "elementKey": "apgarScore_time",
      "formIndex": "2",
      "inputType": "visitTime",
      "mandatory": true,
      "tableColumn": "2",
      "validation": "time24",
      "recHeader": true,
      "fqn": "newbornRec.time"
    },
    {
      "elementKey": "apagarHeartRate_1",
      "formIndex": "2",
      "inputType": "radioset",
      "options": [
        {
          "key": "0",
          "text": "0 - absent"
        },
        {
          "key": "1",
          "text": "1 - less than 100"
        },
        {
          "key": "2",
          "text": "2 - 100 or more"
        }
      ],
      "passToFunction": "apagarScore_1",
      "tableColumn": "4",
      "fqn": "newbornRec.apagarHeartRate_1"
    },
    {
      "elementKey": "apagarRespiratory_1",
      "formIndex": "2",
      "inputType": "radioset",
      "options": [
        {
          "key": "0",
          "text": "0 - absent"
        },
        {
          "key": "1",
          "text": "1 - weak cry/hypoventilation"
        },
        {
          "key": "2",
          "text": "2 - good crying"
        }
      ],
      "passToFunction": "apagarScore_1",
      "tableColumn": "5",
      "fqn": "newbornRec.apagarRespiratory_1"
    },
    {
      "elementKey": "apagarActivity_1",
      "formIndex": "2",
      "inputType": "radioset",
      "options": [
        {
          "key": "0",
          "text": "0 - limp"
        },
        {
          "key": "1",
          "text": "1 - some flexion"
        },
        {
          "key": "2",
          "text": "2 - active motion"
        }
      ],
      "passToFunction": "apagarScore_1",
      "tableColumn": "6",
      "fqn": "newbornRec.apagarActivity_1"
    },
    {
      "elementKey": "apagarBehave_1",
      "formIndex": "2",
      "inputType": "radioset",
      "options": [
        {
          "key": "0",
          "text": "0 -none"
        },
        {
          "key": "1",
          "text": "1 - grimace"
        },
        {
          "key": "2",
          "text": "2 - active withdrawal,cry, cough,sneeze +"
        }
      ],
      "passToFunction": "apagarScore_1",
      "tableColumn": "7",
      "fqn": "newbornRec.apagarBehave_1"
    },
    {
      "elementKey": "apagarAppearance_1",
      "formIndex": "2",
      "inputType": "radioset",
      "options": [
        {
          "key": "0",
          "text": "0 - cyanotic"
        },
        {
          "key": "1",
          "text": "1 - acrocyanosis"
        },
        {
          "key": "2",
          "text": "2 - appropriate for ethnicity"
        }
      ],
      "passToFunction": "apagarScore_1",
      "tableColumn": "8",
      "fqn": "newbornRec.apagarAppearance_1"
    },
    {
      "elementKey": "apagarScore_1",
      "calculationType": "sum",
      "formIndex": "2",
      "inputType": "calculatedValue",
      "tableColumn": "9",
      "fqn": "newbornRec.apagarScore_1"
    },
    {
      "elementKey": "apagarHeartRate_2",
      "formIndex": "2",
      "inputType": "radioset",
      "options": [
        {
          "key": "0",
          "text": "0 - absent"
        },
        {
          "key": "1",
          "text": "1 - less than 100"
        },
        {
          "key": "2",
          "text": "2 - 100 or more"
        }
      ],
      "passToFunction": "apagarScore_2",
      "tableColumn": "11",
      "fqn": "newbornRec.apagarHeartRate_2"
    },
    {
      "elementKey": "apagarRespiratory_2",
      "formIndex": "2",
      "inputType": "radioset",
      "options": [
        {
          "key": "0",
          "text": "0 - absent"
        },
        {
          "key": "1",
          "text": "1 - weak cry/hypoventilation"
        },
        {
          "key": "2",
          "text": "2 - good crying"
        }
      ],
      "passToFunction": "apagarScore_2",
      "tableColumn": "12",
      "fqn": "newbornRec.apagarRespiratory_2"
    },
    {
      "elementKey": "apagarActivity_2",
      "formIndex": "2",
      "inputType": "radioset",
      "options": [
        {
          "key": "0",
          "text": "0 - limp"
        },
        {
          "key": "1",
          "text": "1 - some flexion"
        },
        {
          "key": "2",
          "text": "2 - active motion"
        }
      ],
      "passToFunction": "apagarScore_2",
      "tableColumn": "13",
      "fqn": "newbornRec.apagarActivity_2"
    },
    {
      "elementKey": "apagarBehave_2",
      "formIndex": "2",
      "inputType": "radioset",
      "options": [
        {
          "key": "0",
          "text": "0 -none"
        },
        {
          "key": "1",
          "text": "1 - grimace"
        },
        {
          "key": "2",
          "text": "2 - active withdrawal,cry, cough,sneeze +"
        }
      ],
      "passToFunction": "apagarScore_2",
      "tableColumn": "14",
      "fqn": "newbornRec.apagarBehave_2"
    },
    {
      "elementKey": "apagarAppearance_2",
      "formIndex": "2",
      "inputType": "radioset",
      "options": [
        {
          "key": "0",
          "text": "0 - cyanotic"
        },
        {
          "key": "1",
          "text": "1 - acrocyanosis"
        },
        {
          "key": "2",
          "text": "2 - appropriate for ethnicity"
        }
      ],
      "passToFunction": "apagarScore_2",
      "tableColumn": "15",
      "fqn": "newbornRec.apagarAppearance_2"
    },
    {
      "elementKey": "apagarScore_2",
      "calculationType": "sum",
      "formIndex": "2",
      "inputType": "calculatedValue",
      "tableColumn": "16",
      "fqn": "newbornRec.apagarScore_2"
    },
    {
      "elementKey": "apagarHeartRate_3",
      "formIndex": "2",
      "inputType": "radioset",
      "options": [
        {
          "key": "0",
          "text": "0 - absent"
        },
        {
          "key": "1",
          "text": "1 - less than 100"
        },
        {
          "key": "2",
          "text": "2 - 100 or more"
        }
      ],
      "passToFunction": "apagarScore_3",
      "tableColumn": "18",
      "fqn": "newbornRec.apagarHeartRate_3"
    },
    {
      "elementKey": "apagarRespiratory_3",
      "formIndex": "2",
      "inputType": "radioset",
      "options": [
        {
          "key": "0",
          "text": "0 - absent"
        },
        {
          "key": "1",
          "text": "1 - weak cry/hypoventilation"
        },
        {
          "key": "2",
          "text": "2 - good crying"
        }
      ],
      "passToFunction": "apagarScore_3",
      "tableColumn": "19",
      "fqn": "newbornRec.apagarRespiratory_3"
    },
    {
      "elementKey": "apagarActivity_3",
      "formIndex": "2",
      "inputType": "radioset",
      "options": [
        {
          "key": "0",
          "text": "0 - limp"
        },
        {
          "key": "1",
          "text": "1 - some flexion"
        },
        {
          "key": "2",
          "text": "2 - active motion"
        }
      ],
      "passToFunction": "apagarScore_3",
      "tableColumn": "20",
      "fqn": "newbornRec.apagarActivity_3"
    },
    {
      "elementKey": "apagarBehave_3",
      "formIndex": "2",
      "inputType": "radioset",
      "options": [
        {
          "key": "0",
          "text": "0 -none"
        },
        {
          "key": "1",
          "text": "1 - grimace"
        },
        {
          "key": "2",
          "text": "2 - active withdrawal,cry, cough,sneeze +"
        }
      ],
      "passToFunction": "apagarScore_3",
      "tableColumn": "21",
      "fqn": "newbornRec.apagarBehave_3"
    },
    {
      "elementKey": "apagarAppearance_3",
      "formIndex": "2",
      "inputType": "radioset",
      "options": [
        {
          "key": "0",
          "text": "0 - cyanotic"
        },
        {
          "key": "1",
          "text": "1 - acrocyanosis"
        },
        {
          "key": "2",
          "text": "2 - appropriate for ethnicity"
        }
      ],
      "passToFunction": "apagarScore_3",
      "tableColumn": "22",
      "fqn": "newbornRec.apagarAppearance_3"
    },
    {
      "elementKey": "apagarScore_3",
      "calculationType": "sum",
      "formIndex": "2",
      "inputType": "calculatedValue",
      "tableColumn": "23",
      "fqn": "newbornRec.apagarScore_3"
    },
    {
      "elementKey": "apagarComments",
      "formIndex": "2",
      "inputType": "textarea",
      "tableColumn": "25",
      "fqn": "newbornRec.apagarComments"
    },
    {
      "elementKey": "oneHourTransistion_name",
      "formIndex": "3",
      "inputType": "practitionerName",
      "tableColumn": "1",
      "recHeader": true,
      "fqn": "newbornRec.name"
    },
    {
      "elementKey": "oneHourTransistion_profession",
      "formIndex": "3",
      "inputType": "practitionerProfession",
      "tableColumn": "1",
      "recHeader": true,
      "fqn": "newbornRec.profession"
    },
    {
      "elementKey": "oneHourTransistion_day",
      "formIndex": "3",
      "inputType": "visitDay",
      "mandatory": true,
      "tableColumn": "1",
      "validation": "visitDay",
      "recHeader": true,
      "fqn": "newbornRec.day"
    },
    {
      "elementKey": "oneHourTransistion_time",
      "formIndex": "3",
      "inputType": "visitTime",
      "mandatory": true,
      "tableColumn": "1",
      "validation": "time24",
      "recHeader": true,
      "fqn": "newbornRec.time"
    },
    {
      "elementKey": "oneHourPositioned",
      "formIndex": "3",
      "inputType": "radioset",
      "options": [
        {
          "key": "skin",
          "text": "skin-to-skin"
        },
        {
          "key": "radiant",
          "text": "radiant warmer"
        },
        {
          "key": "other",
          "text": "other"
        }
      ],
      "tableColumn": "3",
      "fqn": "newbornRec.oneHourPositioned"
    },
    {
      "elementKey": "oneHourPositionedOther",
      "formIndex": "3",
      "inputType": "text",
      "tableColumn": "4",
      "fqn": "newbornRec.oneHourPositionedOther"
    },
    {
      "elementKey": "oneHourAmniotic",
      "formIndex": "3",
      "inputType": "radioset",
      "options": [
        {
          "key": "clear",
          "text": "clear"
        },
        {
          "key": "bloody",
          "text": "bloody"
        },
        {
          "key": "meconium",
          "text": "meconium"
        },
        {
          "key": "other",
          "text": "other"
        }
      ],
      "tableColumn": "5",
      "fqn": "newbornRec.oneHourAmniotic"
    },
    {
      "elementKey": "oneHourAmnioticOther",
      "formIndex": "3",
      "inputType": "text",
      "tableColumn": "6",
      "fqn": "newbornRec.oneHourAmnioticOther"
    },
    {
      "elementKey": "oneHourSuction",
      "formIndex": "3",
      "inputType": "radioset",
      "options": [
        {
          "key": "oroph",
          "text": "oropharyngeal"
        },
        {
          "key": "trachea",
          "text": "trachea"
        },
        {
          "key": "meconium",
          "text": "meconium below cords"
        },
        {
          "key": "stomach",
          "text": "stomach aspirated"
        }
      ],
      "tableColumn": "7",
      "fqn": "newbornRec.oneHourSuction"
    },
    {
      "elementKey": "oneHourOxygen",
      "formIndex": "3",
      "inputType": "radioset",
      "options": [
        {
          "key": "none",
          "text": "none"
        },
        {
          "key": "free",
          "text": "free flow"
        },
        {
          "key": "IPPV",
          "text": "IPPV per mask"
        },
        {
          "key": "resus",
          "text": "see resuscitation form"
        }
      ],
      "tableColumn": "8",
      "fqn": "newbornRec.oneHourOxygen"
    },
    {
      "elementKey": "oneHourFFOStart",
      "formIndex": "3",
      "inputType": "number",
      "suffix": "minutes",
      "tableColumn": "9",
      "fqn": "newbornRec.oneHourFFOStart",
      "suffixText": "minutes"
    },
    {
      "elementKey": "oneHourFFOStop",
      "formIndex": "3",
      "inputType": "number",
      "suffix": "minutes",
      "tableColumn": "10",
      "fqn": "newbornRec.oneHourFFOStop",
      "suffixText": "minutes"
    },
    {
      "elementKey": "oneHourIPPVStart",
      "formIndex": "3",
      "inputType": "number",
      "suffix": "minutes",
      "tableColumn": "11",
      "fqn": "newbornRec.oneHourIPPVStart",
      "suffixText": "minutes"
    },
    {
      "elementKey": "oneHourIPPVStop",
      "formIndex": "3",
      "inputType": "number",
      "suffix": "minutes",
      "tableColumn": "12",
      "fqn": "newbornRec.oneHourIPPVStop",
      "suffixText": "minutes"
    },
    {
      "elementKey": "oneHourPulse",
      "formIndex": "3",
      "inputType": "radioset",
      "options": [
        {
          "key": "yes",
          "text": "Yes"
        },
        {
          "key": "no",
          "text": "No"
        },
        {
          "key": "comment",
          "text": "Comment"
        }
      ],
      "tableColumn": "13",
      "fqn": "newbornRec.oneHourPulse"
    },
    {
      "elementKey": "oneHourTT100",
      "formIndex": "3",
      "inputType": "number",
      "options": [
        {
          "key": "immediate",
          "text": "Under 30 sec"
        },
        {
          "key": "early",
          "text": "30 sec to 1 min"
        },
        {
          "key": "moderate",
          "text": "1 to 5 min"
        },
        {
          "key": "later",
          "text": "Over 5 min"
        }
      ],
      "tableColumn": "14",
      "fqn": "newbornRec.oneHourTT100"
    },
    {
      "elementKey": "oneHourSpontaneous",
      "formIndex": "3",
      "inputType": "radioset",
      "options": [
        {
          "key": "yes",
          "text": "Yes"
        },
        {
          "key": "no",
          "text": "No"
        }
      ],
      "tableColumn": "15",
      "fqn": "newbornRec.oneHourSpontaneous"
    },
    {
      "elementKey": "oneHourTTSpontaneous",
      "formIndex": "3",
      "inputType": "number",
      "options": [
        {
          "key": "immediate",
          "text": "Under 30 sec"
        },
        {
          "key": "early",
          "text": "30 sec to 1 min"
        },
        {
          "key": "moderate",
          "text": "1 to 5 min"
        },
        {
          "key": "later",
          "text": "Over 5 min"
        }
      ],
      "tableColumn": "16",
      "fqn": "newbornRec.oneHourTTSpontaneous"
    },
    {
      "elementKey": "oneHourPrimary",
      "formIndex": "3",
      "inputType": "text",
      "tableColumn": "18",
      "fqn": "newbornRec.oneHourPrimary"
    },
    {
      "elementKey": "oneHourSecondary",
      "formIndex": "3",
      "inputType": "text",
      "tableColumn": "19",
      "fqn": "newbornRec.oneHourSecondary"
    },
    {
      "elementKey": "oneHourRM",
      "formIndex": "3",
      "inputType": "text",
      "tableColumn": "20",
      "fqn": "newbornRec.oneHourRM"
    },
    {
      "elementKey": "oneHourMD",
      "formIndex": "3",
      "inputType": "text",
      "tableColumn": "21",
      "fqn": "newbornRec.oneHourMD"
    },
    {
      "elementKey": "deliveryDetails_name",
      "formIndex": "4",
      "inputType": "practitionerName",
      "tableColumn": "1",
      "recHeader": true,
      "fqn": "newbornRec.name"
    },
    {
      "elementKey": "deliveryDetails_profession",
      "formIndex": "4",
      "inputType": "practitionerProfession",
      "tableColumn": "1",
      "recHeader": true,
      "fqn": "newbornRec.profession"
    },
    {
      "elementKey": "deliveryDetails_day",
      "formIndex": "4",
      "inputType": "visitDay",
      "mandatory": true,
      "tableColumn": "1",
      "validation": "visitDay",
      "recHeader": true,
      "fqn": "newbornRec.day"
    },
    {
      "elementKey": "deliveryDetails_time",
      "formIndex": "4",
      "inputType": "visitTime",
      "mandatory": true,
      "tableColumn": "1",
      "validation": "time24",
      "recHeader": true,
      "fqn": "newbornRec.time"
    },
    {
      "elementKey": "deliveryBDate",
      "formIndex": "4",
      "inputType": "visitDay",
      "tableColumn": "3",
      "fqn": "newbornRec.deliveryBDate"
    },
    {
      "elementKey": "deliveryBTime",
      "formIndex": "4",
      "inputType": "visitTime",
      "tableColumn": "4",
      "fqn": "newbornRec.deliveryBTime"
    },
    {
      "elementKey": "deliveryAgeWeeks",
      "formIndex": "4",
      "inputType": "number",
      "suffix": "weeks",
      "tableColumn": "5",
      "fqn": "newbornRec.deliveryAgeWeeks",
      "suffixText": "weeks"
    },
    {
      "elementKey": "deliveryAgeDays",
      "formIndex": "4",
      "inputType": "number",
      "suffix": "days",
      "tableColumn": "6",
      "fqn": "newbornRec.deliveryAgeDays",
      "suffixText": "days"
    },
    {
      "elementKey": "deliveryAge",
      "formIndex": "4",
      "inputType": "text",
      "tableColumn": "7",
      "fqn": "newbornRec.deliveryAge"
    },
    {
      "elementKey": "deliveryType",
      "formIndex": "4",
      "inputType": "radioset",
      "options": [
        {
          "key": "spontaneous",
          "text": "spontaneous vaginal"
        },
        {
          "key": "assisted",
          "text": "assisted vaginal"
        },
        {
          "key": "caesarean",
          "text": "caesarean section"
        }
      ],
      "tableColumn": "8",
      "fqn": "newbornRec.deliveryType"
    },
    {
      "elementKey": "deliveryMRN",
      "formIndex": "4",
      "inputType": "text",
      "tableColumn": "9",
      "fqn": "newbornRec.deliveryMRN"
    },
    {
      "elementKey": "deliveryIdBy",
      "formIndex": "4",
      "inputType": "text",
      "tableColumn": "10",
      "fqn": "newbornRec.deliveryIdBy"
    },
    {
      "elementKey": "deliveryVoided",
      "formIndex": "4",
      "inputType": "radioset",
      "options": [
        {
          "key": "yes",
          "text": "Yes"
        },
        {
          "key": "no",
          "text": "No"
        }
      ],
      "tableColumn": "11",
      "fqn": "newbornRec.deliveryVoided"
    },
    {
      "elementKey": "deliveryMeconium",
      "formIndex": "4",
      "inputType": "radioset",
      "options": [
        {
          "key": "yes",
          "text": "Yes"
        },
        {
          "key": "no",
          "text": "No"
        }
      ],
      "tableColumn": "12",
      "fqn": "newbornRec.deliveryMeconium"
    },
    {
      "elementKey": "deliveryBreastfeeding",
      "formIndex": "4",
      "inputType": "radioset",
      "options": [
        {
          "key": "yes",
          "text": "Yes"
        },
        {
          "key": "no",
          "text": "No"
        }
      ],
      "tableColumn": "13",
      "fqn": "newbornRec.deliveryBreastfeeding"
    },
    {
      "elementKey": "deliveryComments",
      "formIndex": "4",
      "inputType": "textarea",
      "tableColumn": "15",
      "fqn": "newbornRec.deliveryComments"
    },
    {
      "elementKey": "routineProcCord",
      "formIndex": "4",
      "inputType": "radioset",
      "options": [
        {
          "key": "rh",
          "text": "Rh"
        },
        {
          "key": "blood",
          "text": "Blood gas"
        },
        {
          "key": "notCollected",
          "text": "Not collected"
        }
      ],
      "tableColumn": "17",
      "fqn": "newbornRec.routineProcCord"
    },
    {
      "elementKey": "routineProcCordOther",
      "formIndex": "4",
      "inputType": "text",
      "tableColumn": "18",
      "fqn": "newbornRec.routineProcCordOther"
    },
    {
      "elementKey": "routineProcEye",
      "formIndex": "4",
      "inputType": "radioset",
      "options": [
        {
          "key": "given",
          "text": "given (see MAR)"
        },
        {
          "key": "declined",
          "text": "declined"
        }
      ],
      "tableColumn": "19",
      "fqn": "newbornRec.routineProcEye"
    },
    {
      "elementKey": "routineProcVitK",
      "formIndex": "4",
      "inputType": "radioset",
      "options": [
        {
          "key": "given",
          "text": "given (see MAR)"
        },
        {
          "key": "declined",
          "text": "declined"
        }
      ],
      "tableColumn": "20",
      "fqn": "newbornRec.routineProcVitK"
    },
    {
      "elementKey": "birthMeasure_name",
      "formIndex": "5",
      "inputType": "practitionerName",
      "tableColumn": "1",
      "recHeader": true,
      "fqn": "newbornRec.name"
    },
    {
      "elementKey": "birthMeasure_profession",
      "formIndex": "5",
      "inputType": "practitionerProfession",
      "tableColumn": "1",
      "recHeader": true,
      "fqn": "newbornRec.profession"
    },
    {
      "elementKey": "birthMeasure_day",
      "formIndex": "5",
      "inputType": "visitDay",
      "mandatory": true,
      "tableColumn": "1",
      "validation": "visitDay",
      "recHeader": true,
      "fqn": "newbornRec.day"
    },
    {
      "elementKey": "birthMeasure_time",
      "formIndex": "5",
      "inputType": "visitTime",
      "mandatory": true,
      "tableColumn": "1",
      "validation": "time24",
      "recHeader": true,
      "fqn": "newbornRec.time"
    },
    {
      "elementKey": "birthMeasureFormLabel",
      "formIndex": "5",
      "formCss": "ehr-row-label",
      "inputType": "form_label",
      "tableColumn": "3",
      "fqn": "newbornRec.birthMeasureFormLabel"
    },
    {
      "elementKey": "birthMeasureKg",
      "formIndex": "5",
      "inputType": "decimal2",
      "suffix": "kg",
      "tableColumn": "4",
      "fqn": "newbornRec.birthMeasureKg",
      "suffixText": "kg"
    },
    {
      "elementKey": "birthMeasureCm",
      "formIndex": "5",
      "inputType": "number",
      "suffix": "cm",
      "tableColumn": "5",
      "fqn": "newbornRec.birthMeasureCm",
      "suffixText": "cm"
    },
    {
      "elementKey": "birthMeasureHeadCm",
      "formIndex": "5",
      "inputType": "number",
      "suffix": "cm",
      "tableColumn": "6",
      "fqn": "newbornRec.birthMeasureHeadCm",
      "suffixText": "cm"
    },
    {
      "elementKey": "sysMeasureT",
      "formIndex": "5",
      "inputType": "form_label",
      "tableColumn": "8",
      "fqn": "newbornRec.sysMeasureT"
    },
    {
      "elementKey": "sysMeasureKg",
      "formIndex": "5",
      "inputType": "decimal2",
      "suffix": "kg",
      "tableColumn": "9",
      "fqn": "newbornRec.sysMeasureKg",
      "suffixText": "kg"
    },
    {
      "elementKey": "sysMeasureDoseKg",
      "formIndex": "5",
      "inputType": "decimal2",
      "suffix": "kg",
      "tableColumn": "10",
      "fqn": "newbornRec.sysMeasureDoseKg",
      "suffixText": "kg"
    },
    {
      "elementKey": "sysMeasureCm",
      "formIndex": "5",
      "inputType": "number",
      "suffix": "cm",
      "tableColumn": "11",
      "fqn": "newbornRec.sysMeasureCm",
      "suffixText": "cm"
    },
    {
      "elementKey": "sysMeasureHeadCm",
      "formIndex": "5",
      "inputType": "number",
      "suffix": "cm",
      "tableColumn": "12",
      "fqn": "newbornRec.sysMeasureHeadCm",
      "suffixText": "cm"
    },
    {
      "elementKey": "evaluationDevWeight",
      "formIndex": "5",
      "inputType": "number",
      "suffix": "%",
      "tableColumn": "14",
      "fqn": "newbornRec.evaluationDevWeight",
      "suffixText": "%"
    },
    {
      "elementKey": "evaluationDevLength",
      "formIndex": "5",
      "inputType": "number",
      "suffix": "%",
      "tableColumn": "15",
      "fqn": "newbornRec.evaluationDevLength",
      "suffixText": "%"
    },
    {
      "elementKey": "evaluationDevHead",
      "formIndex": "5",
      "inputType": "number",
      "suffix": "%",
      "tableColumn": "16",
      "fqn": "newbornRec.evaluationDevHead",
      "suffixText": "%"
    },
    {
      "elementKey": "evaluationDevGestion",
      "formIndex": "5",
      "inputType": "radioset",
      "options": [
        {
          "key": "preterm",
          "text": "Preterm"
        },
        {
          "key": "term",
          "text": "Term"
        },
        {
          "key": "posterm",
          "text": "Posterm"
        }
      ],
      "tableColumn": "17",
      "fqn": "newbornRec.evaluationDevGestion"
    },
    {
      "elementKey": "evaluationDevIntrauterine",
      "formIndex": "5",
      "inputType": "radioset",
      "options": [
        {
          "key": "sga",
          "text": "Small for gestational age (SGA)"
        },
        {
          "key": "aga",
          "text": "Average for gestational age (AGA)"
        },
        {
          "key": "lga",
          "text": "Large for gestational age (LGA)"
        }
      ],
      "tableColumn": "18",
      "fqn": "newbornRec.evaluationDevIntrauterine"
    },
    {
      "elementKey": "stillbirth_name",
      "formIndex": "6",
      "inputType": "practitionerName",
      "tableColumn": "1",
      "recHeader": true,
      "fqn": "newbornRec.name"
    },
    {
      "elementKey": "stillbirth_profession",
      "formIndex": "6",
      "inputType": "practitionerProfession",
      "tableColumn": "1",
      "recHeader": true,
      "fqn": "newbornRec.profession"
    },
    {
      "elementKey": "stillbirth_day",
      "formIndex": "6",
      "inputType": "visitDay",
      "mandatory": true,
      "tableColumn": "1",
      "validation": "visitDay",
      "recHeader": true,
      "fqn": "newbornRec.day"
    },
    {
      "elementKey": "stillbirth_time",
      "formIndex": "6",
      "inputType": "visitTime",
      "mandatory": true,
      "tableColumn": "1",
      "validation": "time24",
      "recHeader": true,
      "fqn": "newbornRec.time"
    },
    {
      "elementKey": "stillbirthMacerated",
      "formIndex": "6",
      "inputType": "radioset",
      "options": [
        {
          "key": "yes",
          "text": "Yes"
        },
        {
          "key": "no",
          "text": "No"
        }
      ],
      "tableColumn": "3",
      "fqn": "newbornRec.stillbirthMacerated"
    },
    {
      "elementKey": "stillbirthIugr",
      "formIndex": "6",
      "inputType": "radioset",
      "options": [
        {
          "key": "yes",
          "text": "Yes"
        },
        {
          "key": "no",
          "text": "No"
        }
      ],
      "tableColumn": "4",
      "fqn": "newbornRec.stillbirthIugr"
    },
    {
      "elementKey": "stillbirthRetroplancenta",
      "formIndex": "6",
      "inputType": "radioset",
      "options": [
        {
          "key": "yes",
          "text": "Yes"
        },
        {
          "key": "no",
          "text": "No"
        }
      ],
      "tableColumn": "5",
      "fqn": "newbornRec.stillbirthRetroplancenta"
    },
    {
      "elementKey": "stillbirthAnemia",
      "formIndex": "6",
      "inputType": "radioset",
      "options": [
        {
          "key": "yes",
          "text": "Yes"
        },
        {
          "key": "no",
          "text": "No"
        }
      ],
      "tableColumn": "6",
      "fqn": "newbornRec.stillbirthAnemia"
    },
    {
      "elementKey": "stillbirthAutopsy",
      "formIndex": "6",
      "inputType": "radioset",
      "options": [
        {
          "key": "yes",
          "text": "Yes"
        },
        {
          "key": "no",
          "text": "No"
        }
      ],
      "tableColumn": "7",
      "fqn": "newbornRec.stillbirthAutopsy"
    },
    {
      "elementKey": "stillbirthAnonmaly",
      "formIndex": "6",
      "inputType": "radioset",
      "options": [
        {
          "key": "yes",
          "text": "Yes"
        },
        {
          "key": "no",
          "text": "No"
        }
      ],
      "tableColumn": "8",
      "fqn": "newbornRec.stillbirthAnonmaly"
    },
    {
      "elementKey": "stillbirthUmbilical",
      "formIndex": "6",
      "inputType": "number",
      "suffix": "cm",
      "tableColumn": "9",
      "fqn": "newbornRec.stillbirthUmbilical",
      "suffixText": "cm"
    },
    {
      "elementKey": "stillbirthComments",
      "formIndex": "6",
      "inputType": "textarea",
      "tableColumn": "11",
      "fqn": "newbornRec.stillbirthComments"
    }
  ],
  "pageElements": {
    "matHistoryForm": {
      "elementKey": "matHistoryForm",
      "pageElementIndex": "1",
      "formKey": "matHistoryForm",
      "fqn": "newbornRec.matHistoryForm",
      "isPageForm": true,
      "ehr_groups": [
        {
          "elementKey": "mat_hdrGroup",
          "formCss": "record-header",
          "fqn": "newbornRec.mat_hdrGroup",
          "gIndex": "1",
          "gChildren": [
            "matHistoryForm_name",
            "matHistoryForm_profession",
            "matHistoryForm_day",
            "matHistoryForm_time"
          ]
        },
        {
          "elementKey": "mat_1",
          "formCss": "ehr-row-flow",
          "fqn": "newbornRec.mat_1",
          "gIndex": "2",
          "gChildren": [
            "matParentMrn"
          ]
        },
        {
          "elementKey": "mat_2",
          "formCss": "ehr-row-flow",
          "fqn": "newbornRec.mat_2",
          "gIndex": "3",
          "gChildren": [
            "matGravida",
            "matPara",
            "matTerm",
            "matPreterm",
            "matAbortus",
            "matLiving"
          ]
        },
        {
          "elementKey": "mat_3",
          "formCss": "ehr-row-flow",
          "fqn": "newbornRec.mat_3",
          "gIndex": "4",
          "gChildren": [
            "matEdd",
            "matEddBy"
          ]
        },
        {
          "elementKey": "mat_4",
          "formCss": "ehr-row-flow",
          "fqn": "newbornRec.mat_4",
          "gIndex": "5",
          "gChildren": [
            "matBloodGroup",
            "matRh",
            "matAntibodies",
            "matGBS",
            "matSubstance",
            "MatSubOther"
          ]
        },
        {
          "elementKey": "mat_5",
          "formCss": "grid-left-to-right-1",
          "fqn": "newbornRec.mat_5",
          "gIndex": "6",
          "gChildren": [
            "matComments"
          ]
        }
      ]
    },
    "apgarScore": {
      "elementKey": "apgarScore",
      "pageElementIndex": "2",
      "formKey": "apgarScore",
      "fqn": "newbornRec.apgarScore",
      "isPageForm": true,
      "ehr_groups": [
        {
          "elementKey": "apagar_hdrGroup",
          "formCss": "record-header",
          "fqn": "newbornRec.apagar_hdrGroup",
          "gIndex": "1",
          "gChildren": [
            "apgarScore_name",
            "apgarScore_profession",
            "apgarScore_day",
            "apgarScore_time"
          ]
        },
        {
          "elementKey": "apagar_1",
          "formCss": "ehr-row-flow",
          "fqn": "newbornRec.apagar_1",
          "gIndex": "2",
          "gChildren": [
            "apagarHeartRate_1",
            "apagarRespiratory_1",
            "apagarActivity_1",
            "apagarBehave_1",
            "apagarAppearance_1",
            "apagarScore_1"
          ]
        },
        {
          "elementKey": "apagar_2",
          "formCss": "ehr-row-flow",
          "fqn": "newbornRec.apagar_2",
          "gIndex": "3",
          "gChildren": [
            "apagarHeartRate_2",
            "apagarRespiratory_2",
            "apagarActivity_2",
            "apagarBehave_2",
            "apagarAppearance_2",
            "apagarScore_2"
          ]
        },
        {
          "elementKey": "apagar_3",
          "formCss": "ehr-row-flow",
          "fqn": "newbornRec.apagar_3",
          "gIndex": "4",
          "gChildren": [
            "apagarHeartRate_3",
            "apagarRespiratory_3",
            "apagarActivity_3",
            "apagarBehave_3",
            "apagarAppearance_3",
            "apagarScore_3"
          ]
        },
        {
          "elementKey": "apagar_4",
          "formCss": "grid-left-to-right-1",
          "fqn": "newbornRec.apagar_4",
          "gIndex": "5",
          "gChildren": [
            "apagarComments"
          ]
        }
      ]
    },
    "oneHourTransistion": {
      "elementKey": "oneHourTransistion",
      "pageElementIndex": "3",
      "formKey": "oneHourTransistion",
      "fqn": "newbornRec.oneHourTransistion",
      "isPageForm": true,
      "ehr_groups": [
        {
          "elementKey": "oneHour_hdrGroup",
          "formCss": "record-header",
          "fqn": "newbornRec.oneHour_hdrGroup",
          "gIndex": "1",
          "gChildren": [
            "oneHourTransistion_name",
            "oneHourTransistion_profession",
            "oneHourTransistion_day",
            "oneHourTransistion_time"
          ]
        },
        {
          "elementKey": "oneHour_1",
          "formCss": "ehr-row-flow",
          "fqn": "newbornRec.oneHour_1",
          "gIndex": "2",
          "gChildren": [
            "oneHourPositioned",
            "oneHourPositionedOther",
            "oneHourAmniotic",
            "oneHourAmnioticOther",
            "oneHourSuction",
            "oneHourOxygen",
            "oneHourFFOStart",
            "oneHourFFOStop",
            "oneHourIPPVStart",
            "oneHourIPPVStop",
            "oneHourPulse",
            "oneHourTT100",
            "oneHourSpontaneous",
            "oneHourTTSpontaneous"
          ]
        },
        {
          "elementKey": "oneHour_2",
          "formCss": "ehr-row-flow",
          "fqn": "newbornRec.oneHour_2",
          "gIndex": "3",
          "gChildren": [
            "oneHourPrimary",
            "oneHourSecondary",
            "oneHourRM",
            "oneHourMD"
          ]
        }
      ]
    },
    "deliveryDetails": {
      "elementKey": "deliveryDetails",
      "pageElementIndex": "4",
      "formKey": "deliveryDetails",
      "fqn": "newbornRec.deliveryDetails",
      "isPageForm": true,
      "ehr_groups": [
        {
          "elementKey": "deliveryHdr",
          "formCss": "record-header",
          "fqn": "newbornRec.deliveryHdr",
          "gIndex": "1",
          "gChildren": [
            "deliveryDetails_name",
            "deliveryDetails_profession",
            "deliveryDetails_day",
            "deliveryDetails_time"
          ]
        },
        {
          "elementKey": "delivery_1",
          "formCss": "ehr-row-flow",
          "fqn": "newbornRec.delivery_1",
          "gIndex": "2",
          "gChildren": [
            "deliveryBDate",
            "deliveryBTime",
            "deliveryAgeWeeks",
            "deliveryAgeDays",
            "deliveryAge",
            "deliveryType",
            "deliveryMRN",
            "deliveryIdBy",
            "deliveryVoided",
            "deliveryMeconium",
            "deliveryBreastfeeding"
          ]
        },
        {
          "elementKey": "delivery_2",
          "formCss": "grid-left-to-right-1",
          "fqn": "newbornRec.delivery_2",
          "gIndex": "3",
          "gChildren": [
            "deliveryComments"
          ]
        },
        {
          "elementKey": "routineProc_1",
          "formCss": "ehr-row-flow",
          "fqn": "newbornRec.routineProc_1",
          "gIndex": "4",
          "gChildren": [
            "routineProcCord",
            "routineProcCordOther",
            "routineProcEye",
            "routineProcVitK"
          ]
        }
      ]
    },
    "birthMeasure": {
      "elementKey": "birthMeasure",
      "pageElementIndex": "5",
      "formKey": "birthMeasure",
      "fqn": "newbornRec.birthMeasure",
      "isPageForm": true,
      "ehr_groups": [
        {
          "elementKey": "birthMeasure_hdrGroup",
          "formCss": "record-header",
          "fqn": "newbornRec.birthMeasure_hdrGroup",
          "gIndex": "1",
          "gChildren": [
            "birthMeasure_name",
            "birthMeasure_profession",
            "birthMeasure_day",
            "birthMeasure_time"
          ]
        },
        {
          "elementKey": "birthMeasure_1",
          "formCss": "ehr-row-flow",
          "fqn": "newbornRec.birthMeasure_1",
          "gIndex": "2",
          "gChildren": [
            "birthMeasureFormLabel",
            "birthMeasureKg",
            "birthMeasureCm",
            "birthMeasureHeadCm"
          ]
        },
        {
          "elementKey": "sysMeasure_1",
          "formCss": "ehr-row-flow",
          "fqn": "newbornRec.sysMeasure_1",
          "gIndex": "3",
          "gChildren": [
            "sysMeasureT",
            "sysMeasureKg",
            "sysMeasureDoseKg",
            "sysMeasureCm",
            "sysMeasureHeadCm"
          ]
        },
        {
          "elementKey": "evaluationDev_1",
          "formCss": "ehr-row-flow",
          "fqn": "newbornRec.evaluationDev_1",
          "gIndex": "4",
          "gChildren": [
            "evaluationDevWeight",
            "evaluationDevLength",
            "evaluationDevHead",
            "evaluationDevGestion",
            "evaluationDevIntrauterine"
          ]
        }
      ]
    },
    "stillbirth": {
      "elementKey": "stillbirth",
      "pageElementIndex": "6",
      "formKey": "stillbirth",
      "fqn": "newbornRec.stillbirth",
      "isPageForm": true,
      "ehr_groups": [
        {
          "elementKey": "stillbirth_hdrGroup",
          "formCss": "record-header",
          "fqn": "newbornRec.stillbirth_hdrGroup",
          "gIndex": "1",
          "gChildren": [
            "stillbirth_name",
            "stillbirth_profession",
            "stillbirth_day",
            "stillbirth_time"
          ]
        },
        {
          "elementKey": "stillbirth_1",
          "formCss": "ehr-row-flow",
          "fqn": "newbornRec.stillbirth_1",
          "gIndex": "2",
          "gChildren": [
            "stillbirthMacerated",
            "stillbirthIugr",
            "stillbirthRetroplancenta",
            "stillbirthAnemia",
            "stillbirthAutopsy",
            "stillbirthAnonmaly",
            "stillbirthUmbilical"
          ]
        },
        {
          "elementKey": "stillbirth_2",
          "formCss": "grid-left-to-right-1",
          "fqn": "newbornRec.stillbirth_2",
          "gIndex": "3",
          "gChildren": [
            "stillbirthComments"
          ]
        }
      ]
    }
  },
  "path": "/ehr/peri-ped/newborn",
  "routeName": "newborn-rec",
  "label": "Newborn Record",
  "generateComponent": "v2page",
  "featureFlag": "ffPed",
  "ehr": "yes",
  "fullPageKey": "periPed.newborn.newbornRec"
}
export default DEFS