/* eslint-disable */
const DEFS = {
  "pageDataKey": "newbornScreening",
  "pIndex": "68",
  "hasGridTable": true,
  "pageChildren": [
    {
      "elementKey": "nbScreen_form_name",
      "formIndex": "1",
      "inputType": "practitionerName",
      "tableColumn": "1",
      "recHeader": true,
      "fqn": "newbornScreening.name"
    },
    {
      "elementKey": "nbScreen_form_id",
      "inputType": "generatedId",
      "tableColumn": 0,
      "label": "Row id",
      "tableCss": "row-id"
    },
    {
      "elementKey": "nbScreen_form_profession",
      "formIndex": "1",
      "inputType": "practitionerProfession",
      "tableColumn": "1",
      "recHeader": true,
      "fqn": "newbornScreening.profession"
    },
    {
      "elementKey": "nbScreen_form_day",
      "formIndex": "1",
      "inputType": "visitDay",
      "mandatory": true,
      "tableColumn": "1",
      "validation": "visitDay",
      "recHeader": true,
      "fqn": "newbornScreening.day"
    },
    {
      "elementKey": "nbScreen_form_time",
      "formIndex": "1",
      "inputType": "visitTime",
      "mandatory": true,
      "tableColumn": "1",
      "validation": "time24",
      "recHeader": true,
      "fqn": "newbornScreening.time"
    },
    {
      "elementKey": "nbScreenTcBAge",
      "formIndex": "1",
      "inputType": "number",
      "suffix": "hrs",
      "tableColumn": "3",
      "fqn": "newbornScreening.nbScreenTcBAge",
      "suffixText": "hrs"
    },
    {
      "elementKey": "nbScreenTcBResult",
      "formIndex": "1",
      "inputType": "number",
      "suffix": "umol/L",
      "tableColumn": "4",
      "fqn": "newbornScreening.nbScreenTcBResult",
      "suffixText": "umol/L"
    },
    {
      "elementKey": "nbScreenTcBComments",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "5",
      "fqn": "newbornScreening.nbScreenTcBComments"
    },
    {
      "elementKey": "nbMetabolic_form_name",
      "formIndex": "2",
      "inputType": "practitionerName",
      "tableColumn": "1",
      "recHeader": true,
      "fqn": "newbornScreening.name"
    },
    {
      "elementKey": "nbMetabolic_form_id",
      "inputType": "generatedId",
      "tableColumn": 0,
      "label": "Row id",
      "tableCss": "row-id"
    },
    {
      "elementKey": "nbMetabolic_form_profession",
      "formIndex": "2",
      "inputType": "practitionerProfession",
      "tableColumn": "1",
      "recHeader": true,
      "fqn": "newbornScreening.profession"
    },
    {
      "elementKey": "nbMetabolic_form_day",
      "formIndex": "2",
      "inputType": "visitDay",
      "mandatory": true,
      "tableColumn": "1",
      "validation": "visitDay",
      "recHeader": true,
      "fqn": "newbornScreening.day"
    },
    {
      "elementKey": "nbMetabolic_form_time",
      "formIndex": "2",
      "inputType": "visitTime",
      "mandatory": true,
      "tableColumn": "1",
      "validation": "time24",
      "recHeader": true,
      "fqn": "newbornScreening.time"
    },
    {
      "elementKey": "nbScreenMetabolicScreen",
      "formIndex": "2",
      "inputType": "radioset",
      "options": [
        {
          "key": "sample",
          "text": "Sample collected"
        },
        {
          "key": "RM",
          "text": "RM to collect"
        },
        {
          "key": "declined",
          "text": "Declined"
        }
      ],
      "tableColumn": "7",
      "fqn": "newbornScreening.nbScreenMetabolicScreen"
    },
    {
      "elementKey": "nbScreenMetabolicRefusal",
      "formIndex": "2",
      "inputType": "radioset",
      "options": [
        {
          "key": "yes",
          "text": "Yes"
        },
        {
          "key": "no",
          "text": "No"
        }
      ],
      "tableColumn": "8",
      "fqn": "newbornScreening.nbScreenMetabolicRefusal"
    },
    {
      "elementKey": "nbScreenMetabolicAge",
      "formIndex": "2",
      "inputType": "number",
      "suffix": "hrs",
      "tableColumn": "9",
      "fqn": "newbornScreening.nbScreenMetabolicAge",
      "suffixText": "hrs"
    },
    {
      "elementKey": "nbScreenMetabolicComments",
      "formIndex": "2",
      "inputType": "text",
      "tableColumn": "10",
      "fqn": "newbornScreening.nbScreenMetabolicComments"
    },
    {
      "elementKey": "nbCCHD_form_name",
      "formIndex": "3",
      "inputType": "practitionerName",
      "tableColumn": "1",
      "recHeader": true,
      "fqn": "newbornScreening.name"
    },
    {
      "elementKey": "nbCCHD_form_id",
      "inputType": "generatedId",
      "tableColumn": 0,
      "label": "Row id",
      "tableCss": "row-id"
    },
    {
      "elementKey": "nbCCHD_form_profession",
      "formIndex": "3",
      "inputType": "practitionerProfession",
      "tableColumn": "1",
      "recHeader": true,
      "fqn": "newbornScreening.profession"
    },
    {
      "elementKey": "nbCCHD_form_day",
      "formIndex": "3",
      "inputType": "visitDay",
      "mandatory": true,
      "tableColumn": "1",
      "validation": "visitDay",
      "recHeader": true,
      "fqn": "newbornScreening.day"
    },
    {
      "elementKey": "nbCCHD_form_time",
      "formIndex": "3",
      "inputType": "visitTime",
      "mandatory": true,
      "tableColumn": "1",
      "validation": "time24",
      "recHeader": true,
      "fqn": "newbornScreening.time"
    },
    {
      "elementKey": "nbScreenCCHDNumber",
      "formIndex": "3",
      "inputType": "radioset",
      "options": [
        {
          "key": "1",
          "text": "1"
        },
        {
          "key": "2",
          "text": "2"
        },
        {
          "key": "3",
          "text": "3"
        }
      ],
      "tableColumn": "12",
      "fqn": "newbornScreening.nbScreenCCHDNumber"
    },
    {
      "elementKey": "nbScreenCCHDPreductal",
      "formIndex": "3",
      "inputType": "number",
      "suffix": "%",
      "tableColumn": "13",
      "fqn": "newbornScreening.nbScreenCCHDPreductal",
      "suffixText": "%"
    },
    {
      "elementKey": "nbScreenCCHDPostductal",
      "formIndex": "3",
      "inputType": "number",
      "suffix": "%",
      "tableColumn": "14",
      "fqn": "newbornScreening.nbScreenCCHDPostductal",
      "suffixText": "%"
    },
    {
      "elementKey": "nbScreenCCHDDifference",
      "formIndex": "3",
      "inputType": "number",
      "suffix": "%",
      "tableColumn": "15",
      "fqn": "newbornScreening.nbScreenCCHDDifference",
      "suffixText": "%"
    },
    {
      "elementKey": "nbScreenCCHDOutcome",
      "formIndex": "3",
      "inputType": "radioset",
      "options": [
        {
          "key": "pass",
          "text": "Pass"
        },
        {
          "key": "repeat",
          "text": "Repeat Screen"
        },
        {
          "key": "fail",
          "text": "Fail"
        }
      ],
      "tableColumn": "16",
      "fqn": "newbornScreening.nbScreenCCHDOutcome"
    },
    {
      "elementKey": "nbScreenComments",
      "formIndex": "3",
      "inputType": "textarea",
      "tableColumn": "18",
      "fqn": "newbornScreening.nbScreenComments"
    }
  ],
  "pageElements": {
    "nbScreen_form": {
      "elementKey": "nbScreen_form",
      "pageElementIndex": "1",
      "pageKey": "newbornScreening",
      "tableKey": "nbScreen_form",
      "isTable": true,
      "form": {
        "elementKey": "nbScreen_form",
        "label": "Bilirubin",
        "addButtonText": "Add Bilirubin",
        "formKey": "nbScreen_form",
        "ehr_groups": [
          {
            "elementKey": "nbScreen_hdrGroup",
            "formCss": "record-header",
            "fqn": "newbornScreening.nbScreen_hdrGroup",
            "gIndex": "1",
            "gChildren": [
              "nbScreen_form_name",
              "nbScreen_form_profession",
              "nbScreen_form_day",
              "nbScreen_form_time"
            ]
          },
          {
            "elementKey": "nbScreen_1",
            "formCss": "ehr-row-flow",
            "fqn": "newbornScreening.nbScreen_1",
            "gIndex": "2",
            "gChildren": [
              "nbScreenTcBAge",
              "nbScreenTcBResult",
              "nbScreenTcBComments"
            ]
          }
        ],
        "ehr_data": {
          "nbScreen_form_name": "",
          "nbScreen_form_profession": "",
          "nbScreen_form_day": "",
          "nbScreen_form_time": "",
          "nbScreenTcBAge": "",
          "nbScreenTcBResult": "",
          "nbScreenTcBComments": ""
        }
      },
      "fqn": "newbornScreening.nbScreen_form",
      "tableChildren": [
        "nbScreen_form_id",
        "nbScreen_form_day",
        "nbScreen_form_time",
        "nbScreenTcBAge",
        "nbScreenTcBResult",
        "nbScreenTcBComments",
        "nbScreen_form_name",
        "nbScreen_form_profession"
      ],
      "hasRecHeader": true
    },
    "nbMetabolic_form": {
      "elementKey": "nbMetabolic_form",
      "pageElementIndex": "2",
      "pageKey": "newbornScreening",
      "tableKey": "nbMetabolic_form",
      "isTable": true,
      "form": {
        "elementKey": "nbMetabolic_form",
        "label": "Metabolic",
        "addButtonText": "Add Metabolic",
        "formKey": "nbMetabolic_form",
        "ehr_groups": [
          {
            "elementKey": "nbMetabolicHdr",
            "formCss": "record-header",
            "fqn": "newbornScreening.nbMetabolicHdr",
            "gIndex": "1",
            "gChildren": [
              "nbMetabolic_form_name",
              "nbMetabolic_form_profession",
              "nbMetabolic_form_day",
              "nbMetabolic_form_time"
            ]
          },
          {
            "elementKey": "nbScreenMetabolicGrp",
            "formCss": "ehr-row-flow",
            "fqn": "newbornScreening.nbScreenMetabolicGrp",
            "gIndex": "2",
            "gChildren": [
              "nbScreenMetabolicScreen",
              "nbScreenMetabolicRefusal",
              "nbScreenMetabolicAge",
              "nbScreenMetabolicComments"
            ]
          }
        ],
        "ehr_data": {
          "nbMetabolic_form_name": "",
          "nbMetabolic_form_profession": "",
          "nbMetabolic_form_day": "",
          "nbMetabolic_form_time": "",
          "nbScreenMetabolicScreen": "",
          "nbScreenMetabolicRefusal": "",
          "nbScreenMetabolicAge": "",
          "nbScreenMetabolicComments": ""
        }
      },
      "fqn": "newbornScreening.nbMetabolic_form",
      "tableChildren": [
        "nbMetabolic_form_id",
        "nbMetabolic_form_day",
        "nbMetabolic_form_time",
        "nbScreenMetabolicScreen",
        "nbScreenMetabolicRefusal",
        "nbScreenMetabolicAge",
        "nbScreenMetabolicComments",
        "nbMetabolic_form_name",
        "nbMetabolic_form_profession"
      ],
      "hasRecHeader": true
    },
    "nbCCHD_form": {
      "elementKey": "nbCCHD_form",
      "pageElementIndex": "3",
      "pageKey": "newbornScreening",
      "tableKey": "nbCCHD_form",
      "isTable": true,
      "form": {
        "elementKey": "nbCCHD_form",
        "label": "CCHD",
        "addButtonText": "Add CCHD",
        "formKey": "nbCCHD_form",
        "ehr_groups": [
          {
            "elementKey": "nbCCHDHdr",
            "formCss": "record-header",
            "fqn": "newbornScreening.nbCCHDHdr",
            "gIndex": "1",
            "gChildren": [
              "nbCCHD_form_name",
              "nbCCHD_form_profession",
              "nbCCHD_form_day",
              "nbCCHD_form_time"
            ]
          },
          {
            "elementKey": "nbScreenCCHDGrp",
            "formCss": "ehr-row-flow",
            "fqn": "newbornScreening.nbScreenCCHDGrp",
            "gIndex": "2",
            "gChildren": [
              "nbScreenCCHDNumber",
              "nbScreenCCHDPreductal",
              "nbScreenCCHDPostductal",
              "nbScreenCCHDDifference",
              "nbScreenCCHDOutcome"
            ]
          },
          {
            "elementKey": "nbScreen_4",
            "formCss": "grid-left-to-right-1",
            "fqn": "newbornScreening.nbScreen_4",
            "gIndex": "3",
            "gChildren": [
              "nbScreenComments"
            ]
          }
        ],
        "ehr_data": {
          "nbCCHD_form_name": "",
          "nbCCHD_form_profession": "",
          "nbCCHD_form_day": "",
          "nbCCHD_form_time": "",
          "nbScreenCCHDNumber": "",
          "nbScreenCCHDPreductal": "",
          "nbScreenCCHDPostductal": "",
          "nbScreenCCHDDifference": "",
          "nbScreenCCHDOutcome": "",
          "nbScreenComments": ""
        }
      },
      "fqn": "newbornScreening.nbCCHD_form",
      "tableChildren": [
        "nbCCHD_form_id",
        "nbCCHD_form_day",
        "nbCCHD_form_time",
        "nbScreenCCHDNumber",
        "nbScreenCCHDPreductal",
        "nbScreenCCHDPostductal",
        "nbScreenCCHDDifference",
        "nbScreenCCHDOutcome",
        "nbScreenComments",
        "nbCCHD_form_name",
        "nbCCHD_form_profession"
      ],
      "hasRecHeader": true
    }
  },
  "path": "/ehr/peri-ped/newborn",
  "routeName": "newborn-screening",
  "label": "Screening Intervention",
  "generateComponent": "v2page",
  "featureFlag": "ffPed",
  "ehr": "yes",
  "fullPageKey": "periPed.newborn.newbornScreening"
}
export default DEFS