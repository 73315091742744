/* eslint-disable */
const DEFS = {
  "pageDataKey": "biopsychosocial",
  "pIndex": "21",
  "hasGridTable": true,
  "pageChildren": [
    {
      "elementKey": "table_name",
      "formIndex": "1",
      "inputType": "practitionerName",
      "tableColumn": "1",
      "recHeader": true,
      "fqn": "biopsychosocial.name"
    },
    {
      "elementKey": "table_id",
      "inputType": "generatedId",
      "tableColumn": 0,
      "label": "Row id",
      "tableCss": "row-id"
    },
    {
      "elementKey": "table_profession",
      "formIndex": "1",
      "inputType": "practitionerProfession",
      "tableColumn": "1",
      "recHeader": true,
      "fqn": "biopsychosocial.profession"
    },
    {
      "elementKey": "table_day",
      "formIndex": "1",
      "inputType": "visitDay",
      "mandatory": true,
      "tableColumn": "1",
      "validation": "visitDay",
      "recHeader": true,
      "fqn": "biopsychosocial.day"
    },
    {
      "elementKey": "table_time",
      "formIndex": "1",
      "inputType": "visitTime",
      "mandatory": true,
      "tableColumn": "1",
      "validation": "time24",
      "recHeader": true,
      "fqn": "biopsychosocial.time"
    },
    {
      "elementKey": "hygieneGrooming",
      "formIndex": "1",
      "inputType": "select",
      "options": [
        {
          "key": "Good",
          "text": "Good"
        },
        {
          "key": "Requires attention",
          "text": "Requires attention"
        }
      ],
      "tableColumn": "2",
      "fqn": "biopsychosocial.hygieneGrooming"
    },
    {
      "elementKey": "hygieneComments",
      "formIndex": "1",
      "formCss": "grid-span-3-columns",
      "inputType": "textarea",
      "tableColumn": "3",
      "fqn": "biopsychosocial.hygieneComments"
    },
    {
      "elementKey": "behaviour",
      "formIndex": "1",
      "inputType": "checkset",
      "options": [
        {
          "key": "calm",
          "text": "Calm"
        },
        {
          "key": "cooperative",
          "text": "Cooperative"
        },
        {
          "key": "agitated",
          "text": "Agitated"
        },
        {
          "key": "flatAffect",
          "text": "Flat affect"
        },
        {
          "key": "threatening",
          "text": "Threatening"
        },
        {
          "key": "physicallyAgressive",
          "text": "Physically agressive"
        },
        {
          "key": "uncommunicative",
          "text": "Uncommunicative"
        }
      ],
      "tableColumn": "4",
      "fqn": "biopsychosocial.behaviour"
    },
    {
      "elementKey": "hallucinations",
      "formIndex": "1",
      "inputType": "checkset",
      "options": [
        {
          "key": "none",
          "text": "None"
        },
        {
          "key": "visual",
          "text": "Visual"
        },
        {
          "key": "auditory",
          "text": "Auditory"
        },
        {
          "key": "olafactory",
          "text": "Olafactory"
        }
      ],
      "tableColumn": "5",
      "fqn": "biopsychosocial.hallucinations"
    },
    {
      "elementKey": "suicidal",
      "formIndex": "1",
      "inputType": "select",
      "options": [
        {
          "key": "None",
          "text": "None"
        },
        {
          "key": "Ideation",
          "text": "Ideation"
        },
        {
          "key": "Attempt",
          "text": "Attempt"
        }
      ],
      "tableColumn": "6",
      "fqn": "biopsychosocial.suicidal"
    },
    {
      "elementKey": "homicidal",
      "formIndex": "1",
      "inputType": "select",
      "options": [
        {
          "key": "None",
          "text": "None"
        },
        {
          "key": "Ideation",
          "text": "Ideation"
        }
      ],
      "tableColumn": "7",
      "fqn": "biopsychosocial.homicidal"
    },
    {
      "elementKey": "speech",
      "formIndex": "1",
      "inputType": "select",
      "options": [
        {
          "key": "Clear and coherent",
          "text": "Clear and coherent"
        },
        {
          "key": "Other",
          "text": "Other"
        }
      ],
      "tableColumn": "8",
      "fqn": "biopsychosocial.speech"
    },
    {
      "elementKey": "label21-4",
      "formIndex": "1",
      "formCss": "grid-span-3-columns",
      "inputType": "form_label",
      "fqn": "biopsychosocial.label21-4"
    },
    {
      "elementKey": "domesticViolence",
      "formIndex": "1",
      "inputType": "select",
      "options": [
        {
          "key": "Didn't ask",
          "text": "Didn't ask"
        },
        {
          "key": "Yes",
          "text": "Yes"
        },
        {
          "key": "No",
          "text": "No"
        }
      ],
      "tableColumn": "9",
      "fqn": "biopsychosocial.domesticViolence"
    },
    {
      "elementKey": "label21-5",
      "formIndex": "1",
      "formCss": "grid-span-3-columns",
      "inputType": "form_label",
      "fqn": "biopsychosocial.label21-5"
    },
    {
      "elementKey": "requestContact",
      "formIndex": "1",
      "inputType": "select",
      "options": [
        {
          "key": "Didn't ask",
          "text": "Didn't ask"
        },
        {
          "key": "Yes",
          "text": "Yes"
        },
        {
          "key": "No",
          "text": "No"
        }
      ],
      "tableColumn": "10",
      "fqn": "biopsychosocial.requestContact"
    },
    {
      "elementKey": "supportPerson",
      "formIndex": "1",
      "formCss": "grid-new-subsection",
      "inputType": "text",
      "tableColumn": "11",
      "fqn": "biopsychosocial.supportPerson"
    },
    {
      "elementKey": "supportComments",
      "formIndex": "1",
      "formCss": "grid-span-2-columns",
      "inputType": "textarea",
      "tableColumn": "12",
      "fqn": "biopsychosocial.supportComments"
    },
    {
      "elementKey": "securityPolicePresent",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "13",
      "fqn": "biopsychosocial.securityPolicePresent"
    },
    {
      "elementKey": "securityComments",
      "formIndex": "1",
      "formCss": "grid-span-2-columns",
      "inputType": "textarea",
      "tableColumn": "14",
      "fqn": "biopsychosocial.securityComments"
    },
    {
      "elementKey": "patientRestrained",
      "formIndex": "1",
      "inputType": "select",
      "options": [
        {
          "key": "Yes",
          "text": "Yes"
        },
        {
          "key": "No",
          "text": "No"
        }
      ],
      "tableColumn": "15",
      "fqn": "biopsychosocial.patientRestrained"
    },
    {
      "elementKey": "restraintDay",
      "formIndex": "1",
      "inputType": "visitDay",
      "tableColumn": "16",
      "validation": "visitDay",
      "fqn": "biopsychosocial.restraintDay"
    },
    {
      "elementKey": "restraintTime",
      "formIndex": "1",
      "inputType": "visitTime",
      "tableColumn": "17",
      "validation": "time24",
      "fqn": "biopsychosocial.restraintTime"
    },
    {
      "elementKey": "restraintComments",
      "formIndex": "1",
      "formCss": "grid-span-3-columns",
      "inputType": "textarea",
      "tableColumn": "18",
      "fqn": "biopsychosocial.restraintComments"
    },
    {
      "elementKey": "generalComments",
      "formIndex": "1",
      "inputType": "textarea",
      "tableColumn": "19",
      "fqn": "biopsychosocial.generalComments"
    }
  ],
  "pageElements": {
    "table": {
      "elementKey": "table",
      "pageElementIndex": "1",
      "pageKey": "biopsychosocial",
      "tableKey": "table",
      "isTable": true,
      "form": {
        "elementKey": "table",
        "label": "Biopsychosocial assessment",
        "addButtonText": "Add a biopsychosocial assessment",
        "formOption": "transpose",
        "formKey": "table",
        "ehr_groups": [
          {
            "elementKey": "hdrGroup",
            "formCss": "record-header",
            "fqn": "biopsychosocial.hdrGroup",
            "gIndex": "1",
            "gChildren": [
              "table_name",
              "table_profession",
              "table_day",
              "table_time"
            ]
          },
          {
            "elementKey": "cGroup21-2",
            "formCss": "grid-left-to-right-3",
            "fqn": "biopsychosocial.cGroup21-2",
            "gIndex": "2",
            "gChildren": [
              "hygieneGrooming",
              "hygieneComments",
              "behaviour"
            ]
          },
          {
            "elementKey": "state",
            "formCss": "grid-left-to-right-3",
            "fqn": "biopsychosocial.state",
            "gIndex": "3",
            "gChildren": [
              "hallucinations",
              "suicidal",
              "homicidal",
              "speech"
            ]
          },
          {
            "elementKey": "support",
            "formCss": "grid-left-to-right-3",
            "fqn": "biopsychosocial.support",
            "gIndex": "4",
            "gChildren": [
              "label21-4",
              "domesticViolence",
              "label21-5",
              "requestContact",
              "supportPerson",
              "supportComments"
            ]
          },
          {
            "elementKey": "security",
            "formCss": "grid-left-to-right-3",
            "fqn": "biopsychosocial.security",
            "gIndex": "5",
            "gChildren": [
              "securityPolicePresent",
              "securityComments",
              "patientRestrained",
              "restraintDay",
              "restraintTime",
              "restraintComments"
            ]
          },
          {
            "elementKey": "cGroup21-6",
            "formCss": "grid-left-to-right-1",
            "fqn": "biopsychosocial.cGroup21-6",
            "gIndex": "6",
            "gChildren": [
              "generalComments"
            ]
          }
        ],
        "ehr_data": {
          "table_name": "",
          "table_profession": "",
          "table_day": "",
          "table_time": "",
          "hygieneGrooming": "",
          "hygieneComments": "",
          "behaviour": "",
          "hallucinations": "",
          "suicidal": "",
          "homicidal": "",
          "speech": "",
          "domesticViolence": "",
          "requestContact": "",
          "supportPerson": "",
          "supportComments": "",
          "securityPolicePresent": "",
          "securityComments": "",
          "patientRestrained": "",
          "restraintDay": "",
          "restraintTime": "",
          "restraintComments": "",
          "generalComments": ""
        }
      },
      "fqn": "biopsychosocial.table",
      "tableChildren": [
        "table_id",
        "table_day",
        "table_time",
        "hygieneGrooming",
        "hygieneComments",
        "behaviour",
        "hallucinations",
        "suicidal",
        "homicidal",
        "speech",
        "domesticViolence",
        "requestContact",
        "supportPerson",
        "supportComments",
        "securityPolicePresent",
        "securityComments",
        "patientRestrained",
        "restraintDay",
        "restraintTime",
        "restraintComments",
        "generalComments",
        "table_name",
        "table_profession"
      ],
      "hasRecHeader": true
    }
  },
  "path": "/ehr/current/assessments",
  "routeName": "biopsychosocial",
  "label": "Biopsychosocial assessment",
  "redirectEhr": "",
  "redirectLis": "",
  "ehr": "yes",
  "fullPageKey": "current.assessments.biopsychosocial"
}
export default DEFS