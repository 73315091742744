/* eslint-disable */
const DEFS = {
  "pageDataKey": "linesDevices",
  "pIndex": "59",
  "hasGridTable": true,
  "pageChildren": [
    {
      "elementKey": "chestTubeProfile_name",
      "formIndex": "1",
      "inputType": "practitionerName",
      "tableColumn": "1",
      "recHeader": true,
      "fqn": "linesDevices.name"
    },
    {
      "elementKey": "chestTubeProfile_id",
      "inputType": "generatedId",
      "tableColumn": 0,
      "label": "Row id",
      "tableCss": "row-id"
    },
    {
      "elementKey": "chestTubeProfile_profession",
      "formIndex": "1",
      "inputType": "practitionerProfession",
      "tableColumn": "1",
      "recHeader": true,
      "fqn": "linesDevices.profession"
    },
    {
      "elementKey": "chestTubeProfile_day",
      "formIndex": "1",
      "inputType": "visitDay",
      "mandatory": true,
      "tableColumn": "1",
      "validation": "visitDay",
      "recHeader": true,
      "fqn": "linesDevices.day"
    },
    {
      "elementKey": "chestTubeProfile_time",
      "formIndex": "1",
      "inputType": "visitTime",
      "mandatory": true,
      "tableColumn": "1",
      "validation": "time24",
      "recHeader": true,
      "fqn": "linesDevices.time"
    },
    {
      "elementKey": "tubeLabel",
      "formIndex": "1",
      "inputType": "select",
      "options": [
        {
          "key": "Chest Tube A",
          "text": "Chest Tube A"
        },
        {
          "key": "Chest Tube B",
          "text": "Chest Tube B"
        },
        {
          "key": "Chest Tube C",
          "text": "Chest Tube C"
        },
        {
          "key": "Chest Tube D",
          "text": "Chest Tube D"
        }
      ],
      "tableColumn": "2",
      "fqn": "linesDevices.tubeLabel"
    },
    {
      "elementKey": "tubeLocation",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "3",
      "fqn": "linesDevices.tubeLocation"
    },
    {
      "elementKey": "tubeReason",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "4",
      "fqn": "linesDevices.tubeReason"
    },
    {
      "elementKey": "tubeSuction",
      "defaultValue": "--tubeSuction--",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "5",
      "fqn": "linesDevices.tubeSuction"
    },
    {
      "elementKey": "tubeInsertDay",
      "formIndex": "1",
      "inputType": "visitDay",
      "tableColumn": "6",
      "validation": "visitDay",
      "fqn": "linesDevices.tubeInsertDay"
    },
    {
      "elementKey": "tubeInsertTime",
      "formIndex": "1",
      "inputType": "visitTime",
      "tableColumn": "7",
      "validation": "time24",
      "fqn": "linesDevices.tubeInsertTime"
    },
    {
      "elementKey": "tubeRmovalDay",
      "formIndex": "1",
      "inputType": "visitDay",
      "tableAction": "allowChange",
      "tableColumn": "8",
      "validation": "visitDay",
      "fqn": "linesDevices.tubeRmovalDay"
    },
    {
      "elementKey": "tubeRemovalTime",
      "formIndex": "1",
      "inputType": "visitTime",
      "tableAction": "allowChange",
      "tableColumn": "9",
      "validation": "time24",
      "fqn": "linesDevices.tubeRemovalTime"
    },
    {
      "elementKey": "comments",
      "formIndex": "1",
      "formCss": "grid-span-3-columns",
      "inputType": "textarea",
      "tableAction": "allowChange",
      "tableColumn": "10",
      "fqn": "linesDevices.comments"
    },
    {
      "elementKey": "chestTubeAssessment_name",
      "formIndex": "2",
      "inputType": "practitionerName",
      "tableColumn": "1",
      "recHeader": true,
      "fqn": "linesDevices.name"
    },
    {
      "elementKey": "chestTubeAssessment_id",
      "inputType": "generatedId",
      "tableColumn": 0,
      "label": "Row id",
      "tableCss": "row-id"
    },
    {
      "elementKey": "chestTubeAssessment_profession",
      "formIndex": "2",
      "inputType": "practitionerProfession",
      "tableColumn": "1",
      "recHeader": true,
      "fqn": "linesDevices.profession"
    },
    {
      "elementKey": "chestTubeAssessment_day",
      "formIndex": "2",
      "inputType": "visitDay",
      "mandatory": true,
      "tableColumn": "1",
      "validation": "visitDay",
      "recHeader": true,
      "fqn": "linesDevices.day"
    },
    {
      "elementKey": "chestTubeAssessment_time",
      "formIndex": "2",
      "inputType": "visitTime",
      "mandatory": true,
      "tableColumn": "1",
      "validation": "time24",
      "recHeader": true,
      "fqn": "linesDevices.time"
    },
    {
      "elementKey": "ctaLabel",
      "formIndex": "2",
      "inputType": "select",
      "options": [
        {
          "key": "Chest Tube A",
          "text": "Chest Tube A"
        },
        {
          "key": "Chest Tube B",
          "text": "Chest Tube B"
        },
        {
          "key": "Chest Tube C",
          "text": "Chest Tube C"
        },
        {
          "key": "Chest Tube D",
          "text": "Chest Tube D"
        }
      ],
      "tableColumn": "2",
      "fqn": "linesDevices.ctaLabel"
    },
    {
      "elementKey": "ctaLabel3",
      "formIndex": "2",
      "inputType": "form_label",
      "fqn": "linesDevices.ctaLabel3"
    },
    {
      "elementKey": "ctaPatient",
      "formIndex": "2",
      "inputType": "text",
      "tableColumn": "3",
      "fqn": "linesDevices.ctaPatient"
    },
    {
      "elementKey": "ctaLabel4",
      "formIndex": "2",
      "inputType": "form_label",
      "fqn": "linesDevices.ctaLabel4"
    },
    {
      "elementKey": "ctaDressing",
      "formIndex": "2",
      "inputType": "checkbox",
      "tableColumn": "4",
      "fqn": "linesDevices.ctaDressing"
    },
    {
      "elementKey": "ctaLabel5",
      "formIndex": "2",
      "inputType": "form_label",
      "fqn": "linesDevices.ctaLabel5"
    },
    {
      "elementKey": "ctaChanged",
      "formIndex": "2",
      "inputType": "checkbox",
      "tableColumn": "5",
      "fqn": "linesDevices.ctaChanged"
    },
    {
      "elementKey": "ctaLabel6",
      "formIndex": "2",
      "inputType": "form_label",
      "fqn": "linesDevices.ctaLabel6"
    },
    {
      "elementKey": "ctaSubcutaneous",
      "formIndex": "2",
      "formCss": "radioset-inline",
      "inputType": "radioset",
      "options": [
        {
          "key": "y",
          "text": "Yes"
        },
        {
          "key": "n",
          "text": "No"
        }
      ],
      "tableColumn": "6",
      "fqn": "linesDevices.ctaSubcutaneous"
    },
    {
      "elementKey": "ctaLabel7",
      "formIndex": "2",
      "inputType": "form_label",
      "fqn": "linesDevices.ctaLabel7"
    },
    {
      "elementKey": "ctaSmallBore",
      "formIndex": "2",
      "formCss": "radioset-inline",
      "inputType": "radioset",
      "options": [
        {
          "key": "y",
          "text": "Yes"
        },
        {
          "key": "n",
          "text": "No"
        }
      ],
      "tableColumn": "7",
      "fqn": "linesDevices.ctaSmallBore"
    },
    {
      "elementKey": "ctaLabel8",
      "formIndex": "2",
      "inputType": "form_label",
      "fqn": "linesDevices.ctaLabel8"
    },
    {
      "elementKey": "ctaConnections",
      "formIndex": "2",
      "formCss": "radioset-inline",
      "inputType": "radioset",
      "options": [
        {
          "key": "y",
          "text": "Yes"
        },
        {
          "key": "n",
          "text": "No"
        }
      ],
      "tableColumn": "8",
      "fqn": "linesDevices.ctaConnections"
    },
    {
      "elementKey": "ctaLabel9",
      "formIndex": "2",
      "inputType": "form_label",
      "fqn": "linesDevices.ctaLabel9"
    },
    {
      "elementKey": "ctaTubing",
      "formIndex": "2",
      "formCss": "radioset-inline",
      "inputType": "radioset",
      "options": [
        {
          "key": "y",
          "text": "Yes"
        },
        {
          "key": "n",
          "text": "No"
        }
      ],
      "tableColumn": "9",
      "fqn": "linesDevices.ctaTubing"
    },
    {
      "elementKey": "ctaLabel10",
      "formIndex": "2",
      "inputType": "form_label",
      "fqn": "linesDevices.ctaLabel10"
    },
    {
      "elementKey": "ctaBed",
      "formIndex": "2",
      "formCss": "radioset-inline",
      "inputType": "radioset",
      "options": [
        {
          "key": "y",
          "text": "Yes"
        },
        {
          "key": "n",
          "text": "No"
        }
      ],
      "tableColumn": "10",
      "fqn": "linesDevices.ctaBed"
    },
    {
      "elementKey": "ctaLabel11",
      "formIndex": "2",
      "inputType": "form_label",
      "fqn": "linesDevices.ctaLabel11"
    },
    {
      "elementKey": "ctaBreathing",
      "formIndex": "2",
      "formCss": "radioset-inline",
      "inputType": "radioset",
      "options": [
        {
          "key": "y",
          "text": "Yes"
        },
        {
          "key": "n",
          "text": "No"
        }
      ],
      "tableColumn": "11",
      "fqn": "linesDevices.ctaBreathing"
    },
    {
      "elementKey": "ctaLabel12",
      "formIndex": "2",
      "inputType": "form_label",
      "fqn": "linesDevices.ctaLabel12"
    },
    {
      "elementKey": "ctaPleurEvac",
      "formIndex": "2",
      "formCss": "radioset-inline",
      "inputType": "radioset",
      "options": [
        {
          "key": "y",
          "text": "Yes"
        },
        {
          "key": "n",
          "text": "No"
        }
      ],
      "tableColumn": "12",
      "fqn": "linesDevices.ctaPleurEvac"
    },
    {
      "elementKey": "ctaLabel13",
      "formIndex": "2",
      "inputType": "form_label",
      "fqn": "linesDevices.ctaLabel13"
    },
    {
      "elementKey": "ctaSuctionControlDial",
      "formIndex": "2",
      "inputType": "text",
      "tableColumn": "13",
      "fqn": "linesDevices.ctaSuctionControlDial"
    },
    {
      "elementKey": "ctaLabel14",
      "formIndex": "2",
      "inputType": "form_label",
      "fqn": "linesDevices.ctaLabel14"
    },
    {
      "elementKey": "ctaOrangeFloat",
      "formIndex": "2",
      "formCss": "radioset-inline",
      "inputType": "radioset",
      "options": [
        {
          "key": "y",
          "text": "Yes"
        },
        {
          "key": "n",
          "text": "No"
        }
      ],
      "tableColumn": "14",
      "fqn": "linesDevices.ctaOrangeFloat"
    },
    {
      "elementKey": "ctaLabel15",
      "formIndex": "2",
      "inputType": "form_label",
      "fqn": "linesDevices.ctaLabel15"
    },
    {
      "elementKey": "ctaAirLeakMeter",
      "formIndex": "2",
      "formCss": "radioset-inline",
      "inputType": "radioset",
      "options": [
        {
          "key": "y",
          "text": "Yes"
        },
        {
          "key": "n",
          "text": "No"
        }
      ],
      "tableColumn": "15",
      "fqn": "linesDevices.ctaAirLeakMeter"
    },
    {
      "elementKey": "ctaLabel16",
      "formIndex": "2",
      "inputType": "form_label",
      "fqn": "linesDevices.ctaLabel16"
    },
    {
      "elementKey": "ctaAirLeakReading",
      "formIndex": "2",
      "inputType": "text",
      "options": [
        {
          "key": "y",
          "text": "Yes"
        },
        {
          "key": "n",
          "text": "No"
        }
      ],
      "tableColumn": "16",
      "fqn": "linesDevices.ctaAirLeakReading"
    },
    {
      "elementKey": "ctaLabel17",
      "formIndex": "2",
      "inputType": "form_label",
      "fqn": "linesDevices.ctaLabel17"
    },
    {
      "elementKey": "ctaTidaling",
      "formIndex": "2",
      "formCss": "radioset-inline",
      "inputType": "radioset",
      "options": [
        {
          "key": "y",
          "text": "Yes"
        },
        {
          "key": "n",
          "text": "No"
        }
      ],
      "tableColumn": "17",
      "fqn": "linesDevices.ctaTidaling"
    },
    {
      "elementKey": "ctaLabel18",
      "formIndex": "2",
      "inputType": "form_label",
      "fqn": "linesDevices.ctaLabel18"
    },
    {
      "elementKey": "ctaNegativePressure",
      "formIndex": "2",
      "inputType": "radioset",
      "options": [
        {
          "key": "I",
          "text": "Intermittent: no wall suction seen with respirations"
        },
        {
          "key": "Y",
          "text": "Yes on wall suction, seen continuously"
        }
      ],
      "tableColumn": "18",
      "fqn": "linesDevices.ctaNegativePressure"
    },
    {
      "elementKey": "ctaLabel19",
      "formIndex": "2",
      "inputType": "form_label",
      "fqn": "linesDevices.ctaLabel19"
    },
    {
      "elementKey": "ctaDrainageStatus",
      "formIndex": "2",
      "formCss": "radioset-inline",
      "inputType": "radioset",
      "options": [
        {
          "key": "y",
          "text": "Yes"
        },
        {
          "key": "n",
          "text": "No"
        }
      ],
      "tableColumn": "19",
      "fqn": "linesDevices.ctaDrainageStatus"
    },
    {
      "elementKey": "ctaLabel20",
      "formIndex": "2",
      "inputType": "form_label",
      "fqn": "linesDevices.ctaLabel20"
    },
    {
      "elementKey": "ctaDrainageType",
      "formIndex": "2",
      "inputType": "select",
      "options": [
        {
          "key": "s",
          "text": "Serous"
        },
        {
          "key": "sa",
          "text": "Sanguinous"
        },
        {
          "key": "ss",
          "text": "Serosanguinous"
        },
        {
          "key": "p",
          "text": "Purulent"
        },
        {
          "key": "n",
          "text": "None"
        }
      ],
      "tableColumn": "20",
      "fqn": "linesDevices.ctaDrainageType"
    },
    {
      "elementKey": "ctaLabel21",
      "formIndex": "2",
      "inputType": "form_label",
      "fqn": "linesDevices.ctaLabel21"
    },
    {
      "elementKey": "ctaEmergEquip",
      "formIndex": "2",
      "inputType": "checkbox",
      "tableColumn": "21",
      "fqn": "linesDevices.ctaEmergEquip"
    },
    {
      "elementKey": "ctaEquipList",
      "formIndex": "2",
      "inputType": "form_label",
      "fqn": "linesDevices.ctaEquipList"
    },
    {
      "elementKey": "enteralProfile_name",
      "formIndex": "3",
      "inputType": "practitionerName",
      "tableColumn": "1",
      "recHeader": true,
      "fqn": "linesDevices.name"
    },
    {
      "elementKey": "enteralProfile_id",
      "inputType": "generatedId",
      "tableColumn": 0,
      "label": "Row id",
      "tableCss": "row-id"
    },
    {
      "elementKey": "enteralProfile_profession",
      "formIndex": "3",
      "inputType": "practitionerProfession",
      "tableColumn": "1",
      "recHeader": true,
      "fqn": "linesDevices.profession"
    },
    {
      "elementKey": "enteralProfile_day",
      "formIndex": "3",
      "inputType": "visitDay",
      "mandatory": true,
      "tableColumn": "1",
      "validation": "visitDay",
      "recHeader": true,
      "fqn": "linesDevices.day"
    },
    {
      "elementKey": "enteralProfile_time",
      "formIndex": "3",
      "inputType": "visitTime",
      "mandatory": true,
      "tableColumn": "1",
      "validation": "time24",
      "recHeader": true,
      "fqn": "linesDevices.time"
    },
    {
      "elementKey": "enteralLabel",
      "formIndex": "3",
      "inputType": "select",
      "options": [
        {
          "key": "enteralA",
          "text": "Enteral A"
        },
        {
          "key": "enteralB",
          "text": "Enteral B"
        },
        {
          "key": "enteralC",
          "text": "Enteral C"
        }
      ],
      "tableColumn": "2",
      "fqn": "linesDevices.enteralLabel"
    },
    {
      "elementKey": "enteralPurpose",
      "formIndex": "3",
      "inputType": "select",
      "options": [
        {
          "key": "decompression",
          "text": "Decompression"
        },
        {
          "key": "medAdmin",
          "text": "Medication administration"
        },
        {
          "key": "aspiration",
          "text": "Aspiration"
        },
        {
          "key": "gastricLavage",
          "text": "Gastric lavage"
        },
        {
          "key": "enteralNutrition",
          "text": "Enteral nutrition"
        },
        {
          "key": "other",
          "text": "Other"
        }
      ],
      "tableColumn": "3",
      "fqn": "linesDevices.enteralPurpose"
    },
    {
      "elementKey": "enteralStatus",
      "formIndex": "3",
      "inputType": "select",
      "options": [
        {
          "key": "active",
          "text": "Active"
        },
        {
          "key": "discontinued",
          "text": "Discontinued"
        },
        {
          "key": "replaced",
          "text": "Replaced"
        },
        {
          "key": "removed",
          "text": "Removed"
        },
        {
          "key": "pending",
          "text": "Pending"
        },
        {
          "key": "complicated",
          "text": "Complicated"
        },
        {
          "key": "resolved",
          "text": "Resolved"
        }
      ],
      "tableAction": "allowChange",
      "tableColumn": "4",
      "fqn": "linesDevices.enteralStatus"
    },
    {
      "elementKey": "enteralType",
      "formIndex": "3",
      "inputType": "select",
      "options": [
        {
          "key": "ng",
          "text": "Nasogastric (NG)"
        },
        {
          "key": "og",
          "text": "Orogastric (OG)"
        },
        {
          "key": "gTube",
          "text": "Gastrostomy (G-tube)"
        },
        {
          "key": "jTube",
          "text": "Jejunostomy (J-tube)"
        },
        {
          "key": "gJTube",
          "text": "Gastrojejunostomy (G-J tube)"
        },
        {
          "key": "peg",
          "text": "Percutaneous Endoscopic Gastrostomy (PEG)"
        },
        {
          "key": "pej",
          "text": "Percutaneous Endoscopic Jejunostomy (PEJ)"
        },
        {
          "key": "surgicalGTube",
          "text": "Surgically Placed Gastrostomy Tube"
        },
        {
          "key": "surgicalJTube",
          "text": "Surgically Placed Jejunostomy Tube"
        },
        {
          "key": "nasojejunal",
          "text": "Nasojejunal Tube"
        },
        {
          "key": "nasoduodenal",
          "text": "Nasoduodenal Tube"
        }
      ],
      "tableColumn": "5",
      "fqn": "linesDevices.enteralType"
    },
    {
      "elementKey": "enteralLocation",
      "formIndex": "3",
      "inputType": "select",
      "options": [
        {
          "key": "right",
          "text": "Right nare"
        },
        {
          "key": "left",
          "text": "Left nare"
        },
        {
          "key": "orogastric",
          "text": "Orogastric"
        },
        {
          "key": "stomach",
          "text": "Stomach"
        },
        {
          "key": "duodenum",
          "text": "Duodenum"
        },
        {
          "key": "jejunum",
          "text": "Jejunum"
        }
      ],
      "tableColumn": "6",
      "fqn": "linesDevices.enteralLocation"
    },
    {
      "elementKey": "enteralGauge",
      "formIndex": "3",
      "inputType": "decimal2",
      "suffix": "fr",
      "tableColumn": "7",
      "fqn": "linesDevices.enteralGauge",
      "suffixText": "fr"
    },
    {
      "elementKey": "enteralLength",
      "formIndex": "3",
      "inputType": "decimal2",
      "suffix": "cm",
      "tableColumn": "8",
      "fqn": "linesDevices.enteralLength",
      "suffixText": "cm"
    },
    {
      "elementKey": "enteralFeedFormula",
      "formIndex": "3",
      "inputType": "text",
      "tableColumn": "9",
      "fqn": "linesDevices.enteralFeedFormula"
    },
    {
      "elementKey": "enteralFeedMethod",
      "formIndex": "3",
      "inputType": "select",
      "options": [
        {
          "key": "continuous",
          "text": "Continuous feeding"
        },
        {
          "key": "bolus",
          "text": "Bolus feeding"
        },
        {
          "key": "gravity",
          "text": "Gravity feeding"
        },
        {
          "key": "pump",
          "text": "Feeding with an enteral pump"
        },
        {
          "key": "intermittent",
          "text": "Intermittent feeding"
        },
        {
          "key": "none",
          "text": "No feeding (for decompression or other purposes)"
        }
      ],
      "tableColumn": "10",
      "fqn": "linesDevices.enteralFeedMethod"
    },
    {
      "elementKey": "enteralFeedRate",
      "formIndex": "3",
      "inputType": "decimal2",
      "suffix": "mmHg",
      "tableColumn": "11",
      "fqn": "linesDevices.enteralFeedRate",
      "suffixText": "mmHg"
    },
    {
      "elementKey": "enteralPh",
      "formIndex": "3",
      "inputType": "decimal2",
      "tableColumn": "12",
      "fqn": "linesDevices.enteralPh"
    },
    {
      "elementKey": "enteralPlacement",
      "formIndex": "3",
      "inputType": "select",
      "options": [
        {
          "key": "xray",
          "text": "X-ray"
        },
        {
          "key": "phTest",
          "text": "pH test"
        },
        {
          "key": "auscultation",
          "text": "Auscultation (air bolus method)"
        },
        {
          "key": "endoscopy",
          "text": "Endoscopic placement"
        },
        {
          "key": "fluoroscopy",
          "text": "Fluoroscopic verification"
        },
        {
          "key": "surgical",
          "text": "Surgical placement"
        },
        {
          "key": "clinicalAssessment",
          "text": "Clinical assessment"
        }
      ],
      "tableColumn": "13",
      "fqn": "linesDevices.enteralPlacement"
    },
    {
      "elementKey": "enteralSuctionType",
      "formIndex": "3",
      "inputType": "select",
      "options": [
        {
          "key": "lowIntermittent",
          "text": "Low intermittent suction"
        },
        {
          "key": "continuous",
          "text": "Continuous suction"
        },
        {
          "key": "intermittent",
          "text": "Intermittent suction"
        },
        {
          "key": "highIntermittent",
          "text": "High intermittent suction"
        },
        {
          "key": "none",
          "text": "No suction"
        }
      ],
      "tableColumn": "14",
      "fqn": "linesDevices.enteralSuctionType"
    },
    {
      "elementKey": "enteralSuctionPressure",
      "formIndex": "3",
      "inputType": "decimal2",
      "suffix": "mmHg",
      "tableColumn": "15",
      "fqn": "linesDevices.enteralSuctionPressure",
      "suffixText": "mmHg"
    },
    {
      "elementKey": "enteralSecurement",
      "formIndex": "3",
      "inputType": "select",
      "options": [
        {
          "key": "nasalTape",
          "text": "Nasal tape"
        },
        {
          "key": "adhesiveDressings",
          "text": "Adhesive dressings"
        },
        {
          "key": "tubeHolders",
          "text": "Tube holders"
        },
        {
          "key": "suture",
          "text": "Suture (less common)"
        },
        {
          "key": "elasticBands",
          "text": "Elastic bands"
        },
        {
          "key": "strapsFasteners",
          "text": "Straps or fasteners"
        }
      ],
      "tableColumn": "16",
      "fqn": "linesDevices.enteralSecurement"
    },
    {
      "elementKey": "enteralInsertDay",
      "formIndex": "3",
      "inputType": "visitDay",
      "tableColumn": "17",
      "validation": "visitDay",
      "fqn": "linesDevices.enteralInsertDay"
    },
    {
      "elementKey": "enteralInsertTime",
      "formIndex": "3",
      "inputType": "visitTime",
      "tableColumn": "18",
      "validation": "time24",
      "fqn": "linesDevices.enteralInsertTime"
    },
    {
      "elementKey": "enteralRemovalDay",
      "formIndex": "3",
      "inputType": "visitDay",
      "tableAction": "allowChange",
      "tableColumn": "19",
      "validation": "visitDay",
      "fqn": "linesDevices.enteralRemovalDay"
    },
    {
      "elementKey": "enteralRemovalTime",
      "formIndex": "3",
      "inputType": "visitTime",
      "tableAction": "allowChange",
      "tableColumn": "20",
      "validation": "time24",
      "fqn": "linesDevices.enteralRemovalTime"
    },
    {
      "elementKey": "enteralComments",
      "formIndex": "3",
      "inputType": "textarea",
      "tableAction": "allowChange",
      "tableColumn": "21",
      "fqn": "linesDevices.enteralComments"
    },
    {
      "elementKey": "enteralSupportAssessment_name",
      "formIndex": "4",
      "inputType": "practitionerName",
      "tableColumn": "1",
      "recHeader": true,
      "fqn": "linesDevices.name"
    },
    {
      "elementKey": "enteralSupportAssessment_id",
      "inputType": "generatedId",
      "tableColumn": 0,
      "label": "Row id",
      "tableCss": "row-id"
    },
    {
      "elementKey": "enteralSupportAssessment_profession",
      "formIndex": "4",
      "inputType": "practitionerProfession",
      "tableColumn": "1",
      "recHeader": true,
      "fqn": "linesDevices.profession"
    },
    {
      "elementKey": "enteralSupportAssessment_day",
      "formIndex": "4",
      "inputType": "visitDay",
      "mandatory": true,
      "tableColumn": "1",
      "validation": "visitDay",
      "recHeader": true,
      "fqn": "linesDevices.day"
    },
    {
      "elementKey": "enteralSupportAssessment_time",
      "formIndex": "4",
      "inputType": "visitTime",
      "mandatory": true,
      "tableColumn": "1",
      "validation": "time24",
      "recHeader": true,
      "fqn": "linesDevices.time"
    },
    {
      "elementKey": "entassLabel",
      "formIndex": "4",
      "inputType": "select",
      "options": [
        {
          "key": "enteralA",
          "text": "Enteral A"
        },
        {
          "key": "enteralB",
          "text": "Enteral B"
        },
        {
          "key": "enteralC",
          "text": "Enteral C"
        }
      ],
      "tableColumn": "2",
      "fqn": "linesDevices.entassLabel"
    },
    {
      "elementKey": "entassComplications",
      "formIndex": "4",
      "formCss": "grid-span-3-columns",
      "inputType": "checkset",
      "options": [
        {
          "key": "dislodged",
          "text": "Dislodged"
        },
        {
          "key": "obstruction",
          "text": "Obstruction"
        },
        {
          "key": "leakage",
          "text": "Leakage"
        },
        {
          "key": "infection",
          "text": "Infection"
        },
        {
          "key": "bleeding",
          "text": "Bleeding"
        },
        {
          "key": "pain",
          "text": "Pain"
        },
        {
          "key": "aspiration",
          "text": "Aspiration"
        },
        {
          "key": "perforation",
          "text": "Perforation"
        },
        {
          "key": "vomiting",
          "text": "Vomiting"
        },
        {
          "key": "gastrointestinal",
          "text": "Gastrointestinal issues"
        }
      ],
      "tableColumn": "3",
      "fqn": "linesDevices.entassComplications"
    },
    {
      "elementKey": "entassPlacement",
      "formIndex": "4",
      "inputType": "select",
      "options": [
        {
          "key": "secure",
          "text": "Secure"
        },
        {
          "key": "dislodged",
          "text": "Dislodged"
        },
        {
          "key": "atRisk",
          "text": "At risk"
        }
      ],
      "tableColumn": "4",
      "fqn": "linesDevices.entassPlacement"
    },
    {
      "elementKey": "entassDischarge",
      "formIndex": "4",
      "inputType": "select",
      "options": [
        {
          "key": "bilious",
          "text": "Bilious"
        },
        {
          "key": "brown_tan",
          "text": "Brown/Tan"
        },
        {
          "key": "feculent",
          "text": "Feculent"
        },
        {
          "key": "mucous",
          "text": "Mucous"
        },
        {
          "key": "purulent",
          "text": "Purulent"
        },
        {
          "key": "sanguineous",
          "text": "Sanguineous"
        },
        {
          "key": "serosanguineous",
          "text": "Serosanguineous"
        },
        {
          "key": "serous",
          "text": "Serous"
        },
        {
          "key": "other",
          "text": "Other"
        }
      ],
      "tableColumn": "5",
      "fqn": "linesDevices.entassDischarge"
    },
    {
      "elementKey": "entassAmount",
      "formIndex": "4",
      "inputType": "decimal2",
      "suffix": "mL",
      "tableColumn": "6",
      "fqn": "linesDevices.entassAmount",
      "suffixText": "mL"
    },
    {
      "elementKey": "entassPatency",
      "formIndex": "4",
      "inputType": "checkset",
      "options": [
        {
          "key": "patent",
          "text": "Patent"
        },
        {
          "key": "sluggish",
          "text": "Sluggish"
        },
        {
          "key": "occluded",
          "text": "Occluded"
        }
      ],
      "tableColumn": "7",
      "fqn": "linesDevices.entassPatency"
    },
    {
      "elementKey": "entassSkin",
      "formIndex": "4",
      "inputType": "select",
      "options": [
        {
          "key": "intact",
          "text": "Intact"
        },
        {
          "key": "erythema",
          "text": "Erythema"
        },
        {
          "key": "irritation",
          "text": "Irritation"
        },
        {
          "key": "breakdown",
          "text": "Breakdown"
        },
        {
          "key": "infection",
          "text": "Infection"
        },
        {
          "key": "exudate",
          "text": "Exudate"
        },
        {
          "key": "dryness",
          "text": "Dryness"
        },
        {
          "key": "edema",
          "text": "Edema"
        }
      ],
      "tableColumn": "8",
      "fqn": "linesDevices.entassSkin"
    },
    {
      "elementKey": "entassComments",
      "formIndex": "4",
      "inputType": "textarea",
      "tableColumn": "9",
      "fqn": "linesDevices.entassComments"
    },
    {
      "elementKey": "periphIvProfile_name",
      "formIndex": "5",
      "inputType": "practitionerName",
      "tableColumn": "1",
      "recHeader": true,
      "fqn": "linesDevices.name"
    },
    {
      "elementKey": "periphIvProfile_id",
      "inputType": "generatedId",
      "tableColumn": 0,
      "label": "Row id",
      "tableCss": "row-id"
    },
    {
      "elementKey": "periphIvProfile_profession",
      "formIndex": "5",
      "inputType": "practitionerProfession",
      "tableColumn": "1",
      "recHeader": true,
      "fqn": "linesDevices.profession"
    },
    {
      "elementKey": "periphIvProfile_day",
      "formIndex": "5",
      "inputType": "visitDay",
      "mandatory": true,
      "tableColumn": "1",
      "validation": "visitDay",
      "recHeader": true,
      "fqn": "linesDevices.day"
    },
    {
      "elementKey": "periphIvProfile_time",
      "formIndex": "5",
      "inputType": "visitTime",
      "mandatory": true,
      "tableColumn": "1",
      "validation": "time24",
      "recHeader": true,
      "fqn": "linesDevices.time"
    },
    {
      "elementKey": "periphIvLabel",
      "formIndex": "5",
      "inputType": "select",
      "options": [
        {
          "key": "pivA",
          "text": "Peripheral IV A"
        },
        {
          "key": "pivB",
          "text": "Peripheral IV B"
        },
        {
          "key": "pivC",
          "text": "Peripheral IV C"
        },
        {
          "key": "pivD",
          "text": "Peripheral IV D"
        }
      ],
      "tableColumn": "2",
      "fqn": "linesDevices.periphIvLabel"
    },
    {
      "elementKey": "periphIvStatus",
      "formIndex": "5",
      "inputType": "select",
      "options": [
        {
          "key": "active",
          "text": "Active"
        },
        {
          "key": "discontinued",
          "text": "Discontinued"
        },
        {
          "key": "removed",
          "text": "Removed"
        }
      ],
      "tableAction": "allowChange",
      "tableColumn": "3",
      "fqn": "linesDevices.periphIvStatus"
    },
    {
      "elementKey": "periphIvGauge",
      "formIndex": "5",
      "inputType": "select",
      "options": [
        {
          "key": "g14",
          "text": "14"
        },
        {
          "key": "g16",
          "text": "16"
        },
        {
          "key": "g18",
          "text": "18"
        },
        {
          "key": "g20",
          "text": "20"
        },
        {
          "key": "g22",
          "text": "22"
        },
        {
          "key": "g24",
          "text": "24"
        }
      ],
      "tableColumn": "4",
      "fqn": "linesDevices.periphIvGauge"
    },
    {
      "elementKey": "periphIvHemi",
      "formIndex": "5",
      "inputType": "select",
      "options": [
        {
          "key": "left",
          "text": "Left"
        },
        {
          "key": "right",
          "text": "Right"
        }
      ],
      "tableColumn": "5",
      "fqn": "linesDevices.periphIvHemi"
    },
    {
      "elementKey": "periphIvLocation",
      "formIndex": "5",
      "inputType": "select",
      "options": [
        {
          "key": "hand",
          "text": "Dorsal veins (hand)"
        },
        {
          "key": "wrist_cephalic",
          "text": "Cephalic vein (wrist)"
        },
        {
          "key": "wrist_basilic",
          "text": "Basilic vein (wrist)"
        },
        {
          "key": "forearm_cephalic",
          "text": "Cephalic vein (forearm)"
        },
        {
          "key": "forearm_basilic",
          "text": "Basilic vein (forearm)"
        },
        {
          "key": "forearm_median",
          "text": "Median antebrachial vein (forearm)"
        },
        {
          "key": "antecubital_median",
          "text": "Median cubital vein (antecubital fossa)"
        },
        {
          "key": "antecubital_cephalic",
          "text": "Cephalic vein (antecubital fossa)"
        },
        {
          "key": "antecubital_basilic",
          "text": "Basilic vein (antecubital fossa)"
        },
        {
          "key": "foot_dorsal",
          "text": "Dorsal venous arch (foot)"
        },
        {
          "key": "foot_saphenous",
          "text": "Great saphenous vein (foot)"
        },
        {
          "key": "scalp",
          "text": "Scalp veins (neonates/infants)"
        },
        {
          "key": "external_jugular",
          "text": "External jugular vein (emergency use)"
        },
        {
          "key": "other",
          "text": "Other"
        }
      ],
      "tableColumn": "6",
      "fqn": "linesDevices.periphIvLocation"
    },
    {
      "elementKey": "periphIvMethod",
      "formIndex": "5",
      "inputType": "select",
      "options": [
        {
          "key": "manual",
          "text": "Manual insertion"
        },
        {
          "key": "ultrasound",
          "text": "Ultrasound-guided insertion"
        },
        {
          "key": "veinViewer",
          "text": "Vein viewer-guided insertion"
        }
      ],
      "tableColumn": "7",
      "fqn": "linesDevices.periphIvMethod"
    },
    {
      "elementKey": "periphIvSecurement",
      "formIndex": "5",
      "inputType": "select",
      "options": [
        {
          "key": "transparent",
          "text": "Transparent dressing"
        },
        {
          "key": "tape",
          "text": "Tape"
        },
        {
          "key": "gauze",
          "text": "Gauze"
        },
        {
          "key": "sterilePad",
          "text": "Sterile pad"
        }
      ],
      "tableColumn": "8",
      "fqn": "linesDevices.periphIvSecurement"
    },
    {
      "elementKey": "periphIvCircumference",
      "formIndex": "5",
      "inputType": "decimal2",
      "suffix": "cm",
      "tableColumn": "9",
      "fqn": "linesDevices.periphIvCircumference",
      "suffixText": "cm"
    },
    {
      "elementKey": "periphIvInsertDay",
      "formIndex": "5",
      "inputType": "visitDay",
      "tableColumn": "10",
      "validation": "visitDay",
      "fqn": "linesDevices.periphIvInsertDay"
    },
    {
      "elementKey": "periphIvInsertTime",
      "formIndex": "5",
      "inputType": "visitTime",
      "tableColumn": "11",
      "validation": "time24",
      "fqn": "linesDevices.periphIvInsertTime"
    },
    {
      "elementKey": "periphIvRemovalDay",
      "formIndex": "5",
      "inputType": "visitDay",
      "tableAction": "allowChange",
      "tableColumn": "12",
      "validation": "visitDay",
      "fqn": "linesDevices.periphIvRemovalDay"
    },
    {
      "elementKey": "periphIvRemovalTime",
      "formIndex": "5",
      "inputType": "visitTime",
      "tableAction": "allowChange",
      "tableColumn": "13",
      "validation": "time24",
      "fqn": "linesDevices.periphIvRemovalTime"
    },
    {
      "elementKey": "periphIvComments",
      "formIndex": "5",
      "inputType": "textarea",
      "tableAction": "allowChange",
      "tableColumn": "14",
      "fqn": "linesDevices.periphIvComments"
    },
    {
      "elementKey": "pivasSiteAssessment_name",
      "formIndex": "6",
      "inputType": "practitionerName",
      "tableColumn": "1",
      "recHeader": true,
      "fqn": "linesDevices.name"
    },
    {
      "elementKey": "pivasSiteAssessment_id",
      "inputType": "generatedId",
      "tableColumn": 0,
      "label": "Row id",
      "tableCss": "row-id"
    },
    {
      "elementKey": "pivasSiteAssessment_profession",
      "formIndex": "6",
      "inputType": "practitionerProfession",
      "tableColumn": "1",
      "recHeader": true,
      "fqn": "linesDevices.profession"
    },
    {
      "elementKey": "pivasSiteAssessment_day",
      "formIndex": "6",
      "inputType": "visitDay",
      "mandatory": true,
      "tableColumn": "1",
      "validation": "visitDay",
      "recHeader": true,
      "fqn": "linesDevices.day"
    },
    {
      "elementKey": "pivasSiteAssessment_time",
      "formIndex": "6",
      "inputType": "visitTime",
      "mandatory": true,
      "tableColumn": "1",
      "validation": "time24",
      "recHeader": true,
      "fqn": "linesDevices.time"
    },
    {
      "elementKey": "pivasLabel",
      "formIndex": "6",
      "inputType": "select",
      "options": [
        {
          "key": "pivA",
          "text": "Peripheral IV A"
        },
        {
          "key": "pivB",
          "text": "Peripheral IV B"
        },
        {
          "key": "pivC",
          "text": "Peripheral IV C"
        },
        {
          "key": "pivD",
          "text": "Peripheral IV D"
        }
      ],
      "tableColumn": "2",
      "fqn": "linesDevices.pivasLabel"
    },
    {
      "elementKey": "pivasComplications",
      "formIndex": "6",
      "formCss": "grid-span-3-columns",
      "inputType": "checkset",
      "options": [
        {
          "key": "erythema",
          "text": "Erythema"
        },
        {
          "key": "edema",
          "text": "Edema"
        },
        {
          "key": "hematoma",
          "text": "Hematoma"
        },
        {
          "key": "discharge",
          "text": "Discharge"
        },
        {
          "key": "infiltration",
          "text": "Infiltration"
        },
        {
          "key": "extravasation",
          "text": "Extravasation"
        },
        {
          "key": "swelling",
          "text": "Swelling"
        },
        {
          "key": "phlebitis",
          "text": "Phlebitis"
        },
        {
          "key": "thrombophlebitis",
          "text": "Thrombophlebitis"
        },
        {
          "key": "other",
          "text": "Other"
        }
      ],
      "tableColumn": "3",
      "fqn": "linesDevices.pivasComplications"
    },
    {
      "elementKey": "pivasPlacement",
      "formIndex": "6",
      "inputType": "select",
      "options": [
        {
          "key": "secure",
          "text": "Secure"
        },
        {
          "key": "dislodged",
          "text": "Dislodged"
        },
        {
          "key": "atRisk",
          "text": "At risk"
        }
      ],
      "tableColumn": "4",
      "fqn": "linesDevices.pivasPlacement"
    },
    {
      "elementKey": "pivasCircumference",
      "formIndex": "6",
      "inputType": "decimal2",
      "suffix": "cm",
      "tableColumn": "5",
      "fqn": "linesDevices.pivasCircumference",
      "suffixText": "cm"
    },
    {
      "elementKey": "pivasDischarge",
      "formIndex": "6",
      "inputType": "select",
      "options": [
        {
          "key": "bleeding",
          "text": "Bleeding"
        },
        {
          "key": "extravasation",
          "text": "Extravasation"
        },
        {
          "key": "hematoma",
          "text": "Hematoma"
        },
        {
          "key": "infection",
          "text": "Infection"
        },
        {
          "key": "infiltration",
          "text": "Infiltration"
        },
        {
          "key": "occlusion",
          "text": "Occlusion"
        },
        {
          "key": "phlebitis",
          "text": "Phlebitis"
        }
      ],
      "tableColumn": "6",
      "fqn": "linesDevices.pivasDischarge"
    },
    {
      "elementKey": "pivasDisVol",
      "formIndex": "6",
      "inputType": "decimal2",
      "suffix": "mL",
      "tableColumn": "7",
      "fqn": "linesDevices.pivasDisVol",
      "suffixText": "mL"
    },
    {
      "elementKey": "pivasLength",
      "formIndex": "6",
      "inputType": "decimal2",
      "suffix": "cm",
      "tableColumn": "8",
      "fqn": "linesDevices.pivasLength",
      "suffixText": "cm"
    },
    {
      "elementKey": "pivasSiteWarmth",
      "formIndex": "6",
      "inputType": "select",
      "options": [
        {
          "key": "normal",
          "text": "Normal"
        },
        {
          "key": "warm",
          "text": "Warm"
        },
        {
          "key": "hot",
          "text": "Hot"
        }
      ],
      "tableColumn": "9",
      "fqn": "linesDevices.pivasSiteWarmth"
    },
    {
      "elementKey": "pivasSkin",
      "formIndex": "6",
      "inputType": "select",
      "options": [
        {
          "key": "intact",
          "text": "Intact"
        },
        {
          "key": "erythema",
          "text": "Erythema"
        },
        {
          "key": "irritation",
          "text": "Irritation"
        },
        {
          "key": "breakdown",
          "text": "Breakdown"
        },
        {
          "key": "infection",
          "text": "Infection"
        },
        {
          "key": "exudate",
          "text": "Exudate"
        },
        {
          "key": "dryness",
          "text": "Dryness"
        },
        {
          "key": "edema",
          "text": "Edema"
        }
      ],
      "tableColumn": "10",
      "fqn": "linesDevices.pivasSkin"
    },
    {
      "elementKey": "pivasSystemicSymptoms",
      "formIndex": "6",
      "inputType": "checkset",
      "options": [
        {
          "key": "fever",
          "text": "Fever"
        },
        {
          "key": "chills",
          "text": "Chills"
        },
        {
          "key": "malaise",
          "text": "Malaise"
        },
        {
          "key": "none",
          "text": "None"
        }
      ],
      "tableColumn": "11",
      "fqn": "linesDevices.pivasSystemicSymptoms"
    },
    {
      "elementKey": "pivasDressing",
      "formIndex": "6",
      "inputType": "checkbox",
      "tableColumn": "12",
      "fqn": "linesDevices.pivasDressing"
    },
    {
      "elementKey": "pivasSecurement",
      "formIndex": "6",
      "inputType": "checkbox",
      "tableColumn": "13",
      "fqn": "linesDevices.pivasSecurement"
    },
    {
      "elementKey": "pivasBloodReturn",
      "formIndex": "6",
      "inputType": "select",
      "options": [
        {
          "key": "present",
          "text": "Present"
        },
        {
          "key": "absent",
          "text": "Absent"
        },
        {
          "key": "sluggish",
          "text": "Sluggish"
        }
      ],
      "tableColumn": "14",
      "fqn": "linesDevices.pivasBloodReturn"
    },
    {
      "elementKey": "pivasFlushType",
      "formIndex": "6",
      "inputType": "select",
      "options": [
        {
          "key": "saline",
          "text": "Saline"
        },
        {
          "key": "heparin",
          "text": "Heparin"
        },
        {
          "key": "other",
          "text": "Other"
        }
      ],
      "tableColumn": "15",
      "fqn": "linesDevices.pivasFlushType"
    },
    {
      "elementKey": "pivasFlushVol",
      "formIndex": "6",
      "inputType": "decimal2",
      "suffix": "ml",
      "tableColumn": "16",
      "fqn": "linesDevices.pivasFlushVol",
      "suffixText": "ml"
    },
    {
      "elementKey": "pivasPatency",
      "formIndex": "6",
      "inputType": "checkset",
      "options": [
        {
          "key": "patent",
          "text": "Patent"
        },
        {
          "key": "sluggish",
          "text": "Sluggish"
        },
        {
          "key": "occluded",
          "text": "Occluded"
        }
      ],
      "tableColumn": "17",
      "fqn": "linesDevices.pivasPatency"
    },
    {
      "elementKey": "pivasResistance",
      "formIndex": "6",
      "inputType": "checkset",
      "options": [
        {
          "key": "mild",
          "text": "Mild"
        },
        {
          "key": "moderate",
          "text": "Moderate"
        },
        {
          "key": "severe",
          "text": "Severe"
        }
      ],
      "tableColumn": "18",
      "fqn": "linesDevices.pivasResistance"
    },
    {
      "elementKey": "pivasComments",
      "formIndex": "6",
      "inputType": "textarea",
      "tableColumn": "19",
      "fqn": "linesDevices.pivasComments"
    },
    {
      "elementKey": "cvadProfile_name",
      "formIndex": "7",
      "inputType": "practitionerName",
      "tableColumn": "1",
      "recHeader": true,
      "fqn": "linesDevices.name"
    },
    {
      "elementKey": "cvadProfile_id",
      "inputType": "generatedId",
      "tableColumn": 0,
      "label": "Row id",
      "tableCss": "row-id"
    },
    {
      "elementKey": "cvadProfile_profession",
      "formIndex": "7",
      "inputType": "practitionerProfession",
      "tableColumn": "1",
      "recHeader": true,
      "fqn": "linesDevices.profession"
    },
    {
      "elementKey": "cvadProfile_day",
      "formIndex": "7",
      "inputType": "visitDay",
      "mandatory": true,
      "tableColumn": "1",
      "validation": "visitDay",
      "recHeader": true,
      "fqn": "linesDevices.day"
    },
    {
      "elementKey": "cvadProfile_time",
      "formIndex": "7",
      "inputType": "visitTime",
      "mandatory": true,
      "tableColumn": "1",
      "validation": "time24",
      "recHeader": true,
      "fqn": "linesDevices.time"
    },
    {
      "elementKey": "cvadLabel",
      "formIndex": "7",
      "inputType": "select",
      "options": [
        {
          "key": "cvadA",
          "text": "CVAD A"
        },
        {
          "key": "cvadB",
          "text": "CVAD B"
        },
        {
          "key": "cvadC",
          "text": "CVAD C"
        },
        {
          "key": "cvadD",
          "text": "CVAD D"
        }
      ],
      "tableColumn": "2",
      "fqn": "linesDevices.cvadLabel"
    },
    {
      "elementKey": "cvadType",
      "formIndex": "7",
      "inputType": "select",
      "options": [
        {
          "key": "cvc",
          "text": "CVC (Non-Tunneled)"
        },
        {
          "key": "cvcTunneled",
          "text": "CVC (Tunneled)"
        },
        {
          "key": "implantedPort",
          "text": "Implanted Port"
        },
        {
          "key": "dialysisAvFistula",
          "text": "AV Fistula"
        },
        {
          "key": "dialysisAvGraft",
          "text": "AV Graft"
        },
        {
          "key": "dialysisHemo",
          "text": "Hemodialysis Catheter (Tunneled)"
        },
        {
          "key": "dialysisHemoNonTun",
          "text": "Hemodialysis Catheter (Non-Tunneled)"
        },
        {
          "key": "picc",
          "text": "PICC"
        }
      ],
      "tableColumn": "3",
      "fqn": "linesDevices.cvadType"
    },
    {
      "elementKey": "cvadStatus",
      "formIndex": "7",
      "inputType": "select",
      "options": [
        {
          "key": "active",
          "text": "Active"
        },
        {
          "key": "discontinued",
          "text": "Discontinued"
        },
        {
          "key": "removed",
          "text": "Removed"
        }
      ],
      "tableAction": "allowChange",
      "tableColumn": "4",
      "fqn": "linesDevices.cvadStatus"
    },
    {
      "elementKey": "cvadGauge",
      "formIndex": "7",
      "inputType": "select",
      "options": [
        {
          "key": "french_6",
          "text": "6 Fr"
        },
        {
          "key": "french_8",
          "text": "8 Fr"
        },
        {
          "key": "french_10",
          "text": "10 Fr"
        },
        {
          "key": "french_12",
          "text": "12 Fr"
        },
        {
          "key": "french_14",
          "text": "14 Fr"
        },
        {
          "key": "french_16",
          "text": "16 Fr"
        },
        {
          "key": "french_18",
          "text": "18 Fr"
        },
        {
          "key": "french_20",
          "text": "20 Fr"
        }
      ],
      "tableColumn": "5",
      "fqn": "linesDevices.cvadGauge"
    },
    {
      "elementKey": "cvadLocation",
      "formIndex": "7",
      "inputType": "select",
      "options": [
        {
          "key": "piccLeftArm",
          "text": "Left arm (PICC placement)"
        },
        {
          "key": "piccRightArm",
          "text": "Right arm (PICC placement)"
        },
        {
          "key": "veinExternalJugular",
          "text": "External jugular vein (emergency use)"
        },
        {
          "key": "veinFemoral",
          "text": "Femoral vein"
        },
        {
          "key": "veinInternalJugular",
          "text": "Internal jugular vein"
        },
        {
          "key": "veinPeripheral",
          "text": "Peripheral veins (for other venous access)"
        },
        {
          "key": "veinSubclavian",
          "text": "Subclavian vein"
        }
      ],
      "tableColumn": "6",
      "fqn": "linesDevices.cvadLocation"
    },
    {
      "elementKey": "cvadConfirmation",
      "formIndex": "7",
      "inputType": "select",
      "options": [
        {
          "key": "xRay",
          "text": "X-ray"
        },
        {
          "key": "ultra",
          "text": "Ultrasound"
        }
      ],
      "tableColumn": "7",
      "fqn": "linesDevices.cvadConfirmation"
    },
    {
      "elementKey": "cvadSecurement",
      "formIndex": "7",
      "inputType": "select",
      "options": [
        {
          "key": "gauze",
          "text": "Gauze pad"
        },
        {
          "key": "sterilePad",
          "text": "Sterile dressing pad"
        },
        {
          "key": "suture",
          "text": "Sutures"
        },
        {
          "key": "tape",
          "text": "Adhesive tape"
        },
        {
          "key": "transparent",
          "text": "Transparent dressing"
        }
      ],
      "tableColumn": "8",
      "fqn": "linesDevices.cvadSecurement"
    },
    {
      "elementKey": "cvadTipLocation",
      "formIndex": "7",
      "inputType": "select",
      "options": [
        {
          "key": "atriumLeft",
          "text": "Left Atrium"
        },
        {
          "key": "atriumRight",
          "text": "Right Atrium"
        },
        {
          "key": "cajLower",
          "text": "Cavoatrial Junction (CAJ)"
        },
        {
          "key": "malpos",
          "text": "Malpositioned"
        },
        {
          "key": "pending",
          "text": "Pending"
        },
        {
          "key": "svcLower",
          "text": "Lower Superior Vena Cava (SVC)"
        },
        {
          "key": "ventricleLeft",
          "text": "Left Ventricle"
        },
        {
          "key": "ventricleRight",
          "text": "Right Ventricle"
        }
      ],
      "tableAction": "allowChange",
      "tableColumn": "9",
      "fqn": "linesDevices.cvadTipLocation"
    },
    {
      "elementKey": "cvadCircumference",
      "formIndex": "7",
      "inputType": "decimal2",
      "suffix": "cm",
      "tableColumn": "10",
      "fqn": "linesDevices.cvadCircumference",
      "suffixText": "cm"
    },
    {
      "elementKey": "cvadLengthIn",
      "formIndex": "7",
      "inputType": "decimal2",
      "suffix": "cm",
      "tableColumn": "11",
      "fqn": "linesDevices.cvadLengthIn",
      "suffixText": "cm"
    },
    {
      "elementKey": "cvadLengthEx",
      "formIndex": "7",
      "inputType": "decimal2",
      "suffix": "cm",
      "tableColumn": "12",
      "fqn": "linesDevices.cvadLengthEx",
      "suffixText": "cm"
    },
    {
      "elementKey": "cvadInsertDay",
      "formIndex": "7",
      "inputType": "visitDay",
      "tableColumn": "13",
      "validation": "visitDay",
      "fqn": "linesDevices.cvadInsertDay"
    },
    {
      "elementKey": "cvadInsertTime",
      "formIndex": "7",
      "inputType": "visitTime",
      "tableColumn": "14",
      "validation": "time24",
      "fqn": "linesDevices.cvadInsertTime"
    },
    {
      "elementKey": "cvadRemovalDay",
      "formIndex": "7",
      "inputType": "visitDay",
      "tableAction": "allowChange",
      "tableColumn": "15",
      "validation": "visitDay",
      "fqn": "linesDevices.cvadRemovalDay"
    },
    {
      "elementKey": "cvadRemovalTime",
      "formIndex": "7",
      "inputType": "visitTime",
      "tableAction": "allowChange",
      "tableColumn": "16",
      "validation": "time24",
      "fqn": "linesDevices.cvadRemovalTime"
    },
    {
      "elementKey": "cvadComments",
      "formIndex": "7",
      "inputType": "textarea",
      "tableAction": "allowChange",
      "tableColumn": "17",
      "fqn": "linesDevices.cvadComments"
    },
    {
      "elementKey": "cvadAssessment_name",
      "formIndex": "8",
      "inputType": "practitionerName",
      "tableColumn": "1",
      "recHeader": true,
      "fqn": "linesDevices.name"
    },
    {
      "elementKey": "cvadAssessment_id",
      "inputType": "generatedId",
      "tableColumn": 0,
      "label": "Row id",
      "tableCss": "row-id"
    },
    {
      "elementKey": "cvadAssessment_profession",
      "formIndex": "8",
      "inputType": "practitionerProfession",
      "tableColumn": "1",
      "recHeader": true,
      "fqn": "linesDevices.profession"
    },
    {
      "elementKey": "cvadAssessment_day",
      "formIndex": "8",
      "inputType": "visitDay",
      "mandatory": true,
      "tableColumn": "1",
      "validation": "visitDay",
      "recHeader": true,
      "fqn": "linesDevices.day"
    },
    {
      "elementKey": "cvadAssessment_time",
      "formIndex": "8",
      "inputType": "visitTime",
      "mandatory": true,
      "tableColumn": "1",
      "validation": "time24",
      "recHeader": true,
      "fqn": "linesDevices.time"
    },
    {
      "elementKey": "cvadAsmtLabel",
      "formIndex": "8",
      "inputType": "select",
      "options": [
        {
          "key": "cvadA",
          "text": "CVAD A"
        },
        {
          "key": "cvadB",
          "text": "CVAD B"
        },
        {
          "key": "cvadC",
          "text": "CVAD C"
        },
        {
          "key": "cvadD",
          "text": "CVAD D"
        }
      ],
      "tableColumn": "2",
      "fqn": "linesDevices.cvadAsmtLabel"
    },
    {
      "elementKey": "cvadAsmtComplications",
      "formIndex": "8",
      "formCss": "grid-span-3-columns",
      "inputType": "checkset",
      "options": [
        {
          "key": "erythema",
          "text": "Erythema"
        },
        {
          "key": "edema",
          "text": "Edema"
        },
        {
          "key": "hematoma",
          "text": "Hematoma"
        },
        {
          "key": "discharge",
          "text": "Discharge"
        },
        {
          "key": "infiltration",
          "text": "Infiltration"
        },
        {
          "key": "extravasation",
          "text": "Extravasation"
        },
        {
          "key": "swelling",
          "text": "Swelling"
        },
        {
          "key": "phlebitis",
          "text": "Phlebitis"
        },
        {
          "key": "thrombophlebitis",
          "text": "Thrombophlebitis"
        },
        {
          "key": "other",
          "text": "Other"
        }
      ],
      "tableColumn": "3",
      "fqn": "linesDevices.cvadAsmtComplications"
    },
    {
      "elementKey": "cvadAsmtPlacement",
      "formIndex": "8",
      "inputType": "select",
      "options": [
        {
          "key": "secure",
          "text": "Secure"
        },
        {
          "key": "dislodged",
          "text": "Dislodged"
        },
        {
          "key": "atRisk",
          "text": "At risk"
        }
      ],
      "tableColumn": "4",
      "fqn": "linesDevices.cvadAsmtPlacement"
    },
    {
      "elementKey": "cvadAsmtCircumference",
      "formIndex": "8",
      "inputType": "decimal2",
      "suffix": "cm",
      "tableColumn": "5",
      "fqn": "linesDevices.cvadAsmtCircumference",
      "suffixText": "cm"
    },
    {
      "elementKey": "cvadAsmtDischarge",
      "formIndex": "8",
      "inputType": "select",
      "options": [
        {
          "key": "bilious",
          "text": "Bilious"
        },
        {
          "key": "brownTan",
          "text": "Brown/Tan"
        },
        {
          "key": "feculent",
          "text": "Feculent"
        },
        {
          "key": "mucous",
          "text": "Mucous"
        },
        {
          "key": "purulent",
          "text": "Purulent"
        },
        {
          "key": "sanguineous",
          "text": "Sanguineous"
        },
        {
          "key": "serosanguineous",
          "text": "Serosanguineous"
        },
        {
          "key": "serous",
          "text": "Serous"
        },
        {
          "key": "other",
          "text": "Other"
        }
      ],
      "tableColumn": "6",
      "fqn": "linesDevices.cvadAsmtDischarge"
    },
    {
      "elementKey": "cvadAsmtDisVol",
      "formIndex": "8",
      "inputType": "decimal2",
      "suffix": "mL",
      "tableColumn": "7",
      "fqn": "linesDevices.cvadAsmtDisVol",
      "suffixText": "mL"
    },
    {
      "elementKey": "cvadAssmtLengthEx",
      "formIndex": "8",
      "inputType": "decimal2",
      "suffix": "cm",
      "tableColumn": "8",
      "fqn": "linesDevices.cvadAssmtLengthEx",
      "suffixText": "cm"
    },
    {
      "elementKey": "cvadAsmtSiteWarmth",
      "formIndex": "8",
      "inputType": "select",
      "options": [
        {
          "key": "normal",
          "text": "Normal"
        },
        {
          "key": "warm",
          "text": "Warm"
        },
        {
          "key": "hot",
          "text": "Hot"
        }
      ],
      "tableColumn": "9",
      "fqn": "linesDevices.cvadAsmtSiteWarmth"
    },
    {
      "elementKey": "cvadAsmtSkin",
      "formIndex": "8",
      "inputType": "checkset",
      "options": [
        {
          "key": "intact",
          "text": "Intact"
        },
        {
          "key": "erythema",
          "text": "Erythema"
        },
        {
          "key": "irritation",
          "text": "Irritation"
        },
        {
          "key": "breakdown",
          "text": "Breakdown"
        },
        {
          "key": "infection",
          "text": "Infection"
        },
        {
          "key": "exudate",
          "text": "Exudate"
        },
        {
          "key": "dryness",
          "text": "Dryness"
        },
        {
          "key": "edema",
          "text": "Edema"
        }
      ],
      "tableColumn": "10",
      "fqn": "linesDevices.cvadAsmtSkin"
    },
    {
      "elementKey": "cvadAsmtSystemicSymptoms",
      "formIndex": "8",
      "inputType": "checkset",
      "options": [
        {
          "key": "fever",
          "text": "Fever"
        },
        {
          "key": "chills",
          "text": "Chills"
        },
        {
          "key": "malaise",
          "text": "Malaise"
        },
        {
          "key": "none",
          "text": "None"
        }
      ],
      "tableColumn": "11",
      "fqn": "linesDevices.cvadAsmtSystemicSymptoms"
    },
    {
      "elementKey": "cvadAsmtDressing",
      "formIndex": "8",
      "inputType": "checkbox",
      "tableColumn": "12",
      "fqn": "linesDevices.cvadAsmtDressing"
    },
    {
      "elementKey": "cvadAsmtSecurement",
      "formIndex": "8",
      "inputType": "checkbox",
      "tableColumn": "13",
      "fqn": "linesDevices.cvadAsmtSecurement"
    },
    {
      "elementKey": "cvadAsmtComments",
      "formIndex": "8",
      "inputType": "textarea",
      "tableColumn": "14",
      "fqn": "linesDevices.cvadAsmtComments"
    },
    {
      "elementKey": "cvadLumenAssessment_name",
      "formIndex": "9",
      "inputType": "practitionerName",
      "tableColumn": "1",
      "recHeader": true,
      "fqn": "linesDevices.name"
    },
    {
      "elementKey": "cvadLumenAssessment_id",
      "inputType": "generatedId",
      "tableColumn": 0,
      "label": "Row id",
      "tableCss": "row-id"
    },
    {
      "elementKey": "cvadLumenAssessment_profession",
      "formIndex": "9",
      "inputType": "practitionerProfession",
      "tableColumn": "1",
      "recHeader": true,
      "fqn": "linesDevices.profession"
    },
    {
      "elementKey": "cvadLumenAssessment_day",
      "formIndex": "9",
      "inputType": "visitDay",
      "mandatory": true,
      "tableColumn": "1",
      "validation": "visitDay",
      "recHeader": true,
      "fqn": "linesDevices.day"
    },
    {
      "elementKey": "cvadLumenAssessment_time",
      "formIndex": "9",
      "inputType": "visitTime",
      "mandatory": true,
      "tableColumn": "1",
      "validation": "time24",
      "recHeader": true,
      "fqn": "linesDevices.time"
    },
    {
      "elementKey": "cvadLumenCvadLabel",
      "formIndex": "9",
      "inputType": "select",
      "options": [
        {
          "key": "cvadA",
          "text": "CVAD A"
        },
        {
          "key": "cvadB",
          "text": "CVAD B"
        },
        {
          "key": "cvadC",
          "text": "CVAD C"
        },
        {
          "key": "cvadD",
          "text": "CVAD D"
        }
      ],
      "tableColumn": "2",
      "fqn": "linesDevices.cvadLumenCvadLabel"
    },
    {
      "elementKey": "cvadLumenPosition",
      "formIndex": "9",
      "inputType": "select",
      "options": [
        {
          "key": "proximal",
          "text": "Proximal"
        },
        {
          "key": "medial",
          "text": "Medial"
        },
        {
          "key": "distal",
          "text": "Distal"
        }
      ],
      "tableColumn": "3",
      "fqn": "linesDevices.cvadLumenPosition"
    },
    {
      "elementKey": "cvadLumenStatus",
      "formIndex": "9",
      "inputType": "select",
      "options": [
        {
          "key": "active",
          "text": "Active"
        },
        {
          "key": "discontinued",
          "text": "Discontinued"
        },
        {
          "key": "removed",
          "text": "Removed"
        }
      ],
      "tableColumn": "4",
      "fqn": "linesDevices.cvadLumenStatus"
    },
    {
      "elementKey": "cvadLumenPurpose",
      "formIndex": "9",
      "formCss": "grid-span-3-columns",
      "inputType": "text",
      "tableColumn": "5",
      "fqn": "linesDevices.cvadLumenPurpose"
    },
    {
      "elementKey": "cvadLumenBloodReturn",
      "formIndex": "9",
      "inputType": "select",
      "options": [
        {
          "key": "present",
          "text": "Present"
        },
        {
          "key": "absent",
          "text": "Absent"
        },
        {
          "key": "sluggish",
          "text": "Sluggish"
        }
      ],
      "tableColumn": "6",
      "fqn": "linesDevices.cvadLumenBloodReturn"
    },
    {
      "elementKey": "cvadLumenFlushType",
      "formIndex": "9",
      "inputType": "select",
      "options": [
        {
          "key": "saline",
          "text": "Saline"
        },
        {
          "key": "heparin",
          "text": "Heparin"
        },
        {
          "key": "other",
          "text": "Other"
        }
      ],
      "tableColumn": "7",
      "fqn": "linesDevices.cvadLumenFlushType"
    },
    {
      "elementKey": "cvadLumenFlushVol",
      "formIndex": "9",
      "inputType": "number",
      "suffix": "mL",
      "tableColumn": "8",
      "fqn": "linesDevices.cvadLumenFlushVol",
      "suffixText": "mL"
    },
    {
      "elementKey": "cvadLumenPatency",
      "formIndex": "9",
      "inputType": "select",
      "options": [
        {
          "key": "patent",
          "text": "Patent"
        },
        {
          "key": "sluggish",
          "text": "Sluggish"
        },
        {
          "key": "occluded",
          "text": "Occluded"
        }
      ],
      "tableColumn": "9",
      "fqn": "linesDevices.cvadLumenPatency"
    },
    {
      "elementKey": "cvadLumenResistance",
      "formIndex": "9",
      "inputType": "select",
      "options": [
        {
          "key": "mild",
          "text": "Mild"
        },
        {
          "key": "moderate",
          "text": "Moderate"
        },
        {
          "key": "severe",
          "text": "Severe"
        }
      ],
      "tableColumn": "10",
      "fqn": "linesDevices.cvadLumenResistance"
    },
    {
      "elementKey": "cvadLumenComments",
      "formIndex": "9",
      "inputType": "textarea",
      "tableColumn": "12",
      "fqn": "linesDevices.cvadLumenComments"
    },
    {
      "elementKey": "epidProfile_name",
      "formIndex": "10",
      "inputType": "practitionerName",
      "tableColumn": "1",
      "recHeader": true,
      "fqn": "linesDevices.name"
    },
    {
      "elementKey": "epidProfile_id",
      "inputType": "generatedId",
      "tableColumn": 0,
      "label": "Row id",
      "tableCss": "row-id"
    },
    {
      "elementKey": "epidProfile_profession",
      "formIndex": "10",
      "inputType": "practitionerProfession",
      "tableColumn": "1",
      "recHeader": true,
      "fqn": "linesDevices.profession"
    },
    {
      "elementKey": "epidProfile_day",
      "formIndex": "10",
      "inputType": "visitDay",
      "mandatory": true,
      "tableColumn": "1",
      "validation": "visitDay",
      "recHeader": true,
      "fqn": "linesDevices.day"
    },
    {
      "elementKey": "epidProfile_time",
      "formIndex": "10",
      "inputType": "visitTime",
      "mandatory": true,
      "tableColumn": "1",
      "validation": "time24",
      "recHeader": true,
      "fqn": "linesDevices.time"
    },
    {
      "elementKey": "epidStatus",
      "formIndex": "10",
      "inputType": "select",
      "options": [
        {
          "key": "active",
          "text": "Active"
        },
        {
          "key": "discontinued",
          "text": "Discontinued"
        },
        {
          "key": "removed",
          "text": "Removed"
        }
      ],
      "tableAction": "allowChange",
      "tableColumn": "2",
      "fqn": "linesDevices.epidStatus"
    },
    {
      "elementKey": "epidGauge",
      "formIndex": "10",
      "inputType": "select",
      "options": [
        {
          "key": "french_6",
          "text": "6 Fr"
        },
        {
          "key": "french_7",
          "text": "7 Fr"
        },
        {
          "key": "french_8",
          "text": "8 Fr"
        },
        {
          "key": "french_9",
          "text": "9 Fr"
        }
      ],
      "tableColumn": "3",
      "fqn": "linesDevices.epidGauge"
    },
    {
      "elementKey": "epidLocation",
      "formIndex": "10",
      "inputType": "select",
      "options": [
        {
          "key": "caudal",
          "text": "Caudal spine"
        },
        {
          "key": "cervical",
          "text": "Cervical spine"
        },
        {
          "key": "lumbar",
          "text": "Lumbar spine"
        },
        {
          "key": "thoracic",
          "text": "Thoracic spine"
        }
      ],
      "tableColumn": "4",
      "fqn": "linesDevices.epidLocation"
    },
    {
      "elementKey": "epidSecurement",
      "formIndex": "10",
      "inputType": "select",
      "options": [
        {
          "key": "gauze",
          "text": "Gauze pad"
        },
        {
          "key": "sterilePad",
          "text": "Sterile dressing pad"
        },
        {
          "key": "tape",
          "text": "Adhesive tape"
        },
        {
          "key": "transparent",
          "text": "Transparent dressing"
        }
      ],
      "tableColumn": "5",
      "fqn": "linesDevices.epidSecurement"
    },
    {
      "elementKey": "epidLengthIn",
      "formIndex": "10",
      "inputType": "decimal2",
      "suffix": "cm",
      "tableColumn": "6",
      "fqn": "linesDevices.epidLengthIn",
      "suffixText": "cm"
    },
    {
      "elementKey": "epidLengthEx",
      "formIndex": "10",
      "inputType": "decimal2",
      "suffix": "cm",
      "tableColumn": "7",
      "fqn": "linesDevices.epidLengthEx",
      "suffixText": "cm"
    },
    {
      "elementKey": "epidInsertDay",
      "formIndex": "10",
      "inputType": "visitDay",
      "tableColumn": "8",
      "validation": "visitDay",
      "fqn": "linesDevices.epidInsertDay"
    },
    {
      "elementKey": "epidInsertTime",
      "formIndex": "10",
      "inputType": "visitTime",
      "tableColumn": "9",
      "validation": "time24",
      "fqn": "linesDevices.epidInsertTime"
    },
    {
      "elementKey": "epidRemovalDay",
      "formIndex": "10",
      "inputType": "visitDay",
      "tableAction": "allowChange",
      "tableColumn": "10",
      "validation": "visitDay",
      "fqn": "linesDevices.epidRemovalDay"
    },
    {
      "elementKey": "epidRemovalTime",
      "formIndex": "10",
      "inputType": "visitTime",
      "tableAction": "allowChange",
      "tableColumn": "11",
      "validation": "time24",
      "fqn": "linesDevices.epidRemovalTime"
    },
    {
      "elementKey": "epidComments",
      "formIndex": "10",
      "inputType": "textarea",
      "tableAction": "allowChange",
      "tableColumn": "12",
      "fqn": "linesDevices.epidComments"
    },
    {
      "elementKey": "epidAssessment_name",
      "formIndex": "11",
      "inputType": "practitionerName",
      "tableColumn": "1",
      "recHeader": true,
      "fqn": "linesDevices.name"
    },
    {
      "elementKey": "epidAssessment_id",
      "inputType": "generatedId",
      "tableColumn": 0,
      "label": "Row id",
      "tableCss": "row-id"
    },
    {
      "elementKey": "epidAssessment_profession",
      "formIndex": "11",
      "inputType": "practitionerProfession",
      "tableColumn": "1",
      "recHeader": true,
      "fqn": "linesDevices.profession"
    },
    {
      "elementKey": "epidAssessment_day",
      "formIndex": "11",
      "inputType": "visitDay",
      "mandatory": true,
      "tableColumn": "1",
      "validation": "visitDay",
      "recHeader": true,
      "fqn": "linesDevices.day"
    },
    {
      "elementKey": "epidAssessment_time",
      "formIndex": "11",
      "inputType": "visitTime",
      "mandatory": true,
      "tableColumn": "1",
      "validation": "time24",
      "recHeader": true,
      "fqn": "linesDevices.time"
    },
    {
      "elementKey": "epidAsmtComplications",
      "formIndex": "11",
      "formCss": "grid-span-3-columns",
      "inputType": "checkset",
      "options": [
        {
          "key": "abscess",
          "text": "Epidural abscess"
        },
        {
          "key": "bleeding",
          "text": "Excessive bleeding"
        },
        {
          "key": "edema",
          "text": "Edema"
        },
        {
          "key": "erythema",
          "text": "Erythema"
        },
        {
          "key": "hematoma",
          "text": "Hematoma"
        },
        {
          "key": "hypotension",
          "text": "Hypotension"
        },
        {
          "key": "infection",
          "text": "Infection"
        },
        {
          "key": "malposition",
          "text": "Malpositioned"
        },
        {
          "key": "nerveDamage",
          "text": "Nerve damage"
        },
        {
          "key": "respiratoryDepression",
          "text": "Respiratory depression"
        },
        {
          "key": "spinalHeadache",
          "text": "Spinal headache"
        },
        {
          "key": "urinaryRetention",
          "text": "Urinary retention"
        }
      ],
      "tableColumn": "2",
      "fqn": "linesDevices.epidAsmtComplications"
    },
    {
      "elementKey": "epidAsmtPlacement",
      "formIndex": "11",
      "inputType": "select",
      "options": [
        {
          "key": "secure",
          "text": "Secure"
        },
        {
          "key": "dislodged",
          "text": "Dislodged"
        },
        {
          "key": "atRisk",
          "text": "At risk"
        }
      ],
      "tableColumn": "3",
      "fqn": "linesDevices.epidAsmtPlacement"
    },
    {
      "elementKey": "epidAsmtDischarge",
      "formIndex": "11",
      "inputType": "select",
      "options": [
        {
          "key": "bilious",
          "text": "Bilious"
        },
        {
          "key": "brownTan",
          "text": "Brown/Tan"
        },
        {
          "key": "feculent",
          "text": "Feculent"
        },
        {
          "key": "mucous",
          "text": "Mucous"
        },
        {
          "key": "purulent",
          "text": "Purulent"
        },
        {
          "key": "sanguineous",
          "text": "Sanguineous"
        },
        {
          "key": "serosanguineous",
          "text": "Serosanguineous"
        },
        {
          "key": "serous",
          "text": "Serous"
        },
        {
          "key": "other",
          "text": "Other"
        }
      ],
      "tableColumn": "4",
      "fqn": "linesDevices.epidAsmtDischarge"
    },
    {
      "elementKey": "epidAsmtDisVol",
      "formIndex": "11",
      "inputType": "decimal2",
      "suffix": "mL",
      "tableColumn": "5",
      "fqn": "linesDevices.epidAsmtDisVol",
      "suffixText": "mL"
    },
    {
      "elementKey": "epidAssmtLengthEx",
      "formIndex": "11",
      "inputType": "decimal2",
      "suffix": "cm",
      "tableColumn": "6",
      "fqn": "linesDevices.epidAssmtLengthEx",
      "suffixText": "cm"
    },
    {
      "elementKey": "epidAsmtSiteWarmth",
      "formIndex": "11",
      "inputType": "select",
      "options": [
        {
          "key": "normal",
          "text": "Normal"
        },
        {
          "key": "warm",
          "text": "Warm"
        },
        {
          "key": "hot",
          "text": "Hot"
        }
      ],
      "tableColumn": "7",
      "fqn": "linesDevices.epidAsmtSiteWarmth"
    },
    {
      "elementKey": "epidAsmtSkin",
      "formIndex": "11",
      "inputType": "checkset",
      "options": [
        {
          "key": "intact",
          "text": "Intact"
        },
        {
          "key": "erythema",
          "text": "Erythema"
        },
        {
          "key": "irritation",
          "text": "Irritation"
        },
        {
          "key": "breakdown",
          "text": "Breakdown"
        },
        {
          "key": "infection",
          "text": "Infection"
        },
        {
          "key": "exudate",
          "text": "Exudate"
        },
        {
          "key": "dryness",
          "text": "Dryness"
        },
        {
          "key": "edema",
          "text": "Edema"
        }
      ],
      "tableColumn": "8",
      "fqn": "linesDevices.epidAsmtSkin"
    },
    {
      "elementKey": "epidAsmtSystemicSymptoms",
      "formIndex": "11",
      "inputType": "checkset",
      "options": [
        {
          "key": "fever",
          "text": "Fever"
        },
        {
          "key": "chills",
          "text": "Chills"
        },
        {
          "key": "malaise",
          "text": "Malaise"
        },
        {
          "key": "none",
          "text": "None"
        }
      ],
      "tableColumn": "9",
      "fqn": "linesDevices.epidAsmtSystemicSymptoms"
    },
    {
      "elementKey": "epidAsmtLumenFlushType",
      "formIndex": "11",
      "inputType": "select",
      "options": [
        {
          "key": "saline",
          "text": "Saline"
        },
        {
          "key": "heparin",
          "text": "Heparin"
        },
        {
          "key": "other",
          "text": "Other"
        }
      ],
      "tableColumn": "10",
      "fqn": "linesDevices.epidAsmtLumenFlushType"
    },
    {
      "elementKey": "epidAsmtLumenFlushVol",
      "formIndex": "11",
      "inputType": "number",
      "suffix": "mL",
      "tableColumn": "11",
      "fqn": "linesDevices.epidAsmtLumenFlushVol",
      "suffixText": "mL"
    },
    {
      "elementKey": "epidAsmtPatency",
      "formIndex": "11",
      "inputType": "select",
      "options": [
        {
          "key": "patent",
          "text": "Patent"
        },
        {
          "key": "sluggish",
          "text": "Sluggish"
        },
        {
          "key": "occluded",
          "text": "Occluded"
        }
      ],
      "tableColumn": "12",
      "fqn": "linesDevices.epidAsmtPatency"
    },
    {
      "elementKey": "epidAsmtFlushResistance",
      "formIndex": "11",
      "inputType": "select",
      "options": [
        {
          "key": "mild",
          "text": "Mild"
        },
        {
          "key": "moderate",
          "text": "Moderate"
        },
        {
          "key": "severe",
          "text": "Severe"
        }
      ],
      "tableColumn": "13",
      "fqn": "linesDevices.epidAsmtFlushResistance"
    },
    {
      "elementKey": "epidAsmtDressing",
      "formIndex": "11",
      "inputType": "checkbox",
      "tableColumn": "14",
      "fqn": "linesDevices.epidAsmtDressing"
    },
    {
      "elementKey": "epidAsmtSecurement",
      "formIndex": "11",
      "inputType": "checkbox",
      "tableColumn": "15",
      "fqn": "linesDevices.epidAsmtSecurement"
    },
    {
      "elementKey": "epidAsmtComments",
      "formIndex": "11",
      "inputType": "textarea",
      "tableColumn": "16",
      "fqn": "linesDevices.epidAsmtComments"
    },
    {
      "elementKey": "artLineProfile_name",
      "formIndex": "12",
      "inputType": "practitionerName",
      "tableColumn": "1",
      "recHeader": true,
      "fqn": "linesDevices.name"
    },
    {
      "elementKey": "artLineProfile_id",
      "inputType": "generatedId",
      "tableColumn": 0,
      "label": "Row id",
      "tableCss": "row-id"
    },
    {
      "elementKey": "artLineProfile_profession",
      "formIndex": "12",
      "inputType": "practitionerProfession",
      "tableColumn": "1",
      "recHeader": true,
      "fqn": "linesDevices.profession"
    },
    {
      "elementKey": "artLineProfile_day",
      "formIndex": "12",
      "inputType": "visitDay",
      "mandatory": true,
      "tableColumn": "1",
      "validation": "visitDay",
      "recHeader": true,
      "fqn": "linesDevices.day"
    },
    {
      "elementKey": "artLineProfile_time",
      "formIndex": "12",
      "inputType": "visitTime",
      "mandatory": true,
      "tableColumn": "1",
      "validation": "time24",
      "recHeader": true,
      "fqn": "linesDevices.time"
    },
    {
      "elementKey": "artLineType",
      "formIndex": "12",
      "inputType": "select",
      "options": [
        {
          "key": "brachial",
          "text": "Brachial Arterial Line"
        },
        {
          "key": "femoral",
          "text": "Femoral Arterial Line"
        },
        {
          "key": "radial",
          "text": "Radial Arterial Line"
        }
      ],
      "tableColumn": "2",
      "fqn": "linesDevices.artLineType"
    },
    {
      "elementKey": "artLineStatus",
      "formIndex": "12",
      "inputType": "select",
      "options": [
        {
          "key": "active",
          "text": "Active"
        },
        {
          "key": "discontinued",
          "text": "Discontinued"
        },
        {
          "key": "removed",
          "text": "Removed"
        }
      ],
      "tableAction": "allowChange",
      "tableColumn": "3",
      "fqn": "linesDevices.artLineStatus"
    },
    {
      "elementKey": "artLineGauge",
      "formIndex": "12",
      "inputType": "select",
      "options": [
        {
          "key": "french_6",
          "text": "6 Fr"
        },
        {
          "key": "french_7",
          "text": "7 Fr"
        },
        {
          "key": "french_8",
          "text": "8 Fr"
        },
        {
          "key": "french_9",
          "text": "9 Fr"
        },
        {
          "key": "french_10",
          "text": "10 Fr"
        },
        {
          "key": "french_11",
          "text": "11 Fr"
        }
      ],
      "tableColumn": "4",
      "fqn": "linesDevices.artLineGauge"
    },
    {
      "elementKey": "artLineConfirmation",
      "formIndex": "12",
      "inputType": "select",
      "options": [
        {
          "key": "xRay",
          "text": "X-ray"
        },
        {
          "key": "ultra",
          "text": "Ultrasound"
        }
      ],
      "tableColumn": "5",
      "fqn": "linesDevices.artLineConfirmation"
    },
    {
      "elementKey": "artLineSecurement",
      "formIndex": "12",
      "inputType": "select",
      "options": [
        {
          "key": "gauze",
          "text": "Gauze pad"
        },
        {
          "key": "sterilePad",
          "text": "Sterile dressing pad"
        },
        {
          "key": "suture",
          "text": "Sutures"
        },
        {
          "key": "tape",
          "text": "Adhesive tape"
        },
        {
          "key": "transparent",
          "text": "Transparent dressing"
        }
      ],
      "tableColumn": "6",
      "fqn": "linesDevices.artLineSecurement"
    },
    {
      "elementKey": "artLineLengthIn",
      "formIndex": "12",
      "inputType": "decimal2",
      "suffix": "cm",
      "tableColumn": "7",
      "fqn": "linesDevices.artLineLengthIn",
      "suffixText": "cm"
    },
    {
      "elementKey": "artLineLengthEx",
      "formIndex": "12",
      "inputType": "decimal2",
      "suffix": "cm",
      "tableColumn": "8",
      "fqn": "linesDevices.artLineLengthEx",
      "suffixText": "cm"
    },
    {
      "elementKey": "artLineInsertDay",
      "formIndex": "12",
      "inputType": "visitDay",
      "tableColumn": "9",
      "validation": "visitDay",
      "fqn": "linesDevices.artLineInsertDay"
    },
    {
      "elementKey": "artLineInsertTime",
      "formIndex": "12",
      "inputType": "visitTime",
      "tableColumn": "10",
      "validation": "time24",
      "fqn": "linesDevices.artLineInsertTime"
    },
    {
      "elementKey": "artLineRemovalDay",
      "formIndex": "12",
      "inputType": "visitDay",
      "tableAction": "allowChange",
      "tableColumn": "11",
      "validation": "visitDay",
      "fqn": "linesDevices.artLineRemovalDay"
    },
    {
      "elementKey": "artLineRemovalTime",
      "formIndex": "12",
      "inputType": "visitTime",
      "tableAction": "allowChange",
      "tableColumn": "12",
      "validation": "time24",
      "fqn": "linesDevices.artLineRemovalTime"
    },
    {
      "elementKey": "artLineComments",
      "formIndex": "12",
      "inputType": "textarea",
      "tableAction": "allowChange",
      "tableColumn": "13",
      "fqn": "linesDevices.artLineComments"
    },
    {
      "elementKey": "artLineAssessment_name",
      "formIndex": "13",
      "inputType": "practitionerName",
      "tableColumn": "1",
      "recHeader": true,
      "fqn": "linesDevices.name"
    },
    {
      "elementKey": "artLineAssessment_id",
      "inputType": "generatedId",
      "tableColumn": 0,
      "label": "Row id",
      "tableCss": "row-id"
    },
    {
      "elementKey": "artLineAssessment_profession",
      "formIndex": "13",
      "inputType": "practitionerProfession",
      "tableColumn": "1",
      "recHeader": true,
      "fqn": "linesDevices.profession"
    },
    {
      "elementKey": "artLineAssessment_day",
      "formIndex": "13",
      "inputType": "visitDay",
      "mandatory": true,
      "tableColumn": "1",
      "validation": "visitDay",
      "recHeader": true,
      "fqn": "linesDevices.day"
    },
    {
      "elementKey": "artLineAssessment_time",
      "formIndex": "13",
      "inputType": "visitTime",
      "mandatory": true,
      "tableColumn": "1",
      "validation": "time24",
      "recHeader": true,
      "fqn": "linesDevices.time"
    },
    {
      "elementKey": "artLineAsmtComplications",
      "formIndex": "13",
      "formCss": "grid-span-3-columns",
      "inputType": "checkset",
      "options": [
        {
          "key": "erythema",
          "text": "Erythema"
        },
        {
          "key": "edema",
          "text": "Edema"
        },
        {
          "key": "hematoma",
          "text": "Hematoma"
        },
        {
          "key": "discharge",
          "text": "Discharge"
        },
        {
          "key": "infiltration",
          "text": "Infiltration"
        },
        {
          "key": "extravasation",
          "text": "Extravasation"
        },
        {
          "key": "swelling",
          "text": "Swelling"
        },
        {
          "key": "phlebitis",
          "text": "Phlebitis"
        },
        {
          "key": "thrombophlebitis",
          "text": "Thrombophlebitis"
        },
        {
          "key": "other",
          "text": "Other"
        }
      ],
      "tableColumn": "2",
      "fqn": "linesDevices.artLineAsmtComplications"
    },
    {
      "elementKey": "artLineAsmtPlacement",
      "formIndex": "13",
      "inputType": "select",
      "options": [
        {
          "key": "secure",
          "text": "Secure"
        },
        {
          "key": "dislodged",
          "text": "Dislodged"
        },
        {
          "key": "atRisk",
          "text": "At risk"
        }
      ],
      "tableColumn": "3",
      "fqn": "linesDevices.artLineAsmtPlacement"
    },
    {
      "elementKey": "artLineAsmtCircumference",
      "formIndex": "13",
      "inputType": "decimal2",
      "suffix": "cm",
      "tableColumn": "4",
      "fqn": "linesDevices.artLineAsmtCircumference",
      "suffixText": "cm"
    },
    {
      "elementKey": "artLineAsmtDischarge",
      "formIndex": "13",
      "inputType": "select",
      "options": [
        {
          "key": "bilious",
          "text": "Bilious"
        },
        {
          "key": "brownTan",
          "text": "Brown/Tan"
        },
        {
          "key": "feculent",
          "text": "Feculent"
        },
        {
          "key": "mucous",
          "text": "Mucous"
        },
        {
          "key": "purulent",
          "text": "Purulent"
        },
        {
          "key": "sanguineous",
          "text": "Sanguineous"
        },
        {
          "key": "serosanguineous",
          "text": "Serosanguineous"
        },
        {
          "key": "serous",
          "text": "Serous"
        },
        {
          "key": "other",
          "text": "Other"
        }
      ],
      "tableColumn": "5",
      "fqn": "linesDevices.artLineAsmtDischarge"
    },
    {
      "elementKey": "artLineAsmtDisVol",
      "formIndex": "13",
      "inputType": "decimal2",
      "suffix": "mL",
      "tableColumn": "6",
      "fqn": "linesDevices.artLineAsmtDisVol",
      "suffixText": "mL"
    },
    {
      "elementKey": "artLineAssmtLengthEx",
      "formIndex": "13",
      "inputType": "decimal2",
      "suffix": "cm",
      "tableColumn": "7",
      "fqn": "linesDevices.artLineAssmtLengthEx",
      "suffixText": "cm"
    },
    {
      "elementKey": "artLineAsmtSiteWarmth",
      "formIndex": "13",
      "inputType": "select",
      "options": [
        {
          "key": "normal",
          "text": "Normal"
        },
        {
          "key": "warm",
          "text": "Warm"
        },
        {
          "key": "hot",
          "text": "Hot"
        }
      ],
      "tableColumn": "8",
      "fqn": "linesDevices.artLineAsmtSiteWarmth"
    },
    {
      "elementKey": "artLineAsmtSkin",
      "formIndex": "13",
      "inputType": "checkset",
      "options": [
        {
          "key": "intact",
          "text": "Intact"
        },
        {
          "key": "erythema",
          "text": "Erythema"
        },
        {
          "key": "irritation",
          "text": "Irritation"
        },
        {
          "key": "breakdown",
          "text": "Breakdown"
        },
        {
          "key": "infection",
          "text": "Infection"
        },
        {
          "key": "exudate",
          "text": "Exudate"
        },
        {
          "key": "dryness",
          "text": "Dryness"
        },
        {
          "key": "edema",
          "text": "Edema"
        }
      ],
      "tableColumn": "9",
      "fqn": "linesDevices.artLineAsmtSkin"
    },
    {
      "elementKey": "artLineAsmtSystemicSymptoms",
      "formIndex": "13",
      "inputType": "checkset",
      "options": [
        {
          "key": "fever",
          "text": "Fever"
        },
        {
          "key": "chills",
          "text": "Chills"
        },
        {
          "key": "malaise",
          "text": "Malaise"
        },
        {
          "key": "none",
          "text": "None"
        }
      ],
      "tableColumn": "10",
      "fqn": "linesDevices.artLineAsmtSystemicSymptoms"
    },
    {
      "elementKey": "artLineLumenBloodReturn",
      "formIndex": "13",
      "inputType": "select",
      "options": [
        {
          "key": "present",
          "text": "Present"
        },
        {
          "key": "absent",
          "text": "Absent"
        },
        {
          "key": "sluggish",
          "text": "Sluggish"
        }
      ],
      "tableColumn": "11",
      "fqn": "linesDevices.artLineLumenBloodReturn"
    },
    {
      "elementKey": "artLineLumenFlushType",
      "formIndex": "13",
      "inputType": "select",
      "options": [
        {
          "key": "saline",
          "text": "Saline"
        },
        {
          "key": "heparin",
          "text": "Heparin"
        },
        {
          "key": "other",
          "text": "Other"
        }
      ],
      "tableColumn": "12",
      "fqn": "linesDevices.artLineLumenFlushType"
    },
    {
      "elementKey": "artLineLumenFlushVol",
      "formIndex": "13",
      "inputType": "number",
      "suffix": "mL",
      "tableColumn": "13",
      "fqn": "linesDevices.artLineLumenFlushVol",
      "suffixText": "mL"
    },
    {
      "elementKey": "artLineLumenPatency",
      "formIndex": "13",
      "inputType": "select",
      "options": [
        {
          "key": "patent",
          "text": "Patent"
        },
        {
          "key": "sluggish",
          "text": "Sluggish"
        },
        {
          "key": "occluded",
          "text": "Occluded"
        }
      ],
      "tableColumn": "14",
      "fqn": "linesDevices.artLineLumenPatency"
    },
    {
      "elementKey": "artLineLumenResistance",
      "formIndex": "13",
      "inputType": "select",
      "options": [
        {
          "key": "mild",
          "text": "Mild"
        },
        {
          "key": "moderate",
          "text": "Moderate"
        },
        {
          "key": "severe",
          "text": "Severe"
        }
      ],
      "tableColumn": "15",
      "fqn": "linesDevices.artLineLumenResistance"
    },
    {
      "elementKey": "artLineAsmtDressing",
      "formIndex": "13",
      "inputType": "checkbox",
      "tableColumn": "16",
      "fqn": "linesDevices.artLineAsmtDressing"
    },
    {
      "elementKey": "artLineAsmtSecurement",
      "formIndex": "13",
      "inputType": "checkbox",
      "tableColumn": "17",
      "fqn": "linesDevices.artLineAsmtSecurement"
    },
    {
      "elementKey": "artLineAsmtComments",
      "formIndex": "13",
      "inputType": "textarea",
      "tableColumn": "18",
      "fqn": "linesDevices.artLineAsmtComments"
    },
    {
      "elementKey": "uriCathProfile_name",
      "formIndex": "14",
      "inputType": "practitionerName",
      "tableColumn": "1",
      "recHeader": true,
      "fqn": "linesDevices.name"
    },
    {
      "elementKey": "uriCathProfile_id",
      "inputType": "generatedId",
      "tableColumn": 0,
      "label": "Row id",
      "tableCss": "row-id"
    },
    {
      "elementKey": "uriCathProfile_profession",
      "formIndex": "14",
      "inputType": "practitionerProfession",
      "tableColumn": "1",
      "recHeader": true,
      "fqn": "linesDevices.profession"
    },
    {
      "elementKey": "uriCathProfile_day",
      "formIndex": "14",
      "inputType": "visitDay",
      "mandatory": true,
      "tableColumn": "1",
      "validation": "visitDay",
      "recHeader": true,
      "fqn": "linesDevices.day"
    },
    {
      "elementKey": "uriCathProfile_time",
      "formIndex": "14",
      "inputType": "visitTime",
      "mandatory": true,
      "tableColumn": "1",
      "validation": "time24",
      "recHeader": true,
      "fqn": "linesDevices.time"
    },
    {
      "elementKey": "uriCathLocation",
      "formIndex": "14",
      "inputType": "select",
      "options": [
        {
          "key": "suprapubic",
          "text": "Suprapubic (bladder)"
        },
        {
          "key": "urethraFemale",
          "text": "Female Urethra"
        },
        {
          "key": "urethraMale",
          "text": "Male Urethra"
        },
        {
          "key": "urostomy",
          "text": "Urostomy (stoma on abdomen)"
        }
      ],
      "fqn": "linesDevices.uriCathLocation"
    },
    {
      "elementKey": "uriCathType",
      "formIndex": "14",
      "inputType": "select",
      "options": [
        {
          "key": "condom",
          "text": "Condom"
        },
        {
          "key": "coude",
          "text": "Coude"
        },
        {
          "key": "foley",
          "text": "Foley"
        },
        {
          "key": "intermittent",
          "text": "Intermittent"
        },
        {
          "key": "urostomy",
          "text": "Urostomy"
        }
      ],
      "tableColumn": "2",
      "fqn": "linesDevices.uriCathType"
    },
    {
      "elementKey": "uriCathInsertion",
      "formIndex": "14",
      "inputType": "select",
      "options": [
        {
          "key": "assisted",
          "text": "Assisted"
        },
        {
          "key": "blind",
          "text": "Blind"
        },
        {
          "key": "cleanTechnique",
          "text": "Clean technique"
        },
        {
          "key": "sterileTechnique",
          "text": "Sterile technique"
        },
        {
          "key": "stomaCreation",
          "text": "Stoma creation (for urostomy)"
        },
        {
          "key": "surgical",
          "text": "Surgical"
        },
        {
          "key": "ultrasound",
          "text": "Ultrasound guided"
        }
      ],
      "fqn": "linesDevices.uriCathInsertion"
    },
    {
      "elementKey": "uriCathSecurement",
      "formIndex": "14",
      "inputType": "select",
      "options": [
        {
          "key": "anchorStrap",
          "text": "Anchor strap"
        },
        {
          "key": "catheterHolder",
          "text": "Catheter holder"
        },
        {
          "key": "legStrap",
          "text": "Leg strap"
        },
        {
          "key": "statLock",
          "text": "StatLock"
        },
        {
          "key": "suture",
          "text": "Suture"
        }
      ],
      "fqn": "linesDevices.uriCathSecurement"
    },
    {
      "elementKey": "uriCathGauge",
      "formIndex": "14",
      "inputType": "select",
      "options": [
        {
          "key": "fr6",
          "text": "6 Fr"
        },
        {
          "key": "fr8",
          "text": "8 Fr"
        },
        {
          "key": "fr10",
          "text": "10 Fr"
        },
        {
          "key": "fr12",
          "text": "12 Fr"
        },
        {
          "key": "fr14",
          "text": "14 Fr"
        },
        {
          "key": "fr16",
          "text": "16 Fr"
        },
        {
          "key": "fr18",
          "text": "18 Fr"
        },
        {
          "key": "fr20",
          "text": "20 Fr"
        }
      ],
      "fqn": "linesDevices.uriCathGauge"
    },
    {
      "elementKey": "uriStomaSize",
      "formIndex": "14",
      "inputType": "select",
      "options": [
        {
          "key": "bagLarge",
          "text": "Large bag"
        },
        {
          "key": "bagMedium",
          "text": "Medium bag"
        },
        {
          "key": "bagSmall",
          "text": "Small bag"
        },
        {
          "key": "stomaLarge",
          "text": "Large stoma"
        },
        {
          "key": "stomaMedium",
          "text": "Medium stoma"
        },
        {
          "key": "stomaSmall",
          "text": "Small stoma"
        }
      ],
      "fqn": "linesDevices.uriStomaSize"
    },
    {
      "elementKey": "uriCathBalloonLumenSize",
      "formIndex": "14",
      "inputType": "number",
      "suffix": "cc",
      "fqn": "linesDevices.uriCathBalloonLumenSize",
      "suffixText": "cc"
    },
    {
      "elementKey": "uriCathLengthIn",
      "formIndex": "14",
      "inputType": "decimal2",
      "suffix": "cm",
      "tableColumn": "1",
      "fqn": "linesDevices.uriCathLengthIn",
      "suffixText": "cm"
    },
    {
      "elementKey": "uriCathLengthEx",
      "formIndex": "14",
      "inputType": "decimal2",
      "suffix": "cm",
      "tableColumn": "2",
      "fqn": "linesDevices.uriCathLengthEx",
      "suffixText": "cm"
    },
    {
      "elementKey": "uriCathInsertDay",
      "formIndex": "14",
      "inputType": "visitDay",
      "tableColumn": "3",
      "validation": "visitDay",
      "fqn": "linesDevices.uriCathInsertDay"
    },
    {
      "elementKey": "uriCathInsertTime",
      "formIndex": "14",
      "inputType": "visitTime",
      "tableColumn": "4",
      "validation": "time24",
      "fqn": "linesDevices.uriCathInsertTime"
    },
    {
      "elementKey": "uriCathRemovalDay",
      "formIndex": "14",
      "inputType": "visitDay",
      "tableAction": "allowChange",
      "tableColumn": "5",
      "validation": "visitDay",
      "fqn": "linesDevices.uriCathRemovalDay"
    },
    {
      "elementKey": "uriCathRemovalTime",
      "formIndex": "14",
      "inputType": "visitTime",
      "tableAction": "allowChange",
      "tableColumn": "6",
      "validation": "time24",
      "fqn": "linesDevices.uriCathRemovalTime"
    },
    {
      "elementKey": "uriCathComments",
      "formIndex": "14",
      "inputType": "textarea",
      "tableAction": "allowChange",
      "tableColumn": "7",
      "fqn": "linesDevices.uriCathComments"
    },
    {
      "elementKey": "uriCathAssessment_name",
      "formIndex": "15",
      "inputType": "practitionerName",
      "tableColumn": "1",
      "recHeader": true,
      "fqn": "linesDevices.name"
    },
    {
      "elementKey": "uriCathAssessment_id",
      "inputType": "generatedId",
      "tableColumn": 0,
      "label": "Row id",
      "tableCss": "row-id"
    },
    {
      "elementKey": "uriCathAssessment_profession",
      "formIndex": "15",
      "inputType": "practitionerProfession",
      "tableColumn": "1",
      "recHeader": true,
      "fqn": "linesDevices.profession"
    },
    {
      "elementKey": "uriCathAssessment_day",
      "formIndex": "15",
      "inputType": "visitDay",
      "mandatory": true,
      "tableColumn": "1",
      "validation": "visitDay",
      "recHeader": true,
      "fqn": "linesDevices.day"
    },
    {
      "elementKey": "uriCathAssessment_time",
      "formIndex": "15",
      "inputType": "visitTime",
      "mandatory": true,
      "tableColumn": "1",
      "validation": "time24",
      "recHeader": true,
      "fqn": "linesDevices.time"
    },
    {
      "elementKey": "uriCathAsmtComplications",
      "formIndex": "15",
      "formCss": "grid-span-3-columns",
      "inputType": "checkset",
      "options": [
        {
          "key": "erythema",
          "text": "Erythema"
        },
        {
          "key": "edema",
          "text": "Edema"
        },
        {
          "key": "hematoma",
          "text": "Hematoma"
        },
        {
          "key": "discharge",
          "text": "Discharge"
        },
        {
          "key": "infiltration",
          "text": "Infiltration"
        },
        {
          "key": "extravasation",
          "text": "Extravasation"
        },
        {
          "key": "swelling",
          "text": "Swelling"
        },
        {
          "key": "phlebitis",
          "text": "Phlebitis"
        },
        {
          "key": "thrombophlebitis",
          "text": "Thrombophlebitis"
        },
        {
          "key": "stomaIrritation",
          "text": "Stoma irritation"
        },
        {
          "key": "stomaInfection",
          "text": "Stoma infection"
        },
        {
          "key": "bagLeakage",
          "text": "Bag leakage"
        },
        {
          "key": "stomaGranulation",
          "text": "Stoma granulation"
        },
        {
          "key": "other",
          "text": "Other"
        }
      ],
      "tableColumn": "2",
      "fqn": "linesDevices.uriCathAsmtComplications"
    },
    {
      "elementKey": "uriCathAsmtPlacement",
      "formIndex": "15",
      "inputType": "select",
      "options": [
        {
          "key": "secure",
          "text": "Secure"
        },
        {
          "key": "dislodged",
          "text": "Dislodged"
        },
        {
          "key": "atRisk",
          "text": "At risk"
        }
      ],
      "tableColumn": "3",
      "fqn": "linesDevices.uriCathAsmtPlacement"
    },
    {
      "elementKey": "uriCathAsmtDischarge",
      "formIndex": "15",
      "inputType": "select",
      "options": [
        {
          "key": "bilious",
          "text": "Bilious"
        },
        {
          "key": "brownTan",
          "text": "Brown/Tan"
        },
        {
          "key": "feculent",
          "text": "Feculent"
        },
        {
          "key": "mucous",
          "text": "Mucous"
        },
        {
          "key": "purulent",
          "text": "Purulent"
        },
        {
          "key": "sanguineous",
          "text": "Sanguineous"
        },
        {
          "key": "serosanguineous",
          "text": "Serosanguineous"
        },
        {
          "key": "serous",
          "text": "Serous"
        },
        {
          "key": "other",
          "text": "Other"
        }
      ],
      "tableColumn": "4",
      "fqn": "linesDevices.uriCathAsmtDischarge"
    },
    {
      "elementKey": "uriCathAsmtDisVol",
      "formIndex": "15",
      "inputType": "decimal2",
      "suffix": "mL",
      "tableColumn": "5",
      "fqn": "linesDevices.uriCathAsmtDisVol",
      "suffixText": "mL"
    },
    {
      "elementKey": "uriCathAssmtLengthEx",
      "formIndex": "15",
      "inputType": "decimal2",
      "suffix": "cm",
      "tableColumn": "6",
      "fqn": "linesDevices.uriCathAssmtLengthEx",
      "suffixText": "cm"
    },
    {
      "elementKey": "uriCathAsmtSiteWarmth",
      "formIndex": "15",
      "inputType": "select",
      "options": [
        {
          "key": "normal",
          "text": "Normal"
        },
        {
          "key": "warm",
          "text": "Warm"
        },
        {
          "key": "hot",
          "text": "Hot"
        }
      ],
      "tableColumn": "7",
      "fqn": "linesDevices.uriCathAsmtSiteWarmth"
    },
    {
      "elementKey": "uriCathAsmtSkin",
      "formIndex": "15",
      "inputType": "checkset",
      "options": [
        {
          "key": "intact",
          "text": "Intact"
        },
        {
          "key": "erythema",
          "text": "Erythema"
        },
        {
          "key": "irritation",
          "text": "Irritation"
        },
        {
          "key": "breakdown",
          "text": "Breakdown"
        },
        {
          "key": "infection",
          "text": "Infection"
        },
        {
          "key": "exudate",
          "text": "Exudate"
        },
        {
          "key": "dryness",
          "text": "Dryness"
        },
        {
          "key": "edema",
          "text": "Edema"
        }
      ],
      "tableColumn": "8",
      "fqn": "linesDevices.uriCathAsmtSkin"
    },
    {
      "elementKey": "uriCathAsmtSystemicSymptoms",
      "formIndex": "15",
      "inputType": "checkset",
      "options": [
        {
          "key": "fever",
          "text": "Fever"
        },
        {
          "key": "chills",
          "text": "Chills"
        },
        {
          "key": "malaise",
          "text": "Malaise"
        },
        {
          "key": "none",
          "text": "None"
        }
      ],
      "tableColumn": "9",
      "fqn": "linesDevices.uriCathAsmtSystemicSymptoms"
    },
    {
      "elementKey": "uriCathLumenBloodReturn",
      "formIndex": "15",
      "inputType": "select",
      "options": [
        {
          "key": "present",
          "text": "Present"
        },
        {
          "key": "absent",
          "text": "Absent"
        },
        {
          "key": "sluggish",
          "text": "Sluggish"
        }
      ],
      "tableColumn": "10",
      "fqn": "linesDevices.uriCathLumenBloodReturn"
    },
    {
      "elementKey": "uriCathLumenFlushType",
      "formIndex": "15",
      "inputType": "select",
      "options": [
        {
          "key": "saline",
          "text": "Saline"
        },
        {
          "key": "heparin",
          "text": "Heparin"
        },
        {
          "key": "other",
          "text": "Other"
        }
      ],
      "tableColumn": "11",
      "fqn": "linesDevices.uriCathLumenFlushType"
    },
    {
      "elementKey": "uriCathLumenFlushVol",
      "formIndex": "15",
      "inputType": "number",
      "suffix": "mL",
      "tableColumn": "12",
      "fqn": "linesDevices.uriCathLumenFlushVol",
      "suffixText": "mL"
    },
    {
      "elementKey": "uriCathLumenPatency",
      "formIndex": "15",
      "inputType": "select",
      "options": [
        {
          "key": "patent",
          "text": "Patent"
        },
        {
          "key": "sluggish",
          "text": "Sluggish"
        },
        {
          "key": "occluded",
          "text": "Occluded"
        }
      ],
      "tableColumn": "13",
      "fqn": "linesDevices.uriCathLumenPatency"
    },
    {
      "elementKey": "uriCathLumenResistance",
      "formIndex": "15",
      "inputType": "select",
      "options": [
        {
          "key": "mild",
          "text": "Mild"
        },
        {
          "key": "moderate",
          "text": "Moderate"
        },
        {
          "key": "severe",
          "text": "Severe"
        }
      ],
      "tableColumn": "14",
      "fqn": "linesDevices.uriCathLumenResistance"
    },
    {
      "elementKey": "uriCathBalloonPatency",
      "formIndex": "15",
      "inputType": "select",
      "options": [
        {
          "key": "leaking",
          "text": "Leaking"
        },
        {
          "key": "occluded",
          "text": "Occluded"
        },
        {
          "key": "patent",
          "text": "Patent"
        }
      ],
      "tableColumn": "15",
      "fqn": "linesDevices.uriCathBalloonPatency"
    },
    {
      "elementKey": "uriCathBalloonStatus",
      "formIndex": "15",
      "inputType": "select",
      "options": [
        {
          "key": "deflated",
          "text": "Deflated"
        },
        {
          "key": "inflated",
          "text": "Inflated"
        },
        {
          "key": "leaking",
          "text": "Leaking"
        }
      ],
      "tableColumn": "16",
      "fqn": "linesDevices.uriCathBalloonStatus"
    },
    {
      "elementKey": "uriCathAsmtDressing",
      "formIndex": "15",
      "inputType": "checkbox",
      "tableColumn": "17",
      "fqn": "linesDevices.uriCathAsmtDressing"
    },
    {
      "elementKey": "uriCathAsmtSecurement",
      "formIndex": "15",
      "inputType": "checkbox",
      "tableColumn": "18",
      "fqn": "linesDevices.uriCathAsmtSecurement"
    },
    {
      "elementKey": "uriCathAsmtComments",
      "formIndex": "15",
      "inputType": "textarea",
      "tableColumn": "19",
      "fqn": "linesDevices.uriCathAsmtComments"
    },
    {
      "elementKey": "airwayProfile_name",
      "formIndex": "16",
      "inputType": "practitionerName",
      "tableColumn": "1",
      "recHeader": true,
      "fqn": "linesDevices.name"
    },
    {
      "elementKey": "airwayProfile_id",
      "inputType": "generatedId",
      "tableColumn": 0,
      "label": "Row id",
      "tableCss": "row-id"
    },
    {
      "elementKey": "airwayProfile_profession",
      "formIndex": "16",
      "inputType": "practitionerProfession",
      "tableColumn": "1",
      "recHeader": true,
      "fqn": "linesDevices.profession"
    },
    {
      "elementKey": "airwayProfile_day",
      "formIndex": "16",
      "inputType": "visitDay",
      "mandatory": true,
      "tableColumn": "1",
      "validation": "visitDay",
      "recHeader": true,
      "fqn": "linesDevices.day"
    },
    {
      "elementKey": "airwayProfile_time",
      "formIndex": "16",
      "inputType": "visitTime",
      "mandatory": true,
      "tableColumn": "1",
      "validation": "time24",
      "recHeader": true,
      "fqn": "linesDevices.time"
    },
    {
      "elementKey": "airwayType",
      "formIndex": "16",
      "formCss": "grid-span-3-columns",
      "inputType": "select",
      "options": [
        {
          "key": "etTUBE",
          "text": "Endotracheal Tube (ETT)"
        },
        {
          "key": "nasopharyngeal",
          "text": "Nasopharyngeal Airway (NPA)"
        },
        {
          "key": "oropharyngeal",
          "text": "Oropharyngeal Airway (OPA)"
        },
        {
          "key": "tracheostomy",
          "text": "Tracheostomy Tube"
        }
      ],
      "tableColumn": "2",
      "fqn": "linesDevices.airwayType"
    },
    {
      "elementKey": "airwayInsertion",
      "formIndex": "16",
      "inputType": "select",
      "options": [
        {
          "key": "assisted",
          "text": "Assisted"
        },
        {
          "key": "blind",
          "text": "Blind"
        },
        {
          "key": "cleanTechnique",
          "text": "Clean technique"
        },
        {
          "key": "sterileTechnique",
          "text": "Sterile technique"
        },
        {
          "key": "surgical",
          "text": "Surgical"
        },
        {
          "key": "ultrasound",
          "text": "Ultrasound guided"
        }
      ],
      "tableColumn": "3",
      "fqn": "linesDevices.airwayInsertion"
    },
    {
      "elementKey": "airwayLocation",
      "formIndex": "16",
      "inputType": "select",
      "options": [
        {
          "key": "nasopharyngeal",
          "text": "Nasopharyngeal"
        },
        {
          "key": "oropharyngeal",
          "text": "Oropharyngeal"
        },
        {
          "key": "trachea",
          "text": "Trachea"
        }
      ],
      "tableColumn": "4",
      "fqn": "linesDevices.airwayLocation"
    },
    {
      "elementKey": "airwayGauge",
      "formIndex": "16",
      "inputType": "select",
      "options": [
        {
          "key": "fr6",
          "text": "6 Fr"
        },
        {
          "key": "fr8",
          "text": "8 Fr"
        },
        {
          "key": "fr10",
          "text": "10 Fr"
        },
        {
          "key": "fr12",
          "text": "12 Fr"
        },
        {
          "key": "fr14",
          "text": "14 Fr"
        }
      ],
      "tableColumn": "5",
      "fqn": "linesDevices.airwayGauge"
    },
    {
      "elementKey": "airwaySecurement",
      "formIndex": "16",
      "inputType": "select",
      "options": [
        {
          "key": "cuff",
          "text": "Cuff inflation (for ETT and tracheostomy)"
        },
        {
          "key": "suture",
          "text": "Sutures"
        },
        {
          "key": "tape",
          "text": "Adhesive tape"
        }
      ],
      "tableColumn": "6",
      "fqn": "linesDevices.airwaySecurement"
    },
    {
      "elementKey": "airwayLengthIn",
      "formIndex": "16",
      "inputType": "decimal2",
      "suffix": "cm",
      "tableColumn": "7",
      "fqn": "linesDevices.airwayLengthIn",
      "suffixText": "cm"
    },
    {
      "elementKey": "airwayLengthEx",
      "formIndex": "16",
      "inputType": "decimal2",
      "suffix": "cm",
      "tableColumn": "8",
      "fqn": "linesDevices.airwayLengthEx",
      "suffixText": "cm"
    },
    {
      "elementKey": "airwayInsertDay",
      "formIndex": "16",
      "inputType": "visitDay",
      "tableColumn": "9",
      "validation": "visitDay",
      "fqn": "linesDevices.airwayInsertDay"
    },
    {
      "elementKey": "airwayInsertTime",
      "formIndex": "16",
      "inputType": "visitTime",
      "tableColumn": "10",
      "validation": "time24",
      "fqn": "linesDevices.airwayInsertTime"
    },
    {
      "elementKey": "airwayRemovalDay",
      "formIndex": "16",
      "inputType": "visitDay",
      "tableAction": "allowChange",
      "tableColumn": "11",
      "validation": "visitDay",
      "fqn": "linesDevices.airwayRemovalDay"
    },
    {
      "elementKey": "airwayRemovalTime",
      "formIndex": "16",
      "inputType": "visitTime",
      "tableAction": "allowChange",
      "tableColumn": "12",
      "validation": "time24",
      "fqn": "linesDevices.airwayRemovalTime"
    },
    {
      "elementKey": "airwayComments",
      "formIndex": "16",
      "inputType": "textarea",
      "tableAction": "allowChange",
      "tableColumn": "13",
      "fqn": "linesDevices.airwayComments"
    },
    {
      "elementKey": "airwayAssessment_name",
      "formIndex": "17",
      "inputType": "practitionerName",
      "tableColumn": "1",
      "recHeader": true,
      "fqn": "linesDevices.name"
    },
    {
      "elementKey": "airwayAssessment_id",
      "inputType": "generatedId",
      "tableColumn": 0,
      "label": "Row id",
      "tableCss": "row-id"
    },
    {
      "elementKey": "airwayAssessment_profession",
      "formIndex": "17",
      "inputType": "practitionerProfession",
      "tableColumn": "1",
      "recHeader": true,
      "fqn": "linesDevices.profession"
    },
    {
      "elementKey": "airwayAssessment_day",
      "formIndex": "17",
      "inputType": "visitDay",
      "mandatory": true,
      "tableColumn": "1",
      "validation": "visitDay",
      "recHeader": true,
      "fqn": "linesDevices.day"
    },
    {
      "elementKey": "airwayAssessment_time",
      "formIndex": "17",
      "inputType": "visitTime",
      "mandatory": true,
      "tableColumn": "1",
      "validation": "time24",
      "recHeader": true,
      "fqn": "linesDevices.time"
    },
    {
      "elementKey": "airwayAsmtComplications",
      "formIndex": "17",
      "formCss": "grid-span-3-columns",
      "inputType": "checkset",
      "options": [
        {
          "key": "extubation",
          "text": "Unplanned extubation"
        },
        {
          "key": "cuffLeak",
          "text": "Cuff leak"
        },
        {
          "key": "dislodged",
          "text": "Dislodged"
        },
        {
          "key": "infection",
          "text": "Infection"
        },
        {
          "key": "obstruction",
          "text": "Obstruction"
        }
      ],
      "tableColumn": "2",
      "fqn": "linesDevices.airwayAsmtComplications"
    },
    {
      "elementKey": "airwayAsmtPlacement",
      "formIndex": "17",
      "inputType": "select",
      "options": [
        {
          "key": "secure",
          "text": "Secure"
        },
        {
          "key": "dislodged",
          "text": "Dislodged"
        },
        {
          "key": "atRisk",
          "text": "At risk"
        }
      ],
      "tableColumn": "3",
      "fqn": "linesDevices.airwayAsmtPlacement"
    },
    {
      "elementKey": "airwayAsmtPatency",
      "formIndex": "17",
      "inputType": "select",
      "options": [
        {
          "key": "leak",
          "text": "Leak"
        },
        {
          "key": "obstructed",
          "text": "Obstructed"
        },
        {
          "key": "patent",
          "text": "Patent"
        }
      ],
      "tableColumn": "4",
      "fqn": "linesDevices.airwayAsmtPatency"
    },
    {
      "elementKey": "airwayAsmtDressing",
      "formIndex": "17",
      "inputType": "checkbox",
      "tableColumn": "5",
      "fqn": "linesDevices.airwayAsmtDressing"
    },
    {
      "elementKey": "airwayAsmtSecurement",
      "formIndex": "17",
      "inputType": "checkbox",
      "tableColumn": "6",
      "fqn": "linesDevices.airwayAsmtSecurement"
    },
    {
      "elementKey": "airwayAsmtComments",
      "formIndex": "17",
      "inputType": "textarea",
      "tableColumn": "7",
      "fqn": "linesDevices.airwayAsmtComments"
    }
  ],
  "pageElements": {
    "chestTubeProfile": {
      "elementKey": "chestTubeProfile",
      "pageElementIndex": "1",
      "pageKey": "linesDevices",
      "tableKey": "chestTubeProfile",
      "isTable": true,
      "form": {
        "elementKey": "chestTubeProfile",
        "label": "Chest Tube Profile",
        "addButtonText": "Add a Chest Tube Profile",
        "formKey": "chestTubeProfile",
        "ehr_groups": [
          {
            "elementKey": "cttp_1",
            "formCss": "record-header",
            "fqn": "linesDevices.cttp_1",
            "gIndex": "1",
            "gChildren": [
              "chestTubeProfile_name",
              "chestTubeProfile_profession",
              "chestTubeProfile_day",
              "chestTubeProfile_time"
            ]
          },
          {
            "elementKey": "cttp_2",
            "formCss": "grid-left-to-right-2",
            "fqn": "linesDevices.cttp_2",
            "gIndex": "2",
            "gChildren": [
              "tubeLabel",
              "tubeLocation",
              "tubeReason",
              "tubeSuction"
            ]
          },
          {
            "elementKey": "cttp_3",
            "formCss": "grid-left-to-right-2",
            "fqn": "linesDevices.cttp_3",
            "gIndex": "3",
            "gChildren": [
              "tubeInsertDay",
              "tubeInsertTime",
              "tubeRmovalDay",
              "tubeRemovalTime"
            ]
          },
          {
            "elementKey": "cttp_3",
            "formCss": "grid-left-to-right-3",
            "fqn": "linesDevices.cttp_3",
            "gIndex": "4",
            "gChildren": [
              "comments"
            ]
          }
        ],
        "ehr_data": {
          "chestTubeProfile_name": "",
          "chestTubeProfile_profession": "",
          "chestTubeProfile_day": "",
          "chestTubeProfile_time": "",
          "tubeLabel": "",
          "tubeLocation": "",
          "tubeReason": "",
          "tubeSuction": "--tubeSuction--",
          "tubeInsertDay": "",
          "tubeInsertTime": "",
          "tubeRmovalDay": "",
          "tubeRemovalTime": "",
          "comments": ""
        }
      },
      "fqn": "linesDevices.chestTubeProfile",
      "tableChildren": [
        "chestTubeProfile_id",
        "chestTubeProfile_day",
        "chestTubeProfile_time",
        "tubeLabel",
        "tubeLocation",
        "tubeReason",
        "tubeSuction",
        "tubeInsertDay",
        "tubeInsertTime",
        "tubeRmovalDay",
        "tubeRemovalTime",
        "comments",
        "chestTubeProfile_name",
        "chestTubeProfile_profession"
      ],
      "hasRecHeader": true
    },
    "chestTubeAssessment": {
      "elementKey": "chestTubeAssessment",
      "pageElementIndex": "2",
      "pageKey": "linesDevices",
      "tableKey": "chestTubeAssessment",
      "isTable": true,
      "form": {
        "elementKey": "chestTubeAssessment",
        "label": "Chest Tube Assessment",
        "addButtonText": "Add a Chest Tube Assessment",
        "formKey": "chestTubeAssessment",
        "ehr_groups": [
          {
            "elementKey": "cta_1",
            "formCss": "record-header",
            "fqn": "linesDevices.cta_1",
            "gIndex": "1",
            "gChildren": [
              "chestTubeAssessment_name",
              "chestTubeAssessment_profession",
              "chestTubeAssessment_day",
              "chestTubeAssessment_time"
            ]
          },
          {
            "elementKey": "cta_2",
            "formCss": "grid-left-to-right-1",
            "fqn": "linesDevices.cta_2",
            "gIndex": "2",
            "gChildren": [
              "ctaLabel"
            ]
          },
          {
            "elementKey": "cta_3",
            "formCss": "chest-tube-assessment form-table-group",
            "fqn": "linesDevices.cta_3",
            "gIndex": "3",
            "gChildren": [
              "ctaLabel3",
              "ctaPatient",
              "ctaLabel4",
              "ctaDressing",
              "ctaLabel5",
              "ctaChanged",
              "ctaLabel6",
              "ctaSubcutaneous",
              "ctaLabel7",
              "ctaSmallBore",
              "ctaLabel8",
              "ctaConnections",
              "ctaLabel9",
              "ctaTubing",
              "ctaLabel10",
              "ctaBed",
              "ctaLabel11",
              "ctaBreathing",
              "ctaLabel12",
              "ctaPleurEvac",
              "ctaLabel13",
              "ctaSuctionControlDial",
              "ctaLabel14",
              "ctaOrangeFloat",
              "ctaLabel15",
              "ctaAirLeakMeter",
              "ctaLabel16",
              "ctaAirLeakReading",
              "ctaLabel17",
              "ctaTidaling",
              "ctaLabel18",
              "ctaNegativePressure",
              "ctaLabel19",
              "ctaDrainageStatus",
              "ctaLabel20",
              "ctaDrainageType",
              "ctaLabel21",
              "ctaEmergEquip",
              "ctaEquipList"
            ]
          }
        ],
        "ehr_data": {
          "chestTubeAssessment_name": "",
          "chestTubeAssessment_profession": "",
          "chestTubeAssessment_day": "",
          "chestTubeAssessment_time": "",
          "ctaLabel": "",
          "ctaPatient": "",
          "ctaDressing": "",
          "ctaChanged": "",
          "ctaSubcutaneous": "",
          "ctaSmallBore": "",
          "ctaConnections": "",
          "ctaTubing": "",
          "ctaBed": "",
          "ctaBreathing": "",
          "ctaPleurEvac": "",
          "ctaSuctionControlDial": "",
          "ctaOrangeFloat": "",
          "ctaAirLeakMeter": "",
          "ctaAirLeakReading": "",
          "ctaTidaling": "",
          "ctaNegativePressure": "",
          "ctaDrainageStatus": "",
          "ctaDrainageType": "",
          "ctaEmergEquip": ""
        }
      },
      "fqn": "linesDevices.chestTubeAssessment",
      "tableChildren": [
        "chestTubeAssessment_id",
        "chestTubeAssessment_day",
        "chestTubeAssessment_time",
        "ctaLabel",
        "ctaPatient",
        "ctaDressing",
        "ctaChanged",
        "ctaSubcutaneous",
        "ctaSmallBore",
        "ctaConnections",
        "ctaTubing",
        "ctaBed",
        "ctaBreathing",
        "ctaPleurEvac",
        "ctaSuctionControlDial",
        "ctaOrangeFloat",
        "ctaAirLeakMeter",
        "ctaAirLeakReading",
        "ctaTidaling",
        "ctaNegativePressure",
        "ctaDrainageStatus",
        "ctaDrainageType",
        "ctaEmergEquip",
        "chestTubeAssessment_name",
        "chestTubeAssessment_profession"
      ],
      "hasRecHeader": true
    },
    "enteralProfile": {
      "elementKey": "enteralProfile",
      "pageElementIndex": "3",
      "pageKey": "linesDevices",
      "tableKey": "enteralProfile",
      "isTable": true,
      "form": {
        "elementKey": "enteralProfile",
        "label": "Enteral Support",
        "addButtonText": "Add a Enteral Support Profile",
        "formKey": "enteralProfile",
        "ehr_groups": [
          {
            "elementKey": "enteral_1",
            "formCss": "record-header",
            "fqn": "linesDevices.enteral_1",
            "gIndex": "1",
            "gChildren": [
              "enteralProfile_name",
              "enteralProfile_profession",
              "enteralProfile_day",
              "enteralProfile_time"
            ]
          },
          {
            "elementKey": "enteral_2",
            "formCss": "grid-left-to-right-3",
            "fqn": "linesDevices.enteral_2",
            "gIndex": "2",
            "gChildren": [
              "enteralLabel",
              "enteralPurpose",
              "enteralStatus",
              "enteralType"
            ]
          },
          {
            "elementKey": "enteral_3",
            "formCss": "grid-left-to-right-3",
            "fqn": "linesDevices.enteral_3",
            "gIndex": "3",
            "gChildren": [
              "enteralLocation",
              "enteralGauge",
              "enteralLength",
              "enteralFeedFormula",
              "enteralFeedMethod",
              "enteralFeedRate",
              "enteralPh",
              "enteralPlacement",
              "enteralSuctionType",
              "enteralSuctionPressure",
              "enteralSecurement"
            ]
          },
          {
            "elementKey": "enteral_4",
            "formCss": "grid-left-to-right-2",
            "fqn": "linesDevices.enteral_4",
            "gIndex": "4",
            "gChildren": [
              "enteralInsertDay",
              "enteralInsertTime",
              "enteralRemovalDay",
              "enteralRemovalTime"
            ]
          },
          {
            "elementKey": "enteral_3",
            "formCss": "grid-left-to-right-1",
            "fqn": "linesDevices.enteral_3",
            "gIndex": "5",
            "gChildren": [
              "enteralComments"
            ]
          }
        ],
        "ehr_data": {
          "enteralProfile_name": "",
          "enteralProfile_profession": "",
          "enteralProfile_day": "",
          "enteralProfile_time": "",
          "enteralLabel": "",
          "enteralPurpose": "",
          "enteralStatus": "",
          "enteralType": "",
          "enteralLocation": "",
          "enteralGauge": "",
          "enteralLength": "",
          "enteralFeedFormula": "",
          "enteralFeedMethod": "",
          "enteralFeedRate": "",
          "enteralPh": "",
          "enteralPlacement": "",
          "enteralSuctionType": "",
          "enteralSuctionPressure": "",
          "enteralSecurement": "",
          "enteralInsertDay": "",
          "enteralInsertTime": "",
          "enteralRemovalDay": "",
          "enteralRemovalTime": "",
          "enteralComments": ""
        }
      },
      "fqn": "linesDevices.enteralProfile",
      "tableChildren": [
        "enteralProfile_id",
        "enteralProfile_day",
        "enteralProfile_time",
        "enteralLabel",
        "enteralPurpose",
        "enteralStatus",
        "enteralType",
        "enteralLocation",
        "enteralGauge",
        "enteralLength",
        "enteralFeedFormula",
        "enteralFeedMethod",
        "enteralFeedRate",
        "enteralPh",
        "enteralPlacement",
        "enteralSuctionType",
        "enteralSuctionPressure",
        "enteralSecurement",
        "enteralInsertDay",
        "enteralInsertTime",
        "enteralRemovalDay",
        "enteralRemovalTime",
        "enteralComments",
        "enteralProfile_name",
        "enteralProfile_profession"
      ],
      "hasRecHeader": true
    },
    "enteralSupportAssessment": {
      "elementKey": "enteralSupportAssessment",
      "pageElementIndex": "4",
      "pageKey": "linesDevices",
      "tableKey": "enteralSupportAssessment",
      "isTable": true,
      "form": {
        "elementKey": "enteralSupportAssessment",
        "label": "Enteral Support Assessment",
        "addButtonText": "Add a Enteral Support Assessment",
        "formKey": "enteralSupportAssessment",
        "ehr_groups": [
          {
            "elementKey": "entass_1",
            "formCss": "record-header",
            "fqn": "linesDevices.entass_1",
            "gIndex": "1",
            "gChildren": [
              "enteralSupportAssessment_name",
              "enteralSupportAssessment_profession",
              "enteralSupportAssessment_day",
              "enteralSupportAssessment_time"
            ]
          },
          {
            "elementKey": "entass_2",
            "formCss": "grid-left-to-right-3",
            "fqn": "linesDevices.entass_2",
            "gIndex": "2",
            "gChildren": [
              "entassLabel",
              "entassComplications",
              "entassPlacement"
            ]
          },
          {
            "elementKey": "entass_3",
            "formCss": "grid-left-to-right-3",
            "fqn": "linesDevices.entass_3",
            "gIndex": "3",
            "gChildren": [
              "entassDischarge",
              "entassAmount",
              "entassPatency",
              "entassSkin"
            ]
          },
          {
            "elementKey": "entass_3",
            "formCss": "grid-left-to-right-1",
            "fqn": "linesDevices.entass_3",
            "gIndex": "4",
            "gChildren": [
              "entassComments"
            ]
          }
        ],
        "ehr_data": {
          "enteralSupportAssessment_name": "",
          "enteralSupportAssessment_profession": "",
          "enteralSupportAssessment_day": "",
          "enteralSupportAssessment_time": "",
          "entassLabel": "",
          "entassComplications": "",
          "entassPlacement": "",
          "entassDischarge": "",
          "entassAmount": "",
          "entassPatency": "",
          "entassSkin": "",
          "entassComments": ""
        }
      },
      "fqn": "linesDevices.enteralSupportAssessment",
      "tableChildren": [
        "enteralSupportAssessment_id",
        "enteralSupportAssessment_day",
        "enteralSupportAssessment_time",
        "entassLabel",
        "entassComplications",
        "entassPlacement",
        "entassDischarge",
        "entassAmount",
        "entassPatency",
        "entassSkin",
        "entassComments",
        "enteralSupportAssessment_name",
        "enteralSupportAssessment_profession"
      ],
      "hasRecHeader": true
    },
    "periphIvProfile": {
      "elementKey": "periphIvProfile",
      "pageElementIndex": "5",
      "pageKey": "linesDevices",
      "tableKey": "periphIvProfile",
      "isTable": true,
      "form": {
        "elementKey": "periphIvProfile",
        "label": "Peripheral IV",
        "addButtonText": "Add a Peripheral IV Profile",
        "formKey": "periphIvProfile",
        "ehr_groups": [
          {
            "elementKey": "periphIv_1",
            "formCss": "record-header",
            "fqn": "linesDevices.periphIv_1",
            "gIndex": "1",
            "gChildren": [
              "periphIvProfile_name",
              "periphIvProfile_profession",
              "periphIvProfile_day",
              "periphIvProfile_time"
            ]
          },
          {
            "elementKey": "periphIv_2",
            "formCss": "grid-left-to-right-3",
            "fqn": "linesDevices.periphIv_2",
            "gIndex": "2",
            "gChildren": [
              "periphIvLabel",
              "periphIvStatus"
            ]
          },
          {
            "elementKey": "periphIv_3",
            "formCss": "grid-left-to-right-3",
            "fqn": "linesDevices.periphIv_3",
            "gIndex": "3",
            "gChildren": [
              "periphIvGauge",
              "periphIvHemi",
              "periphIvLocation",
              "periphIvMethod",
              "periphIvSecurement",
              "periphIvCircumference"
            ]
          },
          {
            "elementKey": "periphIv_4",
            "formCss": "grid-left-to-right-3",
            "fqn": "linesDevices.periphIv_4",
            "gIndex": "4",
            "gChildren": [
              "periphIvInsertDay",
              "periphIvInsertTime",
              "periphIvRemovalDay",
              "periphIvRemovalTime"
            ]
          },
          {
            "elementKey": "periphIv_5",
            "formCss": "grid-left-to-right-1",
            "fqn": "linesDevices.periphIv_5",
            "gIndex": "5",
            "gChildren": [
              "periphIvComments"
            ]
          }
        ],
        "ehr_data": {
          "periphIvProfile_name": "",
          "periphIvProfile_profession": "",
          "periphIvProfile_day": "",
          "periphIvProfile_time": "",
          "periphIvLabel": "",
          "periphIvStatus": "",
          "periphIvGauge": "",
          "periphIvHemi": "",
          "periphIvLocation": "",
          "periphIvMethod": "",
          "periphIvSecurement": "",
          "periphIvCircumference": "",
          "periphIvInsertDay": "",
          "periphIvInsertTime": "",
          "periphIvRemovalDay": "",
          "periphIvRemovalTime": "",
          "periphIvComments": ""
        }
      },
      "fqn": "linesDevices.periphIvProfile",
      "tableChildren": [
        "periphIvProfile_id",
        "periphIvProfile_day",
        "periphIvProfile_time",
        "periphIvLabel",
        "periphIvStatus",
        "periphIvGauge",
        "periphIvHemi",
        "periphIvLocation",
        "periphIvMethod",
        "periphIvSecurement",
        "periphIvCircumference",
        "periphIvInsertDay",
        "periphIvInsertTime",
        "periphIvRemovalDay",
        "periphIvRemovalTime",
        "periphIvComments",
        "periphIvProfile_name",
        "periphIvProfile_profession"
      ],
      "hasRecHeader": true
    },
    "pivasSiteAssessment": {
      "elementKey": "pivasSiteAssessment",
      "pageElementIndex": "6",
      "pageKey": "linesDevices",
      "tableKey": "pivasSiteAssessment",
      "isTable": true,
      "form": {
        "elementKey": "pivasSiteAssessment",
        "label": "Peripheral IV Site Assessment",
        "addButtonText": "Add a Peripheral IV Site Assessment",
        "formKey": "pivasSiteAssessment",
        "ehr_groups": [
          {
            "elementKey": "pivas_1",
            "formCss": "record-header",
            "fqn": "linesDevices.pivas_1",
            "gIndex": "1",
            "gChildren": [
              "pivasSiteAssessment_name",
              "pivasSiteAssessment_profession",
              "pivasSiteAssessment_day",
              "pivasSiteAssessment_time"
            ]
          },
          {
            "elementKey": "pivas_2",
            "formCss": "grid-left-to-right-3",
            "fqn": "linesDevices.pivas_2",
            "gIndex": "2",
            "gChildren": [
              "pivasLabel",
              "pivasComplications",
              "pivasPlacement",
              "pivasCircumference",
              "pivasDischarge",
              "pivasDisVol",
              "pivasLength",
              "pivasSiteWarmth",
              "pivasSkin",
              "pivasSystemicSymptoms"
            ]
          },
          {
            "elementKey": "pivas_5",
            "formCss": "grid-left-to-right-3",
            "fqn": "linesDevices.pivas_5",
            "gIndex": "3",
            "gChildren": [
              "pivasDressing",
              "pivasSecurement"
            ]
          },
          {
            "elementKey": "pivasLumenGroup",
            "formCss": "grid-left-to-right-3",
            "fqn": "linesDevices.pivasLumenGroup",
            "gIndex": "4",
            "gChildren": [
              "pivasBloodReturn",
              "pivasFlushType",
              "pivasFlushVol",
              "pivasPatency",
              "pivasResistance"
            ]
          },
          {
            "elementKey": "pivas_6",
            "formCss": "grid-left-to-right-1",
            "fqn": "linesDevices.pivas_6",
            "gIndex": "5",
            "gChildren": [
              "pivasComments"
            ]
          }
        ],
        "ehr_data": {
          "pivasSiteAssessment_name": "",
          "pivasSiteAssessment_profession": "",
          "pivasSiteAssessment_day": "",
          "pivasSiteAssessment_time": "",
          "pivasLabel": "",
          "pivasComplications": "",
          "pivasPlacement": "",
          "pivasCircumference": "",
          "pivasDischarge": "",
          "pivasDisVol": "",
          "pivasLength": "",
          "pivasSiteWarmth": "",
          "pivasSkin": "",
          "pivasSystemicSymptoms": "",
          "pivasDressing": "",
          "pivasSecurement": "",
          "pivasBloodReturn": "",
          "pivasFlushType": "",
          "pivasFlushVol": "",
          "pivasPatency": "",
          "pivasResistance": "",
          "pivasComments": ""
        }
      },
      "fqn": "linesDevices.pivasSiteAssessment",
      "tableChildren": [
        "pivasSiteAssessment_id",
        "pivasSiteAssessment_day",
        "pivasSiteAssessment_time",
        "pivasLabel",
        "pivasComplications",
        "pivasPlacement",
        "pivasCircumference",
        "pivasDischarge",
        "pivasDisVol",
        "pivasLength",
        "pivasSiteWarmth",
        "pivasSkin",
        "pivasSystemicSymptoms",
        "pivasDressing",
        "pivasSecurement",
        "pivasBloodReturn",
        "pivasFlushType",
        "pivasFlushVol",
        "pivasPatency",
        "pivasResistance",
        "pivasComments",
        "pivasSiteAssessment_name",
        "pivasSiteAssessment_profession"
      ],
      "hasRecHeader": true
    },
    "cvadProfile": {
      "elementKey": "cvadProfile",
      "pageElementIndex": "7",
      "pageKey": "linesDevices",
      "tableKey": "cvadProfile",
      "isTable": true,
      "form": {
        "elementKey": "cvadProfile",
        "label": "CVAD (incl PICC, CVC, Dialysis)",
        "addButtonText": "Add a CVAD (PICC, CVC, etc)",
        "formKey": "cvadProfile",
        "ehr_groups": [
          {
            "elementKey": "cvad_1",
            "formCss": "record-header",
            "fqn": "linesDevices.cvad_1",
            "gIndex": "1",
            "gChildren": [
              "cvadProfile_name",
              "cvadProfile_profession",
              "cvadProfile_day",
              "cvadProfile_time"
            ]
          },
          {
            "elementKey": "cvad_2",
            "formCss": "grid-left-to-right-3",
            "fqn": "linesDevices.cvad_2",
            "gIndex": "2",
            "gChildren": [
              "cvadLabel",
              "cvadType",
              "cvadStatus"
            ]
          },
          {
            "elementKey": "cvad_3",
            "formCss": "grid-left-to-right-3",
            "fqn": "linesDevices.cvad_3",
            "gIndex": "3",
            "gChildren": [
              "cvadGauge",
              "cvadLocation",
              "cvadConfirmation",
              "cvadSecurement",
              "cvadTipLocation",
              "cvadCircumference",
              "cvadLengthIn",
              "cvadLengthEx"
            ]
          },
          {
            "elementKey": "cvad_4",
            "formCss": "grid-left-to-right-2",
            "fqn": "linesDevices.cvad_4",
            "gIndex": "4",
            "gChildren": [
              "cvadInsertDay",
              "cvadInsertTime",
              "cvadRemovalDay",
              "cvadRemovalTime"
            ]
          },
          {
            "elementKey": "cvad_5",
            "formCss": "grid-left-to-right-1",
            "fqn": "linesDevices.cvad_5",
            "gIndex": "5",
            "gChildren": [
              "cvadComments"
            ]
          }
        ],
        "ehr_data": {
          "cvadProfile_name": "",
          "cvadProfile_profession": "",
          "cvadProfile_day": "",
          "cvadProfile_time": "",
          "cvadLabel": "",
          "cvadType": "",
          "cvadStatus": "",
          "cvadGauge": "",
          "cvadLocation": "",
          "cvadConfirmation": "",
          "cvadSecurement": "",
          "cvadTipLocation": "",
          "cvadCircumference": "",
          "cvadLengthIn": "",
          "cvadLengthEx": "",
          "cvadInsertDay": "",
          "cvadInsertTime": "",
          "cvadRemovalDay": "",
          "cvadRemovalTime": "",
          "cvadComments": ""
        }
      },
      "fqn": "linesDevices.cvadProfile",
      "tableChildren": [
        "cvadProfile_id",
        "cvadProfile_day",
        "cvadProfile_time",
        "cvadLabel",
        "cvadType",
        "cvadStatus",
        "cvadGauge",
        "cvadLocation",
        "cvadConfirmation",
        "cvadSecurement",
        "cvadTipLocation",
        "cvadCircumference",
        "cvadLengthIn",
        "cvadLengthEx",
        "cvadInsertDay",
        "cvadInsertTime",
        "cvadRemovalDay",
        "cvadRemovalTime",
        "cvadComments",
        "cvadProfile_name",
        "cvadProfile_profession"
      ],
      "hasRecHeader": true
    },
    "cvadAssessment": {
      "elementKey": "cvadAssessment",
      "pageElementIndex": "8",
      "pageKey": "linesDevices",
      "tableKey": "cvadAssessment",
      "isTable": true,
      "form": {
        "elementKey": "cvadAssessment",
        "label": "CVAD Assessment",
        "addButtonText": "Add a CVAD Assessment",
        "formKey": "cvadAssessment",
        "ehr_groups": [
          {
            "elementKey": "cvadAsmt_1",
            "formCss": "record-header",
            "fqn": "linesDevices.cvadAsmt_1",
            "gIndex": "1",
            "gChildren": [
              "cvadAssessment_name",
              "cvadAssessment_profession",
              "cvadAssessment_day",
              "cvadAssessment_time"
            ]
          },
          {
            "elementKey": "cvadAsmt_2",
            "formCss": "grid-left-to-right-3",
            "fqn": "linesDevices.cvadAsmt_2",
            "gIndex": "2",
            "gChildren": [
              "cvadAsmtLabel",
              "cvadAsmtComplications",
              "cvadAsmtPlacement"
            ]
          },
          {
            "elementKey": "cvadAsmt_2",
            "formCss": "grid-left-to-right-3",
            "fqn": "linesDevices.cvadAsmt_2",
            "gIndex": "3",
            "gChildren": [
              "cvadAsmtCircumference",
              "cvadAsmtDischarge",
              "cvadAsmtDisVol",
              "cvadAssmtLengthEx",
              "cvadAsmtSiteWarmth",
              "cvadAsmtSkin",
              "cvadAsmtSystemicSymptoms"
            ]
          },
          {
            "elementKey": "cvadAsmt_5",
            "formCss": "grid-left-to-right-3",
            "fqn": "linesDevices.cvadAsmt_5",
            "gIndex": "4",
            "gChildren": [
              "cvadAsmtDressing",
              "cvadAsmtSecurement"
            ]
          },
          {
            "elementKey": "cvadAsmt_6",
            "formCss": "grid-left-to-right-1",
            "fqn": "linesDevices.cvadAsmt_6",
            "gIndex": "5",
            "gChildren": [
              "cvadAsmtComments"
            ]
          }
        ],
        "ehr_data": {
          "cvadAssessment_name": "",
          "cvadAssessment_profession": "",
          "cvadAssessment_day": "",
          "cvadAssessment_time": "",
          "cvadAsmtLabel": "",
          "cvadAsmtComplications": "",
          "cvadAsmtPlacement": "",
          "cvadAsmtCircumference": "",
          "cvadAsmtDischarge": "",
          "cvadAsmtDisVol": "",
          "cvadAssmtLengthEx": "",
          "cvadAsmtSiteWarmth": "",
          "cvadAsmtSkin": "",
          "cvadAsmtSystemicSymptoms": "",
          "cvadAsmtDressing": "",
          "cvadAsmtSecurement": "",
          "cvadAsmtComments": ""
        }
      },
      "fqn": "linesDevices.cvadAssessment",
      "tableChildren": [
        "cvadAssessment_id",
        "cvadAssessment_day",
        "cvadAssessment_time",
        "cvadAsmtLabel",
        "cvadAsmtComplications",
        "cvadAsmtPlacement",
        "cvadAsmtCircumference",
        "cvadAsmtDischarge",
        "cvadAsmtDisVol",
        "cvadAssmtLengthEx",
        "cvadAsmtSiteWarmth",
        "cvadAsmtSkin",
        "cvadAsmtSystemicSymptoms",
        "cvadAsmtDressing",
        "cvadAsmtSecurement",
        "cvadAsmtComments",
        "cvadAssessment_name",
        "cvadAssessment_profession"
      ],
      "hasRecHeader": true
    },
    "cvadLumenAssessment": {
      "elementKey": "cvadLumenAssessment",
      "pageElementIndex": "9",
      "pageKey": "linesDevices",
      "tableKey": "cvadLumenAssessment",
      "isTable": true,
      "form": {
        "elementKey": "cvadLumenAssessment",
        "label": "CVAD Lumen Patency and Flush Assessment",
        "addButtonText": "Add a CVAD Lumen Assessment",
        "formKey": "cvadLumenAssessment",
        "ehr_groups": [
          {
            "elementKey": "cvadLumen_1",
            "formCss": "record-header",
            "fqn": "linesDevices.cvadLumen_1",
            "gIndex": "1",
            "gChildren": [
              "cvadLumenAssessment_name",
              "cvadLumenAssessment_profession",
              "cvadLumenAssessment_day",
              "cvadLumenAssessment_time"
            ]
          },
          {
            "elementKey": "cvadLumen_2",
            "formCss": "grid-left-to-right-3",
            "fqn": "linesDevices.cvadLumen_2",
            "gIndex": "2",
            "gChildren": [
              "cvadLumenCvadLabel",
              "cvadLumenPosition",
              "cvadLumenStatus",
              "cvadLumenPurpose"
            ]
          },
          {
            "elementKey": "cvadLumen_3",
            "formCss": "grid-left-to-right-3",
            "fqn": "linesDevices.cvadLumen_3",
            "gIndex": "3",
            "gChildren": [
              "cvadLumenBloodReturn",
              "cvadLumenFlushType",
              "cvadLumenFlushVol",
              "cvadLumenPatency",
              "cvadLumenResistance"
            ]
          },
          {
            "elementKey": "cvadLumen_4",
            "formCss": "grid-left-to-right-1",
            "fqn": "linesDevices.cvadLumen_4",
            "gIndex": "4",
            "gChildren": [
              "cvadLumenComments"
            ]
          }
        ],
        "ehr_data": {
          "cvadLumenAssessment_name": "",
          "cvadLumenAssessment_profession": "",
          "cvadLumenAssessment_day": "",
          "cvadLumenAssessment_time": "",
          "cvadLumenCvadLabel": "",
          "cvadLumenPosition": "",
          "cvadLumenStatus": "",
          "cvadLumenPurpose": "",
          "cvadLumenBloodReturn": "",
          "cvadLumenFlushType": "",
          "cvadLumenFlushVol": "",
          "cvadLumenPatency": "",
          "cvadLumenResistance": "",
          "cvadLumenComments": ""
        }
      },
      "fqn": "linesDevices.cvadLumenAssessment",
      "tableChildren": [
        "cvadLumenAssessment_id",
        "cvadLumenAssessment_day",
        "cvadLumenAssessment_time",
        "cvadLumenCvadLabel",
        "cvadLumenPosition",
        "cvadLumenStatus",
        "cvadLumenPurpose",
        "cvadLumenBloodReturn",
        "cvadLumenFlushType",
        "cvadLumenFlushVol",
        "cvadLumenPatency",
        "cvadLumenResistance",
        "cvadLumenComments",
        "cvadLumenAssessment_name",
        "cvadLumenAssessment_profession"
      ],
      "hasRecHeader": true
    },
    "epidProfile": {
      "elementKey": "epidProfile",
      "pageElementIndex": "10",
      "pageKey": "linesDevices",
      "tableKey": "epidProfile",
      "isTable": true,
      "form": {
        "elementKey": "epidProfile",
        "label": "Epidural",
        "addButtonText": "Add a Epidural profile",
        "formKey": "epidProfile",
        "ehr_groups": [
          {
            "elementKey": "epid_1",
            "formCss": "record-header",
            "fqn": "linesDevices.epid_1",
            "gIndex": "1",
            "gChildren": [
              "epidProfile_name",
              "epidProfile_profession",
              "epidProfile_day",
              "epidProfile_time"
            ]
          },
          {
            "elementKey": "epid_2",
            "formCss": "grid-left-to-right-3",
            "fqn": "linesDevices.epid_2",
            "gIndex": "2",
            "gChildren": [
              "epidStatus"
            ]
          },
          {
            "elementKey": "epid_3",
            "formCss": "grid-left-to-right-3",
            "fqn": "linesDevices.epid_3",
            "gIndex": "3",
            "gChildren": [
              "epidGauge",
              "epidLocation",
              "epidSecurement",
              "epidLengthIn",
              "epidLengthEx"
            ]
          },
          {
            "elementKey": "epid_4",
            "formCss": "grid-left-to-right-2",
            "fqn": "linesDevices.epid_4",
            "gIndex": "4",
            "gChildren": [
              "epidInsertDay",
              "epidInsertTime",
              "epidRemovalDay",
              "epidRemovalTime"
            ]
          },
          {
            "elementKey": "epid_5",
            "formCss": "grid-left-to-right-1",
            "fqn": "linesDevices.epid_5",
            "gIndex": "5",
            "gChildren": [
              "epidComments"
            ]
          }
        ],
        "ehr_data": {
          "epidProfile_name": "",
          "epidProfile_profession": "",
          "epidProfile_day": "",
          "epidProfile_time": "",
          "epidStatus": "",
          "epidGauge": "",
          "epidLocation": "",
          "epidSecurement": "",
          "epidLengthIn": "",
          "epidLengthEx": "",
          "epidInsertDay": "",
          "epidInsertTime": "",
          "epidRemovalDay": "",
          "epidRemovalTime": "",
          "epidComments": ""
        }
      },
      "fqn": "linesDevices.epidProfile",
      "tableChildren": [
        "epidProfile_id",
        "epidProfile_day",
        "epidProfile_time",
        "epidStatus",
        "epidGauge",
        "epidLocation",
        "epidSecurement",
        "epidLengthIn",
        "epidLengthEx",
        "epidInsertDay",
        "epidInsertTime",
        "epidRemovalDay",
        "epidRemovalTime",
        "epidComments",
        "epidProfile_name",
        "epidProfile_profession"
      ],
      "hasRecHeader": true
    },
    "epidAssessment": {
      "elementKey": "epidAssessment",
      "pageElementIndex": "11",
      "pageKey": "linesDevices",
      "tableKey": "epidAssessment",
      "isTable": true,
      "form": {
        "elementKey": "epidAssessment",
        "label": "Epidural Assessment",
        "addButtonText": "Add a Epidural Assessment",
        "formKey": "epidAssessment",
        "ehr_groups": [
          {
            "elementKey": "epidAsmt_1",
            "formCss": "record-header",
            "fqn": "linesDevices.epidAsmt_1",
            "gIndex": "1",
            "gChildren": [
              "epidAssessment_name",
              "epidAssessment_profession",
              "epidAssessment_day",
              "epidAssessment_time"
            ]
          },
          {
            "elementKey": "epidAsmt_2",
            "formCss": "grid-left-to-right-3",
            "fqn": "linesDevices.epidAsmt_2",
            "gIndex": "2",
            "gChildren": [
              "epidAsmtComplications",
              "epidAsmtPlacement"
            ]
          },
          {
            "elementKey": "epidAsmt_2",
            "formCss": "grid-left-to-right-3",
            "fqn": "linesDevices.epidAsmt_2",
            "gIndex": "3",
            "gChildren": [
              "epidAsmtDischarge",
              "epidAsmtDisVol",
              "epidAssmtLengthEx",
              "epidAsmtSiteWarmth",
              "epidAsmtSkin",
              "epidAsmtSystemicSymptoms"
            ]
          },
          {
            "elementKey": "epidAsmt_4",
            "formCss": "grid-left-to-right-3",
            "fqn": "linesDevices.epidAsmt_4",
            "gIndex": "4",
            "gChildren": [
              "epidAsmtLumenFlushType",
              "epidAsmtLumenFlushVol",
              "epidAsmtPatency",
              "epidAsmtFlushResistance"
            ]
          },
          {
            "elementKey": "epidAsmt_5",
            "formCss": "grid-left-to-right-3",
            "fqn": "linesDevices.epidAsmt_5",
            "gIndex": "5",
            "gChildren": [
              "epidAsmtDressing",
              "epidAsmtSecurement"
            ]
          },
          {
            "elementKey": "epidAsmt_6",
            "formCss": "grid-left-to-right-1",
            "fqn": "linesDevices.epidAsmt_6",
            "gIndex": "6",
            "gChildren": [
              "epidAsmtComments"
            ]
          }
        ],
        "ehr_data": {
          "epidAssessment_name": "",
          "epidAssessment_profession": "",
          "epidAssessment_day": "",
          "epidAssessment_time": "",
          "epidAsmtComplications": "",
          "epidAsmtPlacement": "",
          "epidAsmtDischarge": "",
          "epidAsmtDisVol": "",
          "epidAssmtLengthEx": "",
          "epidAsmtSiteWarmth": "",
          "epidAsmtSkin": "",
          "epidAsmtSystemicSymptoms": "",
          "epidAsmtLumenFlushType": "",
          "epidAsmtLumenFlushVol": "",
          "epidAsmtPatency": "",
          "epidAsmtFlushResistance": "",
          "epidAsmtDressing": "",
          "epidAsmtSecurement": "",
          "epidAsmtComments": ""
        }
      },
      "fqn": "linesDevices.epidAssessment",
      "tableChildren": [
        "epidAssessment_id",
        "epidAssessment_day",
        "epidAssessment_time",
        "epidAsmtComplications",
        "epidAsmtPlacement",
        "epidAsmtDischarge",
        "epidAsmtDisVol",
        "epidAssmtLengthEx",
        "epidAsmtSiteWarmth",
        "epidAsmtSkin",
        "epidAsmtSystemicSymptoms",
        "epidAsmtLumenFlushType",
        "epidAsmtLumenFlushVol",
        "epidAsmtPatency",
        "epidAsmtFlushResistance",
        "epidAsmtDressing",
        "epidAsmtSecurement",
        "epidAsmtComments",
        "epidAssessment_name",
        "epidAssessment_profession"
      ],
      "hasRecHeader": true
    },
    "artLineProfile": {
      "elementKey": "artLineProfile",
      "pageElementIndex": "12",
      "pageKey": "linesDevices",
      "tableKey": "artLineProfile",
      "isTable": true,
      "form": {
        "elementKey": "artLineProfile",
        "label": "Arterial Lines",
        "addButtonText": "Add an Arterial Line Profile",
        "formKey": "artLineProfile",
        "ehr_groups": [
          {
            "elementKey": "artLine_1",
            "formCss": "record-header",
            "fqn": "linesDevices.artLine_1",
            "gIndex": "1",
            "gChildren": [
              "artLineProfile_name",
              "artLineProfile_profession",
              "artLineProfile_day",
              "artLineProfile_time"
            ]
          },
          {
            "elementKey": "artLine_2",
            "formCss": "grid-left-to-right-3",
            "fqn": "linesDevices.artLine_2",
            "gIndex": "2",
            "gChildren": [
              "artLineType",
              "artLineStatus"
            ]
          },
          {
            "elementKey": "artLine_3",
            "formCss": "grid-left-to-right-3",
            "fqn": "linesDevices.artLine_3",
            "gIndex": "3",
            "gChildren": [
              "artLineGauge",
              "artLineConfirmation",
              "artLineSecurement",
              "artLineLengthIn",
              "artLineLengthEx"
            ]
          },
          {
            "elementKey": "artLine_4",
            "formCss": "grid-left-to-right-2",
            "fqn": "linesDevices.artLine_4",
            "gIndex": "4",
            "gChildren": [
              "artLineInsertDay",
              "artLineInsertTime",
              "artLineRemovalDay",
              "artLineRemovalTime"
            ]
          },
          {
            "elementKey": "artLine_5",
            "formCss": "grid-left-to-right-1",
            "fqn": "linesDevices.artLine_5",
            "gIndex": "5",
            "gChildren": [
              "artLineComments"
            ]
          }
        ],
        "ehr_data": {
          "artLineProfile_name": "",
          "artLineProfile_profession": "",
          "artLineProfile_day": "",
          "artLineProfile_time": "",
          "artLineType": "",
          "artLineStatus": "",
          "artLineGauge": "",
          "artLineConfirmation": "",
          "artLineSecurement": "",
          "artLineLengthIn": "",
          "artLineLengthEx": "",
          "artLineInsertDay": "",
          "artLineInsertTime": "",
          "artLineRemovalDay": "",
          "artLineRemovalTime": "",
          "artLineComments": ""
        }
      },
      "fqn": "linesDevices.artLineProfile",
      "tableChildren": [
        "artLineProfile_id",
        "artLineProfile_day",
        "artLineProfile_time",
        "artLineType",
        "artLineStatus",
        "artLineGauge",
        "artLineConfirmation",
        "artLineSecurement",
        "artLineLengthIn",
        "artLineLengthEx",
        "artLineInsertDay",
        "artLineInsertTime",
        "artLineRemovalDay",
        "artLineRemovalTime",
        "artLineComments",
        "artLineProfile_name",
        "artLineProfile_profession"
      ],
      "hasRecHeader": true
    },
    "artLineAssessment": {
      "elementKey": "artLineAssessment",
      "pageElementIndex": "13",
      "pageKey": "linesDevices",
      "tableKey": "artLineAssessment",
      "isTable": true,
      "form": {
        "elementKey": "artLineAssessment",
        "label": "Arterial Line Assessment",
        "addButtonText": "Add a CVAD Assessment",
        "formKey": "artLineAssessment",
        "ehr_groups": [
          {
            "elementKey": "artLineAsmt_1",
            "formCss": "record-header",
            "fqn": "linesDevices.artLineAsmt_1",
            "gIndex": "1",
            "gChildren": [
              "artLineAssessment_name",
              "artLineAssessment_profession",
              "artLineAssessment_day",
              "artLineAssessment_time"
            ]
          },
          {
            "elementKey": "artLineAsmt_2",
            "formCss": "grid-left-to-right-3",
            "fqn": "linesDevices.artLineAsmt_2",
            "gIndex": "2",
            "gChildren": [
              "artLineAsmtComplications",
              "artLineAsmtPlacement"
            ]
          },
          {
            "elementKey": "artLineAsmt_2",
            "formCss": "grid-left-to-right-3",
            "fqn": "linesDevices.artLineAsmt_2",
            "gIndex": "3",
            "gChildren": [
              "artLineAsmtCircumference",
              "artLineAsmtDischarge",
              "artLineAsmtDisVol",
              "artLineAssmtLengthEx",
              "artLineAsmtSiteWarmth",
              "artLineAsmtSkin",
              "artLineAsmtSystemicSymptoms"
            ]
          },
          {
            "elementKey": "artLineLumen_3",
            "formCss": "grid-left-to-right-3",
            "fqn": "linesDevices.artLineLumen_3",
            "gIndex": "4",
            "gChildren": [
              "artLineLumenBloodReturn",
              "artLineLumenFlushType",
              "artLineLumenFlushVol",
              "artLineLumenPatency",
              "artLineLumenResistance"
            ]
          },
          {
            "elementKey": "artLineAsmt_5",
            "formCss": "grid-left-to-right-3",
            "fqn": "linesDevices.artLineAsmt_5",
            "gIndex": "5",
            "gChildren": [
              "artLineAsmtDressing",
              "artLineAsmtSecurement"
            ]
          },
          {
            "elementKey": "artLineAsmt_6",
            "formCss": "grid-left-to-right-1",
            "fqn": "linesDevices.artLineAsmt_6",
            "gIndex": "6",
            "gChildren": [
              "artLineAsmtComments"
            ]
          }
        ],
        "ehr_data": {
          "artLineAssessment_name": "",
          "artLineAssessment_profession": "",
          "artLineAssessment_day": "",
          "artLineAssessment_time": "",
          "artLineAsmtComplications": "",
          "artLineAsmtPlacement": "",
          "artLineAsmtCircumference": "",
          "artLineAsmtDischarge": "",
          "artLineAsmtDisVol": "",
          "artLineAssmtLengthEx": "",
          "artLineAsmtSiteWarmth": "",
          "artLineAsmtSkin": "",
          "artLineAsmtSystemicSymptoms": "",
          "artLineLumenBloodReturn": "",
          "artLineLumenFlushType": "",
          "artLineLumenFlushVol": "",
          "artLineLumenPatency": "",
          "artLineLumenResistance": "",
          "artLineAsmtDressing": "",
          "artLineAsmtSecurement": "",
          "artLineAsmtComments": ""
        }
      },
      "fqn": "linesDevices.artLineAssessment",
      "tableChildren": [
        "artLineAssessment_id",
        "artLineAssessment_day",
        "artLineAssessment_time",
        "artLineAsmtComplications",
        "artLineAsmtPlacement",
        "artLineAsmtCircumference",
        "artLineAsmtDischarge",
        "artLineAsmtDisVol",
        "artLineAssmtLengthEx",
        "artLineAsmtSiteWarmth",
        "artLineAsmtSkin",
        "artLineAsmtSystemicSymptoms",
        "artLineLumenBloodReturn",
        "artLineLumenFlushType",
        "artLineLumenFlushVol",
        "artLineLumenPatency",
        "artLineLumenResistance",
        "artLineAsmtDressing",
        "artLineAsmtSecurement",
        "artLineAsmtComments",
        "artLineAssessment_name",
        "artLineAssessment_profession"
      ],
      "hasRecHeader": true
    },
    "uriCathProfile": {
      "elementKey": "uriCathProfile",
      "pageElementIndex": "14",
      "pageKey": "linesDevices",
      "tableKey": "uriCathProfile",
      "isTable": true,
      "form": {
        "elementKey": "uriCathProfile",
        "label": "Urinary Catheter",
        "addButtonText": "Add a Urinary Catherter Profile",
        "formKey": "uriCathProfile",
        "ehr_groups": [
          {
            "elementKey": "uriCath_1",
            "formCss": "record-header",
            "fqn": "linesDevices.uriCath_1",
            "gIndex": "1",
            "gChildren": [
              "uriCathProfile_name",
              "uriCathProfile_profession",
              "uriCathProfile_day",
              "uriCathProfile_time"
            ]
          },
          {
            "elementKey": "uriCath_2",
            "formCss": "grid-left-to-right-3",
            "fqn": "linesDevices.uriCath_2",
            "gIndex": "2",
            "gChildren": [
              "uriCathLocation",
              "uriCathType",
              "uriCathInsertion",
              "uriCathSecurement",
              "uriCathGauge",
              "uriStomaSize",
              "uriCathBalloonLumenSize",
              "uriCathLengthIn",
              "uriCathLengthEx"
            ]
          },
          {
            "elementKey": "uriCath_4",
            "formCss": "grid-left-to-right-2",
            "fqn": "linesDevices.uriCath_4",
            "gIndex": "3",
            "gChildren": [
              "uriCathInsertDay",
              "uriCathInsertTime",
              "uriCathRemovalDay",
              "uriCathRemovalTime"
            ]
          },
          {
            "elementKey": "uriCath_5",
            "formCss": "grid-left-to-right-1",
            "fqn": "linesDevices.uriCath_5",
            "gIndex": "4",
            "gChildren": [
              "uriCathComments"
            ]
          }
        ],
        "ehr_data": {
          "uriCathProfile_name": "",
          "uriCathProfile_profession": "",
          "uriCathProfile_day": "",
          "uriCathProfile_time": "",
          "uriCathLocation": "",
          "uriCathType": "",
          "uriCathInsertion": "",
          "uriCathSecurement": "",
          "uriCathGauge": "",
          "uriStomaSize": "",
          "uriCathBalloonLumenSize": "",
          "uriCathLengthIn": "",
          "uriCathLengthEx": "",
          "uriCathInsertDay": "",
          "uriCathInsertTime": "",
          "uriCathRemovalDay": "",
          "uriCathRemovalTime": "",
          "uriCathComments": ""
        }
      },
      "fqn": "linesDevices.uriCathProfile",
      "tableChildren": [
        "uriCathProfile_id",
        "uriCathProfile_day",
        "uriCathProfile_time",
        "uriCathType",
        "uriCathLengthIn",
        "uriCathLengthEx",
        "uriCathInsertDay",
        "uriCathInsertTime",
        "uriCathRemovalDay",
        "uriCathRemovalTime",
        "uriCathComments",
        "uriCathProfile_name",
        "uriCathProfile_profession"
      ],
      "hasRecHeader": true
    },
    "uriCathAssessment": {
      "elementKey": "uriCathAssessment",
      "pageElementIndex": "15",
      "pageKey": "linesDevices",
      "tableKey": "uriCathAssessment",
      "isTable": true,
      "form": {
        "elementKey": "uriCathAssessment",
        "label": "Urinary Catheter Assessment",
        "addButtonText": "Add a Urinary Catherter Assessment",
        "formKey": "uriCathAssessment",
        "ehr_groups": [
          {
            "elementKey": "uriCathAsmt_1",
            "formCss": "record-header",
            "fqn": "linesDevices.uriCathAsmt_1",
            "gIndex": "1",
            "gChildren": [
              "uriCathAssessment_name",
              "uriCathAssessment_profession",
              "uriCathAssessment_day",
              "uriCathAssessment_time"
            ]
          },
          {
            "elementKey": "uriCathAsmt_2",
            "formCss": "grid-left-to-right-3",
            "fqn": "linesDevices.uriCathAsmt_2",
            "gIndex": "2",
            "gChildren": [
              "uriCathAsmtComplications",
              "uriCathAsmtPlacement"
            ]
          },
          {
            "elementKey": "uriCathAsmt_2",
            "formCss": "grid-left-to-right-3",
            "fqn": "linesDevices.uriCathAsmt_2",
            "gIndex": "3",
            "gChildren": [
              "uriCathAsmtDischarge",
              "uriCathAsmtDisVol",
              "uriCathAssmtLengthEx",
              "uriCathAsmtSiteWarmth",
              "uriCathAsmtSkin",
              "uriCathAsmtSystemicSymptoms"
            ]
          },
          {
            "elementKey": "uriCathLumen_3",
            "formCss": "grid-left-to-right-3",
            "fqn": "linesDevices.uriCathLumen_3",
            "gIndex": "4",
            "gChildren": [
              "uriCathLumenBloodReturn",
              "uriCathLumenFlushType",
              "uriCathLumenFlushVol",
              "uriCathLumenPatency",
              "uriCathLumenResistance",
              "uriCathBalloonPatency",
              "uriCathBalloonStatus"
            ]
          },
          {
            "elementKey": "uriCathAsmt_5",
            "formCss": "grid-left-to-right-3",
            "fqn": "linesDevices.uriCathAsmt_5",
            "gIndex": "5",
            "gChildren": [
              "uriCathAsmtDressing",
              "uriCathAsmtSecurement"
            ]
          },
          {
            "elementKey": "uriCathAsmt_6",
            "formCss": "grid-left-to-right-1",
            "fqn": "linesDevices.uriCathAsmt_6",
            "gIndex": "6",
            "gChildren": [
              "uriCathAsmtComments"
            ]
          }
        ],
        "ehr_data": {
          "uriCathAssessment_name": "",
          "uriCathAssessment_profession": "",
          "uriCathAssessment_day": "",
          "uriCathAssessment_time": "",
          "uriCathAsmtComplications": "",
          "uriCathAsmtPlacement": "",
          "uriCathAsmtDischarge": "",
          "uriCathAsmtDisVol": "",
          "uriCathAssmtLengthEx": "",
          "uriCathAsmtSiteWarmth": "",
          "uriCathAsmtSkin": "",
          "uriCathAsmtSystemicSymptoms": "",
          "uriCathLumenBloodReturn": "",
          "uriCathLumenFlushType": "",
          "uriCathLumenFlushVol": "",
          "uriCathLumenPatency": "",
          "uriCathLumenResistance": "",
          "uriCathBalloonPatency": "",
          "uriCathBalloonStatus": "",
          "uriCathAsmtDressing": "",
          "uriCathAsmtSecurement": "",
          "uriCathAsmtComments": ""
        }
      },
      "fqn": "linesDevices.uriCathAssessment",
      "tableChildren": [
        "uriCathAssessment_id",
        "uriCathAssessment_day",
        "uriCathAssessment_time",
        "uriCathAsmtComplications",
        "uriCathAsmtPlacement",
        "uriCathAsmtDischarge",
        "uriCathAsmtDisVol",
        "uriCathAssmtLengthEx",
        "uriCathAsmtSiteWarmth",
        "uriCathAsmtSkin",
        "uriCathAsmtSystemicSymptoms",
        "uriCathLumenBloodReturn",
        "uriCathLumenFlushType",
        "uriCathLumenFlushVol",
        "uriCathLumenPatency",
        "uriCathLumenResistance",
        "uriCathBalloonPatency",
        "uriCathBalloonStatus",
        "uriCathAsmtDressing",
        "uriCathAsmtSecurement",
        "uriCathAsmtComments",
        "uriCathAssessment_name",
        "uriCathAssessment_profession"
      ],
      "hasRecHeader": true
    },
    "airwayProfile": {
      "elementKey": "airwayProfile",
      "pageElementIndex": "16",
      "pageKey": "linesDevices",
      "tableKey": "airwayProfile",
      "isTable": true,
      "form": {
        "elementKey": "airwayProfile",
        "label": "Airway Profile",
        "addButtonText": "Add an Airway Profile",
        "formKey": "airwayProfile",
        "ehr_groups": [
          {
            "elementKey": "airway_1",
            "formCss": "record-header",
            "fqn": "linesDevices.airway_1",
            "gIndex": "1",
            "gChildren": [
              "airwayProfile_name",
              "airwayProfile_profession",
              "airwayProfile_day",
              "airwayProfile_time"
            ]
          },
          {
            "elementKey": "airway_2",
            "formCss": "grid-left-to-right-3",
            "fqn": "linesDevices.airway_2",
            "gIndex": "2",
            "gChildren": [
              "airwayType",
              "airwayInsertion",
              "airwayLocation",
              "airwayGauge",
              "airwaySecurement",
              "airwayLengthIn",
              "airwayLengthEx"
            ]
          },
          {
            "elementKey": "airway_4",
            "formCss": "grid-left-to-right-2",
            "fqn": "linesDevices.airway_4",
            "gIndex": "3",
            "gChildren": [
              "airwayInsertDay",
              "airwayInsertTime",
              "airwayRemovalDay",
              "airwayRemovalTime"
            ]
          },
          {
            "elementKey": "airway_5",
            "formCss": "grid-left-to-right-1",
            "fqn": "linesDevices.airway_5",
            "gIndex": "4",
            "gChildren": [
              "airwayComments"
            ]
          }
        ],
        "ehr_data": {
          "airwayProfile_name": "",
          "airwayProfile_profession": "",
          "airwayProfile_day": "",
          "airwayProfile_time": "",
          "airwayType": "",
          "airwayInsertion": "",
          "airwayLocation": "",
          "airwayGauge": "",
          "airwaySecurement": "",
          "airwayLengthIn": "",
          "airwayLengthEx": "",
          "airwayInsertDay": "",
          "airwayInsertTime": "",
          "airwayRemovalDay": "",
          "airwayRemovalTime": "",
          "airwayComments": ""
        }
      },
      "fqn": "linesDevices.airwayProfile",
      "tableChildren": [
        "airwayProfile_id",
        "airwayProfile_day",
        "airwayProfile_time",
        "airwayType",
        "airwayInsertion",
        "airwayLocation",
        "airwayGauge",
        "airwaySecurement",
        "airwayLengthIn",
        "airwayLengthEx",
        "airwayInsertDay",
        "airwayInsertTime",
        "airwayRemovalDay",
        "airwayRemovalTime",
        "airwayComments",
        "airwayProfile_name",
        "airwayProfile_profession"
      ],
      "hasRecHeader": true
    },
    "airwayAssessment": {
      "elementKey": "airwayAssessment",
      "pageElementIndex": "17",
      "pageKey": "linesDevices",
      "tableKey": "airwayAssessment",
      "isTable": true,
      "form": {
        "elementKey": "airwayAssessment",
        "label": "Airway Assessment",
        "addButtonText": "Add an Airway Assessment",
        "formKey": "airwayAssessment",
        "ehr_groups": [
          {
            "elementKey": "airwayAsmt_1",
            "formCss": "record-header",
            "fqn": "linesDevices.airwayAsmt_1",
            "gIndex": "1",
            "gChildren": [
              "airwayAssessment_name",
              "airwayAssessment_profession",
              "airwayAssessment_day",
              "airwayAssessment_time"
            ]
          },
          {
            "elementKey": "airwayAsmt_2",
            "formCss": "grid-left-to-right-3",
            "fqn": "linesDevices.airwayAsmt_2",
            "gIndex": "2",
            "gChildren": [
              "airwayAsmtComplications",
              "airwayAsmtPlacement",
              "airwayAsmtPatency"
            ]
          },
          {
            "elementKey": "airwayAsmt_5",
            "formCss": "grid-left-to-right-3",
            "fqn": "linesDevices.airwayAsmt_5",
            "gIndex": "3",
            "gChildren": [
              "airwayAsmtDressing",
              "airwayAsmtSecurement"
            ]
          },
          {
            "elementKey": "airwayAsmt_6",
            "formCss": "grid-left-to-right-1",
            "fqn": "linesDevices.airwayAsmt_6",
            "gIndex": "4",
            "gChildren": [
              "airwayAsmtComments"
            ]
          }
        ],
        "ehr_data": {
          "airwayAssessment_name": "",
          "airwayAssessment_profession": "",
          "airwayAssessment_day": "",
          "airwayAssessment_time": "",
          "airwayAsmtComplications": "",
          "airwayAsmtPlacement": "",
          "airwayAsmtPatency": "",
          "airwayAsmtDressing": "",
          "airwayAsmtSecurement": "",
          "airwayAsmtComments": ""
        }
      },
      "fqn": "linesDevices.airwayAssessment",
      "tableChildren": [
        "airwayAssessment_id",
        "airwayAssessment_day",
        "airwayAssessment_time",
        "airwayAsmtComplications",
        "airwayAsmtPlacement",
        "airwayAsmtPatency",
        "airwayAsmtDressing",
        "airwayAsmtSecurement",
        "airwayAsmtComments",
        "airwayAssessment_name",
        "airwayAssessment_profession"
      ],
      "hasRecHeader": true
    }
  },
  "path": "/ehr/current/assessments",
  "routeName": "lines-devices",
  "label": "Lines and Devices",
  "generateComponent": "custom",
  "ehr": "yes",
  "fullPageKey": "current.assessments.linesDevices"
}
export default DEFS