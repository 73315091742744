/* eslint-disable */
const DEFS = {
  "pageDataKey": "pedInOut",
  "pIndex": "78",
  "hasGridTable": true,
  "pageChildren": [
    {
      "elementKey": "pedIOForm_name",
      "formIndex": "1",
      "inputType": "practitionerName",
      "tableColumn": "1",
      "recHeader": true,
      "fqn": "pedInOut.name"
    },
    {
      "elementKey": "pedIOForm_id",
      "inputType": "generatedId",
      "tableColumn": 0,
      "label": "Row id",
      "tableCss": "row-id"
    },
    {
      "elementKey": "pedIOForm_profession",
      "formIndex": "1",
      "inputType": "practitionerProfession",
      "tableColumn": "1",
      "recHeader": true,
      "fqn": "pedInOut.profession"
    },
    {
      "elementKey": "pedIOForm_day",
      "formIndex": "1",
      "inputType": "visitDay",
      "mandatory": true,
      "tableColumn": "1",
      "validation": "visitDay",
      "recHeader": true,
      "fqn": "pedInOut.day"
    },
    {
      "elementKey": "pedIOForm_time",
      "formIndex": "1",
      "inputType": "visitTime",
      "mandatory": true,
      "tableColumn": "1",
      "validation": "time24",
      "recHeader": true,
      "fqn": "pedInOut.time"
    },
    {
      "elementKey": "pedInOutOralAmt",
      "formIndex": "1",
      "inputType": "number",
      "suffix": "mL",
      "tableColumn": "3",
      "fqn": "pedInOut.pedInOutOralAmt",
      "suffixText": "mL"
    },
    {
      "elementKey": "pedInOutOralDesc",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "4",
      "fqn": "pedInOut.pedInOutOralDesc"
    },
    {
      "elementKey": "pedInOutBfastAmt",
      "formIndex": "1",
      "inputType": "radioset",
      "options": [
        {
          "key": "0%",
          "text": "0%"
        },
        {
          "key": "25%",
          "text": "25%"
        },
        {
          "key": "50%",
          "text": "50%"
        },
        {
          "key": "75%",
          "text": "75%"
        },
        {
          "key": "100%",
          "text": "100%"
        },
        {
          "key": "other",
          "text": "Other"
        }
      ],
      "tableColumn": "6",
      "fqn": "pedInOut.pedInOutBfastAmt"
    },
    {
      "elementKey": "pedInOutBfastOther",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "7",
      "fqn": "pedInOut.pedInOutBfastOther"
    },
    {
      "elementKey": "pedInOutLunchAmt",
      "formIndex": "1",
      "inputType": "radioset",
      "options": [
        {
          "key": "0%",
          "text": "0%"
        },
        {
          "key": "25%",
          "text": "25%"
        },
        {
          "key": "50%",
          "text": "50%"
        },
        {
          "key": "75%",
          "text": "75%"
        },
        {
          "key": "100%",
          "text": "100%"
        },
        {
          "key": "other",
          "text": "Other"
        }
      ],
      "tableColumn": "8",
      "fqn": "pedInOut.pedInOutLunchAmt"
    },
    {
      "elementKey": "pedInOutLunchOther",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "9",
      "fqn": "pedInOut.pedInOutLunchOther"
    },
    {
      "elementKey": "pedInOutDinnerAmt",
      "formIndex": "1",
      "inputType": "radioset",
      "options": [
        {
          "key": "0%",
          "text": "0%"
        },
        {
          "key": "25%",
          "text": "25%"
        },
        {
          "key": "50%",
          "text": "50%"
        },
        {
          "key": "75%",
          "text": "75%"
        },
        {
          "key": "100%",
          "text": "100%"
        },
        {
          "key": "other",
          "text": "Other"
        }
      ],
      "tableColumn": "10",
      "fqn": "pedInOut.pedInOutDinnerAmt"
    },
    {
      "elementKey": "pedInOutDinnerOther",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "11",
      "fqn": "pedInOut.pedInOutDinnerOther"
    },
    {
      "elementKey": "pedInOutSnackAmt",
      "formIndex": "1",
      "inputType": "radioset",
      "options": [
        {
          "key": "0%",
          "text": "0%"
        },
        {
          "key": "25%",
          "text": "25%"
        },
        {
          "key": "50%",
          "text": "50%"
        },
        {
          "key": "75%",
          "text": "75%"
        },
        {
          "key": "100%",
          "text": "100%"
        },
        {
          "key": "other",
          "text": "Other"
        }
      ],
      "tableColumn": "12",
      "fqn": "pedInOut.pedInOutSnackAmt"
    },
    {
      "elementKey": "pedInOutSnackOther",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "13",
      "fqn": "pedInOut.pedInOutSnackOther"
    },
    {
      "elementKey": "pedInOutMealRep",
      "formIndex": "1",
      "inputType": "radioset",
      "options": [
        {
          "key": "breakfast",
          "text": "Breakfast"
        },
        {
          "key": "lunch",
          "text": "Lunch"
        },
        {
          "key": "dinner",
          "text": "Dinner"
        },
        {
          "key": "snack",
          "text": "Snack"
        }
      ],
      "tableColumn": "14",
      "fqn": "pedInOut.pedInOutMealRep"
    },
    {
      "elementKey": "pedInOutMealRepType",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "15",
      "fqn": "pedInOut.pedInOutMealRepType"
    },
    {
      "elementKey": "pedInOutMealRepAmt",
      "formIndex": "1",
      "inputType": "number",
      "suffix": "mL",
      "tableColumn": "16",
      "fqn": "pedInOut.pedInOutMealRepAmt",
      "suffixText": "mL"
    },
    {
      "elementKey": "pedInOutMealRepTol",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "17",
      "fqn": "pedInOut.pedInOutMealRepTol"
    },
    {
      "elementKey": "pedInOutORSolution",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "19",
      "fqn": "pedInOut.pedInOutORSolution"
    },
    {
      "elementKey": "pedInOutORSAmt",
      "formIndex": "1",
      "inputType": "number",
      "suffix": "ml",
      "tableColumn": "20",
      "fqn": "pedInOut.pedInOutORSAmt",
      "suffixText": "ml"
    },
    {
      "elementKey": "pedInOutORSTol",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "21",
      "fqn": "pedInOut.pedInOutORSTol"
    },
    {
      "elementKey": "pedIOTubeFeedToDo",
      "formIndex": "1",
      "inputType": "form_label",
      "tableColumn": "23",
      "fqn": "pedInOut.pedIOTubeFeedToDo"
    },
    {
      "elementKey": "PedIOComments",
      "formIndex": "1",
      "inputType": "textarea",
      "tableColumn": "25",
      "fqn": "pedInOut.PedIOComments"
    }
  ],
  "pageElements": {
    "pedIOForm": {
      "elementKey": "pedIOForm",
      "pageElementIndex": "1",
      "pageKey": "pedInOut",
      "tableKey": "pedIOForm",
      "isTable": true,
      "form": {
        "elementKey": "pedIOForm",
        "label": "Intake Assessment Peds",
        "addButtonText": "Add a Intake Assessment Peds assessment",
        "formKey": "pedIOForm",
        "ehr_groups": [
          {
            "elementKey": "pedIOHdrGrp",
            "formCss": "record-header",
            "fqn": "pedInOut.pedIOHdrGrp",
            "gIndex": "1",
            "gChildren": [
              "pedIOForm_name",
              "pedIOForm_profession",
              "pedIOForm_day",
              "pedIOForm_time"
            ]
          },
          {
            "elementKey": "pedIOIntake",
            "formCss": "ehr-row-flow",
            "fqn": "pedInOut.pedIOIntake",
            "gIndex": "2",
            "gChildren": [
              "pedInOutOralAmt",
              "pedInOutOralDesc"
            ]
          },
          {
            "elementKey": "pedIOConsumption",
            "formCss": "ehr-row-flow",
            "fqn": "pedInOut.pedIOConsumption",
            "gIndex": "3",
            "gChildren": [
              "pedInOutBfastAmt",
              "pedInOutBfastOther",
              "pedInOutLunchAmt",
              "pedInOutLunchOther",
              "pedInOutDinnerAmt",
              "pedInOutDinnerOther",
              "pedInOutSnackAmt",
              "pedInOutSnackOther",
              "pedInOutMealRep",
              "pedInOutMealRepType",
              "pedInOutMealRepAmt",
              "pedInOutMealRepTol"
            ]
          },
          {
            "elementKey": "pedIORehydration",
            "formCss": "ehr-row-flow",
            "fqn": "pedInOut.pedIORehydration",
            "gIndex": "4",
            "gChildren": [
              "pedInOutORSolution",
              "pedInOutORSAmt",
              "pedInOutORSTol"
            ]
          },
          {
            "elementKey": "pedIOTubeFeed",
            "formCss": "grid-left-to-right-1",
            "fqn": "pedInOut.pedIOTubeFeed",
            "gIndex": "5",
            "gChildren": [
              "pedIOTubeFeedToDo"
            ]
          },
          {
            "elementKey": "PedIOCommentsGrp",
            "formCss": "grid-left-to-right-1",
            "fqn": "pedInOut.PedIOCommentsGrp",
            "gIndex": "6",
            "gChildren": [
              "PedIOComments"
            ]
          }
        ],
        "ehr_data": {
          "pedIOForm_name": "",
          "pedIOForm_profession": "",
          "pedIOForm_day": "",
          "pedIOForm_time": "",
          "pedInOutOralAmt": "",
          "pedInOutOralDesc": "",
          "pedInOutBfastAmt": "",
          "pedInOutBfastOther": "",
          "pedInOutLunchAmt": "",
          "pedInOutLunchOther": "",
          "pedInOutDinnerAmt": "",
          "pedInOutDinnerOther": "",
          "pedInOutSnackAmt": "",
          "pedInOutSnackOther": "",
          "pedInOutMealRep": "",
          "pedInOutMealRepType": "",
          "pedInOutMealRepAmt": "",
          "pedInOutMealRepTol": "",
          "pedInOutORSolution": "",
          "pedInOutORSAmt": "",
          "pedInOutORSTol": "",
          "PedIOComments": ""
        }
      },
      "fqn": "pedInOut.pedIOForm",
      "tableChildren": [
        "pedIOForm_id",
        "pedIOForm_day",
        "pedIOForm_time",
        "pedInOutOralAmt",
        "pedInOutOralDesc",
        "pedInOutBfastAmt",
        "pedInOutBfastOther",
        "pedInOutLunchAmt",
        "pedInOutLunchOther",
        "pedInOutDinnerAmt",
        "pedInOutDinnerOther",
        "pedInOutSnackAmt",
        "pedInOutSnackOther",
        "pedInOutMealRep",
        "pedInOutMealRepType",
        "pedInOutMealRepAmt",
        "pedInOutMealRepTol",
        "pedInOutORSolution",
        "pedInOutORSAmt",
        "pedInOutORSTol",
        "pedIOTubeFeedToDo",
        "PedIOComments",
        "pedIOForm_name",
        "pedIOForm_profession"
      ],
      "hasRecHeader": true
    }
  },
  "path": "/ehr/peri-ped/pediatrics",
  "routeName": "ped-in-out",
  "label": "Intake Assessment Peds",
  "generateComponent": "v2page",
  "featureFlag": "ffPed",
  "ehr": "yes",
  "fullPageKey": "periPed.pediatrics.pedInOut"
}
export default DEFS