/* eslint-disable */
const DEFS = {
  "pageDataKey": "medLab",
  "pIndex": "105",
  "pageChildren": [],
  "pageElements": {},
  "path": "/ehr",
  "routeName": "med-lab",
  "label": "Med Lab",
  "generateComponent": "custom",
  "lis": "yes",
  "fullPageKey": "medLab"
}
export default DEFS