import StoreHelper from '@/helpers/store-helper'
import store from '@/store'

/**
 *
 * @param event
 * @param data
 */
export function logMetricEvent (event, data = {}) {
  console.log(`[Analytics] ${event}`, data)
  let body
  try {
    let vId = store.getters['visit/visitId']
    body = JSON.stringify({ vId, event, data,  timestamp: Date.now() })
  } catch (err) {
    console.error('Failed to serialize metric event:', err)
    return
  }
  let apiUrl = StoreHelper.apiUrlGet()
  apiUrl += '/metric/event'
  void fetch(apiUrl, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body,
  })
}

