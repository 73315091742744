/* eslint-disable */
const DEFS = {
  "pageDataKey": "newbornFeeding",
  "pIndex": "63",
  "hasGridTable": true,
  "pageChildren": [
    {
      "elementKey": "neoFeed_form_name",
      "formIndex": "1",
      "inputType": "practitionerName",
      "tableColumn": "2",
      "recHeader": true,
      "fqn": "newbornFeeding.name"
    },
    {
      "elementKey": "neoFeed_form_id",
      "inputType": "generatedId",
      "tableColumn": 0,
      "label": "Row id",
      "tableCss": "row-id"
    },
    {
      "elementKey": "neoFeed_form_profession",
      "formIndex": "1",
      "inputType": "practitionerProfession",
      "tableColumn": "2",
      "recHeader": true,
      "fqn": "newbornFeeding.profession"
    },
    {
      "elementKey": "neoFeed_form_day",
      "formIndex": "1",
      "inputType": "visitDay",
      "mandatory": true,
      "tableColumn": "2",
      "validation": "visitDay",
      "recHeader": true,
      "fqn": "newbornFeeding.day"
    },
    {
      "elementKey": "neoFeed_form_time",
      "formIndex": "1",
      "inputType": "visitTime",
      "mandatory": true,
      "tableColumn": "2",
      "validation": "time24",
      "recHeader": true,
      "fqn": "newbornFeeding.time"
    },
    {
      "elementKey": "neoFeedGoals",
      "formIndex": "1",
      "inputType": "radioset",
      "options": [
        {
          "key": "exclusive",
          "text": "Exclusive breast/chest milk"
        },
        {
          "key": "mixed",
          "text": "Mixed feeding"
        },
        {
          "key": "formula",
          "text": "Formula"
        }
      ],
      "tableColumn": "4",
      "fqn": "newbornFeeding.neoFeedGoals"
    },
    {
      "elementKey": "neoFeedCues",
      "formIndex": "1",
      "inputType": "checkset",
      "options": [
        {
          "key": "awake",
          "text": "Awake independently"
        },
        {
          "key": "sucking",
          "text": "Sucking"
        },
        {
          "key": "woken",
          "text": "Woken for feed"
        },
        {
          "key": "rooting",
          "text": "Rooting"
        },
        {
          "key": "sleepy",
          "text": "Sleepy/sleeping"
        },
        {
          "key": "no cues",
          "text": "No feeding cues"
        },
        {
          "key": "stirring",
          "text": "Stirring"
        },
        {
          "key": "licking",
          "text": "Licking"
        },
        {
          "key": "crying",
          "text": "Crying"
        },
        {
          "key": "fussing",
          "text": "Fussing"
        },
        {
          "key": "other",
          "text": "Other"
        }
      ],
      "tableColumn": "6",
      "fqn": "newbornFeeding.neoFeedCues"
    },
    {
      "elementKey": "neoFeedCuesOther",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "7",
      "fqn": "newbornFeeding.neoFeedCuesOther"
    },
    {
      "elementKey": "neoFeedPosition",
      "formIndex": "1",
      "inputType": "checkset",
      "options": [
        {
          "key": "skin",
          "text": "skin to skin"
        },
        {
          "key": "cradle",
          "text": "cradle"
        },
        {
          "key": "cross cradle",
          "text": "cross cradle"
        },
        {
          "key": "football",
          "text": "football"
        },
        {
          "key": "side",
          "text": "side lying"
        },
        {
          "key": "tandem",
          "text": "tandem"
        },
        {
          "key": "other",
          "text": "other"
        }
      ],
      "tableColumn": "9",
      "fqn": "newbornFeeding.neoFeedPosition"
    },
    {
      "elementKey": "neoFeedPositionOther",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "10",
      "fqn": "newbornFeeding.neoFeedPositionOther"
    },
    {
      "elementKey": "neoFeedObserv",
      "formIndex": "1",
      "inputType": "checkset",
      "options": [
        {
          "key": "good",
          "text": "good latch"
        },
        {
          "key": "poor",
          "text": "poor latch"
        },
        {
          "key": "no",
          "text": "no latch"
        },
        {
          "key": "strong",
          "text": "strong suck"
        },
        {
          "key": "moderate",
          "text": "moderate suck"
        },
        {
          "key": "weak",
          "text": "weak suck"
        },
        {
          "key": "audible",
          "text": "audible swallow"
        },
        {
          "key": "licking",
          "text": "licking/muzzling"
        },
        {
          "key": "no swallow",
          "text": "no swallow"
        },
        {
          "key": "nipple",
          "text": "nipple shield"
        },
        {
          "key": "other",
          "text": "other"
        }
      ],
      "tableColumn": "11",
      "fqn": "newbornFeeding.neoFeedObserv"
    },
    {
      "elementKey": "neoFeedObservOther",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "12",
      "fqn": "newbornFeeding.neoFeedObservOther"
    },
    {
      "elementKey": "neoFeedBreast",
      "formIndex": "1",
      "inputType": "checkset",
      "options": [
        {
          "key": "byNurse",
          "text": "observed by nurse"
        },
        {
          "key": "byParents",
          "text": "observed by parents"
        }
      ],
      "tableColumn": "13",
      "fqn": "newbornFeeding.neoFeedBreast"
    },
    {
      "elementKey": "neoFeedBreastDuration",
      "formIndex": "1",
      "inputType": "number",
      "suffix": "min",
      "tableColumn": "14",
      "fqn": "newbornFeeding.neoFeedBreastDuration",
      "suffixText": "min"
    },
    {
      "elementKey": "neoFeedBottle",
      "formIndex": "1",
      "inputType": "checkset",
      "options": [
        {
          "key": "strong",
          "text": "strong suck"
        },
        {
          "key": "weak",
          "text": "weak suck"
        },
        {
          "key": "moderate",
          "text": "moderate suck"
        },
        {
          "key": "uncoordinated",
          "text": "uncoordinated suck"
        },
        {
          "key": "selfPacing",
          "text": "self pacing"
        },
        {
          "key": "external",
          "text": "needed external pacing"
        },
        {
          "key": "pooling",
          "text": "pooling/spilling"
        },
        {
          "key": "other",
          "text": "other"
        }
      ],
      "tableColumn": "16",
      "fqn": "newbornFeeding.neoFeedBottle"
    },
    {
      "elementKey": "neoFeedBottleOther",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "17",
      "fqn": "newbornFeeding.neoFeedBottleOther"
    },
    {
      "elementKey": "neoFeedBottleVol",
      "formIndex": "1",
      "inputType": "number",
      "suffix": "ml",
      "tableColumn": "18",
      "fqn": "newbornFeeding.neoFeedBottleVol",
      "suffixText": "ml"
    },
    {
      "elementKey": "neoFeedBottleDur",
      "formIndex": "1",
      "inputType": "number",
      "suffix": "min",
      "tableColumn": "19",
      "fqn": "newbornFeeding.neoFeedBottleDur",
      "suffixText": "min"
    },
    {
      "elementKey": "neoFeedFormula",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "20",
      "fqn": "newbornFeeding.neoFeedFormula"
    },
    {
      "elementKey": "neoFeedInstructions",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "21",
      "fqn": "newbornFeeding.neoFeedInstructions"
    },
    {
      "elementKey": "neoFeedProvidedBy",
      "formIndex": "1",
      "inputType": "radioset",
      "options": [
        {
          "key": "nurse",
          "text": "nurse"
        },
        {
          "key": "parent",
          "text": "parent"
        },
        {
          "key": "caregiver",
          "text": "caregiver"
        },
        {
          "key": "other",
          "text": "other"
        }
      ],
      "tableColumn": "23",
      "fqn": "newbornFeeding.neoFeedProvidedBy"
    },
    {
      "elementKey": "neoFeedSupport",
      "formIndex": "1",
      "inputType": "radioset",
      "options": [
        {
          "key": "none",
          "text": "none"
        },
        {
          "key": "minimal",
          "text": "minimal assistance"
        },
        {
          "key": "full",
          "text": "full assistance"
        },
        {
          "key": "other",
          "text": "other"
        }
      ],
      "tableColumn": "24",
      "fqn": "newbornFeeding.neoFeedSupport"
    },
    {
      "elementKey": "neoFeedTolerance",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "25",
      "fqn": "newbornFeeding.neoFeedTolerance"
    },
    {
      "elementKey": "neoFeedCarePlan",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "26",
      "fqn": "newbornFeeding.neoFeedCarePlan"
    },
    {
      "elementKey": "neoFeedComments",
      "formIndex": "1",
      "inputType": "textarea",
      "tableColumn": "28",
      "fqn": "newbornFeeding.neoFeedComments"
    }
  ],
  "pageElements": {
    "neoFeed_form": {
      "elementKey": "neoFeed_form",
      "pageElementIndex": "1",
      "pageKey": "newbornFeeding",
      "tableKey": "neoFeed_form",
      "isTable": true,
      "form": {
        "elementKey": "neoFeed_form",
        "label": "Feeding Assessment",
        "addButtonText": "Add Feeding Assessment",
        "formKey": "neoFeed_form",
        "ehr_groups": [
          {
            "elementKey": "neoFeed_hdrGroup",
            "formCss": "record-header",
            "fqn": "newbornFeeding.neoFeed_hdrGroup",
            "gIndex": "1",
            "gChildren": [
              "neoFeed_form_name",
              "neoFeed_form_profession",
              "neoFeed_form_day",
              "neoFeed_form_time"
            ]
          },
          {
            "elementKey": "neoFeed_1",
            "formCss": "ehr-row-flow",
            "fqn": "newbornFeeding.neoFeed_1",
            "gIndex": "2",
            "gChildren": [
              "neoFeedGoals"
            ]
          },
          {
            "elementKey": "neoFeed_2",
            "formCss": "ehr-row-flow",
            "fqn": "newbornFeeding.neoFeed_2",
            "gIndex": "3",
            "gChildren": [
              "neoFeedCues",
              "neoFeedCuesOther"
            ]
          },
          {
            "elementKey": "neoFeed_4",
            "formCss": "ehr-row-flow",
            "fqn": "newbornFeeding.neoFeed_4",
            "gIndex": "4",
            "gChildren": [
              "neoFeedPosition",
              "neoFeedPositionOther",
              "neoFeedObserv",
              "neoFeedObservOther",
              "neoFeedBreast",
              "neoFeedBreastDuration"
            ]
          },
          {
            "elementKey": "neoFeed_5",
            "formCss": "ehr-row-flow",
            "fqn": "newbornFeeding.neoFeed_5",
            "gIndex": "5",
            "gChildren": [
              "neoFeedBottle",
              "neoFeedBottleOther",
              "neoFeedBottleVol",
              "neoFeedBottleDur",
              "neoFeedFormula",
              "neoFeedInstructions"
            ]
          },
          {
            "elementKey": "neoFeed_6",
            "formCss": "ehr-row-flow",
            "fqn": "newbornFeeding.neoFeed_6",
            "gIndex": "6",
            "gChildren": [
              "neoFeedProvidedBy",
              "neoFeedSupport",
              "neoFeedTolerance",
              "neoFeedCarePlan"
            ]
          },
          {
            "elementKey": "neoFeed_7",
            "formCss": "grid-left-to-right-1",
            "fqn": "newbornFeeding.neoFeed_7",
            "gIndex": "7",
            "gChildren": [
              "neoFeedComments"
            ]
          }
        ],
        "ehr_data": {
          "neoFeed_form_name": "",
          "neoFeed_form_profession": "",
          "neoFeed_form_day": "",
          "neoFeed_form_time": "",
          "neoFeedGoals": "",
          "neoFeedCues": "",
          "neoFeedCuesOther": "",
          "neoFeedPosition": "",
          "neoFeedPositionOther": "",
          "neoFeedObserv": "",
          "neoFeedObservOther": "",
          "neoFeedBreast": "",
          "neoFeedBreastDuration": "",
          "neoFeedBottle": "",
          "neoFeedBottleOther": "",
          "neoFeedBottleVol": "",
          "neoFeedBottleDur": "",
          "neoFeedFormula": "",
          "neoFeedInstructions": "",
          "neoFeedProvidedBy": "",
          "neoFeedSupport": "",
          "neoFeedTolerance": "",
          "neoFeedCarePlan": "",
          "neoFeedComments": ""
        }
      },
      "fqn": "newbornFeeding.neoFeed_form",
      "tableChildren": [
        "neoFeed_form_id",
        "neoFeed_form_day",
        "neoFeed_form_time",
        "neoFeedGoals",
        "neoFeedCues",
        "neoFeedCuesOther",
        "neoFeedPosition",
        "neoFeedPositionOther",
        "neoFeedObserv",
        "neoFeedObservOther",
        "neoFeedBreast",
        "neoFeedBreastDuration",
        "neoFeedBottle",
        "neoFeedBottleOther",
        "neoFeedBottleVol",
        "neoFeedBottleDur",
        "neoFeedFormula",
        "neoFeedInstructions",
        "neoFeedProvidedBy",
        "neoFeedSupport",
        "neoFeedTolerance",
        "neoFeedCarePlan",
        "neoFeedComments",
        "neoFeed_form_name",
        "neoFeed_form_profession"
      ],
      "hasRecHeader": true
    }
  },
  "path": "/ehr/peri-ped/newborn",
  "routeName": "newborn-feeding",
  "label": "Feeding Assessment",
  "generateComponent": "v2page",
  "featureFlag": "ffPed",
  "ehr": "yes",
  "fullPageKey": "periPed.newborn.newbornFeeding"
}
export default DEFS