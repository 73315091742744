/* eslint-disable */
const DEFS = {
  "pageDataKey": "matAntenatal",
  "pIndex": "85",
  "hasGridForm": true,
  "pageChildren": [
    {
      "elementKey": "matAntenatalForm_name",
      "formIndex": "1",
      "inputType": "practitionerName",
      "tableColumn": "2",
      "recHeader": true,
      "fqn": "matAntenatal.name"
    },
    {
      "elementKey": "matAntenatalForm_profession",
      "formIndex": "1",
      "inputType": "practitionerProfession",
      "tableColumn": "2",
      "recHeader": true,
      "fqn": "matAntenatal.profession"
    },
    {
      "elementKey": "matAntenatalForm_day",
      "formIndex": "1",
      "inputType": "visitDay",
      "mandatory": true,
      "tableColumn": "2",
      "validation": "visitDay",
      "recHeader": true,
      "fqn": "matAntenatal.day"
    },
    {
      "elementKey": "matAntenatalForm_time",
      "formIndex": "1",
      "inputType": "visitTime",
      "mandatory": true,
      "tableColumn": "2",
      "validation": "time24",
      "recHeader": true,
      "fqn": "matAntenatal.time"
    },
    {
      "elementKey": "matAntenatalGravida",
      "formIndex": "1",
      "inputType": "number",
      "tableColumn": "4",
      "fqn": "matAntenatal.matAntenatalGravida"
    },
    {
      "elementKey": "matAntenatalPara",
      "formIndex": "1",
      "inputType": "number",
      "tableColumn": "5",
      "fqn": "matAntenatal.matAntenatalPara"
    },
    {
      "elementKey": "matAntenatalTerm",
      "formIndex": "1",
      "inputType": "number",
      "tableColumn": "6",
      "fqn": "matAntenatal.matAntenatalTerm"
    },
    {
      "elementKey": "matAntenatalPreterm",
      "formIndex": "1",
      "inputType": "number",
      "tableColumn": "7",
      "fqn": "matAntenatal.matAntenatalPreterm"
    },
    {
      "elementKey": "matAntenatalAbortus",
      "formIndex": "1",
      "inputType": "number",
      "tableColumn": "8",
      "fqn": "matAntenatal.matAntenatalAbortus"
    },
    {
      "elementKey": "matAntenatalLiving",
      "formIndex": "1",
      "inputType": "number",
      "tableColumn": "9",
      "fqn": "matAntenatal.matAntenatalLiving"
    },
    {
      "elementKey": "matAntenatalEddDerivedBy",
      "formIndex": "1",
      "inputType": "radioset",
      "options": [
        {
          "key": "US",
          "text": "US"
        },
        {
          "key": "IVF",
          "text": "IVF"
        },
        {
          "key": "LMP",
          "text": "LMP"
        }
      ],
      "tableColumn": "10",
      "fqn": "matAntenatal.matAntenatalEddDerivedBy"
    },
    {
      "elementKey": "matAntenatalLmpDate",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "11",
      "fqn": "matAntenatal.matAntenatalLmpDate"
    },
    {
      "elementKey": "matAntenatalEdd",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "12",
      "fqn": "matAntenatal.matAntenatalEdd"
    },
    {
      "elementKey": "matAntenatalGaWeeks",
      "formIndex": "1",
      "inputType": "number",
      "tableColumn": "13",
      "fqn": "matAntenatal.matAntenatalGaWeeks"
    },
    {
      "elementKey": "matAntenatalGaDays",
      "formIndex": "1",
      "inputType": "number",
      "tableColumn": "14",
      "fqn": "matAntenatal.matAntenatalGaDays"
    },
    {
      "elementKey": "matAntenatalGestAge",
      "formIndex": "1",
      "inputType": "number",
      "tableColumn": "15",
      "fqn": "matAntenatal.matAntenatalGestAge"
    },
    {
      "elementKey": "matAntenatalGestAgeCmt",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "16",
      "fqn": "matAntenatal.matAntenatalGestAgeCmt"
    },
    {
      "elementKey": "matAntenatalFirstUsDate",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "17",
      "fqn": "matAntenatal.matAntenatalFirstUsDate"
    },
    {
      "elementKey": "matAntenatalFirstUsResults",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "18",
      "fqn": "matAntenatal.matAntenatalFirstUsResults"
    },
    {
      "elementKey": "matAntenatalCurrWeight",
      "formIndex": "1",
      "inputType": "number",
      "tableColumn": "19",
      "fqn": "matAntenatal.matAntenatalCurrWeight"
    },
    {
      "elementKey": "matAntenatalHeight",
      "formIndex": "1",
      "inputType": "number",
      "tableColumn": "20",
      "fqn": "matAntenatal.matAntenatalHeight"
    },
    {
      "elementKey": "matAntenatalPrePregWeight",
      "formIndex": "1",
      "inputType": "number",
      "tableColumn": "21",
      "fqn": "matAntenatal.matAntenatalPrePregWeight"
    },
    {
      "elementKey": "matAntenatalPrePregBmi",
      "formIndex": "1",
      "inputType": "number",
      "tableColumn": "22",
      "fqn": "matAntenatal.matAntenatalPrePregBmi"
    },
    {
      "elementKey": "matAntenatalWeightChange",
      "formIndex": "1",
      "inputType": "number",
      "tableColumn": "23",
      "fqn": "matAntenatal.matAntenatalWeightChange"
    },
    {
      "elementKey": "matAntenatalBloodType",
      "formIndex": "1",
      "inputType": "radioset",
      "options": [
        {
          "key": "O",
          "text": "O"
        },
        {
          "key": "A",
          "text": "A"
        },
        {
          "key": "B",
          "text": "B"
        },
        {
          "key": "AB",
          "text": "AB"
        },
        {
          "key": "unknown",
          "text": "Unknown"
        }
      ],
      "tableColumn": "25",
      "fqn": "matAntenatal.matAntenatalBloodType"
    },
    {
      "elementKey": "matAntenatalRhFactor",
      "formIndex": "1",
      "inputType": "radioset",
      "options": [
        {
          "key": "positive",
          "text": "Positive"
        },
        {
          "key": "negative",
          "text": "Negative"
        }
      ],
      "tableColumn": "26",
      "fqn": "matAntenatal.matAntenatalRhFactor"
    },
    {
      "elementKey": "matAntenatalRhIgGiven",
      "formIndex": "1",
      "inputType": "radioset",
      "options": [
        {
          "key": "yes",
          "text": "Yes"
        },
        {
          "key": "no",
          "text": "No"
        },
        {
          "key": "comment",
          "text": "Comment"
        }
      ],
      "tableColumn": "27",
      "fqn": "matAntenatal.matAntenatalRhIgGiven"
    },
    {
      "elementKey": "matAntenatalRhIgGivenCmt",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "28",
      "fqn": "matAntenatal.matAntenatalRhIgGivenCmt"
    },
    {
      "elementKey": "matAntenatalRubellaStatus",
      "formIndex": "1",
      "inputType": "radioset",
      "options": [
        {
          "key": "immune",
          "text": "Immune"
        },
        {
          "key": "nonImmune",
          "text": "Non-immune"
        },
        {
          "key": "unknown",
          "text": "Unknown"
        }
      ],
      "tableColumn": "29",
      "fqn": "matAntenatal.matAntenatalRubellaStatus"
    },
    {
      "elementKey": "matAntenatalCurrMeds",
      "formIndex": "1",
      "inputType": "radioset",
      "options": [
        {
          "key": "none",
          "text": "None"
        },
        {
          "key": "vitaminsMedications",
          "text": "Vitamins/medications"
        }
      ],
      "tableColumn": "30",
      "fqn": "matAntenatal.matAntenatalCurrMeds"
    },
    {
      "elementKey": "matAntenatalCompTherapy",
      "formIndex": "1",
      "inputType": "radioset",
      "options": [
        {
          "key": "yes",
          "text": "Yes"
        },
        {
          "key": "no",
          "text": "No"
        },
        {
          "key": "comment",
          "text": "Comment"
        }
      ],
      "tableColumn": "31",
      "fqn": "matAntenatal.matAntenatalCompTherapy"
    },
    {
      "elementKey": "matAntenatalCompTherapyCmt",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "32",
      "fqn": "matAntenatal.matAntenatalCompTherapyCmt"
    },
    {
      "elementKey": "matAntenatalPrevAdmPreg",
      "formIndex": "1",
      "inputType": "radioset",
      "options": [
        {
          "key": "yes",
          "text": "Yes"
        },
        {
          "key": "no",
          "text": "No"
        },
        {
          "key": "comment",
          "text": "Comment"
        }
      ],
      "tableColumn": "33",
      "fqn": "matAntenatal.matAntenatalPrevAdmPreg"
    },
    {
      "elementKey": "matAntenatalPrevAdmPregCmt",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "34",
      "fqn": "matAntenatal.matAntenatalPrevAdmPregCmt"
    },
    {
      "elementKey": "matAntenatalAntenatalSteroid",
      "formIndex": "1",
      "inputType": "radioset",
      "options": [
        {
          "key": "yes",
          "text": "Yes"
        },
        {
          "key": "no",
          "text": "No"
        },
        {
          "key": "na",
          "text": "Not applicable"
        }
      ],
      "tableColumn": "35",
      "fqn": "matAntenatal.matAntenatalAntenatalSteroid"
    },
    {
      "elementKey": "matAntenatalAdminDateDose1",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "36",
      "fqn": "matAntenatal.matAntenatalAdminDateDose1"
    },
    {
      "elementKey": "matAntenatalAdminDateDose2",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "37",
      "fqn": "matAntenatal.matAntenatalAdminDateDose2"
    },
    {
      "elementKey": "matAntenatalEcvAttempted",
      "formIndex": "1",
      "inputType": "radioset",
      "options": [
        {
          "key": "yes",
          "text": "Yes"
        },
        {
          "key": "no",
          "text": "No"
        },
        {
          "key": "na",
          "text": "Not applicable"
        }
      ],
      "tableColumn": "38",
      "fqn": "matAntenatal.matAntenatalEcvAttempted"
    },
    {
      "elementKey": "matAntenatalEcvDate",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "39",
      "fqn": "matAntenatal.matAntenatalEcvDate"
    },
    {
      "elementKey": "matAntenatalPlannedDelMode",
      "formIndex": "1",
      "inputType": "radioset",
      "options": [
        {
          "key": "vaginal",
          "text": "Vaginal"
        },
        {
          "key": "PrimaryC-section",
          "text": "Primary c-section"
        },
        {
          "key": "repeatC-section",
          "text": "repeatC-section"
        }
      ],
      "tableColumn": "40",
      "fqn": "matAntenatal.matAntenatalPlannedDelMode"
    },
    {
      "elementKey": "matAntenatalVbacElig",
      "formIndex": "1",
      "inputType": "radioset",
      "options": [
        {
          "key": "yes",
          "text": "Yes"
        },
        {
          "key": "no",
          "text": "No"
        },
        {
          "key": "na",
          "text": "Not applicable"
        }
      ],
      "tableColumn": "41",
      "fqn": "matAntenatal.matAntenatalVbacElig"
    },
    {
      "elementKey": "matAntenatalVbacNotEligCmt",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "42",
      "fqn": "matAntenatal.matAntenatalVbacNotEligCmt"
    },
    {
      "elementKey": "matAntenatalGbsResults",
      "formIndex": "1",
      "inputType": "radioset",
      "options": [
        {
          "key": "negative",
          "text": "Negative"
        },
        {
          "key": "positive",
          "text": "Positive"
        },
        {
          "key": "unknown",
          "text": "Unknown"
        }
      ],
      "tableColumn": "43",
      "fqn": "matAntenatal.matAntenatalGbsResults"
    },
    {
      "elementKey": "matAntenatalGbsSwabTaken",
      "formIndex": "1",
      "inputType": "radioset",
      "options": [
        {
          "key": "yes",
          "text": "Yes"
        },
        {
          "key": "no",
          "text": "No"
        },
        {
          "key": "na",
          "text": "Not applicable"
        }
      ],
      "tableColumn": "44",
      "fqn": "matAntenatal.matAntenatalGbsSwabTaken"
    },
    {
      "elementKey": "matAntenatalGbsSwabDate",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "45",
      "fqn": "matAntenatal.matAntenatalGbsSwabDate"
    },
    {
      "elementKey": "matAntenatalPphRiskAssess",
      "formIndex": "1",
      "inputType": "radioset",
      "options": [
        {
          "key": "low",
          "text": "Low"
        },
        {
          "key": "medium",
          "text": "Medium"
        },
        {
          "key": "high",
          "text": "High"
        }
      ],
      "tableColumn": "46",
      "fqn": "matAntenatal.matAntenatalPphRiskAssess"
    },
    {
      "elementKey": "matAntenatalAntenatalRecPart1",
      "formIndex": "1",
      "inputType": "radioset",
      "options": [
        {
          "key": "reviewed",
          "text": "Reviewed"
        },
        {
          "key": "notAvailable",
          "text": "Not available"
        }
      ],
      "tableColumn": "48",
      "fqn": "matAntenatal.matAntenatalAntenatalRecPart1"
    },
    {
      "elementKey": "matAntenatalAntenatalRecPart2",
      "formIndex": "1",
      "inputType": "radioset",
      "options": [
        {
          "key": "reviewed",
          "text": "Reviewed"
        },
        {
          "key": "notAvailable",
          "text": "Not available"
        }
      ],
      "tableColumn": "49",
      "fqn": "matAntenatal.matAntenatalAntenatalRecPart2"
    },
    {
      "elementKey": "matAntenatalPregConcerns",
      "formIndex": "1",
      "inputType": "radioset",
      "options": [
        {
          "key": "yes",
          "text": "Yes"
        },
        {
          "key": "no",
          "text": "No"
        }
      ],
      "tableColumn": "50",
      "fqn": "matAntenatal.matAntenatalPregConcerns"
    },
    {
      "elementKey": "matAntenatalPregConcernsCmt",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "51",
      "fqn": "matAntenatal.matAntenatalPregConcernsCmt"
    },
    {
      "elementKey": "matAntenatalPastObsConcerns",
      "formIndex": "1",
      "inputType": "radioset",
      "options": [
        {
          "key": "yes",
          "text": "Yes"
        },
        {
          "key": "no",
          "text": "No"
        }
      ],
      "tableColumn": "52",
      "fqn": "matAntenatal.matAntenatalPastObsConcerns"
    },
    {
      "elementKey": "matAntenatalPastObsConcernsCmt",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "53",
      "fqn": "matAntenatal.matAntenatalPastObsConcernsCmt"
    },
    {
      "elementKey": "matAntenatalMedSurgAnesConcerns",
      "formIndex": "1",
      "inputType": "radioset",
      "options": [
        {
          "key": "yes",
          "text": "Yes"
        },
        {
          "key": "no",
          "text": "No"
        }
      ],
      "tableColumn": "54",
      "fqn": "matAntenatal.matAntenatalMedSurgAnesConcerns"
    },
    {
      "elementKey": "matAntenatalMedSurgAnesConcernsCmt",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "55",
      "fqn": "matAntenatal.matAntenatalMedSurgAnesConcernsCmt"
    },
    {
      "elementKey": "matAntenatalPsychConcerns",
      "formIndex": "1",
      "inputType": "checkset",
      "options": [
        {
          "key": "none",
          "text": "None"
        },
        {
          "key": "lifestyleSocial",
          "text": "Lifestyle/social"
        },
        {
          "key": "substanceUse",
          "text": "Substance use"
        },
        {
          "key": "mentalHealth",
          "text": "Mental health"
        },
        {
          "key": "other",
          "text": "Other"
        }
      ],
      "tableColumn": "56",
      "fqn": "matAntenatal.matAntenatalPsychConcerns"
    },
    {
      "elementKey": "matAntenatalPsychConcernsCmt",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "57",
      "fqn": "matAntenatal.matAntenatalPsychConcernsCmt"
    },
    {
      "elementKey": "matAntenatalSeamlessReferral",
      "formIndex": "1",
      "inputType": "radioset",
      "options": [
        {
          "key": "yes",
          "text": "Yes"
        },
        {
          "key": "no",
          "text": "No"
        },
        {
          "key": "comment",
          "text": "Comment"
        }
      ],
      "tableColumn": "58",
      "fqn": "matAntenatal.matAntenatalSeamlessReferral"
    },
    {
      "elementKey": "matAntenatalSeamlessReferralCmt",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "59",
      "fqn": "matAntenatal.matAntenatalSeamlessReferralCmt"
    },
    {
      "elementKey": "matAntenatalNicHist",
      "formIndex": "1",
      "inputType": "radioset",
      "options": [
        {
          "key": "none",
          "text": "None"
        },
        {
          "key": "currentUse",
          "text": "Current use"
        },
        {
          "key": "quitLessThan30Days",
          "text": "Quit less than 30 days ago"
        },
        {
          "key": "quitGreaterThan30Days",
          "text": "Quit greater than 30 days ago"
        },
        {
          "key": "notAsked",
          "text": "Not asked"
        },
        {
          "key": "unableToCollect",
          "text": "Unable to collect"
        }
      ],
      "tableColumn": "61",
      "fqn": "matAntenatal.matAntenatalNicHist"
    },
    {
      "elementKey": "matAntenatalNicHistCmt",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "62",
      "fqn": "matAntenatal.matAntenatalNicHistCmt"
    },
    {
      "elementKey": "matAntenatalNicType",
      "formIndex": "1",
      "inputType": "checkset",
      "options": [
        {
          "key": "cigarettes",
          "text": "Cigarettes"
        },
        {
          "key": "cigarsCigarellosPipe",
          "text": "Cigars/cigarellos/pipe"
        },
        {
          "key": "smokeless",
          "text": "Smokeless"
        },
        {
          "key": "e-cigarettes",
          "text": "E-cigarettes"
        },
        {
          "key": "hookahBidisKreteks",
          "text": "Hookah/bidis/kreteks"
        },
        {
          "key": "other",
          "text": "Other"
        }
      ],
      "tableColumn": "63",
      "fqn": "matAntenatal.matAntenatalNicType"
    },
    {
      "elementKey": "matAntenatalNicTypeOth",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "64",
      "fqn": "matAntenatal.matAntenatalNicTypeOth"
    },
    {
      "elementKey": "matAntenatalCigsPerDay",
      "formIndex": "1",
      "inputType": "number",
      "suffix": "cigarettes/day",
      "tableColumn": "65",
      "fqn": "matAntenatal.matAntenatalCigsPerDay",
      "suffixText": "cigarettes/day"
    },
    {
      "elementKey": "matAntenatalYearsSmoking",
      "formIndex": "1",
      "inputType": "number",
      "suffix": "yrs",
      "tableColumn": "66",
      "fqn": "matAntenatal.matAntenatalYearsSmoking",
      "suffixText": "yrs"
    },
    {
      "elementKey": "matAntenatalPackYearsAmt",
      "formIndex": "1",
      "inputType": "number",
      "tableColumn": "67",
      "fqn": "matAntenatal.matAntenatalPackYearsAmt"
    },
    {
      "elementKey": "matAntenatalVapeMlPerDay",
      "formIndex": "1",
      "inputType": "number",
      "suffix": "mL",
      "tableColumn": "68",
      "fqn": "matAntenatal.matAntenatalVapeMlPerDay",
      "suffixText": "mL"
    },
    {
      "elementKey": "matAntenatalVapeNicStrength",
      "formIndex": "1",
      "inputType": "radioset",
      "options": [
        {
          "key": "3mg",
          "text": "3mg"
        },
        {
          "key": "6mg",
          "text": "6mg"
        },
        {
          "key": "12mg",
          "text": "12mg"
        },
        {
          "key": "18mg",
          "text": "18mg"
        },
        {
          "key": "20mg",
          "text": "20mg"
        },
        {
          "key": "other",
          "text": "Other"
        }
      ],
      "tableColumn": "69",
      "fqn": "matAntenatal.matAntenatalVapeNicStrength"
    },
    {
      "elementKey": "matAntenatalVapeNicStrength2",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "70",
      "fqn": "matAntenatal.matAntenatalVapeNicStrength2"
    },
    {
      "elementKey": "matAntenatalNicStopDate",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "71",
      "fqn": "matAntenatal.matAntenatalNicStopDate"
    },
    {
      "elementKey": "matAntenatalNicCessInterv",
      "formIndex": "1",
      "inputType": "checkset",
      "options": [
        {
          "key": "na",
          "text": "Not applicable"
        },
        {
          "key": "NRTOfferedRefused",
          "text": "NRT offered/refused"
        },
        {
          "key": "NRTInitiated",
          "text": "NRT initiated"
        },
        {
          "key": "onRxCessationMed",
          "text": "On Rx cessation med"
        },
        {
          "key": "advisedToQuit",
          "text": "Advised to quit"
        },
        {
          "key": "cessationInfoProvided",
          "text": "Cessation info provided"
        },
        {
          "key": "other",
          "text": "Other"
        }
      ],
      "tableColumn": "73",
      "fqn": "matAntenatal.matAntenatalNicCessInterv"
    },
    {
      "elementKey": "matAntenatalNicCessIntervOth",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "74",
      "fqn": "matAntenatal.matAntenatalNicCessIntervOth"
    },
    {
      "elementKey": "matAntenatalWillingQuit",
      "formIndex": "1",
      "inputType": "radioset",
      "options": [
        {
          "key": "quitWithThisAdmission",
          "text": "Quit with this admission"
        },
        {
          "key": "quitInNextMonth",
          "text": "Quit in next month"
        },
        {
          "key": "quitInNext6Months",
          "text": "Quit in next 6 months"
        },
        {
          "key": "noPlan",
          "text": "No plan in next 6 months"
        }
      ],
      "tableColumn": "75",
      "fqn": "matAntenatal.matAntenatalWillingQuit"
    },
    {
      "elementKey": "matAntenatalNoSmokeFH",
      "formIndex": "1",
      "inputType": "radioset",
      "options": [
        {
          "key": "yes",
          "text": "Yes"
        },
        {
          "key": "no",
          "text": "No"
        },
        {
          "key": "comment",
          "text": "Comment"
        }
      ],
      "tableColumn": "76",
      "fqn": "matAntenatal.matAntenatalNoSmokeFH"
    },
    {
      "elementKey": "matAntenatalNoSmokeFHCmt",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "77",
      "fqn": "matAntenatal.matAntenatalNoSmokeFHCmt"
    },
    {
      "elementKey": "matAntenatalAlcSubHist",
      "formIndex": "1",
      "inputType": "checkset",
      "options": [
        {
          "key": "none",
          "text": "None"
        },
        {
          "key": "alcohol",
          "text": "Alcohol"
        },
        {
          "key": "cannabis",
          "text": "Cannabis"
        },
        {
          "key": "otherSubstances",
          "text": "Other substances"
        },
        {
          "key": "prescriptionMedication",
          "text": "Prescription medication"
        },
        {
          "key": "other",
          "text": "Other"
        }
      ],
      "tableColumn": "79",
      "fqn": "matAntenatal.matAntenatalAlcSubHist"
    },
    {
      "elementKey": "matAntenatalAlcSubHist2",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "80",
      "fqn": "matAntenatal.matAntenatalAlcSubHist2"
    },
    {
      "elementKey": "matAntenatalComments",
      "formIndex": "1",
      "inputType": "textarea",
      "tableColumn": "82",
      "fqn": "matAntenatal.matAntenatalComments"
    }
  ],
  "pageElements": {
    "matAntenatalForm": {
      "elementKey": "matAntenatalForm",
      "pageElementIndex": "1",
      "formKey": "matAntenatalForm",
      "fqn": "matAntenatal.matAntenatalForm",
      "isPageForm": true,
      "ehr_groups": [
        {
          "elementKey": "matAntenatalHdrGrp",
          "formCss": "record-header",
          "fqn": "matAntenatal.matAntenatalHdrGrp",
          "gIndex": "1",
          "gChildren": [
            "matAntenatalForm_name",
            "matAntenatalForm_profession",
            "matAntenatalForm_day",
            "matAntenatalForm_time"
          ]
        },
        {
          "elementKey": "matAntenatalSnglMultBrths",
          "formCss": "ehr-row-flow",
          "fqn": "matAntenatal.matAntenatalSnglMultBrths",
          "gIndex": "2",
          "gChildren": [
            "matAntenatalGravida",
            "matAntenatalPara",
            "matAntenatalTerm",
            "matAntenatalPreterm",
            "matAntenatalAbortus",
            "matAntenatalLiving",
            "matAntenatalEddDerivedBy",
            "matAntenatalLmpDate",
            "matAntenatalEdd",
            "matAntenatalGaWeeks",
            "matAntenatalGaDays",
            "matAntenatalGestAge",
            "matAntenatalGestAgeCmt",
            "matAntenatalFirstUsDate",
            "matAntenatalFirstUsResults",
            "matAntenatalCurrWeight",
            "matAntenatalHeight",
            "matAntenatalPrePregWeight",
            "matAntenatalPrePregBmi",
            "matAntenatalWeightChange"
          ]
        },
        {
          "elementKey": "matAntenatalHistRiskFactors",
          "formCss": "ehr-row-flow",
          "fqn": "matAntenatal.matAntenatalHistRiskFactors",
          "gIndex": "3",
          "gChildren": [
            "matAntenatalBloodType",
            "matAntenatalRhFactor",
            "matAntenatalRhIgGiven",
            "matAntenatalRhIgGivenCmt",
            "matAntenatalRubellaStatus",
            "matAntenatalCurrMeds",
            "matAntenatalCompTherapy",
            "matAntenatalCompTherapyCmt",
            "matAntenatalPrevAdmPreg",
            "matAntenatalPrevAdmPregCmt",
            "matAntenatalAntenatalSteroid",
            "matAntenatalAdminDateDose1",
            "matAntenatalAdminDateDose2",
            "matAntenatalEcvAttempted",
            "matAntenatalEcvDate",
            "matAntenatalPlannedDelMode",
            "matAntenatalVbacElig",
            "matAntenatalVbacNotEligCmt",
            "matAntenatalGbsResults",
            "matAntenatalGbsSwabTaken",
            "matAntenatalGbsSwabDate",
            "matAntenatalPphRiskAssess"
          ]
        },
        {
          "elementKey": "matAntenatalAntenatalRecRev",
          "formCss": "ehr-row-flow",
          "fqn": "matAntenatal.matAntenatalAntenatalRecRev",
          "gIndex": "4",
          "gChildren": [
            "matAntenatalAntenatalRecPart1",
            "matAntenatalAntenatalRecPart2",
            "matAntenatalPregConcerns",
            "matAntenatalPregConcernsCmt",
            "matAntenatalPastObsConcerns",
            "matAntenatalPastObsConcernsCmt",
            "matAntenatalMedSurgAnesConcerns",
            "matAntenatalMedSurgAnesConcernsCmt",
            "matAntenatalPsychConcerns",
            "matAntenatalPsychConcernsCmt",
            "matAntenatalSeamlessReferral",
            "matAntenatalSeamlessReferralCmt"
          ]
        },
        {
          "elementKey": "matAntenatalNicUse",
          "formCss": "ehr-row-flow",
          "fqn": "matAntenatal.matAntenatalNicUse",
          "gIndex": "5",
          "gChildren": [
            "matAntenatalNicHist",
            "matAntenatalNicHistCmt",
            "matAntenatalNicType",
            "matAntenatalNicTypeOth",
            "matAntenatalCigsPerDay",
            "matAntenatalYearsSmoking",
            "matAntenatalPackYearsAmt",
            "matAntenatalVapeMlPerDay",
            "matAntenatalVapeNicStrength",
            "matAntenatalVapeNicStrength2",
            "matAntenatalNicStopDate"
          ]
        },
        {
          "elementKey": "matAntenatalNicInterv",
          "formCss": "ehr-row-flow",
          "fqn": "matAntenatal.matAntenatalNicInterv",
          "gIndex": "6",
          "gChildren": [
            "matAntenatalNicCessInterv",
            "matAntenatalNicCessIntervOth",
            "matAntenatalWillingQuit",
            "matAntenatalNoSmokeFH",
            "matAntenatalNoSmokeFHCmt"
          ]
        },
        {
          "elementKey": "matAntenatalSubUse",
          "formCss": "ehr-row-flow",
          "fqn": "matAntenatal.matAntenatalSubUse",
          "gIndex": "7",
          "gChildren": [
            "matAntenatalAlcSubHist",
            "matAntenatalAlcSubHist2"
          ]
        },
        {
          "elementKey": "matAntenatalCGrp",
          "formCss": "ehr-row-flow",
          "fqn": "matAntenatal.matAntenatalCGrp",
          "gIndex": "8",
          "gChildren": [
            "matAntenatalComments"
          ]
        }
      ]
    }
  },
  "path": "/ehr/peri-ped/maternity",
  "routeName": "mat-antenatal",
  "label": "Antenatal Review",
  "generateComponent": "v2page",
  "featureFlag": "ffPed",
  "ehr": "yes",
  "fullPageKey": "periPed.maternity.matAntenatal"
}
export default DEFS