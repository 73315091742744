/* eslint-disable */
const DEFS = {
  "pageDataKey": "reports",
  "pIndex": "102",
  "pageChildren": [],
  "pageElements": {},
  "path": "/ehr/chart",
  "routeName": "reports",
  "label": "Reports and documents",
  "generateComponent": "custom",
  "ehr": "yes",
  "fullPageKey": "chart.reports"
}
export default DEFS