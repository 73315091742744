/* eslint-disable */
const DEFS = {
  "pageDataKey": "pedHourly",
  "pIndex": "77",
  "hasGridTable": true,
  "pageChildren": [
    {
      "elementKey": "pedHrForm_name",
      "formIndex": "1",
      "inputType": "practitionerName",
      "tableColumn": "1",
      "recHeader": true,
      "fqn": "pedHourly.name"
    },
    {
      "elementKey": "pedHrForm_id",
      "inputType": "generatedId",
      "tableColumn": 0,
      "label": "Row id",
      "tableCss": "row-id"
    },
    {
      "elementKey": "pedHrForm_profession",
      "formIndex": "1",
      "inputType": "practitionerProfession",
      "tableColumn": "1",
      "recHeader": true,
      "fqn": "pedHourly.profession"
    },
    {
      "elementKey": "pedHrForm_day",
      "formIndex": "1",
      "inputType": "visitDay",
      "mandatory": true,
      "tableColumn": "1",
      "validation": "visitDay",
      "recHeader": true,
      "fqn": "pedHourly.day"
    },
    {
      "elementKey": "pedHrForm_time",
      "formIndex": "1",
      "inputType": "visitTime",
      "mandatory": true,
      "tableColumn": "1",
      "validation": "time24",
      "recHeader": true,
      "fqn": "pedHourly.time"
    },
    {
      "elementKey": "pedHrTubeFree",
      "formIndex": "1",
      "inputType": "radioset",
      "options": [
        {
          "key": "yes",
          "text": "Yes"
        },
        {
          "key": "no",
          "text": "No"
        },
        {
          "key": "comment",
          "text": "Comment"
        }
      ],
      "tableColumn": "3",
      "fqn": "pedHourly.pedHrTubeFree"
    },
    {
      "elementKey": "pedHrTubeComment",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "4",
      "fqn": "pedHourly.pedHrTubeComment"
    },
    {
      "elementKey": "pedHrArousal",
      "formIndex": "1",
      "inputType": "radioset",
      "options": [
        {
          "key": "1",
          "text": "1 = awake and alert/oriented"
        },
        {
          "key": "2",
          "text": "2 = normal sleep/easy to arouse to verbal stimulation"
        },
        {
          "key": "3",
          "text": "3 = difficult to arouse to verbal stimulation"
        },
        {
          "key": "4",
          "text": "4 = responds only to physical stimulation"
        },
        {
          "key": "5",
          "text": "5 = does not respond to verbal or physical stimulation"
        }
      ],
      "tableColumn": "5",
      "fqn": "pedHourly.pedHrArousal"
    },
    {
      "elementKey": "pedHrActivity",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "6",
      "fqn": "pedHourly.pedHrActivity"
    },
    {
      "elementKey": "pedHrOxi",
      "formIndex": "1",
      "inputType": "number",
      "suffix": "%",
      "tableColumn": "8",
      "fqn": "pedHourly.pedHrOxi",
      "suffixText": "%"
    },
    {
      "elementKey": "pedHrLdIntro",
      "formIndex": "1",
      "inputType": "formInfoText",
      "tableColumn": "10",
      "fqn": "pedHourly.pedHrLdIntro"
    },
    {
      "elementKey": "pedHrLdTypes",
      "formIndex": "1",
      "inputType": "checkset",
      "options": [
        {
          "key": "cvad",
          "text": "CVAD"
        },
        {
          "key": "piv",
          "text": "PIV"
        },
        {
          "key": "chest",
          "text": "Chest tube"
        },
        {
          "key": "enteral",
          "text": "Enteral"
        },
        {
          "key": "urinary",
          "text": "Urinary catheter"
        }
      ],
      "tableColumn": "10",
      "fqn": "pedHourly.pedHrLdTypes"
    },
    {
      "elementKey": "pedHrLdChecked",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "11",
      "fqn": "pedHourly.pedHrLdChecked"
    },
    {
      "elementKey": "pedHrLdComment",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "12",
      "fqn": "pedHourly.pedHrLdComment"
    },
    {
      "elementKey": "pedHrEnteralIntro",
      "formIndex": "1",
      "inputType": "formInfoText",
      "tableColumn": "14",
      "fqn": "pedHourly.pedHrEnteralIntro"
    },
    {
      "elementKey": "pedHrEnteralPurpose",
      "formIndex": "1",
      "inputType": "checkset",
      "options": [
        {
          "key": "cvad",
          "text": "CVAD"
        },
        {
          "key": "piv",
          "text": "PIV"
        },
        {
          "key": "chest",
          "text": "Chest tube"
        },
        {
          "key": "enteral",
          "text": "Enteral"
        },
        {
          "key": "urinary",
          "text": "Urinary catheter"
        }
      ],
      "tableColumn": "15",
      "fqn": "pedHourly.pedHrEnteralPurpose"
    },
    {
      "elementKey": "pedHrEnteralChecked",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "16",
      "fqn": "pedHourly.pedHrEnteralChecked"
    },
    {
      "elementKey": "pedHrEnteralComment",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "17",
      "fqn": "pedHourly.pedHrEnteralComment"
    },
    {
      "elementKey": "pedHrCarePartners",
      "formIndex": "1",
      "inputType": "checkset",
      "options": [
        {
          "key": "mother",
          "text": "Mother"
        },
        {
          "key": "father",
          "text": "Father"
        },
        {
          "key": "grandmother",
          "text": "Grandmother"
        },
        {
          "key": "grandfather",
          "text": "Grandfather"
        },
        {
          "key": "fosterMother",
          "text": "Foster Mother"
        },
        {
          "key": "fosterFather",
          "text": "Foster Father"
        },
        {
          "key": "caregiver",
          "text": "Caregiver"
        },
        {
          "key": "friend",
          "text": "Friend"
        },
        {
          "key": "other",
          "text": "Other"
        }
      ],
      "tableColumn": "19",
      "fqn": "pedHourly.pedHrCarePartners"
    },
    {
      "elementKey": "pedHrCarePartnersOther",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "20",
      "fqn": "pedHourly.pedHrCarePartnersOther"
    },
    {
      "elementKey": "pedHrCareInteractions",
      "formIndex": "1",
      "inputType": "checkset",
      "options": [
        {
          "key": "cuddling",
          "text": "Cuddling"
        },
        {
          "key": "feeding",
          "text": "Feeding"
        },
        {
          "key": "holding",
          "text": "Holding"
        },
        {
          "key": "playing",
          "text": "Playing"
        },
        {
          "key": "providingAllCare",
          "text": "Providing All Care"
        },
        {
          "key": "skinToSkin",
          "text": "Skin to Skin"
        },
        {
          "key": "visiting",
          "text": "Visiting"
        },
        {
          "key": "other",
          "text": "Other"
        }
      ],
      "tableColumn": "21",
      "fqn": "pedHourly.pedHrCareInteractions"
    },
    {
      "elementKey": "pedHrCareInteractionsComments",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "22",
      "fqn": "pedHourly.pedHrCareInteractionsComments"
    },
    {
      "elementKey": "pedHrComments",
      "formIndex": "1",
      "inputType": "textarea",
      "tableColumn": "24",
      "fqn": "pedHourly.pedHrComments"
    }
  ],
  "pageElements": {
    "pedHrForm": {
      "elementKey": "pedHrForm",
      "pageElementIndex": "1",
      "pageKey": "pedHourly",
      "tableKey": "pedHrForm",
      "isTable": true,
      "form": {
        "elementKey": "pedHrForm",
        "label": "Hourly Assessment Peds",
        "addButtonText": "Add a Hourly Assessment Peds assessment",
        "formKey": "pedHrForm",
        "ehr_groups": [
          {
            "elementKey": "pedHrHdrGrp",
            "formCss": "record-header",
            "fqn": "pedHourly.pedHrHdrGrp",
            "gIndex": "1",
            "gChildren": [
              "pedHrForm_name",
              "pedHrForm_profession",
              "pedHrForm_day",
              "pedHrForm_time"
            ]
          },
          {
            "elementKey": "pedHrSafeCheck",
            "formCss": "ehr-row-flow",
            "fqn": "pedHourly.pedHrSafeCheck",
            "gIndex": "2",
            "gChildren": [
              "pedHrTubeFree",
              "pedHrTubeComment",
              "pedHrArousal",
              "pedHrActivity"
            ]
          },
          {
            "elementKey": "pedHrOxiGrp",
            "formCss": "ehr-row-flow",
            "fqn": "pedHourly.pedHrOxiGrp",
            "gIndex": "3",
            "gChildren": [
              "pedHrOxi"
            ]
          },
          {
            "elementKey": "pedHrLdGrp",
            "formCss": "ehr-row-flow",
            "fqn": "pedHourly.pedHrLdGrp",
            "gIndex": "4",
            "gChildren": [
              "pedHrLdIntro",
              "pedHrLdTypes",
              "pedHrLdChecked",
              "pedHrLdComment"
            ]
          },
          {
            "elementKey": "pedHrEnteralGrp",
            "formCss": "ehr-row-flow",
            "fqn": "pedHourly.pedHrEnteralGrp",
            "gIndex": "5",
            "gChildren": [
              "pedHrEnteralIntro",
              "pedHrEnteralPurpose",
              "pedHrEnteralChecked",
              "pedHrEnteralComment"
            ]
          },
          {
            "elementKey": "pedHrCareGrp",
            "formCss": "ehr-row-flow",
            "fqn": "pedHourly.pedHrCareGrp",
            "gIndex": "6",
            "gChildren": [
              "pedHrCarePartners",
              "pedHrCarePartnersOther",
              "pedHrCareInteractions",
              "pedHrCareInteractionsComments"
            ]
          },
          {
            "elementKey": "pedHrCmtGrp",
            "formCss": "grid-left-to-right-1",
            "fqn": "pedHourly.pedHrCmtGrp",
            "gIndex": "7",
            "gChildren": [
              "pedHrComments"
            ]
          }
        ],
        "ehr_data": {
          "pedHrForm_name": "",
          "pedHrForm_profession": "",
          "pedHrForm_day": "",
          "pedHrForm_time": "",
          "pedHrTubeFree": "",
          "pedHrTubeComment": "",
          "pedHrArousal": "",
          "pedHrActivity": "",
          "pedHrOxi": "",
          "pedHrLdTypes": "",
          "pedHrLdChecked": "",
          "pedHrLdComment": "",
          "pedHrEnteralPurpose": "",
          "pedHrEnteralChecked": "",
          "pedHrEnteralComment": "",
          "pedHrCarePartners": "",
          "pedHrCarePartnersOther": "",
          "pedHrCareInteractions": "",
          "pedHrCareInteractionsComments": "",
          "pedHrComments": ""
        }
      },
      "fqn": "pedHourly.pedHrForm",
      "tableChildren": [
        "pedHrForm_id",
        "pedHrForm_day",
        "pedHrForm_time",
        "pedHrTubeFree",
        "pedHrTubeComment",
        "pedHrArousal",
        "pedHrActivity",
        "pedHrOxi",
        "pedHrLdIntro",
        "pedHrLdTypes",
        "pedHrLdChecked",
        "pedHrLdComment",
        "pedHrEnteralIntro",
        "pedHrEnteralPurpose",
        "pedHrEnteralChecked",
        "pedHrEnteralComment",
        "pedHrCarePartners",
        "pedHrCarePartnersOther",
        "pedHrCareInteractions",
        "pedHrCareInteractionsComments",
        "pedHrComments",
        "pedHrForm_name",
        "pedHrForm_profession"
      ],
      "hasRecHeader": true
    }
  },
  "path": "/ehr/peri-ped/pediatrics",
  "routeName": "ped-hourly",
  "label": "Hourly Assessment Peds",
  "generateComponent": "v2page",
  "featureFlag": "ffPed",
  "ehr": "yes",
  "fullPageKey": "periPed.pediatrics.pedHourly"
}
export default DEFS