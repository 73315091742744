/* eslint-disable */
const DEFS = {
  "pageDataKey": "integumentaryAssessment",
  "pIndex": "42",
  "hasGridTable": true,
  "pageChildren": [
    {
      "elementKey": "tableIncision_name",
      "formIndex": "1",
      "inputType": "practitionerName",
      "tableColumn": "1",
      "recHeader": true,
      "fqn": "integumentaryAssessment.name"
    },
    {
      "elementKey": "tableIncision_id",
      "inputType": "generatedId",
      "tableColumn": 0,
      "label": "Row id",
      "tableCss": "row-id"
    },
    {
      "elementKey": "tableIncision_profession",
      "formIndex": "1",
      "inputType": "practitionerProfession",
      "tableColumn": "1",
      "recHeader": true,
      "fqn": "integumentaryAssessment.profession"
    },
    {
      "elementKey": "tableIncision_day",
      "formIndex": "1",
      "inputType": "visitDay",
      "mandatory": true,
      "tableColumn": "1",
      "validation": "visitDay",
      "recHeader": true,
      "fqn": "integumentaryAssessment.day"
    },
    {
      "elementKey": "tableIncision_time",
      "formIndex": "1",
      "inputType": "visitTime",
      "mandatory": true,
      "tableColumn": "1",
      "validation": "time24",
      "recHeader": true,
      "fqn": "integumentaryAssessment.time"
    },
    {
      "elementKey": "incisionLabel",
      "formIndex": "1",
      "inputType": "select",
      "options": [
        {
          "key": "Incision 1",
          "text": "Incision 1"
        },
        {
          "key": "Incision 2",
          "text": "Incision 2"
        },
        {
          "key": "Incision 3",
          "text": "Incision 3"
        },
        {
          "key": "Incision 4",
          "text": "Incision 4"
        }
      ],
      "tableColumn": "2",
      "fqn": "integumentaryAssessment.incisionLabel"
    },
    {
      "elementKey": "incisionLocation",
      "formIndex": "1",
      "inputType": "select",
      "options": [
        {
          "key": "Head",
          "text": "Head"
        },
        {
          "key": "Face",
          "text": "Face"
        },
        {
          "key": "Neck",
          "text": "Neck"
        },
        {
          "key": "Chest",
          "text": "Chest"
        },
        {
          "key": "Arm (Left)",
          "text": "Arm (Left)"
        },
        {
          "key": "Arm (Right)",
          "text": "Arm (Right)"
        },
        {
          "key": "Hand (Left)",
          "text": "Hand (Left)"
        },
        {
          "key": "Hand (Right)",
          "text": "Hand (Right)"
        },
        {
          "key": "Abdomen",
          "text": "Abdomen"
        },
        {
          "key": "Back",
          "text": "Back"
        },
        {
          "key": "Pelvis",
          "text": "Pelvis"
        },
        {
          "key": "Buttock",
          "text": "Buttock"
        },
        {
          "key": "Leg (Left)",
          "text": "Leg (Left)"
        },
        {
          "key": "Leg (Right)",
          "text": "Leg (Right)"
        },
        {
          "key": "Foot (Left)",
          "text": "Foot (Left)"
        },
        {
          "key": "Foot (Right)",
          "text": "Foot (Right)"
        }
      ],
      "tableColumn": "3",
      "fqn": "integumentaryAssessment.incisionLocation"
    },
    {
      "elementKey": "postOpDay",
      "formIndex": "1",
      "inputType": "visitDay",
      "tableColumn": "4",
      "validation": "visitDay",
      "fqn": "integumentaryAssessment.postOpDay"
    },
    {
      "elementKey": "opProcedure",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "5",
      "fqn": "integumentaryAssessment.opProcedure"
    },
    {
      "elementKey": "closureMethod",
      "formIndex": "1",
      "inputType": "checkset",
      "options": [
        {
          "key": "sutures",
          "text": "Sutures"
        },
        {
          "key": "staples",
          "text": "Staples"
        },
        {
          "key": "retentionSutures",
          "text": "Retention Sutures"
        },
        {
          "key": "surgiGlue",
          "text": "SurgiGlue"
        },
        {
          "key": "steriStrip",
          "text": "Steri-Strip"
        },
        {
          "key": "notApplicable",
          "text": "Not Applicable"
        }
      ],
      "tableColumn": "6",
      "fqn": "integumentaryAssessment.closureMethod"
    },
    {
      "elementKey": "incisionProfileImage",
      "formIndex": "1",
      "inputType": "ehrFile",
      "tableColumn": "7",
      "fqn": "integumentaryAssessment.incisionProfileImage"
    },
    {
      "elementKey": "incisionProfileComments",
      "formIndex": "1",
      "formCss": "grid-span-3-columns",
      "inputType": "textarea",
      "tableColumn": "8",
      "fqn": "integumentaryAssessment.incisionProfileComments"
    },
    {
      "elementKey": "tableIncisionAssessment_name",
      "formIndex": "2",
      "inputType": "practitionerName",
      "tableColumn": "1",
      "recHeader": true,
      "fqn": "integumentaryAssessment.name"
    },
    {
      "elementKey": "tableIncisionAssessment_id",
      "inputType": "generatedId",
      "tableColumn": 0,
      "label": "Row id",
      "tableCss": "row-id"
    },
    {
      "elementKey": "tableIncisionAssessment_profession",
      "formIndex": "2",
      "inputType": "practitionerProfession",
      "tableColumn": "1",
      "recHeader": true,
      "fqn": "integumentaryAssessment.profession"
    },
    {
      "elementKey": "tableIncisionAssessment_day",
      "formIndex": "2",
      "inputType": "visitDay",
      "mandatory": true,
      "tableColumn": "1",
      "validation": "visitDay",
      "recHeader": true,
      "fqn": "integumentaryAssessment.day"
    },
    {
      "elementKey": "tableIncisionAssessment_time",
      "formIndex": "2",
      "inputType": "visitTime",
      "mandatory": true,
      "tableColumn": "1",
      "validation": "time24",
      "recHeader": true,
      "fqn": "integumentaryAssessment.time"
    },
    {
      "elementKey": "incisionLabel",
      "formIndex": "2",
      "inputType": "select",
      "options": [
        {
          "key": "Incision 1",
          "text": "Incision 1"
        },
        {
          "key": "Incision 2",
          "text": "Incision 2"
        },
        {
          "key": "Incision 3",
          "text": "Incision 3"
        },
        {
          "key": "Incision 4",
          "text": "Incision 4"
        }
      ],
      "tableColumn": "2",
      "fqn": "integumentaryAssessment.incisionLabel"
    },
    {
      "elementKey": "incisionStatus",
      "formIndex": "2",
      "inputType": "select",
      "options": [
        {
          "key": "Approximated",
          "text": "Approximated"
        },
        {
          "key": "Fully Epithelialized",
          "text": "Fully Epithelialized"
        },
        {
          "key": "Tenuous",
          "text": "Tenuous"
        },
        {
          "key": "Gaping",
          "text": "Gaping"
        }
      ],
      "tableColumn": "3",
      "fqn": "integumentaryAssessment.incisionStatus"
    },
    {
      "elementKey": "incisionStatusImage",
      "formIndex": "2",
      "inputType": "ehrFile",
      "tableColumn": "4",
      "fqn": "integumentaryAssessment.incisionStatusImage"
    },
    {
      "elementKey": "closureChanges",
      "formIndex": "2",
      "inputType": "text",
      "tableColumn": "5",
      "fqn": "integumentaryAssessment.closureChanges"
    },
    {
      "elementKey": "exudateAmount",
      "formIndex": "2",
      "inputType": "checkset",
      "options": [
        {
          "key": "none",
          "text": "None"
        },
        {
          "key": "small",
          "text": "Small"
        },
        {
          "key": "moderate",
          "text": "Moderate"
        },
        {
          "key": "large",
          "text": "Large"
        }
      ],
      "tableColumn": "6",
      "fqn": "integumentaryAssessment.exudateAmount"
    },
    {
      "elementKey": "exudateType",
      "formIndex": "2",
      "inputType": "checkset",
      "options": [
        {
          "key": "serous",
          "text": "Serous"
        },
        {
          "key": "sanguineous",
          "text": "Sanguineous"
        },
        {
          "key": "purulent",
          "text": "Purulent"
        },
        {
          "key": "other",
          "text": "Other"
        },
        {
          "key": "notApplicable",
          "text": "Not Applicable"
        }
      ],
      "tableColumn": "7",
      "fqn": "integumentaryAssessment.exudateType"
    },
    {
      "elementKey": "periIncisionSkin",
      "formIndex": "2",
      "inputType": "checkset",
      "options": [
        {
          "key": "intact",
          "text": "Intact"
        },
        {
          "key": "macerated",
          "text": "Macerated"
        },
        {
          "key": "blistersErythemaLessThan2Cm",
          "text": "Blisters Erythema less than 2cm"
        },
        {
          "key": "erythemaGreaterThan2Cm",
          "text": "Erythema greater than 2cm"
        },
        {
          "key": "indurationLessThan2Cm",
          "text": "Induration less than 2cm"
        },
        {
          "key": "indurationGreaterThan2Cm",
          "text": "Induration greater than 2cm"
        },
        {
          "key": "other",
          "text": "Other"
        }
      ],
      "tableColumn": "8",
      "fqn": "integumentaryAssessment.periIncisionSkin"
    },
    {
      "elementKey": "periIncisionSkinOther",
      "formIndex": "2",
      "inputType": "text",
      "tableColumn": "9",
      "fqn": "integumentaryAssessment.periIncisionSkinOther"
    },
    {
      "elementKey": "incisionStatusComments",
      "formIndex": "2",
      "formCss": "grid-span-3-columns",
      "inputType": "textarea",
      "tableColumn": "10",
      "fqn": "integumentaryAssessment.incisionStatusComments"
    },
    {
      "elementKey": "tableWound_name",
      "formIndex": "3",
      "inputType": "practitionerName",
      "tableColumn": "1",
      "recHeader": true,
      "fqn": "integumentaryAssessment.name"
    },
    {
      "elementKey": "tableWound_id",
      "inputType": "generatedId",
      "tableColumn": 0,
      "label": "Row id",
      "tableCss": "row-id"
    },
    {
      "elementKey": "tableWound_profession",
      "formIndex": "3",
      "inputType": "practitionerProfession",
      "tableColumn": "1",
      "recHeader": true,
      "fqn": "integumentaryAssessment.profession"
    },
    {
      "elementKey": "tableWound_day",
      "formIndex": "3",
      "inputType": "visitDay",
      "mandatory": true,
      "tableColumn": "1",
      "validation": "visitDay",
      "recHeader": true,
      "fqn": "integumentaryAssessment.day"
    },
    {
      "elementKey": "tableWound_time",
      "formIndex": "3",
      "inputType": "visitTime",
      "mandatory": true,
      "tableColumn": "1",
      "validation": "time24",
      "recHeader": true,
      "fqn": "integumentaryAssessment.time"
    },
    {
      "elementKey": "woundLocation",
      "formIndex": "3",
      "inputType": "select",
      "options": [
        {
          "key": "Head",
          "text": "Head"
        },
        {
          "key": "Face",
          "text": "Face"
        },
        {
          "key": "Neck",
          "text": "Neck"
        },
        {
          "key": "Chest",
          "text": "Chest"
        },
        {
          "key": "Arm (Left)",
          "text": "Arm (Left)"
        },
        {
          "key": "Arm (Right)",
          "text": "Arm (Right)"
        },
        {
          "key": "Hand (Left)",
          "text": "Hand (Left)"
        },
        {
          "key": "Hand (Right)",
          "text": "Hand (Right)"
        },
        {
          "key": "Abdomen",
          "text": "Abdomen"
        },
        {
          "key": "Back",
          "text": "Back"
        },
        {
          "key": "Pelvis",
          "text": "Pelvis"
        },
        {
          "key": "Buttock",
          "text": "Buttock"
        },
        {
          "key": "Leg (Left)",
          "text": "Leg (Left)"
        },
        {
          "key": "Leg (Right)",
          "text": "Leg (Right)"
        },
        {
          "key": "Foot (Left)",
          "text": "Foot (Left)"
        },
        {
          "key": "Foot (Right)",
          "text": "Foot (Right)"
        }
      ],
      "tableColumn": "2",
      "fqn": "integumentaryAssessment.woundLocation"
    },
    {
      "elementKey": "woundLabel",
      "formIndex": "3",
      "inputType": "select",
      "options": [
        {
          "key": "Wound A",
          "text": "Wound A"
        },
        {
          "key": "Wound B",
          "text": "Wound B"
        },
        {
          "key": "Wound C",
          "text": "Wound C"
        },
        {
          "key": "Wound D",
          "text": "Wound D"
        }
      ],
      "tableColumn": "3",
      "fqn": "integumentaryAssessment.woundLabel"
    },
    {
      "elementKey": "woundDayOnset",
      "formIndex": "3",
      "inputType": "text",
      "tableColumn": "4",
      "validation": "visitDay",
      "fqn": "integumentaryAssessment.woundDayOnset"
    },
    {
      "elementKey": "stageType",
      "formIndex": "3",
      "formCss": "grid-span-3-columns",
      "inputType": "checkset",
      "options": [
        {
          "key": "pressureInjuryStage1",
          "text": "Pressure Injury Stage 1"
        },
        {
          "key": "pressureInjuryStage2",
          "text": "Pressure Injury Stage 2"
        },
        {
          "key": "pressureInjuryStage3",
          "text": "Pressure Injury Stage 3"
        },
        {
          "key": "pressureInjuryStage4",
          "text": "Pressure Injury Stage 4"
        },
        {
          "key": "pressureInjuryUnstageable",
          "text": "Pressure Injury Unstageable"
        },
        {
          "key": "pressureInjuryDeepTissueInjury",
          "text": "Pressure Injury Deep Tissue Injury"
        },
        {
          "key": "surgicalSecondaryIntent",
          "text": "Surgical Secondary Intent"
        },
        {
          "key": "skinTear",
          "text": "Skin Tear"
        },
        {
          "key": "abscess",
          "text": "Abscess"
        },
        {
          "key": "burn",
          "text": "Burn"
        },
        {
          "key": "venousUlcer",
          "text": "Venous Ulcer"
        },
        {
          "key": "arterialUlcer",
          "text": "Arterial Ulcer"
        },
        {
          "key": "mixedVenous/arterialUlcer",
          "text": "Mixed Venous/Arterial Ulcer"
        },
        {
          "key": "diabetic/neuropathicUlcer",
          "text": "Diabetic/Neuropathic Ulcer"
        },
        {
          "key": "other",
          "text": "Other"
        }
      ],
      "tableColumn": "5",
      "fqn": "integumentaryAssessment.stageType"
    },
    {
      "elementKey": "stageOther",
      "dependentOn": "visble:stageType=other",
      "formIndex": "3",
      "inputType": "text",
      "tableColumn": "6",
      "fqn": "integumentaryAssessment.stageOther"
    },
    {
      "elementKey": "goalOfCare",
      "formIndex": "3",
      "inputType": "select",
      "options": [
        {
          "key": "To heal",
          "text": "To heal"
        },
        {
          "key": "To maintain",
          "text": "To maintain"
        },
        {
          "key": "To monitor/manage",
          "text": "To monitor/manage"
        }
      ],
      "tableColumn": "7",
      "fqn": "integumentaryAssessment.goalOfCare"
    },
    {
      "elementKey": "woundProfileImage",
      "formIndex": "3",
      "inputType": "ehrFile",
      "tableColumn": "8",
      "fqn": "integumentaryAssessment.woundProfileImage"
    },
    {
      "elementKey": "woundProfileComments",
      "formIndex": "3",
      "formCss": "grid-span-3-columns",
      "inputType": "textarea",
      "tableColumn": "9",
      "fqn": "integumentaryAssessment.woundProfileComments"
    },
    {
      "elementKey": "tableWoundAssessment_name",
      "formIndex": "4",
      "inputType": "practitionerName",
      "tableColumn": "1",
      "recHeader": true,
      "fqn": "integumentaryAssessment.name"
    },
    {
      "elementKey": "tableWoundAssessment_id",
      "inputType": "generatedId",
      "tableColumn": 0,
      "label": "Row id",
      "tableCss": "row-id"
    },
    {
      "elementKey": "tableWoundAssessment_profession",
      "formIndex": "4",
      "inputType": "practitionerProfession",
      "tableColumn": "1",
      "recHeader": true,
      "fqn": "integumentaryAssessment.profession"
    },
    {
      "elementKey": "tableWoundAssessment_day",
      "formIndex": "4",
      "inputType": "visitDay",
      "mandatory": true,
      "tableColumn": "1",
      "validation": "visitDay",
      "recHeader": true,
      "fqn": "integumentaryAssessment.day"
    },
    {
      "elementKey": "tableWoundAssessment_time",
      "formIndex": "4",
      "inputType": "visitTime",
      "mandatory": true,
      "tableColumn": "1",
      "validation": "time24",
      "recHeader": true,
      "fqn": "integumentaryAssessment.time"
    },
    {
      "elementKey": "woundLabel",
      "formIndex": "4",
      "inputType": "select",
      "options": [
        {
          "key": "Wound A",
          "text": "Wound A"
        },
        {
          "key": "Wound B",
          "text": "Wound B"
        },
        {
          "key": "Wound C",
          "text": "Wound C"
        },
        {
          "key": "Wound D",
          "text": "Wound D"
        }
      ],
      "tableColumn": "2",
      "fqn": "integumentaryAssessment.woundLabel"
    },
    {
      "elementKey": "woundAssessmentImage",
      "formIndex": "4",
      "inputType": "ehrFile",
      "tableColumn": "3",
      "fqn": "integumentaryAssessment.woundAssessmentImage"
    },
    {
      "elementKey": "length",
      "formIndex": "4",
      "inputType": "text",
      "tableColumn": "4",
      "fqn": "integumentaryAssessment.length"
    },
    {
      "elementKey": "width",
      "formIndex": "4",
      "inputType": "text",
      "tableColumn": "5",
      "fqn": "integumentaryAssessment.width"
    },
    {
      "elementKey": "depth",
      "formIndex": "4",
      "inputType": "text",
      "tableColumn": "6",
      "fqn": "integumentaryAssessment.depth"
    },
    {
      "elementKey": "sinusDepth1",
      "formIndex": "4",
      "inputType": "text",
      "tableColumn": "7",
      "fqn": "integumentaryAssessment.sinusDepth1"
    },
    {
      "elementKey": "sinusDepthLocation1",
      "formIndex": "4",
      "inputType": "select",
      "options": [
        {
          "key": "1",
          "text": "1"
        },
        {
          "key": "2",
          "text": "2"
        },
        {
          "key": "3",
          "text": "3"
        },
        {
          "key": "4",
          "text": "4"
        },
        {
          "key": "5",
          "text": "5"
        },
        {
          "key": "6",
          "text": "6"
        },
        {
          "key": "7",
          "text": "7"
        },
        {
          "key": "8",
          "text": "8"
        },
        {
          "key": "9",
          "text": "9"
        },
        {
          "key": "10",
          "text": "10"
        },
        {
          "key": "11",
          "text": "11"
        },
        {
          "key": "12",
          "text": "12"
        }
      ],
      "tableColumn": "8",
      "fqn": "integumentaryAssessment.sinusDepthLocation1"
    },
    {
      "elementKey": "spacer42",
      "formIndex": "4",
      "inputType": "spacer",
      "fqn": "integumentaryAssessment.spacer42"
    },
    {
      "elementKey": "sinusDepth2",
      "formIndex": "4",
      "inputType": "text",
      "tableColumn": "9",
      "fqn": "integumentaryAssessment.sinusDepth2"
    },
    {
      "elementKey": "sinusDepthLocation2",
      "formIndex": "4",
      "inputType": "select",
      "options": [
        {
          "key": "1",
          "text": "1"
        },
        {
          "key": "2",
          "text": "2"
        },
        {
          "key": "3",
          "text": "3"
        },
        {
          "key": "4",
          "text": "4"
        },
        {
          "key": "5",
          "text": "5"
        },
        {
          "key": "6",
          "text": "6"
        },
        {
          "key": "7",
          "text": "7"
        },
        {
          "key": "8",
          "text": "8"
        },
        {
          "key": "9",
          "text": "9"
        },
        {
          "key": "10",
          "text": "10"
        },
        {
          "key": "11",
          "text": "11"
        },
        {
          "key": "12",
          "text": "12"
        }
      ],
      "tableColumn": "10",
      "fqn": "integumentaryAssessment.sinusDepthLocation2"
    },
    {
      "elementKey": "spacer43",
      "formIndex": "4",
      "inputType": "spacer",
      "fqn": "integumentaryAssessment.spacer43"
    },
    {
      "elementKey": "underminingDepth1",
      "formIndex": "4",
      "inputType": "text",
      "tableColumn": "11",
      "fqn": "integumentaryAssessment.underminingDepth1"
    },
    {
      "elementKey": "underminingDepthLocation1A",
      "formIndex": "4",
      "inputType": "select",
      "options": [
        {
          "key": "1",
          "text": "1"
        },
        {
          "key": "2",
          "text": "2"
        },
        {
          "key": "3",
          "text": "3"
        },
        {
          "key": "4",
          "text": "4"
        },
        {
          "key": "5",
          "text": "5"
        },
        {
          "key": "6",
          "text": "6"
        },
        {
          "key": "7",
          "text": "7"
        },
        {
          "key": "8",
          "text": "8"
        },
        {
          "key": "9",
          "text": "9"
        },
        {
          "key": "10",
          "text": "10"
        },
        {
          "key": "11",
          "text": "11"
        },
        {
          "key": "12",
          "text": "12"
        }
      ],
      "tableColumn": "12",
      "fqn": "integumentaryAssessment.underminingDepthLocation1A"
    },
    {
      "elementKey": "underminingDepthLocation1B",
      "formIndex": "4",
      "inputType": "select",
      "options": [
        {
          "key": "1",
          "text": "1"
        },
        {
          "key": "2",
          "text": "2"
        },
        {
          "key": "3",
          "text": "3"
        },
        {
          "key": "4",
          "text": "4"
        },
        {
          "key": "5",
          "text": "5"
        },
        {
          "key": "6",
          "text": "6"
        },
        {
          "key": "7",
          "text": "7"
        },
        {
          "key": "8",
          "text": "8"
        },
        {
          "key": "9",
          "text": "9"
        },
        {
          "key": "10",
          "text": "10"
        },
        {
          "key": "11",
          "text": "11"
        },
        {
          "key": "12",
          "text": "12"
        }
      ],
      "tableColumn": "13",
      "fqn": "integumentaryAssessment.underminingDepthLocation1B"
    },
    {
      "elementKey": "underminingDepth2",
      "formIndex": "4",
      "inputType": "text",
      "tableColumn": "14",
      "fqn": "integumentaryAssessment.underminingDepth2"
    },
    {
      "elementKey": "underminingDepthLocation2A",
      "formIndex": "4",
      "inputType": "select",
      "options": [
        {
          "key": "1",
          "text": "1"
        },
        {
          "key": "2",
          "text": "2"
        },
        {
          "key": "3",
          "text": "3"
        },
        {
          "key": "4",
          "text": "4"
        },
        {
          "key": "5",
          "text": "5"
        },
        {
          "key": "6",
          "text": "6"
        },
        {
          "key": "7",
          "text": "7"
        },
        {
          "key": "8",
          "text": "8"
        },
        {
          "key": "9",
          "text": "9"
        },
        {
          "key": "10",
          "text": "10"
        },
        {
          "key": "11",
          "text": "11"
        },
        {
          "key": "12",
          "text": "12"
        }
      ],
      "tableColumn": "15",
      "fqn": "integumentaryAssessment.underminingDepthLocation2A"
    },
    {
      "elementKey": "underminingDepthLocation2B",
      "formIndex": "4",
      "inputType": "select",
      "options": [
        {
          "key": "1",
          "text": "1"
        },
        {
          "key": "2",
          "text": "2"
        },
        {
          "key": "3",
          "text": "3"
        },
        {
          "key": "4",
          "text": "4"
        },
        {
          "key": "5",
          "text": "5"
        },
        {
          "key": "6",
          "text": "6"
        },
        {
          "key": "7",
          "text": "7"
        },
        {
          "key": "8",
          "text": "8"
        },
        {
          "key": "9",
          "text": "9"
        },
        {
          "key": "10",
          "text": "10"
        },
        {
          "key": "11",
          "text": "11"
        },
        {
          "key": "12",
          "text": "12"
        }
      ],
      "tableColumn": "16",
      "fqn": "integumentaryAssessment.underminingDepthLocation2B"
    },
    {
      "elementKey": "pinkred",
      "formIndex": "4",
      "inputType": "select",
      "options": [
        {
          "key": "10",
          "text": "10%"
        },
        {
          "key": "20",
          "text": "20%"
        },
        {
          "key": "30",
          "text": "30%"
        },
        {
          "key": "40",
          "text": "40%"
        },
        {
          "key": "50",
          "text": "50%"
        },
        {
          "key": "60",
          "text": "60%"
        },
        {
          "key": "70",
          "text": "70%"
        },
        {
          "key": "80",
          "text": "80%"
        },
        {
          "key": "90",
          "text": "90%"
        },
        {
          "key": "100",
          "text": "100%"
        }
      ],
      "passToFunction": "woundBedCalculation",
      "tableColumn": "17",
      "fqn": "integumentaryAssessment.pinkred"
    },
    {
      "elementKey": "granulation",
      "formIndex": "4",
      "inputType": "select",
      "options": [
        {
          "key": "10",
          "text": "10%"
        },
        {
          "key": "20",
          "text": "20%"
        },
        {
          "key": "30",
          "text": "30%"
        },
        {
          "key": "40",
          "text": "40%"
        },
        {
          "key": "50",
          "text": "50%"
        },
        {
          "key": "60",
          "text": "60%"
        },
        {
          "key": "70",
          "text": "70%"
        },
        {
          "key": "80",
          "text": "80%"
        },
        {
          "key": "90",
          "text": "90%"
        },
        {
          "key": "100",
          "text": "100%"
        }
      ],
      "passToFunction": "woundBedCalculation",
      "tableColumn": "18",
      "fqn": "integumentaryAssessment.granulation"
    },
    {
      "elementKey": "nongranulated",
      "formIndex": "4",
      "inputType": "select",
      "options": [
        {
          "key": "10",
          "text": "10%"
        },
        {
          "key": "20",
          "text": "20%"
        },
        {
          "key": "30",
          "text": "30%"
        },
        {
          "key": "40",
          "text": "40%"
        },
        {
          "key": "50",
          "text": "50%"
        },
        {
          "key": "60",
          "text": "60%"
        },
        {
          "key": "70",
          "text": "70%"
        },
        {
          "key": "80",
          "text": "80%"
        },
        {
          "key": "90",
          "text": "90%"
        },
        {
          "key": "100",
          "text": "100%"
        }
      ],
      "passToFunction": "woundBedCalculation",
      "tableColumn": "19",
      "fqn": "integumentaryAssessment.nongranulated"
    },
    {
      "elementKey": "slough",
      "formIndex": "4",
      "inputType": "select",
      "options": [
        {
          "key": "10",
          "text": "10%"
        },
        {
          "key": "20",
          "text": "20%"
        },
        {
          "key": "30",
          "text": "30%"
        },
        {
          "key": "40",
          "text": "40%"
        },
        {
          "key": "50",
          "text": "50%"
        },
        {
          "key": "60",
          "text": "60%"
        },
        {
          "key": "70",
          "text": "70%"
        },
        {
          "key": "80",
          "text": "80%"
        },
        {
          "key": "90",
          "text": "90%"
        },
        {
          "key": "100",
          "text": "100%"
        }
      ],
      "passToFunction": "woundBedCalculation",
      "tableColumn": "20",
      "fqn": "integumentaryAssessment.slough"
    },
    {
      "elementKey": "escharBoggy",
      "formIndex": "4",
      "inputType": "select",
      "options": [
        {
          "key": "10",
          "text": "10%"
        },
        {
          "key": "20",
          "text": "20%"
        },
        {
          "key": "30",
          "text": "30%"
        },
        {
          "key": "40",
          "text": "40%"
        },
        {
          "key": "50",
          "text": "50%"
        },
        {
          "key": "60",
          "text": "60%"
        },
        {
          "key": "70",
          "text": "70%"
        },
        {
          "key": "80",
          "text": "80%"
        },
        {
          "key": "90",
          "text": "90%"
        },
        {
          "key": "100",
          "text": "100%"
        }
      ],
      "passToFunction": "woundBedCalculation",
      "tableColumn": "21",
      "fqn": "integumentaryAssessment.escharBoggy"
    },
    {
      "elementKey": "escharStable",
      "formIndex": "4",
      "inputType": "select",
      "options": [
        {
          "key": "10",
          "text": "10%"
        },
        {
          "key": "20",
          "text": "20%"
        },
        {
          "key": "30",
          "text": "30%"
        },
        {
          "key": "40",
          "text": "40%"
        },
        {
          "key": "50",
          "text": "50%"
        },
        {
          "key": "60",
          "text": "60%"
        },
        {
          "key": "70",
          "text": "70%"
        },
        {
          "key": "80",
          "text": "80%"
        },
        {
          "key": "90",
          "text": "90%"
        },
        {
          "key": "100",
          "text": "100%"
        }
      ],
      "passToFunction": "woundBedCalculation",
      "tableColumn": "22",
      "fqn": "integumentaryAssessment.escharStable"
    },
    {
      "elementKey": "foreign",
      "formIndex": "4",
      "inputType": "select",
      "options": [
        {
          "key": "10",
          "text": "10%"
        },
        {
          "key": "20",
          "text": "20%"
        },
        {
          "key": "30",
          "text": "30%"
        },
        {
          "key": "40",
          "text": "40%"
        },
        {
          "key": "50",
          "text": "50%"
        },
        {
          "key": "60",
          "text": "60%"
        },
        {
          "key": "70",
          "text": "70%"
        },
        {
          "key": "80",
          "text": "80%"
        },
        {
          "key": "90",
          "text": "90%"
        },
        {
          "key": "100",
          "text": "100%"
        }
      ],
      "passToFunction": "woundBedCalculation",
      "tableColumn": "23",
      "fqn": "integumentaryAssessment.foreign"
    },
    {
      "elementKey": "underlying",
      "formIndex": "4",
      "inputType": "select",
      "options": [
        {
          "key": "10",
          "text": "10%"
        },
        {
          "key": "20",
          "text": "20%"
        },
        {
          "key": "30",
          "text": "30%"
        },
        {
          "key": "40",
          "text": "40%"
        },
        {
          "key": "50",
          "text": "50%"
        },
        {
          "key": "60",
          "text": "60%"
        },
        {
          "key": "70",
          "text": "70%"
        },
        {
          "key": "80",
          "text": "80%"
        },
        {
          "key": "90",
          "text": "90%"
        },
        {
          "key": "100",
          "text": "100%"
        }
      ],
      "passToFunction": "woundBedCalculation",
      "tableColumn": "24",
      "fqn": "integumentaryAssessment.underlying"
    },
    {
      "elementKey": "notVisible",
      "formIndex": "4",
      "inputType": "select",
      "options": [
        {
          "key": "10",
          "text": "10%"
        },
        {
          "key": "20",
          "text": "20%"
        },
        {
          "key": "30",
          "text": "30%"
        },
        {
          "key": "40",
          "text": "40%"
        },
        {
          "key": "50",
          "text": "50%"
        },
        {
          "key": "60",
          "text": "60%"
        },
        {
          "key": "70",
          "text": "70%"
        },
        {
          "key": "80",
          "text": "80%"
        },
        {
          "key": "90",
          "text": "90%"
        },
        {
          "key": "100",
          "text": "100%"
        }
      ],
      "passToFunction": "woundBedCalculation",
      "tableColumn": "25",
      "fqn": "integumentaryAssessment.notVisible"
    },
    {
      "elementKey": "other",
      "formIndex": "4",
      "inputType": "select",
      "options": [
        {
          "key": "10",
          "text": "10%"
        },
        {
          "key": "20",
          "text": "20%"
        },
        {
          "key": "30",
          "text": "30%"
        },
        {
          "key": "40",
          "text": "40%"
        },
        {
          "key": "50",
          "text": "50%"
        },
        {
          "key": "60",
          "text": "60%"
        },
        {
          "key": "70",
          "text": "70%"
        },
        {
          "key": "80",
          "text": "80%"
        },
        {
          "key": "90",
          "text": "90%"
        },
        {
          "key": "100",
          "text": "100%"
        }
      ],
      "passToFunction": "woundBedCalculation",
      "tableColumn": "26",
      "fqn": "integumentaryAssessment.other"
    },
    {
      "elementKey": "woundBedCalculation",
      "calculationType": "sum",
      "defaultValue": "0",
      "formIndex": "4",
      "inputType": "calculatedValue",
      "tableColumn": "27",
      "validation": "range(0,100)",
      "fqn": "integumentaryAssessment.woundBedCalculation"
    },
    {
      "elementKey": "exudateAmount",
      "formIndex": "4",
      "inputType": "select",
      "options": [
        {
          "key": "None",
          "text": "None"
        },
        {
          "key": "Scant/small",
          "text": "Scant/small"
        },
        {
          "key": "Moderate",
          "text": "Moderate"
        },
        {
          "key": "Large/copious",
          "text": "Large/copious"
        }
      ],
      "tableColumn": "28",
      "fqn": "integumentaryAssessment.exudateAmount"
    },
    {
      "elementKey": "exudateType",
      "formIndex": "4",
      "inputType": "checkset",
      "options": [
        {
          "key": "serous",
          "text": "Serous"
        },
        {
          "key": "sanguineous",
          "text": "Sanguineous"
        },
        {
          "key": "purulent",
          "text": "Purulent"
        },
        {
          "key": "other",
          "text": "Other"
        }
      ],
      "tableColumn": "29",
      "fqn": "integumentaryAssessment.exudateType"
    },
    {
      "elementKey": "odour",
      "formIndex": "4",
      "inputType": "select",
      "options": [
        {
          "key": "Yes",
          "text": "Yes"
        },
        {
          "key": "No",
          "text": "No"
        }
      ],
      "tableColumn": "30",
      "fqn": "integumentaryAssessment.odour"
    },
    {
      "elementKey": "woundEdge",
      "formIndex": "4",
      "inputType": "checkset",
      "options": [
        {
          "key": "diffuse",
          "text": "Diffuse"
        },
        {
          "key": "demarcated",
          "text": "Demarcated"
        },
        {
          "key": "attached",
          "text": "Attached"
        },
        {
          "key": "notAttached",
          "text": "Not attached"
        },
        {
          "key": "epithelialization",
          "text": "Epithelialization"
        },
        {
          "key": "rolled",
          "text": "Rolled"
        }
      ],
      "tableColumn": "31",
      "fqn": "integumentaryAssessment.woundEdge"
    },
    {
      "elementKey": "periWoundSkin",
      "formIndex": "4",
      "inputType": "checkset",
      "options": [
        {
          "key": "intact",
          "text": "Intact"
        },
        {
          "key": "fragile",
          "text": "Fragile"
        },
        {
          "key": "macerated",
          "text": "Macerated"
        },
        {
          "key": "excoriated/denuded",
          "text": "Excoriated/Denuded"
        },
        {
          "key": "erythema (2CmOrLess)",
          "text": "Erythema (2cm or less)"
        },
        {
          "key": "indurated (2CmOrLess)",
          "text": "Indurated (2cm or less)"
        },
        {
          "key": "indurated (greaterThan2Cm)",
          "text": "Indurated (greater than 2cm)"
        },
        {
          "key": "erythema (greaterThan2Cm)",
          "text": "Erythema (greater than 2cm)"
        },
        {
          "key": "increasedWarmth",
          "text": "Increased Warmth"
        },
        {
          "key": "other",
          "text": "Other"
        }
      ],
      "tableColumn": "32",
      "fqn": "integumentaryAssessment.periWoundSkin"
    },
    {
      "elementKey": "woundPain",
      "formIndex": "4",
      "inputType": "text",
      "suffix": "/10",
      "tableColumn": "33",
      "validation": "range(0,10)",
      "fqn": "integumentaryAssessment.woundPain",
      "suffixText": "/10"
    },
    {
      "elementKey": "packingOut",
      "formIndex": "4",
      "inputType": "text",
      "tableColumn": "34",
      "fqn": "integumentaryAssessment.packingOut"
    },
    {
      "elementKey": "packinIn",
      "formIndex": "4",
      "inputType": "text",
      "tableColumn": "35",
      "fqn": "integumentaryAssessment.packinIn"
    },
    {
      "elementKey": "spacer44",
      "formIndex": "4",
      "inputType": "spacer",
      "fqn": "integumentaryAssessment.spacer44"
    },
    {
      "elementKey": "treatmentComplete",
      "formIndex": "4",
      "inputType": "select",
      "options": [
        {
          "key": "Yes",
          "text": "Yes"
        },
        {
          "key": "No",
          "text": "No"
        }
      ],
      "tableColumn": "36",
      "fqn": "integumentaryAssessment.treatmentComplete"
    },
    {
      "elementKey": "woundAssessmentComments",
      "formIndex": "4",
      "formCss": "grid-span-3-columns",
      "inputType": "textarea",
      "tableColumn": "37",
      "fqn": "integumentaryAssessment.woundAssessmentComments"
    }
  ],
  "pageElements": {
    "tableIncision": {
      "elementKey": "tableIncision",
      "pageElementIndex": "1",
      "pageKey": "integumentaryAssessment",
      "tableKey": "tableIncision",
      "isTable": true,
      "form": {
        "elementKey": "tableIncision",
        "label": "Incision profile",
        "addButtonText": "Add an incision profile",
        "formKey": "tableIncision",
        "ehr_groups": [
          {
            "elementKey": "hdrGroup",
            "formCss": "record-header",
            "fqn": "integumentaryAssessment.hdrGroup",
            "gIndex": "1",
            "gChildren": [
              "tableIncision_name",
              "tableIncision_profession",
              "tableIncision_day",
              "tableIncision_time"
            ]
          },
          {
            "elementKey": "incisionLocationGroup",
            "formCss": "grid-left-to-right-3",
            "fqn": "integumentaryAssessment.incisionLocationGroup",
            "gIndex": "2",
            "gChildren": [
              "incisionLabel",
              "incisionLocation",
              "postOpDay",
              "opProcedure",
              "closureMethod",
              "incisionProfileImage",
              "incisionProfileComments"
            ]
          }
        ],
        "ehr_data": {
          "tableIncision_name": "",
          "tableIncision_profession": "",
          "tableIncision_day": "",
          "tableIncision_time": "",
          "incisionLabel": "",
          "incisionLocation": "",
          "postOpDay": "",
          "opProcedure": "",
          "closureMethod": "",
          "incisionProfileImage": "",
          "incisionProfileComments": ""
        }
      },
      "fqn": "integumentaryAssessment.tableIncision",
      "tableChildren": [
        "tableIncision_id",
        "tableIncision_day",
        "tableIncision_time",
        "incisionLabel",
        "incisionLocation",
        "postOpDay",
        "opProcedure",
        "closureMethod",
        "incisionProfileImage",
        "incisionProfileComments",
        "tableIncision_name",
        "tableIncision_profession"
      ],
      "hasRecHeader": true
    },
    "tableIncisionAssessment": {
      "elementKey": "tableIncisionAssessment",
      "pageElementIndex": "2",
      "pageKey": "integumentaryAssessment",
      "tableKey": "tableIncisionAssessment",
      "isTable": true,
      "form": {
        "elementKey": "tableIncisionAssessment",
        "label": "Incision assessment",
        "addButtonText": "Add an incision assessment",
        "formOption": "transpose",
        "formKey": "tableIncisionAssessment",
        "ehr_groups": [
          {
            "elementKey": "hdrGroup",
            "formCss": "record-header",
            "fqn": "integumentaryAssessment.hdrGroup",
            "gIndex": "1",
            "gChildren": [
              "tableIncisionAssessment_name",
              "tableIncisionAssessment_profession",
              "tableIncisionAssessment_day",
              "tableIncisionAssessment_time"
            ]
          },
          {
            "elementKey": "incisionStatusGroup",
            "formCss": "grid-left-to-right-3",
            "fqn": "integumentaryAssessment.incisionStatusGroup",
            "gIndex": "2",
            "gChildren": [
              "incisionLabel",
              "incisionStatus",
              "incisionStatusImage",
              "closureChanges"
            ]
          },
          {
            "elementKey": "exudateGroup",
            "formCss": "grid-left-to-right-3",
            "fqn": "integumentaryAssessment.exudateGroup",
            "gIndex": "3",
            "gChildren": [
              "exudateAmount",
              "exudateType"
            ]
          },
          {
            "elementKey": "periIncisionGroup",
            "formCss": "grid-left-to-right-3",
            "fqn": "integumentaryAssessment.periIncisionGroup",
            "gIndex": "4",
            "gChildren": [
              "periIncisionSkin",
              "periIncisionSkinOther",
              "incisionStatusComments"
            ]
          }
        ],
        "ehr_data": {
          "tableIncisionAssessment_name": "",
          "tableIncisionAssessment_profession": "",
          "tableIncisionAssessment_day": "",
          "tableIncisionAssessment_time": "",
          "incisionLabel": "",
          "incisionStatus": "",
          "incisionStatusImage": "",
          "closureChanges": "",
          "exudateAmount": "",
          "exudateType": "",
          "periIncisionSkin": "",
          "periIncisionSkinOther": "",
          "incisionStatusComments": ""
        }
      },
      "fqn": "integumentaryAssessment.tableIncisionAssessment",
      "tableChildren": [
        "tableIncisionAssessment_id",
        "tableIncisionAssessment_day",
        "tableIncisionAssessment_time",
        "incisionLabel",
        "incisionStatus",
        "incisionStatusImage",
        "closureChanges",
        "exudateAmount",
        "exudateType",
        "periIncisionSkin",
        "periIncisionSkinOther",
        "incisionStatusComments",
        "tableIncisionAssessment_name",
        "tableIncisionAssessment_profession"
      ],
      "hasRecHeader": true
    },
    "tableWound": {
      "elementKey": "tableWound",
      "pageElementIndex": "3",
      "pageKey": "integumentaryAssessment",
      "tableKey": "tableWound",
      "isTable": true,
      "form": {
        "elementKey": "tableWound",
        "label": "Wound profile",
        "addButtonText": "Add a wound profile",
        "formKey": "tableWound",
        "ehr_groups": [
          {
            "elementKey": "hdrGroup",
            "formCss": "record-header",
            "fqn": "integumentaryAssessment.hdrGroup",
            "gIndex": "1",
            "gChildren": [
              "tableWound_name",
              "tableWound_profession",
              "tableWound_day",
              "tableWound_time"
            ]
          },
          {
            "elementKey": "cGroup2",
            "formCss": "grid-left-to-right-3",
            "fqn": "integumentaryAssessment.cGroup2",
            "gIndex": "2",
            "gChildren": [
              "woundLocation",
              "woundLabel",
              "woundDayOnset"
            ]
          },
          {
            "elementKey": "woundTypeGroup",
            "formCss": "grid-left-to-right-3",
            "fqn": "integumentaryAssessment.woundTypeGroup",
            "gIndex": "3",
            "gChildren": [
              "stageType",
              "stageOther",
              "goalOfCare",
              "woundProfileImage",
              "woundProfileComments"
            ]
          }
        ],
        "ehr_data": {
          "tableWound_name": "",
          "tableWound_profession": "",
          "tableWound_day": "",
          "tableWound_time": "",
          "woundLocation": "",
          "woundLabel": "",
          "woundDayOnset": "",
          "stageType": "",
          "stageOther": "",
          "goalOfCare": "",
          "woundProfileImage": "",
          "woundProfileComments": ""
        }
      },
      "fqn": "integumentaryAssessment.tableWound",
      "tableChildren": [
        "tableWound_id",
        "tableWound_day",
        "tableWound_time",
        "woundLocation",
        "woundLabel",
        "woundDayOnset",
        "stageType",
        "stageOther",
        "goalOfCare",
        "woundProfileImage",
        "woundProfileComments",
        "tableWound_name",
        "tableWound_profession"
      ],
      "hasRecHeader": true
    },
    "tableWoundAssessment": {
      "elementKey": "tableWoundAssessment",
      "pageElementIndex": "4",
      "pageKey": "integumentaryAssessment",
      "tableKey": "tableWoundAssessment",
      "isTable": true,
      "form": {
        "elementKey": "tableWoundAssessment",
        "label": "Wound assessment",
        "addButtonText": "Add a wound assessment",
        "formOption": "transpose",
        "formKey": "tableWoundAssessment",
        "ehr_groups": [
          {
            "elementKey": "hdrGroup",
            "formCss": "record-header",
            "fqn": "integumentaryAssessment.hdrGroup",
            "gIndex": "1",
            "gChildren": [
              "tableWoundAssessment_name",
              "tableWoundAssessment_profession",
              "tableWoundAssessment_day",
              "tableWoundAssessment_time"
            ]
          },
          {
            "elementKey": "woundMeasureGroup",
            "formCss": "grid-left-to-right-3",
            "fqn": "integumentaryAssessment.woundMeasureGroup",
            "gIndex": "2",
            "gChildren": [
              "woundLabel",
              "woundAssessmentImage"
            ]
          },
          {
            "elementKey": "woundMeasureGroup",
            "formCss": "grid-left-to-right-3",
            "fqn": "integumentaryAssessment.woundMeasureGroup",
            "gIndex": "3",
            "gChildren": [
              "length",
              "width",
              "depth",
              "sinusDepth1",
              "sinusDepthLocation1",
              "spacer42",
              "sinusDepth2",
              "sinusDepthLocation2",
              "spacer43",
              "underminingDepth1",
              "underminingDepthLocation1A",
              "underminingDepthLocation1B",
              "underminingDepth2",
              "underminingDepthLocation2A",
              "underminingDepthLocation2B"
            ]
          },
          {
            "elementKey": "woundBed",
            "formCss": "grid-left-to-right-3",
            "fqn": "integumentaryAssessment.woundBed",
            "gIndex": "4",
            "gChildren": [
              "pinkred",
              "granulation",
              "nongranulated",
              "slough",
              "escharBoggy",
              "escharStable",
              "foreign",
              "underlying",
              "notVisible",
              "other",
              "woundBedCalculation"
            ]
          },
          {
            "elementKey": "cGroup5",
            "formCss": "grid-left-to-right-3",
            "fqn": "integumentaryAssessment.cGroup5",
            "gIndex": "5",
            "gChildren": [
              "exudateAmount",
              "exudateType"
            ]
          },
          {
            "elementKey": "cGroup6",
            "formCss": "grid-left-to-right-3",
            "fqn": "integumentaryAssessment.cGroup6",
            "gIndex": "6",
            "gChildren": [
              "odour"
            ]
          },
          {
            "elementKey": "cGroup7",
            "formCss": "grid-left-to-right-3",
            "fqn": "integumentaryAssessment.cGroup7",
            "gIndex": "7",
            "gChildren": [
              "woundEdge",
              "periWoundSkin"
            ]
          },
          {
            "elementKey": "cGroup8",
            "formCss": "grid-left-to-right-3",
            "fqn": "integumentaryAssessment.cGroup8",
            "gIndex": "8",
            "gChildren": [
              "woundPain"
            ]
          },
          {
            "elementKey": "cGroup9",
            "formCss": "grid-left-to-right-3",
            "fqn": "integumentaryAssessment.cGroup9",
            "gIndex": "9",
            "gChildren": [
              "packingOut",
              "packinIn",
              "spacer44",
              "treatmentComplete",
              "woundAssessmentComments"
            ]
          }
        ],
        "ehr_data": {
          "tableWoundAssessment_name": "",
          "tableWoundAssessment_profession": "",
          "tableWoundAssessment_day": "",
          "tableWoundAssessment_time": "",
          "woundLabel": "",
          "woundAssessmentImage": "",
          "length": "",
          "width": "",
          "depth": "",
          "sinusDepth1": "",
          "sinusDepthLocation1": "",
          "sinusDepth2": "",
          "sinusDepthLocation2": "",
          "underminingDepth1": "",
          "underminingDepthLocation1A": "",
          "underminingDepthLocation1B": "",
          "underminingDepth2": "",
          "underminingDepthLocation2A": "",
          "underminingDepthLocation2B": "",
          "pinkred": "",
          "granulation": "",
          "nongranulated": "",
          "slough": "",
          "escharBoggy": "",
          "escharStable": "",
          "foreign": "",
          "underlying": "",
          "notVisible": "",
          "other": "",
          "woundBedCalculation": "0",
          "exudateAmount": "",
          "exudateType": "",
          "odour": "",
          "woundEdge": "",
          "periWoundSkin": "",
          "woundPain": "",
          "packingOut": "",
          "packinIn": "",
          "treatmentComplete": "",
          "woundAssessmentComments": ""
        }
      },
      "fqn": "integumentaryAssessment.tableWoundAssessment",
      "tableChildren": [
        "tableWoundAssessment_id",
        "tableWoundAssessment_day",
        "tableWoundAssessment_time",
        "woundLabel",
        "woundAssessmentImage",
        "length",
        "width",
        "depth",
        "sinusDepth1",
        "sinusDepthLocation1",
        "sinusDepth2",
        "sinusDepthLocation2",
        "underminingDepth1",
        "underminingDepthLocation1A",
        "underminingDepthLocation1B",
        "underminingDepth2",
        "underminingDepthLocation2A",
        "underminingDepthLocation2B",
        "pinkred",
        "granulation",
        "nongranulated",
        "slough",
        "escharBoggy",
        "escharStable",
        "foreign",
        "underlying",
        "notVisible",
        "other",
        "woundBedCalculation",
        "exudateAmount",
        "exudateType",
        "odour",
        "woundEdge",
        "periWoundSkin",
        "woundPain",
        "packingOut",
        "packinIn",
        "treatmentComplete",
        "woundAssessmentComments",
        "tableWoundAssessment_name",
        "tableWoundAssessment_profession"
      ],
      "hasRecHeader": true
    }
  },
  "path": "/ehr/current/assessments",
  "routeName": "integumentary-assessment",
  "label": "Integumentary assessment",
  "redirectEhr": "",
  "redirectLis": "",
  "generateComponent": "custom",
  "ehr": "yes",
  "fullPageKey": "current.assessments.integumentaryAssessment"
}
export default DEFS