/* eslint-disable */
const DEFS = {
  "pageDataKey": "allergies",
  "pIndex": "2",
  "formOption": "no-tabs",
  "hasGridTable": true,
  "hasGridForm": true,
  "pageChildren": [
    {
      "elementKey": "checkbox",
      "formIndex": "1",
      "inputType": "checkbox",
      "fqn": "allergies.checkbox"
    },
    {
      "elementKey": "text",
      "formIndex": "1",
      "inputType": "text",
      "fqn": "allergies.text"
    },
    {
      "elementKey": "allergyList_name",
      "formIndex": "2",
      "inputType": "practitionerName",
      "tableColumn": "1",
      "recHeader": true,
      "fqn": "allergies.name"
    },
    {
      "elementKey": "allergyList_id",
      "inputType": "generatedId",
      "tableColumn": 0,
      "label": "Row id",
      "tableCss": "row-id"
    },
    {
      "elementKey": "allergyList_profession",
      "formIndex": "2",
      "inputType": "practitionerProfession",
      "tableColumn": "1",
      "recHeader": true,
      "fqn": "allergies.profession"
    },
    {
      "elementKey": "allergyList_day",
      "formIndex": "2",
      "inputType": "visitDay",
      "mandatory": true,
      "tableColumn": "1",
      "validation": "visitDay",
      "recHeader": true,
      "fqn": "allergies.day"
    },
    {
      "elementKey": "allergyList_time",
      "formIndex": "2",
      "inputType": "visitTime",
      "mandatory": true,
      "tableColumn": "1",
      "validation": "time24",
      "recHeader": true,
      "fqn": "allergies.time"
    },
    {
      "elementKey": "allergen",
      "formIndex": "2",
      "inputType": "text",
      "tableColumn": "2",
      "fqn": "allergies.allergen"
    },
    {
      "elementKey": "reaction",
      "formIndex": "2",
      "inputType": "text",
      "tableColumn": "3",
      "fqn": "allergies.reaction"
    }
  ],
  "pageElements": {
    "pageForm": {
      "elementKey": "pageForm",
      "pageElementIndex": "1",
      "formKey": "pageForm",
      "fqn": "allergies.pageForm",
      "isPageForm": true,
      "ehr_groups": [
        {
          "elementKey": "formGroup",
          "formCss": "grid-left-to-right-1",
          "fqn": "allergies.formGroup",
          "gIndex": "1",
          "gChildren": [
            "checkbox",
            "text"
          ]
        }
      ]
    },
    "allergyList": {
      "elementKey": "allergyList",
      "pageElementIndex": "2",
      "pageKey": "allergies",
      "tableKey": "allergyList",
      "isTable": true,
      "form": {
        "elementKey": "allergyList",
        "label": "Allergies",
        "addButtonText": "Add an allergy",
        "formKey": "allergyList",
        "ehr_groups": [
          {
            "elementKey": "hdrGroup",
            "formCss": "record-header",
            "fqn": "allergies.hdrGroup",
            "gIndex": "1",
            "gChildren": [
              "allergyList_name",
              "allergyList_profession",
              "allergyList_day",
              "allergyList_time"
            ]
          },
          {
            "elementKey": "cGroup",
            "formCss": "grid-left-to-right-3",
            "fqn": "allergies.cGroup",
            "gIndex": "2",
            "gChildren": [
              "allergen",
              "reaction"
            ]
          }
        ],
        "ehr_data": {
          "allergyList_name": "",
          "allergyList_profession": "",
          "allergyList_day": "",
          "allergyList_time": "",
          "allergen": "",
          "reaction": ""
        }
      },
      "fqn": "allergies.allergyList",
      "tableChildren": [
        "allergyList_id",
        "allergyList_day",
        "allergyList_time",
        "allergen",
        "reaction",
        "allergyList_name",
        "allergyList_profession"
      ],
      "hasRecHeader": true
    }
  },
  "path": "/ehr/patient",
  "routeName": "allergies",
  "label": "Allergies",
  "redirectEhr": "",
  "redirectLis": "",
  "generateComponent": "custom",
  "lis": "yes",
  "ehr": "yes",
  "fullPageKey": "patient.allergies"
}
export default DEFS