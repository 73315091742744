/* eslint-disable */
const DEFS = {
  "pageDataKey": "surgical",
  "pIndex": "5",
  "hasGridTable": true,
  "pageChildren": [
    {
      "elementKey": "physician",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "1",
      "fqn": "surgical.physician"
    },
    {
      "elementKey": "pastSurgery_id",
      "inputType": "generatedId",
      "tableColumn": 0,
      "label": "Row id",
      "tableCss": "row-id"
    },
    {
      "elementKey": "timeSince",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "2",
      "fqn": "surgical.timeSince"
    },
    {
      "elementKey": "procedure",
      "formIndex": "1",
      "formCss": "grid-span-3-columns",
      "inputType": "textarea",
      "tableColumn": "3",
      "fqn": "surgical.procedure"
    },
    {
      "elementKey": "previousAdmissionsDate",
      "formIndex": "2",
      "inputType": "text",
      "tableColumn": "1",
      "fqn": "surgical.previousAdmissionsDate"
    },
    {
      "elementKey": "previous_id",
      "inputType": "generatedId",
      "tableColumn": 0,
      "label": "Row id",
      "tableCss": "row-id"
    },
    {
      "elementKey": "with",
      "formIndex": "2",
      "inputType": "text",
      "tableColumn": "2",
      "fqn": "surgical.with"
    },
    {
      "elementKey": "details",
      "formIndex": "2",
      "inputType": "text",
      "tableColumn": "3",
      "fqn": "surgical.details"
    },
    {
      "elementKey": "previousAdmissions",
      "formIndex": "2",
      "inputType": "text",
      "tableColumn": "4",
      "fqn": "surgical.previousAdmissions"
    },
    {
      "elementKey": "comments",
      "formIndex": "2",
      "formCss": "grid-span-3-columns",
      "inputType": "textarea",
      "tableColumn": "5",
      "fqn": "surgical.comments"
    }
  ],
  "pageElements": {
    "pastSurgery": {
      "elementKey": "pastSurgery",
      "pageElementIndex": "1",
      "pageKey": "surgical",
      "tableKey": "pastSurgery",
      "isTable": true,
      "form": {
        "elementKey": "pastSurgery",
        "label": "Previous surgeries",
        "addButtonText": "Add a previous surgery",
        "formKey": "pastSurgery",
        "ehr_groups": [
          {
            "elementKey": "pastSurgery",
            "formCss": "grid-left-to-right-3",
            "fqn": "surgical.pastSurgery",
            "gIndex": "1",
            "gChildren": [
              "physician",
              "timeSince",
              "procedure"
            ]
          }
        ],
        "ehr_data": {
          "physician": "",
          "timeSince": "",
          "procedure": ""
        }
      },
      "fqn": "surgical.pastSurgery",
      "tableChildren": [
        "pastSurgery_id",
        "procedure",
        "physician",
        "timeSince"
      ]
    },
    "previous": {
      "elementKey": "previous",
      "pageElementIndex": "2",
      "pageKey": "surgical",
      "tableKey": "previous",
      "isTable": true,
      "form": {
        "elementKey": "previous",
        "label": "Previous appointments",
        "addButtonText": "Add a previous appointment",
        "formKey": "previous",
        "ehr_groups": [
          {
            "elementKey": "previous",
            "formCss": "grid-left-to-right-3",
            "fqn": "surgical.previous",
            "gIndex": "1",
            "gChildren": [
              "previousAdmissionsDate",
              "with",
              "details",
              "previousAdmissions",
              "comments"
            ]
          }
        ],
        "ehr_data": {
          "previousAdmissionsDate": "",
          "with": "",
          "details": "",
          "previousAdmissions": "",
          "comments": ""
        }
      },
      "fqn": "surgical.previous",
      "tableChildren": [
        "previous_id",
        "details",
        "previousAdmissions",
        "comments",
        "previousAdmissionsDate",
        "with"
      ]
    }
  },
  "path": "/ehr/patient/history",
  "routeName": "surgical",
  "label": "Surgical history",
  "redirectEhr": "",
  "redirectLis": "",
  "ehr": "yes",
  "fullPageKey": "patient.history.surgical"
}
export default DEFS