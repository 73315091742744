/* eslint-disable */
const DEFS = {
  "pageDataKey": "chart",
  "pIndex": "103",
  "pageChildren": [],
  "pageElements": {},
  "path": "/ehr",
  "routeName": "chart",
  "label": "Patient chart",
  "generateComponent": "custom",
  "ehr": "yes",
  "fullPageKey": "chart"
}
export default DEFS