/* eslint-disable */
const DEFS = {
  "pageDataKey": "careTeam",
  "pIndex": "8",
  "hasGridTable": true,
  "pageChildren": [
    {
      "elementKey": "name",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "1",
      "fqn": "careTeam.name"
    },
    {
      "elementKey": "teams_id",
      "inputType": "generatedId",
      "tableColumn": 0,
      "label": "Row id",
      "tableCss": "row-id"
    },
    {
      "elementKey": "profession",
      "formIndex": "1",
      "inputType": "text",
      "tableColumn": "2",
      "fqn": "careTeam.profession"
    }
  ],
  "pageElements": {
    "teams": {
      "elementKey": "teams",
      "pageElementIndex": "1",
      "pageKey": "careTeam",
      "tableKey": "teams",
      "isTable": true,
      "form": {
        "elementKey": "teams",
        "label": "Care team",
        "addButtonText": "Add a care team member",
        "formKey": "teams",
        "ehr_groups": [
          {
            "elementKey": "cGroup",
            "formCss": "grid-left-to-right-3",
            "fqn": "careTeam.cGroup",
            "gIndex": "1",
            "gChildren": [
              "name",
              "profession"
            ]
          }
        ],
        "ehr_data": {
          "name": "",
          "profession": ""
        }
      },
      "fqn": "careTeam.teams",
      "tableChildren": [
        "teams_id",
        "name",
        "profession"
      ]
    }
  },
  "path": "/ehr/patient",
  "routeName": "care-team",
  "label": "Care team",
  "redirectEhr": "",
  "redirectLis": "",
  "ehr": "yes",
  "fullPageKey": "patient.careTeam"
}
export default DEFS